import React, { useEffect, useState, useRef } from "react"
//i18n
import { withTranslation } from "react-i18next"
import Flatpickr from "react-flatpickr";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Label,
  Input,
} from "reactstrap"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { AvForm, AvField } from "availity-reactstrap-validation"
// Redux
import { connect } from "react-redux"
import withRouter from "../../../components/Common/withRouter"
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import exportFromJSON from "export-from-json"
import BootstrapTable from "react-bootstrap-table-next"
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import logo from "../../../assets/images/Logo.png"
import { postSubmitForm } from "../../../helpers/backend_helper"
import BackgroundImage from "../../../assets/images/Background.png"
import preloader from "../../../helpers/Loader"


const DailyAttendanceReport = props => {

  const [attendanceData, setData] = useState([]);
  const [shifts, setShifts] = useState({});

  const [attendanceDate, setAttendanceDate] = useState(new Date())
  const [tracker, setTracker] = useState(false)
  const [editModal, setEditModal] = useState()
  const [TP, setTP] = useState(0);
  const [TA, setTA] = useState(0);
  const [late, setLate] = useState(0);
  const [selectedUser, setSelectedUser] = useState()


  const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
  const [markUndertime, setMarkUndertime] = useState({ "aksfjaslkf": false });

  const refContainer = useRef(null);
  const { SearchBar } = Search
const code = localStorage.getItem("code")

  const handleDownload = async () => {
    // console.log("hello")
    const fileName = "Daily Attendance Report";
    const exportType = "xls";

    let data_to_export = attendanceData;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          Attendance_Date: v.attendance_date,
          Employee: v.employee_name,
          Code: v.employee_code,
          Day_Status: v.day_status,
          Shift: v.shift_name,
          In_Time: v.in_time,
          Out_Time: v.out_time,
          Late_In: v.late_in,
          Early_out: v.early_out,
          Overtime: v.system_overtime,
          Undertime: v.undertime,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    const imageHeight = "105px";
    const imageWidth = "110px";
    const borderradius = "50%";
    const background = "#000000";
    printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour -${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 200px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2> Daily Attendance Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
    printWindow.document.close();
    const logoImg = printWindow.document.getElementById('logoImg');
    logoImg.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };


  useEffect(() => {
    // function to filter shift id by matching with shift name
    const getShifts = (token) => {

      let url = process.env.REACT_APP_BASEURL

      axios.post(url + `shifts/getall`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(res => setShifts(res.data.data)).catch(err => console.log(err))

    }
    getShifts(employeeToken)
  }, [])

  const fetchAttendance = (object, token) => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL
    axios
      .post(
        url + "attendances/getall_by_date",

        object,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        preloader(false)
        setData(res.data.data)
        const data = res.data.data;
        const totalPresent = data.reduce((total, item) => {
          if (item.day_status === 'P') { return total + 1; } return total;
        }, 0);
        setTP(totalPresent);
        const totalAbsent = data.reduce((total, item) => {
          if (item.day_status === 'A' || item.day_status === "") { return total + 1; } return total;
        }, 0);
        setTA(totalAbsent);
        const totalLate = data.reduce((total, item) => {
          if (item.late_in !== null && !isNaN(parseFloat(item.late_in))) { return total + 1;}  return total;
        }, 0);
        setLate(totalLate);
      })
      .catch((err) => {
        preloader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    const object = {
      attendance_date: new Date().toISOString().split('T')[0]
    }
    const token = employeeToken
    fetchAttendance(object, token);
  }, [tracker])



  const filterShiftId = (shiftName) => {

    return shifts.filter(shift => shift.name === shiftName)[0]

  }




  const handleValidSubmit = async (e, v, token, status) => {

    let object = {}
    if (status === "modalUpdate") {


      const dateString = selectedUser.attendance_date;


      const date = new Date(dateString);

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();


      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

      const shiftObject = filterShiftId(selectedUser.shift_name)

      object = {
        employee_id: selectedUser.employee_id,
        attendance_date: formattedDate,
        shift_id: shiftObject._id,
        in_time: v.in_time,
        out_time: v.out_time,
        day_status: v.day_status,
        is_approved: v.is_approved,
        remarks: v.remarks,
        undertime: markUndertime === false ? 0 : v.undertime,
        mark_undertime: markUndertime === false ? false : v.undertime > 0 ? true : false
      }

      updateAttendance(object, token)

      setEditModal(!editModal);
      refContainer.current.reset()
    } else {

      object = {
        attendance_date: v.attendance_date
      }
      setAttendanceDate(object)
      fetchAttendance(object, token);
    }

  }


  function dateFormatter(cell, row) {

    return row.attendance_date.slice(0, 10)
  }


  function isApprovedFormatter(cell, row) {

    return row.is_approved === true ? "Yes" : "No"
  }


  function dayStatusFormatter(cell, row) {
    return row.day_status === "P" ? <Badge
      className={`fs-5 badge-soft-success`}
    >
      {row.day_status}
    </Badge> : row.day_status === "A" ? <Badge
      className={`fs-5 badge-soft-danger`}
    >
      {row.day_status}
    </Badge> : row.day_status === "LWP" ? <Badge
      className={`fs-5 badge-soft-danger`}
    >
      {row.day_status}
    </Badge> : null;
  }
  // -------------------------------------------------------------

  // function handleCheckbox(e, v) {

  //   setUndertimeDisabled(!undertimeDisabled)
  // }

  const columns = [

    {
      dataField: "_id",
      hidden: true
    },
    {
      dataField: "employee_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Employee Code"),
      //formatter: employeeFormatter,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Employee Name"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Attendance Date"),
      formatter: dateFormatter,
      dataField: "attendance_date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Shift"),
      //formatter: employeeFormatter,
      dataField: "shift_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("In Time"),
      //formatter: employeeFormatter,
      dataField: "in_time",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Out Time"),
      //formatter: employeeFormatter,
      dataField: "out_time",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Late In"),
      //formatter: employeeFormatter,
      dataField: "late_in",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Early Out"),
      //formatter: employeeFormatter,
      dataField: "early_out",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("System Overtime"),
      //formatter: employeeFormatter,
      dataField: "system_overtime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Approved Overtime"),
      // formatter: (col, row) => row.approved_overtime || '0',
      dataField: "approved_overtime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Approved Undertime"),
      // formatter: (col, row) => row.approved_undertime || '0',
      dataField: "undertime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Day Status"),
      formatter: dayStatusFormatter,
      dataField: "day_status",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Is Approved"),
      formatter: isApprovedFormatter,
      dataField: "is_approved",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Remarks"),
      dataField: "remarks",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },


  ]


  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Attendance Report" breadcrumbItem1="Daily Attendance Report" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v, employeeToken, ""); }} ref={refContainer}>
                    <Row >

                      <Col md={3}>


                        <AvField
                          label={props.t("Attendance Date")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={attendanceDate}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}

                          name="attendance_date"
                          placeholder="Select Attendance Date"
                          errorMessage="Select Attendance Date"
                          validate={{
                            required: { value: true },
                          }}
                        />

                      </Col>



                      <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                        <label style={{ height: "20.5px" }} className="form-label">

                        </label>
                        <div style={{ display: "flex" }}>

                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "-0.2rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Show")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>

                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("Search by Name")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                          className="btn btn-sm btn-secondary"
                          disabled={attendanceData.length <= 0}
                          onClick={() => { printDiv("printDiv") }}
                        >
                          <i
                            className="fa fa-print " aria-hidden="true"
                            onClick={() => { printDiv("printDiv") }}
                          />&nbsp; {props.t("Print")}
                        </Button>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={attendanceData.length <= 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>

                    </Row>
                  </CardTitle>
                  <div id="printDiv">
                    <ToolkitProvider
                      keyField="_id"
                      data={attendanceData}
                      columns={columns}
                      search
                    >
                      {propst => (
                        <div>
                          <Row className="hide">
                            <Col lg={2} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                className="form-control"
                                {...propst.searchProps}
                                // style={{ width: "340px" }}
                                placeholder="search"
                              />
                            </Col>
                            <Col lg={10} >
                            <Row style={{fontSize: "20px", display:"flex", justifyContent:"space-around"}}>
                          <Col ><Label>Present : {TP}</Label></Col>
                          <Col ><Label>Absent : {TA}</Label></Col>
                          <Col > <Label>Late : {late}</Label></Col>
                            </Row>
                            </Col>
                          </Row>

                          <BootstrapTable
                            {...propst.baseProps}
                            noDataIndication={props.t("No data to display.")}
                            striped
                            filter={filterFactory()}
                            hover
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}
export default withRouter(connect(null, {})(withTranslation()(DailyAttendanceReport)))
