import React from 'react'

export const Pagination = ({totalPage,pageNumber,setPageNumber}) => {



    const handlePageChange = page => {
        setPageNumber(page)
      }
    
      const goToPrevPage = () => {
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1)
        }
      }
    
      const goToNextPage = () => {
        if (pageNumber < totalPage) {
          setPageNumber(pageNumber + 1)
        }
      }

  const generatePageButtons = () => {
    const buttonsToShow = 5
    const totalPagesLeft = totalPage - pageNumber
    const startIndex = Math.max(1, pageNumber - Math.floor(buttonsToShow / 2))
    let endIndex = Math.min(totalPage, startIndex + buttonsToShow - 1)

    if (totalPagesLeft < Math.floor(buttonsToShow / 2)) {
      endIndex = totalPage
    }

    const pageButtons = []
    for (let i = startIndex; i <= endIndex; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          style={{
            margin: '0 5px',
            padding: '10px 15px',
            borderRadius: '5px',
            cursor: 'pointer',
            backgroundColor: pageNumber === i ? '#556EE6' : '',
            color: pageNumber === i ? 'white' : 'black',
            border: 'none',
            outline: 'none',
            transition: 'background-color 0.3s, color 0.3s',
          }}
          className={
            i === pageNumber
              ? "font-bold text-2xl rounded-x px-4 bg-blue-800 rounded-xl"
              : "font-semibold text-xl rounded-xl px-3  bg-blue-600"
          }
        >
          {i}
        </button>
      )
    }
    return pageButtons.length==0? <button
    style={{
      margin: '0 5px',
      padding: '10px 15px',
      borderRadius: '5px',
      cursor: 'pointer',
      backgroundColor: '#556EE6' ,
      color:'white',
      border: 'none',
      outline: 'none',
      transition: 'background-color 0.3s, color 0.3s',
    }}
    className={
      
        "font-bold text-2xl rounded-x px-4 bg-blue-800 rounded-xl"
       
    }
  >
    {1}
  </button>:pageButtons
  }
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <button
                             onMouseOver={(e) => {
                                e.target.style.backgroundColor = pageNumber !== 1 ? 'darkgray' : 'lightgray';
                                e.target.style.color = 'white';
                              }}
                              onMouseOut={(e) => {
                                e.target.style.backgroundColor = 'lightgray';
                                e.target.style.color = 'black';
                              }}
                             style={{
                                margin: '0 10px',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                backgroundColor: 'lightgray',
                                color: 'black',
                                border: 'none',
                                outline: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                opacity: pageNumber === 1 ? 0.5 : 1,
                                pointerEvents: pageNumber === 1 ? 'none' : 'auto',
                              }}
                              onClick={goToPrevPage}
                              disabled={pageNumber === 1}
                            >
                              PREV
                            </button>
                            <div  style={{ display: 'inline-block' }}>{generatePageButtons()}</div>
                            <button
                              onClick={goToNextPage}
                              disabled={pageNumber === totalPage}
                              style={{
                                margin: '0 10px',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                backgroundColor: 'lightgray',
                                color: 'black',
                                border: 'none',
                                outline: 'none',
                                transition: 'background-color 0.3s, color 0.3s',
                                opacity: pageNumber === totalPage ? 0.5 : 1,
                                pointerEvents: pageNumber === totalPage ? 'none' : 'auto',
                              }}
                              onMouseOver={(e) => {
                                e.target.style.backgroundColor = pageNumber !== totalPage ? 'darkgray' : 'lightgray';
                                e.target.style.color = 'white';
                              }}
                              onMouseOut={(e) => {
                                e.target.style.backgroundColor = 'lightgray';
                                e.target.style.color = 'black';
                              }}
                            >
                              NEXT
                            </button>
                        
                        </div>
  )
}
