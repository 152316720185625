import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody, CardTitle,
  Col,
  Row,
  Container,
  Badge,
  Button,
  Label,
} from "reactstrap"
import Swal from "sweetalert2"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import exportFromJSON from "export-from-json"
import BackgroundImage from "../../assets/images/Background.png"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import moment from "moment"


const ApplyLeaveByOwner = (props) => {
  //usestate for showing alert and when apply leave application gets submitted
  const [showalert, setShowalert] = useState(false)
  // useState for fetching and storing the data of the apply leave table
  const [employee_id, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  )
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [appliedLeaveDetails, setAppliedLeaveDetails] = useState([]);
  const refContainer = useRef(null)

  const [minDateVal, setMinDateVal] = useState("")
  const [disabledStatus, setDisabledStatus] = useState(true)

  const [submitButtonClickedTracker, setSubmitButtonClickedTracker] = useState(0)
  const [leaveApplications, setLeaveApplications] = useState([]);
  const { SearchBar } = Search

  useEffect(() => {
    fetchLeaveApplications();
  }, []);

  useEffect(() => {
    function getRoster(token) {
      let url = process.env.REACT_APP_BASEURL
      // console.log("token:",token)
      axios
        .post(
          url + `leaveapplications/get_by_employee`,
          { employee_id: employee_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(res => {
          setAppliedLeaveDetails(res.data.data)
        })
        .catch(err => console.log(err))
    }

    getRoster(employeeToken);
    loadAllEmployees();
  }, [submitButtonClickedTracker])




  // -------------------------------------------------------------
  const [allEmployees, setAllEmployees] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState("")
  const loadAllEmployees = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall"
    let response = await postSubmitForm(url, "")

    if (response.status === 1) {
      response.data.map(employee => {
        employee.label = employee.name,
          employee.value = employee._id
      })
      const active = response.data.filter(employee => employee.is_active === true)
      setAllEmployees(active)

    } else {
      showNotification(response.message, "Error")
    }
  }
  // function handle submit
  const handleValidSubmit = async (e, v, token) => {

    let url = process.env.REACT_APP_BASEURL

    const object = {
      employee_id: selectedEmployee?._id,
      date_from: v.date_from,
      date_to: v.date_to,
      leave_type: v.leave_type,
      reason: v.reason,
    }
    // console.log("Data", object)
    // return
    axios
      .post(
        url + `leaveapplications/insert`,
        object,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        setShowalert(true)
        // console.log(res)
        setSubmitButtonClickedTracker(submitButtonClickedTracker + 1)
        if (res.data.status === 1) {
          Swal.fire("Success", res.data.message, "success");
          setSelectedEmployee("")
          fetchLeaveApplications();
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
        if (refContainer.current) {
          refContainer.current.reset();
        }
        fetchLeaveApplications();
      })
      .catch(err => {
        console.log(err);

        swal.fire({
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 2000
        });
        if (refContainer.current) {
          refContainer.current.reset();
        }
      })
   
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
  }
  // ____fetchdata____
  const fetchLeaveApplications = async () => {
    let url = process.env.REACT_APP_BASEURL
    try {
      const response = await postSubmitForm(url + "leaveapplications/getall_appliedBy",)
      if(response.status===1){
        setLeaveApplications(response.data);
      }
    } catch (error) {
      showNotification(error,"Error")
    }
  }
  // __--------------------------------------------
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Code"),
      hidden: true,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Name/Code"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      formatter: (cell, row) => {
        return row.employee_name + " " + "(" + row.employee_code + ")"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "date_from",
      text: props.t("From Date"),
      formatter: (cell, row) => {
        return (
          <p>{row.date_from.slice(0, 10)}</p>
        )
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "date_to",
      text: props.t("To Date"),
      formatter: (cell, row) => {
        return (
          <p>{row.date_to.slice(0, 10)}</p>
        )
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "leave_type",
      text: props.t("Type of Leave"),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "remaining_annual_leave",
      text: props.t("Remaining Leave"),
      sort: false,
      formatter: (cell, row) => {
        switch (row.leave_type) {
          case "Annual Leave": return row.remaining_annual_leave;
          case "Sick Leave": return row.remaining_sick_leave;
          case "LWP": return "NA";
          default: return "NA";
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "reason",
      text: props.t("Reason"),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <Badge
              className={`font-size-12 badge-soft-${row.status === "Approved"
                ? "success"
                : row.status === "Pending"
                  ? "warning"
                  : row.status === "Rejected"
                    ? "danger"
                    : null
                }`}
            >
              {row.status}
            </Badge>

            <div>
              <p
                style={{
                  fontSize: "11px",
                  fontWeight: "500",
                  marginTop: "0.4rem",
                }}
              >
                {row.remark
                  ? `Remark:   ${row.remark} `
                  : ""}
              </p>
            </div>
          </>
        )
      },
      filter: selectFilter({
        options: [
          { value: "Approved", label: "Approved" },
          { value: "Pending", label: "Pending" },
          { value: "Rejected", label: "Rejected" },
        ],
        defaultValue:'All',
        placeholder: "ALL",
      }),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]
  const handleDownload = async () => {
    const fileName = "All Applied Leaves";
    const exportType = "xls";

    let data_to_export = leaveApplications;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Employye_Name: v.employee_name,
          Employye_Code: v.employee_code,
          Date_from: moment(v.date_from).format("YYYY-MM-DD"),
          Date_to: moment(v.date_to).format("YYYY-MM-DD"),
          LeaveType: v.leave_type,
          Remaining_leave: v.leave_type==="Annual Leave"  ? v.remaining_annual_leave : v.leave_type==="Sick Leave" ? v.remaining_sick_leave:"N/A",
          Reason: v.reason,
          Status: v.status,

        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  // ------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Leave  " breadcrumbItem="Leave Application Form" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" }}>
                  <AvForm onValidSubmit={(e, v) => {
                    if (selectedEmployee === "") {
                      showWarning("Please Select Employee");
                      return;
                    } else {
                      handleValidSubmit(e, v, employeeToken);
                    }
                  }} ref={refContainer}>
                    <Row>

                      <Col lg={4}>
                        <Label>
                          <span >
                            {props.t("Select Employee")}*
                          </span>
                        </Label>
                        <Select
                          value={selectedEmployee || []}
                          onChange={selected => {
                            setSelectedEmployee(selected)
                          }}
                          options={allEmployees}
                          closeMenuOnSelect={true}
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <div className="mx-2">
                          <label className="form-label">
                            {props.t("From Date")}*
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            name="date_from"
                            placeholder="Select Date"
                            errorMessage="Select From Date."
                            options={{
                              minDate: "today",
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },
                            }}
                            onChange={(v) => {

                              // if (minDateOnChangeActive === true) {


                              const dateString = v;


                              const date = new Date(dateString);

                              // Extract the date components
                              const day = date.getDate();
                              const month = date.getMonth() + 1; // Months are zero-based, so add 1
                              const year = date.getFullYear();

                              // Format the date as "dd-mm-yyyy"
                              const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                              // console.log(formattedDate); // Output: 26-12-2023
                              setMinDateVal(formattedDate);
                              setDisabledStatus(false)
                              //   setMaxDateOnChangeActive(false)
                              // }
                            }}
                            validate={{
                              required: { value: true },
                            }}
                          />

                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mx-2 ">
                          <label className="form-label">
                            {props.t("To Date")}*
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            name="date_to"
                            disabled={disabledStatus}
                            options={{
                              minDate: "today",
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },
                              minDate: minDateVal
                            }}
                            placeholder="Select To Date"
                            validate={{ required: { value: true } }}
                            errorMessage="Select To Date"
                          >
                            <option value="">Select role</option>

                            {/* {allDesignations && 
                                allDesignations.map((role)=>(
                                  <option value={role.name} key={role._id}>{role.name}</option>
                                ))} */}

                          </AvField>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                          <label className="form-label">
                            {props.t("Leave Type")}*
                          </label>
                          <AvField
                            className="form-control"
                            name="leave_type"
                            type="select"

                            errorMessage="Leave Type cannot be empty."
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">{props.t("Select Leave Type")}</option>
                            <option value="Annual Leave">
                              {props.t("Annual Leave")}
                            </option>
                            <option value="Sick Leave">
                              {props.t("Sick Leave")}
                            </option>
                            <option value="LWP">
                              {props.t("LWP")}
                            </option>

                          </AvField>
                      </Col>
                      <Col lg={4}>
                        <div className="mx-2 ">
                          <label className="form-label">
                            {props.t("Reason")}*
                          </label>
                          <AvField
                            className="form-control"
                            type="textarea"
                            placeholder="Enter Reason for Leave"
                            name="reason"
                            validate={{
                              required: { value: true }
                            }}
                            errorMessage="Reason cannot be empty."
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg={4}>
                        <div className="mx-2"></div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "-1rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Submit")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle className="fs-5" >View all applied leave here!</CardTitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={leaveApplications || []}
                    search
                    isGlobalFilter={true}
                    isAddUserList={true}
                    customPageSize={10}  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            {/* <label className="form-label">
                              {("Search By Employee Name")}
                            </label> */}
                            <Col lg={6} style={{ display: "flex", }}>
                              <SearchBar
                                {...propst.searchProps}
                              // style={{ width: "340px" }}
                              />
                            </Col>
                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                            <Col  >
                              {/* <Button className="btn btn-info" onClick={() => { setResetBtnClickedTracker(prev => prev = prev + 1); setFirstTimeApiCall(false); }}  >Reset To Current Month</Button> */}
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ApplyLeaveByOwner)))
