import React, { useEffect, useState, useRef } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import showNotification from "../../helpers/show_notificaton"

import { postSubmitForm } from "../../helpers/backend_helper"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"


const AddSupplier = props => {

  const { SearchBar } = Search
  const refForForm = useRef(null)

  useEffect(() => {
    loadAllSuppliers()
  }, [])

  const [editModal, setEditModal] = useState()
  const [allSuppliers, setAllSuppliers] = useState([])

  const loadAllSuppliers = async () => {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      setAllSuppliers(response.data)
    } else {
      showNotification(response.message, "Error")
    }
  }

  function preventValueChangeOnScroll(e) {

    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current     
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  // ------------------------------------------------------------------------------



  const handleValidSubmit = async (e, v) => {
    try {
      const object = {
        name: v.name,
        contact_person: v.contact_person,
        phone: v.phone,
        address: v.address,
        mobile: v.mobile,
        email: v.email,
        section: v.section,
      }

      let url = process.env.REACT_APP_BASEURL + "suppliers/insert"
      let response = await postSubmitForm(url, object)
      if (response.status === 1) {
        showNotification(response.message, "Success")
        loadAllSuppliers()
        refForForm.current.reset()
      } else {
        showNotification(response.message, "Error")
      }
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }

  //meta title
  document.title = "Add Suppliers | Rendezvous - Game Parlour"

  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "contact_person",
      text: props.t("Contact Person"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Mobile"),
      //formatter: employeeFormatter,
      dataField: "mobile",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "phone",
      text: props.t("Phone"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "email",
      text: props.t("Email"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "address",
      text: props.t("Adress"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs alternateTitle={props.t("Supplier")} title="Add Supplier" breadcrumbItem1="Suppliers" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                 style={{
                  
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}

                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refForForm}>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          label={props.t("Name *")}
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder={props.t("Enter Name")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />

                      </Col>
                      <Col lg={6}>

                        <AvField
                          label={props.t("Contact Person *")}
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Contact Person Name")}
                          name="contact_person"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Contact Person Name cannot be empty")}
                        />

                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Mobile *")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Mobile")}
                          name="mobile"
                          validate={{
                            required: { value: true },
                            maxLength: {
                              value: 13,
                              errorMessage: props.t(
                                "Maximum 13 Digits are allowed."
                              ),
                            },
                          }}
                          errorMessage={props.t("Phone number cannot be empty")}
                        />

                      </Col>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Phone *")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Phone Number")}
                          name="phone"
                          validate={{
                            required: { value: true },
                            maxLength: {
                              value: 13,
                              errorMessage: props.t(
                                "Maximum 13 Digits are allowed."
                              ),
                            },
                          }}
                          errorMessage={props.t("Phone number cannot be empty")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          label={props.t("Email *")}
                          className="form-control"
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Email cannot be empty")}
                        />

                      </Col>

                      <Col lg={6}>
                        <AvField
                          label={props.t("Addres *")}
                          className="form-control"
                          type="textarea"
                          placeholder={props.t("Enter Addres")}
                          name="address"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Address cannot be empty")}
                        />
                      </Col>
                    </Row>
                    <Row>

                      <Col lg={12}>
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>
                      </Col>
                     
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <label>View All your Existing Supplier Here...</label>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allSuppliers && allSuppliers}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Supplier")}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody
                            >
                              <AvForm onValidSubmit="">
                                <Row>
                                  <Col lg={6}>
                                    <AvField
                                      name="name"
                                      label="Name"
                                      placeholder={props.t("Enter Name")}
                                      type="text"
                                      value={allSuppliers && allSuppliers.name}
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t("Name cannot be empty")}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <AvField
                                      name="contact_person"
                                      label={props.t("Contact Person")}
                                      placeholder={props.t("Enter Contact Person Name")}
                                      value={
                                        allSuppliers &&
                                        allSuppliers.contact_person
                                      }
                                      type="text"
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t("Contact person name cannot be empty")}
                                    />
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="mobile"
                                          value={
                                            allSuppliers && allSuppliers.mobile
                                          }
                                          label="Mobile"
                                          placeholder={props.t("Enter Mobile")}
                                          type="text"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage:
                                               props.t("Mobile cannot be empty."),
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage:
                                                props.t("Only numbers are allowed."),
                                            },
                                            maxLength: {
                                              value: 13,
                                              errorMessage:
                                                props.t("Max 13 digits are allowed"),
                                            },
                                            minLength: {
                                              value: 10,
                                              errorMessage:
                                                props.t("Min 10 Digits are required"),
                                            },
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col lg={6}>
                                    <AvField
                                      name="phone"
                                      label={props.t("Phone")}
                                      value={allSuppliers && allSuppliers.phone}
                                      type="text"
                                      errorMessage={props.t("Phone cannot be empty")}
                                      placeholder={props.t("Enter Phone")}
                                      validate={{
                                        pattern: {
                                          value: "^[0-9]+$",
                                          errorMessage:
                                            props.t("Only numbers are allowed."),
                                        },
                                        maxLength: {
                                          value: 13,
                                          errorMessage: props.t("Max 13 digits are allowed"),
                                        },
                                        minLength: {
                                          value: 13,
                                          errorMessage: props.t("Min 10 digits are required"),
                                        },
                                      }}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <AvField
                                      name="email"
                                      value={allSuppliers && allSuppliers.email}
                                      label={props.t("Email")}
                                      type="email"
                                      placeholder={props.t("Enter Email")}
                                    />
                                  </Col>

                                  <Col lg={6}>
                                    <AvField
                                      name="address"
                                      value={
                                        allSuppliers && allSuppliers.address
                                      }
                                      label="Address"
                                      placeholder={props.t("Enter Address")}
                                      type="textarea"
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t("Address cannot be empty")}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <AvField
                                      name="remarks"
                                      value={
                                        allSuppliers && allSuppliers.remarks
                                      }
                                      label="Remark"
                                      type="textarea"
                                      errorMessage={props.t("Please Enter Remark")}
                                      placeholder={props.t("Enter Remark")}
                                    />
                                  </Col>

                                  <Col lg={12}>
                                    <hr />
                                    <p>Tentat</p>
                                    <hr />
                                    <FormGroup className="mb-0 text-center">
                                      <div>
                                        <Button
                                          type="submit"
                                          color="primary"
                                          className="mr-1"
                                        >
                                          "Submit"
                                        </Button>{" "}
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AvForm>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AddSupplier)))
