import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import {
  postSubmitForm,
} from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { showWarning } from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import logo from "../../assets/images/Logo.png"
import BackgroundImage from "../../assets/images/Background.png"
import CustomerDetails from "../../components/CustomerDetails"
import SpentDetails from "../../components/SpentDetails"
// import Flatpickr from "react-flatpickr"

const ListOfSortedCustomer = props => {

  const { SearchBar } = Search
  const [disabledStatus, setDisabledStatus] = useState(true)
  const [minDateVal, setMinDateVal] = useState("")
  const [top20Customers, setTop20Customers] = useState([])
  const code = localStorage.getItem("code")
  //for pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [type, setType] = useState("");
  const [fromdate, setFromdate] = useState(new Date())
  const [todate, setTodate] = useState(new Date())
  const refContainer = useRef(null)

  const handleDownload = async () => {
    const fileName = "Sorted Customers by Amount Paid, No of visits"
    const exportType = "xls"

    // let data_to_export = top20Customers
    let data_to_export = type !== ""
      ? top20Customers.filter(item => item.customer_type === type)
      : top20Customers

    if (data_to_export) {
      var data = []
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          // Serial_Number: index + 1,
          Customer_Name: v.customer_details.name,
          Mobile: v.customer_details.mobile,
          Type: v.customer_details.customer_type,
          createdBY: v.customer_details?.customerCreatedBy?.employee_name,
          createdOn: moment(v.customer_details?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          Visits: v.visit_count,
          Amount_Paid: v.total_paid,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }


  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    const imageHeight = "105px";
    const imageWidth = "110px";
    const borderradius = "50%";
    const background = "#000000";
    printWindow.document.write(`
   
       <html>
        <title> Rendezvous - Game Parlour-${code}</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
        
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
         .hide{
            display:none;
         }
         .react-bootstrap-table-pagination {
            display: none; 
        }
        .heading{
            display: flex;
            align-items: center;
        }
        h2 {
            margin: 0; 
        }
        #logoImg {
            height: ${imageHeight};
            width: ${imageWidth};
            background-color: ${background};
            border-radius: ${borderradius};
            margin-right: 60px; 
        }
        </style>
       </head>
    <body>
   <div class="heading">

   <img src=${logo} id="logoImg" alt="Logo" >
   <h2>  List of Customer sorted by Amount paid and visits </h2>
   </div>
  ${content.innerHTML}
    </body>
       </html>

    `);
    printWindow.document.close();
    const logoImg = printWindow.document.getElementById('logoImg');
    logoImg.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  useEffect(() => {
    handleValidSubmit()
  }, [])

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  )

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page)
      setPageSize(sizePerPage)
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: top20Customers && top20Customers.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  }


  const loadTop20Customers = async (payload) => {
    let url =
      process.env.REACT_APP_BASEURL +
      "reports/customers_sorted_by_payment_and_visits"
    const response = await postSubmitForm(url, payload)
    if (response && response.status === 1) {
      // console.log(response.data, 'top 20 customer')

      setTop20Customers(prev => (prev = response.data))
    } else {
      showWarning(response.message, "warning")
    }
  }

  const handleValidSubmit = async () => {
    const object = {
      from_date: moment(fromdate).format("YYYY-MM-DD"),
      to_date: moment(todate).format("YYYY-MM-DD"),
    }
    let dateFrom = moment(fromdate);
        let dateTo = moment(todate);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
    loadTop20Customers(object)
  }

  // view customer details
  const [selectedRow, setSelectedRow] = useState({})
  const [detailsModal, setDetailsModal] = useState(false)
  function viewDetails(cell, row) {
    return (
      <i
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => {
          setDetailsModal(true)
          setSelectedRow(row.customer_details)
        }}
      >
        {row.customer_details.name}
      </i>
    );
  }

  //view spent details
  const [spentModal, setSpentModal] = useState(false)
  function viewSpentDetails(cell, row) {
    return (
      <i
        style={{ color: "blue", cursor: "pointer", justifyContent: "right", display: 'flex' }}
        onClick={() => {
          setSpentModal(true)
          setSelectedRow(row.spent_details)
        }}
      >
        {row.total_paid.toFixed(2)}
      </i>
    );
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1)
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "0.5%" }
      },
      footer: false
    },
    {
      text: props.t("Customer Name"),
      dataField: "customer_details.name",
      formatter: viewDetails,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%" }
      },
      footer: false
    },

    {
      text: props.t("Customer Type"),
      dataField: "customer_details.customer_type",
      filter: selectFilter({
        style: { marginLeft: "5px" },
        options: [
          { value: "VIP", label: "VIP" },
          { value: "Regular", label: "Regular" },
          { value: "Friend", label: "Friend" },
        ],
        placeholder: "ALL",
        onFilter: (value) => {
          setType(value)
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },
      footer: false
    },
    {
      text: props.t("Created By"),
      dataField: "customer_details.customerCreatedBy.employee_name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" }
      },
      footer: false
    },
    {
      text: props.t("Created On"),
      dataField: "createdAt",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" }
      },
      formatter: (cell, row) => {
        return moment(row.customer_details?.createdAt).format("YYYY-MM-DD HH:mm:ss")
      },
      footer: false
    },
    {
      text: props.t("Visit Count"),
      dataField: "visit_count",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
      formatter: (cell, row) => {
        return (
        <span>
          {row.visit_count}
        </span>
        )
      },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
    },

    {
      text: props.t("Amount Paid"),
      dataField: "total_paid",
      formatter: viewSpentDetails,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
      footerStyle: (colum, colIndex) => {
        return { display: "flex", justifyContent: "right" }
      },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }



  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="List Of Customers"
            breadcrumbItem1="List Of Customers"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm
                    onValidSubmit={handleValidSubmit}
                    ref={refContainer}
                  >
                    <Row>
                      <Col md={3}>
                        <label className="form-label">From Date</label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={fromdate}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          onChange={v => {
                            const dateString = v
                            setFromdate(new Date(dateString))
                            const date = new Date(dateString)

                            // Extract the date components
                            const day = date.getDate()
                            const month = date.getMonth() + 1 // Months are zero-based, so add 1
                            const year = date.getFullYear()
                            const formattedDate = `${year}-${month
                              .toString()
                              .padStart(2, "0")}-${day
                                .toString()
                                .padStart(2, "0")}`
                            setMinDateVal(formattedDate)
                            setDisabledStatus(false)
                          }}
                          name="from_date"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col md={3}>
                        <label className="form-label">To Date</label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={todate}
                          onChange={v => {
                            const dateString = v
                            setTodate(new Date(dateString))
                          }}
                          options={{
                            minDate: minDateVal,
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="to_date"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col
                        md={4}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label
                          style={{ height: "0px" }}
                          className="form-label"
                        ></label>
                        <Button
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "128px",
                            marginTop: "1rem",
                            // margin: "auto",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          type="submit"
                          // color="primary"
                          // className="btn btn-info btn-md"
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          Show
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t(
                          "List of customer sorted by Amount Paid, No of visits"
                        )}
                      </Col>

                      <Col
                        lg={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                          className="btn btn-sm btn-secondary"
                          disabled={top20Customers.length <= 0}
                          onClick={() => { printDiv("printDiv") }}
                        >
                          <i
                            className="fa fa-print " aria-hidden="true"
                            onClick={() => { printDiv("printDiv") }}
                          />&nbsp; {props.t("Print")}
                        </Button>
                        <Button
                          style={{
                            width: "115px",
                            height: "35px",
                            background: "#F93A5C",
                            border: "none",
                          }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={top20Customers.length <= 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>

                  <div id="printDiv">
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={top20Customers || []}
                      search
                    >
                      {propst => (
                        <div>
                          <AvForm>
                            <Row className="hide">
                              <Col
                                lg={7}
                                style={{
                                  display: "flex",
                                  aligntop20Customers: "flex-start",

                                  gap: "30px"
                                }}
                              >
                                <SearchBar
                                  {...propst.searchProps}
                                />
                                <Col lg={3}>

                                </Col>
                              </Col>
                            </Row>
                          </AvForm>
                          <BootstrapTable
                            {...propst.baseProps}
                            filter={filterFactory()}
                            noDataIndication={props.t("No data to display.")}
                            striped
                            hover
                            condensed
                          // pagination={paginationFactory(options)}
                          />
                        </div>
                      )}
                    </ToolkitProvider>

                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* ---Customer Modal start---  */}
          <CustomerDetails
            isOpen={detailsModal}
            details={selectedRow}
            toggle={() => setDetailsModal(false)}
          />
          {/* ---Customer Modal end---  */}

          {/* ---Spent Details Modal start---  */}
          <SpentDetails
            isOpen={spentModal}
            details={selectedRow}
            toggle={() => setSpentModal(false)}
          />
          {/* ---Spent Details Modal end---  */}


        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  connect(null, {})(withTranslation()(ListOfSortedCustomer))
)
