import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, Button, Label, CardTitle } from "reactstrap";
import { withTranslation } from "react-i18next"
import { AvForm, AvField, AvRadio } from "availity-reactstrap-validation";
import { postSubmitForm } from "../../../helpers/backend_helper";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from 'react-bootstrap-table2-editor';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// Redux
import { connect } from "react-redux";
import withRouter from "../../../components/Common/withRouter";
import preloader from "../../../helpers/Loader";
import showNotification, { showWarning } from "../../../helpers/show_notificaton";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr";
import moment from "moment";
import ReactSelect from "react-select";

const Closure = (props) => {


    const [closureDetails, setClosureDetails] = useState();
    const [cashInHand, setCashInHand] = useState();
    const [remarkss, setRemarkss] = useState("");
    const [tracker, setTracker] = useState(false)
    const [cashBreakup, setCashBreakUp] = useState([])


    const [selectedDate, setSelectedDate] = useState()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get("Date")) {
            setSelectedDate(urlParams.get("Date"))
            loadClosureDetailsByOwner({ date: urlParams.get("Date"),source:"get_daily_closer" })
        }else{
            setSelectedDate(new Date().toISOString().split('T')[0])
            loadClosureDetails();
        }
      }, [])

      const loadClosureDetailsByOwner = async (payload) => {
        try {
            preloader(true);
            let url = process.env.REACT_APP_BASEURL + "reports/daily_closure_details";
            const response = await postSubmitForm(url,payload);

            if (response && response.status === 1) {
                preloader(false);
                setCashInHand(response.data.cash_in_hand)
                setCashBreakUp(response.data.cashier_break_up)
                setTracker(false)
                setClosureDetails(response.data);
            } else {
                preloader(false);
                showNotification("Something went wrong!", "Error");
            }
        } catch (error) {
            preloader(false);
            console.log(error, "Error");
        }
    };

    useEffect(() => {

        tracker ? loadClosureDetails() : null
    }, [tracker]);

    const handleAfterSaveCell = (oldValue, newValue, row, column) => {
        if (oldValue !== newValue) {
            const updatedData = cashBreakup.map(item => {
                if (item._id === row._id) {
                    return { ...item, [column.dataField]: column.dataField === 'cash_in_hand' ? Number(newValue) : newValue };
                }
                return item;
            });

            setCashBreakUp(updatedData);
        }
    };
    const checkCashBreakup = () => {
        for (const cashier of cashBreakup) {
            if (cashier.cash_in_hand < cashier.actual_cash - cashier.change_amount && cashier.remarks === "") {
                showWarning(`Remarks are required for ${cashier._id} if the cash in hand is less than the actual cash after subtracting the change amount.`, "Warning");
                return false;
            }

        }
        return true;
    };

    const loadClosureDetails = async () => {
        try {
            preloader(true);
            let url = process.env.REACT_APP_BASEURL + "reports/daily_closure_details";
            const response = await postSubmitForm(url, {
                date: moment(selectedDate).format("YYYY-MM-DD"),
                source: "get_daily_closer"
            });

            if (response && response.status === 1) {
                preloader(false);
                setCashInHand(response.data.cash_in_hand)
                setCashBreakUp(response.data.cashier_break_up)
                // setDate(new Date())
                setTracker(false)
                setClosureDetails(response.data);
                // const wallet = response.data.wallet_transactions
                // setResultTransactuion( Object.groupBy(wallet, ({ transaction_type }) => transaction_type))
            } else {
                preloader(false);
                showNotification("Something went wrong!", "Error");
            }
        } catch (error) {
            preloader(false);
            console.log(error, "Error");
            // showNotification(error, "Error");
        }
    };

    const saveDailyCloser = async () => {
        if (!checkCashBreakup()) {
            return
        }
        let payload = {
            date: moment(selectedDate).format("YYYY-MM-DD"),
            source: "",
            cash_details: cashBreakup?.map((item) => {
                return {
                    cashier: item._id,
                    actual_cash: item.actual_cash,
                    actual_card: item.actual_card,
                    cash_in_hand: item.cash_in_hand,
                    change_amount: item.change_amount,
                    remarks: item.remarks,
                }
            }),
            remarkss: remarkss,
            cash_in_hand: cashBreakup.reduce((acc,curr)=>acc+curr.cash_in_hand,0)
        }
        let url = process.env.REACT_APP_BASEURL + "reports/daily_closure_details"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            // setUnderStockItems([response.data])
            showNotification(response.message, "Success")
            setCashInHand()
            setCashBreakUp([])
            setRemarkss("")
            loadClosureDetails();
        } else {
            showNotification(response.message, "Error")
        }
    }

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false,
            footer: false
        },
        {
            text: props.t("Cashier"),
            dataField: "_id",
            formatter:(cell,row)=>{
                return closureDetails.is_saved ? row.cashier : row._id
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
            editable: false,
            footer: false
        },
        {
            text: props.t("Actual Cash"),
            dataField: "actual_cash",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        },
        {
            text: props.t("Change Amount"),
            dataField: "change_amount",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: closureDetails?.is_saved ? false : false,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        },

        {
            text: props.t("Cash in hand"),
            style: (cell, row, rowIndex, colIndex) => {
                if (row.actual_cash - row.change_amount > row.cash_in_hand && !closureDetails?.is_saved) {
                    return { background: "#FFD4E6" };
                }
                return {};
            },
            editCellStyle: (cell, row, rowIndex, colIndex) => {
                const backgroundColor = row.actual_cash - row.change_amount > row.cash_in_hand ? '#FFD4E6' : "";
                return { backgroundColor };
            },
            dataField: "cash_in_hand",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: closureDetails?.is_saved ? false : true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        },
        {
            text: props.t("Card"),
            dataField: "actual_card",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
        },
        {
            text: props.t("Remarks"),
            dataField: "remarks",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: closureDetails?.is_saved ? false : true,
            footer: false,
        },
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Closure")}
                        breadcrumbItem1={props.t("Submit Closure")}
                    />
                    <AvForm
                        onValidSubmit={saveDailyCloser}>
                        <Card>
                            <CardBody>
                                <Col lg={12}>
                                    <Row>
                                        <Col lg={3}>
                                            <AvField
                                                className="form-control"
                                                type="select"
                                                tag={Flatpickr}
                                                value={selectedDate}
                                                options={{
                                                    onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                        dayElem.addEventListener('mousedown', (e) => {
                                                            e.preventDefault(); // Prevent default click behavior
                                                        });
                                                    },
                                                    maxDate: "today",
                                                }}
                                                onChange={(v) => {
                                                    const dateString = v;
                                                    const date = new Date(dateString);
                                                    setSelectedDate(date);
                                                    setTracker(true)

                                                }}
                                                name="from_date"
                                                // placeholder="Select Date"
                                                errorMessage="Select Date"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </Col>
                                        <Col lg={3}>


                                            {closureDetails?.is_saved === true ?
                                                (
                                                    <a
                                                        style={{
                                                            position: "relative",
                                                            overflow: "hidden",
                                                            backgroundColor: "#fdd005",
                                                            border: "none",
                                                            marginBottom: "10px",
                                                            color: "black",
                                                            fontSize: "15px",
                                                            fontWeight: "500",
                                                            width: "190px",
                                                            boxShadow:
                                                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                            transition: "transform 0.3s ease",
                                                        }}

                                                        className="btn btn-primary mr-1"
                                                        href={
                                                            "/closure-print?closure=" + moment(selectedDate).format('YYYY-MM-DD')
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <i className="mdi mdi-printer"></i>
                                                        {props.t(" Print Closure")}


                                                    </a>
                                                ) :
                                                (<Button
                                                    type="submit"
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        marginBottom: "10px",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "190px",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                    disabled={closureDetails?.is_saved === true}
                                                // onClick={saveDailyCloser}
                                                >
                                                    <i className="mdi mdi-content-save" /> &nbsp;
                                                    {props.t("Save Closure")}
                                                </Button>)
                                            }
                                            <br />
                                            {/* {closureDetails?.is_saved===true && (
                                                <span style={{color:"red", }}>
                                                Already Saved for Selected Date
                                            </span>
                                            )} */}

                                        </Col>
                                        <Col lg={6}>
                                        <Label style={{fontSize:"14px" ,fontWeight:"bold"}}>
                                            Total Income &nbsp; = &nbsp; Sum of all sales <br />
                                            Net Income &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  = &nbsp; Sum of all sales - Refunds + Extra Amount 
                                        </Label>
                                        </Col>
                                    </Row>
                                </Col>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col sm="3">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4D4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Income")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails.total_income % 1 !== 0 ? closureDetails?.total_income.toFixed(2) : closureDetails?.total_income }
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="3">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4D4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Net Income")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails.total_net_income  }
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Cash Payment")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails.paid_by_cash % 1 !== 0 ? closureDetails?.paid_by_cash.toFixed(2) : closureDetails?.paid_by_cash }
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-100 font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Card Payment")}
                                                </p>
                                                <h4 className="mb-0">
                                
                                                    QR {closureDetails?.paid_by_card?closureDetails?.paid_by_card:0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-credit-card font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Google Pay")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR &nbsp;
                                                    {closureDetails?.paid_by_googlePay?closureDetails?.paid_by_googlePay:0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-google-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Apple Pay")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR &nbsp;
                                                    {closureDetails?.paid_by_applePay?closureDetails?.paid_by_applePay:0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-multiple font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Extra Amount")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {closureDetails &&
                                                        closureDetails?.total_difference_amount+closureDetails.total_extra_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-plus font-size-24"></i>                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Extra Amount (from bill split)")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {closureDetails &&
                                                        closureDetails?.total_extra_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-plus font-size-24"></i>                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Wallet Credits")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR &nbsp;
                                                    {/* {resultTransactuion?.Credit?.length ||0} */}
                                                    {closureDetails && closureDetails.wallet_credit_total}
                                                </h4>
                                            </div>
                                            <div
                                                className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-wallet font-size-20"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            
                           {/* Refund amount */}
                           <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#FFD4E6" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Total Refund ")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails.total_refund}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-minus font-size-24"></i>                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#FFD4E6" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Total Change")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails.total_change_amount}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-minus font-size-24"></i>                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#FFD4E6" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Wallet Debits")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR &nbsp;
                                                    {/* { resultTransactuion&& resultTransactuion.Debit?.length || 0} */}
                                                    {closureDetails && closureDetails.wallet_debit_total}
                                                </h4>
                                            </div>
                                            <div
                                                className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-wallet font-size-20"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Customer Visits")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {closureDetails && closureDetails.customers_visited}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span
                                                    className="avatar-title"
                                                // style={{ backgroundColor: "#ec4561" }}
                                                >
                                                    <i className="mdi mdi-clipboard-account font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                          

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Item Sold")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails?.products_sold % 1 !== 0 ? closureDetails?.products_sold.toFixed(2):closureDetails?.products_sold}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-multiple font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Game Sale")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails && closureDetails?.games_sale % 1 !== 0 ? closureDetails?.games_sale.toFixed(2):closureDetails?.games_sale}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-multiple font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Package Sold")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {closureDetails?.package_sold?closureDetails?.package_sold:0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-multiple font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4fffb" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Completed Bookings")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {closureDetails &&
                                                        closureDetails?.completed_bookings}
                                                </h4>
                                            </div>
                                            <div

                                                className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                                            >
                                                <span
                                                    className="avatar-title"
                                                    style={{ backgroundColor: "red" }}
                                                >
                                                    <i className="bx bx-timer font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4fffb" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Cancelled Bookings")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {closureDetails &&
                                                        closureDetails?.cancelled_bookings}
                                                </h4>
                                            </div>
                                            <div

                                                className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                                            >
                                                <span
                                                    className="avatar-title"
                                                    style={{ backgroundColor: "red" }}
                                                >
                                                    <i className="bx bx-timer font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col sm="3">
                                <div
                                    className="mini-stats-wid card"
                                    style={{ background: "#d4fffb" }}
                                >
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted font-weight-bold">
                                                    {props.t("Discount")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {closureDetails &&
                                                        closureDetails?.total_discount_amount}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-plus font-size-24"></i>                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={12}>
                                <CardTitle>
                                    <Label>{props.t("Cashier Breakup")}</Label>
                                </CardTitle>

                                <BootstrapTable
                                    keyField="_id"
                                    columns={columns}
                                    // data={cashierData}
                                    data={cashBreakup || []}
                                    noDataIndication={props.t("No data to display.")}
                                    rowStyle={{ height: '10px' }}
                                    cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: handleAfterSaveCell })}

                                />

                            </Col>
                        </Row>
                    </AvForm>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(connect(null, {})(withTranslation()(Closure)));
