function AddQuantity(
  item,
  unit,
  subUnit,
  setGrandTotal,
  selectedCustomer,
  setSelectedItemPrice
) {
  unit = +unit
  subUnit = +subUnit
  // console.log(item, "selected item");
  // console.log(unit, subUnit, "all unit input")
  item[0].quantity.in_unit = unit
  item[0].quantity.in_sub_unit = subUnit

  if (selectedCustomer?.type === "VIP") {
    return
  }

  if (item[0].sub_unit === "" || item[0].sub_unit === null) {
    item[0].sub_total = +unit * item[0].selling_price
    item[0].sub_total = +item[0].sub_total.toFixed(2)
    setGrandTotal(prev => (prev += +item[0].sub_total))
    setSelectedItemPrice(prev => (prev += +item[0].sub_total))
  } else {
    // console.log("hello")
    let totalSubUnit = unit * item[0].formula + subUnit
    // console.log(totalSubUnit, 'total sub unit')
    item[0].sub_total = +(totalSubUnit * item[0].selling_price)
    item[0].sub_total = +item[0].sub_total.toFixed(2)
    //console.log("item[0].sub_total", item[0].sub_total)
    setGrandTotal(prev => (prev += +item[0].sub_total))
    setSelectedItemPrice(prev => (prev += +item[0].sub_total))
  }

  //   return item;
}

export default AddQuantity
