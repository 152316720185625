import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../../helpers/backend_helper.js"
import moment from "moment"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useState()

  const role = localStorage.getItem("role")

  useEffect(() => {
    loadAllNotifications()
  }, [])

  // useEffect(()=>{
  //  if(role==="Kitchen" || role==="cafeteria"){
  //   const interval = setInterval(()=>{
  //     loadAllNotifications()
  //   // },60000); 
  //   return () => clearInterval(interval);
  //   },5000); 
  // }

  // },[])
  function findDateInSentence(sentence) {
    const datePattern = /\b(\d{4}[-\/]\d{2}[-\/]\d{2})|(\d{2}[-\/]\d{2}[-\/]\d{4})\b/g;    
    const matches = sentence.match(datePattern);
        if (matches) {
        return matches;
    } else {
        return "No dates found in the sentence.";
    }
}

  //All Notification
  const loadAllNotifications = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/get_last_ten"
    let response = await postSubmitForm(url, {})
    if (response.status === 1) {
      setNotifications(response.data)
      // console.log(notifications)
    } else {
      console.log("Error")
    }
  }

  const handleSetNotificationsRead = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/set_read"
    const response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      loadAllNotifications()
    } else {
      console.log("Error")
    }
  }

  const getLink = (notification) => {
    let pageLink = ""
    if (role === "Cashier" || role === "Manager"||role === "Admin"||role==="Owner") {
      switch (notification.action) {
        case "Booking Received":
          pageLink = "/all-booking"
          break;
          case "Booking Updated": pageLink = "/add-reservation"
          break;
          case "refund processed successfully": pageLink = `/refund_invoice?booking_id=` + notification.others
          break;
          case "Check In Successful":pageLink= "/add-reservation"
          break;
          case "Booking Cancel": pageLink = "/add-reservation"
          break;
          case "Check Out Successful": pageLink = "/all-booking"
          break;
          case "Daily Closure with Remarks" : 
          let Date = findDateInSentence(body)
          pageLink = "/daily_closure?Date="+Date
          break;
      }
    }
    if (role === "Kitchen") {
      switch (notification.action) {
        case "Kitchen Order":
          pageLink = "/new-orders?type="+"Kitchen"
          break;
      }
    }
    if (role === "Cafeteria") {
      switch (notification.action) {
        case "Cafe Order":
          pageLink = "/new-orders?type="+"Cafe"
          break;
      }
    }
    return pageLink;
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i
            className={
              notifications && notifications.unread_notifications === 0
                ? "bx bx-bell"
                : "bx bx-bell bx-tada"
            }
            // onClick={handleSetNotificationsRead}
          />
          <span className="badge bg-danger rounded-pill">
            {notifications && notifications?.unread_notifications === 0
              ? ""
              : notifications?.unread_notifications}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>
          {notifications && notifications.notifications.length > 0 ? (
            <>
              <SimpleBar style={{ height: "230px" }}>
                {notifications.notifications &&
                  notifications.notifications.map((notification, ind) => {
                    return (
                      <>
                      {notification.action==="refund processed successfully" ? (
                        <a
                          onClick={() => {
                            setMenu(prev => (prev = !prev))
                            handleSetNotificationsRead()
                          }}
                          href={getLink(notification)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-reset notification-item"
                        >
                          <div
                            className={
                              notification.is_read === true
                                ? "d-flex bg-danger text-dark bg-opacity-25"
                                : "d-flex bg-success text-dark bg-opacity-10"
                            }
                          >
                            <Row>
                            <Col md={2} sm={2} lg={2} xl={2}>
                            <div className="avatar-xs me-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-cart" />
                            </span>
                          </div>
                            </Col>
                            <Col  md={10} sm={10} lg={10} xl={10}>
                            <h6 className="mt-0 mb-1">
                              {notification.title}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">{notification.body}</p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i>{" "}
                                {moment(notification.createdAt).fromNow()}{" "}
                              </p>
                            </div>
                            </Col>

                          </Row>
                          </div>
                        </a>
                      ):(<Link
                        onClick={() => {
                          setMenu(prev => (prev = !prev))
                          handleSetNotificationsRead()
                        }}
                        to={getLink(notification)}
                        className="text-reset notification-item"
                      >
                        <div
                          className={
                            notification.is_read === true
                              ? "d-flex bg-danger text-dark bg-opacity-25"
                              : "d-flex bg-success text-dark bg-opacity-10"
                          }
                        >
                          <Row>
                            <Col md={2} sm={2} lg={2} xl={2}>
                            <div className="avatar-xs me-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-cart" />
                            </span>
                          </div>
                            </Col>
                            <Col  md={10} sm={10} lg={10} xl={10}>
                            <h6 className="mt-0 mb-1">
                              {notification.title}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">{notification.body}</p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i>{" "}
                                {moment(notification.createdAt).fromNow()}{" "}
                              </p>
                            </div>
                            </Col>

                          </Row>
                        </div>
                      </Link>)}
                        
                      </>
                    )
                  })}
              </SimpleBar>

              <div className="p-2 border-top d-grid text-center">
                <Link
                  onClick={() => setMenu(prev => (prev = !prev))}
                  className="btn btn-sm btn-link font-size-14 text-center"
                  to="/notification"
                >
                  <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                  <span key="t-view-more">{props.t("View All")}</span>
                </Link>
              </div>
            </>
          ) : (
           <>
            <SimpleBar style={{ height: "80px" }}>
              <Link to="" className="text-reset notification-item">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1"></h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">There are no notifications.</p>
                    </div>
                  </div>
                </div>
              </Link>
            </SimpleBar>
             <div className="p-2 border-top d-grid text-center">
             <Link
               onClick={() => setMenu(prev => (prev = !prev))}
               className="btn btn-sm btn-link font-size-14 text-center"
               to="/notification"
             >
               <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
               <span key="t-view-more">{props.t("View All")}</span>
             </Link>
           </div>
           </>
          )}
         
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
