import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Badge
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { postSubmitForm } from "../../helpers/backend_helper"
import moment from "moment"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader";
import logo from "../../assets/images/Logo.png"
import { useLocation } from "react-router-dom";



const NewOrders = props => {

  const { SearchBar } = Search
  const [viewModal, setViewModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState([]);
  const location = useLocation();

 

  useEffect(() => {
    const handleURLChange = () => {
      const urlParams = new URLSearchParams(window.location.search);
      let type = urlParams.get("type") 
      loadAllOrder(type)
    };
    handleURLChange();
    window.addEventListener('popstate', handleURLChange);
    return (()=> window.addEventListener('popstate', handleURLChange))
  }, [location]);

const filterData = (data ,type) => {

    return data
        .map(obj => ({
            ...obj,
            item_details: obj.item_details.filter(item => type === "Kitchen" ? item.section === 'Kitchen' :item.section === 'Cafe')
        }))
        .filter(obj => obj.item_details.length > 0);
};

  //getAll
  const [allOrder, setAll_Order] = useState([])
  const loadAllOrder = async (type) => {
   try {
    preloader(true)
     let url = process.env.REACT_APP_BASEURL + "bookings/get_item_orders"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      preloader(false)
      setAll_Order(filterData(response.data,type));
    //   setAll_Order(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
   } catch (error) {
    preloader(false)
    showNotification(error,'Error')
   }
  }

  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }

  function editFormatter(cell, row) {
    const urlParams = new URLSearchParams(window.location.search);
    let type = urlParams.get("type") 
    return (
      <>
        <span className="text-info">
            <i
          className="bx bxs-file font-size-15"
          title="View order details"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setViewModal(true)
            setSelectedOrder(row.item_details)
          }}
        ></i>
        </span>
         <span className="mr-2 text-info">
          
         <a
           href={type==="Kitchen"?"/items-invoice?Kitchen=" + row._id:"/items-invoice?Cafe=" + row._id}
           target="_blank"
           rel="noreferrer"
           className="font-size-11 ms-2"
           style={{ cursor: "pointer", color: "black" }}
         >
           <i
             className="mdi mdi-printer font-size-15"
             title="Print PO"
             style={{ cursor: "pointer" }}
           ></i>
         </a>
       </span>
      </>
    )
}

  const ItemDetails = [
    {
      dataField: "item_id",
      hidden: true
    },
    {
      text: props.t("S.N"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },

      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
        text: props.t("Section"),
        dataField: "section",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
    {
        dataField: "quantity.in_unit",
        text: "Quantity",
        formatter: (cell, row) => {
          if (row.quantity?.in_unit > 0 || row.quantiy?.in_sub_unit > 0) {
            return (
              <>
                {row.quantity?.in_unit +
                  "  " +
                  row.unit +
                  " " +
                  (row.sub_unit && row.sub_unit != "undefined" && row.quantity.in_sub_unit > 0
                    ? row.quantity.in_sub_unit + " " + row.sub_unit
                    : "")}
              </>
            )
          } else {
            return "N/A"
          }
  
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        }
    },
    {
        text: props.t("Sub Total"),
        dataField: "sub_total",
        formatter: (cell, row) => {
            return row.sub_total?.toFixed(2)
          },
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },

  ]

  const Columns_External = [

    {
      dataField: "_id",
      hidden: true
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },

      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "0.5%" }
      },
    },
    {
        text: "Customer Name",
        dataField: "customer_details.name",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
      {
        text: "Check-In",
        dataField: "check_in",
        formatter: (cell, row) => {
          if (row.check_in) {
            return moment(row.check_in).format("YYYY-MM-DD HH:mm:ss")
          } else {
            return "Not yet checked in "
          }
        },
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
      {
        text: "Duration",
        formatter: (cell, row) => {
          const checkIn = moment(row.check_in)
          const endTime = moment(row.end_time)
          if (checkIn.isValid() && endTime.isValid()) {
            const duration = moment.duration(endTime.diff(checkIn))
            const hours = duration.hours()
            const minutes = duration.minutes()
            if (hours > 0) {
              return hours + " hours " + minutes + " minutes"
            } else if (minutes > 0) {
              return minutes + " minutes"
            } else {
              return "N/A"
            }
          } else {
            return "N/A"
          }
        },
        hidden: false,
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "2%" }
        },
      },
      {
        text: props.t("Booking Status"),
        dataField: "booking_status",
        // filter: selectFilter({
        //   options: [
        //     { value: "In Process", label: "In Process" },
        //     { value: "Unpaid", label: "Unpaid" },
        //   ],
        //   defaultValue: "In Process",
        //   placeholder: "All",
        // }),
        style: (cell, row) => {
          return {
            textAlign: "center",
          }
        },
        formatter: (cell, row) => {
          if (row.booking_status === "In Process") {
            return <Badge color="warning">{row.booking_status}</Badge>
          } else if (row.booking_status === "Unpaid") {
            return <Badge color="danger">{row.booking_status}</Badge>
          } else return <Badge >{row.booking_status}</Badge>
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
    {
      text: props.t("Ordered by"),
      dataField: "booking_received_by.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Order Date"),
      dataField: "updatedAt",
      sort: true,
      formatter: (cell, row) => {
        const timestamp = row.updatedAt;
        const formattedDate = moment(timestamp).format('YYYY-MM-DD');
        return formattedDate;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
        text: props.t("View Details"),
        formatter: editFormatter,
        dataField: "",
        headerStyle: (colum, colIndex) => {
            return { width: "1%" }
        },
    },
  ]

  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
       <>
            <BootstrapTable
              noDataIndication={props.t("No data to display.")}
              striped
              keyField='id'
              data={ row.item_details }
              columns={ ItemDetails }
              filter={filterFactory()}
            //   defaultSorted={defaultSorted}
              condensed 
            />
     
       </>
    ),
  }

  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Orders" breadcrumbItem1="In Process Orders" alternateTitle="In Process Orders" />
          <Row >
            <Col>
              <Card>
                <CardBody style={{boxShadow:"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}}>
               <div id="printDiv">
               <ToolkitProvider
                    keyField="_id"
                    data={allOrder||[]}
                    columns={Columns_External}
                    search
                  >
                    {propst => (
                      <div>
                        <Row className="hide">
                          <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                            <SearchBar
                              className="form-control"
                              {...propst.searchProps}
                              style={{ width: "340px" }}
                              placeholder="search"
                            />
                          </Col>

                        </Row>

                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          filter={filterFactory()}
                          expandRow={expandRow}
                          defaultSorted={defaultSorted}
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
               </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col>
          <Modal
            size="xl"
            isOpen={viewModal}
            toggle={() => setViewModal(!viewModal)}
          >
            <ModalHeader toggle={() => setViewModal(!viewModal)} >
              {props.t("Order Details")}
            </ModalHeader>
            <ModalBody>
              {selectedOrder && (
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="item_id"
                      data={selectedOrder}
                      columns={ItemDetails}
                    >
                      {propst => (
                        <div>
                          <BootstrapTable
                            {...propst.baseProps}
                            // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                            noDataIndication={props.t("No data to display.")}
                            striped
                            filter={filterFactory()}
                            hover
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              )}
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(NewOrders)))
