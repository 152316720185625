import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Row,
    Badge,
    Container,
    Input,
    FormGroup,
    Label,
    CardTitle,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { setDefaultLocale } from "react-datepicker";
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { func, object } from "prop-types";
import Flatpickr from "react-flatpickr"
import moment from "moment";



const PromotionalGoods = (props) => {

    // const [editModal, setEditModal] = useState();
    const { SearchBar } = Search

    const [rowId, setRowId] = useState("")
    const [row, setRow] = useState()
    const [count, setCount] = useState(0);
    const [arr, setArr] = useState([])
    const [expiryDate, setExpiryDate] = useState("");
    const [rowIndex, setRowIndex] = useState([])

    const { setAddedItems, addedItems, singleItemId, promotionalGoods, selectedPO, cameWithRow, setPromotionalGoods } = props;


    // console.log(promotionalGoods, "promoo goods")

    useEffect(() => {

        setPromotionalGoods(prev => prev = { ...prev, [selectedPO._id]: prev[selectedPO._id] ? [...prev[selectedPO._id]] : [] })
        if (row) {

            let newTableArr = promotionalGoods[selectedPO._id]?.filter(item => item.uniqueId !== row.uniqueId)
            // let newTableArr = []
            // let flag = false;
            // for (let i = 0; i < promotionalGoods[selectedPO._id].length; i++) {

            //     if (promotionalGoods[selectedPO._id][i]._id === row._id && flag === false) {
            //         flag = true;

            //     } else {
            //         newTableArr.push(promotionalGoods[selectedPO._id][i])
            //     }
            // }
            // let newAddedItems = addedItems[selectedPO._id];

            // console.log(newAddedItems, "new added items")
            // if (newAddedItems) {

            //     delete newAddedItems[row.name]
            // }


            // console.log(newTableArr, "new arrat after deletion")

            // if (newTableArr.length === 0) {
            //     setPromoTableStatus(prev => prev = { ...prev, [selectedPO._id]: false })
            // }

            setPromotionalGoods(prev => prev = { ...prev, [selectedPO._id]: newTableArr })
            // setAddedItems(prev => prev = { ...prev, newAddedItems })

        }





    }, [row, expiryDate])

    // const handleDateChange = (e, rowIndex, row, editorProps) => {
    //     // console.log(data,"data")
    //     const { value } = e.target;
    //     // console.log(e.target.value, "chnaged date")
    //     editorProps.onUpdate(value);
    // };


    // function to prevent value to change on scroll in input field with type number
    function preventValueChangeOnScroll(e) {

        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current     
        // function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }
    // ------------------------------------------------------------------------------







    function deleteFormatter(cell, row) {

        return (
            <i
                className="fa fa-trash fs-5 ms-3" aria-hidden="true"
                // title="Send Email"
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => {

                    setRow(row)
                }}
            ></i>
        )
    }



    function expiryDateFormatter(cell, row, rowIndex) {

        return (
            <>
                {row.is_expiry_date === false ? <p>No Expiry</p> :
                    <Flatpickr style={{ border: "1px solid lightGray", borderRadius: "5px", backgroundColor: "rgb(248 249 250)", width: "100px" }} onChange={(date) => {

                        setExpiryDate(prev => prev = moment(date[0]).format('YYYY-MM-DD'));
                        setRowIndex(prev => prev = rowIndex)
                        row.expiry_date = moment(date[0]).format('YYYY-MM-DD')
                    }} />}
            </>
        )
    }

    const handleQuantityChange = (e, rowIndex, row, editorProps) => {

        const { value } = e.target;
        const updatedData = { ...row };
        const rowData = updatedData;
        rowData.quantity.in_unit = +value;


        editorProps.onUpdate(+value);


    };

    // modal table columns accessory items
    const promotionalGoodsColumn = [
        {
            dataField: "uniqueId",
            hidden: true
        },
        {
            text: props.t("#"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Came With"),
            formatter: (cell, row) => {
                return (
                    // <p>{cameWithRow[singleItemId].name}</p>
                    <p>{row.cameWith}</p>
                )
            },
            dataField: "cameWith",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Name"),
            dataField: "name",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Section"),
            dataField: "section",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Category"),
            dataField: "category",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Unit"),
            dataField: "unit",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Sub-Unit"),
            dataField: "sub_unit",
            formatter: (cell, row) => {
                return <p>{(row.sub_unit === null || row.sub_unit === "") ? <Badge className="badge-soft-danger" >No Sub-Unit</Badge> : `${row.sub_unit}`}</p>;
            },
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Formula"),
            dataField: "formula",
            sort: true,
            editable: false,
            formatter: (cell, row) => {
                return (
                    <>
                        {"1 " + row.unit + " = " + row.formula + row.sub_unit}
                    </>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { width: "10%" }
            },
        },
        // {
        //     text: props.t("Quantity"),
        //     // formatter: (cell, row) => {
        //     //   return (
        //     //     <p>{`${row.quantity.in_unit} ${row.unit}`}</p>
        //     //   )
        //     // },
        //     dataField: "in_unit",
        //     sort: true,
        //     editable: true,
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "5%" }
        //     },
        // },
        {
            text: props.t("Cost Price"),
            formatter: (cell, row) => {
                return (
                    <p>0</p>
                )
            },
            dataField: "price",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Selling Price"),
            dataField: "selling_price",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Total"),
            dataField: "total_price",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("In Unit"),
            dataField: "receive.in_unit",
            formatter: (cell, row) => {
                return <p style={{ color: +row.receive.in_unit === 0 ? "#E57373" : "", fontWeight: +row.receive.in_unit === 0 ? 600 : 0, width: "50px" }} >{`${row.receive.in_unit} ${row.unit}`}</p>;
            },
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{ width: "50px", height: "40px", border: "1px solid lightGray", padding: "5px", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    type="number"
                    border={`1px solid ${value === 0 ? 'red' : 'lightGray'}`}
                    defaultValue={value}
                    // onChange={(e) => handleQuantityChange(e, rowIndex,row,editorProps)}
                    onBlur={(e) => {
                        // handleQuantityChange(e, rowIndex, row, editorProps);
                        editorProps.onUpdate(+e.target.value)
                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } }) 
                    }}
                    onFocus={(e) => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            sort: true,
            editable: true,
            attrs: (cell, row, rowIndex, colIndex) => {
                return { title: "Click to Edit" };
            },
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            // style: (cell, row, rowIndex, colIndex) => {
            //   return { cursor: "pointer", background: "#ffffaa" };
            // },
        },
        {
            text: props.t("In Sub-Unit"),
            dataField: "receive.in_sub_unit",
            // formatter: (cell, row) => {
            //     return <p style={{ color: +row.receive.in_sub_unit === 0 ? "#E57373" : "", fontWeight: +row.receive.in_sub_unit === 0 ? 600 : 0, width: "50px" }} >{`${row.receive.in_sub_unit} ${row.sub_unit}`}</p>;
            // },
            formatter: (cell, row) => {
                return <p style={{ color: row.receive.in_sub_unit === 0 ? "#E57373" : "", fontWeight: row.receive.in_sub_unit === 0 ? 600 : 0, width: "50px" }} >{(row.sub_unit === "" || row.sub_unit === null) ? <i className="fa fa-ban" /> : `${row.receive.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            sort: true,
            editable: (cell, row) => (row.sub_unit === "" || row.sub_unit === null) ? false : true,
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{ width: "50px", height: "40px", border: "1px solid lightGray", padding: "5px", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    type="number"
                    border={`1px solid ${value === 0 ? 'red' : 'lightGray'}`}
                    defaultValue={value}
                    // onChange={(e) => handleQuantityChange(e, rowIndex,row,editorProps)}
                    onBlur={(e) => {
                        // handleSubUnitQuantityChange(e, rowIndex, row, editorProps);
                        editorProps.onUpdate(+e.target.value)
                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } }) 
                    }}
                    onFocus={(e) => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),

            validator: (newValue, row, column) => {
                if (+newValue >= row.formula) {
                    // localStorage.setItem("in_unit_validation", true)
                    return {
                      valid: false,
                      message: "cannot exceed more than formula",
                    }
                  }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (+newValue % 1 != 0) {
                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            attrs: (cell, row, rowIndex, colIndex) => {
                return { title: "Click to Edit" };
            },
            // style: (cell, row, rowIndex, colIndex) => {
            //   return { cursor: "pointer", background: "#ffffaa" };
            // },
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },



        {
            text: props.t("Expiry Date"),
            // dataField: "expiry_date",
            sort: true,
            editable: false,
            formatter: expiryDateFormatter,

            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },

        {
            text: props.t("Action"),
            formatter: deleteFormatter,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
    ]

    return <>
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>{props.t("Promotional Items")} </CardTitle>
                        <ToolkitProvider
                            keyField={"uniqueId"}
                            data={promotionalGoods[selectedPO._id] || []}
                            columns={promotionalGoodsColumn}
                        >
                            {propst => (
                                <div>
                                    <BootstrapTable
                                        {...propst.baseProps}
                                        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                    />
                                </div>
                            )}
                        </ToolkitProvider>


                    </CardBody>
                </Card>
            </Col>


        </Row>
    </>
}

export default withRouter(connect(null, {})(withTranslation()(PromotionalGoods)))