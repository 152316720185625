import React, { useState, useEffect, useRef } from "react"
import axios from "axios"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  Button,
  CardTitle,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { AvField, AvForm } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import { sortBy } from "lodash"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { formatDate } from "@fullcalendar/core"
import moment from "moment"
import BackgroundImage from "../../assets/images/Background.png"
const Apply_Loan = (props) => {

  useEffect(()=>{
    loadAllLoan()
    // loadAllEmployees()
  },[])

  const { SearchBar } = Search

  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const employee_id = localStorage.getItem("employee_id")
  const employee_name = localStorage.getItem("name")
  const refContainer = useRef(null);
  const [loanAmount, setLoanAmount] = useState(0);
  const [tenureDuration, setTenureDuration] = useState(0);

  const [allLoan, setAllLoan] = useState([])
 
  //getALL
  // console.log("All Loan", allLoan)
  const loadAllLoan = async (e,v) => {
    let url = process.env.REACT_APP_BASEURL + "loans/get_by_employee"
    //let url = "http://localhost:3002/loans/get_by_employee"
    let response = await postSubmitForm(url, {
      employee_id: employee_id,
    })
    if (response.status === 1) {
      // console.log(response.data,"loan data")
      setAllLoan(response.data)
    } else {
     // showNotification(response.message, "Error")
    }
  }

  // //getting employee code
  // const [employeeCode, setEmployeeCode] = useState([])
  // console.log(employeeCode)
  // const loadAllEmployees = async () => {
  //   let url = process.env.REACT_APP_BASEURL + "employees/getall"
  //  //let url = "http://localhost:3002/employees/getall"
  //   const response = await postSubmitForm(url, {})
  //   if (response && response.status === 1) {
  //     const filter = response.data.filter(emp => emp._id === employee_id )
  //     console.log(filter,"Filter")
  //     setEmployeeCode(filter[0].code)
  //   } else {
  //     showNotification(response.message, "Error")
  //   }
  // }
  
//Insert
  const handleValidSubmit = async (e,v) => {
  try {
    const obj = {
      employee_id: employee_id,
      // employee_code: employeeCode,
      // employee_name: employee_name,
      loan_apply_date: new Date(),
      loan_amount: v.loan_amount,
       emi_amount: v.emi_amount,
      tenure: v.tenure,
      loan_reason: v.loan_reason,
    }
    // console.log("Details",obj)
    let url = process.env.REACT_APP_BASEURL + "loans/insert"
    // let url = "http://localhost:3002/loans/insert"
    let response = await postSubmitForm(url, obj)
    if (response.status === 1) {
      refContainer.current.reset()
      loadAllLoan()
      setLoanAmount(0);
      setTenureDuration(0)
      showNotification(response.message,"Success")
    } else {
      showWarning(response.message,"warning")
    }
  } catch (error) {
     showNotification(error,"Error")
  }
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: "2%" }
      }
    },
    {
      text: props.t("Requested Loan Amount"),
      dataField: "loan_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
    {
      text: props.t("EMI Amount"),
      dataField: "emi_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
    {
      text: props.t("Requested Tenure"),
      dataField: "tenure",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
    {
      text: props.t("Loan Reason"),
      dataField: "loan_reason",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
    {
      text: props.t("Remaining Loan"),
      dataField: "remaining_loan",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
    {
      text: props.t("Remaining Tenure"),
      dataField: "remaining_tenure",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
    // {
    //   text: props.t("Remark"),
    //   dataField: "remark",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" }
    //   },
    //   // editable: false
    // },
    {
      text: "Applied Date",
      dataField: props.t("loan_apply_date"),
      formatter: (cell, row) => { 
          const  applyDate = row.loan_apply_date;
          const formattedDate =   moment(applyDate).format('YYYY-MM-DD') ;
          return formattedDate;
      } ,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Approved Date",
      dataField: "loan_approval_date",
      formatter: (cell, row) => {
    
        return row.loan_approval_date ? moment(row.loan_approval_date).format("YYYY-MM-DD") : "";
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Status"),
      dataField: "loan_status",
      sort: true,
      formatter:(cell, row) => {
        return (
         <>
          <Badge className={`fs-10 badge-soft-${row.loan_status === "Approved" ? "success" : row.loan_status === "Pending" ? "warning" : row.loan_status === "Rejected" ? "danger" : null}`}>
            {row.loan_status}
          </Badge> <br />
          {row.remark && "Remark's : " + row.remark}
         </>
        )
        },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      // editable: false
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
  }

  return <React.Fragment>

    <div className="page-content" style={backgroundImageStyle}>
      <Container fluid={true}>
        <Breadcrumbs
          title="Loan Application"
          breadcrumbItem="Loan Application"
        />

        <Row>
          <Col>
            <Card>
              <CardBody style={{
                 boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
              }}>
                <div className="mb-3" >

                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row >

                      <Col>
                        <AvField
                          type="number"
                          name="loan_amount"
                          label="Loan Amount"
                          onChange={(e)=> setLoanAmount(e.target.value)}
                          validate={{
                            required: { value: true }
                          }}
                          errorMessage="Loan Amount is required"
                        />
                      </Col>

                      
                       <Col>
                        <AvField
                          type="number"
                          name="tenure"
                          label="Tenure"
                          onChange={(e)=> setTenureDuration(e.target.value)}
                          validate={{
                            required: { value: true }
                          }}
                          errorMessage="Loan Amount is required"
                        />
                      </Col>



                    </Row>

                    <Row>

                    <Col>
                        <AvField
                          type="number"
                          name="emi_amount"
                          label="EMI Amount"
                          disabled="true"
                          value={(loanAmount/tenureDuration).toFixed(2)}
                          validate={{
                            required: { value: true }
                          }}
                          errorMessage="EMI Amount is required"
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          type="textarea"
                          name="loan_reason"
                          label="Reason"
                          validate={{
                            required: { value: true }
                          }}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mx-2"></div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                         <Button
                                      type="submit"
                                    
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "0rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody style={{
                 boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
              }}>
                 <ToolkitProvider 
                   keyField={"_id"}
                   data={allLoan && allLoan}
                   columns={columns}
                   search
                 >

                  {propst => (
                    <div>
                      <AvForm>
                          <Row>
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display .")}
                          striped
                          hover
                          condensed
                        />
                    </div>
                  )}
                 </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>


  </React.Fragment>

}
export default withRouter(connect(null, {})(withTranslation()(Apply_Loan)))
