import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import { postSubmitForm } from "../../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../../helpers/show_notificaton"
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../../assets/images/Background.png"
// import Flatpickr from "react-flatpickr"
import Piechart from "../../Chart/piechart"
import { Link } from "react-router-dom"
import preloader from "../../../helpers/Loader"
const CustomerVisitFrequency = props => {


    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [branches, setBranches] = useState("");
    const [todayDate, setTodayDate] = useState(new Date().toISOString().split('T')[0]);
    const [fromdate, setFromdate] = useState(new Date())
    const [todate, setTodate] = useState(new Date())
    const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));

    const refContainer = useRef(null)
    const branchesRef = useRef([]);
 
    const [totalSales, setTotalSales] = useState()

    useEffect(() => {
        loadAllBranches();
        loadGameSalesReportOnFirst()
    }, [])



    const loadTotalSales = async (payload) => {
       try {
        preloader(true)
        let url = process.env.REACT_APP_BASEURL + "reports/total_sales_details"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            preloader(false)
            setTotalSales(prev => prev = response.data)
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
       } catch (error) {
        preloader(false)
        showNotification(error, "Error")
       }
    }

    // fetch data
    const handleValidSubmit = async (e, v, token) => {
        const object = {
            from_date: moment(fromdate).format("YYYY-MM-DD"),
            to_date: moment(todate).format("YYYY-MM-DD"),
            branch_id: branchesRef.current[0]?._id
            // branch_name: selectedBranch.label
            
        }
        let dateFrom = moment(fromdate);
        let dateTo = moment(todate);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
        loadTotalSales(object);
    }

   
    const loadGameSalesReportOnFirst = async () => {
      
        let branchesResponse = await postSubmitForm(process.env.REACT_APP_BASEURL + "branches/getall", "");
        if (branchesResponse && branchesResponse.status === 1) {
          let branchList = branchesResponse.data
          branchList.filter((brn) => brn.name === "Rendezvous");
          setBranches(branchList)
          branchesRef.current = branchList
        } else {
          console.log("Error fetching branches");
        }
        setTodayDate(new Date().toISOString().split('T')[0])
        const payloadSales = {
            from_date: moment(fromdate).format("YYYY-MM-DD"),
            to_date: moment(todate).format("YYYY-MM-DD"),
          branch_id: branchesRef.current[0]?._id,
        };
        let salesUrl = process.env.REACT_APP_BASEURL + "reports/total_sales_details";
        let salesResponse = await postSubmitForm(salesUrl, payloadSales);
    
        if (salesResponse && salesResponse.status === 1) {
          setTotalSales(salesResponse.data);
        //   console.log(salesResponse.data);
        } else {
          showNotification(salesResponse.message, "Error");
        }
    
    };


    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    const loadAllBranches = async () => {
        let url = process.env.REACT_APP_BASEURL + "branches/getall"
        let response = await postSubmitForm(url, "")
        if (response && response.status === 1) {
          // console.log(response.data,"branch data from api")
    
          let branchList = response.data.map(branch => ({label:branch.name,value: branch.value, adderess: branch.adderess}));
        //   console.log(branchList,"branch list")
          setBranches(branchList)
          // console.log(response.data)
        } else {
          console.log("Error");
        }
      }

   
    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Total Sales And Revenue" breadcrumbItem1="Total Sales and revenue" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "black",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={(e, v) => {handleValidSubmit(e, v, employeeToken)}} 
                                        ref={refContainer}>
                                        <Row >
                                            <Col lg={3}>
                                                <label style={{color:"white"}} className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={fromdate}
                                                    options={{
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        const date = new Date(dateString);
                                                        setFromdate(date)
                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)
                                                        //   setMaxDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    name="from_date"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col lg={3} >

                                                <label style={{color:"white"}} className="form-label">
                                                    {props.t("To Date")}
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        const date = new Date(dateString);
                                                        setTodate(date)
                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                
                                                    }}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}

                                                    name="to_date"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />


                                            </Col>
                                             

                            

                                            <Col lg={2} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "128px",
                                                    marginTop: "1rem",
                                                    // margin: "auto",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }} type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    {props.t("Show")}
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                    <Col lg={5}>
              <Card style={{height:"467px"}}>
                <CardBody>
                  <CardTitle>{props.t("Total Sales")} <br />

                <span style={{fontSize:"10px"}}>
                Total sale amount &nbsp; = &nbsp; Sum of all sales <br />
                Net sale amount &nbsp;&nbsp;&nbsp; = &nbsp; Sum of sales - Refunds + Extra amount 
                </span>

                  </CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <Piechart data={totalSales}
                      name={props.t("Bookings Pie")}/>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={7}>
            <Row className="mt-0">
                            <Col sm="6">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4D4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Sale Amount")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {props.t("QR")} {totalSales?.total_sale_amount}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="6">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4D4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Net Sale Amount")}
                                                </p>
                                                <h4 className="mb-0">
                                                    {props.t("QR")} {totalSales?.total_net_sale_amount}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="6">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4D4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Sale Count")}
                                                </p>
                                                <h4 className="mb-0">
                                                     {totalSales?.total_sales || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-counter font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        

                            <Col sm="6">
                            <Link to="/game-sales-report" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Game Sales")}
                                                </p>
                                                <h4 className="mb-0">
                                                {props.t("QR")}  {totalSales?.total_game_sale_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-gamepad-variant-outline font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                            </Col>

                            <Col sm="6">
                            <Link to="/item-sales-report" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Item Sales")}
                                                </p>
                                                <h4 className="mb-0">
                                                {props.t("QR")}  {totalSales?.total_item_sale_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-shape-rectangle-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                            </Col>

                            <Col sm="6">
                            <Link to="/package-sales-report" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#d4e6ff" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Package Sales")}
                                                </p>
                                                <h4 className="mb-0">
                                                {props.t("QR")}  {totalSales?.total_pkg_sale_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-package-variant font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                </Link>
                            </Col>

                            {/* <Col sm="4">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Cash Payment")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.paid_by_cash % 1 !== 0 ? totalSales?.paid_by_cash.toFixed(2) : totalSales?.paid_by_cash || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="4">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Card Payment")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.paid_by_card % 1 !== 0 ? totalSales?.paid_by_card.toFixed(2) : totalSales?.paid_by_card || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="4">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Google Pay")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.paid_by_googlePay % 1 !== 0 ? totalSales?.paid_by_googlePay.toFixed(2) : totalSales?.paid_by_googlePay || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="4">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Apple Pay")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.paid_by_applePay % 1 !== 0 ? totalSales?.paid_by_applePay.toFixed(2) : totalSales?.paid_by_applePay || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> */}

                            <Col sm="6">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#fff4d4" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Extra Amount")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.total_difference_amount+totalSales?.total_extra_amount|| 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                    <i className="mdi mdi-cash-plus font-size-24"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                    

                           
                            <Col sm="6">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4E6" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Refund")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.total_refund % 1 !== 0 ? totalSales?.total_refund.toFixed(2) : totalSales?.total_refund || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-minus font-size-24"></i>  
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/* <Col sm="4">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#FFD4E6" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Change")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.total_change_amount % 1 !== 0 ? totalSales?.total_change_amount.toFixed(2) : totalSales?.total_change_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-minus font-size-24"></i>                                                
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> */}

                            {/* <Col sm="4">
                                <Card
                                    className="mini-stats-wid"
                                    style={{ background: "#d4fffb" }}
                                >
                                    <CardBody >
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-truncate">
                                                    {props.t("Total Discount")}
                                                </p>
                                                <h4 className="mb-0">
                                                    QR {totalSales?.total_discount_amount % 1 !== 0 ? totalSales?.total_discount_amount.toFixed(2) : totalSales?.total_discount_amount || 0}
                                                </h4>
                                            </div>
                                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                <span className="avatar-title">
                                                <i className="mdi mdi-cash-minus font-size-24"></i>  
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> */}

                            
                </Row>
            </Col>

          
                    </Row>
{/* 
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={6}>
                                                {props.t("Total Sales And Revenue")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    disabled={totalSales}
                                                    onClick={() => { printDiv("printDiv") }}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={totalSales}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    <CardSubtitle className="mb-3">
                                        {props.t("View All")}
                                    </CardSubtitle>
                                   <div id="printDiv">
                                   <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={[]}
                                        search

                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row className="hide">
                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    // pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                   </div>



                                  

                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(CustomerVisitFrequency)))
