import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/Logo.png"
//Circular menu
import CirluarMenu from "../Common/CircularMenu"
//i18n


const Header = props => {
  const [menu, setMenu] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  const role = localStorage.getItem("role");
  function getLink() {
    let link = ""
    if (role === "Admin" || role === "Owner" || role === "Manager") {
      link = "/dashboard"
    } else {
      link = "/employee-dashboard"
    } return link
  }
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        style={{
          backgroundColor: "#0B1F32",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <Row>
          <Col lg={12}>
            <div className="navbar-header">

              <div className="d-flex">
                <div className="navbar-brand-box">
                  <Link to={getLink()} className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={logo} alt="" height="60" />
                    </span>
                    <span className="logo-lg">
                      <img src={logo} alt="" height="60" />
                    </span>
                  </Link>

                  <Link to={getLink()} className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logo} alt="" height="60" />
                    </span>
                    <span className="logo-lg">
                      <img src={logo} alt="" height="60" />
                    </span>
                  </Link>
                </div>


                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                  data-toggle="collapse"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu)
                  }}
                  data-target="#topnav-menu-content"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>


                {(role === "Admin" || role === "Accountant" ||
                  role === "Owner") && (
                    <>
                      <Dropdown
                        //className="dropdown-mega d-none d-lg-block ms-2"
                        className="dropdown-mega ms-2"
                        isOpen={menu}
                        toggle={() => setMenu(prev => prev = !prev)}
                      >
                        <DropdownToggle
                          className="btn header-item waves-effect"
                          caret
                          tag="button"
                        >

                          <i className="bx bxs-report font-size-16 align-middle mr-1 " ></i> &nbsp;
                          <span style={{ fontSize: "13px" }}>{props.t("Reports")}</span> <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-megamenu">
                          <Row>
                            <Col md={12}>
                              <Row>
                                <Col md={3}>


                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Inventory Reports")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/under-stock">
                                        <span>{props.t("Under Stock Items")}</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/out-of-stock-items">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("Out of Stock Items")}</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/item-category">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("All Items")}</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/products_for_sale">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("Items For Sale")}</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/products_for_use">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("Items For Use")}</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/used-items-report">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("Used Items Report")}</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/threshold-report">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("Items Threshold Report")}</span>
                                      </Link>
                                    </li>

                                  </ul>



                                </Col>

                                <Col md={3}>
                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Expiry Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/expired-items-reports">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>
                                          {props.t("Expired Items Report")}
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/upcoming-expiry-items">
                                        <span>
                                          {props.t("Expiring Items in next N days")}
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/discarded-items-report">
                                        <span> {props.t("Discarded Items Report")} </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/expirydate-logs">
                                        <span> {props.t("Expiry Date Logs ")} </span>
                                      </Link>
                                    </li>

                                  </ul>

                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Employee's Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to={"/view_employee?view=" + "report"}>
                                        <span>
                                          {props.t("Employee Details")}
                                        </span>
                                      </Link>
                                    </li>

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/daily_attendance_report">
                                        <span>
                                          {props.t("Daily Attendance Report ")}
                                        </span>
                                      </Link>
                                    </li>


                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/employee_attendance_report">
                                        <span>
                                          {props.t("Employee Attendance Report")}
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/leave_report">
                                        <span>
                                          {props.t("Leave Report")}
                                        </span>
                                      </Link>
                                    </li>

                                  </ul>

                                </Col>




                                <Col md={3}>
                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Sales Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">


                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/total_sales_details">
                                        <span>
                                          {props.t(" Total Sales & Revenue")}
                                        </span>
                                      </Link>

                                    </li>

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/game-sales-report">
                                        <span>
                                          {props.t("Games Sales Report")}
                                        </span>
                                      </Link>
                                    </li>

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/item-sales-report">
                                        <span>
                                          {props.t("Items Sales Report")}
                                        </span>
                                      </Link>
                                    </li>

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/package-sales-report">
                                        <span>
                                          {props.t("Package Sales Report")}
                                        </span>
                                      </Link>
                                    </li>

                                  </ul>
                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Costing Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/costing-report">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("PO Costing Report")}</span>
                                      </Link>
                                    </li>

                                  </ul>


                                </Col>

                                <Col md={3}>


                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Customer Loyality Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">
                                    {/* <li>
                        <Link to="#">
                          <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i>
                          <span> {props.t( " Services details by customer with amount " )} </span>
                        </Link>
                      </li> */}
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/customer-visit-frequency">
                                        <span>
                                          {props.t(" Customer visit frequency (number of visits within a time frame)")}
                                        </span>
                                      </Link>
                                    </li>
                                    {/* <li>
                        <Link to="#">                                  
                          <span>
                            {props.t(" List of customer sorted by Amount Paid, No. of visits")}
                          </span>
                        </Link>
                      </li> */}
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/top-20-customers">
                                        <span>
                                          {props.t(" Top 20 customers")}
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/list_of_customers">
                                        <span>
                                          {props.t("List of customer sorted by Amount Paid, No of visits")}
                                        </span>
                                      </Link>
                                    </li>
                                    {/* <li>
                        <Link onClick={() => setMenu(prev => prev = !prev)} to="/services-by-customer">
                          <span>
                            {props.t("Services Availed By Customers")}
                          </span>
                        </Link>
                      </li> */}
                                    {/* <li>
                        <Link to="#">                                  
                          <span>
                            {props.t("Customer History Report")}
                          </span>
                        </Link>
                      </li> */}
                                  </ul>



                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Accounts Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">

                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/accounts-report">
                                        {/* <i className="bx bxs-key font-size-16 align-middle mr-1 text-danger"></i> */}
                                        <span>{props.t("Accounts Report")}</span>
                                      </Link>
                                    </li>

                                  </ul>


                                </Col>

                                <Col md={3}>
                                  <h5 className="font-size-14 mt-0">
                                    <u> {props.t("Daily Closure Report")}</u>
                                  </h5>
                                  <ul className="list-unstyled megamenu-list">
                                    <li>
                                      <Link onClick={() => setMenu(prev => prev = !prev)} to="/view_daily_closer_record">
                                        <span>
                                          {props.t("View Daily Closure Report History")}
                                        </span>
                                      </Link>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </DropdownMenu>
                      </Dropdown>

                    </>
                  )}


                {(role === "Admin" || role === "Owner" || role === "Manager") && <div style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "40px", fontWeight: "normal", overflowX: "hidden", minWidth: "300px" }}>

                  <CirluarMenu hrf={['/add-reservation', '/check-out', '/sell-items', '/sell-packages', '/generate_po', '/all_purchase_order']} links={['Add Reservation', 'CheckOut', 'Sell Items', 'Sell Package', "Generate PO", "All Purchase Order"]} />


                </div>}

                {(role === "Cashier") && <div style={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "40px", fontWeight: "normal", overflowX: "hidden", minWidth: "300px" }}>

                  <CirluarMenu hrf={['/add-reservation', '/check-out', '/sell-items', '/sell-packages']} links={['Add Reservation', 'CheckOut', 'Sell Items', 'Sell Package']} />


                </div>}


              </div>


              <div className="d-flex">
                

                <LanguageDropdown />

               

                <div className="dropdown d-none d-lg-inline-block ms-1">
                  <button
                    style={{ color: "white" }}
                    type="button"
                    className="btn header-item noti-icon "
                    onClick={() => {
                      toggleFullscreen()
                    }}
                    data-toggle="fullscreen"
                  >
                    <i className="bx bx-fullscreen" />
                  </button>
                </div>

                {(role !== "Assistant" || role !== "Store Keeper") && (
                  <NotificationDropdown />
                )}


                <ProfileMenu />
              </div>
            </div>
          </Col>
        </Row>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
