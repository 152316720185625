import {  SUBMIT_KITCHEN_ITEM, SUBMIT_KITCHEN_ITEM_ERROR, SUBMIT_KITCHEN_ITEM_SUCCESS } from "./actionTypes";


const initialState ={
    loading:false,
    error:null,
};

const kitchenItem = ( state= initialState, action )=> {
    switch(action.type){
            case SUBMIT_KITCHEN_ITEM:
                return {
                  ...state,
                    loading:true,
                    error:null,
                };
            case SUBMIT_KITCHEN_ITEM_SUCCESS:
                return {
                  ...state,
                    loading:false,
                    error:null,
                    kitchenItem:action.payload,
                };
            case SUBMIT_KITCHEN_ITEM_ERROR:
                return {
                  ...state,
                    loading:false,
                    error:action.payload,
                };
            default:
                return state;
        }
}

export default kitchenItem;