import React from 'react'
import { Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import withRouter from './Common/withRouter'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'



const CustomerDetails = ({ details, toggle, isOpen, t }) => {


  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle} >
          {t("Customer Details")}&nbsp;-{details?.name}
        </ModalHeader >
        <ModalBody style={{ minHeight: "200px" }}>

          <div id="printDiv">

            <div className="container pl-4  pr-0">


              <div className="row">
                <div className="col col-md-sm-lg-md-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Name &nbsp; &nbsp;
                      <b> {details?.name}</b>
                    </span>
                  </p>
                </div>
                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Date Of Birth &nbsp; &nbsp;
                      <b> {details?.date_of_birth?moment(details?.date_of_birth).format("YYYY-MM-DD"):"N/A"}</b>
                    </span>
                  </p>
                </div>
                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Mobile &nbsp; &nbsp;
                      <b> {details?.mobile}</b>
                    </span>
                  </p>
                </div>
              </div>

              <br />

              <div className="row">

                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Email &nbsp; &nbsp;
                      <b>{details?.email ? details?.email:"N/A"}</b>
                    </span>
                  </p>
                </div>


                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Qatar ID &nbsp; &nbsp;
                      <b> {details?.qatar_id?details?.qatar_id:"N/A"}</b>
                    </span>
                  </p>
                </div>


                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Passport &nbsp; &nbsp;
                      <b> {details?.passport?details?.passport:"N/A"}</b>
                    </span>
                  </p>
                </div>


              </div>

              <br />


              <div className="row">

                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Address &nbsp; &nbsp;
                      <b> {details?.address ? details?.address :"N/A"}</b>
                    </span>
                  </p>
                </div>

                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Customer Type &nbsp; &nbsp;
                      <b> {details?.customer_type?details?.customer_type:"N/A"}</b>
                    </span>
                  </p>
                </div>

                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Wallet &nbsp; &nbsp;
                      <b> {details?.wallet ? "Yes" : "No"}</b>
                    </span>
                  </p>
                </div>
              </div>

              <br />

              <div className="row">

                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Created By &nbsp; &nbsp;
                      <b> {details?.customerCreatedBy?.employee_name ? details?.customerCreatedBy?.employee_name :"N/A"}</b>
                    </span>
                  </p>
                </div>

                <div className="col col-md-sm-md-lg-4">
                  <p className="mb-0 date">
                    <span style={{ fontWeight: 500 }}>
                      Created On &nbsp; &nbsp;
                      <b> {moment(details?.createdAt).format("YYYY-MM-DD")}</b>
                    </span>
                  </p>
                </div>

                <div className="col col-md-sm-md-lg-4">
                  {/* <p className="mb-0 date">
                              <span style={{ fontWeight: 500 }}>
                                Created By &nbsp; &nbsp;
                                <b> {details?.createdBy?.name}</b>
                              </span>
                            </p> */}
                </div>

              </div>


            </div>


          </div>
          {/* <Row className="col text-right pr-5">
                    <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="#" onClick={() => { printDiv("printDiv"); }} className="btn btn-primary">
                        <i className="fa fa-print" /> Print
                      </Link>
                    </Col>
                  </Row> */}
        </ModalBody>
      </Modal>

    </>
  )
}

export default withRouter(connect(null, {})(withTranslation()(CustomerDetails)))