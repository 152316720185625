import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import exportFromJSON from "export-from-json"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import SelectPhotoFormatter from "../../helpers/SelectImageFormatter"

const GameTable = props => {
  document.title = "Add GameTable"

  const refContainer = useRef(null)
  const [document_file, set_document_file] = useState("")
  const { SearchBar } = Search
  const [allGameTables, setAllGameTables] = useState([])
  const [image, setImage] = useState()
  const [selected_game, setSelected_game] = useState("")
  const [allGame, setAllGames] = useState()
  const [editModal, setEditModal] = useState()
  const [selectedRow, setSelectedRow] = useState([])
  const [selecedRowGame, setSelecedRowGame] = useState()
  const [isSquareImg, setIsSquareImage] = useState(false)
  const [imageValidation, setImageValidation] = useState(true)

  useEffect(() => {
    loadAllGameTable()
    loadAllGames()
  }, [])


  // ------------------------------------------------------------------------------
  const handleDownload = async () => {
    const fileName = "Game Tables";
    const exportType = "xls";

    let data_to_export = allGameTables;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          table_type: v.table_type,
          capacity: v.capacity,
          Table_Number: v.table_number,
          Game: v.game.name,
          Resreved: v.is_reserved_for_open ? "Yes" : "No"
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }


  //Image check
  const handleImageReso = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInBytes = 277 + (500 * 1024); // 500 KB
      if (file.size > maxSizeInBytes) {
        // File size exceeds the limit, display an alert and clear state
        showWarning('File size is too large. Please choose a smaller file (max 500 KB).');
        setImageValidation(false)

      } else {
        setImageValidation(true)
        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
            console.log(img.width, "wi")
            console.log(img.height, "hi")

            if (img.width === 800 && img.height === 600 || img.height === 600 && img.width === 800) {
              setIsSquareImage(true);
            } else {
              // Image resolution is not square, display an alert and clear state
              showWarning("Please upload a Rectangle Image of 600 X 800");
              setIsSquareImage(false);
              set_document_file(null); // Clear the state by setting it to null
            }
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    } else {
      setIsSquareImage(true);
      setImageValidation(true)
    }
  };

  //form submit

  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + 'game_tables/insert'
    if (isSquareImg === false) {
      showWarning("Please upload a Rectangle Image of 600 X 800")
      return;
    } else if (imageValidation === false) {
      showWarning('File size is too large. Please choose a smaller file (max 500 KB).');
      return;
    } else {
      const game = {
        game_id: selected_game._id,
        name: selected_game.name,
        price_for_friend: selected_game.price_for_friend,
        price_for_regular: selected_game.price_for_regular,
        price_for_vip: selected_game.price_for_vip || 0,
        image: selected_game.image,
        description: selected_game.description,
        is_active: selected_game.is_active,
        available: selected_game.available,
        duration: selected_game.duration
      };

      // const obj = {
      //   game: {
      //     game_id: selected_game._id,
      //     name: selected_game.name,
      //     price_for_friend: selected_game.price_for_friend,
      //     price_for_regular: selected_game.price_for_regular,
      //     price_for_vip: selected_game.price_for_vip || 0,
      //     image: selected_game.image,
      //     description: selected_game.description,
      //     is_active: selected_game.is_active,
      //     available: selected_game.available,
      //     duration: selected_game.duration
      //   },
      //   table_number: v.table_number,
      //   capacity: v.capacity,

      //   table_type: "Normal"
      // }

      let formData = new FormData()
      formData.set("game", JSON.stringify(game))
      formData.set("table_type", "Normal")
      formData.set("capacity", v.capacity);
      formData.set("table_number", v.table_number);
      formData.set("document_file", document_file);
      postSubmitForm(url, formData).then(response => {
        if (response.status === 1) {
          loadAllGameTable()
          setSelected_game(null)
          set_document_file("")
          refContainer.current.reset()
          setImage()
          showNotification(response.message, "Success")
        } else {
          showNotification(response.message, "Error")
        }
      })
    }
  }

  
  //handle select 
  function handleSelect(data) {
    setSelected_game(data)
  }

  //All Game
  const loadAllGames = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "games/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      preloader(false)
      const filterdata = response.data.filter(game=>game.is_active===true)
      filterdata.forEach((game) => {
        game.label = (
          <>
          {SelectPhotoFormatter(game)}
          {game.name}
          </>
        )
        // game.label = game.name;
        game.value = game.name;
      })
      setAllGames(filterdata)
    } else {
      preloader(false)
      //  showNotification(response.message, "Error")
    }
  }
  //getall
  const loadAllGameTable = async () => {
    let url = process.env.REACT_APP_BASEURL + "game_tables/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      // console.log(response.data);
      setAllGameTables(response.data)
    } else {
      // showNotification(response.message, "Error")
    }
  }

  // console.log(allGameTables, "All Game Table")

  //Delete
  const handleValidDelete = async game => {
    let url = process.env.REACT_APP_BASEURL + "game_tables/activate_deactivate_game_table"
    let response = await postSubmitForm(url, {
      _id: game._id,
      is_active: game.is_active === true ? false : true
    })
    if (response.status === 1) {
      loadAllGameTable()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }

  const handleValidUpdateResreved = async table => {
    let url = process.env.REACT_APP_BASEURL + "game_tables/reserve_game_table"
    let response = await postSubmitForm(url, {
      _id: table._id,
      is_reserved_for_open: table.is_reserved_for_open === true ? false : true
    })
    if (response.status === 1) {
      loadAllGameTable()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }
  // console.log(selectedRow)
  //Upodate
  const handleValidUpdate = async (e, v) => {
    if (v.document_file !== "" && isSquareImg === false) {
      // console.log("helllo")
      showNotification("Pls upload a square image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc")
      return;
    } else {
      try {
        let formData = new FormData();
        formData.set("_id", selectedRow._id,)
        formData.set("capacity", v.capacity);
        formData.set("table_type", v.table_type);
        if (document_file === "") {
          formData.set("document_file", selectedRow.table_image_url);
        } else {
          formData.append('document_file', document_file);
        }
     
        let url = process.env.REACT_APP_BASEURL + "game_tables/update"
        let response = await postSubmitForm_withformdata(url, formData)
        // let response = await postSubmitForm(url, {
        //   _id: selectedRow._id,
        //   capacity: v.capacity,
        //   table_type: v.table_type,
    
        //   // game:{
        //   //   game_id:selecedRowGame._id,
        //   //   name:selecedRowGame.name,
        //   //   price:selecedRowGame.price,
        //   //   image: selecedRowGame.image,
        //   //   description:selecedRowGame.description,
        //   //   is_active:selecedRowGame.is_active,
        //   //   available:selecedRowGame.available
        //   // },
        // })
        if (response.status === 1) {
          loadAllGameTable()
          setSelecedRowGame()
          setEditModal(!editModal)
          showNotification(response.message, "Success")
          // console.log(allGameTables);
        } else {
          showNotification(response.message, "Error")
        }

      } catch (error) {
        showNotification(error.message, "Error")
      }
    }}

  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.table_image_url}
          alt={row.table_number}
          className="avatar-md rounded-circle img-thumbnail"
        ></img>
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }
  //Action
  const [isReserved, setIsReserved] = useState(false)
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedRow(row)
            setIsReserved(row.is_reserved_for_open)
            setSelecedRowGame({
              label: row.game.name,
              value: row.game._id
            })
          }}
        />
        <i>
          <div className="form-switch ms-2" dir="ltr">
            <input
              type="checkbox"
              style={{ height: "15px", width: "30px" }}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>

      </span>
    )
  }
  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "table_image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Table"),
      //formatter: employeeFormatter,
      dataField: "table_number",
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", 
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Table Type"),
      //formatter: employeeFormatter,
      dataField: "table_type",
      // filter: selectFilter({
      //   options: [
      //     { value: "Regular", label: "Regular" },
      //     { value: "VIP", label: "VIP" },
      //     { value: "Friend", label: "Friend" },
      //   ],
      //   placeholder: "ALL",
      // }),      
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Game"),
      //formatter: employeeFormatter,
      dataField: "game.name",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Capacity"),
      dataField: "capacity",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Reserved"),
      dataField: "is_reserved_for_open",
      sort: true,
      hidden:localStorage.getItem("role")==="Owner" ? false:true,
      formatter:(cell,row)=>{
        return (
          <span className="text-info d-flex justify-content-evenly">
        <i>
        <div className="form-switch ms-2" dir="ltr">
          <input
            type="checkbox"
            style={{ height: "15px", width: "30px" }}
            title={row.is_reserved_for_open ? "Click to free ." : "click to Reserve"}
            className="form-check-input"
            id={"customSwitchsize1" + row._id}
            onClick={() => {
              handleValidUpdateResreved(row)
            }}
            checked={row.is_reserved_for_open}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ fontWeight: "100", cursor: "pointer" }}
          ></label>
        </div>
      </i>
      </span>
      )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
  ]
  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Services")}
            breadcrumbItem1={props.t("Game Table")}
            alternateTitle={props.t("Add Game Table")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "black",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm 
                  onValidSubmit={(e,v)=>{
                    if( selected_game==="" || selected_game===null){
                      showWarning("Please select Game");
                      return;
                    }else{
                      handleValidSubmit(e,v);
                    }
                  }} 
                  ref={refContainer}>
                    <Row >

                      <Col lg={6} >
                        <Label style={{ color: "white" }}>{props.t("Table Name *")}</Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="table_number"
                          // label={props.t("Table Name")}
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Table Name")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Table Name cannot be empty")}
                        />
                      </Col>

                      <Col lg={6} className="mb-2">
                        {/* <AvField
                          name="game"
                          label={props.t("Game")}
                          className="form-control"
                          onChange={e => {
                            const selectedGame = allGame.find(game => game.name === e.target.value);
                            setSelected_game(selectedGame);
                          }}
                          type="select"
                          placeholder="--Select Game--"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Enter a Valid Game Name")}
                        >
                          <option value="">{props.t("--Select Game--")}</option>
                          {allGame && allGame.map((game)=>{
                            return (
                              <option key={game._id} value={game.name}>  {game.name} </option>
                            )
                          })}
                          </AvField> */}
                        <Label style={{ color: "white" }}>
                          {props.t("Game*")}
                        </Label>
                        <Select
                          onChange={handleSelect}
                          options={allGame}
                          value={selected_game}
                          placeholder={(props.t("Search or Select Game"))}
                          isSearchable
                          isRequired
                        // styles={{
                        //   menu: provided => ({
                        //     ...provided,
                        //     height: '150px',
                        //     overflow: 'auto'
                        //   })
                        // }}
                        />

                      </Col>


                    </Row>
                    <Row>
                      <Col lg={6} >
                        <Label style={{ color: "white" }}>{props.t("Capacity *")}</Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="capacity"
                          // label={props.t("Capacity")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Capacity")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Capacity cannot be empty")}
                        />
                      </Col>

                      <Col lg={6}>
                        <Label style={{ color: "white" }}>{props.t("Upload Image *")} &nbsp;
                          <span style={{ fontSize: "10px", color: "red" }}>
                            {props.t("( Rectangular image of height 600 and width 800 )")}
                          </span>
                        </Label>
                        <AvField
                          name="document_file"
                          className="btn btn-primary btn-sm"
                          type="file"
                          validate={{ required: { value: true } }}
                          onChange={e => { setIsSquareImage(false); set_document_file(e.target.files[0]); handleImageReso(e) }}
                          placeholder={props.t("Uplaod Image")}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>


                      <Button
                        type="submit"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "100px",
                          marginTop: "1.6rem",
                          margin: "auto",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Submit")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>



                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>

                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>

                      <Col lg={6}>
                        {props.t("All Game Tables")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}

                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={allGameTables.length < 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allGameTables && allGameTables}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                          headerStyle={{ background: 'violet', color: 'red' }}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
          >
            <ModalHeader toggle={() => setEditModal(!editModal)}>
              {props.t("Update Item - ")}
              {selectedRow && selectedRow.table_number}
            </ModalHeader>
            <ModalBody>
              {selectedRow && (
                <>
                  <Row>
                    <Col xlg={12}>
                      <Card>
                        <CardBody



                        >
                          <AvForm onValidSubmit={handleValidUpdate} >
                            <Row>
                              <Col lg={6}>
                                <AvField
                                  onWheel={preventValueChangeOnScroll}
                                  name="capacity"
                                  label={props.t("Capacity")}
                                  placeholder={props.t("Enter Capacity")}
                                  type="number"
                                  errorMessage={props.t("Name cannot be empty")}
                                  value={selectedRow.capacity}
                                />
                              </Col>

                             

                              <Col lg={6} >
                                <AvField
                                  name="table_type"
                                  label={props.t("Table Type")}
                                  className="form-control"
                                  type="text"
                                  value={selectedRow.table_type}
                                  placeholder={props.t("Enter Table Type")}
                                  validate={{ required: { value: true } }}
                                  errorMessage={props.t("Table Type cannot be empty")}
                                />
                              </Col>


                            </Row>
                            <Row>
                                  

                                      <Col lg={6}>
                                        <AvField
                                          name="document_file"
                                          label={props.t("Upload Image")}
                                          className="btn btn-primary btn-sm"
                                          type="file"
                                          // value={selectedUser.document_file}
                                          onChange={e => { set_document_file(e.target.files[0]); handleImageReso(e) }}
                                          placeholder={props.t("Uplaod Image")}
                                      
                                        />
                                      </Col>
                                    </Row>


                            <Row>
                              <Col lg={12} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

                                <Button
                                  type="submit"
                                  style={{
                                    position: "relative",
                                    overflow: "hidden",
                                    backgroundColor: "#fdd005",
                                    border: "none",
                                    color: "black",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    width: "128px",
                                    marginTop: "1.6rem",

                                    boxShadow:
                                      "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                    transition: "transform 0.3s ease",
                                  }}
                                  onMouseEnter={e => {
                                    e.target.style.transform = "scale(1.05)"
                                  }}
                                  onMouseLeave={e => {
                                    e.target.style.transform = "scale(1)"
                                  }}
                                >
                                  {props.t("Update Table")}
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      right: "0",
                                      bottom: "0",
                                      background:
                                        "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                      pointerEvents: "none",
                                    }}
                                  ></div>
                                </Button>

                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(GameTable)))
