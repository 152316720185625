function AddGameDuration(selectedGame, GameDuration,gameStartTime, gameEndTime, selectedCustomer, setGrandTotal, setSelectedGamePrice) {
 
    selectedGame.game.duration = GameDuration;
    selectedGame.game.table_start_time = gameStartTime
    new Date(selectedGame.game.table_start_time.getTime() + (GameDuration * 60 * 1000));
    selectedGame.game.table_end_time = gameEndTime;

    let price = 0;

    if (selectedCustomer?.type === "Friend") {

        price = selectedGame.game.price_for_friend;

    } else if (selectedCustomer?.type === "Regular") {

        price = selectedGame.game.price_for_regular

    } else if (selectedCustomer?.type === "VIP") {

        price = 0;

    }


    if (selectedGame.game.name.includes("VIP") && selectedCustomer?.type !== "VIP") {

        if (+GameDuration > 60) {

            let sixtyMin = 60;
            let rem = +GameDuration - sixtyMin;

            selectedGame.game.sub_total = ((150 / 60) * sixtyMin) + ((100 / 60) * rem);
            selectedGame.game.sub_total = +(selectedGame.game.sub_total.toFixed(2));
            setGrandTotal(prev => prev += +selectedGame.game.sub_total)
            setSelectedGamePrice(prev => prev += +selectedGame.game.sub_total)
        } else {

            selectedGame.game.sub_total = (150 / 60) * +GameDuration;
            selectedGame.game.sub_total = +(selectedGame.game.sub_total.toFixed(2));
            setGrandTotal(prev => prev += +selectedGame.game.sub_total);
            setSelectedGamePrice(prev => prev += +selectedGame.game.sub_total)
        }

    } else {
        selectedGame.game.sub_total = (price / 60) * +GameDuration;
        selectedGame.game.sub_total = +(selectedGame.game.sub_total.toFixed(2))
        setGrandTotal(prev => prev += +selectedGame.game.sub_total);
        setSelectedGamePrice(prev => prev += +selectedGame.game.sub_total)
    }
}

export default AddGameDuration;