import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Input,
    Button,
    CardTitle,
    Table,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { AvField, AvForm } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import showNotification from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import { showWarning } from "../../helpers/show_notificaton"
const ApproveLeave = (props) => {
    
    const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
    const refContainer = useRef(null);
    const [submitBtnClickedTracker, setSubmitBtnClickedTracker] = useState(0)
    const [selectedRow, setSelectedRow] = useState()
    const [editModal, setEditModal] = useState()
    // ----------------------------------------------------------------------------

    const [unitData, setUnitData] = useState([])

    const { SearchBar } = Search


    // Dynamic Moth Year
    const [currentMonth, setCurrentMonth] = useState('');
    const currentDate = new Date();
    const options = { month: 'long', year: 'numeric' }
    const month = currentDate.toLocaleString('default', options);

    useEffect(() => {
      preloader(true)
        let url = process.env.REACT_APP_BASEURL

        axios.post(url + `units/getall`, {}, {
            headers: {
                Authorization: `Bearer ${employeeToken}`,
            },
        }).then(res => {
          preloader(false)
            // console.log(res)
            setUnitData(res.data.data)
            refContainer.current.reset()
        }).catch(err => console.log(err))
    }, [submitBtnClickedTracker])
    // --------------------------------------------------------------

    const insertUnit = async (payload) => {
      let url = process.env.REACT_APP_BASEURL
  
      try {
          const response = await axios.post(url + `units/insert`, payload, {
              headers: {
                  Authorization: `Bearer ${employeeToken}`,
              },
          });

          if (response.data.status === 1) {
              showNotification(response.data.message,"Success");
          } 

          setSubmitBtnClickedTracker(prev => prev += 1);
      } catch (error) {
          if (error.response.status === 409) {
              showWarning("Data Already Exists");
          } else {
              console.error(error);
              showNotification("An error occurred while making the request");
          }
      }
  }
  
  

    function deleteUnit(row){
        let url = process.env.REACT_APP_BASEURL
        axios.post(url + `units/activate_deactivate`,{id: row._id,is_active : row.is_active===true? false : true},{
            headers: {
                Authorization: `Bearer ${employeeToken}`,
            },
        }).then(res => {
            console.log(res)
            showNotification(res.data.message, "Success")
            setSubmitBtnClickedTracker(prev => prev += 1)
        }).catch(err => console.log(err))
    }

  
    function editFormatter(cell, row) {
        return (
            <span className="text-info d-flex justify-content-evenly">
                
                <i
                    className="bx bxs-edit fs-5 " aria-hidden="true"
                    title="Click to Edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                         setSelectedRow(row)
                         setEditModal(!editModal)
                    }}
                ></i>
                {/* <i
                    className="fa fa-trash fs-5 ms-3" aria-hidden="true"
                    title="Click to delete"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                        deleteUnit(row)
                    }}
                ></i> */}
                 <i>
                <div className="form-switch ms-2" dir="ltr">
                    <input
                        type="checkbox"
                        style={{height:"15px", width:"30px"}}
                        title={row.is_active?"Click to Deactivate .":"Activate"}
                        className="form-check-input"
                        id={"customSwitchsize1" + row._id}
                        onClick={() => { 
                        deleteUnit(row)
                        }}
                        checked={row.is_active}
                    />
                    <label
                        title="Click to change status."
                        className="custom-control-label"
                        htmlFor={"customSwitch1" + row._id}
                        style={{ fontWeight: "100", cursor: "pointer" }}
                    ></label>
                    </div>
                </i>
            </span>
        )
    }

    const columns = [

        {
            dataField: "_id",
            hidden: true
        },
        {
            text: props.t("#"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            headerStyle: (colum, colIndex) => {
                return { width: "0.2%" }
            },
        },
        {
            text: props.t("Unit"),
            dataField: "unit",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Sub-Unit"),
            dataField: "sub_unit",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },

        {
            text: props.t("Action"),
            formatter: editFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
        },

    ]







    // handle valid submit to validate the inputs given to the form or if the form is empty
    const handleValidSubmit = async (e, v, token) => {


        const object = {
            unit: v.unit,
            sub_unit: v.sub_unit
        }

        // console.log(object, "object")

        insertUnit(object)

    }
    // -------------------------------------------------------------------------------------------
//Update
  const handleValidUpdate= async (e, v) => {
    try {
      const object = {
        id: selectedRow._id,
        unit: v.unit,
        sub_unit: v.sub_unit
      }
      let url = process.env.REACT_APP_BASEURL + "units/update"
    //let url ="http://localhost:3002/units/update"
     postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          showNotification(res.message, "Success")
          setSubmitBtnClickedTracker(prev => prev += 1)
          setEditModal(!editModal)
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }
  
  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };


    return (
        <React.Fragment>
            <div className="page-content"style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs
                        title={props.t("Unit of Measurement")}
                        breadcrumbItem1={props.t("Add Unit of Measurement")}
                        alternateTitle={props.t("Unit of Measurement")}
                    />

                    <Row>
                        <Col lg={12}>
                            <Card >
                                <CardBody 
                                  style={{
                                    color: "white",
                                    background:
                                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                  }}
                                >
                                    <div className="mb-3" >

                                        <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken)} ref={refContainer}>
                                            <Row >
                                                <Col md={3}>
                                                    <label className="form-label">
                                                    {props.t("Unit *")}
                                                    </label>

                                                    <AvField
                                                        className="form-control"
                                                        type="text"
                                                        name="unit"
                                                        placeholder={props.t("Enter Unit")}
                                                        errorMessage={props.t("Enter Unit")}
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                    />

                                                </Col>

                                                <Col md={3}>

                                                    <label className="form-label">
                                                     {props.t("Sub - Unit")}
                                                    </label>
                                                    <AvField
                                                        className="form-control"
                                                        type="text"
                                                        name="sub_unit"
                                                        placeholder={props.t("Enter Sub-Unit")}
                                                        errorMessage={props.t("Enter Sub-Unit")}
                                                      
                                                    />


                                                </Col>

                                                <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                    <label style={{ height: "20.5px" }} className="form-label">

                                                    </label>
                                                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "-0.15rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                                                </Col>

                                            </Row>
                                        </AvForm>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* --------------------------------------------- */}

                    <Row>
                        <Col>
                            <Card>
                                <CardBody
                                 style={{
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                  }}
                  
                                >


                                    <ToolkitProvider
                                        keyField={"_id"}
                                        data={unitData}
                                        columns={columns}
                                        search
                                    // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                                    >
                                        {propst => (
                                            <div>
                                                <Row>
                                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                        <SearchBar
                                                            className="form-control"
                                                            {...propst.searchProps}
                                                            // style={{ width: "340px" }}
                                                            placeholder="search"
                                                        />
                                                    </Col>
                                                </Row>

                                                <BootstrapTable
                                                    {...propst.baseProps}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                // cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row >
                </Container >
                 <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update - ")}
                      {selectedRow && selectedRow.unit}
                    </ModalHeader>
                    <ModalBody>
                      {selectedRow && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate} >
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="unit"
                                          label={props.t("Unit")}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                  errorMessage={props.t("Name cannot be empty")}
                                          value={selectedRow.unit}
                                        />
                                      </Col>

                                                                                                            

                                      <Col lg={6}>
                                        <AvField
                                          name="sub_unit"
                                          label={props.t("Sub Unit")}
                                          placeholder={props.t("Enter Sub Unit")}
                                          type="text"
                                  errorMessage={props.t("Sub Unit cannot be empty")}
                                          value={selectedRow.sub_unit}
                                        />
                                         

                                      </Col>
                                     
                                    </Row>
                                  

                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color=""
                                              style={{width:"auto"}}
                                              className="mr-1 btn btn-primary"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
            </div >

       
          
        </React.Fragment >
    )
}

export default withRouter(connect(null, {})(withTranslation()(ApproveLeave)))
