import { showLogoutAlert } from "./show_notificaton";
let logoutTimer;
let alertTimer;
const isLoginPage = () => window.location.pathname === '/login';

export const initLogoutTimer = () => {
  if (isLoginPage()) return;
  logoutTimer = 0;
  alertTimer = 0;
  logoutTimer = setTimeout(() => {
    window.location.replace('/logout');
  }, 7200000); //120 minutes
//   }, 600000); //10 minutes
//   }, 120000); // 2 minutes

  alertTimer = setTimeout(() => {
    console.log(12);
    showLogoutAlert()
  }, 6300000); //115 minutes
//   },120000); // 2 minutes
//   },60000); // 1 minutes

};

export const resetLogoutTimer = () => {
  if (isLoginPage()) return;
  clearTimeout(logoutTimer);
  clearTimeout(alertTimer);
  initLogoutTimer();
};