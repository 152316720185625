import React, { CSSProperties, useEffect, useRef, useState } from "react"
import logo from "../assets/images/Logo.png"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Spinner,
  Alert,
} from "reactstrap"
import Breadcrumbs from "../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../components/Common/withRouter"
import showNotification from "../helpers/show_notificaton"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import cellEditFactory from "react-bootstrap-table2-editor"
import swal from "sweetalert2"
import axios from "axios"
import { Link } from "react-router-dom" 
import BackgroundImage from "../assets/images/Background.png"
import SelectPhotoFormatter from "../helpers/SelectImageFormatter"

const ExtendExpiry = props => {

  const refSelectGame = useRef(null)
  const refContainer = useRef(null)
  const { SearchBar } = Search
  const [paidAmount, setPaidAmount] = useState(0)
  const [section, setSection] = useState("Cafe")
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    loadAllItems()

  }, [])



  const loadAllItems = async () => {
    // preloader(true)
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_items"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      setLoading(false);
       response.data.forEach(item => {
        item.label = (
          <>
            {SelectPhotoFormatter(item)}
            {item.name}
          </>
        );
        item.value = item._id;
      });
      setAccessory(response.data?.filter(item => item.section === "Accessories") || [] )
      setCafe(response.data?.filter(item => item.section === "Cafe") || [] )
      setKitchen(response.data?.filter(item => item.section === "Kitchen") || [] )
      setAll_Items(response.data?.filter(item => item.section === section) || [] )
    } else {
      // preloader(false)
      setLoading(false);
      setAll_Items([])
      console.log("Error")
    }
  }




   




  




  

  




  

 

    
  

 

 
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }


  const addItemsDetailsColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: "S.N",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      editable: false,
    },
    
    {
      dataField: "image_url",
      text: "Image",
      formatter: showPhotoFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },

    {
      dataField: "name",
      text: "Item Name",
      formatter: (cell, row) => row.name,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },

    {
      dataField: "Section",
      formatter: (cell, row) => row.section,
      text: "section",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
      editable: false,
    },
    
    {
        text: props.t("Formula"),
        dataField: "formula",
        sort: false,
        editable:false,
        formatter: (cell,row)=>{
            return (
                <>
                {row.sub_unit ? 
                "1 " + row.unit + " = " + row.formula + " " + row.sub_unit :
                "1 " + row.unit
                } 
                </>
            )
        },
        headerStyle: (colum, colIndex) => {
          return { width: "7%" }
        },
      },


      {
        dataField: "available.in_unit",
        text: "Available ",
        formatter: (cell,row)=>{
          return (
            <>
            {row.available?.in_unit + "  " + row.unit +" "+ row.available?.in_sub_unit + " " + row.sub_unit}
        </>
          )
      },
        headerStyle: (colum, colIndex) => {
          return { width: "7%" }
        },
        editable: false,
      },
  

    {
      text: "Quantity (in unit)",
      dataField: "quantity.in_unit",
      formatter: (cell, row) => {
        return <p style={{ color: row.quantity.in_unit === 0 ? "#E57373" : "", fontWeight: row.quantity.in_unit === 0 ? 600 : 0, width: "50px" }} >{`${row.quantity.in_unit} ${row.unit}`}</p>;
      },
      headerStyle: (column, colIndex) => {
        return { width: "7%" };
      },
      editable: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const availableItems = row.available; 
        const isAvailable = availableItems && availableItems.in_unit > 0;
    
        if (isAvailable) {
          return (
            <input
              style={{
                width: "50px",
                height: "40px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="number"
              onBlur={(e) => {
                setQuantityBtn(true)
                handleQuantityChange(e, rowIndex, row, editorProps);
              }}
              onFocus={(e) => e.target.select()} // Retain focus on input field
              autoFocus
            />
          );
        } else {
          return <div style={{color:"red"}}>Not Available</div>;
        }
      },
      validator: (newValue, row, column) => {

        if (+newValue > Number(row.available.in_unit)) {
          return {
            valid: false,
            message: "Cannot exceed available units",
          };
        }

        if (+newValue < 0) {
          return {
            valid: false,
            message: "Cannot be a negative value",
          };
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {

          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
    },

    {
      text: "Quantity (in sub-unit)",
      dataField: "quantity.in_sub_unit",
      formatter: (cell, row) => {
        return <p style={{ color: row.quantity.in_sub_unit === 0 ? "#E57373" : "", fontWeight: row.quantity.in_sub_unit === 0 ? 600 : 0, width: "50px" }} >{`${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: (cell, row) => ((row.sub_unit === "" || row.sub_unit === null) ? false : true),
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const availableItems = row.available; 
        const isAvailable = availableItems && availableItems.in_unit > 0 || availableItems.in_sub_unit;
    
        if (isAvailable) {
          return (
        <input
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          // defaultValue={value}
          onBlur={e => {
            setQuantityBtn(true)
            handleSubUnitQuantityChange(e, rowIndex, row, editorProps)     
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      );
    } else {
          return <div style={{color:"red"}}>Not Available</div>;
        }
      },
      validator: (newValue, row, column) => {
        if (+newValue > Number(((+row.formula) * (+row.available.in_unit)) + (+row.available.in_sub_unit))) {
          return {
            valid: false,
            message: "Cannot exceed available quantity",
          };
        }
        if (+newValue < 0) {
          return {
            valid: false,
            message: "Cannot be a negative value",
          };
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          };
        }
        if (newValue % 1 != 0) {

          return {
            valid: false,
            message: "Decimals not allowed.",
          };
        }
        return true;
      },
    },
    {
      dataField: "selling_price",
      formatter: (cell, row) => row.selling_price,
      text: "Rate (in Unit) ",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "sub_total",
      formatter: (cell, row) => row.sub_total.toFixed(2),
      text: "Sub Total",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    
  };


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            // title="Sales"
            // breadcrumbItem1="Sell Items"
            // alternateTitle="Sell Items"
          />

    



          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ExtendExpiry)))
