import {
    SUBMIT_ITEM,
    SUBMIT_ITEM_SUCCESS,
    SUBMIT_ITEM_ERROR
} from "./actionTypes"

export const submitItem = (addItem, history) =>{
    return{
        type: SUBMIT_ITEM,
        payload:{ addItem, history },
    };
};

export const submitItemSuccess = (addItem) =>{
    return{
        type: SUBMIT_ITEM_SUCCESS,
        payload:{ addItem },
    };
};

export const submitItemError = (error) =>{
    return{
        type: SUBMIT_ITEM_ERROR,
        payload:{ error },
    };
}