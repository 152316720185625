import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

const Salary_Slip = props => {
  const { SearchBar } = Search
  //meta title
  document.title = "Add Users | Rendezvous - Game Parlour"

  // useEffect(() => {
  //   loadAllEmployees()
  //   loadAllDesignations()
  //   loadAllUsers()
  // }, [])

  const refContainer = useRef(null)


  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/inser"
    // let formData = new FormData();
    // formData.set("month", v.month)
    // // formData.set("username", v.username);
    // // formData.set("pwd", v.pwd);
    // // formData.set("role", v.role);
    // console.log(formData,"form data")

    const object = {
      month:v.month
    }
    //console.log("Data", object)
    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error hello")
    //   }
    // })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("User")}
            breadcrumbItem={props.t("Users")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row style={{display:'flex',justifyContent:"center"}} >
                      {/* <Col lg={6}>
                        <div className="mx-2">
                          <label className="form-label">
                            {props.t("Employee")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            name="employee_id"
                            placeholder="Select Employee"
                            errorMessage="Select Employee."
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">
                              {props.t("Select Employee")}
                            </option>
                            {
                              [].map(emp => (
                                <option value={emp._id} key={emp._id}>
                                  {emp.name}
                                </option>
                              ))}
                          </AvField>
                        </div>
                      </Col> */}
                      <Col lg={6}  >
                        <div   className="mx-2">
                          <label className="form-label">
                            {props.t("View Salary Slip by Month")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            name="month"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Month")}
                          >
                            <option value="">{props.t("Select Month")}</option>

                            {/* {allDesignations && 
                                allDesignations.map((role)=>(
                                  <option value={role.name} key={role._id}>{role.name}</option>
                                ))} */}
                            <option value="january">
                              {props.t("Jan")}
                            </option>
                            <option value="february">
                              {props.t("Feb")}
                            </option>
                            <option value="march">
                              {props.t("Mar")}
                            </option>
                            <option value="april">
                              {props.t("April")}
                            </option>
                            <option value="may">
                              {props.t("May")}
                            </option>
                            <option value="june">
                              {props.t("Jun")}
                            </option>
                            <option value="july">
                              {props.t("Jul")}
                            </option>
                            <option value="august">
                              {props.t("Aug")}
                            </option>
                            <option value="september">
                              {props.t("Sept")}
                            </option>
                            <option value="october">
                              {props.t("Oct")}
                            </option>
                            <option value="november">
                              {props.t("Nov")}
                            </option>
                            <option value="december">
                              {props.t("Dec")}
                            </option>
                          </AvField>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    {/* <Row>
                      <Col lg={6}>
                        <div className="mx-2">
                          <label className="form-label">
                            {props.t("Username")}
                          </label>
                          <AvField
                            className="form-control"
                            name="username"
                            type="text"
                            placeholder="Enter Username"
                            errorMessage="Username cannot be empty."
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9a-zA-Z]+$",
                                errorMessage: props.t(
                                  "Cannot use space/special characters."
                                ),
                              },
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mx-2 ">
                          <label className="form-label">
                            {props.t("Password")}
                          </label>
                          <AvField
                            className="form-control"
                            type="password"
                            placeholder="Enter Password"
                            name="pwd"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 5,
                                errorMessage: props.t(
                                  "Minimum 5 characters required."
                                ),
                              },
                            }}
                            errorMessage={props.t("Password cannot be empty.")}
                          />
                        </div>
                      </Col>
                    </Row> */}
                    <hr />
                    <Row>
                      <Col lg={4}>
                        <div className="mx-2"></div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button type="submit" className="btn btn-info btn-lg">
                            {props.t("Search")}
                          </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{marginTop:"30px"}} >
            <h3 style={{ textAlign: "center" }} className="card-header">Salary slip for the month of May, 2023</h3>
            <Col style={{marginTop:"30px"}} >
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <p>Employee Name</p>
                      <p>Date of Joining</p>
                      <p> Section</p>
                    </Col>

                    <Col>
                      <p>MICHAL MINDAL LAMBEN</p>
                      <p>15 May 2023</p>
                      <p>Kitchen</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col style={{marginTop:"30px"}} >
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <p>Employee Code</p>
                      <p>Date of Birth</p>
                      <p>Designation</p>
                    </Col>

                    <Col>
                      <p>1233</p>
                      <p>10 July 2000</p>
                      <p>Operator</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* -------------------------SALARY DETAILS--------------------------------------------- */}

          <Row  >

            <Col>
              <Card style={{ padding: "10px" }} >
                <CardTitle style={{ margin: "auto" }} tag="h1">{"Allowance"}</CardTitle>
                <CardBody>
                  <Row>
                    <Col>
                      <p>Basic Salary</p>
                      <p>Food</p>
                      <p>Housing</p>
                      <p>Overtime</p>
                      <p>Other</p>
                      <p>Arrear</p>
                      <p>Special</p>
                      <p style={{ fontWeight: "bold" }} >Total Allowance{"(TA)"}</p>
                    </Col>

                    <Col>
                      <p>10 QAR</p>
                      <p>2 QAR</p>
                      <p>1 QAR</p>
                      <p>1 QAR</p>
                      <p>1 QAR</p>
                      <p>1 QAR</p>
                      <p>1 QAR</p>
                      <p style={{ fontWeight: "bold" }} >17 QAR</p>

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card style={{ padding: "10px" }} >
                <CardTitle style={{ margin: "auto" }} tag="h1">{"Deductions"}</CardTitle>
                <CardBody>
                  <Row>
                    <Col>
                      <p>Loan</p>
                      <p>Undertime</p>
                      <p>Punishment</p>
                      <p style={{ fontWeight: "bold" }} >Total Deduction{"(TD)"}</p>
                    </Col>

                    <Col>
                      <p>1 QAR</p>
                      <p>2 QAR</p>
                      <p>1 QAR</p>
                      <p style={{ fontWeight: "bold" }} >4 QAR</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Salary_Slip)))
