import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

const ExpiredItemsreport = props => {


    const { SearchBar } = Search

    const code = localStorage.getItem("code")
    const handleDownload = async () => {
        const fileName = "Expired_Items";
        const exportType = "xls";

        let data_to_export = section!=="" 
        ? expiredItems.filter(item=>item.section===section) 
        : expiredItems

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v,index) {
                data.push({
                    // Serial_Number: index+1,
                    Batch_Number: v.batch,
                    Section:v.section,
                    Item_Name: v.item_details.name,
                    Expiry_Date: moment(v.expiry_date).format('YYYY-MM-DD'),
                    Avaialble_Quantity: `${v.item_details?.available?.in_unit} ${v.item_details?.unit} ${v.item_details?.sub_unit? v.item_details?.available?.in_sub_unit : ""} ${v.item_details.sub_unit ? v.item_details.sub_unit : ""}`
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
          "",
          "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        printWindow.document.write(`
       
           <html>
           <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            </style>
           </head>
        <body>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        printWindow.print();
        printWindow.focus();
      };

   


    const [expiredItems, setExpiredItems] = useState([])
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [section, setSection] = useState("");

    useEffect(() => {
        loadAllExpiredItems()
    }, [])



    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: expiredItems && expiredItems.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };



    const loadAllExpiredItems = async () => {
        let url = process.env.REACT_APP_BASEURL + "reports/expired_items"
        const response = await postSubmitForm(url, {})
        if (response && response.status === 1) {
            // console.log(response.data,"expired iexpiredItems  setExpiredItems(response.data)")
            setExpiredItems(prev => prev = response.data)
        } else {
            showNotification(response.message, "Error")
        }
    }

 

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
        },
        {
            text: props.t("Batch Number"),
            dataField: "batch",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Item Name"),
            dataField: "item_details.name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Section"),
            dataField: "item_details.section",
            filter: selectFilter({
                style:{ marginLeft:"5px"},
                options: [
                  { value: "Accessories", label: "Accessories" },
                  { value: "Cafe", label: "Cafe" },
                  { value: "Kitchen", label: "Kitchen" },
                ],
                placeholder: "ALL",
                onFilter: (value) => {
                    setSection( value )
                  }  
              }),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Expiry Date"),
            dataField: "expiry_date",
            formatter: (cell, row) => {
                return  moment(row.expiry_date).format('YYYY-MM-DD')
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Quantity"),
            dataField: "expiry_date",
            formatter: (cell, row) => {
                return  <p>{`${row.item_details.available.in_unit} ${row.item_details.unit} ${row.item_details.sub_unit? row.item_details.available.in_sub_unit : ""} ${row.item_details.sub_unit ? row.item_details.sub_unit : ""}`}</p>
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
      
    ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Expired Items" breadcrumbItem1="Expired Items" />

                  
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={6}>
                                                {props.t("All Expired Items")}
                                            </Col>                                       
                                                
                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>

                                            <Button 
                                                 style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize:"12px",marginRight:"10px" }}
                                                 className="btn btn-sm btn-secondary"
                                                 onClick={() => {  printDiv("printDiv") }}
                                                 disabled={expiredItems.length <= 0}
                                                 >
                                                <i
                                                    className="fa fa-print " aria-hidden="true"
                                                    onClick={() => {  printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={expiredItems.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    {/* <CardSubtitle className="mb-3">
                                        {props.t("View all expired items")}
                                    </CardSubtitle> */}
                               <div id="printDiv">
                               <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={expiredItems && expiredItems}
                                        search

                                    >
                                        {propst => (
                                            <div className="">
                                                <AvForm>
                                                    <Row className="hide">
                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }} >
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    // pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                               </div>



                                 

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(ExpiredItemsreport)))
