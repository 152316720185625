import React, { useState, useEffect } from "react"
import { Col, Row, Container, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import logo from "../../assets/images/Logo.png"
import { Link } from "react-router-dom"
import moment from "moment"
import { postSubmitForm } from "../../helpers/backend_helper"

const Invoice = props => {
  const [selectedRow, setselected_Row] = useState()
  const [isLoading, setIsLoading] = useState(false)

  function printInvoice() {
    window.print()
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("booking_id")) {
      loadData({ booking_id: urlParams.get("booking_id") })
    }
  }, [])

  const loadData = async data => {
    setIsLoading(true)
    let url = process.env.REACT_APP_BASEURL + "sales/re-print"
    const response = await postSubmitForm(url, data)
    if (response && response.status === 1) {
      setIsLoading(false)
      setselected_Row(response.data[0])
    } else {
      setIsLoading(false)
      // showNotification(response.message, "Error");
    }
  }

  return (
    <React.Fragment>
      {isLoading === true ? (
        <div  style={{
           display:"flex",
           flexDirection:"column",
           justifyContent:"center",
           alignItems:"center",
           gap:"1rem",
           marginTop:"7rem"
                     
        }}>
          <Spinner style={{ margin:"auto", height: "4rem", width: "4rem" }} />
          <p style={{ marginTop: "10px", textAlign: "center" }}>Generating bill, please wait...</p>
        </div>
      ) : (
        <div style={{ width: "8.5cm", margin: "auto", marginTop: "70px" }}>
          <div
            className="row head"
            style={{
              width: "100%",
              margin: "auto",
              height: "50px",
              borderBottom: "1px dotted #000",
            }}
          >
            <div
              className="col col-md-2 col-sm-2 col-2"
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <img
                src={logo}
                style={{
                  height: "40px",
                  borderRadius: " 50%",
                  width: "90px",
                  marginTop: "2px",
                  backgroundColor: "#000000",
                }}
              />
            </div>
            <div
              className="col col-md-10 col-sm-10 col-10 pt-1"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <p
                className=""
                style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
              >
                {selectedRow?.invoice_no} فاتورة رقم
                <br />
                {/* PO Id : {selectedRow.po_id}<br /> */}
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "",
                  }}
                >
                  {moment(selectedRow?.createdAt).format("YYYY-MM-DD HH:mm")} [
                  {selectedRow?.emp_details?.name} ]
                </span>
                {/* <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{name}</span> */}
              </p>
            </div>
          </div>
          <br />
          <strong style={{ color: "black" }}>
            <div style={{ margin: "0px 2px 0px 2px" }}>
              <div className="row">
                <p>
                  <b>{props.t("Billed To")}</b>
                </p>
              </div>
              <p
                className="mb-0 date"
                style={{
                  fontSize: "14px",
                  marginTop: "-15px",
                  width: "100%",
                  margin: "auto",
                }}
              >
                <span>
                  {props.t("Name")}: &nbsp;
                  <b> {selectedRow?.customer_details?.name}</b>
                </span>
              </p>
              <p
                className="mb-0 d-flex justify-content-between"
                style={{ fontSize: "14px" }}
              >
                <span>
                  <span>{props.t("Phone")}:</span> &nbsp;
                  <b>{selectedRow?.customer_details?.mobile}</b>
                </span>
                <span>
                  <span>{props.t("Type")}:</span> &nbsp;
                  <b> {selectedRow?.customer_details?.customer_type}</b>
                </span>
              </p>
            </div>
            <div
              className="row mt-3 mb-0"
              style={{ fontSize: "14px", width: "100%", margin: "auto" }}
            >
              <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-12">
                <div
                  className="table-responsive"
                  style={{
                    width: "109%",
                    marginLeft: "-0.8rem",
                    color: "black",
                  }}
                >
                  {/* Games */}
                  {selectedRow?.game_details?.length > 0 && (
                    <>
                      <div style={{ margin: "0px 2px 0px 2px" }}>
                        <p
                          style={{
                            marginBottom: "1px",
                            borderBottom: "1px dotted #000",
                          }}
                        >
                          <b>{props.t("Games ")}</b>
                        </p>
                        <table
                          id="example"
                          className="table "
                          style={{ color: "black" }}
                        >
                          {/* <thead className="bg-light"  > */}
                          <tr style={{ borderBottom: "1px dotted #000" }}>
                            <td>
                              {" "}
                              رقم <br />
                              {props.t("S.N")}
                            </td>
                            <td>
                              {" "}
                              الوصف <br />
                              {props.t("Description")}
                            </td>
                            <td>
                              الطاولة <br />
                              {props.t("Table")}
                            </td>
                            <td>
                              الوقت <br />
                              {props.t("Duration")}
                            </td>
                            <td>
                              {" "}
                              مجموع <br />
                              {props.t("Sub Total")}
                            </td>
                            {/* <td>{props.t("Start")}</td>
                                  <td>{props.t("End")}</td> */}
                          </tr>
                          {/* </thead> */}
                          {/* <tbody> */}
                          {selectedRow?.game_details?.map((game, index) => (
                            <>
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{game.game?.name}</td>
                                <td>{game.table_number}</td>
                                <td>{game.game?.duration}</td>
                                <td>{game.game?.sub_total}</td>
                                {/* <td>{moment(game.game?.table_start_time).format("HH:mm")}</td>
                                <td>{moment(game.game?.table_end_time).format("HH:mm")}</td> */}
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  {props.t("Start Time")} :{" "}
                                  {moment(game.game?.table_start_time).format(
                                    "HH:mm"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  {props.t("End Time")} :{" "}
                                  {moment(game.game?.table_end_time).format(
                                    "HH:mm"
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr>
                            <td
                              colSpan={4}
                              style={{
                                textAlign: "end",
                                fontWeight: "bold",
                                padding: "2px",
                              }}
                            >
                              {props.t("Game Total")} :
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {selectedRow?.game_total_price || ""}
                            </td>
                          </tr>

                          {/* </tbody> */}
                        </table>
                      </div>
                    </>
                  )}

                  {/* Foods */}
                  {selectedRow?.item_details?.length > 0 &&
                    selectedRow?.item_details?.some(
                      item => item.section !== "Accessories"
                    ) && (
                      <>
                        <div style={{ marginLeft: "2px", marginRight: "2px" }}>
                          <p
                            style={{
                              marginBottom: "1px",
                              borderBottom: "1px dotted #000",
                            }}
                          >
                            <b>{props.t("Foods ")}</b>
                          </p>
                          <table
                            id="example"
                            className="table"
                            style={{ color: "black" }}
                          >
                            {/* <thead className="bg-light"  > */}
                            <tr style={{ borderBottom: "1px dotted #000" }}>
                              <td>
                                {" "}
                                رقم <br />
                                {props.t("S.N")}
                              </td>
                              <td>
                                {" "}
                                الوصف <br />
                                {props.t("Description")}
                              </td>
                              <td>
                                {" "}
                                الكمية <br />
                                {props.t("Qty.")}
                              </td>
                              <td>
                                {" "}
                                السعر <br />
                                {props.t("Price")}
                              </td>
                              <td>
                                {" "}
                                مجموع <br />
                                {props.t("Sub Total")}
                              </td>
                            </tr>
                            {/* </thead> */}
                            {/* <tbody> */}
                            {selectedRow?.item_details
                              ?.filter(item => item.section !== "Accessories")
                              .map((item, index) => (
                                <tr key={index + 1}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    {item.quantity.in_unit > 0 &&
                                      item.quantity.in_unit}{" "}
                                    {item.quantity.in_unit > 0 && item.unit}
                                    {item.quantity.in_sub_unit > 0 &&
                                      item.quantity.in_sub_unit}{" "}
                                    {item.quantity.in_sub_unit > 0 &&
                                      item.sub_unit}
                                  </td>
                                  {/* <td>{item.quantity.in_sub_unit} {item.sub_unit}</td> */}
                                  <td>{item.price}</td>
                                  <td>{item.sub_total}</td>
                                </tr>
                              ))}
                            <tr>
                              <td
                                colSpan={4}
                                style={{
                                  textAlign: "end",
                                  fontWeight: "bold",
                                  padding: "2px",
                                }}
                              >
                                {props.t("Foods Total")} :
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {selectedRow?.item_details
                                  ?.filter(
                                    item => item.section !== "Accessories"
                                  )
                                  .reduce(
                                    (acc, curr) => acc + curr.sub_total,
                                    0
                                  )}
                              </td>
                            </tr>

                            {/* </tbody> */}
                          </table>
                        </div>
                      </>
                    )}

                  {/* Accessories */}
                  {selectedRow?.item_details?.length > 0 &&
                    selectedRow?.item_details?.some(
                      item => item.section === "Accessories"
                    ) && (
                      <>
                        <div style={{ margin: "0px 2px 0px 2px" }}>
                          <p
                            style={{
                              marginBottom: "1px",
                              borderBottom: "1px dotted #000",
                            }}
                          >
                            <b>{props.t("Accessories ")}</b>
                          </p>
                          <table
                            id="example"
                            className="table"
                            style={{ color: "black" }}
                          >
                            {/* <thead className="bg-light" > */}
                            <tr style={{ borderBottom: "1px dotted #000" }}>
                              <td>
                                {" "}
                                رقم <br />
                                {props.t("S.N")}
                              </td>
                              <td>
                                {" "}
                                الوصف <br />
                                {props.t("Description")}
                              </td>
                              <td>
                                {" "}
                                الكمية <br />
                                {props.t("Qty.")}
                              </td>
                              <td>
                                {" "}
                                السعر <br />
                                {props.t("Price")}
                              </td>
                              <td>
                                {" "}
                                مجموع <br />
                                {props.t("SubTotal")}
                              </td>
                            </tr>
                            {/* </thead> */}
                            {/* <tbody> */}
                            {selectedRow?.item_details
                              ?.filter(item => item.section === "Accessories")
                              .map((item, index) => (
                                <tr key={index + 1}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>
                                    {item.quantity.in_unit > 0 &&
                                      item.quantity.in_unit}{" "}
                                    {item.quantity.in_unit > 0 && item.unit}
                                    {item.quantity.in_sub_unit > 0 &&
                                      item.quantity.in_sub_unit}{" "}
                                    {item.quantity.in_sub_unit > 0 &&
                                      item.sub_unit}
                                  </td>
                                  {/* <td>{item.quantity.in_sub_unit} {item.sub_unit}</td> */}
                                  <td>{item.price}</td>
                                  <td>{item.sub_total}</td>
                                </tr>
                              ))}
                            <tr>
                              <td
                                colSpan={4}
                                style={{
                                  textAlign: "end",
                                  padding: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                {props.t("Accessories Total")} :
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {selectedRow?.item_details
                                  ?.filter(
                                    item => item.section === "Accessories"
                                  )
                                  .reduce(
                                    (acc, curr) => acc + curr.sub_total,
                                    0
                                  )}
                              </td>
                            </tr>

                            {/* </tbody> */}
                          </table>
                        </div>
                      </>
                    )}

                  {/* Package */}
                  {selectedRow?.package_details?.length > 0 && (
                    <>
                      {/* Games */}
                      {selectedRow?.package_details[0]?.game_details?.length >
                        0 && (
                        <>
                          <div style={{ margin: "0px 2px 0px 2px" }}>
                            <p
                              style={{
                                marginBottom: "1px",
                                borderBottom: "1px dotted #000",
                              }}
                            >
                              <b>{props.t("Package - Games ")}</b>
                            </p>
                            <table
                              id="example"
                              className="table "
                              style={{ color: "black" }}
                            >
                              {/* <thead className="bg-light"  > */}
                              <tr style={{ borderBottom: "1px dotted #000" }}>
                                <td>
                                  {" "}
                                  رقم <br />
                                  {props.t("S.N")}
                                </td>
                                <td>
                                  {" "}
                                  الوصف <br />
                                  {props.t("Description")}
                                </td>
                                <td>
                                  الطاولة <br />
                                  {props.t("Table")}
                                </td>
                                <td>
                                  الوقت <br />
                                  {props.t("Duration")}
                                </td>
                              </tr>

                              {selectedRow?.package_details[0]?.game_details?.map(
                                (game, index) => (
                                  <>
                                    <tr key={index + 1}>
                                      <td>{index + 1}</td>
                                      <td>{game?.name}</td>
                                      <td>{game.table_number}</td>
                                      <td>{game?.requested_duration}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}>
                                        {props.t("Start Time")} :{" "}
                                        {moment(game?.table_start_time).format(
                                          "HH:mm"
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}>
                                        {props.t("End Time")} :{" "}
                                        {moment(game?.table_end_time).format(
                                          "HH:mm"
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )
                              )}

                              {/* </tbody> */}
                            </table>
                          </div>
                        </>
                      )}

                      {/* Foods */}
                      {selectedRow?.package_details[0]?.item_details?.length >
                        0 && (
                        <>
                          <div style={{ margin: "0px 2px 0px 2px" }}>
                            <p
                              style={{
                                marginBottom: "1px",
                                borderBottom: "1px dotted #000",
                              }}
                            >
                              <b>{props.t("Package - Foods ")}</b>
                            </p>
                            <table
                              id="example"
                              className="table "
                              style={{ color: "black" }}
                            >
                              {/* <thead className="bg-light"  > */}
                              <tr style={{ borderBottom: "1px dotted #000" }}>
                                <td>
                                  {" "}
                                  رقم <br />
                                  {props.t("S.N")}
                                </td>
                                <td>
                                  {" "}
                                  الوصف <br />
                                  {props.t("Description")}
                                </td>
                                <td>
                                  {" "}
                                  الكمية <br />
                                  {props.t("Qty.")}
                                </td>
                              </tr>
                              {/* </thead> */}
                              {/* <tbody> */}
                              {selectedRow?.package_details[0]?.item_details?.map(
                                (item, index) => (
                                  <tr key={index + 1}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      {item.quantity.in_unit > 0 &&
                                        item.quantity.in_unit}{" "}
                                      {item.quantity.in_unit > 0 && item.unit}
                                      {item.quantity.in_sub_unit > 0 &&
                                        item.quantity.in_sub_unit}{" "}
                                      {item.quantity.in_sub_unit > 0 &&
                                        item.sub_unit}
                                    </td>
                                  </tr>
                                )
                              )}
                            </table>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <div
                  style={{
                    borderTop: "1px dotted #000",
                    borderBottom: "1px dotted #000",
                    textAlign: "right",
                  }}
                  className="row"
                >
                  <div className="col-md-11 col-sm-11 col-11 ">
                    Total Amount &nbsp; اجمالى القيمة :
                  </div>

                  <div
                    className="col-md-1 col-sm-1 col-1"
                    style={{ marginLeft: "-1.4rem" }}
                  >
                    {selectedRow?.total_amount}
                  </div>

                  {selectedRow?.discount_amount > 0 && (
                    <div className="row">
                      <div
                        className="col-md-11 col-sm-11 col-11"
                        style={{ marginLeft: "1.2rem" }}
                      >
                        Discount Amount &nbsp;:
                      </div>

                      <div
                        className="col-md-1 col-sm-1 col-1"
                        style={{ marginLeft: "-1.4rem" }}
                      >
                        {selectedRow?.discount_amount}
                      </div>
                    </div>
                  )}

                  {selectedRow?.payment_details?.map((payment, index) => {
                    const { _id, ...paymentDetailsWithoutId } = payment

                    let mappedString = ""
                    for (let key in paymentDetailsWithoutId) {
                      let value = paymentDetailsWithoutId[key]
                      if (key === "Cash") {
                        return (
                          <>
                            <div className="row">
                              <div
                                className="col-md-11 col-sm-11 col-11"
                                style={{ marginLeft: "1.2rem" }}
                              >
                                Paid By {key} نقدا :
                              </div>

                              <div
                                className="col-md-1 col-sm-1 col-1"
                                style={{ marginLeft: "-1.4rem" }}
                              >
                                {value}
                              </div>
                            </div>
                          </>
                        )
                        // mappedString += `Paid By ${key} ${cashArabicword} : ${value}\n`;
                      } else {
                        return (
                          <>
                            <div className="row">
                              <div
                                className="col-md-11 col-sm-11 col-11"
                                style={{ marginLeft: "1.2rem" }}
                              >
                                Paid By {key} :
                              </div>

                              <div
                                className="col-md-1 col-sm-1 col-1"
                                style={{ marginLeft: "-1.4rem" }}
                              >
                                {value}
                              </div>
                            </div>
                          </>
                        )
                        // mappedString += `Paid By ${key}  :  ${value}\n`;
                      }
                    }
                  })}

                  <div className="row">
                    <div
                      className="col-md-11 col-sm-11 col-11"
                      style={{ marginLeft: "1.2rem" }}
                    >
                      Change &nbsp;الباقى :
                    </div>

                    <div
                      className="col-md-1 col-sm-1 col-1"
                      style={{ marginLeft: "-1.4rem" }}
                    >
                      {selectedRow?.change_amount}
                    </div>
                  </div>
                  {selectedRow?.refund?.refund_amount>0 && (
                      <div className="row">
                        <div
                          className="col-md-11 col-sm-11 col-11"
                          style={{ marginLeft: "1.2rem" }}
                        >
                          Refund:
                        </div>

                        <div
                          className="col-md-1 col-sm-1 col-1"
                          style={{ marginLeft: "-1.4rem" }}
                        >
                          {selectedRow?.refund?.refund_amount}
                        </div>
                      </div>
                  )}

                  

                  {selectedRow && selectedRow.bill_split_amount > 0 && (
                    <>
                      <div className="row">
                        <div
                          className="col-sm-11 col-md-11 col-11"
                          style={{ marginLeft: "1.2rem" }}
                        >
                          Split Amount :
                        </div>

                        <div
                          className="col-md-1 col-sm-1 col-1"
                          style={{ marginLeft: "-1.4rem" }}
                        >
                          {selectedRow?.bill_split_amount}
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-md-11 col-sm-11 col-11"
                          style={{ marginLeft: "1.2rem" }}
                        >
                          {props.t("Split Between")} :
                        </div>
                        <div
                          className="col-md-1 col-sm-1 col-1"
                          style={{ marginLeft: "-1.4rem" }}
                        >
                          {selectedRow?.bill_split_between}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row">
                    <div
                      className="col-md-11 col-sm-11 col-11"
                      style={{ marginLeft: "1.2rem" }}
                    >
                      Paid Amount &nbsp;:
                    </div>

                    <div
                      className="col-md-1 col-sm-1 col-1"
                      style={{ marginLeft: "-1.4rem" }}
                    >
                      {selectedRow?.paid_amount || selectedRow?.final_amount}
                    </div>
                  </div>
                </div>

                <div className="row text-center">
                  <div className="col col-lg-12 ">
                    {props.t("Thank you")} <br />
                    {props.t("Visit Again")}
                  </div>
                </div>
              </div>
            </div>
          </strong>
        </div>
      )}

      {isLoading === true ? (
        <div></div>
      ) : (
        <Row className="d-print-none col text-right pr-5 mt-5">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Link
              to="#"
              onClick={() => {
                printInvoice()
                // localStorage.removeItem("invoiceData")
              }}
              className="btn btn-primary"
            >
              <i className="fa fa-print" /> {props.t("Print")}
            </Link>
          </Col>
        </Row>
      )}

      {/* </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Invoice)))
