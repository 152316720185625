import React, { useEffect, useState, useRef } from "react"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
// import "../../assets/icon.css"
import { withTranslation } from "react-i18next"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import exportFromJSON from "export-from-json";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { Country } from "country-state-city"
import ReactAvatarEditor from "react-avatar-editor"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import showNotification from "../../helpers/show_notificaton"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/backend_helper"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import { Link, useLocation } from "react-router-dom"
import moment from "moment"
import ViewFiles from "./ViewFiles"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"
const ViewEmployees = props => {
  const { SearchBar } = Search

  const [printModal, setPrintModal] = useState()
  const [selectedUserPrint, setSelectedUserPrint] = useState("")
  const [editModal, setEditModal] = useState()
  const [imageModal, setImageModal] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [selectedPhoto, setSelectedPhoto] = useState()
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
  const [scale, setScale] = useState(1)
  const [allowZoomOut] = useState(false)
  const [allCountries, setAllCountries] = useState([])
  const location = useLocation();

  useEffect(() => {
    setAllCountries(Country.getAllCountries())
    loadAllDesignations()
    loadAllEmployees()
    loadAllBranches()
  }, []);

  const [viewType, setViewType] = useState("")

  useEffect(() => {
    const handleURLChange = () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("view")) {
        setViewType(urlParams.get("view"));
      } else {
        setViewType("");
      }
    };
    handleURLChange();
    window.addEventListener('popstate', handleURLChange);
    return (()=> window.addEventListener('popstate', handleURLChange))
  }, [location]);


  
  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    printWindow.document.write(`
   
       <html>
       <title>${divName}</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
        
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
         .row{
          display:flex;
          justify-content:space-between;
         }

         
      }
        </style>
       </head>
    <body>
  ${content.innerHTML}
    </body>
       </html>

    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.focus();
  };



  const handleDownload = async () => {
    const fileName = "all_employees";
    const exportType = "xls";

    let data_to_export = allemployees;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Code: v.code,
          Name: v.name,
          Branch: v.branch_details?.name,
          Address: v.address,
          Date_of_Birth: formatDate(v.date_of_birth),
          Date_of_Joining: formatDate(v.date_of_joining),
          Qatar_ID: v.qatarid,
          Qatar_ID_Expirydate: formatDate(v.qatarid_expirydate),
          Passport: v.passport,
          Passport_Expirydate: formatDate(v.passport_expirydate),
          Health_Card_ID: v.healthcardid,
          Healthcard_Expirydate: formatDate(v.healthcardid_expirydate),
          Medicalfit_ID: v.medicalfitid,
          Medicalfit_Expirydate: formatDate(v.medicalfitid_expirydate),
          Driving_License_ID: v.driving_license_id,
          Driving_License_ID_Expirydate: formatDate(v.driving_license_id_expirydate),
          Insurance_Policy_No: v.insurance_policy_no,
          Insurance_Policy_Expirydate: formatDate(v.insurance_policy_expirydate),
          Mobile: v.mobile,
          Email: v.email,
          Designation: v.designation,
          Sponser: v.sponser,
          Nationality: v.nationality,
          Annual_Leave: v.leave.annual_leave,
          Sick_Leave: v.leave.sick_leave,
          Basic_Salary: v.salary.basic_salary,
          Food_Allowance: v.salary.food_allowance,
          Housing_Allowance: v.salary.housing_allowance,
          Other_Allowance: v.salary.other_allowance,
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  const [allemployees, setAllEmployees] = useState([])
  const loadAllEmployees = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "employees/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false)
      setAllEmployees(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  //All Designations
  const [allDesignations, setAllDesignations] = useState([])
  const loadAllDesignations = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "designations/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false);
      setAllDesignations(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  //Branches
  const [allBranches, setAllBranches] = useState([])
  const loadAllBranches = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false)
      setAllBranches(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }


  const handleScale = e => {
    const scale = parseFloat(e.target.value)
    setScale(scale)
  }

  const editorRef = useRef()
  // setEditorRef = (editor) => (this.editor = editor);
  const [rotate] = useState(0)
  const [borderRadius] = useState(0)
  const onPhotoChange = event => {
    setSelectedPhoto(event.target.files[0])
  }

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        ></img>

          <span
            style={{ cursor: "pointer" }}
            hidden={viewType==="report"?true:false}
            onClick={() => {
              setEditModal(false)
              setScale(1)
              setImageModal(!imageModal)
              setSelectedUser(row)
              setSelectedPhoto()
            }}
            className="font-size-12 badge-soft-info badge badge-success badge-pill"
          >
            <i className="mdi mdi-image-edit mr-1" title="Click to Edit"></i>
            {props.t("Change")}
          </span>
      
      </>
    )
  }

  const handleUserStatusUpdate = async employee => {
    let url = process.env.REACT_APP_BASEURL + "employees/activate_deactivate"
    const response = await postSubmitForm(url, {
      id: employee._id,
      is_active: !employee.is_active,
    })
    if (response && response.status === 1) {
      loadAllEmployees()
    } else {
      showNotification(response.message, props.t("Error"))
    }
  }

  function showStatusFormatter(cell, row) {
    // if (row.is_active) {
    return (
      <>
     
          <div className="form-switch mb-3" dir="ltr" hidden={viewType=="report"}>
            <input
              type="checkbox"
              hidden={viewType==="report"}
              style={{ height: "15px", width: "30px" }}
              title="Click to change status."
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleUserStatusUpdate(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
     

        {row.is_active ? (
          <span className="font-size-5 badge-soft-success badge badge-success badge-pill">
            {props.t("Active")}
          </span>
        ) : (
          <span className="font-size-5 badge-soft-danger badge badge-danger badge-pill">
            {props.t("Inactive")}
          </span>
        )}
      </>
    )
  }

  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedUser(row)
            // setQatarID(row.qatarid.length > 0)
            // setPassport(row.passport.length > 0)
          }}
        ></i>
      </span>
    )
  }

  // Validation for selected designation
  const [selectedDesignation, setSelectedDesignation] = useState("")
  const [medicalRequired, setMedicalRequired] = useState(false)
  const [drivingLicenseRequired, setDrivingLicenseRequired] = useState(false)

  const handleDesignationChange = event => {
    const selectedValue = event.target.value
    const selectedDesignation = allDesignations.find(
      designation => designation.name === selectedValue
    )

    setSelectedDesignation(selectedValue)
    setMedicalRequired(
      selectedDesignation.is_medicalfitid_required ? true : false
    )
    setDrivingLicenseRequired(
      selectedDesignation.is_driving_license_required ? true : false
    )
  }
  //field Validation
  const [qatarID, setQatarID] = useState(null)
  const [qatarid_expiry_date, setQatarid_expiry_date] = useState(null)
  const [passport, setPassport] = useState(null)
  const [passport_expiry_date, setPassport_expiry_date] = useState(null)
  const [medicalfitid, setMedicalfitid] = useState(null)
  const [medical_expiry_date, setMedical_expiry_date] = useState(null)
  const [healthid, setHealthid] = useState(null)
  const [health_expiry_date, setHealth_expiry_date] = useState(null)
  const [drivinglicense, setDrivinglicense] = useState(null)
  const [driving_expiry_date, setDriving_expiry_date] = useState(null)
  const [insurance, setInsurance] = useState(null)
  const [insurance_expiry_date, setInsurance_expiry_date] = useState(null)
  const [qatarid_doc_url, set_qatarid_doc_url] = useState()
  const [passport_doc_url, set_passport_doc_url] = useState()
  const [healthcardid_doc_url, set_healthcardid_doc_url] = useState()
  const [medicalfitid_doc_url, set_medicalfitid_doc_url] = useState()
  const [driving_license_doc_url, set_driving_license_doc_url] = useState()
  const [insurance_policy_doc_url, set_insurance_policy_doc_url] = useState()
 

  const handleValidUpdate = async (e, v) => {
    const data_to_send = {}
    data_to_send.id = selectedUser._id
    data_to_send.name = v.name
    data_to_send.code = v.code
    data_to_send.email = v.email
    data_to_send.mobile = v.mobile
    data_to_send.date_of_birth = v.date_of_birth
    data_to_send.date_of_joining = v.date_of_joining
    data_to_send.qatarid = v.qatarid
    data_to_send.qatarid_expirydate = v.qatarid_expirydate
    data_to_send.address = v.address
    data_to_send.nationality = v.nationality
    data_to_send.passport = v.passport
    data_to_send.passport_expirydate = v.passport_expirydate
    data_to_send.medicalfitid = v.medicalfitid
    data_to_send.medicalfitid_expirydate = v.medicalfitid_expirydate
    data_to_send.healthcardid = v.healthcardid
    data_to_send.healthcardid_expirydate = v.healthcardid_expirydate
    data_to_send.insurance_policy_no = v.insurance_policy_no
    data_to_send.insurance_policy_expirydate = v.insurance_policy_expirydate
    data_to_send.driving_license_id = v.driving_license_id
    data_to_send.driving_license_id_expirydate = v.driving_license_id_expirydate
    data_to_send.sponser = v.sponser
    data_to_send.designation = v.designation
    data_to_send.basic_salary = v.basic_salary
    data_to_send.food_allowance = v.food_allowance
    data_to_send.housing_allowance = v.housing_allowance
    data_to_send.other_allowance = v.other_allowance
    data_to_send.annual_leave = v.annual_leave
    data_to_send.sick_leave = v.sick_leave
    // if (v.branch) {
    //   data_to_send.branch_id = v.branch
    // }

    let url = process.env.REACT_APP_BASEURL + "employees/update"
    const response = await postSubmitForm(url, data_to_send)
    if (response.status === 1 && response.data) {
      setQatarID(null)
      setQatarid_expiry_date(null)
      setPassport(null)
      setPassport_expiry_date(null)
      setHealthid(null)
      setHealth_expiry_date(null)
      setMedicalfitid(null)
      setMedical_expiry_date(null)
      setInsurance(null)
      setInsurance_expiry_date(null)
      setDrivinglicense(null)
      setDriving_expiry_date(null)
      url = process.env.REACT_APP_BASEURL + "employees/upload_document"

      if (qatarid_doc_url) {
        let formData = new FormData()

        formData.set("id", response.data._id)
        formData.set("document_name", "qatarid_doc_url")
        formData.append("document_file", qatarid_doc_url)

        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1 && response.data) {
            //setSelectedPhoto()
          }
        })
      }

      if (passport_doc_url) {
        let formData = new FormData()

        formData.set("id", response.data._id)
        formData.set("document_name", "passport_doc_url")
        formData.append("document_file", passport_doc_url)

        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1 && response.data) {
            //setSelectedPhoto()
          }
        })
      }

      if (healthcardid_doc_url) {
        let formData = new FormData()

        formData.set("id", response.data._id)
        formData.set("document_name", "healthcardid_doc_url")
        formData.append("document_file", healthcardid_doc_url)

        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1 && response.data) {
            //setSelectedPhoto()
          }
        })
      }

      if (medicalfitid_doc_url) {
        let formData = new FormData()

        formData.set("id", response.data._id)
        formData.set("document_name", "medicalfitid_doc_url")
        formData.append("document_file", medicalfitid_doc_url)

        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1 && response.data) {
            //setSelectedPhoto()
          }
        })
      }

      if (driving_license_doc_url) {
        let formData = new FormData()

        formData.set("id", response.data._id)
        formData.set("document_name", "driving_license_doc_url")
        formData.append("document_file", driving_license_doc_url)

        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1 && response.data) {
            //setSelectedPhoto()
          }
        })
      }

      if (insurance_policy_doc_url) {
        let formData = new FormData()

        formData.set("id", response.data._id)
        formData.set("document_name", "insurance_policy_doc_url")
        formData.append("document_file", insurance_policy_doc_url)

        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1 && response.data) {
            //setSelectedPhoto()
          }
        })
      }
      showNotification(response.message, "Success")
      loadAllBranches()
      loadAllEmployees()


      setEditModal(!editModal)
    } else {
      setQatarID(null)
      setQatarid_expiry_date(null)
      setPassport(null)
      setPassport_expiry_date(null)
      setHealthid(null)
      setHealth_expiry_date(null)
      setMedicalfitid(null)
      setMedical_expiry_date(null)
      setInsurance(null)
      setInsurance_expiry_date(null)
      setDrivinglicense(null)
      setDriving_expiry_date(null)
      setQatarID(null)
      setQatarid_expiry_date(null)
      setPassport(null)
      setPassport_expiry_date(null)
      setHealthid(null)
      setHealth_expiry_date(null)
      setMedicalfitid(null)
      setMedical_expiry_date(null)
      setInsurance(null)
      setInsurance_expiry_date(null)
      setDrivinglicense(null)
      setDriving_expiry_date(null)
      showNotification(response.message, "Error")
    }
  }

  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType })
      })
  }

  const handleUpdatePhoto = async (e, v) => {
    srcToFile(
      editorRef.current.getImageScaledToCanvas().toDataURL("image/png"),
      "new_image.png",
      "image/png"
    ).then(function (file) {
      let formData = new FormData()
      formData.set("id", selectedUser._id)
      formData.set("document_name", "image_url")
      formData.append("document_file", file)
      let url = process.env.REACT_APP_BASEURL + "employees/upload_document"
      postSubmitForm_withformdata(url, formData).then(api_response => {
        if (api_response.status === 1 && api_response.data) {
          loadAllEmployees()
          setEditModal(false)
          setImageModal(!imageModal)
          setSelectedPhoto()
          showNotification(api_response.message, props.t("Success"))
        } else {
          showNotification(api_response.message, props.t("Error"))
        }
      })
    })
  }

  const renderDocumentLink = (documentType, documentFileUrl) => {
    if (documentFileUrl) {
      return (<>
        <a href={"/view_docs?docs=" + documentFileUrl} target="_blank" rel="noreferrer">
          {documentType}
        </a>
      </>
      )
    }
    return <span style={{ color: "gray" }}>Not available</span>
  }

  function viewDetails(cell, row) {
    return (
      <i
        style={{ color: "blue", cursor: "pointer", justifyContent: "center", display: 'flex' }}
        onClick={() => {
          setPrintModal(prev => prev = !prev)
          setSelectedUserPrint(row)
        }}
      >
        {row.code}
      </i>
    );
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Code"),
      //formatter: employeeFormatter,
      dataField: "code",
      sort: true,
      formatter: viewDetails,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Designation"),
      dataField: "designation",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Email"),
      //formatter: employeeFormatter,
      dataField: "email",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      text: props.t("Mobile"),
      dataField: "mobile",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Qatar ID"),
      dataField: "qatarid",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      //dataField: "",
      text: props.t("Photo"),
      formatter: showPhotoFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
    },
    {
      dataField: "is_active",
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      //dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      hidden: viewType === "report",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]
  const defaultSorted = [{
    dataField: 'is_active',
    order: 'desc'
  }];

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem1={props.t("View Employees")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}>
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Employees")}
                      </Col>
                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>

                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing employees here")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    data={allemployees && allemployees}
                    columns={columns}
                    search
                  >
                    {propst => (
                      <div>
                        <Row>
                          <Col
                            lg={4}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <SearchBar
                              {...propst.searchProps}
                              style={{ width: "340px" }}
                            />
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          defaultSorted={defaultSorted}
                          filter={filterFactory()}
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="xl"
                    isOpen={editModal}
                    toggle={() => {
                      setEditModal(!editModal);
                      setQatarID(null)
                      setQatarid_expiry_date(null)
                      setPassport(null)
                      setPassport_expiry_date(null)
                      setHealthid(null)
                      setHealth_expiry_date(null)
                      setMedicalfitid(null)
                      setMedical_expiry_date(null)
                      setInsurance(null)
                      setInsurance_expiry_date(null)
                      setDrivinglicense(null)
                      setDriving_expiry_date(null)
                    }}
                  >

                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Name - ")}
                      {selectedUser && selectedUser.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (

                        <Row>
                          <Col lg={12}>
                            <Card>
                              <CardBody>
                                <AvForm
                                  onValidSubmit={(e, v) => {
                                    handleValidUpdate(e, v)
                                  }}
                                >
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label="Name"
                                        type="text"
                                        name="name"
                                        value={selectedUser.name}
                                        placeholder={props.t("Enter Name")}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t("Name cannot be empty")}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Employee Code")}
                                        type="text"
                                        name="code"
                                        value={selectedUser.code}
                                        placeholder={props.t("Enter Employee Code")}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t("Employee code cannot be empty")}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        className="form-control"
                                        name="date_of_birth"
                                        label={props.t("Date of Birth")}
                                        placeholder={props.t("Select date of Birth")}
                                        type="select"
                                        tag={Flatpickr}
                                        options={{
                                          onDayCreate: (
                                            dObj,
                                            dStr,
                                            fp,
                                            dayElem
                                          ) => {
                                            dayElem.addEventListener(
                                              "mousedown",
                                              e => {
                                                e.preventDefault() // Prevent default click behavior
                                              }
                                            )
                                          },
                                        }}
                                        value={formatDate(
                                          selectedUser.date_of_birth
                                        )}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t("Date of Birth cannot be empty")}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Date of Joining")}
                                        className="form-control"
                                        type="select"
                                        tag={Flatpickr}
                                        options={{
                                          onDayCreate: (
                                            dObj,
                                            dStr,
                                            fp,
                                            dayElem
                                          ) => {
                                            dayElem.addEventListener(
                                              "mousedown",
                                              e => {
                                                e.preventDefault() // Prevent default click behavior
                                              }
                                            )
                                          },
                                        }}
                                        name="date_of_joining"
                                        placeholder={props.t("Enter Joining date")}
                                        value={formatDate(
                                          selectedUser.date_of_joining
                                        )}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Date of Joining cannot be empty"
                                        )}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Email")}
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        value={selectedUser.email}
                                        placeholder="Enter Email"
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Email cannot be empty"
                                        )}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Mobile")}
                                        className="form-control"
                                        type="number"
                                        name="mobile"
                                        value={selectedUser.mobile}
                                        placeholder={props.t(
                                          "Enter mobile number"
                                        )}
                                        validate={{
                                          required: { value: true },
                                          maxLength: {
                                            value: 13,
                                            errorMessage: props.t(
                                              "Maximum 13 Digits are allowed."
                                            ),
                                          },
                                        }}
                                        errorMessage={props.t("Phone number cannot be empty")}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label="Address"
                                        className="form-control"
                                        type="textarea"
                                        name="address"
                                        value={selectedUser.address}
                                        placeholder={props.t("Enter Address")}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Address cannot be empty"
                                        )}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        label="Sponser"
                                        className="form-control"
                                        type="select"
                                        name="sponser"
                                        value={selectedUser.sponser}
                                        placeholder={props.t(
                                          "--Select Sponser--"
                                        )}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Sponser cannot be empty"
                                        )}
                                      >
                                        <option value="">
                                          -- {props.t("Select Sponser")} --
                                        </option>
                                        <option value="Rendezvous">
                                          {props.t("Rendezvous")}
                                        </option>
                                        <option value="Outside">
                                          {props.t("Outside")}
                                        </option>
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Designation")}
                                        className="form-control"
                                        type="select"
                                        name="designation"
                                        onChange={handleDesignationChange}
                                        value={selectedUser.designation}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          " Select Designation"
                                        )}
                                      >
                                        <option value="">
                                          -- {props.t("Select Designation")}{" "}
                                          --
                                        </option>
                                        {allDesignations &&
                                          allDesignations.map(designation => (
                                            <option
                                              key={designation._id}
                                              value={designation._name}
                                            >
                                              {designation.name}
                                            </option>
                                          ))}
                                      </AvField>
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Branch") + " *"}
                                        className="form-control"
                                        type="text"
                                        name="branch"
                                        disabled={true}
                                        value={selectedUser.branch_details ? selectedUser.branch_details.name : "N/A"}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Select Branch"
                                        )}
                                      >
                                        {/* <option value="">
                                            --Select Branch--
                                          </option>
                                          {allBranches &&
                                            allBranches.map(branch => (
                                              <option
                                                key={branch._id}
                                                value={branch._id}
                                              >
                                                {branch.name}
                                              </option>
                                            ))} */}
                                      </AvField>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Qatar ID")}
                                        className="form-control"
                                        type="number"
                                        name="qatarid"
                                        placeholder="Enter Qatar ID"
                                        value={selectedUser.qatarid}
                                        errorMessage={props.t(
                                          "Both Qatar ID and Passport Number cannot be empty."
                                        )}
                                        onChange={e => {
                                          setQatarID(e.target.value)
                                          set_qatarid_doc_url(null)
                                        }}
                                        validate={{
                                          required: {
                                            value:
                                              !selectedUser.passport &&
                                              !passport,
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={8}>
                                          <AvField
                                            label={props.t(
                                              "Qatar ID Expiry Date"
                                            )}
                                            name="qatarid_expirydate"
                                            className="form-control"
                                            placeholder="Select Qatar ID Expiry Date"
                                            type="select"
                                            tag={Flatpickr}
                                            options={{
                                              onDayCreate: (
                                                dObj,
                                                dStr,
                                                fp,
                                                dayElem
                                              ) => {
                                                dayElem.addEventListener(
                                                  "mousedown",
                                                  e => {
                                                    e.preventDefault() // Prevent default click behavior
                                                  }
                                                )
                                              },
                                            }}
                                            value={formatDate(
                                              selectedUser.qatarid_expirydate
                                            )}
                                            onChange={e => setQatarid_expiry_date(e.target.value)}
                                            validate={{
                                              required: {
                                                value: qatarID,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Select Qatar ID Expiry Date"
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <Label>&nbsp;</Label>
                                          {renderDocumentLink(
                                            "View Qatar ID",
                                            selectedUser.qatarid_doc_url
                                          )}
                                          <AvField
                                            className="btn btn-primary btn-sm"
                                            name="qatarid_doc_url"
                                            type="file"
                                            onChange={e => {
                                              set_qatarid_doc_url(
                                                e.target.files[0]
                                              )
                                            }}
                                            validate={{
                                              required: {
                                                value: qatarID || qatarid_expiry_date,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Please Upload Qatar ID"
                                            )}
                                          >
                                            {props.t("Upload File")}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Passport No.")}
                                        className="form-control"
                                        type="text"
                                        name="passport"
                                        value={selectedUser.passport}
                                        placeholder={props.t(
                                          "Enter passport No."
                                        )}
                                        errorMessage={props.t(
                                          "Both Qatar ID and Passport Number cannot be empty."
                                        )}
                                        onChange={e => {
                                          setPassport(e.target.value)
                                          set_passport_doc_url(null)
                                        }}
                                        validate={{
                                          required: {
                                            value:
                                              !selectedUser.qatarid &&
                                              !qatarID,
                                          },
                                        }}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <Row>
                                        <Col l={8}>
                                          <AvField
                                            label={props.t(
                                              "Passport Expiry Date"
                                            )}
                                            className="form-control"
                                            placeholder="Select Qatar ID Expiry Date"
                                            name="passport_expirydate"
                                            value={formatDate(
                                              selectedUser.passport_expirydate
                                            )}
                                            type="select"
                                            tag={Flatpickr}
                                            options={{
                                              onDayCreate: (
                                                dObj,
                                                dStr,
                                                fp,
                                                dayElem
                                              ) => {
                                                dayElem.addEventListener(
                                                  "mousedown",
                                                  e => {
                                                    e.preventDefault() // Prevent default click behavior
                                                  }
                                                )
                                              },
                                            }}
                                            onChange={e =>
                                              setPassport_expiry_date(
                                                e.target.value
                                              )
                                            }
                                            validate={{
                                              required: {
                                                value: passport,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Select Passport Expiry Date"
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <Label>&nbsp;</Label>
                                          {renderDocumentLink(
                                            "View Passport",
                                            selectedUser.passport_doc_url
                                          )}
                                          <AvField
                                            className="btn btn-primary wave-effect btn-sm"
                                            name="passport_doc_url"
                                            type="file"
                                            onChange={e => {
                                              set_passport_doc_url(
                                                e.target.files[0]
                                              )
                                            }}
                                            validate={{
                                              required: {
                                                value:
                                                  passport || qatarid_expiry_date,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Please Upload Passport"
                                            )}
                                          >
                                            {props.t("Upload File")}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Health Card No.")}
                                        name="healthcardid"
                                        value={selectedUser.healthcardid}
                                        type="text"
                                        placeholder={props.t(
                                          "Enter Health Card No."
                                        )}
                                        onChange={e =>
                                          setHealthid(e.target.value)
                                        }
                                        validate={{}}
                                      />
                                    </Col>

                                    <Col lg={6}>
                                      <Row>
                                        <Col lang={8}>
                                          <AvField
                                            name="healthcardid_expirydate"
                                            className="form-control"
                                            label={props.t(
                                              "Health Card Expiry Date"
                                            )}
                                            placeholder={props.t(
                                              "Select Health Card Expiry Date"
                                            )}
                                            onChange={e => setHealth_expiry_date(e.target.value)}
                                            value={formatDate(
                                              selectedUser.healthcardid_expirydate
                                            )}
                                            type="select"
                                            tag={Flatpickr}
                                            options={{
                                              onDayCreate: (
                                                dObj,
                                                dStr,
                                                fp,
                                                dayElem
                                              ) => {
                                                dayElem.addEventListener(
                                                  "mousedown",
                                                  e => {
                                                    e.preventDefault() // Prevent default click behavior
                                                  }
                                                )
                                              },
                                            }}
                                            validate={{
                                              required: {
                                                value: healthid
                                                  ? true
                                                  : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Select Health Card Expiry Date"
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <Label>&nbsp;</Label>

                                          {renderDocumentLink(
                                            "View Health ID",
                                            selectedUser.healthcardid_doc_url
                                          )}
                                          <AvField
                                            className="btn btn-primary btn-sm"
                                            name="healthcardid_doc_url"
                                            type="file"
                                            onChange={e => {
                                              set_healthcardid_doc_url(
                                                e.target.files[0]
                                              )
                                            }}
                                            validate={{
                                              required: {
                                                value:
                                                  healthid ||
                                                    health_expiry_date
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Please Upload Health Card"
                                            )}
                                          >
                                            {props.t("Upload File")}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Medical Fit ID")}
                                        onChange={e =>
                                          setMedicalfitid(e.target.value)
                                        }
                                        className="form-control"
                                        type="text"
                                        name="medicalfitid"
                                        placeholder={props.t(
                                          "Enter Medical fit ID"
                                        )}
                                        //validate={{}}
                                        validate={{
                                          required: {
                                            value: medicalRequired
                                              ? true
                                              : false,
                                          },
                                        }}
                                        errorMessage={props.t(
                                          "Enter Medical Fit ID"
                                        )}
                                        value={selectedUser.medicalfitid}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={8}>
                                          <AvField
                                            label={props.t(
                                              "Medical Fit ID Expiry Date"
                                            )}
                                            name="medicalfitid_expirydate"
                                            placeholder={props.t(
                                              "Select Medical Expiry Date"
                                            )}
                                            className="form-control"
                                            onChange={e => setMedical_expiry_date(e.target.value)}
                                            value={formatDate(
                                              selectedUser.medicalfitid_expirydate
                                            )}
                                            type="select"
                                            tag={Flatpickr}
                                            options={{
                                              onDayCreate: (
                                                dObj,
                                                dStr,
                                                fp,
                                                dayElem
                                              ) => {
                                                dayElem.addEventListener(
                                                  "mousedown",
                                                  e => {
                                                    e.preventDefault() // Prevent default click behavior
                                                  }
                                                )
                                              },
                                            }}
                                            validate={{
                                              required: {
                                                value: medicalfitid
                                                  ? true
                                                  : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              " Select Medical Expiry Date"
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <Label>&nbsp;</Label>
                                          {renderDocumentLink(
                                            "View Medical ID",
                                            selectedUser.medicalfitid_doc_url
                                          )}
                                          <AvField
                                            className="btn btn-primary btn-sm"
                                            name="medicalfitid_doc_url"
                                            type="file"
                                            onChange={e => {
                                              set_medicalfitid_doc_url(
                                                e.target.files[0]
                                              )
                                            }}
                                            validate={{
                                              required: {
                                                value:
                                                  medicalfitid || medical_expiry_date ? true : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Please Upload Medical"
                                            )}
                                          >
                                            {props.t("Upload File")}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t(
                                          "Insurance Policy No."
                                        )}
                                        className="form-control"
                                        type="text"
                                        name="insurance_policy_no"
                                        placeholder="Enter Insurance No."
                                        value={
                                          selectedUser.insurance_policy_no
                                        }
                                        onChange={e =>
                                          setInsurance(e.target.value)
                                        }
                                        validate={{}}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={8}>
                                          <AvField
                                            label={props.t(
                                              "Insurance Policy Expiry Date"
                                            )}
                                            name="insurance_policy_expirydate"
                                            className="form-control"
                                            placeholder={props.t(
                                              "Select Insurance Policy Expiry Date"
                                            )}
                                            value={formatDate(
                                              selectedUser.insurance_policy_expirydate
                                            )}
                                            type="select"
                                            tag={Flatpickr}
                                            options={{
                                              onDayCreate: (
                                                dObj,
                                                dStr,
                                                fp,
                                                dayElem
                                              ) => {
                                                dayElem.addEventListener(
                                                  "mousedown",
                                                  e => {
                                                    e.preventDefault() // Prevent default click behavior
                                                  }
                                                )
                                              },
                                            }}
                                            onChange={e => setInsurance_expiry_date(e.target.value)}
                                            validate={{
                                              required: {
                                                value: insurance ? true : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Select Insurance Expiry Date"
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <Label>&nbsp;</Label>
                                          {renderDocumentLink(
                                            "View Insurance",
                                            selectedUser.insurance_policy_doc_url
                                          )}

                                          <AvField
                                            className="btn btn-primary btn-sm"
                                            name="insurance_policy_doc_url"
                                            type="file"
                                            onChange={e => {
                                              set_insurance_policy_doc_url(
                                                e.target.files[0]
                                              )
                                            }}
                                            validate={{
                                              required: {
                                                value:
                                                  insurance ||
                                                    insurance_expiry_date
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Please Upload Insurance"
                                            )}
                                          >
                                            {props.t("Upload File")}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label={props.t("Driving License No.")}
                                        className="form-control"
                                        type="text"
                                        name="driving_license_id"
                                        plplaceholder={props.t(
                                          "Enter driving license no."
                                        )}
                                        value={
                                          selectedUser.driving_license_id
                                        }
                                        onChange={e =>
                                          setDrivinglicense(e.target.value)
                                        }
                                        validate={{
                                          required: {
                                            value: drivingLicenseRequired
                                              ? true
                                              : false,
                                          },
                                        }}
                                        errorMessage={props.t(
                                          "Enter Driving License ID"
                                        )}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <Row>
                                        <Col lg={8}>
                                          <AvField
                                            label={props.t(
                                              "Driving License Expiry Date"
                                            )}
                                            className="form-control"
                                            placeholder={props.t(
                                              "Select Driving License Expiry Date"
                                            )}
                                            type="select"
                                            tag={Flatpickr}
                                            options={{
                                              onDayCreate: (
                                                dObj,
                                                dStr,
                                                fp,
                                                dayElem
                                              ) => {
                                                dayElem.addEventListener(
                                                  "mousedown",
                                                  e => {
                                                    e.preventDefault() // Prevent default click behavior
                                                  }
                                                )
                                              },
                                            }}
                                            name="driving_license_id_expirydate"
                                            value={formatDate(
                                              selectedUser.driving_license_id_expirydate
                                            )}
                                            onChange={e =>
                                              setDriving_expiry_date(
                                                e.target.value
                                              )
                                            }
                                            validate={{
                                              required: {
                                                value: drivinglicense
                                                  ? true
                                                  : false,
                                              },
                                            }}
                                            errorMessage={props.t(
                                              "Select Expiry Date"
                                            )}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <Label>&nbsp;</Label>
                                          {renderDocumentLink(
                                            "View Driving Lic",
                                            selectedUser.driving_license_doc_url
                                          )}
                                          <AvField
                                            className="btn btn-primary btn-sm"
                                            name="driving_license_doc_url"
                                            type="file"
                                            validate={{
                                              required: {
                                                value:
                                                  drivinglicense ||
                                                    driving_expiry_date
                                                    ? true
                                                    : false,
                                              },
                                            }}
                                            onChange={e => {
                                              set_driving_license_doc_url(
                                                e.target.files[0]
                                              )
                                            }}
                                            errorMessage={props.t(
                                              " Please Upload Document "
                                            )}
                                          >
                                            {props.t("Upload File")}
                                          </AvField>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Basic Salary")}
                                        className="form-control"
                                        type="number"
                                        name="basic_salary"
                                        placeholder={props.t(
                                          "Enter Basic Salary"
                                        )}
                                        value={selectedUser.salary.basic_salary.toString()}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Basic Salary cannot be empty"
                                        )}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Food Allowance")}
                                        className="form-control"
                                        type="number"
                                        name="food_allowance"
                                        value={selectedUser.salary.food_allowance.toString()}
                                        placeholder={props.t(
                                          "Enter Food Allowance"
                                        )}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Food Allowance cannot be empty"
                                        )}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Housing Allowance")}
                                        className="form-control"
                                        type="number"
                                        name="housing_allowance"
                                        value={selectedUser.salary.housing_allowance.toString()}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Housing Allowance cannot be empty"
                                        )}
                                        placeholder={props.t(
                                          "Enter Housing Allowance"
                                        )}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Other Allowance")}
                                        className="form-control"
                                        type="number"
                                        name="other_allowance"
                                        placeholder={props.t(
                                          "Enter Other Allowance"
                                        )}
                                        value={selectedUser.salary.other_allowance.toString()}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Other Allowance cannot be empty"
                                        )}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Annual Leave")}
                                        className="form-control"
                                        type="number"
                                        name="annual_leave"
                                        placeholder="Enter Annual Leave"
                                        value={selectedUser.leave.annual_leave.toString()}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Annual Leave cannot be empty"
                                        )}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        label={props.t("Sick Leave")}
                                        className="form-control"
                                        type="number"
                                        name="sick_leave"
                                        placeholder="Enter Sick Leave"
                                        value={selectedUser.leave.sick_leave.toString()}
                                        validate={{
                                          required: { value: true },
                                        }}
                                        errorMessage={props.t(
                                          "Sick Leave cannot be empty"
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        label="Nationality"
                                        name="nationality"
                                        type="select"
                                        value={selectedUser.nationality}
                                      >
                                        <option value="">
                                          -- {props.t("Select Nationality")}{" "}
                                          --
                                        </option>
                                        <option value="Qatar">
                                          {props.t("Qatar")}
                                        </option>
                                        {allCountries &&
                                          allCountries.map((item, ind) => {
                                            return (
                                              <option
                                                key={ind}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          })}
                                      </AvField>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={4}>
                                      <div className="mt-4"></div>
                                    </Col>
                                    <Col lg={4}>
                                      <div
                                        className="mt-4"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          type="submit"
                                          className="btn btn-info btn-lg"
                                        >
                                          Update
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="mt-4"></div>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                      )}
                    </ModalBody>
                  </Modal>

                  {/* Update Photo */}
                  <Modal
                    size="md"
                    isOpen={imageModal}
                    toggle={() => setImageModal(!imageModal)}
                  >
                    <ModalHeader toggle={() => setImageModal(!imageModal)}>
                      {props.t("Name")}: {selectedUser && selectedUser.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm
                                    onValidSubmit={(e, v) => {
                                      handleUpdatePhoto(e, v)
                                    }}
                                  >
                                    <Row>
                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center d-flex justify-content-center">
                                          <div>
                                            <ReactAvatarEditor
                                              ref={editorRef}
                                              scale={parseFloat(scale)}
                                              width={200}
                                              height={200}
                                              position={position}
                                              onPositionChange={
                                                position => {
                                                  setPosition(position)
                                                }
                                                //this.handlePositionChange
                                              }
                                              rotate={rotate}
                                              borderRadius={
                                                100 / (100 / borderRadius)
                                              }
                                              image={
                                                selectedPhoto && selectedPhoto
                                              }
                                              className="editor-canvas"
                                            />
                                            <br />
                                            <br />
                                            <input
                                              name="scale"
                                              type="range"
                                              onChange={handleScale}
                                              min={allowZoomOut ? "0.1" : "1"}
                                              max="2"
                                              step="0.01"
                                              defaultValue="1"
                                            />
                                            <br />
                                            <br />
                                            <Input
                                              type="file"
                                              id="resume"
                                              className="form-control-file"
                                              onChange={onPhotoChange}
                                            />
                                          </div>
                                        </FormGroup>
                                        <hr />
                                      </Col>

                                      <Col lg={12}>
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              className="mr-1"
                                              disabled={
                                                selectedPhoto && selectedPhoto
                                                  ? false
                                                  : true
                                              }
                                            >
                                              {props.t("Save")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* View Details Modal */}

          <Row>
            <Col>
              <Modal
                size="xl"
                isOpen={printModal}
                toggle={() => setPrintModal(prev => prev = !prev)}
              >
                <ModalHeader toggle={() => setPrintModal(prev => prev = !prev)} >
                  {props.t("Employee Details")}&nbsp;-{selectedUserPrint?.name}
                </ModalHeader >
                <ModalBody>
                  <div id="printDiv">

                    <div className="container pl-4  pr-0">
                      <div className="row head" style={{ backgroundColor: " #f0f0f0", height: "100px", borderRadius: "50px 0px 0px 50px" }}>
                        <div className="col col-md-2 col-sm-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                          <img src={selectedUserPrint?.image_url} style={{ height: "105px", borderRadius: " 50%", width: "110px", backgroundColor: "#000000" }} />
                        </div>
                        <div className="col col-md-10 col-sm-10 pt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                          <p className="" style={{ color: "#000000", fontWeight: "500" }}>
                            {selectedUserPrint?.name} <br />
                            Emp.Code  {selectedUserPrint?.code}<br />
                            {selectedUserPrint?.designation}<br />
                            <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}></span>
                          </p>
                        </div>
                      </div>
                      <br />
                      <br />





                      <div className="row">
                        <div className="col col-md-sm-lg-md-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Name &nbsp; &nbsp;
                              <b> {selectedUserPrint?.name}</b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Employee Code &nbsp; &nbsp;
                              <b> {selectedUserPrint?.code}</b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Date Of Birth &nbsp; &nbsp;
                              <b> {moment(selectedUserPrint?.date_of_birth).format("DD-MM-YYYY")}</b>
                            </span>
                          </p>
                        </div>

                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Date Of Joining &nbsp; &nbsp;
                              <b>
                                {
                                  selectedUserPrint.date_of_joining ? moment(selectedUserPrint?.date_of_joining).format("DD-MM-YYYY") : null
                                }
                              </b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Email &nbsp; &nbsp;
                              <b> {selectedUserPrint?.email}</b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Mobile &nbsp; &nbsp;
                              <b> {selectedUserPrint?.mobile}</b>
                            </span>
                          </p>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Address &nbsp; &nbsp;
                              <b> {selectedUserPrint?.address}</b>
                            </span>
                          </p>
                        </div>


                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Sponser &nbsp; &nbsp;
                              <b> {selectedUserPrint?.sponser}</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Branch &nbsp; &nbsp;
                              <b> {selectedUserPrint?.branch_details?.name}</b>
                            </span>
                          </p>
                        </div>
                      </div>

                      <br />


                      <div className="row">


                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Qatar ID &nbsp; &nbsp;
                              <b> {selectedUserPrint.qatarid
                                ?
                                selectedUserPrint.qatarid
                                :
                                <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Qatar ID Expiry Date &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.qatarid_expirydate
                                  ?
                                  moment(selectedUserPrint?.qatarid_expirydate).format("DD-MM-YYYY") :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Document &nbsp; &nbsp;
                              {renderDocumentLink(
                                "View Qatar ID",
                                selectedUserPrint?.qatarid_doc_url
                              )} <br />
                            </span>
                          </p>
                        </div>


                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Passport No. &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.passport
                                  ?
                                  selectedUserPrint.passport
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Passport Expiry Date &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.passport_expirydate
                                  ?
                                  moment(selectedUserPrint?.passport_expirydate).format("DD-MM-YYYY")
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Document &nbsp; &nbsp;
                              {renderDocumentLink(
                                "View Qatar ID",
                                selectedUserPrint?.passport_doc_url
                              )} <br />
                            </span>
                          </p>
                        </div>

                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Health Card &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.healthcardid
                                  ?
                                  selectedUserPrint.healthcardid
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Health Card Expiry Date &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.healthcardid_expirydate
                                  ?
                                  moment(selectedUserPrint?.healthcardid_expirydate).format("DD-MM-YYYY")
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Document &nbsp; &nbsp;
                              {renderDocumentLink(
                                "View Health Card",
                                selectedUserPrint?.healthcardid_doc_url
                              )} <br />
                            </span>
                          </p>
                        </div>



                      </div>

                      <br />

                      <div className="row">

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Insurance Policy &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.insurance_policy_no
                                  ?
                                  selectedUserPrint.insurance_policy_no
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Insurance  Expiry Date &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.insurance_policy_expirydate
                                  ?
                                  moment(selectedUserPrint?.insurance_policy_expirydate).format("DD-MM-YYYY")
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Document &nbsp; &nbsp;
                              {renderDocumentLink(
                                "View Insurance",
                                selectedUserPrint?.insurance_policy_doc_url
                              )} <br />
                            </span>
                          </p>
                        </div>

                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Medical Fit ID &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.medicalfitid
                                  ?
                                  selectedUserPrint.medicalfitid
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Medical Fit Expiry Date &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.medicalfitid_expirydate
                                  ?
                                  moment(selectedUserPrint?.medicalfitid_expirydate).format("DD-MM-YYYY")
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Document &nbsp; &nbsp;
                              {renderDocumentLink(
                                "View Medical",
                                selectedUserPrint?.medicalfitid_doc_url
                              )}
                            </span>
                          </p>
                        </div>



                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Driving License  &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.driving_license_id
                                  ?
                                  selectedUserPrint.driving_license_id
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Driving License Expiry Date &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint.driving_license_id_expirydate
                                  ?
                                  moment(selectedUserPrint?.driving_license_id_expirydate).format("DD-MM-YYYY")
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Document &nbsp; &nbsp;
                              {renderDocumentLink(
                                "view Driving License",
                                selectedUserPrint?.driving_license_doc_url
                              )} <br />
                            </span>
                          </p>
                        </div>

                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Basic Salary &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.salary?.basic_salary
                                  ?
                                  selectedUserPrint?.salary?.basic_salary
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Food Allowance &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.salary?.food_allowance
                                  ?
                                  selectedUserPrint?.salary?.food_allowance
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-0 date">
                            <span style={{ fontWeight: 500 }}>
                              Housing Allowance &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.salary?.housing_allowance
                                  ?
                                  selectedUserPrint?.salary?.housing_allowance
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                      </div>

                      <br />

                      <div className="row">
                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-2 date">
                            <span style={{ fontWeight: 500 }}>
                              Other Allowance &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.salary?.other_allowance
                                  ?
                                  selectedUserPrint?.salary?.other_allowance
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-2 date">
                            <span style={{ fontWeight: 500 }}>
                              Annual Leave &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.leave?.annual_leave
                                  ?
                                  selectedUserPrint?.leave?.annual_leave
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>

                        <div className="col col-md-sm-md-lg-4">
                          <p className="mb-2 date">
                            <span style={{ fontWeight: 500 }}>
                              Sick Leave &nbsp; &nbsp;
                              <b> {
                                selectedUserPrint?.leave?.sick_leave
                                  ?
                                  selectedUserPrint?.leave?.sick_leave
                                  :
                                  <span style={{ color: "gray", fontWeight: "normal" }}>{props.t("Not Available")}</span>
                              }</b>
                            </span>
                          </p>
                        </div>
                      </div>


                    </div>


                  </div>
                  <Row className="col text-right pr-5">
                    <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
                      <Link to="#" onClick={() => { printDiv("printDiv"); }} className="btn btn-primary">
                        <i className="fa fa-print" /> Print
                      </Link>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(connect(null, {})(withTranslation()(ViewEmployees)))
