import React, { useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar.png"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = () => {

  const [welcomeHeight, setWelcomeHeight] = useState("293px")
  const [flag, setFlag] = useState(false)
  // let flag = false;
  const role = (localStorage.getItem("role"))
  const name = (localStorage.getItem("name"))
  
  // Function to handle viewport width changes
  function handleViewportChange() {
    // Get the updated viewport width
    // console.log(" handle viewport change executed")
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    // Display the updated viewport width
    // console.log("Viewport width: " + viewportWidth, flag);

    if (viewportWidth < 992 && flag === false) {
      setWelcomeHeight("auto");
      // flag = true;
      setFlag(true)
    }
  }

  function setFlagTrue() {

    // console.log(" set flag true executed")
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth >= 992 && flag === true) {
      setWelcomeHeight("226px");
      // flag = false;
      setFlag(false)
    }
  }

  // Attach the event listener to the window's resize event
  window.addEventListener('resize', flag === false ? handleViewportChange : setFlagTrue);

  // Initial call to handleViewportChange to display the initial viewport width
  // handleViewportChange();


  console.log(welcomeHeight, " welcome height")
  return (
    <React.Fragment>

      <Card className="overflow-hidden p-2" style={{
        height: "293px", boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      }}>
        <div style={{ height: "50%", background: "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)" }}>
          <Row >
            <Col>
              <div className="text-white p-3">
                <h5 style={{ fontSize:"30px" }} >Welcome Back </h5>
                {/* <p>Skote Dashboard</p> */}
              </div>
            </Col>
            {/* <Col xs="5" style={{ display: "flex", justifyContent: "flex-end" }} className="align-self-end"> */}
              {/* <img src={profileImg} alt="" className="img-fluid" /> */}
            {/* </Col> */}
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  style={{ border: "4px solid #F93A5C" }}
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">{name||"N/A"}</h5>
              <p className="text-muted mb-0 text-truncate">{role || "N/A"}</p>
            </Col>

            <Col sm="8">
              {role === "Owner" && <div className="pt-4">
                {/* <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">QAR 1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row> */}
                {/* <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
