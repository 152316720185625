import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { LOAD_SUPPLIER, SUBMIT_SUPPLIER, UPDATE_SUPPLIER } from "./actionTypes";
import {
  submitSupplierSuccess,
  supplierApiError,
  updateSupplierSuccess,
  loadSupplierSuccess,
} from "./actions";

import "toastr/build/toastr.min.css";
import swal from "sweetalert2";
//Include Helper Files with needed methods
import { postSubmitForm } from "../../helpers/backend_helper";

function* submitSupplier({ payload: { supplier, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "suppliers/insert";

    const response = yield call(postSubmitForm, url, supplier);

    if (response.status === 1) {
      showNotification("Supplier added successfully.", "Success");
      yield* loadrefreshSuppliers();
      yield put(submitSupplierSuccess(response));
    } else {
      console.log(response);
      showNotification(response.message, "Error");
      yield put(supplierApiError(response.message));
    }
  } catch (error) {
    showNotification("Something went wrong!", "Error");
    yield put(supplierApiError(error));
  }
}

function* updateSupplier({ payload: { supplier, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "suppliers/update";

    const response = yield call(postSubmitForm, url, supplier);

    if (response.status === 1) {
      showNotification("Supplier updated successfully.", "Success");
      yield* loadrefreshSuppliers();
      yield put(updateSupplierSuccess(response));
    } else {
      console.log(response);
      showNotification(response.message, "Error");
      yield put(supplierApiError(response.message));
    }
  } catch (error) {
    showNotification("Something went wrong!", "Error");
    yield put(supplierApiError(error));
  }
}
export function* watchSubmitSupplier() {
  yield takeEvery(SUBMIT_SUPPLIER, submitSupplier);
}
export function* watchUpdateSupplier() {
  yield takeEvery(UPDATE_SUPPLIER, updateSupplier);
}
//Load All Ssupplierss
function* loadSuppliers({ payload: {} }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall";
    const response = yield call(postSubmitForm, url, {});

    if (response.status === 1) {
      yield put(loadSupplierSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(supplierApiError(response));
    }
  } catch (error) {
    showNotification(error.message, "Error");
    yield put(supplierApiError(error));
  }
}
export function* watchLoadSupplier() {
  yield takeEvery(LOAD_SUPPLIER, loadSuppliers);
}

function showNotification(message, type) {
  var title = type;
  if (type === "Success") swal.fire(type, message, "success");
  else swal.fire(type, message, "error");
}

function* loadrefreshSuppliers() {
  try {
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall";
    const response = yield call(postSubmitForm, url, {});

    if (response.status === 1) {
      yield put(loadSupplierSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(supplierApiError(response));
    }
  } catch (error) {
    showNotification(error.message, "Error");
    yield put(supplierApiError(error));
  }
}

function* supplierSaga() {
  yield all([
    fork(watchSubmitSupplier),
    fork(watchUpdateSupplier),
    fork(watchLoadSupplier),
  ]);
}

export default supplierSaga;
