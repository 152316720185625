import React from "react"
import { Navigate } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index"
import DashboardCrypto from "../pages/Dashboard-crypto/index"
import Blog from "../pages/Dashboard-Blog/index"
import DashboardJob from "../pages/DashboardJob/index"

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Contacts
import Users from "../pages/Users/Users"
import Shifts from "../pages/Shifts/Shifts"
import ApproveOvertime from "../pages/ApproveOvertime/ApproveOvertime"
import AddEmployee from "../pages/Employees/AddEmployees"
import ViewEmployees from "../pages/Employees/ViewEmployees"
import Designation from "../pages/Designation/Designation"
import EditAttendance from "../pages/Attendance/EditAttendance"
import Roster from "../pages/Roster/Roster"
import ApproveLeave from "../pages/ApproveLeave/ApproveLeave"
import OvertimeCalculation from "../pages/OvertimeCalculation/OvertimeCalculation"
import GeneratePO from "../pages/GeneratePO/GeneratePO"
import AllPurchaseOrder from "../pages/AllPurchaseOrder/AllPurchaseOrder"
import AddSupplier from "../pages/Supplier/AddSupplier"
import UpdateSupplier from "../pages/Supplier/UpdateSupplier"
import ChangePassword from "../pages/Users/ChangePassword"
import Employee_Dashboard from "../pages/Employee_Dashboard/Employee_Dashboard"
import Apply_Leave from "../pages/Employee_Dashboard/Apply_Leave"
import Apply_Overtime from "../pages/Employee_Dashboard/Apply_Overtime"
import Salary_Slip from "../pages/Employee_Dashboard/Salary_Slip"
import Arrear_Special_Punishment from "../pages/Arrear_Special_Punishment/Arrear_Special_Punishment"
import ViewSalary from "../pages/ViewSalary/ViewSalary"
import Loan from "../pages/Loan/Loan"
import GenerateSalary from "../pages/GenerateSalary/GenerateSalary"
import HolidayMaster from "../pages/Holiday Master/HolidayMaster"
import Attendance from "../pages/Employee_Dashboard/Attendance"
import AddNewCategory from "../pages/Categories/AddNewCategory"
import UpdateCategory from "../pages/Categories/UpdateCategory"
import AddInventory from "../pages/Invetory/AddInventory"
import AddItem from "../pages/Items/AddItem"
import Apply_Loan from "../pages/Loan/Apply_Loan"
import Unit_of_measurement from "../pages/Unit_Of_Measurement/Unit_of_measurement"
import AddCustomer from "../pages/Customer/AddCustomer"
import ViewInventory from "../pages/Invetory/ViewInventory"
import ItemOrders from "../pages/Employee_Dashboard/ItemOrders"
import KitchenWithdraw from "../pages/Orders/KitchenWithdraw"
import UsedItems from "../pages/Orders/ViewUsedItems"
import CompletedLoan from "../pages/Loan/CompletedLoan"
import Add_Game from "../pages/Games/Add_Game"
import GameTable from "../pages/Games/GameTable"
import Packages from "../pages/Games/Packages"
import Coupons from "../pages/coupons/coupons"
import UsedInventory from "../pages/Invetory/UsedInventory"
import AllBookings from "../pages/Bookings/AllBookings"
import SellPackages from "../pages/Shop/SellPackages"
import SellItems from "../pages/Shop/SellItems"
import CheckOut from "../pages/Bookings/Check-out"
import GameBookingsTable from "../pages/Bookings/GameBookingsTable"
import InProcessBookings from "../pages/Bookings/addItems"
import WalletTransactions from "../pages/Customer/WalletTransactions"
import WalletHistory from "../pages/Customer/WalletHistory"
import PaymentMethods from "../pages/PaymentMethods/PaymentMethods"
import AllItems from "../pages/Shop/Alltems"
import AllPackageSold from "../pages/Shop/AllPackageSold"
import ExpiredItemsReports from "../pages/Reports/ExpiredItemsReports"
import UpcomingExpiryItems from "../pages/Reports/UpcomingExpiryItems"
import OutOfStockItems from "../pages/Reports/OutOfStockItems"
import Top20Customers from "../pages/Reports/Top20Customers"
import CustomerVisitFrequency from "../pages/Reports/CustomerVisitFrequency"
import AddGames from "../pages/Bookings/addGames"
import AccessoriesOrder from "../pages/Orders/AccessoriesWithdraw"
import UnderStock from "../pages/Reports/underStock"
import TotalItemSold from "../pages/Reports/SalesReport/TotalItemSold"
import TotalPackageSold from "../pages/Reports/SalesReport/TotalPackageSold"
import TotalBookings from "../pages/Reports/SalesReport/TotalBookings"
import ItemsForSale from "../pages/Reports/ItemsForSale"
import ItemsForUse from "../pages/Reports/ItemsForUse"
import Notification from "../pages/Notification"
import InvoicePage from "../pages/Invoice_Page/InvoicePage"
import TotalSaleDetails from "../pages/Reports/SalesReport/TotalSaleDetails"
import ListOfSortedCustomer from "../pages/Reports/ListOfSortedCustomer"
import ServiceDetailsByCustomer from "../pages/Reports/ServiceDetailsByCustomer"
import ViewDailyCloser from "../pages/Reports/Closure/ViewDailyCloser"
import KitchenOrderEmp from "../pages/EmployeeFeatures/KitchenOrderEmp"
import DashboardTableStatus from "../pages/Bookings/DashboardTableStatus"
import ViewAttendance from "../pages/Attendance/ViewAttendance"
import ExtendExpiry from "../pages/ExtendExpiry"
import ApplyOvertimeByOwner from "../pages/ApproveOvertime/ApplyOvertimeByOwner"
import ApplyLeaveByOwner from "../pages/ApproveLeave/ApplyLeaveByOwner"
import MarkAttendance from "../pages/Attendance/MarkAttendance"
import Itemcategory from "../pages/Reports/AllItemsDetails"
import ExpiredItems from "../pages/Invetory/ExpiredItems"
import DiscardedItemsReport from "../pages/Reports/DiscardedItemsReport"
import GameSales from "../pages/Reports/SalesReport/GameSales"
import ItemSales from "../pages/Reports/SalesReport/ItemSales"
import PackageSalesReport from "../pages/Reports/SalesReport/PackageSalesReport"
import GetDailyCloser from "../pages/Reports/Closure/GetDailyCloser"
import Closure from "../pages/Reports/Closure/Closure"
import ClosurePrint from "../pages/Reports/Closure/ClosurePrint"
import ShiftClosure from "../pages/Reports/Closure/ShiftClosure"
import ViewFiles from "../pages/Employees/ViewFiles"
import AddExpense from "../pages/Expenses/AddExpense"
import AddSource from "../pages/Expenses/AddSource"
import DailyAttendanceReport from "../pages/Reports/AttendanceReport/DailyAttendanceReport"
import ViewEmployeeAttendanceReport from "../pages/Reports/AttendanceReport/ViewEmployeeAttendanceReport"
import ThresholdReport from "../pages/Reports/ThresholdReport"
import ViewEmpAttendance from "../pages/Employee_Dashboard/ViewEmpAttendance"
import ApplyLoanForAll from "../pages/Loan/ApplyLoanForAll"
import ExpiryDateLogsReport from "../pages/Reports/ExpiryDateLogsReport"
import LeaveCount from "../pages/Reports/LeaveCount"
import UsedItemsReport from "../pages/Reports/UsedItemsReport"
import CostingReport from "../pages/Reports/CostingReport"
import PackageInvoice from "../pages/Invoice_Page/PackageInvoice"
import AccountsReport from "../pages/Reports/AccountsReport"
import SequentialBooking from "../pages/Bookings/SequentialBooking"
import PurchaseOrder from "../pages/AllPurchaseOrder/PurchaseOrder"
import NewOrders from "../pages/Orders/NewOrders"
import ItemsInvoice from "../pages/Invoice_Page/ItemsInvoice"
import Refund from "../pages/Bookings/Refund"
import RefundInvoice from "../pages/Invoice_Page/RefundInvoice"
//Suppliers

const authProtectedRoutes = [


  // employee dashboard features
  { path: "/employee-kitchen-order", component: <KitchenOrderEmp /> },
  { path: "/accessories-order", component: <AccessoriesOrder /> },
  { path: "/add-games", component: <AddGames /> },


  //Reports
  { path: "/services-by-customer", component: <ServiceDetailsByCustomer /> },
  { path: "/customer-visit-frequency", component: <CustomerVisitFrequency /> },
  { path: "/top-20-customers", component: <Top20Customers /> },
  { path: "/list_of_customers", component: <ListOfSortedCustomer /> },
  { path: "/out-of-stock-items", component: <OutOfStockItems /> },
  { path: "/item-category", component: <Itemcategory /> },
  { path: "/expirydate-logs", component: <ExpiryDateLogsReport /> },
  { path: "/upcoming-expiry-items", component: <UpcomingExpiryItems /> },
  { path: "/expired-items-reports", component: <ExpiredItemsReports /> },
  { path: "/discarded-items-report", component: <DiscardedItemsReport /> },
  { path: "/under-stock", component: <UnderStock /> },
  { path: "/costing-report", component: <CostingReport /> },
  { path: "/accounts-report", component: <AccountsReport /> },
  { path: "/threshold-report", component: <ThresholdReport /> },
  { path: "/used-items-report", component: <UsedItemsReport /> },



  //Sales Report
  { path: "/total_items_sold", component: <TotalItemSold /> },
  { path: "/total_packages_sold", component: <TotalPackageSold /> },
  { path: "/total_bookings_details", component: <TotalBookings /> },
  { path: "/total_sales_details", component: <TotalSaleDetails /> },
  { path: "/game-sales-report", component: <GameSales /> },
  { path: "/item-sales-report", component: <ItemSales /> },
  { path: "/package-sales-report", component: <PackageSalesReport /> },

  //Attendance Report
  { path: "/daily_attendance_report", component: <DailyAttendanceReport /> },
  { path: "/employee_attendance_report", component: <ViewEmployeeAttendanceReport /> },
  { path: "/products_for_sale", component: <ItemsForSale /> },
  { path: "/products_for_use", component: <ItemsForUse /> },
  { path: "/leave_report", component: <LeaveCount /> },

  //Closure
  { path: "/get_daily_closer", component: <GetDailyCloser /> },
  { path: "/shift_closure", component: <ShiftClosure /> },
  { path: "/daily_closure", component: <Closure /> },
  { path: "/view_daily_closer_record", component: <ViewDailyCloser /> },
  { path: "/add-reservation", component: <SequentialBooking /> },

  // Bookings
  { path: "/all-booking", component: <AllBookings /> },
  { path: "/refund", component: <Refund /> },
  { path: "/check-out", component: <CheckOut /> },
  { path: "/game-bookings-table", component: <GameBookingsTable /> },
  { path: "/add-items", component: <InProcessBookings /> },
  { path: "/dashboard-table-status", component: <DashboardTableStatus /> },


  { path: "/unit-of-measurement", component: <Unit_of_measurement /> },
  { path: "/apply-loan", component: <Apply_Loan /> },
  { path: "/apply-loan-owner", component: <ApplyLoanForAll /> },
  { path: "/generate-salary", component: <GenerateSalary /> },
  { path: "/loan", component: <Loan /> },
  { path: "/completed_loan", component: <CompletedLoan /> },

  //Customer
  { path: "/add-customer", component: <AddCustomer /> },
  { path: "/wallet-transactions", component: <WalletTransactions /> },
  { path: "/wallet-history", component: <WalletHistory /> },

  //Notification
  { path: "/notification", component: <Notification /> },

  { path: "/view-salary", component: <ViewSalary /> },
  { path: "/arrear-deduction", component: <Arrear_Special_Punishment /> },
  { path: "/salary-slip", component: <Salary_Slip /> },
  { path: "/apply-overtime", component: <Apply_Overtime /> },
  { path: "/apply-overtime-owner", component: <ApplyOvertimeByOwner /> },
  { path: "/apply-leave", component: <Apply_Leave /> },
  { path: "/apply-leave-owner", component: <ApplyLeaveByOwner /> },
  { path: "/employee-dashboard", component: <Employee_Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard-saas", component: <DashboardSaas /> },
  { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  { path: "/blog", component: <Blog /> },
  { path: "/dashboard-job", component: <DashboardJob /> },
  { path: "/user-changepassword", component: <ChangePassword /> },
  //Games 
  { path: "/add_games", component: <Add_Game /> },
  { path: "/add_game_tables", component: <GameTable /> },
  { path: "/add_packages", component: <Packages /> },

  //Shop
  { path: "/sell-packages", component: <SellPackages /> },
  { path: "/sell-items", component: <SellItems /> },
  { path: "/item-sold-only", component: <AllItems /> },
  { path: "/all-sold-packages", component: <AllPackageSold /> },

  //Coupons
  { path: "/coupons", component: <Coupons /> },

  //Payments Methods
  { path: "/payment_methods", component: <PaymentMethods /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/add_new_employee", component: <AddEmployee /> },
  { path: "/view_employee", component: <ViewEmployees /> },
  { path: "/users", component: <Users /> },
  //Attendance
  { path: "/attendance", component: <Attendance /> },
  { path: "/mark-attendance", component: <MarkAttendance /> },
  { path: "/view-attendance", component: <ViewAttendance /> },
  { path: "/view-emp-attendance", component: <ViewEmpAttendance /> },

  // Masters 
  { path: "/add_supplier", component: <AddSupplier /> },
  { path: "/update_supplier", component: <UpdateSupplier /> },
  { path: "/holiday_master", component: <HolidayMaster /> },
  { path: "/add_category", component: <AddNewCategory /> },
  { path: "/update_category", component: <UpdateCategory /> },
  { path: "/add_item", component: <AddItem /> },
  { path: "/item_order", component: <ItemOrders /> },
  { path: "/add_expense", component: <AddExpense /> },
  { path: "/add_source", component: <AddSource /> },

  //Inventory
  { path: "/used_inventory", component: <UsedInventory /> },
  { path: "/view_inventory", component: <ViewInventory /> },
  { path: "/add_inventory", component: <AddInventory /> },
  { path: "/expired_items", component: <ExpiredItems /> },

  //Orders
  { path: "/kitchen_withdraw", component: <KitchenWithdraw /> },
  { path: "/new-orders", component: <NewOrders /> },
  { path: "/used_items", component: <UsedItems /> },

  { path: "/shift", component: <Shifts /> },
  { path: "/approve_overtime", component: <ApproveOvertime /> },
  { path: "/designation", component: <Designation /> },
  { path: "/edit_attendance", component: <EditAttendance /> },
  { path: "/roster", component: <Roster /> },
  { path: "/approve_leave", component: <ApproveLeave /> },
  { path: "/overtime_calculation", component: <OvertimeCalculation /> },
  { path: "/generate_po", component: <GeneratePO /> },
  { path: "/all_purchase_order", component: <AllPurchaseOrder /> },

  //Extend Expiry
  { path: "/extend_expiry", component: <ExtendExpiry /> },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/Purchase-order", component: <PurchaseOrder /> },
  { path: "/pkgInvoice", component: <PackageInvoice /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/closure-print", component: <ClosurePrint /> },
  { path: "/view_docs", component: <ViewFiles /> },
  { path: "/register", component: <Register /> },
  { path: "/invoice_details", component: <InvoicePage /> },
  { path: "/refund_invoice", component: <RefundInvoice /> },
  { path: "/items-invoice", component: <ItemsInvoice /> },
  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },
]

export { authProtectedRoutes, publicRoutes }
