import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  CardTitle,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const OvertimeCalculation = () => {
  const [ot, setOt] = useState(null)
  const handleChange = value => {
    setOt(value)
  }
  //meta title
  document.title = "Form Elements | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Overtime" breadcrumbItem="Overtime calculation" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Card>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        backgroundColor: "#E9EAEF",
                        padding: "10px 10px 10px 10px",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Input type="search" placeholder="Select Employee" />
                      </div>
                      <div>
                        <CardTitle>May, 2023</CardTitle>
                      </div>
                    </div>
                  </Card>
                  <div>
                    <Table className="table table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Shift</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                          <th>Late In</th>
                          <th>Early Out</th>
                          <th>System Overtime</th>
                          <th>Approved Overtime</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1/02/2023</td>
                          <td>9:00am - 5:00pm</td>
                          <td>9:10AM</td>
                          <td>5:30pm</td>
                          <td>10</td>
                          <td>-30</td>
                          <td>
                            <div className="form-check form-check-right form-check-success">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                style={{ width: "17px", height: "17px" }}
                                // onChange={() => handleChange(20)}
                              />
                              <label className="form-check-label">20</label>
                            </div>
                          </td>
                          <td>
                            <Input
                              style={{ width: "100px" }}
                              type="text"
                              //value={}
                              placeholder=""
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OvertimeCalculation
