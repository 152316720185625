import {
  SUBMIT_ADDEMPLOYEE,
  SUBMIT_ADDEMPLOYEE_SUCCESS,
  LOAD_ADDEMPLOYEE,
  LOAD_ADDEMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  ACTIVATE_DEACTIVATE_EMPLOYEE,
  ACTIVATE_DEACTIVATE_EMPLOYEE_SUCCESS,
  ADDEMPLOYEE_API_ERROR,
  LOAD_GETBYROLE,
  LOAD_GETBYROLE_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
}

const employees = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_ADDEMPLOYEE:
      state = {
        ...state,
        loading: true,
      }
      break
    case SUBMIT_ADDEMPLOYEE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "User added successfully.",
        message_type: "Success",
      }
      break
    case UPDATE_EMPLOYEE:
      state = {
        ...state,
        loading: true,
      }
      break
    case UPDATE_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "User updated successfully.",
        message_type: "Success",
      }
      break

    case ACTIVATE_DEACTIVATE_EMPLOYEE:
      state = {
        ...state,
        loading: true,
      }
      break
    case ACTIVATE_DEACTIVATE_EMPLOYEE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break

    case LOAD_ADDEMPLOYEE:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOAD_ADDEMPLOYEE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        employees: action.payload.data,
      }
      break

    case LOAD_GETBYROLE:
      state = {
        ...state,
        loading: true,
      }
      break

    case LOAD_GETBYROLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        reporting_officers: action.payload.data,
      }
      break
    case ADDEMPLOYEE_API_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        message: action.payload,
        message_type: "Error",
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default employees
