import { SUBMIT_CAFE_ITEM, SUBMIT_CAFE_ITEM_ERROR, SUBMIT_CAFE_ITEM_SUCCESS,LOAD_CAFE_ITEM,LOAD_CAFE_ITEM_SUCCESS } from "./actionTypes";


const initialState ={
    loading:false,
    error:null,
};

const cafeItem = ( state= initialState, action )=> {
    switch(action.type){
            case SUBMIT_CAFE_ITEM:
                return {
                  ...state,
                    loading:true,
                    error:null,
                };
            case SUBMIT_CAFE_ITEM_SUCCESS:
                return {
                  ...state,
                    loading:false,
                    error:null,
                    cafeItem:action.payload,
                };
            case SUBMIT_CAFE_ITEM_ERROR:
                return {
                  ...state,
                    loading:false,
                    error:action.payload,
                };
            case LOAD_CAFE_ITEM:
                return{
                    ...state,
                    loading:true,
                };
            case LOAD_CAFE_ITEM_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    items: action.payload.data
                };
            default:
                return state;
        }
}

export default cafeItem;