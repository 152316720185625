import {
  SUBMIT_ADDEMPLOYEE,
  SUBMIT_ADDEMPLOYEE_SUCCESS,
  LOAD_ADDEMPLOYEE,
  LOAD_ADDEMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  ACTIVATE_DEACTIVATE_EMPLOYEE,
  ACTIVATE_DEACTIVATE_EMPLOYEE_SUCCESS,
  ADDEMPLOYEE_API_ERROR,
  LOAD_GETBYROLE,
  LOAD_GETBYROLE_SUCCESS,
} from "./actionTypes";

export const submitAddEmployee = (addEmployee, history) => {
  return {
    type: SUBMIT_ADDEMPLOYEE,
    payload: { addEmployee, history },
  };
};

export const submitAddEmployeeSuccess = (addEmployee) => {
  return {
    type: SUBMIT_ADDEMPLOYEE_SUCCESS,
    payload: addEmployee,
  };
};

export const updateEmployee = (employee, history) => {
  return {
    type: UPDATE_EMPLOYEE,
    payload: { employee, history },
  };
};

export const updateEmployeeSuccess = (employee) => {
  return {
    type: UPDATE_EMPLOYEE_SUCCESS,
    payload: employee,
  };
};

export const activate_deactivateEmployee = (employee_status, history) => {
  console.log("emp status: ", employee_status);
  return {
    type: ACTIVATE_DEACTIVATE_EMPLOYEE,
    payload: { employee_status, history },
  };
};

export const activate_deactivateEmployeeSuccess = (employee_status) => {
  return {
    type: ACTIVATE_DEACTIVATE_EMPLOYEE_SUCCESS,
    payload: employee_status,
  };
};

export const loadAddEmployee = () => {
  return {
    type: LOAD_ADDEMPLOYEE,
    payload: {},
  };
};

export const loadAddEmployeeSuccess = (addEmployee) => {
  return {
    type: LOAD_ADDEMPLOYEE_SUCCESS,
    payload: addEmployee,
  };
};

export const addEmployeeApiError = (error) => {
  return {
    type: ADDEMPLOYEE_API_ERROR,
    payload: error,
  };
};

export const loadgetByRole = (role) => {
  return {
    type: LOAD_GETBYROLE,
    payload: { role },
  };
};

export const loadgetByRoleSuccess = (employee) => {
  return {
    type: LOAD_GETBYROLE_SUCCESS,
    payload: employee,
  };
};
