import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import { Card, CardBody,Col, Row, Container, Input, Button, CardTitle, Table, Badge, Modal, ModalHeader, ModalBody, ModalFooter,} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { AvField, AvForm } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import { withTranslation } from "react-i18next"
import preloader from "../../helpers/Loader"
import showNotification from "../../helpers/show_notificaton"

const ApproveLeave = (props) => {
  const [leaveApplications, setLeaveApplications] = useState([])
  const [modal, setModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false);

  const [selectedApplication, setSelectedApplication] = useState(null)
  const [remark, setRemark] = useState("")
  const [filterStatus, setFilterStatus] = useState("") // Add filterStatus state
  const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
  const refContainer = useRef(null);
  const [minDateVal, setMinDateVal] = useState("");
  const [maxDateVal, setMaxDateVal] = useState("");
  // const [minDateOnChangeActive, setMinDateOnChangeActive] = useState(true);
  // const [maxDateOnChangeActive, setMaxDateOnChangeActive] = useState(true);
  const [apiDatePayload, setApiDatePayload] = useState({})
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [filterData, setFilterData] = useState([])
  const [resetBtnClickedTracker, setResetBtnClickedTracker] = useState(0)

  const { SearchBar } = Search


  // Dynamic Moth Year
  const [fromDateHeading, setFromDateHeading] = useState("");
  const [toDateHeading, setToDateHeading] = useState("");
  const currentDate = new Date();
  const options = { month: 'long', year: 'numeric' }
  const month = currentDate.toLocaleString('default', options);

  useEffect(() => {

    if (apiDatePayload.date_from) {

    } else {
      preloader(true)
      let url = process.env.REACT_APP_BASEURL
      axios.post(url + "leaveapplications/pending", {}, {
        headers: {
          Authorization: `Bearer ${employeeToken}`,
        },
      }).then(res => {
        preloader(false)
        const applications = res.data.data.map(application => ({
          ...application,
          isApprovedOrRejected:
            application.status === "Approved" ||
            application.status === "Rejected",
        }))

        setLeaveApplications(applications)
        setFilterData([]);
        setFilterStatus("")
      }).catch(err => 
        
        console.log(err))
    }

  }, [resetBtnClickedTracker])

  const toggleModal = () => {
    setModal(!modal)
  }
  const toggleApproveModal = () => {
    setApproveModal(!approveModal);
  };

  const fetchLeaveApplications = async (object) => {
    let url = process.env.REACT_APP_BASEURL
    try {
      const userToken = localStorage.getItem("userToken")
      const response = await axios.post(
        url + "leaveapplications/getall_by_date_range",
        object,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      const applications = response.data.data.map(application => ({
        ...application,
        isApprovedOrRejected:
          application.status === "Approved" ||
          application.status === "Rejected",
      }))
      setLeaveApplications(applications)
      // console.log(applications, "applicatons data")
      setFilterData([]);
      setFilterStatus("")
    } catch (error) {
      console.log(error)
    }
  }
  // console.log(leaveApplications,'leaveApplications')

  const handleApprove = (application) => {
    setSelectedApplication(application);
    toggleApproveModal();
  };

  const handleApproveSubmit = id => {
    let url = process.env.REACT_APP_BASEURL
    // console.log("id:", id)
    const userToken = localStorage.getItem("userToken")

    axios
      .post(
        url + "leaveapplications/update_status",
        { id: id, status: "Approved" },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        const res = response.data
        if(res && res.status===1){
          showNotification(res.message,"Success")
        } else{
          showNotification(res.message,"error")
        }
        // Refresh the leave applications list
        if(apiDatePayload.date_from){
          fetchLeaveApplications(apiDatePayload);
        }else{

          setResetBtnClickedTracker(prev => prev += 1)
        }
        toggleApproveModal();
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleReject = application => {
    setSelectedApplication(application)
    toggleModal()
  }

  const handleRejectSubmit = () => {
    let url = process.env.REACT_APP_BASEURL
    const { _id } = selectedApplication
    const userToken = localStorage.getItem("userToken")

    axios
      .post(
        url + "leaveapplications/update_status",
        { id: _id, status: "Rejected", remark: remark },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        const res = response.data
        if(res && res.status===1){
          showNotification(res.message,"Success")
        } else{
          showNotification(res.message,"error")
        }
        // Refresh the leave applications list
        // fetchLeaveApplications(apiDatePayload)
        if(apiDatePayload.date_from){

          fetchLeaveApplications(apiDatePayload);
        }else{

          setResetBtnClickedTracker(prev => prev += 1)
        }
      
        toggleModal()
      })
      .catch(error => {
        console.error(error)
      })
  }

  // Apply filter to leave applications based on the selected status


  // handle valid submit to validate the inputs given to the form or if the form is empty
  const handleValidSubmit = async (e, v, token) => {

    const monthObj = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    };

    const object = {
      date_from: v.from_date,
      date_to: v.to_date
    }

    const from_Date = object.date_from.slice(8, 10)
    const to_Date = object.date_to.slice(8, 10)

    const month = object.date_from.slice(5, 7)
    const date = new Date(`2000-${month}-01`);
    const monthString = date.toLocaleString('default', { month: 'short' });

    const toMonth = object.date_to.slice(5, 7)
    const toDate = new Date(`2000-${toMonth}-01`);
    const toMonthString = toDate.toLocaleString('default', { month: 'short' });

    const year = object.date_to.slice(0, 4);


    setFromDateHeading(`${from_Date} ${monthString}`)
    setToDateHeading(`${to_Date} ${toMonthString}`)



    setApiDatePayload(object)


    fetchLeaveApplications(object);

  }
  // -------------------------------------------------------------------------------------------

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Code"),
      hidden: true,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Name/Code"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      formatter: (cell, row) => {
        return row.employee_name + " " + "(" + row.employee_code + ")"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "date_from",
      text: props.t("From Date"),
      formatter: (cell, row) => {
        return (
          <p>{row.date_from.slice(0, 10)}</p>
        )
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "date_to",
      text: props.t("To Date"),
      formatter: (cell, row) => {
        return (
          <p>{row.date_to.slice(0, 10)}</p>
        )
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "leave_type",
      text: props.t("Type of Leave"),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
   
    {
      dataField: "remaining_annual_leave",
      text: props.t("Remaining Leave"),
      sort: false,
      formatter: (cell, row) => {
        switch (row.leave_type){
        case "Annual Leave" : return row.remaining_annual_leave;
        case "Sick Leave" : return row.remaining_sick_leave;
        case "LWP" : return "NA";
        default:  return "NA";
       }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "reason",
      text: props.t("Reason"),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <Badge
              className={`font-size-12 badge-soft-${row.status === "Approved"
                ? "success"
                : row.status === "Pending"
                  ? "warning"
                  : row.status === "Rejected"
                    ? "danger"
                    : null
                }`}
            >
              {row.status}
            </Badge>

            <div>
              <p
                style={{
                  fontSize: "11px",
                  fontWeight: "500",
                  marginTop: "0.4rem",
                }}
              >
                {row.remark
                  ? `Remark:   ${row.remark} `
                  : ""}
              </p>
            </div>
          </>
        )
      },
      filter: selectFilter({
        options: [
          { value: "Approved", label: "Approved" },
          { value: "Pending", label: "Pending" },
          { value: "Rejected", label: "Rejected" },
        ],
        defaultValue:'Pending',
        placeholder: "ALL",
      }),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "loanOutstandings",
      text: props.t("Loan Outstanding"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: (cell, row) => {
        return (
          <div
            className="d-flex"
            style={{ width: "100%", gap: "10px" }}
          >
           

           
           <i
        size="md"
          className="me-2 fs-5 fa fa-check"
          style={{color:"#42eb60", cursor:"pointer"}}
         onClick={() => handleApprove(row._id)}
         disabled={row.isApprovedOrRejected}
        >
          
        </i>

        <i
      className="ms-2 fs-5 fa fa-times"
          size="md"
          style={{color:"red", cursor:"pointer"}}
          onClick={() => handleReject(row)}
          disabled={row.isApprovedOrRejected}
        >
          </i>
         
          </div>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },

  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Attendance"
            alternateTitle="Leave Approval"
            breadcrumbItem1="Approve Leave"
          />
          {/* <Button style={{ marginTop: '-10px', marginBottom: "15px", backgroundColor: "#143758", cursor: "auto" }} >
            Leave Approval
          </Button> */}
          <Row>
            <Col lg={12}>
              <Card >
                <CardBody
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <div className="mb-3" >

                    <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken)} ref={refContainer}>
                      <Row >
                        <Col md={3}>
                          <label className="form-label">
                            From Date
                          </label>

                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}

                            options={{

                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },
                            }}
                            onChange={(v) => {

                              // if (minDateOnChangeActive === true) {


                              const dateString = v;


                              const date = new Date(dateString);

                              // Extract the date components
                              const day = date.getDate();
                              const month = date.getMonth() + 1; // Months are zero-based, so add 1
                              const year = date.getFullYear();

                              // Format the date as "dd-mm-yyyy"
                              const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                              // console.log(formattedDate); // Output: 26-12-2023
                              setMinDateVal(formattedDate);
                              setDisabledStatus(false)
                              //   setMaxDateOnChangeActive(false)
                              // }
                            }}
                            name="from_date"
                            placeholder="Select From Date"
                            errorMessage="Select From Date."
                            validate={{
                              required: { value: true },
                            }}
                          />

                        </Col>

                        <Col md={3}>

                          <label className="form-label">
                            To Date
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            onChange={(v) => {

                              // if (maxDateOnChangeActive === true) {


                              const dateString = v;


                              const date = new Date(dateString);

                              // Extract the date components
                              const day = date.getDate();
                              const month = date.getMonth() + 1; // Months are zero-based, so add 1
                              const year = date.getFullYear();

                              // Format the date as "dd-mm-yyyy"
                              const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                              // console.log(formattedDate); // Output: 26-12-2023
                              // setMaxDateVal(formattedDate)

                              //   setMinDateOnChangeActive(false)
                              // }
                            }}
                            disabled={disabledStatus}
                            options={{
                              minDate: minDateVal,
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },

                            }}

                            name="to_date"
                            placeholder="Select To Date"
                            errorMessage="Select To Date."
                            validate={{
                              required: { value: true },
                            }}
                          />


                        </Col>

                        <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                          <label style={{ height: "20.5px" }} className="form-label">

                          </label>
                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "-0.2rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Show")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                        </Col>

                      </Row>
                    </AvForm>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>

                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <CardTitle className="fs-3" >{fromDateHeading !== "" ? `${fromDateHeading} - ${toDateHeading}` : "All Time Pending"}</CardTitle>

                  <Col>
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={leaveApplications || []}
                      search
                      isGlobalFilter={true}
                      isAddUserList={true}
                      // handleUserClick={handleUserClicks}
                      customPageSize={10}
                    // className="custom-header-css"

                    >
                      {propst => (
                        <div>
                          <AvForm>
                            <Row>
                              <label className="form-label">
                                {("Search By Employee Name")}
                              </label>
                              <Col lg={2} style={{ display: "flex", }}>
                                <SearchBar
                                  {...propst.searchProps}
                                // style={{ width: "340px" }}
                                />
                              </Col>
                              <Col  >
                                {/* <Button className="btn btn-info" onClick={() => { setResetBtnClickedTracker(prev => prev = prev + 1); setFirstTimeApiCall(false); }}  >Reset To Current Month</Button> */}
                              </Col>
                            </Row>
                          </AvForm>
                          <BootstrapTable
                            {...propst.baseProps}
                            filter={filterFactory()}
                            noDataIndication={("No data to display.")}
                            striped
                            hover
                            condensed

                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Approve Modal */}
      < Modal isOpen={approveModal} toggle={toggleApproveModal} >
        <ModalHeader toggle={toggleApproveModal}>Approve Leave</ModalHeader>
        <ModalBody>
          <div>
            <label className="form-label">Are you sure ?</label>
            {/* <Input
              type="number"
              className="form-control"
              value={approved}
              onChange={(e) => setApproved(e.target.value)}
            /> */}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleApproveModal}>
            Cancel
          </Button>
          <Button color="success" onClick={() => handleApproveSubmit(selectedApplication)}>
            Approve
          </Button>
        </ModalFooter>
      </Modal >

      {/* Reject Modal */}
      < Modal
        isOpen={modal}
        toggle={toggleModal}
        className="d-flex align-items-center justify-content-center"
      >
        <ModalHeader toggle={toggleModal}>Reject Leave</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Enter remark"
            value={remark}
            onChange={e => setRemark(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleRejectSubmit}>
            Reject
          </Button>
        </ModalFooter>
      </Modal >
    </React.Fragment >
  )
}

export default withRouter(connect(null, {})(withTranslation()(ApproveLeave)))
