import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Spinner
} from "reactstrap"
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import exportFromJSON from "export-from-json"
import preloader from "../../helpers/Loader";
import SelectPhotoFormatter from "../../helpers/SelectImageFormatter";
import BackgroundImage from "../../assets/images/Background.png"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll";

const Packages = props => {
  document.title = "Packages"

  const refSelectGame = useRef(null);
  const refContainer = useRef(null);
  const { SearchBar } = Search

  //Tabs
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [editModal, setEditModal] = useState()
  const [section, setSection] = useState("Cafe")
  const [loading, setLoading] = useState(true);
  const [allGame, setAllGames] = useState([])
  const [selected_game, setSelected_game] = useState([])
  const [updateSelectedGame, setUpdateSelectedGame] = useState([])
  const [all_Items, setAll_Items] = useState([])
  const [updateSelectedItem, setUpdateSelectedItem] = useState([])
  const [selected_item, setSelected_item] = useState([])
  const [allPackages, setAllPackages] = useState([])
  const [package_Item_Details, setPackage_Item_Details] = useState([])
  const [package_Game_Details, setPackage_Game_Details] = useState([])
  const [selectedRow, setSelectedRow] = useState({ game_details: [], item_details: [] });
  // const [changedPriceValue, setChangedPrice] = useState({ label: "", value: 0 })
  const [newSelectedGame, setNewSelectedGame] = useState([])
  const [newSelectedItem, setNewSelectedItem] = useState([])
  const [gameIsChanged, setGameIsChanged] = useState(false)
  const [itemIsChanged, setItemIsChanged] = useState(false)
  const [price, setPrice] = useState(0)
  const [validation, setValidation] = useState(false);
  const [duplicateValidation, setDuplicateValidation] = useState(false);
  const [rowToDelete, setRowToDelete] = useState({});
  const [deleteModal, setDeleteModal] = useState(false)
  const [error, setError] = useState(null);
  const [itemChanged, setItemChanged] = useState(false);
  const [unitValidation, setUnitValidation] = useState();
  const [subUnitValidatioin, setSubUnitValidatioin] = useState()
  const [updatemodal, setUpdateModal] = useState()
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // console.log(selectedRow,"selected row")
  // console.log(updateSelectedItem, "update selected items")
  // console.log(updateSelectedGame, "update selected games")
  // console.log(allGame, "all games")
  // console.log(removedValue, "removed value")
  // console.log(price, "price")
// console.log(selected_game,"SG");
// console.log(selected_item,'SI');

  useEffect(() => {
    loadAllGames()
    loadAllItems()
    loadAllPackages()
  }, []);

  // -------------------------------------------------------

  const handleDownload = async () => {
    const fileName = "All Packages";
    const exportType = "xls";

    let data_to_export = allPackages;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Package_Id: v.package_name,
          Price: v.price,
          Validity: v.validity,
          Total_Sales_Limit: v.total_sales_limit,
          Per_Customer_Selling_Limit: v.per_customer_selling_limit,
          Created_Date: moment(v.createdAt).format('YYYY-MM-DD')
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  const handleGameMinutesChange = (index, value) => {

    setSelected_game((prevSelectedGame) => {
      const updatedGames = [...prevSelectedGame];
      updatedGames[index] = { ...updatedGames[index], duration: value };
      return updatedGames;
    });
  };

  const handleItemQuantityChange = (index, value, field) => {

    setSelected_item(prevSelectedItem => {
      const item = { ...prevSelectedItem[index] };
      if (field === 'in_unit') {
        item.quantity.in_unit = value;
      } else if (field === 'in_sub_unit') {
        item.quantity.in_sub_unit = value;
      }
      const updatedItems = [...prevSelectedItem];
      updatedItems[index] = item;
      return updatedItems;
    });


  };

  const UpdatehandleGameMinutesChange = (index, value) => {
    setSelectedRow((prevSelectedRow) => {
      const updatedGames2 = [...prevSelectedRow.game_details];
      updatedGames2[index] = { ...updatedGames2[index], duration: value };
      return { ...prevSelectedRow, game_details: updatedGames2 };
    });
  };

  const handleItemChange = (selected, changedValue) => {
    // Check if item added
    if (changedValue.action !== 'select-option') {
      // Add item logic
      let newSelected = updateSelectedItem.filter(item => item.label !== changedValue.removedValue.label);

      setUpdateSelectedItem(prev => prev = newSelected)
    }

    // Check if item removed
    if (selected.length > 0 && changedValue.action === 'remove-value') {
      // Remove item logic
      let duplicate = updateSelectedItem.filter(item => item.label === changedValue.option.label);

      if (duplicate.length > 0) {
        setDuplicateValidation(prev => prev = true);
        return;
      } else {
        setDuplicateValidation(prev => prev = false)
      }

    }

    setUpdatedSelectedItems(selected);

    if (selected !== originalItems) {
      setItemChanged(true);
    }
  }

  const originalItems = selectedRow.item_details;
  const [updatedSelectedItems, setUpdatedSelectedItems] = useState(originalItems);
  const UpdatehandleItemQuantityChange = (index, value) => {
    setSelectedRow((prevSelectedRow) => {
      const updatedItems = [...prevSelectedRow.item_details];
      updatedItems[index] = {
        ...updatedItems[index],
        quantity: {
          ...updatedItems[index].quantity,
          in_sub_unit: value,
        },
      }; 
      // console.log(updatedItems);
      return { ...prevSelectedRow, item_details: updatedItems };
    });
  };

  // console.log("Before updating item_details:", selectedRow.item_details);

  function updatePriceCalcByGame(changedValue) {
    // console.log(changedValue, "changed value")
    // console.log(price,"price")

    if (changedValue.action === "remove-value") {
      let priceTobeSubtracted = allGame.filter(item => item.name === changedValue.removedValue.label)[0].price
      let newSum = price - priceTobeSubtracted;
      setPrice(prev => prev = newSum)
      let newGamesArr = selectedRow.game_details.filter(item => item.name !== changedValue.removedValue.label);
      setNewSelectedGame(prev => prev = newGamesArr)
      setGameIsChanged(prev => prev = true)
      setValidation(prev => prev = false)
    } else {
      let priceTobeSubtracted = allGame.filter(item => item.name === changedValue.option.label)[0].price
      let newSum = price + priceTobeSubtracted;
      setPrice(prev => prev = newSum)
      let newGamesArr = allGame.filter(item => item.name === changedValue.option.label);
      setNewSelectedGame(prev => prev = [...prev, ...selectedRow.game_details, ...newGamesArr])
      setGameIsChanged(prev => prev = true)
      setValidation(prev => prev = false)
    }
  }


  //Tabs Function
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };



  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 100,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: allPackages && allPackages.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  //form submit
  const handleValidSubmit = async (e, v) => {
    if (!selected_game) {
      setError('Please select at least one game');
      return;
    }
    let url = process.env.REACT_APP_BASEURL + 'packages/insert'
    const obj = {

      game_details: [
        ...selected_game.map((game) => {
          return {
            game_id: game._id,
            name: game.name,
            description: game.description,
            price: game.price,
            image: game.image,
            duration: Number(game.duration) || 0
          }
        })
      ],

      item_details: [
        ...selected_item.map((item) => {
          return {
            item_id: item._id,
            name: item.name,
            available: item.available,
            selling_price: item.selling_price,
            section: item.section,
            category: item.category,
            unit: item.unit,
            sub_unit: item.sub_unit,
            formula: item.formula,
            threshold: item.threshold,
            image_url: item.image_url,
            cost_price: item.cost_price,
            available: {
              in_unit: Number(item.quantity.in_unit) || 0, in_sub_unit: Number(item.quantity.in_sub_unit) || 0,
            }
          }
        })
      ],
      price: v.price,
      package_name: v.package_name,
      total_sales_limit: v.total_sales_limit,
      per_customer_selling_limit: v.per_customer_selling_limit,
      validity: v.validity
    }
    //  console.log(obj,"paylaod");
    // return;

    postSubmitForm(url, obj).then(response => {
      if (response.status === 1) {
        loadAllPackages()
        setSelected_item([])
        setSelected_game([])
        setPrice(0)
        refContainer.current.reset()
        showNotification(response.message, "Success")
      } else {
        showWarning(response.message, "Warning")
      }
    })
  }

  const updatedItems = itemChanged
    ? updatedSelectedItems
    : originalItems;

  //form submit
  const handleValidUpdate = async (e, v) => {
    if (gameIsChanged === false && itemIsChanged === false) {
      setValidation(prev => prev = true);
      return;
    }
    let url = process.env.REACT_APP_BASEURL + 'packages/update';

    const obj = {
      _id: selectedRow._id,
      game_details: gameIsChanged === true ? [
        ...newSelectedGame.map((game) => {
          return {
            game_id: game.game_id || game._id,
            name: game.name,
            description: game.description,
            // price: game.price,
            image: game.image,
            duration: game.duration || 30
          }
        })
      ] : selectedRow.game_details,
      item_details: updatedItems,
      // item_details: itemIsChanged ? [
      //   ...newSelectedItem.map((item) => {
      //     return {
      //       item_id: item.item_id || item._id,
      //       name: item.name,
      //       available: item.available,
      //       selling_price: item.selling_price,
      //       section: item.section,
      //       category: item.category,
      //       unit: item.unit,
      //       sub_unit: item.sub_unit,
      //       formula: item.formula,
      //       threshold: item.threshold,
      //       image_url: item.image_url,
      //       cost_price: item.cost_price,
      //       quantity: item.quantity || { unit: 0, in_sub_unit: 0 },
      //     }
      //   })
      // ] : selectedRow.item_details,
      price: v.price,
      validity: v.validity
    }
    // console.log(obj, "paylaod");
    // return;
    postSubmitForm(url, obj).then(response => {
      if (response.status === 1) {
        setUpdateModal(prev => prev = !prev)
        loadAllPackages()
        setSelected_item(null)
        setSelected_game(null)
        refContainer.current.reset()
        setNewSelectedGame(prev => prev = [])
        setNewSelectedItem(prev => prev = [])
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    })
  }
  //Price



  const calculateTotalPrice = (items, games) => {
    const UnitPrice = items?.reduce((total, item) => {
      const availableInUnit = item.quantity?.in_unit || 0; 
      return  item.sub_unit!=="" ? total + (item.formula * availableInUnit) * item.selling_price : total +  item.selling_price * availableInUnit;
  }, 0);

  const Sub_UnitPrice = items?.reduce((total, item) => {
      const availableInSubUnit = item.quantity?.in_sub_unit || 0; 
      return total + item.selling_price * availableInSubUnit;
  }, 0);

  const gamesPrice = games?.reduce((total, game) => {
    const adjustedDuration = game.duration < 30 ? 30 : game.duration;
    return total + (game.price_for_regular / 60) * adjustedDuration;
}, 0);
    const unitPrice = UnitPrice;
    const sub_unitPrice = Sub_UnitPrice  || 0;
    const total = gamesPrice +  unitPrice + sub_unitPrice;
    return total.toFixed(2)
  };

  //handle select 
  function handleSelect(data) {
    setSelected_game(data)
  }
  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }
  //All Game
  const loadAllGames = async () => {
    let url = process.env.REACT_APP_BASEURL + "games/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      response.data.forEach(game => {
        game.label = (
          <>
            {SelectPhotoFormatter(game)}
            {game.name}
          </>
        );
        game.value = game.name
      })
      // console.log(response.data);
      setAllGames(response.data)
    } else {
      console.log("Error");
    }
  }

  //All Items
  const loadAllItems = async () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + "items/getall_item"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      setLoading(false)
      const filterData = response.data.filter(
        item => (item.is_active === true) && (item.is_menu_item === true || item.is_household_item === true)
      )
      filterData.map(item => {
       item.label = (
         <>
           {SelectPhotoFormatter(item)}
           {item.name}
         </>
       );
       item.value = item.name;
     });
      setAll_Items(filterData || [])
    } else {
      setLoading(false);
      console.log("Error");
    }
  }

  useEffect(() => {
    loadAllItems()
  }, [section])


  //All Packages
  const loadAllPackages = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "packages/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      preloader(false)
      // console.log(response.data);
      setAllPackages(response.data)
    } else {
      preloader(false)
      console.log("Error");
    }
  }

  // console.log(selected_item,"SI");
  //Delete
  const handleValidDelete = async game => {
    let url = process.env.REACT_APP_BASEURL + "packages/activate_deactivate_package"
    let response = await postSubmitForm(url, {
      id: game._id,
      is_active: game.is_active === true ? false : true
    })
    if (response.status === 1) {
      loadAllPackages()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }

  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-file fs-5 mt-1"
          title="Click to see details"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedRow(row)
            setPackage_Item_Details(row.item_details)
            setPackage_Game_Details(row.game_details)
          }}
        ></i>

        <i>

          <div className="form-check form-switch form-switch-md  ms-2" dir="ltr">
            <input
              type="checkbox"
              style={{ height: "15px", width: "30px" }}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>

      </span>
    )
  }

  const handleValidate = (e) => {
    const field = e.target;
    try {
      if (field.props && field.props.onChange) {
        field.props.onChange(e);
        field.validateField();
      } else {
        console.error('Field props or onChange function is undefined:', field);
      }
    } catch (error) {
      console.error('Error in handleValidate:', error);
    }
  };

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "package_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Price (QR)"),
      dataField: "price",
      sort: false,
      // formatter:(cell,row)=>{
      //   return row.price.toFixed(2) + " " +"Qr"
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Validity (days)"),
      dataField: "validity",
      sort: false,
      // formatter:(cell,row)=>{
      //   return row.validity + " " +"days"
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Package Created Date"),
      dataField: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        const timestamp = row.createdAt;
        const formattedDate = moment(timestamp).format('YYYY-MM-DD');
        return formattedDate;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Total sales Limit"),
      dataField: "total_sales_limit",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Per Customer selling Limit"),
      dataField: "per_customer_selling_limit",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
  ]

  const PackageItemDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Unit"),
      dataField: "available.in_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_unit + " " + row.unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub-Unit"),
      dataField: "available.in_sub_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_sub_unit + " " + row.sub_unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //   text: props.t("Regular Price"),
    //   dataField: "selling_price",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     let price = row.selling_price / row.formula;
    //     return price.toFixed(2) + " " + "Qr"
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
  ]

  const PackageGameDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Minutes"),
      dataField: "duration",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs 
            title={props.t("Games")}
            breadcrumbItem1={props.t("Packages")}
            alternateTitle={props.t("Add Package")} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody  >
                  <AvForm onValidSubmit={(e,v)=>{
                    if(selected_game.length<=0){
                      showWarning("Please select atleast one game!");
                      return;
                    }else{
                      handleValidSubmit(e,v);
                    }
                    }} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                      <Label>{props.t("Package Name *")}</Label>
                        <AvField
                          name="package_name"
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Package Name")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <Label>Validity (Days)*</Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="validity"
                          // label={props.t("Validity")}
                          className="form-control"
                          placeholder={props.t("Enter Validity in Days")}
                          type="number"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Validity cannot be empty")}
                        />
                      </Col>
                     </Row>

                     <Row>
                     <Col lg={6} className="mb-2">
                        <Label >
                          {props.t("Games*")}
                        </Label>
                        <Select
                          ref={refSelectGame}
                          value={selected_game}
                          isMulti
                          onChange={handleSelect}
                          options={allGame}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                          required
                        />
                        {error && <p className="error">{error}</p>}

                      </Col>



                      {/* {loading ? (
                        <Spinner className="mx-auto mt-3" />
                      ) : ( */}
                     
                      {/* {loading ? (
                        <Spinner className="mx-auto mt-3" />
                      ) : ( */}
                        <Col lg={6} className="mb-2">
                          <Label>
                              {props.t("Items")}
                          </Label>
                          <Select
                            ref={refSelectGame}
                            value={selected_item}
                            isMulti
                            onChange={(select) => { setSelected_item(select) }}
                            // options={groupedOptions || []}
                            options={all_Items || []}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            required
                          />
                        </Col>
                     </Row>

                    <Row>

                      <Col lg={6} style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {selected_game && selected_game.map((game, index) => (
                          <div key={index} style={{ width: "10vw" }}>
                            <Label style={{ fontSize: "10px", }}>Duration*:<span style={{ color: "#e37168" }}> {game.name}  </span> </Label>
                            <AvField
                              onWheel={preventValueChangeOnScroll}
                              // name="duration"
                              name={`duration_${index}`}
                              type="number"
                              min="30"
                              style={{ height: "25px", fontSize: "10px" }}
                              className="form-control"
                              // placeholder="30"
                              value={game.duration || 0}
                              onBlur={(e) => handleValidate(e)}
                              onChange={(e) => handleGameMinutesChange(index, e.target.value)}
                              validate={{ required: true, min: { value: 30, errorMessage: "Duration must be at least 30 duration" } }}
                              errorMessage={props.t("Duration cannot be empty")}
                            />
                          </div>
                        ))}
                      </Col>


                      <Col lg={6} style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {selected_item && selected_item.map((item, index) => (
                          <div key={index} style={{ width: "10vw" }}>
                            <Label style={{ fontSize: "10px" }}>Quantity* </Label>
                            {/* <span style={{ color: "#e37168" }}>{item.name}</span>  <span className="d-flex"> {Math.ceil(item.selling_price)} QR /{item.unit} </span>  </Label> */}
                            <Row>

                              <Col lg={6} className="ms-0">
                                <AvField
                                  // name="in_unit"
                                  name={`in_unit_${index}`}
                                  type="number"
                                  style={{ height: "25px", fontSize: "10px" }}
                                  className="form-control"
                                  placeholder={`${item.unit}`}
                                  // placeholder="Unit"
                                  onBlur={(e) => handleValidate(e)}
                                  value={item.quantity ? item.quantity.in_unit : 0 || 0}
                                  onChange={(e) => { handleItemQuantityChange(index, e.target.value, 'in_unit'); setUnitValidation(e.target.value) }}
                                  validate={{ required: { value: subUnitValidatioin ? false : true } }}
                                  errorMessage={props.t("Unit required")}
                                />
                              </Col>

                              <Col lg={6}>
                                <AvField
                                  // name="in_sub_unit"
                                  name={`in_sub_unit_${index}`}
                                  type="number"
                                  style={{ height: "25px", fontSize: "10px" }}
                                  className="form-control"
                                  onBlur={(e) => handleValidate(e)}
                                  placeholder={`${item.sub_unit}`}
                                  disabled={item?.sub_unit === ""}
                                  value={item.quantity ? item.quantity.in_sub_unit : 0 || 0}
                                  onChange={(e) => { handleItemQuantityChange(index, e.target.value, 'in_sub_unit'); setSubUnitValidatioin(e.target.value) }}
                                  validate={{ required: { value: unitValidation ? false : true } }}
                                  errorMessage={props.t("Sub-Unit Required")}
                                />

                              </Col>


                            </Row>
                          </div>
                        ))}
                      </Col>

                    </Row>
                    <Row>
                      <Col lg={3}>
                        <Label>{props.t("Package Price*")}</Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="price"
                          // label={props.t("Price")}
                          className="form-control"
                          type="number"
                          // value={calculateTotalPrice(selected_item, selected_game)}
                          placeholder={props.t("Enter Price")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Price cannot be empty")}
                        />
                      </Col>
                 {selected_game?.length>0  || selected_item?.length>0? (
                       <Col lg={3} className="mt-4">
                       <Label>{props.t("Ordinary Price")}
                       : <span className="text-danger">{calculateTotalPrice( selected_item,selected_game)}</span>
                       </Label> 
                   
                     </Col>
                 ) : (
                  <Col lg={3}>
                  </Col>
                 )}
                      <Col lg={3}>
                        <Label>{props.t("Per Customer Sales Limit *")}</Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="per_customer_selling_limit"
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Per Customer Sales Limit")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Per Customer Selling Limit can't be empty")}
                        />
                      </Col>
                      <Col lg={3}>
                        <Label>{props.t("Total Sales Limit *")}</Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="total_sales_limit"
                          className="form-control"
                          type="number"
                          placeholder={props.t("Total Sales Limit")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Total Sales Limit can't be empty")}
                        />
                      </Col>

                     

                    </Row>
                    <hr />
                    <Row>


                      <Button
                        type="submit"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "150px",
                          marginTop: "1.6rem",
                          margin: "auto",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Create Package")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>



                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row >
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Packages")}
                      </Col>
                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={allPackages.length < 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allPackages && allPackages}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable

                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>

          </Row>

          <Modal style={{ marginTop: "auto" }}
            size="xl"
            isOpen={editModal}
            toggle={() => setEditModal(prev => prev = !prev)}
          >
            <ModalHeader toggle={() => setEditModal(prev => prev = !prev)}>
              {selectedRow.package_name}
            </ModalHeader>
            <ModalBody>
              {allPackages && (
                
                <>
                  <Row>
                    <Col md="3">
                      <Nav pills className="flex-column">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleVertical("1");
                            }}
                          >
                            {props.t(" Items")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleVertical("2");
                            }}
                          >
                            {props.t("Games")}
                          </NavLink>
                        </NavItem>


                      </Nav>
                    </Col>
                    <Col md="9">
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <ToolkitProvider
                            keyField="_id"
                            columns={PackageItemDetails}
                            data={package_Item_Details && package_Item_Details}
                            search
                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable

                                  {...propst.baseProps}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            )}
                          </ToolkitProvider>

                        </TabPane>
                        <TabPane tabId="2">
                          {/* Game Details */}
                          <ToolkitProvider
                            keyField="_id"
                            columns={PackageGameDetails}
                            data={package_Game_Details && package_Game_Details}
                            search
                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable

                                  {...propst.baseProps}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            )}
                          </ToolkitProvider>

                        </TabPane>

                      </TabContent>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>

          <Modal
            size="lg"


            isOpen={updatemodal}
            toggle={() => { setUpdateModal(prev => prev = !prev); setValidation(prev => prev = false); setDuplicateValidation(prev => prev = false) }}
          >
            <ModalHeader toggle={() => { setUpdateModal(prev => prev = !prev); setValidation(prev => prev = false); setDuplicateValidation(prev => prev = false) }}>
              {selectedRow.package_name}
            </ModalHeader>
            <ModalBody >
              {selectedRow && (
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleValidUpdate} >
                      <Row >



                        <Col lg={6} className="mb-2">
                          <Label>
                            {props.t("Game")}
                          </Label>

                          <Select
                            styles={{ background: "red" }}
                            //   ref={refSelectGame}
                            value={updateSelectedGame.map(item => { return { label: item.label, value: item.value } })}
                            isMulti
                            onChange={(selected, changedValue) => {


                              if (changedValue.action !== "select-option") {

                                let newSelected = updateSelectedGame.filter(item => item.label !== changedValue.removedValue.label);

                                setUpdateSelectedGame(prev => prev = newSelected)
                              }
                              if (selected.length > 0 && changedValue.action === "select-option") {

                                let duplicate = updateSelectedGame.filter(item => item.label === changedValue.option.label);

                                if (duplicate.length > 0) {
                                  setDuplicateValidation(prev => prev = true);
                                  return;
                                } else {
                                  setDuplicateValidation(prev => prev = false)
                                }

                                setUpdateSelectedGame(prev => prev = selected);
                              }

                              updatePriceCalcByGame(changedValue)

                            }}

                            options={allGame.map(item => ({ label: item.name, value: item.name }))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            isDisabled={true}
                          />


                        </Col>

                        <Col lg={6}>
                          <Label>
                            {props.t("Items")}
                          </Label>
                          <Select
                            // ref={refSelectGame}
                            value={updateSelectedItem.map(item => ({ label: item.label, value: item.value }))}
                            isMulti
                            onChange={(selected, changedValue) => {
                              handleItemChange(selected, changedValue);

                            }}
                            options={all_Items.map(result => ({ label: result.name, value: result._id }))}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            isDisabled={true}
                          />

                        </Col>


                      </Row>

                      <Row>
                        <Col lg={6} style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                          {selectedRow.game_details && selectedRow.game_details.map((game, index) => (
                            <div key={index}>
                              <Label>Duration:<span style={{ color: "#e37168" }}> {game.name}  </span> </Label>
                              <AvField
                                name="duration"
                                type="number"
                                style={{ width: "10vw" }}
                                className="form-control"
                                placeholder="Enter Duration in Minutes"
                                value={game.duration || ''}
                                onChange={(e) => {
                                  UpdatehandleGameMinutesChange(index, e.target.value);
                                  setItemIsChanged(prev => prev = true)
                                  setValidation(prev => prev = false)
                                }}
                                validate={{ required: { value: true } }}
                                errorMessage={props.t("Duration cannot be empty")}
                              />

                            </div>
                          ))}
                        </Col>

                        <Col lg={6} style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                          {selectedRow.item_details && selectedRow.item_details.map((item, index) => (
                            <div key={index}>
                              <Label>Quantity: <span style={{ color: "#e37168" }}>{item.name}</span></Label>
                              <AvField
                                name="in_sub_unit"
                                type="number"
                                style={{ width: "10vw" }}
                                className="form-control"
                                placeholder="Enter Quantitny in Sub-Unit"
                                value={item.quantity ? item.quantity.in_sub_unit : 0}
                                onChange={(e) => {
                                  UpdatehandleItemQuantityChange(index, e.target.value);
                                  setItemIsChanged(true); // Set the item change flag to true
                                  setValidation(false);
                                }}
                              />
                            </div>
                          ))}
                        </Col>

                      </Row>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            name="price"
                            label={props.t("Price")}
                            className="form-control"
                            type="number"
                            value={selectedRow.price}
                            onChange={(e) => {
                              setItemIsChanged(prev => prev = true)
                              setValidation(prev => prev = false)
                            }}
                            placeholder="Enter Price"
                          />
                        </Col>

                        <Col lg={6}>
                          <AvField
                            name="validity"
                            label={props.t("Validity")}
                            className="form-control"
                            value={selectedRow.validity}
                            placeholder="Enter Validity"
                            type="number"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Validity cannot be empty")}
                          />
                        </Col>

                      </Row>
                      <Row>
                        <Col lg={12} style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

                          <Button type="submit" className="btn btn-info">
                            {props.t("Update Package")}
                          </Button>
                        </Col>

                      </Row>
                      {validation && <Row>
                        <Col style={{ display: "flex", justifyContent: "center", color: "red" }}>
                          Can't Update, No Changes Made.
                        </Col>
                      </Row>}

                      {duplicateValidation && <Row>
                        <Col style={{ display: "flex", justifyContent: "center", color: "red" }}>
                          Already Selected.
                        </Col>
                      </Row>}
                    </AvForm>
                  </CardBody>
                </Card>
              )}
            </ModalBody>
          </Modal>

          {/* Delete Modal */}
          < Modal isOpen={deleteModal} toggle={() => setDeleteModal(prev => prev = !prev)} >
            <ModalHeader toggle={() => setDeleteModal(prev => prev = !prev)}>Delete Package</ModalHeader>
            <ModalBody>
              <div>
                <label className="form-label">Are you sure ?</label>

              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => { setDeleteModal(prev => prev = !prev); }}>
                Cancel
              </Button>
              <Button color="danger" onClick={() => { handleValidDelete(rowToDelete); setDeleteModal(prev => prev = !prev) }}>
                Delete
              </Button>
            </ModalFooter>
          </Modal >
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Packages)))
