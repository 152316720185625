import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Card, Row, Col, CardBody, Spinner } from 'reactstrap';
import { postSubmitForm } from '../../helpers/backend_helper';
import preloader from '../../helpers/Loader';
import logo from "../../assets/images/Logo.png"
import showNotification from '../../helpers/show_notificaton';
import { Link } from 'react-router-dom';

const PurchaseOrder = () => {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get("po_id")) {
            loadData({ po_id: urlParams.get("po_id") })
        }
    }, [])

    const [poData, setPoData] = useState([])
    const [promoItems, setPromoItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const loadData = async data => {
        try {
            setIsLoading(true)
            let url = process.env.REACT_APP_BASEURL + "purchase_orders/get_by_po_id"
            const response = await postSubmitForm(url, data)
            if (response && response.status === 1) {
                setIsLoading(false)
                let po = response.data
                setPoData(po)
                let promoItemsArr = po.delivery_details?.map(item => {
                    for (let i = 0; i < item.promotional_goods.length; i++) {
                        item.promotional_goods[i].cameWith = item.name
                    }
                    return item.promotional_goods
                })
                let tempPromoItemsArr = []
                for (let i = 0; i < promoItemsArr.length; i++) {
                    tempPromoItemsArr = [...tempPromoItemsArr, ...promoItemsArr[i]]
                }
                setPromoItems(tempPromoItemsArr)
                setPoData(po)
            } else {
                setIsLoading(false)
                showNotification(response.data, "Error")
            }
        } catch (error) {
            setIsLoading(false)
            showNotification(error, "Error")
        }
    }
    let grandTotal = 0
    const printDiv = divName => {
        let content = document.getElementById(divName)
        var printWindow = window.open(
            "",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0"
        )
        printWindow.document.write(`
       
           <html>
           <title>${divName}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
            </style>
           </head>
        <body>
      ${content.innerHTML}
        </body>
           </html>
    
        `)
        printWindow.document.close()
        printWindow.print()
        printWindow.focus()
    }
    console.log(poData, "PO");
    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div id="printDiv">
                                {isLoading === true ? (
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "1rem",
                                        marginTop: "7rem"

                                    }}>
                                        <Spinner style={{ margin: "auto", height: "4rem", width: "4rem" }} />
                                        <p style={{ marginTop: "10px", textAlign: "center" }}>Loading details, please wait...</p>
                                    </div>
                                ) : poData?.items?.length > 0 && (
                                    <div className="container pl-4  pr-0">
                                        <div
                                            className="row head"
                                            style={{
                                                backgroundColor: " #f0f0f0",
                                                height: "90px",
                                                borderRadius: "50px 0px 0px 50px",
                                            }}
                                        >
                                            <div
                                                className="col col-md-2 col-sm-2"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                <img
                                                    src={logo}
                                                    style={{
                                                        height: "105px",
                                                        borderRadius: " 50%",
                                                        width: "110px",
                                                        backgroundColor: "#000000",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="col col-md-10 col-sm-10 pt-3"
                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                            >
                                                <p
                                                    className=""
                                                    style={{ color: "#000000", fontWeight: "500" }}
                                                >
                                                    Purchase Order {`${poData.po_status === "Pending" ? " " : poData.po_status !== "Partially Delivered" ? "Received" : " "}`} <br />
                                                    PO Id : {poData.po_id}
                                                    <br />
                                                    Date:
                                                    {moment(poData.createdAt).format("YYYY-MM-DD")}
                                                </p>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row ">
                                            <h5> Supplier Information:</h5>
                                        </div> <br />
                                        <div className="row ">
                                            <div className="col col-md-4 col-sm-4">
                                                <p>
                                                    <span style={{ fontWeight: 500 }}>
                                                        Supplier Name: &nbsp;
                                                    </span>
                                                    {poData.supplier?.name}
                                                </p>
                                            </div>
                                            <div className="col col-md-4 col-sm-4">
                                                <p>
                                                    <span style={{ fontWeight: 500 }}>
                                                        Contact Person: &nbsp;
                                                    </span>
                                                    {poData.supplier?.contact_person}
                                                </p>
                                            </div>
                                            <div className="col col-md-4 col-sm-4">
                                                <p>
                                                    <span style={{ fontWeight: 500 }}>
                                                        Phone No. : &nbsp;
                                                    </span>
                                                    {poData.supplier?.phone}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col col-md-4 col-sm-4">
                                                <p>
                                                    <span style={{ fontWeight: 500 }}>Email: &nbsp;</span>
                                                    {poData.supplier?.email}
                                                </p>
                                            </div>
                                            <div className="col col-md-6 col-sm-6">
                                                <p>
                                                    <span style={{ fontWeight: 500 }}>
                                                        Address: &nbsp;
                                                    </span>
                                                    {poData.supplier?.address}
                                                </p>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row ">
                                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                <div className="table-responsive">
                                                    <h5>Items</h5>
                                                    <table
                                                        id="example"
                                                        className="table table-bordered"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <thead className="bg-light">
                                                            <tr>
                                                                <th>S.N</th>
                                                                <th>Name</th>
                                                                <th>Section</th>
                                                                <th>Category</th>
                                                                <th>Quantity</th>
                                                                {/* <th>Quantity In Sub-Unit</th> */}
                                                                <th>Cost Price</th>
                                                                <th>Sub Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* {poData.delivery_details.filter(item => +(item.receive.in_unit) !== 0 || +(item.receive.in_sub_unit) !== 0).map((item, index) => { */}
                                                            {poData.po_status === 'Pending' ?
                                                                // Render Items array
                                                                poData?.items?.map((item, index) => (
                                                                    <tr key={index + 1}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.section}</td>
                                                                        <td>{item.category}</td>
                                                                        <td>
                                                                            {item.quantity?.in_unit > 0 && item.quantity.in_unit + " " + item.unit}
                                                                            {item.quantity?.in_sub_unit > 0 && item.quantity.in_sub_unit + " " + item.sub_unit}
                                                                        </td>
                                                                        <td>{item.cost_price}</td>
                                                                        <td>{(item.total_price).toFixed(2)}</td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                // Render delivery_details array
                                                                poData?.delivery_details?.map((item, index) => {
                                                                    const rowTotal = item.receive?.in_sub_unit !== 0
                                                                        ? ((item.price / item.formula) * ((item.receive.in_unit * item.formula) + item.receive.in_sub_unit)).toFixed(2)
                                                                        : (item.price * item.receive.in_unit).toFixed(2);
                                                                    grandTotal += parseFloat(rowTotal);
                                                                    return (
                                                                        <tr key={index + 1}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.section}</td>
                                                                            <td>{item.category}</td>
                                                                            {/* <td>{`${item.receive.in_unit} ${item.unit}`}</td>
                <td>{`${item.receive.in_sub_unit} ${item.sub_unit}`}</td> */}
                                                                            <td>
                                                                                {item.receive?.in_unit > 0 && item.receive.in_unit + " " + item.unit}
                                                                                {item.receive?.in_sub_unit > 0 && item.receive.in_sub_unit + " " + item.sub_unit}
                                                                            </td>
                                                                            <td>{item.price}</td>
                                                                            <td>{rowTotal}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }

                                                            <tr>
                                                                <td
                                                                    colSpan={6}
                                                                    style={{
                                                                        textAlign: "end",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    Grand Total
                                                                </td>
                                                                <td style={{ fontWeight: "bold" }}>
                                                                    {poData?.po_status === "Pending" ? poData?.items_total_price : grandTotal.toFixed(2)}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>



                                                    {promoItems.length > 0 && (
                                                        <>
                                                            <h5>Promotional Items Received</h5>
                                                            <table
                                                                id="example"
                                                                className="table table-bordered"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead className="bg-light">
                                                                    <tr>
                                                                        <th>S.N</th>
                                                                        <th>Came With</th>
                                                                        <th>Name</th>
                                                                        <th>Quantity In Unit</th>
                                                                        <th>Quantity In Sub-Unit</th>
                                                                        <th>Price</th>
                                                                        <th>Total Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {promoItems.map((item, index) => (
                                                                        <tr key={index + 1}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.cameWith}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.receive.in_unit}</td>
                                                                            <td>{item.receive.in_sub_unit}</td>
                                                                            <td>{item.price}</td>
                                                                            <td>{item.total_price}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                        <Row className="col d-flex justify-content-center pr-5">
                                            <Col className='d-print-none  d-flex justify-content-center'>
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        printDiv("printDiv")
                                                    }}
                                                    className="btn btn-primary"
                                                >
                                                    <i className="fa fa-print" /> Print
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>

                                )}
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default PurchaseOrder