import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { submitCafeItemError, submitCafeItemSuccess, loadCafeItemSuccess } from "./actions";
import { SUBMIT_CAFE_ITEM, LOAD_CAFE_ITEM } from "./actionTypes";
import { postSubmitForm } from "../../../helpers/backend_helper";

//notifications
function showNotification(message, type){
    if (type === "Success") Swal.fire(type,message,"success");
    else Swal.fire(type,message,"error");
}

//Insert Items
function* submitCafeItem({ payload: {addItem, history} }) {
    try{
      //  let url = process.env.REACT_APP_BASEURL + "cafe_items/insert";
        let url = "http://localhost:3002/cafe_items/insert";

        const response = yield call(postSubmitForm, url, addItem);
        if(response.status === 1){
            showNotification("Cafe Item Added Successfully", "Success");
            yield put(submitCafeItemSuccess(response));
        }else{
            console.log("Saga Responce",response);
            showNotification(response.message,"Error");
            yield put(submitCafeItemError(response.message));
        }
    }catch(error){
        showNotification("Something went wrong!","Error");
        yield put(submitCafeItemError(error));
    }

}

function* loadCafeItem({ payload }) {
    try{
      //  let url = process.env.REACT_APP_BASEURL + "cafe_items/insert";
        let url = "http://localhost:3002/cafe_items/getall";

        const response = yield call(postSubmitForm, url,payload);
        if(response.status === 1){
            yield put(loadCafeItemSuccess(response));
        }else{
            console.log("Saga Responce",response);
            showNotification(response.message,"Error");
            yield put(CafeItemError(response.message));
        }
    }catch(error){
        showNotification("Something went wrong!","Error");
        yield put(submitCafeItemError(error));
    }

}

export function* watchSubmitCafeItem(){
    yield takeEvery(SUBMIT_CAFE_ITEM, submitCafeItem);
}

export function* watchLoadCafeItem(){
    yield takeEvery(LOAD_CAFE_ITEM, loadCafeItem);
}
function* CafeItemSaga(){
    yield all([fork
            (watchSubmitCafeItem),
            (watchLoadCafeItem)
        ]);
}

export default CafeItemSaga;