import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input, Badge } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter";
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import showNotification, { showWarning } from "../../helpers/show_notificaton";
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import BackgroundImage from "../../assets/images/Background.png";
import logo from "../../assets/images/Logo.png"
import styles from "../Dashboard/Badge.module.css";
import moment from "moment"

const ThresholdReport = props => {

    const { SearchBar } = Search
    const [allItems, setAllItems] = useState([]);
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [section, setSection] = useState("");
    const code = localStorage.getItem("code")

    const handleDownload = async () => {
        const fileName = "All Items";
        const exportType = "xls";

        // let data_to_export = allItems;
        let data_to_export = section!=="" 
        ?
        allItems.filter(item=>item.section===section)  
        : allItems

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
                data.push({
                    Name: v.name,
                    Unit: v.unit,
                    category: v.category,
                    Section: v.section,
                    Sub_Unit: v.sub_unit,
                    Formula: v.formula,
                    Selling_Price: v.selling_price,
                    Threshold: v.threshold,
                    Menu_Item: v.is_menu_item,
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    useEffect(() => {
        loadAllItems()
    }, [])


    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            const imageHeight = "105px";
            const imageWidth = "110px"; 
            const borderradius = "50%";
            const background = "#000000"; 
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 150px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>Items Threshold Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function() {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };


    const loadAllItems = async () => {
        let url = process.env.REACT_APP_BASEURL + "items/getall_item"
        // let url = "http://localhost:8080/item_category/get_all_item_category"
        let response = await postSubmitForm(url, "")
        if (response.status === 1) {
            const filterData = response.data.filter(item=>item.available.in_unit<=item.threshold)
            setAllItems(filterData)
            console.log(filterData);
        } else {

            showNotification(response.message, "Error")
        }
    }
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: allItems && allItems.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
            footer:false,
        },

        {
            text: props.t("Name"),
            dataField: "name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        {
            text: props.t("Category"),
            dataField: "category",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        {
            text: props.t("Section"),
            dataField: "section",
            filter: selectFilter({
                options: [
                    { value: "Accessories", label: "Accessories" },
                    { value: "Cafe", label: "Cafe" },
                    { value: "Kitchen", label: "Kitchen" },
                ],
                placeholder: "ALL",
                onFilter: (value) => {
                    setSection( value )
                  }  
            }),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        {
            text: props.t("Unit"),
            dataField: "unit",
            filter: textFilter({
                caseSensitive: false,
                style: {
                    height: "20px",
                }
            }),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        footer:false,
        },
        {
            text: props.t("Sub-Unit"),
            dataField: "sub_unit",
            filter: textFilter({
                caseSensitive: false,
                style: {
                    height: "20px",
                }
            }),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        {
            text: props.t("Formula"),
            dataField: "formula",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        
        {
            text: props.t("Available"),
            dataField: "available.in_unit" ,
            sort: true,
            formatter: (cell, row) => {
              if(row.available.in_unit <= row.threshold && row.sub_unit!==""){
                return (
                  <>
                    <Badge style={{ fontSize: "12px" }} className={`${styles.glowBadge} badge-soft-danger`}>{ row.available.in_unit + "  " + row.unit + " " + row.available.in_sub_unit + " " + row.sub_unit }</Badge>
                  </>
                );
              } else if (row.available.in_unit <= row.threshold && row.sub_unit === "") {
                return (
                  <>
                    <Badge style={{ fontSize: "12px" }} className={`${styles.glowBadge} badge-soft-danger`}>{row.available.in_unit + "  " + row.unit}</Badge>
                  </>
                );
              }
              else if (row.sub_unit!=="") {
                return (
                  <>
                    {row.available.in_unit + "  " + row.unit + " " + row.available.in_sub_unit + " " + row.sub_unit}
                  </>
                );
              }
               else {
                return (
                  <>
                    {row.available.in_unit + "  " + row.unit}
                  </>
                );
              }
             
            },
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
            footer:false,
          },
        {
            text: props.t("Threshold"),
            dataField: "threshold",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        {
            text: props.t("Last Threshold "),
            dataField: "last_threshold_reached",
            formatter:(cell,row)=>{
                if(row.last_threshold_reached){
                    const thrsoldDate = moment(row.last_threshold_reached)
                    const today = moment();
                    const difference = today.diff(thrsoldDate,'days')
                    return difference + " "+"days ago"
                }
            },
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },
        {
            text: props.t("Selling Price"),
            dataField: "selling_price",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
        },
        {
            text: props.t("Menu Item"),
            dataField: "is_menu_item",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>
                        {row.is_menu_item ? "Yes" : "No"}
                    </>
                )
            },
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            footer:false,
        },

    ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",


    };
    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Items Threshold Report" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <CardTitle>
                                        <Row>
                                            <Col lg={6}>
                                                {props.t("Threshold Report")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={() => { printDiv("printDiv") }}
                                                    disabled={allItems.length<=0}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    disabled={!allItems}
                                                    onClick={handleDownload}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    <CardSubtitle className="mb-3">
                                        {/* {props.t("View all Items")} */}
                                    </CardSubtitle>
                                  <div id="printDiv">
                                  <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={allItems}
                                        search

                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row className="hide">
                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    // pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                  </div>




                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(ThresholdReport)))
