import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import logo from "../../assets/images/Logo.png"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"

// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import moment from "moment"
import BackgroundImage from "../../assets/images/Background.png"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import preloader from "../../helpers/Loader"


const Refund = props => {
  const { SearchBar } = Search

  const [bookings, setBookings] = useState([])
  //Pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [minDateVal, setMinDateVal] = useState("");
  const [pageSize, setPageSize] = useState(5)
  const [printModal, setPrintModal] = useState()
  const [selectedRow, setselected_Row] = useState()
  const [paymentDetails, setPaymentDetails] = useState()
  const [packageDetails, setPackageDetails] = useState()
  const [fromdate, setFromdate] = useState(new Date())
  const [todate, setTodate] = useState(new Date())


  //Customer details modal
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false)

  useEffect(() => {
    handleValidSubmit()
  }, [])


  const handleValidSubmit = async () => {
   try {
    preloader(true)
    const payload = {
        date_from: moment(fromdate).format("YYYY-MM-DD"),
        date_to: moment(todate).format("YYYY-MM-DD"),
      }
      let url = process.env.REACT_APP_BASEURL + "refunds/getall_sale_for_refund"
      const response = await postSubmitForm(url, payload)
      if (response && response.status === 1) {
        preloader(false)
        const filter = response.data.filter(bkng => (bkng.is_package_only === false && bkng.invoice_no))
        setBookings(filter)
      } else {
        preloader(false)
        showNotification(response.message, "Error")
      }
   } catch (error) {
    preloader(false)
    showNotification(error,"Error")
   }
  }


  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  )

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page)
      setPageSize(sizePerPage)
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: bookings && bookings.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  }

  const handleViewCustomerDetails = customerDetails => {
    setSelectedCustomer(customerDetails)
    setIsCustomerModalOpen(true)
  }

  function invoiceFormatter(cell, row) {
    console.log(cell);
    console.log(row);
    return (<>

        <i
          // className="fa fa-print fs-5 ms-2 " aria-hidden="true"
          style={{ color: "blue", cursor: "pointer" }}
          title={"Print Invoice"}
          // disabled={row.booking_status === "Booked" || row.booking_status === "In Process" || row.booking_status === "Cancelled" || row.booking_status === "Completed"}
          onClick={() => {
            setPrintModal(prev => prev = !prev)
            setselected_Row(row)
            setPaymentDetails(row.payment_details)
            setPackageDetails(row.package_details)
          }}
        >{row.invoice_no}
        </i>
</>
    );
  }
const [refundModal, setRefundModal] = useState(false)
const [refund, setRefund] = useState(false)
const [selectedInvoice, setSelectedInvoice] = useState({})
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "customer_details.mobile",
      hidden: true,
    },
    {
      text: "S.N.",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: "Invoice",
      dataField: "invoice_no",
      formatter: invoiceFormatter,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
    },
    {
      text: props.t("Checkout Date"),
      dataField: "createdAt",
      formatter: (cell, row) => {
        return moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Bkg. Rcvd. By"),
      dataField: "booking_received_by.name",
      hidden:true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Bkg. Closed By"),
      dataField: "emp_details.name",
      formatter: (cell, row) => {
        return row.emp_details?.name ? row.emp_details?.name : "Not yet closed"
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Start Time",
      dataField: "start_time",
      formatter: (cell, row) => {
        if (row.start_time) {
          return moment(row.start_time).format("YYYY-MM-DD HH:mm:ss")
        } else {
          return "Not yet started"
        }
      },
      hidden: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "End Time",
      dataField: "end_time",
      formatter: (cell, row) => {
        if (row.end_time) {
          return moment(row.end_time).format("YYYY-MM-DD HH:mm:ss")
        } else {
          return "Not yet ended "
        }
      },
      hidden: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Games"),
      dataField: "game_details",
      formatter: (gameDetails, row) => {
        if (gameDetails && gameDetails.length > 0) {
          return gameDetails.map((game, id) => (
            <Badge key={id} color="primary" className="me-1">
              {game.game.name}
            </Badge>
          ))
        }
        return (
          <Badge style={{ cursor: "not-allowed" }} color="secondary">
            No Games
          </Badge>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Items"),
      dataField: "item_details",
      formatter: (itemDetails, row) => {
        if (itemDetails && itemDetails.length > 0) {
          return itemDetails.map((item, id) => (
            <Badge key={id} color="info" className="me-1">
              {item.name}
            </Badge>
          ))
        }

        return (
          <Badge style={{ cursor: "not-allowed" }} color="secondary">
            No Items
          </Badge>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: "Paid Amount",
      dataField: "final_amount",
      sort: false,
      formatter: (cell, row) => cell || "0",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "booking_status",
      text: "Booking Status",
      filter: selectFilter({
        options: [
          { value: "Booked", label: "Booked" },
          { value: "In Process", label: "In Process" },
          { value: "Completed", label: "Completed" },
          { value: "Cancelled", label: "Cancelled" },
          { value: "Checked out", label: "Checked out" },
        ],
        defaultValue: "All",
        placeholder: "ALL",
      }),
      hidden:true,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "is_refunded",
      text: "Payment Status",
      formatter:(cell,row)=>{
      return row.is_refunded ? "Yes" :"No"    
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Customer",
      formatter: (cell, row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          <i
            className="fa fa-user"
            style={{
              color: "#556EE6",
              fontSize: "17px",
              cursor: "pointer",
              marginTop: "4.3px",
            }}
            onClick={() => handleViewCustomerDetails(row.customer_details)}
          ></i>
          <i
            className="fa fa-info-circle"
            style={{ fontSize: "13px", marginTop: "-2px", cursor: "pointer" }}
            onClick={() => handleViewCustomerDetails(row.customer_details)}
          ></i>
        </div>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: "Action",
      formatter: (cell, row) =>{
          return (
              <>
              {row.is_refunded ? (
                <span className="d-flex justify-content-center">
             <i
                   className="bx bxs-receipt fs-5 mt-1 text-primary"
                   title="Click to view refund receipt"
                   style={{ cursor: "pointer" }}
                   onClick={() => {
                    const invoiceUrl = `${window.location.origin}/refund_invoice?booking_id=` + row.booking_id
                    const newTab = window.open(`${invoiceUrl}`, "_blank")
                    if (newTab) {
                      newTab.onload = () => {
                        newTab.postMessage({}, window.location.origin);
                      };
                      newTab.focus()
                    }
                   }}
                 />
                </span>
      
              ) : (
                <Button className="btn btn-sm btn-danger"
                  onClick={()=>{setRefundModal(true);setSelectedInvoice(row);setRefund(true)}}
                  >
                     Refund
                     </Button>
              )}
           

              </>
          )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
  },

  ]

  const rowStyle = row => {
    if (row.is_refunded === true) {
      return { backgroundColor: "#9873AC" }
    } 
    return {}
  }


  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };


  // var refund = false
  const handleSubmit = async (e,v)=>{
try {
  let payload = {
    invoice_no : selectedInvoice.invoice_no,
          booking_id :selectedInvoice.booking_id,
          refund_amount : v.refund_amount,
          refund_remarks : v.refund_remarks,
          customer_details : selectedInvoice.customer_details,
  }
  // console.table(payload,"pl");return
  
  let url = process.env.REACT_APP_BASEURL + "refunds/insert"
  const response = await postSubmitForm(url, payload) 
  if(response.status===1){
    showNotification(response.message,"Success");
    const invoiceUrl = `${window.location.origin}/refund_invoice?booking_id=` + response.data.booking_id
    const newTab = window.open(`${invoiceUrl}`, "_blank")
    if (newTab) {
      newTab.onload = () => {
        newTab.postMessage({}, window.location.origin);
      };
      newTab.focus()
    }
    handleValidSubmit();
    setSelectedInvoice({});
    setRefundModal(false);
  }
} catch (error) {
  showNotification(error,"Error");
}
}
  function toggle(){
    setRefundModal(false)
    setSelectedInvoice({})
  } 

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Bookings"
            alternateTitle="All Bookings"
            breadcrumbItem1="All Bookings"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={handleValidSubmit}>
                    <Row >


                      <Col lg={3}>
                        <label style={{ color: "white" }} className="form-label">
                          {props.t("From Date")}
                        </label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={fromdate}
                          options={{
                            // maxDate: "today",
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}
                          onChange={(v) => {
                            const dateString = v;
                            const date = new Date(dateString);
                            setFromdate(date);
                            const day = date.getDate();
                            const month = date.getMonth() + 1; // Months are zero-based, so add 1
                            const year = date.getFullYear();
                            // Format the date as "dd-mm-yyyy"
                            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                            setMinDateVal(formattedDate);
                          }}
                          name="date_from"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />

                      </Col>

                      <Col lg={3} >

                        <label style={{ color: "white" }} className="form-label">
                          {props.t("To Date")}
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={todate}
                          onChange={(v) => {
                            const dateString = v;
                            const date = new Date(dateString);
                            setTodate(date);
                            const day = date.getDate();
                            const month = date.getMonth() + 1; // Months are zero-based, so add 1
                            const year = date.getFullYear();
                            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                          }}
                          options={{
                            minDate: minDateVal,
                            // maxDate: "today",
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault();
                              });
                            },

                          }}
                          name="date_to"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />


                      </Col>

                      <Col lg={2}>
                        <div
                          style={{
                            marginTop: "25px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Submit")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>
                      </Col>

                    </Row>

                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}

                >

                  <Col>
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={bookings || []}
                      search
                      isGlobalFilter={true}
                      isAddUserList={true}
                      customPageSize={10}
                    >
                      {propst => (
                        <div>
                          <AvForm>
                            <Row>
                              <label className="form-label">
                                {"Search Bookings"}
                              </label>
                              <Col lg={2} style={{ display: "flex" }}>
                                <SearchBar
                                  {...propst.searchProps}
                                // style={{ width: "340px" }}
                                />
                              </Col>
                              <Col>
                                {/* <Button className="btn btn-info" onClick={() => { setResetBtnClickedTracker(prev => prev = prev + 1); setFirstTimeApiCall(false); }}  >Reset To Current Month</Button> */}
                              </Col>
                            </Row>
                          </AvForm>
                          <BootstrapTable
                            {...propst.baseProps}
                            filter={filterFactory()}
                            noDataIndication={"No data to display."}
                            // striped
                            condensed
                            rowStyle={rowStyle}
                            pagination={paginationFactory(options)}
                          />
                     

                          {/* Customer details Modal */}
                          <Modal
                            style={{ marginTop: "5%" }}
                            isOpen={isCustomerModalOpen}
                            toggle={() => setIsCustomerModalOpen(false)}
                          >
                            <ModalHeader
                              toggle={() => setIsCustomerModalOpen(false)}
                            >
                            </ModalHeader>
                            <ModalBody>
                              {selectedCustomer && (
                                <>
                                  {" "}
                                  <h5
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      background: "#556ee6",
                                      color: "white",
                                      borderRadius: "5px",
                                      height: "20px",
                                      paddingTop: "30px",
                                      paddingBottom: "30px",
                                    }}
                                  >
                                    ⟪ Customer Details ⟫

                                  </h5>{" "}
                                  <br />
                                  <div className="scrollable-details-customer ">
                                    <div style={{ display: "flex", }}>
                                      <h5 className="modal-heading" >
                                        Name:
                                      </h5> &nbsp; &nbsp;
                                      <h5>{selectedCustomer.name}</h5>
                                    </div>
                                    <div style={{ display: "flex", }}>
                                      <h5 className="modal-heading" >
                                        Mobile Number:
                                      </h5> &nbsp; &nbsp;
                                      <h5>{selectedCustomer.mobile}</h5>
                                    </div>
                                    <div style={{ display: "flex", }}>
                                      <h5 className="modal-heading" >
                                        Customer Type:
                                      </h5> &nbsp; &nbsp;
                                      <h5>{selectedCustomer.customer_type}</h5>
                                    </div>



                                    {/* Add more customer details if needed */}
                                  </div>
                                </>
                              )}
                            </ModalBody>
                            <ModalFooter>
                              {/* <Button
                                color="secondary"
                                onClick={() => setIsCustomerModalOpen(false)}
                              >
                                Close
                              </Button> */}
                            </ModalFooter>
                          </Modal>

                          {/* Refund Modal  */}
                        {selectedInvoice && selectedInvoice.invoice_no && (
                              <Modal isOpen={refundModal} toggle={toggle}>
                              <ModalHeader toggle={toggle} className="border-0">Process Refund</ModalHeader>
                              <AvForm onValidSubmit={(e,v)=>handleSubmit(e,v)}>
                                  <ModalBody>
                                  <div className="d-flex justify-content-between mb-3">
                                      <strong>Invoice {invoiceFormatter(selectedInvoice,selectedInvoice)}</strong>
                                      <strong>Total Paid: {selectedInvoice?.final_amount}</strong>
                                  </div>
                                  <AvField
                                            name="refund_amount"
                                            label={props.t("Refund Amount")}
                                            type="number"
                                            errorMessage={props.t("Refund amount cannot be empty")}
                                            placeholder={props.t("Enter refund amount")}
                                            validate={{
                                              pattern: {
                                                value: "^[0-9]+$",
                                                errorMessage:
                                                  props.t("Only numbers are allowed."),
                                              },
                                              max: {
                                                value: selectedInvoice?.final_amount,
                                                errorMessage: props.t(`Refund amount cannot be more than paid amount`),
                                              },
                                              min: {
                                                value: 1,
                                                errorMessage: props.t("Enter a valid amount"),
                                              },
                                            }}
                                          />
                                  <AvField
                                            name="refund_remarks"
                                            label={props.t("Remarks")}
                                            type="textarea"
                                            errorMessage={props.t("Refund amount cannot be empty")}
                                            placeholder={props.t("Enter refund reason")}
                                            validate={{ required: { value: true } }}
                                          />
                                  {/* <FormGroup>
                                      <div className="d-flex justify-content-between">
                                      <span>Refund Policy</span>
                                      <a href="#">View Policy</a>
                                      </div>
                                      <small className="text-muted">
                                      Refunds are subject to a 10% processing fee. Requests must be submitted within 30 days of purchase.
                                      </small>
                                  </FormGroup> */}
                                  </ModalBody>
                                  <ModalFooter className="border-0">
                                  <Button color="secondary" onClick={toggle}>Cancel</Button>
                                  <Button color="dark" type="submit">Submit</Button>
                                  </ModalFooter>
                              </AvForm>
                              </Modal>
                        )}
                        </div>
                      )}
                    </ToolkitProvider>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* Print Receipt */}
        <Row>
          <Col>
            <Modal
              size="xl"
              isOpen={printModal}
              toggle={() => setPrintModal(prev => prev = !prev)}
            >
              <ModalHeader toggle={() => setPrintModal(prev => prev = !prev)} >
              </ModalHeader >
              <ModalBody>
                <div id="printDiv">
                  {/* {selectedRow && ( */}
                  <div className="container pl-4  pr-0">
                    <div className="row head" style={{ backgroundColor: " #f0f0f0", height: "100px", borderRadius: "50px 0px 0px 50px" }}>
                      <div className="col col-md-2 col-sm-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                        <img src={logo} style={{ height: "105px", borderRadius: " 50%", width: "110px", backgroundColor: "#000000" }} />
                      </div>
                      <div className="col col-md-10 col-sm-10 pt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <p className="" style={{ color: "#000000", fontWeight: "500" }}>
                          {selectedRow?.invoice_no} فاتورة رقم<br />
                          {/* PO Id : {selectedRow.po_id}<br /> */}
                          <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{moment(selectedRow?.createdAt).format("YYYY-MM-DD HH:mm")} [{selectedRow?.emp_details?.name}] </span>
                          {/* <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}></span> */}
                        </p>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row ">
                      <h5> {props.t("Billed To")}</h5>
                    </div>
                    <p className="mb-0 date">
                      <span style={{ fontWeight: 500 }}>
                        {props.t("Name")}: &nbsp;
                        <b> {selectedRow?.customer_details.name}</b>
                      </span>
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: 500 }}>{props.t("Phone")}:</span> &nbsp;
                      <b>{selectedRow?.customer_details.mobile}</b>
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: 500 }}>{props.t("Type")}: </span> &nbsp;
                      <b> {selectedRow?.customer_details.customer_type}</b>
                    </p>
                    <hr />

                    {/* Games */}
                    {selectedRow?.game_details.length > 0 && (
                      <div className="row ">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">
                            <h5>{props.t("Games")}</h5>
                            <table
                              id="example"
                              className="table table-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead className="bg-light">
                                <tr>

                                  <th>{props.t("S.N")}</th>
                                  <th>{props.t("Game")}</th>
                                  <th>{props.t("Table")}</th>
                                  <th>{props.t("Duration")}</th>
                                  <th>{props.t("Start Time")}</th>
                                  <th>{props.t("End Time")}</th>
                                  <th>{props.t("Sub Total(QR)")}</th>

                                </tr>
                              </thead>
                              <tbody>
                                {selectedRow?.game_details.map((game, index) =>

                                  <tr key={index + 1} >
                                    <td>{index + 1}</td>
                                    <td>{game?.game.name}</td>
                                    <td>{game?.table_number}</td>
                                    <td>{game?.game.duration}  {props.t("minutes")}</td>
                                    <td>{game?.game.table_start_time ? moment(game?.game.table_start_time).format("HH:mm") : ""}</td>
                                    <td>{game?.game.table_end_time ? moment(game?.game.table_end_time).format("HH:mm") : ""}</td>
                                    <td>{game?.game.sub_total}</td>

                                  </tr>

                                )}
                                <tr>
                                  <td colSpan={6} style={{ textAlign: "end", fontWeight: "bold" }} >{props.t("Game Total")}</td>
                                  <td style={{ fontWeight: "bold" }}>{selectedRow?.game_total_price || 0}</td>
                                </tr>

                              </tbody>
                            </table>



                          </div>


                        </div>
                      </div>
                    )}


                    {/* Items */}
                    <div className="row ">
                      <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        {selectedRow?.item_details.length > 0 && (
                          <div className="table-responsive">
                            <h5>{props.t("Items ")}</h5>
                            <table
                              id="example"
                              className="table table-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead className="bg-light">
                                <tr>
                                  <th>{props.t("S.N")}</th>
                                  <th>{props.t("Name")}</th>
                                  <th>{props.t("Section")}</th>
                                  <th>{props.t("Quantity")}</th>
                                  <th>{props.t("Selling Price")}</th>
                                  <th>{props.t("Sub Total(QR)")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedRow?.item_details.map((item, index) =>
                                  <tr key={index + 1} >

                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.section}</td>
                                    <td>
                                      {item.quantity.in_unit > 0 && item.quantity.in_unit} {item.quantity.in_unit > 0 && item.unit}
                                      {item.quantity.in_sub_unit > 0 && item.quantity.in_sub_unit} {item.quantity.in_sub_unit > 0 && item.sub_unit}
                                    </td>
                                    <td>{item.price}</td>
                                    <td>{item.sub_total}</td>
                                  </tr>)}
                                <tr>
                                  <td colSpan={5} style={{ textAlign: "end", fontWeight: "bold" }} >Item Total</td>
                                  <td style={{ fontWeight: "bold" }}>{selectedRow?.item_total_price || 0}</td>
                                </tr>

                              </tbody>
                            </table>


                          </div>
                        )}

                        <hr />

                        {selectedRow?.is_bill_split && (
                          <div className="row ">
                            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                              <div className="table-responsive">
                                <h5>{props.t("Bill Split Details")} </h5>
                                <table
                                  id="example"
                                  className="table table-bordered"
                                  style={{ width: "100%" }}
                                >
                                  <thead className="bg-light">
                                    <tr>
                                      <th>{props.t("S.N")}</th>
                                      <th>{props.t("Splitted Between")}</th>
                                      <th>{props.t("Splitted Amount Per Head")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>


                                    <tr>
                                      <td>{+1}</td>
                                      <td>{selectedRow.bill_split_between}</td>
                                      <td>{selectedRow.bill_split_amount}</td>
                                    </tr>




                                  </tbody>
                                </table>



                              </div>


                            </div>
                          </div>
                        )}

                        <hr />

                        
                        <div className="row">
                          <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className="">
                              <span style={{ fontWeight: 500 }}>
                                {props.t("Total Amount")} &nbsp; :&nbsp;
                                {selectedRow?.total_amount || 0}
                              </span>
                            </p>
                          </div>
                        </div>



                        {selectedRow?.discount_amount >0  && (
                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  {props.t("Discount Amount")}  &nbsp; :&nbsp;
                                  {selectedRow?.discount_amount || 0}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}



                        <div className="row">
                          <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className="">
                              <span style={{ fontWeight: 500 }}>
                                {props.t("Paid Amount")} &nbsp;:&nbsp;
                                {selectedRow?.final_amount || 0}
                                {/* </b> */}
                              </span>
                            </p>
                          </div>
                        </div>


                        {paymentDetails?.map((payment, index) => {
                          const { _id, ...paymentDetailsWithoutId } = payment;

                          let mappedString = '';
                          for (let key in paymentDetailsWithoutId) {
                            let value = paymentDetailsWithoutId[key];
                            mappedString += `Paid By ${key}  :  ${value}\n`;
                          }


                          return (
                            // <div style={{display:"flex",flexDirection:"column", gap:"6px"}} key={index}>
                            <div key={index} className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>

                                  {mappedString}

                                </span>
                              </p>
                            </div>
                            // </div>
                          );
                        })}

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Change &nbsp; :&nbsp;
                                  {selectedRow?.change_amount || 0}
                                  {/* </b> */}
                                </span>
                              </p>
                            </div>
                          </div>
                          {selectedRow?.refund?.refund_amount > 0 && (
                           <div className="row">
                           <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                             <p className="">
                               <span style={{ fontWeight: 500 }}>
                                 Refunded Amount &nbsp; :&nbsp;
                                 {selectedRow?.refund?.refund_amount}
                                 {/* </b> */}
                               </span>
                             </p>
                           </div>
                         </div>
                         )}

                      {selectedRow?.difference_amount > 0 && (
                           <div className="row">
                           <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                             <p className="">
                               <span style={{ fontWeight: 500 }}>
                                 Extra Income &nbsp; :&nbsp;
                                 {selectedRow?.difference_amount}
                                 {/* </b> */}
                               </span>
                             </p>
                           </div>
                         </div>
                         )}



                        <div className="row mb-5" style={{ display: "flex", justifyContent: "flex-end" }}>

                        </div>
                      </div>
                    </div>

                  </div>
                  {/* )} */}


                </div>
                <Row className="col text-right pr-5">
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    {/* <Link to="#" 
                    onClick={() => { 
                      printDiv("printDiv"); 
                    }} className="btn btn-primary">
                      <i className="fa fa-print" /> {props.t("Print")}
                    </Link> */}
                    <a
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor: "#fdd005",
                        border: "none",
                        marginBottom: "10px",
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "500",
                        width: "190px",
                        boxShadow:
                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                        transition: "transform 0.3s ease",
                      }}

                      className="btn btn-primary"
                      href={
                        "/invoice_details?booking_id=" + selectedRow?._id
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {props.t("Print")}
                    </a>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Col>
        </Row>

      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Refund)))
