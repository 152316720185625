import React, { useRef, useEffect, useState } from "react"
import {
  Card, CardBody, Col, Row, Container, Input, Button, CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Label,
} from "reactstrap"
import Loader from "../../helpers/Loader"
import ReactAvatarEditor from "react-avatar-editor"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation";
import Flatpickr from "react-flatpickr";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import axios from "axios";
import { update } from "lodash";
import filterFactory, { textFilter,selectFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import showNotification from "../../helpers/show_notificaton"
import moment from "moment"
import preloader from "../../helpers/Loader"


const EmployeeViewAttendance = (props) => {

  const [data, setData] = useState([]);
  const [shifts, setShifts] = useState({});

  const [attendanceDate, setAttendanceDate] = useState(new Date())
  const [tracker, setTracker] = useState(false)
  const [editModal, setEditModal] = useState()
  const [employee_id, setEmployee_id] = useState(localStorage.getItem("employee_id"))
  const [selectedUser, setSelectedUser] = useState()
  const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
  const [markUndertime, setMarkUndertime] = useState({ "aksfjaslkf": false });
  const [isLoading, setIsLoading] = useState(false)



  const refContainer = useRef(null);
  const { SearchBar } = Search

 
  const fetchAttendance = (object, token) => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL
    axios
      .post(
        url + "attendances/getall_by_date",

        object,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        preloader(false)
        const Filter = res.data.data.filter(emp=>emp.employee_id===employee_id)
        setData(Filter)
        setIsLoading(prev => prev = false)

      })
      .catch((err) => {
        preloader(false);
        console.log(err);

      });
  };

  useEffect(()=>{
    const object = {
        attendance_date: new Date().toISOString().split('T')[0]
      }
      const token = employeeToken
    fetchAttendance(object,token);
  },[tracker])
 

  const handleValidSubmit = async (e, v, token, status) => {
    // let url = process.env.REACT_APP_BASEURL + "adminusers/inser"
    // let formData = new FormData();
    // formData.set("employee", v.employee.name)
    // formData.set("username", v.username);
    // formData.set("pwd", v.pwd);
    // formData.set("role", v.role);
    // console.log(status, "status from handle submit")
    let object = {}
    if (status === "modalUpdate") {


      const dateString = selectedUser.attendance_date;


      const date = new Date(dateString);
      // Extract the date components
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based, so add 1
      const year = date.getFullYear();

      // Format the date as "dd-mm-yyyy"
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

      const shiftObject = filterShiftId(selectedUser.shift_name)
      // console.log("shiftId:", shiftObject)
      object = {
        employee_id: selectedUser.employee_id,
        attendance_date: formattedDate,
        shift_id: shiftObject._id,
        in_time: v.in_time,
        out_time: v.out_time,
        day_status: v.day_status,
        is_approved: v.is_approved,
        remarks: v.remarks,
        undertime: markUndertime === false ? 0 : v.undertime,
        mark_undertime: markUndertime === false ? false : v.undertime > 0 ? true : false
      }
      // console.log(object, "modal object data")
      updateAttendance(object, token)
      // console.log("below update attendance")
      setEditModal(!editModal);
      refContainer.current.reset()
    } else {

      object = {
        attendance_date: v.attendance_date
      }
      setAttendanceDate(object)
      fetchAttendance(object, token);
    }
    // setApiDatePayload(object)
    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error")
    //   }
    // })
  }



  function dateFormatter(cell, row) {

    return row.attendance_date.slice(0, 10)
  }

  // -----------------------------------------------

  function isApprovedFormatter(cell, row) {

    return row.is_approved === true ? "Yes" : "No"
  }
  // --------------------------------------------------

  function dayStatusFormatter(cell, row) {
    return row.day_status === "P" ? <Badge
      className={`fs-5 badge-soft-success`}
    >
      {row.day_status}
    </Badge> : row.day_status === "A" ? <Badge
      className={`fs-5 badge-soft-danger`}
    >
      {row.day_status}
    </Badge> : row.day_status === "LWP" ? <Badge
      className={`fs-5 badge-soft-danger`}
    >
      {row.day_status}
    </Badge> : null;
  }
 

  const columns = [

    {
      dataField: "_id",
      hidden: true
    },
    {
      dataField: "employee_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Employee Code"),
      //formatter: employeeFormatter,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Employee Name"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Attendance Date"),
      formatter: dateFormatter,
      dataField: "attendance_date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Shift"),
      //formatter: employeeFormatter,
      dataField: "shift_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("In Time"),
      //formatter: employeeFormatter,
      dataField: "in_time",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Out Time"),
      //formatter: employeeFormatter,
      dataField: "out_time",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Late In"),
      //formatter: employeeFormatter,
      dataField: "late_in",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Early Out"),
      //formatter: employeeFormatter,
      dataField: "early_out",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("System Overtime"),
      //formatter: employeeFormatter,
      dataField: "system_overtime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Approved Overtime"),
      // formatter: (col, row) => row.approved_overtime || '0',
      dataField: "approved_overtime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Approved Undertime"),
      // formatter: (col, row) => row.approved_undertime || '0',
      dataField: "undertime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Day Status"),
      formatter: dayStatusFormatter,
      dataField: "day_status",
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", 
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Is Approved"),
      formatter: isApprovedFormatter,
      dataField: "is_approved",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Remarks"),
      //formatter: employeeFormatter,
      dataField: "remarks",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    }

  ]


  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Attendance"  breadcrumbItem1="Edit Attendance" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={(e, v) => { setIsLoading(prev => prev = true); handleValidSubmit(e, v, employeeToken, ""); }} ref ={refContainer}>
                    <Row >

                      <Col md={2}>
                       

                        <AvField
                          label={props.t("Attendance Date")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={attendanceDate}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}

                          name="attendance_date"
                          placeholder="Select Attendance Date"
                          errorMessage="Select Attendance Date"
                          validate={{
                            required: { value: true },
                          }}
                        />

                      </Col>

                      <Col md={2}>
                       

                      <AvField
                          label={props.t("Select Branch")}
                          className="form-control"
                          type="select"
                          name="branch"
                          errorMessage="Please Select Branch"
                          validate={{
                            required: { value: false },
                          }}
                        >
                          <option value="">{props.t("All")}</option>
                          <option value="Rendezvous">{props.t("Rendezvous")}</option>
                        </AvField>

                      </Col>

                      <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                        <label style={{ height: "20.5px" }} className="form-label">

                        </label>
                        <div style={{display:"flex"}}>

                        <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "-0.2rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Show")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>

                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <CardTitle>{props.t("Search by any keyword")} </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    data={data}
                    columns={columns}
                    search
                  >
                    {propst => (
                      <div>
                        <Row>
                          <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                            <SearchBar
                              className="form-control"
                              {...propst.searchProps}
                              // style={{ width: "340px" }}
                              placeholder="search"
                            />
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col>
          <Modal
            size="lg"
            isOpen={editModal}
            toggle={() => setEditModal(prev => prev = !prev )}
          >
            <ModalHeader toggle={() => setEditModal(prev => prev = !prev )} >
              Update Attendance of {selectedUser && selectedUser.employee_name}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v, employeeToken, "modalUpdate")
                        }}
                      >

                        <Row>
                          <Col>
                            <label htmlFor="">In Time</label>
                            <AvField
                              className="form-control"
                              type="text"
                              tag={Flatpickr}
                              value={selectedUser && selectedUser.in_time || 0}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true
                              }}
                              name="in_time"
                              placeholder="In Time"
                              errorMessage="In Time cannot be Empty"
                              validate={{
                                required: { value: selectedUser && selectedUser.in_time ? true : true },
                              }}
                            />
                          </Col>

                          <Col>
                            <label htmlFor="">Out Time</label>
                            <AvField
                              className="form-control"
                              type="text"
                              tag={Flatpickr}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true
                              }}
                              value={selectedUser && selectedUser.out_time || 0}
                              name="out_time"
                              placeholder="Out Time"
                              // errorMessage="Out Time cannot be Empty"
                              // validate={{
                              //   required: { value: selectedUser && selectedUser.out_time ? true : true },
                              // }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          {/* <Col>
                            <label htmlFor="">Day Status</label>
                            <AvField
                              className="form-control"
                              type="select"


                              value={selectedUser && selectedUser.day_status || "A"}
                              name="day_status"
                              placeholder="Day Status"
                              errorMessage="Day Status cannot be Empty"
                              validate={{
                                required: { value: selectedUser && selectedUser.day_status ? true : true },
                              }}
                            >
                              <option value="P">Present</option>
                              <option value="A">Absent</option>
                              <option value="LWP">LWP</option>
                            </AvField>
                          </Col> */}

                          <Col lg={6}>
                            <label htmlFor="">Is Approved</label>
                            <AvField
                              className="form-control"
                              type="select"


                              value={selectedUser && selectedUser.is_approved ? "true" : "false" || "false"}
                              name="is_approved"
                              placeholder="Is Approved"
                              errorMessage="Is Approved cannot be Empty"
                              validate={{
                                required: { value: selectedUser && selectedUser.is_approved ? false : false },
                              }}
                            >

                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </AvField>
                          </Col>

                          <Col lg={6} >
                            <label htmlFor="">Remarks</label>
                            <AvField
                              className="form-control"
                              type="textarea"


                              value={selectedUser && selectedUser.remarks || ""}
                              name="remarks"
                              placeholder="Remarks"
                            // errorMessage="Remarks cannot be Empty"
                            // validate={{
                            //   required: { value: selectedUser && selectedUser.remarks ? true : false },
                            // }}
                            />
                          </Col>
                        </Row>

                        <Row style={{ dsiplay: "flex" }} >

                       

                          <Col lg={6}>
                            <Col   >
                              {/* <FormGroup check> */}
                              <Label check>
                                <Input
                                  style={{ border: "1px solid #ced4da" }}
                                  type="checkbox"
                                  value={markUndertime}
                                  checked={markUndertime}
                                  // defaultChecked={markUndertime[selectedUser._id] || false}
                                  onChange={(e) => setMarkUndertime(e.target.checked)}
                                />
                                {" "}Mark Undertime
                              </Label>
                              {/* </FormGroup> */}
                            </Col>

                            <Col >
                              <Col   >

                                <AvField
                                  style={{ marginTop: '8px' }}
                                  className="form-control"
                                  type="text"

                                  disabled={markUndertime ? false : true}
                                  value={selectedUser && selectedUser.undertime || "0"}
                                  name="undertime"
                                  placeholder="Undertime in minutes"
                                // errorMessage="Remarks cannot be Empty"
                                // validate={{
                                //   required: { value: selectedUser && selectedUser.remarks ? true : false },
                                // }}
                                />
                              </Col>
                            </Col>
                          </Col>
                        </Row>

                        <Row style={{ display: "flex", justifyContent: "center", marginTop: "30px" }} >
                          <Col md={6} style={{ display: "flex", justifyContent: "center" }}  >

                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                          </Col>
                        </Row>

                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(EmployeeViewAttendance)))
