import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import { postSubmitForm } from "../../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import logo from "../../../assets/images/Logo.png"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../../assets/images/Background.png"
// import Flatpickr from "react-flatpickr"
import Linechart from "../../Chart/linechart"
import GamePie from "../../Chart/gamePie"
import moment from "moment"
import { Pagination } from "../../../components/Common/Pagination"
import { TableLoader } from "./ItemSales"

const GameSales = props => {

    const { SearchBar } = Search
    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [branches, setBranches] = useState([]);
    const [todayDate, setTodayDate] = useState(new Date().toISOString().split('T')[0]);
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalPage, setTotalPage] = useState(1)
    const [allGameSales, setAllgameSales] = useState([])
    const [fromdate, setFromdate] = useState(new Date())
  const [todate, setTodate] = useState(new Date())
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(100);
    const [piedata, setPiedata] = useState()
    const [chartData, setChartData] = useState("")
    const refContainer = useRef(null)
    const branchesRef = useRef([]);

    const code = localStorage.getItem("code")
    const handleDownload = async () => {
        const fileName = "Games Sales Report";
        const exportType = "xls";

        let data_to_export = allGameSales;

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    Branch: v.branch_details?.name,
                    Game: v.game_details?.game?.name,
                    Game_Table: v.game_details?.table_number,
                    Duration: v.game_details?.game?.duration,
                    SubTotal: v.game_details?.game?.sub_total,
                    Customer: v.customer_details?.name,
                    Mobile: v.customer_details?.mobile,
                    Emp_Name: v.emp_details?.name,
                    Date: moment(v.createdAt).format("YYYY-MM-DD")
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            const imageHeight = "105px";
            const imageWidth = "110px"; 
            const borderradius = "50%";
            const background = "#000000"; 
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            #sale{
               width: 120px;
               margin: auto;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 135px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Games Sales Report</h2>
       </div>
        <h4 id="sale"> Total Sale : ${totalAmount || 0}</h4>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function() {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };

    useEffect(() => {
        loadAllBranches();
        loadGameSalesReportOnFirst()
    }, [])
    
    const loadGameSalesReport = async (e) => {
        setAllgameSales([])
        
        e&&setPageNumber(1)
        let dateFrom = moment(fromdate);
        let dateTo = moment(todate);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
        const payloadSales = {
            date_from: moment(fromdate).format("YYYY-MM-DD"),
            date_to: moment(todate).format("YYYY-MM-DD"),
            branch_id: branchesRef.current[0]?._id ,
            // branch_id: selectedBranch.value,
            type:"games",
            limit: 100,
            page: pageNumber
        }

        try {
            setLoading(true);
        let url = process.env.REACT_APP_BASEURL + "reports/sales"
        const response = await postSubmitForm(url, payloadSales)
        if (response && response.status === 1) {
            setAllgameSales(response.data)
            setTotalPage(response.totalPages)
            setTotalAmount(response.totalSaleAmount);
            setLoading(false);
        } else {
            showNotification(response.message, "Error")
            setLoading(false);
        }
    } catch (error) {
        setLoading(false);   
    }
        const payload = {
            date_from: moment(fromdate).format("YYYY-MM-DD"),
            date_to: moment(todate).format("YYYY-MM-DD"),
        };
        let PieUrl = process.env.REACT_APP_BASEURL + "reports/games_sale_trend";
        let PieResponse = await postSubmitForm(PieUrl, payload);
            if (PieResponse && PieResponse.status === 1) {
                setPiedata(PieResponse.data);
                setChartData(PieResponse.charts)
        } else {
          showNotification(PieResponse.message, "Error");
        }
    }

    const loadGameSalesReportOnFirst = async () => {
      
        let branchesResponse = await postSubmitForm(process.env.REACT_APP_BASEURL + "branches/getall", "");
        if (branchesResponse && branchesResponse.status === 1) {
          let branchList = branchesResponse.data
          branchList.filter((brn) => brn.name === "Rendezvous");
          setBranches(branchList)
          branchesRef.current = branchList
        } else {
          console.log("Error fetching branches");
        }
        setTodayDate(new Date().toISOString().split('T')[0])
        const payloadSales = {
            date_from: todayDate || v.date_from,
      date_to: todayDate || v.date_to,
            branch_id: branchesRef.current[0]?._id ,
            type:"games",
            limit: 100,
            page: pageNumber
        };
    
        try {
            setLoading(true);
        let salesUrl = process.env.REACT_APP_BASEURL + "reports/sales";
        let salesResponse = await postSubmitForm(salesUrl, payloadSales);      
        if (salesResponse && salesResponse.status === 1) {
        //   refContainer.current.reset();
          setAllgameSales(salesResponse.data);
          setTotalAmount(salesResponse.totalSaleAmount);
          setTotalPage(salesResponse.totalPages)
          setLoading(false);
        } else {
          showNotification(salesResponse.message, "Error");
          setLoading(false);
        }
              
    } catch (error) {
           setLoading(false); 
    }
        const payload = {
            date_from: todayDate,
            date_to: todayDate,
        };
        let PieUrl = process.env.REACT_APP_BASEURL + "reports/games_sale_trend";
        let PieResponse = await postSubmitForm(PieUrl, payload);
    
        if (PieResponse && PieResponse.status === 1) {
            // let combinedObject = PieResponse.data.reduce((accumulator, currentObject) => {
            //     return { ...accumulator, ...currentObject };
            // }, {});
          setPiedata(PieResponse.data);
          setChartData(PieResponse.charts)
        //   console.log(combinedObject,"PR");
        } else {
          showNotification(PieResponse.message, "Error");
        }
    };

    const handleOpenInvoice = (cell,row ) => {
        if (row.booking_id) {
          return (<>
           <i
            style={{ color: "blue", cursor: "pointer", justifyContent: "center", display: 'flex' }}>
            <a href={"/invoice_details?booking_id=" + row.booking_id } target="_blank" rel="noreferrer">
              {row.invoice_no}
            </a>
            </i>
            </>
          )
        }
        return <span style={{ color: "gray" }}>Not available</span>
      }

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("#"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
        },
        {
            text: props.t("Invoice No"),
            dataField: "invoice_no",
            formatter: handleOpenInvoice,
            sort:true,
        },         
        {
            text: props.t("Game Name"),
            dataField: "game_details.game.name",
            sort:true,
        },         
        {
            text: props.t("Table Name"),
            dataField: "game_details.table_number",
            sort:false,
        },         
        {
            text: props.t("Duration"),
            dataField: "game_details.game.duration",
            sort: true,
        },
        {
            text: props.t("Paid Amount"),
            dataField: "final_amount",
            sort: true,
        },
        {
            text: props.t("Customer Name"),
            dataField: "customer_details.name",
            sort: true,
        },
        {
            text: props.t("Customer Mobile"),
            dataField: "customer_details.mobile",
            sort: true,
        },
       
        {
            text: props.t("Employee Name"),
            dataField: "emp_details.name",
            sort:false,
        },
        {
            text: props.t("Date"),
            dataField: "createdAt",
            sort:false,
            formatter:(cell,row)=>{
                return moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
            },
            headerStyle: (colum, colIndex) => {
                return { width: "12%" }
            },
        },

    ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    const loadAllBranches = async () => {
        let url = process.env.REACT_APP_BASEURL + "branches/getall"
        let response = await postSubmitForm(url, "")
        if (response && response.status === 1) {
          let branchList = response.data.map(branch => ({label:branch.name,value: branch.value}));
          branchList.filter(brn=>brn.name==="Rendezvous")
          setBranches(branchList)
        } else {
          console.log("Error");
        }
    }

    const isInitialRender = useRef(true);
    useEffect(() => {
        if (isInitialRender.current) {
          isInitialRender.current = false;
          return;
        }
        loadGameSalesReport(null);
      }, [pageNumber]);
    

    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Games Sales Report" breadcrumbItem="Games Sales Report" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "black",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={loadGameSalesReport} >
                                        <Row >
                                            <Col lg={3}>
                                                <label style={{color:"white"}} className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={fromdate}
                                                    options={{

                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        const date = new Date(dateString);
                                                        setFromdate(date)
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)
                                                    }}
                                                    name="date_from"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col lg={3} >

                                                <label style={{color:"white"}} className="form-label">
                                                    To Date
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        const date = new Date(dateString);
                                                        setTodate(date)
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                                    }}

                                                    // disabled={disabledStatus}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}
                                                    name="date_to"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />


                                            </Col>
                                              {/* <Col lg={4}>

                              <Label style={{color:"white"}}>Branch</Label>
                              <Select
                                placeholder="Select Branch"
                                onChange={(selected) => { (setSelectedBranch(selected))}}
                                value={selectedBranch}
                                options={branches}
                                isClearable={true}
                                isSearchable
                                required
                              />
                                           </Col> */}

                            

                                            <Col lg={2} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "128px",
                                                    marginTop: "1rem",
                                                    // margin: "auto",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }} type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <CardTitle>{props.t(" Sales Chart")}</CardTitle>
                    </Col>
                  </Row>

                  <div id="line-chart" className="e-chart">
                    <Linechart
                      line_data={chartData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
  
                    <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Games Chart")}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <GamePie data={piedata}
                      name={props.t("Games Pie")}/>
                  </div>
                </CardBody>
              </Card>
            </Col>
    
          </Row>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>
                                            <Col lg={6}>
                                                {props.t("Game Sales Report")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    disabled={allGameSales.length<=0}
                                                    onClick={() => { printDiv("printDiv") }}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={allGameSales.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    {/* <CardSubtitle className="mb-3">
                                        {props.t("View All")}
                                    </CardSubtitle> */}
                              <div id="printDiv">
                              <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={allGameSales && allGameSales }
                                        search

                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row className="hide">
                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst?.searchProps}  />
                                                        </Col>
                                                        <Col lg={4}>
                                                          <Label className="fs-5">  {props.t("Total Game Sale :")}&nbsp; <strong>{totalAmount.toFixed(2)}</strong> </Label>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    noDataIndication={loading?<TableLoader/>:props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    // pagination={paginationFactory(options)}
                                                />
                                    
                        <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} totalPage={totalPage}/>

                                            </div>
                                        )}
                                    </ToolkitProvider>
                              </div>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(GameSales)))
