import React, { useEffect, useState, useRef } from "react"
//i18n
import { withTranslation } from "react-i18next"
import Flatpickr from "react-flatpickr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Container,
} from "reactstrap"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { AvForm, AvField } from "availity-reactstrap-validation"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import exportFromJSON from "export-from-json"
import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import { postSubmitForm } from "../../helpers/backend_helper"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import moment from "moment"

const ViewAttendance = props => {


  const [allAttendance, setAllAttendance] = useState([])

const today = moment().format("YYYY-MM-DD")

  useEffect(() => {
    let object = {
      date_from:today,
      date_to:today
    }
    loadAllAttendance(object)
  }, [])

  const handleDownload = async () => {
    const fileName = "Attendance";
    const exportType = "xls";

    let data_to_export = allAttendance;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Log_Id: v.log_id,
          Attendance_date: moment(v.log_datetime).format("YYYY-MM-DD HH:mm:ss"),
          Employee: v.employee_name,


        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

const handleSubmit = (e,v) =>{
  let object = {
    date_from:v.date_from ,
    date_to:v.date_to
  }
  loadAllAttendance(object)
}

  const loadAllAttendance = async (object) => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "attendancelogs/get_all"
    const response = await postSubmitForm(url, object)
    if (response && response.status === 1) {
      preloader(false)
      setAllAttendance(response.data)
    } else {
      preloader(false)
      console.log("Error");
      // showNotification(response.message, "Error")
    }
  }


  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "0.1%" }
      },
    },
    {
      text: props.t("Employee ID"),
      //formatter: employeeFormatter,
      dataField: "log_id",
      filter: textFilter({
        caseSensitive: true,
        style: {
          height: "20px", marginLeft: "5px"
        }
      }),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Employee Name"),
      dataField: "employee_name",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px", marginLeft: "10px"
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Date Time"),
      //formatter: employeeFormatter,
      dataField: "log_datetime",
      formatter: (cell, row) => {
        if (row.log_datetime) {
          return moment(cell).format('DD-MM-YYYY HH:mm:ss')
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },




  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            alternateTitle={props.t("Attendance Logs")}
            title={props.t("Attendance")}
            breadcrumbItem1={props.t("Attendance Logs")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={(e,v) => handleSubmit(e,v)}>
                    <Row >

                      <Col lg={2} md={2}>


                        <AvField
                          label={props.t("From Date")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={today}
                          options={{
                            maxDate: "today",
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}
                          name="date_from"
                          placeholder="Select From Date"
                          errorMessage="Select From Date"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col lg={2} md={2}>


                        <AvField
                          label={props.t("To Date")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={today}
                          options={{
                            maxDate: "today",
                            // minDate: moment(fromDate).format("YYYY-MM-DD"),
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener('mousedown', (e) => {
                                e.preventDefault(); // Prevent default click behavior
                              });
                            },
                          }}
                          name="date_to"
                          placeholder="Select To Date"
                          errorMessage="Select To Date"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                        <label style={{ height: "20.5px" }} className="form-label">

                        </label>
                        <div style={{ display: "flex" }}>

                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "-0.2rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Show")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>

                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("Attendance Logs")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                    {props.t("View all your existing suppliers here")}
                  </CardSubtitle> */}

                  <ToolkitProvider
                    keyField="_id"
                    data={allAttendance && allAttendance || []}
                    columns={columns || []}
                    search
                  >
                    {propst => (
                      <div>
                        {/* <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                              placeholder="Search by Name or Log Id"
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm> */}
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          filter={filterFactory()}
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(connect(null, {})(withTranslation()(ViewAttendance)))
