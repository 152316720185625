import PropTypes from "prop-types"
import React, { useState, useEffect, useRef, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../assets/icon.css"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { loadRosterOwner } from "../../store/owner-roster/action"
//i18n
import { withTranslation } from "react-i18next"
// Pages Components
import WelcomeComp from "./WelcomeComp"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import BackgroundImage from "../../assets/images/Background.png"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import styles from "./Badge.module.css";
import DashboardTableStatus from "../Bookings/DashboardTableStatus";


const Dashboard = props => {
  //meta title
  const { SearchBar } = Search
  const [periodType, setPeriodType] = useState("yearly")
  const refContainer = useRef(null)
  const [allemployees, setAllEmployees] = useState([])
  const [searchTerm, setSearchTerm] = useState();
  const [roster, setRoster] = useState([])
  const [rosterLoader, setRosterLoader] = useState(false)
  const [filteredTables, setFilteredTables] = useState(roster);

  useEffect(() => {
    setFilteredTables(roster);
  }, [roster]);

  useEffect(() => {
    const filtered = roster && roster.filter(item => {
      if (item && searchTerm) {
        const name = item.name ? item.name.toString().toLowerCase() : "";
        const code = item.code ? item.code.toString().toLowerCase() : "";
        const search = searchTerm.toLowerCase();
        return name.includes(search) || code.includes(search);
      }
      return false;
    });

    setFilteredTables(filtered);
  }, [searchTerm]);

  useEffect(() => {
    loadDashboardData()
    loadAllEmployees();
    loadRoster()
  }, [])

  const [dashboardData, setDashboardData] = useState([])
  const [loader, setLoader] = useState(false)
  const loadDashboardData = async (e, v) => {
    try {
      setLoader(true)
      let payload = {
        from_date: v=== undefined ? new Date() : v.from_date,
        to_date: v === undefined ? new Date() : v.to_date
      }
      let url = process.env.REACT_APP_BASEURL + "dashboard/get_dashboard_data"
      let response = await postSubmitForm(url, payload)
      if (response.status === 1) {
        setLoader(false)
        setDashboardData(response.data)
      } else {
        setLoader(false)
        showNotification(error.message, "Error")
      }
    } catch (error) {
      setLoader(false)
      console.log(error.message);
      showNotification(error, "Error")
    }
  }

  const employeeCount = useMemo(() =>  dashboardData?.employeeCount || 0,[dashboardData]);
  const bookingCount = useMemo(() => dashboardData?.bookingCount || 0, [dashboardData]);
  const customerCount = useMemo(() => dashboardData?.customerCount || 0, [dashboardData]);
  const holidayCount = useMemo(() => dashboardData?.holidayCount || 0, [dashboardData]);
  const loanRequestCount = useMemo(() => dashboardData?.loanRequestCount || 0, [dashboardData]);
  const activeSupplierCount = useMemo(() => dashboardData?.activeSupplierCount || 0, [dashboardData]);
  const bookingStatusCounts = useMemo(() => dashboardData?.bookingStatusCounts || [], [dashboardData]);
  const purchaseOrderStatusCounts = useMemo(() => dashboardData?.purchaseOrderStatusCounts || [], [dashboardData]);
  const leaveApplicationStatusCounts = useMemo(() => dashboardData?.leaveApplicationStatusCounts || [], [dashboardData]);


  const [employeeDocsLoader, setEmployeeDocsLoader] = useState(false)
  const loadAllEmployees = async () => {
    setEmployeeDocsLoader(true)
    let url = process.env.REACT_APP_BASEURL + "employees/getall";
    const response = await postSubmitForm(url, {});

    if (response && response.status === 1) {
      const allEmployees = response.data;
      const today = new Date();
      const differenceInDays = (date1, date2) => {
        const diffInTime = date2.getTime() - date1.getTime();
        return Math.round(diffInTime / (1000 * 3600 * 24));
      };

      const employeesExpiringSoon = allEmployees.filter(employee => {
        const qatarIdExpiry = employee.qatarid_expirydate ? new Date(employee.qatarid_expirydate) : null;
        const passportIdExpiry = employee.passport_expirydate ? new Date(employee.passport_expirydate) : null;
        const health = employee.healthcardid_expirydate ? new Date(employee.healthcardid_expirydate) : null;
        const driving = employee.driving_license_id_expirydate ? new Date(employee.driving_license_id_expirydate) : null;
        const insurance = employee.insurance_policy_expirydate ? new Date(employee.insurance_policy_expirydate) : null;
        const medicalFitIdExpiry = employee.medicalfitid_expirydate ? new Date(employee.medicalfitid_expirydate) : null;

        return employee?.is_active === true && (
          (qatarIdExpiry && differenceInDays(today, qatarIdExpiry) <= 60) ||
          (passportIdExpiry && differenceInDays(today, passportIdExpiry) <= 60) ||
          (health && differenceInDays(today, health) <= 60) ||
          (driving && differenceInDays(today, driving) <= 60) ||
          (insurance && differenceInDays(today, insurance) <= 60) ||
          (medicalFitIdExpiry && differenceInDays(today, medicalFitIdExpiry) <= 60)
        );
      });

      setEmployeeDocsLoader(false)

      setAllEmployees(employeesExpiringSoon);
    } else {
      setEmployeeDocsLoader(false)
      showNotification(response.message, "Error");
    }
  };

  const loadRoster = async () => {
    setRosterLoader(true)
    let url = process.env.REACT_APP_BASEURL + "rosters/getall"
    let response = await postSubmitForm(url, {})
    if (response.status === 1) {
      setRoster(response.data)
      setRosterLoader(false)
      // console.log(response.data)
    } else {
      setRosterLoader(false)
      // showNotification(response.message, "Error")
    }
  }

  const reports = [
    {
      title: "Total Employees",
      iconClass: "bx-copy-alt",
      description: `${employeeCount}`,
    },
    {
      title: "Total Bookings",
      iconClass: "bx-archive-in",
      description: `${bookingCount}`,
    },
    {
      title: "Loan Requests",
      iconClass: "bx-purchase-tag-alt",
      description: `${loanRequestCount}`,
    },
  ]

  const reports_2 = [
    {
      title: "Total Suppliers",
      iconClass: "bx-copy-alt",
      description: `${activeSupplierCount}`,
    },
    { title: "Total Customers", iconClass: "bx-archive-in", description: `${customerCount}` },
    {
      title: "Total Holidays",
      iconClass: "bx-purchase-tag-alt",
      description: `${holidayCount}`,
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
  }


  function calculateDaysLeftForExpiry(key) {
    let inputDate = key;
    const targetDate = new Date(inputDate);
    const currentDate = new Date();
    const differenceInMs = targetDate - currentDate;
    const daysLeft = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    if (daysLeft < 0) {
      return (<Badge >Expired</Badge>)
    } else if (daysLeft <= 60) {
      return (<Badge className={`${daysLeft <= 10 ? `${styles.glowBadge} badge-soft-danger` : `badge-soft-${daysLeft > 30 ? "success" : "warning"}`}`}>{`${daysLeft} ${"days left"}`}</Badge>);
    }
  }

  const employeeColumns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },

    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      headerClasses: "custom-header-class"
    },
    {
      dataField: "designation",
      text: props.t("Designation"),
      // sort: true,
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "passport_expirydate",
      text: props.t("Passport Expiry"),
      formatter: (cell, row) => {
        if (row.passport_expirydate !== "" && row.passport_expirydate !== null) {
          return calculateDaysLeftForExpiry(row.passport_expirydate);
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "qatarid_expirydate",
      text: props.t("Qatar ID Expiry"),
      formatter: (cell, row) => {
        if (row.qatarid_expirydate !== "" && row.qatarid_expirydate !== null) {
          return calculateDaysLeftForExpiry(row.qatarid_expirydate);
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "driving_license_id_expirydate",
      text: props.t("Driving License Expiry"),
      formatter: (cell, row) => {
        if (row.driving_license_id_expirydate !== "" && row.driving_license_id_expirydate !== null) {
          return calculateDaysLeftForExpiry(row.driving_license_id_expirydate);
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "healthcardid_expirydate",
      text: props.t("Health Expiry"),
      formatter: (cell, row) => {
        if (row.healthcardid_expirydate !== "" && row.healthcardid_expirydate !== null) {
          return calculateDaysLeftForExpiry(row.healthcardid_expirydate);
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "insurance_policy_expirydate",
      text: props.t("Insurance Expiry"),
      formatter: (cell, row) => {
        if (row.insurance_policy_expirydate !== "" && row.insurance_policy_expirydate !== null) {
          return calculateDaysLeftForExpiry(row.insurance_policy_expirydate);
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "medicalfitid_expirydate",
      text: props.t("Medical Expiry"),
      formatter: (cell, row) => {
        if (row.medicalfitid_expirydate !== "" && row.medicalfitid_expirydate !== null) {
          return calculateDaysLeftForExpiry(row.medicalfitid_expirydate);
        } else {
          return "";
        }
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ]


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col lg={4}>
              <WelcomeComp />
            </Col>

            <Col lg={8}>
              <Row>
                <Col lg={12}>
                  <Card
                    style={{ minHeight: "80px" }}
                  // className="mini-stats-wid p-1"
                  >
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <AvForm
                                onValidSubmit={(e, v) => {
                                  loadDashboardData(e, v)
                                }}
                                ref={refContainer}
                              >
                                {/* <Row style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }} > */}

                                <Row style={{ marginTop: "-15px" }}>
                                  <Col lg={4}>
                                    <AvField
                                      className="form-control"
                                      label="From Date"
                                      type="select"
                                      tag={Flatpickr}
                                      options={{
                                        onDayCreate: (
                                          dObj,
                                          dStr,
                                          fp,
                                          dayElem
                                        ) => {
                                          dayElem.addEventListener(
                                            "mousedown",
                                            e => {
                                              e.preventDefault() // Prevent default click behavior
                                            }
                                          )
                                        },
                                      }}
                                      name="from_date"
                                      placeholder="Select From Date"
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t("Select From Date")}
                                    // onChange={(e) => {setFromDate(e)}}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <AvField
                                      className="form-control"
                                      label="To Date"
                                      type="select"
                                      tag={Flatpickr}
                                      options={{
                                        onDayCreate: (
                                          dObj,
                                          dStr,
                                          fp,
                                          dayElem
                                        ) => {
                                          dayElem.addEventListener(
                                            "mousedown",
                                            e => {
                                              e.preventDefault() // Prevent default click behavior
                                            }
                                          )
                                        },
                                      }}
                                      name="to_date"
                                      placeholder="Select To Date"
                                      validate={{ required: { value: true } }}
                                      errorMessage={props.t("Select To Date")}
                                    // onChange={(e) => setToDate(e)}
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.49rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Show")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                                  </Col>
                                </Row>
                              </AvForm>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {reports.map((report, key) => (
                  <Col key={"col" + key}>
                    <Card
                      className="mini-stats-wid p-1"
                      style={{
                        minHeight: "70px",
                        maxHeight: "70px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      }}
                    >
                      <CardBody>
                        <div className="d-flex" style={{ marginTop: "-18px" }}>
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium text-truncate">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                            <span
                              className="avatar-title rounded-circle"
                              style={{ background: "F93A5C" }}
                            >
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row>
                {reports_2.map((report, key) => (
                  <Col key={"col" + key}>
                    <Card
                      className="mini-stats-wid p-1"
                      style={{
                        height: "70px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      }}
                    >
                      <CardBody>
                        <div className="d-flex" style={{ marginTop: "-18px" }}>
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium text-truncate">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row>
            {/* <Col lg={4}>
              <MonthlyEarning />
            </Col> */}

            <Col>
              <Row>
                <Col>
                  <Card
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    }}
                  >
                    <CardBody >
                      <Table>
                        <thead>
                          <tr>
                            <th
                              colSpan={2}
                              style={{ textAlign: "center" }}
                              className="text-truncate"
                            >
                              {props.t("Purchase Orders")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {loader === true ?
                            (
                              <div className="d-flex justify-content-center mt-5">
                                <Spinner style={{ height: '3rem', width: '3rem' }} />
                              </div>
                            ) : (
                              purchaseOrderStatusCounts.map((bkng, index) => {
                                return (
                                  <>
                                    <tr key={index + 1}>
                                      <td>{bkng._id}</td>
                                      <td>{bkng.count}</td>
                                    </tr>
                                  </>
                                )
                              }))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <Card>
                    <CardBody
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th
                              colSpan={2}
                              style={{ textAlign: "center" }}
                              className="text-truncate"
                            >
                              {props.t("Leave Applications")}
                            </th>
                          </tr>
                        </thead>

                        <tbody>

                          {loader === true ? (
                            <div className="d-flex justify-content-center mt-5">
                              <Spinner style={{ height: '3rem', width: '3rem' }} />
                            </div>
                          ) : (
                            leaveApplicationStatusCounts?.map((bkng, index) => {
                              return (
                                <>
                                  <tr key={index + 1}>
                                    <td>{bkng._id}</td>
                                    <td>{bkng.count}</td>
                                  </tr>
                                </>
                              )
                            })
                          )
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>

                <Col>
                  <Card
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    }}
                  >
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th
                              colSpan={2}
                              style={{ textAlign: "center" }}
                              className="text-truncate"
                            >
                              {props.t("Game Bookings")}
                            </th>
                          </tr>
                        </thead>
                        {/* {bookingStatusCount?.length < 0 ? (<Spinner color="blue"> Loading... </Spinner>) : ( */}
                        <tbody>
                          {loader === true ? (
                            <div className="d-flex justify-content-center mt-5">
                              <Spinner style={{ height: '3rem', width: '3rem' }} />
                            </div>
                          ) : (
                            <>
                              {bookingStatusCounts &&
                                bookingStatusCounts.map((bkng, index) => {
                                  return (
                                    <>
                                      <tr key={index + 1}>
                                        <td>{bkng._id}</td>
                                        <td>{bkng.count}</td>
                                      </tr>
                                    </>
                                  )
                                })}
                            </>
                          )
                          }


                        </tbody>
                        {/* )} */}

                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <DashboardTableStatus />
            </Col>
          </Row>



          <Row>

            <Col >
              <Card>
                <CardBody>
                  <CardTitle
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Employee Document Expiry ( in next 60 days )
                    <Link to="/view_employee">
                      <Button
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "14px",
                          fontWeight: "500",
                          // width: "100px",
                          marginTop: "-0.09rem",
                          marginBottom: "0.5rem",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("View Employee Details")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}

                        ></div>


                      </Button>

                    </Link>
                  </CardTitle>
                  {employeeDocsLoader === true ?
                    (<div className="d-flex justify-content-center">
                      <Spinner style={{ height: '3rem', width: '3rem' }} />
                    </div>
                    ) : (
                      <>
                        <ToolkitProvider
                          keyField="_id"
                          data={allemployees && allemployees}
                          columns={employeeColumns}
                          search
                        >
                          {propst => (
                            <div>
                              <Row>
                                <Col
                                  lg={4}
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <SearchBar
                                    {...propst.searchProps}
                                    style={{ width: "auto" }}
                                  />
                                </Col>
                              </Row>

                              <BootstrapTable
                                bootstrap4
                                {...propst.baseProps}
                                noDataIndication={props.t("No data to display.")}
                                striped
                                hover
                                filter={filterFactory()}
                                condensed
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </>
                    )}

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                <CardBody>
                  <CardTitle
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ display: "flex" }}>
                      {props.t("Roster")}
                      <Input style={{ width: "200px", marginLeft: "20px", marginTop: "0px" }}
                        type="search"
                        placeholder="Search by name or code"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </span>
                    <Link to="/roster">
                      <Button
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "14px",
                          fontWeight: "500",
                          width: "100px",
                          marginTop: "-0.09rem",
                          marginBottom: "0.5rem",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Edit Roster")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}

                        ></div>


                      </Button>


                    </Link>


                    {/* <Button onClick={hitApi}>hello</Button>
                    <Button onClick={openPDFsInNewTabs}>open pdf</Button> */}
                  </CardTitle>

                  <div style={{ overflowX: "auto" }}>
                    {rosterLoader === true ?
                      (<div className="d-flex justify-content-center">
                        <Spinner style={{ height: '3rem', width: '3rem' }} />
                      </div>
                      ) : (
                        <>
                          <Table className="table table-striped table-bordered mb-0">
                            <thead>
                              <tr style={{ height: "50px", width: "50px" }}>
                                <th>#</th>
                                <th>Employee Code</th>
                                <th>Name</th>
                                <th>Sat</th>
                                <th>Sun</th>
                                <th>Mon</th>
                                <th>Tue</th>
                                <th>Wed</th>
                                <th>Thu</th>
                                <th>Fri</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredTables?.map((emp, index) => (
                                <tr
                                  key={index}
                                  style={{ height: "80px", width: "50px" }}
                                >
                                  <td>{index + 1}</td>
                                  <td>{emp.code}</td>
                                  <td>{emp.name}</td>
                                  <td>
                                    {(emp && emp.saturday && emp.saturday.name) ||
                                      "Off"}
                                  </td>
                                  <td>
                                    {(emp && emp.sunday && emp.sunday.name) || "Off"}
                                  </td>
                                  <td>
                                    {(emp && emp.monday && emp.monday.name) || "Off"}
                                  </td>
                                  <td>
                                    {(emp && emp.tuesday && emp.tuesday.name) ||
                                      "Off"}
                                  </td>
                                  <td>
                                    {(emp && emp.wednesday && emp.wednesday.name) ||
                                      "Off"}
                                  </td>
                                  <td>
                                    {(emp && emp.thursday && emp.thursday.name) ||
                                      "Off"}
                                  </td>
                                  <td>
                                    {(emp && emp.friday && emp.friday.name) || "Off"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                          </Table></>)}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
