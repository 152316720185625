import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import withRouter from './Common/withRouter'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import showNotification, { showWarning } from '../helpers/show_notificaton'
import { postSubmitForm } from '../helpers/backend_helper'
import moment from 'moment'

const TransferTable = (props) => {
  const { gameData } = props

  const handleValidSubmit = async () => {
    if (!seletedTable) {
      showNotification("Please select table first", "Warning")
      return
    }
    const { _id } = gameData.details[0]
    let payload = {
      currentTableId:gameData._id ,
      newTableId: seletedTable && seletedTable._id,
      booking_id: _id,
    };
    let url = process.env.REACT_APP_BASEURL + "bookings/transfer-table"
    postSubmitForm(url, payload)
      .then(response => {
        if (response && response.status === 1 ) {
          showNotification(response.message, "Success")
          props.onClose();
          props.reFresh()
        } else {
          showNotification(response.message, "Error")
        }
      })
      .catch(err => {
        showNotification(err, "Error")
      })
  }


  const findTableStatus = async (table) => {

    const { table_end_time, _id } = gameData.details[0]
    let currTime = new Date()
    let payload = {
      start_time: currTime,
      end_time: table_end_time,
      game_id: gameData.game.game_id,
    };

    let url = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(url, payload)
    let data = res.data
    if (res && res.status === 1 && data.some(item => item.table_number == table.value)) {
      setSeletedTable(table)
    } else {
      // showWarning("Table is busy from " + " " +
      //   moment(payload?.start_time).format("HH:mm") + " " +
      //   "to" + " " +
      //   moment(payload?.end_time).format("HH:mm"), "warning")
      showWarning("Table is busy till " + " " +
        moment(payload?.end_time).format("HH:mm"), "warning")
    }

  }

  useEffect(() => {
    loadGameTables()
  }, [])

  function showPhotoFormatter(row) {
    return (
      <>
        <img
          style={{ height: "30px", width: "30px" }}
          src={row.table_image_url + "?" + new Date() + "=" + new Date()}
          alt={row.table_number}
          className="avatar-md rounded-circle img-thumbnail me-2"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

  //Game Tables
  const [allGameTables, setAllGameTables] = useState([]);
  const [seletedTable, setSeletedTable] = useState("");
  const loadGameTables = async () => {
    let url = process.env.REACT_APP_BASEURL + "bookings/get_gametable_booking_details";
    let response = await postSubmitForm(url, {
      game_id: gameData && gameData.game.game_id
    });
    if (response.status === 1) {
      const formattedData = response.data.filter(table => table.is_active === true && table.is_occupied=== false &&table.table_number!==gameData.table_number);
      formattedData.map(data => {
        data.label = (
          <>
            {showPhotoFormatter(data)}
            {data.table_number}
          </>
        )
        data.value = data.table_number
        data._id = data._id
      })
      setAllGameTables(formattedData)
    } else {
      showNotification(response.message, "Error");
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col >
              <Label>{props.t("To")}</Label>
              <Select
                name="tables"
                label={props.t("Tables")}
                options={allGameTables}
                onChange={(ev) => setSeletedTable(ev)}
                value={seletedTable}
                // isClearable={true}
                isSearchable
                required
              />
            </Col>  <hr />
          </Row>
          <Row> <hr />

            <Button
              type="submit"
              onClick={handleValidSubmit}
              style={{
                cursor: "pointer",
                position: "relative",
                overflow: "hidden",
                backgroundColor: "#fdd005",
                border: "none",
                color: "black",
                fontSize: "15px",
                fontWeight: "500",
                width: "128px",
                marginTop: "1rem",
                margin: "auto",
                boxShadow:
                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                transition: "transform 0.3s ease",
              }}
              onMouseEnter={e => {
                e.target.style.transform = "scale(1.05)"
              }}
              onMouseLeave={e => {
                e.target.style.transform = "scale(1)"
              }}
            >
              {props.t("Transfer")}
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  background:
                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                  pointerEvents: "none",
                }}
              ></div>
            </Button>

          </Row>
        </CardBody>
      </Card>

    </>
  )
}

export default withRouter(connect(null, {})(withTranslation()(TransferTable)))