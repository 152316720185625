import { LOAD_SUPPLIER, LOAD_SUPPLIER_SUCCESS, SUBMIT_SUPPLIER, SUBMIT_SUPPLIER_SUCCESS, SUPPLIER_API_ERROR, UPDATE_SUPPLIER, UPDATE_SUPPLIER_SUCCESS } from "./actionTypes";
  
  export const submitSupplier = (supplier, history) => {
    return {
      type: SUBMIT_SUPPLIER,
      payload: { supplier, history },
    };
  };
  
  export const submitSupplierSuccess = (supplier) => {
    return {
      type: SUBMIT_SUPPLIER_SUCCESS,
      payload: supplier,
    };
  };

  export const updateSupplier = (supplier, history) => {
    return {
      type: UPDATE_SUPPLIER,
      payload: { supplier, history },
    };
  };
  
  export const updateSupplierSuccess = (supplier) => {
    return {
      type: UPDATE_SUPPLIER_SUCCESS,
      payload: supplier,
    };
  };
  
  export const loadSupplier = () => {
    return {
      type: LOAD_SUPPLIER,
      payload: {},
    };
  };
  
  export const loadSupplierSuccess = (sellers) => {
    return {
      type: LOAD_SUPPLIER_SUCCESS,
      payload: sellers,
    };
  };
  
  export const supplierApiError = (error) => {
    return {
      type: SUPPLIER_API_ERROR,
      payload: error,
    };
  };
  