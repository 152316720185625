import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input, ModalFooter } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter";
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import { AvField, AvForm } from "availity-reactstrap-validation";
import showNotification, { showWarning } from "../../helpers/show_notificaton";
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import BackgroundImage from "../../assets/images/Background.png";
import DefaultImage from "../../assets/images/Default.jpeg";
import preloader from "../../helpers/Loader"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"


const AddItems = props => {

  const editorRef = useRef()
  const { SearchBar } = Search

  //meta title
  document.title = "Add Item | Rendezvous - Game Parlour"
  const refContainer = useRef(null)
  const [values, setValues] = useState()
  const [confirmation, setConfirmation] = useState()
  const [section, setSection] = useState()
  const [update_section, setUpdate_section] = useState()
  const [is_expiry_date, setIs_expiry_date] = useState(false)
  const [is_House_Hold_item, setIs_House_Hold_Item] = useState(false)
  const [update_expiry, setUpdate_expiry] = useState()
  const [imageValidation, setImageValidation] = useState(true)
  const [popupModal, setPopupModal] = useState(false)

  //for formula 

  const [Formula, setFormula] = useState()
  const [isSubUnitEnabled, setIsSubUnitEnabled] = useState(false);
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [allItem, setAllItem] = useState()
  const [isSquareImg, setIsSquareImage] = useState(false)
  const [is_menu, setIs_menu] = useState(false)
  const [is_for_use, setFor_use] = useState(false)
  const [updateForuse, setUpdateForuse] = useState()
  const [updateMenuItem, setUpdateMenuItem] = useState()
  const [updateHouseHoldItem, setUpdateHouseHoldItem] = useState(false)
  const [all_Items, setAll_Items] = useState([])
  //load AlL Units
  const [all_Units, setAll_Units] = useState([]);
  const [unit, setUnit] = useState()
  const [sub_unit, setSub_unit] = useState()
  const [updateUnit, setUpdateUnit] = useState();
  //getall Category
  const [allCategory, setAllCategory] = useState([])
  const [update_category, setUpdate_category] = useState()
  const [sectionCSV, setSectionCSV] = useState("");

  useEffect(() => {
    loadAllCategories()
  }, [section])

  useEffect(() => {
    loadAllCategories()
  }, [update_section])

  const handleDownload = async () => {
    const fileName = "All Items";
    const exportType = "xls";

    // let data_to_export = all_Items;
    let data_to_export = sectionCSV !== ""
      ? all_Items.filter(item => item.section === sectionCSV)
      : all_Items

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          Unit: v.unit,
          category: v.category,
          Section: v.section,
          Sub_Unit: v.sub_unit,
          Formula: v.formula,
          Selling_Price: v.selling_price,
          Threshold: v.threshold,
          Menu_Item: v.is_menu_item ? "Yes" : "No",
          For_use: v.is_for_use ? "Yes" : "No",
          For_use: v.is_for_use ? "Yes" : "No",
          In_House: v.is_household_item ? "Yes" : "No"
        });
        
      })
      exportFromJSON({ data, fileName, exportType });
    }
  }


  const loadAllCategories = async () => {

    let url = process.env.REACT_APP_BASEURL + "item_category/get_all_item_category"
    // let url = "http://localhost:8080/item_category/get_all_item_category"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {

      const filteredData = response.data.filter(category => category.section === section && category.is_active !== false)
      const updateFilter = response.data.filter(category => category.section === update_section && category.is_active !== false)
      setUpdate_category(updateFilter)
      setAllCategory(filteredData)
      // console.log(section);
    } else {

      showNotification(response.message, "Error")
    }
  }
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 100,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: allItem && allItem.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  // const handleDocumentChange = (event) => {
  //   const file = event.target.files[0];
  //   set_document_file(file);
  // };

  useEffect(() => {
    loadAllItem();
    loadAllCategories();
    loadAllUnits();
  }, [unit])

  const loadAllUnits = async () => {

    let url = process.env.REACT_APP_BASEURL + "units/getall"
    // let url = "http://localhost:3002/units/getall";
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      const filter = response.data.filter(unit => unit.is_active !== false)
      setAll_Units(filter)
    } else {

      showNotification(response.message, "Error")
    }
  }

  //load All Items
  const loadAllItem = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "items/getall_item"
    // let url = "http://localhost:8080/items/getall_item";
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false)
      setAll_Items(response.data)
    } else {
      preloader(false)
      //showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            const isMenuItem = row.is_menu_item !== undefined ? row.is_menu_item : false;
            const isForUse = row.is_for_use !== undefined ? row.is_for_use : false;
            const isExpiryDate = row.is_expiry_date !== undefined ? row.is_expiry_date : false;
            const isHouseHoldItem = row.is_household_item !== undefined ? row.is_household_item : false;

            setEditModal(!editModal)
            setAllItem(row);
            setUpdateMenuItem(isMenuItem);
            setUpdateForuse(isForUse);
            setUpdate_expiry(isExpiryDate);
            setUpdateHouseHoldItem(isHouseHoldItem);

          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
        <i>
          <div className="form-switch ms-2" dir="ltr">
            <input
              type="checkbox"
              style={{ height: "15px", width: "30px" }}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>
      </span>
    )
  }

  // Insert
  const [document_file, set_document_file] = useState()
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "items/insert_item"
    //  let url = "http://localhost:3002/items/insert_item";
    try {
      if (isSquareImg === false) {
        showWarning("Pls upload a square image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc");
        return;
      } else if (imageValidation === false) {
        showWarning('File size is too large. Please choose a smaller file (max 500 KB).');
        return;
      } else {
        // console.log(isSquareImg,"is sqr image state")
        // setIsSquareImage(false)

        let formData = new FormData()
        // formData.set('branch_details',branch_details);
        formData.set("name", v.name)
        formData.set("section", v.section)
        formData.set("selling_price", v.selling_price)
        formData.set("category", v.category)
        formData.set("unit", v.unit)
        formData.set("sub_unit", v.sub_unit)
        formData.set("formula", v.formula)
        formData.set("threshold", v.threshold)
        formData.set("is_menu_item", is_menu)
        formData.set("is_for_use", is_for_use)
        formData.set("is_expiry_date", is_expiry_date)
        formData.set("is_household_item", is_House_Hold_item)
        formData.append("document_file", document_file)
        // console.log("image",document_file)
        // return
        postSubmitForm_withformdata(url, formData).then(response => {
          if (response.status === 1) {
            preloader(false)
            loadAllItem()
            setSub_unit(false)
            setUnit(false)
            setFormula(false)
            setIsSubUnitEnabled(false)
            setIs_House_Hold_Item(false)
            setFor_use(false)
            setIs_menu(false)
            set_document_file();
            setIs_expiry_date(false)
            refContainer.current.reset()
            showNotification(response.message, "Success")
          } else {
            showNotification(response.message, "Error")
            // console.log(formData);
          }
        })
      }
    } catch (error) {
      showNotification("Error", error)
      console.log(error);
    }


  }

  //Update
  const [document_file_update, setdocument_file_update] = useState()
  const handleValidUpdate = async (e, v) => {
    if (v.document_file !== "" && isSquareImg === false) {
      // console.log("helllo")
      showNotification(_, "Pls upload a square image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc")
      return;
    } else {
      try {
        setIsSquareImage(false)
        let formData = new FormData()
        formData.set("id", allItem._id)
        formData.set("name", v.name)
        formData.set("section", v.section)
        formData.set("category", v.category)
        formData.set("unit", v.unit)
        formData.set("selling_price", v.selling_price)
        formData.set("sub_unit", v.sub_unit)
        formData.set("formula", v.formula)
        formData.set("threshold", v.threshold)
        formData.set("is_menu_item", updateMenuItem)
        formData.set("is_for_use", updateForuse)
        formData.set("is_expiry_date", update_expiry)
        formData.set("is_household_item", updateHouseHoldItem)
        if (document_file === "") {
          formData.set("document_file", allItem.image_url);
        } else {
          formData.set("document_file", document_file_update);
        }

        let url = process.env.REACT_APP_BASEURL + "items/update_item"
        //   let url = "http://localhost:3002/items/update_item";
        let response = await postSubmitForm_withformdata(url, formData)
        if (response.status === 1) {
          loadAllItem()
          setEditModal(!editModal)
          showNotification(response.message, "Success")
        } else {
          showNotification(response.message, "Error")
        }
      } catch (error) {
        showNotification(error.message, "Error")
      }
    }
  }

  //Delete
  const handleValidDelete = async allItem => {
    let url = process.env.REACT_APP_BASEURL + "items/activate_deactivate_item"
    //  let url = "http://localhost:8080/items/delete_item";
    let response = await postSubmitForm(url, {
      id: allItem._id,
      is_active: allItem.is_active === true ? false : true
    })
    if (response.status === 1) {
      showNotification(response.message, "Success")
      loadAllItem()
    } else {
      showNotification(response.message, "Error")
    }
  }


  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || DefaultImage}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        ></img>
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }


  // function to get resolution of the image from input field
  const handleImageReso = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInBytes = 277 + (500 * 1024); // 500 KB

      if (file.size > maxSizeInBytes) {
        // File size exceeds the limit, display an alert and clear state
        showWarning('File size is too large. Please choose a smaller file (max 500 KB).');
        setImageValidation(false)

      } else {
        setImageValidation(true)
        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
            const resolution = {
              width: img.width,
              height: img.height
            };
            if (img.width === img.height) {
              // console.log("setting true");
              setIsSquareImage(true);
            } else {
              // Image resolution is not square, display an alert and clear state
              showWarning("Please upload a square Image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc");
              setIsSquareImage(false);
              set_document_file(null); // Clear the state by setting it to null
            }
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    } else {
      setIsSquareImage(true);
      setImageValidation(true)
    }
  };




  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Category"),
      dataField: "category",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      filter: selectFilter({
        options: [
          { value: "Accessories", label: "Accessories" },
          { value: "Cafe", label: "Cafe" },
          { value: "Kitchen", label: "Kitchen" },
        ],
        placeholder: "ALL",
        onFilter: (value) => {
          setSectionCSV(value)
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Unit"),
      dataField: "unit",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub-Unit"),
      dataField: "sub_unit",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Formula"),
      dataField: "formula",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Threshold"),
      dataField: "threshold",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Selling Price"),
      dataField: "selling_price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Menu Item"),
      dataField: "is_menu_item",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {row.is_menu_item ? "Yes" : "No"}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("For Use"),
      dataField: "is_for_use",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {row.is_for_use ? "Yes" : "No"}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("In House Item"),
      dataField: "is_household_item",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {row.is_household_item ? "Yes" : "No"}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Items" breadcrumbItem1="Add Items" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}

                >
                  <AvForm
                    onValidSubmit={(e, v) => {
                      if (v.sub_unit === "") {
                        setPopupModal(!popupModal)
                        setValues(v);
                      } else {
                        handleValidSubmit(e, v)
                      }
                    }
                    }
                    ref={refContainer}>
                    <Row>
                      <Col lg={6}>

                        <AvField
                          className="form-control"
                          label={props.t("Name *")}
                          type="text"
                          placeholder={props.t("Enter Name")}
                          name="name"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />

                      </Col>
                      <Col lg={6}>

                        <AvField
                          label={props.t("Section *")}
                          className="form-control"
                          type="select"
                          placeholder={props.t("Select Section")}
                          name="section"
                          onChange={e => { setSection(e.target.value) }}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Section cannot be empty")}
                        >
                          <option value="">{props.t("--Select Section--")}</option>
                          <option value="Accessories">{props.t("Accessories")}</option>
                          <option value="Cafe">{props.t("Cafe")}</option>
                          <option value="Kitchen">{props.t("Kitchen")}</option>
                        </AvField>

                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>


                        <AvField
                          label={props.t("Category *")}
                          className="form-control"
                          type="select"
                          placeholder={props.t("Select Category")}
                          name="category"
                          disabled={!section}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Category cannot be empty")}
                        >
                          <option value="">{props.t("--Select Category--")}</option>
                          {allCategory &&
                            allCategory.map(category => (
                              <option key={category._id} value={category.name}>
                                {category.name}
                              </option>
                            ))}
                        </AvField>

                      </Col>


                      <Col lg={6}>


                        <AvField
                          label={props.t("Unit *")}
                          className="form-control"
                          type="select"
                          placeholder={props.t("Enter Unit")}
                          name="unit"
                          onChange={(e) => {
                            setUnit(e.target.value);
                          }}
                          disabled={!section}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Unit cannot be empty")}
                        >
                          <option value="">{props.t("--Select Unit--")}</option>
                          {all_Units && all_Units.map((name) => {
                            return (
                              <option key={name._id}
                                value={name.unit}>{name.unit}</option>
                            )
                          })}
                        </AvField>

                      </Col>

                    </Row>

                    <Row>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Selling Price*")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Please Enter Selling Price")}
                          name="selling_price"
                          defaultValue="0"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Selling Price cannot be empty")}

                        />

                      </Col>

                      <Col lg={3}>

                        <FormGroup check>
                          <Label check>
                            <Input style={{ border: "1px solid black" }}
                              type="checkbox"
                              checked={isSubUnitEnabled}
                              onChange={(e) => setIsSubUnitEnabled(e.target.checked)}
                            />
                            {props.t("Sub Unit required")}
                          </Label>
                        </FormGroup>
                        <AvField
                          //  label={props.t("Sub Unit")}
                          className="form-control mt-2"
                          type="select"
                          // value={sub_unit}
                          onChange={e => { setSub_unit(e.target.value) }}
                          placeholder={props.t("Select Sub-unit")}
                          disabled={!isSubUnitEnabled}
                          name="sub_unit"
                          validate={{ required: { value: isSubUnitEnabled ? true : false } }}
                          errorMessage={props.t("Sub-Unit cannot be empty")}
                        >
                          <option value="">{props.t("--Select Sub-Unit--")}</option>
                          {all_Units.filter((obj) => obj.unit === unit).map((item, ind) => <option key={ind} value={item.sub_unit}>{item.sub_unit}</option>)}
                        </AvField>

                      </Col>

                      <Col lg={3}>

                        <FormGroup check >
                          <Label check style={{ marginLeft: "-15px" }} >
                            {props.t("Formula")}  {isSubUnitEnabled && (<span className="text-danger ms-3" > 1 {unit} = {Formula} {sub_unit} </span>)}
                          </Label>
                        </FormGroup>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          //  label={props.t("Formula")}
                          className="form-control mt-2"
                          type="number"
                          placeholder={props.t("Enter Formula")}
                          name="formula"
                          onChange={e => { setFormula(e.target.value) }}
                          disabled={!isSubUnitEnabled}
                          validate={{ required: { value: sub_unit ? true : false } }}
                          errorMessage={props.t("Formula cannot be empty")}
                        />

                      </Col>

                    </Row>

                    <Row>

                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Threshold *")}
                          className="form-control"
                          type="number"
                          name="threshold"
                          onChange={() => setIsSquareImage(true)}
                          placeholder={props.t("Enter Threshold")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Threshold cannot be empty")}
                        />
                      </Col>

                      <Col lg={3}>

                        <Label> {props.t("Upload Image *")} <span style={{ fontSize: "10px", color: "red" }}>{props.t("(max 500kb, square images)")}</span> </Label>
                        <AvField
                          // label={props.t("Upload Image")} 
                          className="btn btn-primary btn-sm"
                          name="document_file"
                          type="file"
                          onChange={(e) => { setIsSquareImage(false); handleImageReso(e); set_document_file(e.target.files[0]) }}
                          validate={{ required: true }}
                          errorMessage={props.t("Please Upload Item Image")}
                        >
                          {props.t("Upload File")}
                        </AvField>

                      </Col>

                      <Col lg={3}>

                        <Row style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                          <Col lg={5}>
                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                              <Label check style={{ fontSize: "11px" }}>
                                <Input style={{ border: "1px solid black" }}
                                  type="checkbox"
                                  checked={is_menu}
                                  onChange={(e) => setIs_menu(e.target.checked)}
                                />
                                {props.t("Menu Item")}
                              </Label>

                            </FormGroup>
                          </Col>
                          <Col lg={5}>
                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                              <Label check style={{ fontSize: "11px" }}>
                                <Input style={{ border: "1px solid black" }}
                                  type="checkbox"
                                  checked={is_for_use}
                                  onChange={(e) => setFor_use(e.target.checked)}
                                />
                                {props.t("For Use")}
                              </Label>

                            </FormGroup>
                          </Col>

                          <Col lg={5}>
                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                              <Label check style={{ fontSize: "11px" }}>
                                <Input style={{ border: "1px solid black" }}
                                  type="checkbox"
                                  checked={is_expiry_date}
                                  onChange={(e) => setIs_expiry_date(e.target.checked)}
                                />
                                {props.t("Expiry Date")}
                              </Label>

                            </FormGroup>
                          </Col>

                          <Col lg={5}>
                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                              <Label check style={{ fontSize: "11px" }}>
                                <Input style={{ border: "1px solid black" }}
                                  type="checkbox"
                                  checked={is_House_Hold_item}
                                  onChange={(e) => setIs_House_Hold_Item(e.target.checked)}
                                />
                                {props.t("In House Item")}
                              </Label>

                            </FormGroup>
                          </Col>

                        </Row>

                      </Col>


                    </Row>
                    <hr />

                    <Row>


                      <Button
                        type="submit"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "100px",
                          marginTop: "1.6rem",
                          margin: "auto",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Add Item")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>



                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Items")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          disabled={!all_Items}
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all existing Items here")}
                  </CardSubtitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={all_Items && all_Items}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable

                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>



                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Item - ")}
                      {allItem && allItem.name}
                    </ModalHeader>
                    <ModalBody>
                      {allItem && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate} >
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage={props.t("Name cannot be empty")}
                                          value={allItem.name}
                                        />
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="unit"
                                          label={props.t("Unit")}
                                          placeholder={props.t("Enter Unit")}
                                          type="select"
                                          errorMessage={props.t("Unit cannot be empty")}
                                          value={allItem.unit}
                                          onChange={(e) => {
                                            setUpdateUnit(e.target.value)
                                          }}
                                        >
                                          <option value="">{props.t("--Select Unit--")}</option>
                                          {all_Units && all_Units.map((name) => {
                                            return (
                                              <option key={name._id}
                                                value={name.unit}>{name.unit}</option>
                                            )
                                          })}
                                        </AvField>
                                      </Col>




                                    </Row>

                                    <Row>




                                      <Col lg={6}>
                                        <AvField
                                          name="sub_unit"
                                          label={props.t("Sub Unit")}
                                          placeholder={props.t("Enter Sub Unit")}
                                          type="select"
                                          errorMessage={props.t("Sub Unit cannot be empty")}
                                          value={allItem.sub_unit}
                                        >
                                          <option value="">{allItem.sub_unit}</option>
                                          {all_Units.filter((obj) => obj.unit === updateUnit)
                                            .map((item, ind) => {
                                              return (
                                                <option key={ind} value={item.sub_unit}>{item.sub_unit}</option>
                                              )
                                            }
                                            )}
                                        </AvField>

                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="formula"
                                          label={props.t("Formula")}
                                          placeholder={props.t("Enter Formula")}
                                          type="number"
                                          errorMessage={props.t("Formula cannot be empty")}
                                          value={allItem.formula}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6} >
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="threshold"
                                          label={props.t("Threshold")}
                                          placeholder={props.t("Enter Threshold")}
                                          type="number"
                                          errorMessage={props.t("Threshold cannot be empty")}
                                          value={allItem.threshold}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Change Image")}
                                          className="btn btn-primary btn-sm"
                                          name="document_file"
                                          type="file"
                                          //value={allItem.image_url}
                                          onChange={e => { setdocument_file_update(e.target.files[0]); handleImageReso(e) }}
                                        // validate={{
                                        //   required: { value: true },
                                        // }}
                                        // errorMessage={props.t("Please Upload Item Image")}
                                        >
                                          {props.t("Upload File")}
                                        </AvField>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>


                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          label={props.t("Selling Price")}
                                          className="form-control"
                                          type="number"
                                          placeholder={props.t("Please Enter Selling Price")}
                                          name="selling_price"
                                          value={allItem.selling_price}
                                        />

                                      </Col>
                                      <Col lg={6}>

                                        <Row style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "none" }}>
                                          <Col lg={5}>
                                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                                              <Label check >
                                                <Input
                                                  style={{ border: "1px solid black" }}
                                                  type="checkbox"
                                                  checked={updateMenuItem}
                                                  onChange={(e) => setUpdateMenuItem(e.target.checked)}
                                                />
                                                {props.t("Menu Item")}
                                              </Label>

                                            </FormGroup>
                                          </Col>
                                          <Col lg={3}>
                                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                                              <Label check >
                                                <Input
                                                  style={{ border: "1px solid black" }}
                                                  type="checkbox"
                                                  checked={updateForuse}
                                                  onChange={(e) => setUpdateForuse(e.target.checked)}
                                                />
                                                {props.t("For Use")}
                                              </Label>

                                            </FormGroup>
                                          </Col>

                                          <Col lg={5}>
                                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                                              <Label check >
                                                <Input
                                                  style={{ border: "1px solid black" }}
                                                  type="checkbox"
                                                  checked={update_expiry}
                                                  onChange={(e) => setUpdate_expiry(e.target.checked)}
                                                />
                                                {props.t("Expiry Date")}
                                              </Label>

                                            </FormGroup>
                                          </Col>
                                          <Col lg={5}>
                                            <FormGroup check className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                                              <Label check >
                                                <Input
                                                  style={{ border: "1px solid black" }}
                                                  type="checkbox"
                                                  checked={updateHouseHoldItem}
                                                  onChange={(e) => setUpdateHouseHoldItem(e.target.checked)}
                                                />
                                                {props.t("House Item")}
                                              </Label>

                                            </FormGroup>
                                          </Col>
                                        </Row>

                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color=""
                                              style={{ width: "auto" }}
                                              className="mr-1 btn btn-primary"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Approve Modal */}
          < Modal isOpen={popupModal} toggle={() => setPopupModal(!popupModal)} >
            <ModalHeader toggle={() => setPopupModal(!popupModal)}>Are you sure </ModalHeader>
            <ModalBody>
              <div>
                <label className="form-label text-danger fw-bold"> Please note that no sub-unit has been specified for this item.</label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setPopupModal(!popupModal)}>
                No
              </Button>
              <Button color="success" onClick={(e) => {
                handleValidSubmit(e, values);
                setPopupModal(!popupModal)
              }}>
                Yes
              </Button>
            </ModalFooter>
          </Modal >

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AddItems)))
