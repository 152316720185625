import { LOAD_ROSTER,LOAD_ROSTER_SUCCESS,LOAD_ROSTER_ERROR } from "./actionTypes";
  
  export const loadRoster = (employee_id) => {
    return {
      type: LOAD_ROSTER,
      payload: employee_id,
    };
  };
  
  export const loadRosterSuccess = (rosterData) => {
    console.log(rosterData," from actions")
    return {
      type: LOAD_ROSTER_SUCCESS,
      payload: rosterData,
    };
  };
  
  export const loadRosterError = (error) => {
    return {
      type: LOAD_ROSTER_ERROR,
      payload: error,
    };
  };
  