import React, { useRef, useEffect, useState } from "react"
import {
    Card, CardBody, Col, Row, Container, Input, Button, CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    Badge,
    Label,
    InputGroup,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import { withTranslation } from "react-i18next"
import axios from "axios";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import Select from "react-select";
import { postSubmitForm } from "../../helpers/backend_helper";
import showNotification, { showWarning } from "../../helpers/show_notificaton";


const Mark_Attendance = (props) => {

    const [data, setData] = useState([]);
    const [in_time, setIn_time] = useState("")
    const [out_time, setOut_time] = useState("")
    const [employees, setEmployees] = useState([])
    const [allShift, SetAllshift] = useState([])
    const [selectedEmp, setSelectedEmp] = useState([])
    const [selectedShift, setSelectedShift] = useState([])
    const [shifts, setShifts] = useState({});
    const [attendanceDate, setAttendanceDate] = useState({})
    const [editModal, setEditModal] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));




    const refContainer = useRef(null);
    const { SearchBar } = Search




    useEffect(() => {
        loadAllEmployees();
        loadAllShift();
    }, [])

    const loadAllEmployees = async () => {
        let url = process.env.REACT_APP_BASEURL + "employees/getall"
        let response = await postSubmitForm(url, "")

        if (response.status === 1) {
            const active = response.data.filter(emp => emp.is_active === true)

            active.map(emp => {
                emp.label = emp.name,
                    emp.value = emp._id
            })

            setEmployees(active)
        } else {
            showNotification(response.message, "Error")
        }
    }

    const loadAllShift = async () => {
        let url = process.env.REACT_APP_BASEURL + "shifts/getall"
        let response = await postSubmitForm(url, "")
        if (response.status === 1) {
            const active = response.data.filter(shift => shift.is_active === true)
            active.map(emp => {
                emp.label = emp.name,
                    emp.value = emp._id
            })
            SetAllshift(active)
        } else {
            showNotification(response.message, "Error")
        }
    }

    const updateAttendance = (object, token) => {
        let url = process.env.REACT_APP_BASEURL
        // const userToken = localStorage.getItem("userToken");
        // console.log("data in update attendance:", object, token)
        axios
            .post(
                url + "attendances/insert",
                object,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                if (res.data.status === 1) {
                    showNotification(res.data.message,"Success")
                    // setData(res.data.data)
                    setIn_time();
                    setOut_time();
                    setSelectedEmp();
                    setSelectedShift();
                }else if(res.data.message==="Attendance record already exists for this employee on the specified date."){
                    showWarning(res.data.message,"warning");
                }else{
                    showNotification(res.data.message || "Something went wrong","Error");
                }

            })
            .catch((err) => {
                showNotification("Something went wrong", "Error")
                console.log(err);
            });
    }

    const filterShiftId = (shiftName) => {

        return shifts.filter(shift => shift.name === shiftName)[0]

    }


    function editFormatter(cell, row) {
        return (
            <span className="text-info">
                <i
                    className="bx bxs-edit fs-5 mt-1"
                    title="Click to Edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setEditModal(!editModal)
                        setSelectedUser(row)
                        setMarkUndertime(row.mark_undertime)
                    }}
                ></i>
            </span>
        )
    }

    const handleValidSubmit = async (e, v, token, status) => {

        let object = {}
        const dateString = v.attendance_date;
        const today = new Date().toISOString().split('T')[0];
        const date = new Date(dateString).toISOString();
        const combinedDateTime = today + 'T' + in_time + 'Z';
        object = {
            employee_id: selectedEmp._id,
            attendance_date: dateString,
            // shift_id: selectedShift._id,
            // in_time: combinedDateTime,
            in_time: in_time,
            // out_time: out_time ? out_time : null,
            day_status: "P",
            remarks: v.remarks,
            is_approved: true,
        }
        // console.log(object, "modal object data")
        // let Datee = new Date()
        // let stringdate = Datee.toISOString() 
        // console.log(stringdate,"dh");
        // return;
        updateAttendance(object, token)
        // console.log("below update attendance")
        setEditModal(!editModal);
        refContainer.current.reset()


    }







    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };


    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Attendance" breadcrumbItem1="Mark Attendance" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        //   color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={(e, v) => {
                                        if (selectedEmp.length <= 0) {
                                            showWarning("Please select Employee!");
                                        }
                                        else if (in_time === "" || in_time === null) {
                                            showWarning("Please enter either In Time or Out Time");
                                            return;
                                        }
                                        else {
                                            handleValidSubmit(e, v, employeeToken, "");
                                        }

                                    }} ref={refContainer}>
                                        <Row >

                                            <Col lg={6}>

                                                <Label style={{ color: "White" }}>
                                                    {props.t("Attendance Date")}
                                                </Label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    options={{
                                                        maxDate: "today",
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}

                                                    name="attendance_date"
                                                    placeholder="Select Attendance Date"
                                                    errorMessage="Select Attendance Date"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col lg={6}>

                                                <Label style={{ color: "White" }}>
                                                    {props.t("Select Employee")}
                                                </Label>


                                                <Select
                                                    value={selectedEmp || []}
                                                    onChange={selected => {
                                                        setSelectedEmp(selected)
                                                    }}
                                                    options={employees}
                                                    //   className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    closeMenuOnSelect={true}
                                                />

                                            </Col>

                                        </Row>
                                        <Row>
                                            {/* <Col lg={6}>

                                                <Label style={{ color: "White" }}>
                                                    {props.t("Select Shift")}
                                                </Label>


                                                <Select
                                                    value={selectedShift || []}
                                                    onChange={selected => {
                                                        setSelectedShift(selected)
                                                    }}
                                                    options={allShift}
                                                    //   className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    closeMenuOnSelect={true}
                                                />
                                            </Col> */}

                                            <Col lg={6}>
                                                <div >
                                                    <Label className="form-label" style={{ color: "White" }}>
                                                        {props.t("In Time")}
                                                    </Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder={props.t("Select In Time")}
                                                            value={in_time}
                                                            onChange={(timeSelect) => {
                                                                // setIn_time(e.);
                                                                let dateString = timeSelect;
                                                                const date = new Date(dateString);
                                                                const hours = date.getHours();
                                                                const minutes = date.getMinutes();
                                                                setIn_time((hours === 0 ? `00` : hours < 10 ? `0${hours}` : hours) + ":" + (minutes === 0 ? `00` : minutes < 10 ? `0${minutes}` : minutes))
                                                                // console.log("time:",(hours === 0 ? `00`:hours < 10 ? `0${hours}`:hours)+":"+ (minutes === 0 ? `00`:minutes < 10 ? `0${minutes}`:minutes));
                                                            }}
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                dateFormat: "H:i",
                                                                time_24hr: true
                                                            }}

                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="mdi mdi-clock-outline" />
                                                            </span>
                                                        </div>
                                                    </InputGroup>
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div >
                                                    <Label className="form-label" style={{ color: "White" }}>
                                                        {props.t("Remarks")}
                                                    </Label>
                                                    <AvField
                                                        className="form-control"
                                                        name="remarks"
                                                        placeholder="Enter remarks"
                                                    />
                                                </div>
                                            </Col>


                                        </Row>
                                        <Row style={{ marginTop: "10px" }}>

                                            {/* <Col lg={6}>
                                                <div >
                                                    <Label className="form-label" style={{ color: "White" }}>
                                                        {props.t("Out Time")}
                                                    </Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder={props.t("Select Out Time")}
                                                            value={out_time}
                                                            onChange={(timeSelect) => {
                                                                // setIn_time(e.);
                                                                let dateString = timeSelect;
                                                                const date = new Date(dateString);
                                                                const hours = date.getHours();
                                                                const minutes = date.getMinutes();
                                                                setOut_time((hours === 0 ? `00` : hours < 10 ? `0${hours}` : hours) + ":" + (minutes === 0 ? `00` : minutes < 10 ? `0${minutes}` : minutes))
                                                                // console.log("time:",(hours === 0 ? `00`:hours < 10 ? `0${hours}`:hours)+":"+ (minutes === 0 ? `00`:minutes < 10 ? `0${minutes}`:minutes));
                                                            }}
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                dateFormat: "H:i",
                                                                time_24hr: true
                                                            }}

                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="mdi mdi-clock-outline" />
                                                            </span>
                                                        </div>
                                                    </InputGroup>
                                                </div>
                                            </Col> */}



                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
                                                <label style={{ height: "20.5px" }} className="form-label">

                                                </label>
                                                <div style={{ display: "flex" }}>

                                                    <Button
                                                        type="submit"
                                                        style={{
                                                            position: "relative",
                                                            overflow: "hidden",
                                                            backgroundColor: "#fdd005",
                                                            border: "none",
                                                            color: "black",
                                                            fontSize: "15px",
                                                            fontWeight: "500",
                                                            width: "100px",
                                                            marginTop: "-0.2rem",
                                                            boxShadow:
                                                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                            transition: "transform 0.3s ease",
                                                        }}
                                                        onMouseEnter={e => {
                                                            e.target.style.transform = "scale(1.05)"
                                                        }}
                                                        onMouseLeave={e => {
                                                            e.target.style.transform = "scale(1)"
                                                        }}
                                                    >
                                                        {props.t("Submit")}
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: "0",
                                                                left: "0",
                                                                right: "0",
                                                                bottom: "0",
                                                                background:
                                                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                                pointerEvents: "none",
                                                            }}
                                                        ></div>
                                                    </Button>
                                                </div>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>


        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(Mark_Attendance)))
