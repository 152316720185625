import { LOAD_SUPPLIER, LOAD_SUPPLIER_SUCCESS, SUBMIT_SUPPLIER, SUBMIT_SUPPLIER_SUCCESS, SUPPLIER_API_ERROR, UPDATE_SUPPLIER, UPDATE_SUPPLIER_SUCCESS } from "./actionTypes";
  
  const initialState = {
    error: null,
    loading: false,
  };
  
  const suppliers = (state = initialState, action) => {
    switch (action.type) {
      case SUBMIT_SUPPLIER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case SUBMIT_SUPPLIER_SUCCESS:
        state = {
          ...state,
          loading: false,
          message: "Supplier added successfully.",
          message_type: "Success",
        };
        break;
        case UPDATE_SUPPLIER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case UPDATE_SUPPLIER_SUCCESS:
        state = {
          ...state,
          loading: false,
          message: "Supplier updated successfully.",
          message_type: "Success",
        };
        break;
      case LOAD_SUPPLIER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case LOAD_SUPPLIER_SUCCESS:
        state = {
          ...state,
          loading: false,
          suppliers: action.payload.data,
        };
        break;
  
      case SUPPLIER_API_ERROR:
        state = {
          ...state,
          error: action.payload,
          loading: false,
          message: action.payload,
          message_type: "Error",
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default suppliers;
  