import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, Button, Table, Label, CardTitle } from "reactstrap";
import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../../helpers/backend_helper";
import logo from "../../../assets/images/Logo.png"
import { connect } from "react-redux";
import withRouter from "../../../components/Common/withRouter";
import showNotification from "../../../helpers/show_notificaton";
import { Link, useParams } from "react-router-dom";
import "./print.css"
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next"


const ClosurePrint = (props) => {

  const code = localStorage.getItem("code")
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const [closureData, setClosureData] = useState();
  const loadClosureData = async (data) => {
    let url = process.env.REACT_APP_BASEURL + "reports/printcloser";
    const response = await postSubmitForm(url, data);
    if (response && response.status === 1) {
      setClosureData(response.data);
    } else {
      showNotification(response.message, "Error");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("closure")) {
      loadClosureData({ date: urlParams.get("closure") });
    }
  }, []);

  function printInvoice() {
    window.print();
  }

  const columns = [
    {
        dataField: "_id",
        hidden: true,
    },
    {
        dataField: "_id",
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
        },
        text: props.t("S.N"),
        headerStyle: (colum, colIndex) => {
            return { width: "1%" }
        },
        footer: false
    },
    {
        text: props.t("Cashier"),
        dataField: "_id",
        formatter:(cell,row)=>{
            return closureData ? row._id : row._id
        },
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: "3%" }
        },
        footer: false
    },
    {
        text: props.t("Actual Cash"),
        dataField: "actual_cash",
        sort: false,
        headerStyle: (colum, colIndex) => {
            return { width: "5%" }
        },
        footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
    },

    {
      text: props.t("Change Amount"),
      dataField: "change_amount",
      sort: false,
      headerStyle: (colum, colIndex) => {
          return { width: "5%" }
      },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
  },
    {
        text: props.t("Cash in hand"),
        style: (cell, row, rowIndex, colIndex) => {
            if (row.actual_cash > row.cash_in_hand && !closureData) {
                return { background: "#FFD4E6" };
            }
            return {};
        },
        dataField: "cash_in_hand",
        sort: false,
        headerStyle: (colum, colIndex) => {
            return { width: "5%" }
        },
        footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
    },
    {
      text: props.t("Card"),
      dataField: "actual_card",
      sort: false,
      headerStyle: (colum, colIndex) => {
          return { width: "5%" }
      },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0)
  },
   
    {
        text: props.t("Remarks"),
        dataField: "remarks",
        sort: false,
        headerStyle: (colum, colIndex) => {
            return { width: "5%" }
        },
        footer: false,
    },
]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>


          <Row>
            <Col lg="12">
              {/* <Card> */}
              {/* <CardBody> */}
              <div className="invoice-title">
                {/* <h4 className="float-right font-size-16">
                        {props.t("Closure Report")}
                        <br />
                        <p >
                          {closureData &&
                            closureData.date_of_closure}
                        </p>
                      </h4> */}
                <div className="row head" style={{ backgroundColor: " #f0f0f0", height: "100px", borderRadius: "50px 0px 0px 50px" }}>
                  <span>{code}</span>
                  <div className="col col-md-2 col-sm-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                    <img src={logo} style={{ height: "105px", borderRadius: " 50%", width: "110px", backgroundColor: "#000000" }} />
                  </div>
                  <div className="col col-md-10 col-sm-10 pt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <p className="" style={{ color: "#000000", fontWeight: "500" }}>
                      <h5>{props.t("Closure Date")}</h5> <br />
                      {/* PO Id : {closureData.po_id}<br /> */}
                      <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "-15px", fontSize: "16px" }}>{closureData?.closure_date ? formatDate(closureData?.closure_date) : "N/A"}</span>
                      <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}></span>
                    </p>
                  </div>
                </div>
              </div>
              <hr />

              {(
                <>
                  <h3>Income Details</h3>
                  <div className="table-responsive">
                    <Table className="table" style={{ fontSize: "17px" }}>
                      <thead>
                        <tr>
                          <th>{props.t("Invoice #")}</th>
                          {/* <th className="text-right">
                            {props.t("Amount")}
                          </th> */}
                          <th className="text-right">
                            {props.t("Games Total")}
                          </th>
                          <th className="text-right">{props.t("Item Total")}</th>
                          <th className="text-right">{props.t("Total Minutes")}</th>
                          <th className="text-right">
                            {props.t("Discount Amount")}
                          </th>
                          <th className="text-right">
                            {props.t("Final Amount")}
                          </th>
                          <th className="text-right">
                            {props.t("Change ")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {closureData &&
                          closureData.tableData.map(
                            (service, key) => (
                              <tr key={key}>
                                <td>{service.invoice_no}</td>
                                {/* <td className="text-right">
                                  {service.total_amount}
                                </td> */}
                                <td className="text-right">
                                  {service.game_total_price || 0}
                                </td>
                                <td className="text-right">
                                  {service.item_total_price}
                                </td>
                                <td className="text-right">
                                  {service.total_time || 0}
                                </td>
                                <td className="text-right">
                                  {`${service.discount_amount} `}
                                </td>
                                <td className="text-right">
                                  {service.final_amount}
                                </td>
                                <td className="text-right">
                                  {service.change_amount}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>

                      <tfoot className="table-footer">
                        <tr>

                          <td className="border-0 text-right">
                            <strong>Total</strong>
                          </td>
                          {/* <td className="border-0 text-right">
                            <strong>

                              {closureData &&
                                closureData.total_amount?.toFixed(2)}
                            </strong>
                          </td> */}
                          <td className="border-0 text-right">
                            <strong>
                              {closureData &&
                                closureData.total_game_price?.toFixed(2)}
                            </strong>
                          </td>
                          <td className="border-0 text-right">
                            <strong>
                              {closureData &&
                                closureData.total_item_price?.toFixed(2)}
                            </strong>
                          </td>
                          <td className="border-0 text-right">
                            <strong>
                              {closureData &&
                                closureData.total_time_sum?.toFixed(2)}
                            </strong>
                          </td>
                          <td className="border-0 text-right">
                            <strong>
                              {closureData &&
                                closureData.total_discount_amount?.toFixed(2)}
                            </strong>
                          </td>
                          <td className="border-0 text-right">
                            <strong>
                              {closureData &&
                                closureData.final_amount_table?.toFixed(2)}
                            </strong>
                          </td>
                          <td className="border-0 text-right">
                            <strong>
                              {closureData &&
                                closureData.total_change_amount?.toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                  {/* <h4>
                          Total Income:{" "}
                          {closureData &&
                            closureData.total_income}
                        </h4> */}
                </>
              )}

<Col lg={12}>
                                <CardTitle>
                                    <Label>{props.t("Cashier Breakup")}</Label>
                                </CardTitle>

                                <BootstrapTable
                                    keyField="_id"
                                    columns={columns}
                                    // data={cashierData}
                                    data={closureData?.cashier_break_up || []}
                                    noDataIndication={props.t("No data to display.")}
                                    rowStyle={{ height: '10px' }}

                                />

                            </Col>


              <hr style={{ border: "1px dotted black" }} />

              <h3>Summary</h3>  <Label style={{fontSize:"14px" ,fontWeight:"bold"}}>
                                            Total Income &nbsp; = &nbsp; Sum of all sales <br /> 
                                            Net Income &nbsp;  = &nbsp; Sum of all sales - Refunds + Extra Amount
                                        </Label>
              <hr />
              <Table className="table" style={{ fontSize: "17px" }}>
                <tbody>
                  <tr>
                    <td className="border-0 ">
                      {props.t("Total Income")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData?.total_income||0}</strong>
                    </td>

                    <td className="border-0 ">
                      {props.t("Net Income")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData?.total_net_income||0}</strong>
                    </td>


                    <td className="border-0 ">
                      {props.t("Card Payment")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData?.paid_by_card ||0}</strong>
                    </td>

                   
                    <td className="border-0 ">
                      {props.t("Cash Payment")}
                    </td>
                    <td className="border-0 ">
                     <strong> {closureData &&
                        closureData.paid_by_cash||0}</strong>
                    </td>



                  </tr>
                  <tr>

                 

                    
                  <td className="border-0 ">
                      {props.t("Apple Pay")}
                    </td>
                    <td className="border-0 ">
                     <strong> {closureData &&
                        closureData.paid_by_applePay || 0}</strong>
                    </td>

                    <td className="border-0 ">
                      {props.t("Google Pay")}
                    </td>
                    <td className="border-0 ">
                     <strong> {closureData &&
                        closureData.paid_by_googlePay || 0}</strong>
                    </td>


                    <td className="border-0 ">
                      {props.t("Extra Amount")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData.total_difference_amount+closureData.total_extra_amount || 0}</strong>
                    </td>
                    <td className="border-0 ">
                      {props.t("Wallet Credits")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData.wallet_credit_total||0}</strong>
                    </td>


                  </tr>

                  <tr>
                    
                 

                    

                   
                  <td className="border-0 ">
                      {props.t("Wallet Debits")}
                    </td>
                    <td className="border-0 ">
                     <strong> {closureData &&
                        closureData.wallet_debit_total||0}</strong>
                    </td>
                 
                   

                  <td className="border-0 ">
                      {props.t("Total Refund")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData.total_refund || 0}</strong>
                    </td>

                    <td className="border-0 ">
                      {props.t("Change Amount")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData.total_change_amount || 0}</strong>
                    </td>

                    <td className="border-0 ">
                      {props.t("Package Sold")}
                    </td>
                    <td className="border-0 ">
                    <strong>  {closureData &&
                        closureData.package_sold ||0}</strong>
                    </td>

                

                 
                  </tr>

                  <tr>
                  
                  <td className="border-0 ">
                      {props.t("Discount Amount")}
                    </td>
                    <td className="border-0 ">
                      <strong>{closureData &&
                        closureData.total_discount_amount || 0}</strong>
                    </td>
                  <td className="border-0 ">
                      {props.t("Client Visits")}
                    </td>
                    <td className="border-0 ">
                    <strong>  {closureData &&
                        closureData.customers_visited ||0}</strong>
                    </td>

                    <td className="border-0 ">
                      {props.t("Completed Bookings")}
                    </td>
                    <td className="border-0 ">
                     <strong> {closureData &&
                        closureData.completed_bookings ||0}</strong>
                    </td>
                    
                
                 
                  <td className="border-0 ">
                      {props.t("Cancelled Booking")}
                    </td>
                    <td className="border-0 ">
                     <strong> {closureData &&
                        closureData.cancelled_bookings ||0}</strong>
                    </td>

                

                  </tr>

                  <tr>
                  <td className="border-0 ">
                      {props.t("Items Sold")}
                    </td>
                    <td className="border-0 ">
                    <strong>  {closureData &&
                        closureData.products_sold ||0}</strong>
                    </td>
                  <td className="border-0 ">
                      {props.t("Package Sold")}
                    </td>
                    <td className="border-0 ">
                    <strong>  {closureData &&
                        closureData.package_sold ||0}</strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <hr />
              <br />


              <div className="d-print-none d-flex justify-content-center">
                <div className="float-right">
                  <Link
                    to="#"
                    onClick={() => {
                      printInvoice();
                    }}
                    className="btn btn-primary btn-lg waves-effect waves-light mr-2"
                  >
                    <i className="fa fa-print"></i> {props.t("Print")}
                  </Link>
                </div>
              </div>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withTranslation()(ClosurePrint)));
