function CalcEndTime(startTime, GameDuration, setBookingEndTime) {

    const inputDate = new Date(startTime);  

    const gameDurationMilliseconds = GameDuration * 60000;

    // Adding the specified milliseconds to the date
    const endTime = new Date(inputDate.getTime() + gameDurationMilliseconds);

    
    const day = String(endTime.getDate()).padStart(2, '0');
    const month = String(endTime.getMonth() + 1).padStart(2, '0');
    const year = endTime.getFullYear();
    
    const hours = String(endTime.getHours()).padStart(2, '0');
    const minutes = String(endTime.getMinutes()).padStart(2, '0');
    
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    const isoDate = `${formattedDate.substr(6, 4)}-${formattedDate.substr(3, 2)}-${formattedDate.substr(0, 2)}T${formattedDate.substr(11)}:00`;
    
    
    setBookingEndTime(prev => prev = isoDate)
}

export default CalcEndTime;