import React, { useEffect, useState, useRef } from "react"
//i18n
import { withTranslation } from "react-i18next"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import swal from "sweetalert2"

import { AvForm, AvField } from "availity-reactstrap-validation"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import exportFromJSON from "export-from-json"
import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const AllSuppliers = props => {

  const { SearchBar } = Search

  useEffect(() => {
    loadAllSuppliers()
  }, [])

  const handleDownload = async () => {
    const fileName = "All Suppliers";
    const exportType = "xls";

    let data_to_export = allSuppliers;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.name,
          Contact_Person: v.contact_person,
          Mobile: v.mobile,
          Phone: v.phone,
          Email: v.email,
          Address: v.address,

        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  function preventValueChangeOnScroll(e) {

    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current     
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  // ------------------------------------------------------------------------------

  const [allSuppliers, setAllSuppliers] = useState([])
  const loadAllSuppliers = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "suppliers/getall"
    const response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      preloader(false)
      setAllSuppliers(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  const [selectedUser, setSelectedUser] = useState()
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly" >
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedUser(row)
          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
        <div className="form-switch ms-2" dir="ltr">
          <input
            type="checkbox"
            style={{height:"15px", width:"30px"}}
            title={row.is_active ? "Click to Deactivate ." : "Activate"}
            className="form-check-input"
            id={"customSwitchsize1" + row._id}
            onClick={() => {
              handleValidDelete(row)
            }}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ fontWeight: "100", cursor: "pointer" }}
          ></label>
        </div>
      </span>
    )
  }


  const handleValidUpdate = async (e, v) => {
    try {
      preloader(true)
      const object = {
        id: selectedUser._id,
        name: v.name,
        contact_person: v.contact_person,
        mobile: v.mobile,
        phone: v.phone,
        address: v.address,
        email: v.email,
      }

      let url = process.env.REACT_APP_BASEURL + "suppliers/update"
      let response = await postSubmitForm(url, object)
      // console.log("url", url)
      if (response.status === 1) {
        preloader(false)
        loadAllSuppliers()
        setEditModal(!editModal)
        showNotification(response.message, "Success")
      } else {
        preloader(false)
        showNotification(response.message, "Error")
      }
    } catch (error) {
      preloader(fase)
      showNotification(error.message, "Error")
    }
  }

  //Delete
  const handleValidDelete = async selectedUser => {
    let url = process.env.REACT_APP_BASEURL + "suppliers/activate_deactivate"
    let response = await postSubmitForm(url, {
      id: selectedUser._id,
      is_active: selectedUser.is_active === true ? false : true
    })
    if (response.status === 1) {
      loadAllSuppliers()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }
  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "contact_person",
      text: props.t("Contact Person"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
    },
    {
      text: props.t("Mobile"),
      //formatter: employeeFormatter,
      dataField: "mobile",
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", 
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "phone",
      text: props.t("Phone"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "email",
      text: props.t("Email"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "address",
      text: props.t("Adress"),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },

    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            alternateTitle={props.t("Update Supplier")}
            title={props.t("Suppliers")}
            breadcrumbItem1={props.t("View Suppliers")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Suppliers")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                          type="submit"

                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your existing suppliers here")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    data={allSuppliers && allSuppliers}
                    columns={columns}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          filter={filterFactory()}
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Supplier - ")}
                      {selectedUser && selectedUser.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col lg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage={props.t("Name cannot be empty")}
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          value={selectedUser.name}
                                        />
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="contact_person"
                                          label={props.t("Contact Person")}
                                          placeholder={props.t(
                                            "Enter Contact Person Name"
                                          )}
                                          type="text"
                                          errorMessage={props.t("Contact Person Name cannot be empty")}
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          value={selectedUser.contact_person}
                                        />
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="mobile"
                                          label={props.t("Mobile")}
                                          placeholder={props.t("Enter Mobile")}
                                          type="number"
                                          errorMessage={props.t("Mobile No. cannot be empty")}
                                          value={selectedUser.mobile}
                                        // validate={{
                                        //   required: {  value: true  },
                                        // }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="phone"
                                          label={props.t("Phone") + " *"}
                                          placeholder={props.t("Enter Phone")}
                                          type="number"
                                          errorMessage={props.t("Phone cannot be empty")}
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          value={selectedUser.phone}
                                        />
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="email"
                                          value={selectedUser.email}
                                          label={props.t("Email")}
                                          placeholder={props.t("Enter Email")}
                                          errorMessage={props.t(
                                            "Please enter valid Email."
                                          )}
                                          type="email"
                                        />
                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="address"
                                          value={selectedUser.address}
                                          label={props.t("Address")}
                                          placeholder={props.t("Enter Address")}
                                          errorMessage={props.t(
                                            "Please enter valid address."
                                          )}
                                          type="text"
                                        />
                                      </Col>



                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              style={{width:"auto"}}
                                              type="submit"
                                              color="primary"
                                              className="mr-1 btn btn-primary"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(connect(null, {})(withTranslation()(AllSuppliers)))
