import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  CardTitle,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification from "../../helpers/show_notificaton"
import {AvForm} from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import moment from "moment"

const WalletHistory = props => {

const { SearchBar } = Search

useEffect(()=>{
    loadAllTransactions()
    },[])
    
    const [editModal, setEditModal] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [wallet, setwallet] = useState()
    const [allTransactions, setAllTransactions] = useState([])
     //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} - {to} of {size} records.
      </span>
    );
  
    const options = {
      paginationSize: 20,
      pageStartIndex: 1,
      onPageChange: (page, sizePerPage) => {
        setPageNumber(page);
        setPageSize(sizePerPage);
      },
      firstPageText: "First",
      prePageText: "Prev",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [
        {
          text: "100",
          value: 100,
        },
        {
          text: "200",
          value: 200,
        },
        {
          text: "300",
          value: 300,
        },
        {
          text: "All",
          value: allTransactions && allTransactions.length,
        },
      ],
      hideSizePerPage: true,
      ignoreSinglePage: true,
    };


      const loadAllTransactions = async () => {
        preloader(true)
        let url = process.env.REACT_APP_BASEURL + "customers/getall"
      //let url = "http://localhost:3002/item_category/get_all_item_category"
        let response = await postSubmitForm(url, "")
        if (response.status === 1) {
          let filtered  = response.data.filter((data)=>data.is_wallet_eligible)
          preloader(false)
            // console.log(response.data);
          setAllTransactions(filtered)
        } else {
          preloader(false)
          showNotification(response.message, "Error")
        }
      }
      function formatDate(date) {
        if (!date) return null
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear()
    
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
    
        return [year, month, day].join("-")
      }
 
      function editFormatter(cell, row) {
        return (
          <span className="text-info">
            <i
              className="bx bxs-file fs-5"
              title="Click to View Transactions "
              style={{ cursor: "pointer", color:"blue" }}
              onClick={() => {
                setEditModal(!editModal)
                setSelectedUser(row)
                setwallet(row.wallet_transactions)
              }}
            ></i>
          </span>
        )
      }
    
    
      const columns = [
        {
          dataField: "_id",
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
          },
          text: props.t("#"),
          headerStyle: (colum, colIndex) => {
            return { width: "0.5%" }
          },
        },
        {
          text: props.t("Name"),
          //formatter: employeeFormatter,
          dataField: "name",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
          text: props.t("Mobile"),
          //formatter: employeeFormatter,
          dataField: "mobile",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
            text: props.t("Wallet"),
            dataField: "wallet",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
          {
            text: props.t("Customer Type"),
            //formatter: employeeFormatter,
            dataField: "customer_type",
            filter: selectFilter({
              options: [
                { value: "Regular", label: "Regular" },
                { value: "VIP", label: "VIP" },
                { value: "Friend", label: "Friend" },
              ],
              placeholder: "ALL",
            }),
            headerStyle: (colum, colIndex) => {
              return { width: "2%" }
            },
          },
          {
            dataField: "customerCreatedBy",
            text: props.t("Created By"),
            sort:true,
            formatter:(cell,row)=>{
              return row.customerCreatedBy?.employee_name
            },
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
          {
            dataField: "createdAt",
            text: props.t("Created On"),
            formatter:(cell,row)=>{
              return moment(row.createdAt).format("YYYY-MM-DD")
            },
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
        {
          dataField: "",
          text: props.t("Action"),
          formatter: editFormatter,
          sort: false,
          headerStyle: (colum, colIndex) => {
            return { width: "1%" }
          },
        },
      ]

      const walletColumn = [
        {
          dataField: "_id",
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
          },
          text: props.t("S.N"),
          headerStyle: (colum, colIndex) => {
            return { width: "1%" }
          },
        },
        {
          text: props.t("Amount"),
          //formatter: employeeFormatter,
          dataField: "transaction_amount",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
          text: props.t("Transaction Date"),
          //formatter: employeeFormatter,
          dataField: "transaction_date",
          sort: true,
          formatter:(cell,row)=>{
            return formatDate(row.transaction_date)
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
            text: props.t("Transaction Remarks"),
            dataField: "transaction_remarks",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
          {
            text: props.t("Transaction Type"),
            //formatter: employeeFormatter,
            dataField: "transaction_type",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
      ]

      const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    
        
      };
    
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Customer")} 
            breadcrumbItem1={props.t("Wallet History")}
            alternateTitle={props.t("Wallet History")}
            />

          <Row>
            <Col lg={12}>
              <Card>
             
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Transactions")}
                      </Col>

                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allTransactions && allTransactions || []}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{display:"flex", alignItems:"flex-start"}}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          filter={filterFactory()}
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
               
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* view modal */}

        <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Transaction History - ")}
                      {selectedUser && selectedUser.name}
                    </ModalHeader>
                    <ModalBody>
                    <ToolkitProvider
                    keyField="_id"
                    columns={walletColumn}
                    data={wallet && wallet || []}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{display:"flex", alignItems:"flex-start"}}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                    </ModalBody>
                  </Modal>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(WalletHistory)))
