import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button

} from "reactstrap"
import swal from "sweetalert2"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import moment from 'moment'
import exportFromJSON from "export-from-json"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import BackgroundImage from "../../assets/images/Background.png"
import Swal from "sweetalert2"
import Select from "react-select"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
}

const ApplyOvertimeByOwner = (props) => {
    const { SearchBar } = Search
    const [allEmployees, setAllEmployees] = useState();
    const [allOvertime_data, setAllOvertime_data] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState("");
    useEffect(() => {
        fetch_overtime_application();
    }, [])
    const loadAllEmployees = async () => {
        let url = process.env.REACT_APP_BASEURL + "employees/getall"
        let response = await postSubmitForm(url, "")

        if (response.status === 1) {
            response.data.map(employee => {
                employee.label = employee.name,
                    employee.value = employee._id
            })
            const active = response.data.filter(employee => employee.is_active === true)
            setAllEmployees(active)

        } else {
            showNotification(response.message, "Error")
        }
    }

    //usestate for showing alert and spinner when apply overtime application gets submitted
    const [showalert, setShowalert] = useState(false)


    // useState for fetching and storing the data of the apply overtime table
    const [employee_id, setEmployeeId] = useState(
        localStorage.getItem("employee_id")
    )
    const [employeeToken, setEmployeeToken] = useState(
        localStorage.getItem("userToken")
    )
    const [appliedOvertimeDetails, setAppliedOvertimeDetails] = useState([])
    const refContainer = useRef(null)

    const [submitButtonClickedTracker, setSubmitButtonClickedTracker] =
        useState(0)
    // --------------------------------------------------

 
    useEffect(() => {
        function getRoster(token) {
            let url = process.env.REACT_APP_BASEURL
            // console.log("token:",token)
            axios
                .post(
                    url + `overtimeapplications/get_by_employee`,
                    { employee_id: employee_id },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(res => {
                    if(res.data.status===1){
                        setAppliedOvertimeDetails(res.data.data)
                    }else{
                        showNotification(response.data.data.message,"Error")
                    }

                })
                .catch(err => console.log(err))
        }

        getRoster(employeeToken)
        loadAllEmployees();
    }, [submitButtonClickedTracker])



    // function handle submit
    const handleValidSubmit = async (e, v, token) => {

        let url = process.env.REACT_APP_BASEURL

        const object = {
            employee_id: selectedEmployee._id,
            overtime_minutes: v.requestedOvertime,
            date: v.date
        }
        // console.log("Data", object) 
        // return
        axios
            .post(
                url + `overtimeapplications/insert`,
                object,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setShowalert(true)
                // console.log(res, "overtime response")
                setSubmitButtonClickedTracker(submitButtonClickedTracker + 1)
                const status = response.data.status;
                if (status === 1) {
                    setSelectedEmployee("")
                    fetch_overtime_application()
                    Swal.fire("Success", response.data.message, "success",);
                } else {
                    Swal.fire("Error", response.data.message, "error");
                }
                if (refContainer.current) {
                    refContainer.current.reset();
                }
            })
            .catch(err => {
                console.log(err);

                swal.fire({
                    icon: "error",
                    title: err,
                    showConfirmButton: false,
                    timer: 2000
                });
                if (refContainer.current) {
                    refContainer.current.reset();
                }
            })
    }
    // ________
    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            text: "#",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            dataField: "date",
            text: "Date",
            formatter: (cell, row) => {
                return moment(row.date).format("YYYY-MM-DD")
            },
            hidden: false,
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Name/Code"),
            dataField: "employee_code",
            hidden: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Name/Code"),
            //formatter: employeeFormatter,
            dataField: "employee_name",
            formatter: (cell, row) => {
                return row.employee_name + "" + "(" + row.employee_code + ")"
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "overtime_minutes",
            text: "Requested Overtime",
            formatter: (cell, row) => cell || "0",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "shift_name",
            text: "Shift",
            formatter: (cell, row) => {
                return row.shift_name ? row.shift_name : " "
              },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            dataField: "in_time",
            text: "In Time",
            sort: false,
            formatter: (cell, row) => cell,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            dataField: "out_time",
            text: "Out Time",
            // formatter: editFormatter,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
        },
        {
            text: props.t("Early In"),
            formatter: (cell, row) => {
                return row.late_in < 0 ? Math.abs(row.late_in) : ""
            },
            dataField: "late_in",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
        },
        {
            text: props.t("Late In"),
            formatter: (cell, row) => {
                return row.late_in > 0 ? Math.abs(row.late_in) : ""
            },
            dataField: "late_in",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
        },
        {
            text: props.t("Early Out"),
            formatter: (cell, row) => {
                return row.early_out > 0 ? Math.abs(row.early_out) : ""
            },
            dataField: "early_out",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
        },
        {
            text: props.t("Late Out"),
            formatter: (cell, row) => {
                return row.early_out < 0 ? Math.abs(row.early_out) : ""
            },
            dataField: "early_out",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
        },
        {
            dataField: "system_overtime",
            text: "System Overtime",
            // formatter: editFormatter,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
        }
    ]
    // ____fetchdata____
    const fetch_overtime_application = async () => {
        let url = process.env.REACT_APP_BASEURL
        try {
            const employee_id = localStorage.getItem("employee_id");
            const userToken = localStorage.getItem("userToken");
            const object = {
                emp_id: employee_id
            }
            const response = await axios.post(url + "overtimeapplications/getall_appliedBy",
                object,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const results = response.data.data;
            setAllOvertime_data(results);
        } catch (error) {
            console.log(error)
        }
    }
    const handleDownload = async () => {
        const fileName = "All Applied Overtimes Application";
        const exportType = "xls";
    
        let data_to_export = allOvertime_data;
    
        if (data_to_export) {
          var data = [];
          JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
            data.push({
              Employye_Name: v.employee_name,
              Employye_Code: v.employee_code,
              Applied_Date: moment(v.date).format("YYYY-MM-DD"),
              Approval_Date: v.loan_approval_date ?  moment(v.loan_approval_date).format("YYYY-MM-DD"): "N/A",
              Overtime_Minutes: v.overtime_minutes,
              Approved_Overtime: v.approved_overtime,
              Status: v.status,
            });
          });
          exportFromJSON({ data, fileName, exportType });
        }
      }
    return (
        <React.Fragment>
            <div className="page-content d-flex justify-content-center" style={backgroundImageStyle}>
                <div style={{ width: "100%" }}>
                    <Container fluid={true}>
                        <Breadcrumbs title="Apply Overtime" breadcrumbItem="Apply Overtime" />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody style={{
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}>
                                        <div>
                                            <AvForm onValidSubmit={(e, v) => {
                                                if (selectedEmployee === "") {
                                                    showWarning("Please Select Employee");
                                                    return;
                                                } else {
                                                    handleValidSubmit(e, v, employeeToken);
                                                }
                                            }} ref={refContainer}>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Label>
                                                            <span style={{ color: "White" }}>
                                                                Select Employee
                                                            </span>
                                                        </Label>
                                                        <Select
                                                            value={selectedEmployee || []}
                                                            onChange={selected => {
                                                                setSelectedEmployee(selected)

                                                            }}
                                                            options={allEmployees}
                                                            closeMenuOnSelect={true}
                                                            required
                                                        />
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mx-2">
                                                            <label className="form-label" style={{ color: "White" }}>
                                                                Requested Overtime in Minutes
                                                            </label>
                                                            <AvField
                                                                className="form-control"
                                                                name="requestedOvertime"
                                                                type="number"
                                                                placeholder="Enter Overtime Minutes"
                                                                errorMessage="Requested Overtime cannot be empty."
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mx-2">
                                                            <label className="form-label" style={{ color: "White" }}>
                                                                Date
                                                            </label>
                                                            <AvField
                                                                className="form-control"
                                                                type="select"
                                                                tag={Flatpickr}
                                                                options={{
                                                                    minDate: "today",
                                                                    onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                                        dayElem.addEventListener('mousedown', (e) => {
                                                                            e.preventDefault(); // Prevent default click behavior
                                                                        });
                                                                    },

                                                                }}
                                                                name="date"
                                                                placeholder="Select Date"
                                                                errorMessage="Select Overtime Date."
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>

                                                <Row>
                                                    <Col lg={12} style={{ display: "flex", justifyContent: "flex-start" }} >
                                                        <div
                                                            className="m-auto mt-4"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Button
                                                                type="submit"

                                                                style={{
                                                                    position: "relative",
                                                                    overflow: "hidden",
                                                                    backgroundColor: "#fdd005",
                                                                    border: "none",
                                                                    color: "black",
                                                                    fontSize: "15px",
                                                                    fontWeight: "500",
                                                                    width: "100px",
                                                                    marginTop: "0rem",
                                                                    boxShadow:
                                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                                    transition: "transform 0.3s ease",
                                                                }}
                                                                onMouseEnter={e => {
                                                                    e.target.style.transform = "scale(1.05)"
                                                                }}
                                                                onMouseLeave={e => {
                                                                    e.target.style.transform = "scale(1)"
                                                                }}
                                                            >
                                                                Submit
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "0",
                                                                        left: "0",
                                                                        right: "0",
                                                                        bottom: "0",
                                                                        background:
                                                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                                        pointerEvents: "none",
                                                                    }}
                                                                ></div>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <br />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody
                                        style={{
                                            boxShadow:
                                                "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                        }}
                                    >
                                        <CardTitle>View all applied overtime application</CardTitle>

                                        <ToolkitProvider
                                            keyField="_id"
                                            columns={columns}
                                            data={allOvertime_data || []}
                                            search
                                            // isGlobalFilter={true}
                                            isAddUserList={true}
                                            customPageSize={10}
                                        >
                                            {propst => (
                                                <div>
                                                     <AvForm>
                          <Row>
                            <Col lg={6} style={{ display: "flex", }}>
                              <SearchBar
                                {...propst.searchProps}
                              />
                            </Col>
                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                          </Row>
                        </AvForm>
                                                    <BootstrapTable
                                                        {...propst.baseProps}
                                                        // filter={filterFactory()}
                                                        noDataIndication={"No data to display."}
                                                        striped
                                                        hover
                                                        condensed
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}

ApplyOvertimeByOwner.propTypes = {
    t: PropTypes.any
}

export default withTranslation()(ApplyOvertimeByOwner)
