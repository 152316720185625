import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Input,
    FormGroup,
    Label,
    CardTitle,
    Badge,
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { setDefaultLocale } from "react-datepicker";
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { object } from "prop-types";
import Flatpickr from "react-flatpickr"



const DeliveredPromotionalGoods = (props) => {

    // const [editModal, setEditModal] = useState();
    const { SearchBar } = Search

    const [rowId, setRowId] = useState("")
    const [row, setRow] = useState()
    const [count, setCount] = useState(0);

    const { deliveredPromoItems, setDeliveredPromoItems, setAddedItems, addedItems, selectedPO, cameWithRow } = props;

    // console.log(deliveredPromoItems, "received items")

    useEffect(() => {



        let a = selectedPO.delivery_details.map(item => {
            // let promoItemsObj = {}
            for (let i = 0; i < item.promotional_goods.length; i++) {
                item.promotional_goods[i].cameWith = item.name;
            }
            return item.promotional_goods;
        })
        let b = []
        for (let i = 0; i < a.length; i++) {
            b = [...b, ...a[i]]
        }
        setDeliveredPromoItems(b)




    }, [])

    const handleDateChange = (e, rowIndex, row, editorProps) => {
        // console.log(data,"data")
        const { value } = e.target;
        // console.log(e.target.value, "chnaged date")
        editorProps.onUpdate(value);
    };










    function deleteFormatter(cell, row) {

        return (
            <i
                className="fa fa-trash fs-5 ms-3" aria-hidden="true"
                // title="Send Email"
                style={{ cursor: "pointer", color: "red" }}
                onClick={() => {

                    setRow(row)
                }}
            ></i>
        )
    }

    function expiryDateFormatter(cell, row, rowIndex) {

        return (
            <>
                {row.expiry_date === null ? <p>No Expiry</p> : <p>{row.expiry_date}</p>}

            </>
        )
    }

    // modal table columns accessory items
    const promotionalGoodsColumn = [
        {
            dataField: "item_id",
            hidden: true
        },
        {
            text: props.t("S.N"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Came With"),
            formatter: (cell, row) => {
                return (
                    // <p>{cameWithRow[singleItemId].name}</p>
                    <p>{row.cameWith}</p>
                )
            },
            dataField: "cameWith",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Name"),
            dataField: "name",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Section"),
            dataField: "section",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Category"),
            dataField: "category",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Unit"),
            dataField: "unit",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Sub-Unit"),
            dataField: "sub_unit",
            formatter: (cell, row) => {
                return <p>{(row.sub_unit === null || row.sub_unit === "") ? <Badge className="badge-soft-danger" >No Sub-Unit</Badge> : `${row.sub_unit}`}</p>;
            },
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
        },
        {
            text: props.t("Formula"),
            dataField: "formula",
            sort: true,
            editable: false,
            formatter: (cell, row) => {
                return (
                    <>
                        {"1 " + row.unit + " = " + row.formula + row.sub_unit}
                    </>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { width: "10%" }
            },
        },
        // {
        //     text: props.t("Quantity"),
        //     // formatter: (cell, row) => {
        //     //   return (
        //     //     <p>{`${row.quantity.in_unit} ${row.unit}`}</p>
        //     //   )
        //     // },
        //     dataField: "in_unit",
        //     sort: true,
        //     editable: true,
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "5%" }
        //     },
        // },
        {
            text: props.t("Cost Price"),
            formatter: (cell, row) => {
                return (
                    <p>0</p>
                )
            },
            dataField: "cost_price",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Selling Price"),
            formatter: (cell, row) => {
                return (
                    <p>0</p>
                )
            },
            dataField: "selling_price",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Total"),
            dataField: "total_price",
            sort: true,
            editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("In Unit"),
            dataField: "receive.in_unit",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{`${row.receive.in_unit} ${row.unit}`}</p>;
            },
            sort: true,
            editable: false,
            attrs: (cell, row, rowIndex, colIndex) => {
                return { title: "Click to Edit" };
            },
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            // style: (cell, row, rowIndex, colIndex) => {
            //   return { cursor: "pointer", background: "#ffffaa" };
            // },
        },
        {
            text: props.t("In Sub-Unit"),
            dataField: "receive.in_sub_unit",
            formatter: (cell, row) => {
                return <p style={{ color: "#E57373", fontWeight: 600, width: "50px" }} >{(row.sub_unit === "" || row.sub_unit === null) ? <i className="fa fa-ban" /> : `${row.receive.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            sort: true,
            editable: false,
            validator: (newValue, row, column) => {
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {
                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            attrs: (cell, row, rowIndex, colIndex) => {
                return { title: "Click to Edit" };
            },
            // style: (cell, row, rowIndex, colIndex) => {
            //   return { cursor: "pointer", background: "#ffffaa" };
            // },
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Expiry Date"),
            dataField: "expiry_date",
            sort: true,
            editable: false,
            formatter: expiryDateFormatter,
            // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            //     <Flatpickr
            //         style={{ width: "50px", height: "40px", border: "1px solid lightGray", padding: "5px", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            //         // type="text"
            //         // defaultValue={value}
            //         onBlur={(e) => handleDateChange(e, rowIndex, row, editorProps)}
            //         onFocus={(e) => e.target.select()} // Retain focus on input field
            //     />
            // ),
            // validator: (newValue, row, column) => {
            //     const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
            //     if (!dateFormatRegex.test(newValue)) {
            //         // console.log(typeof (newValue), "date value")
            //         // localStorage.setItem("expiry_date_validation", true)
            //         return {
            //             valid: false,
            //             message: "date format must be yyyy-mm-dd",
            //         };
            //     }
            //     // localStorage.setItem("expiry_date_validation", false)
            //     return true;
            // },
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },

        // {
        //     text: props.t("Action"),
        //     formatter: deleteFormatter,
        //     sort: true,
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "5%" }
        //     },
        //     editable: false
        // },
    ]

    return <>
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle>{props.t("Promotional Items Received")} </CardTitle>
                        <ToolkitProvider
                            keyField="_id"
                            data={deliveredPromoItems}
                            columns={promotionalGoodsColumn}
                        >
                            {propst => (
                                <div>
                                    <BootstrapTable
                                        {...propst.baseProps}
                                        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}

export default withRouter(connect(null, {})(withTranslation()(DeliveredPromotionalGoods)))