import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import dashboardCryptoSaga from "./dashboard-crypto/saga"
import dashboardBlogSaga from "./dashboard-blog/saga"
import dashboardJobSaga from "./dashboard-jobs/saga"
import EmployeeSaga from "./employees/saga"

import CafeItemSaga from "./item/cafeItem/saga"
import AccessoriesItemSaga from "./item/accessoriesItem/saga"
import KitchenItemSaga from "./item/kitchenItem/saga"
import ShiftSaga from "./shift/saga"
import SupplierSaga from "./supplier/saga"
import employeeRosterSaga from "./employee-roster/saga"
import ownerRosterSaga from "./owner-roster/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(EmployeeSaga),
    fork(SupplierSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardBlogSaga),
    fork(dashboardJobSaga),

    fork(AccessoriesItemSaga),
    fork(CafeItemSaga),
    fork(KitchenItemSaga),

    fork(ShiftSaga),

    fork(employeeRosterSaga),

    fork(ownerRosterSaga)

  ])
}
