import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import BackgroundImage from "../../assets/images/Background.png"

//i18n
import { withTranslation } from "react-i18next"
import WelcomeComp from "../Dashboard/WelcomeComp"
import DashboardTableStatus from "../Bookings/DashboardTableStatus"
import { postSubmitForm } from "../../helpers/backend_helper"


const Dashboard = props => {
  //meta title
  document.title = "Employee Dashboard | Rendezvous - Game Parlour"

  const [employee_id, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  )
  const [roasterData, setRoasterData] = useState("")
  const [leaveDetails, setLeaveDetails] = useState("")
  const role = localStorage.getItem("role")
  const name = localStorage.getItem("name")
  // const [rosterData, setrosterData] = useState({});

  useEffect(() => {
    loadRoaster()
    loadLeaveDetails()
  }, [])

  const loadRoaster = async () => {
    let payload = {
      employee_id: employee_id,
    }
    let url = process.env.REACT_APP_BASEURL + "rosters/get_by_employee"
    const response = await postSubmitForm(url, payload)
    if (response && response.status === 1) {
      setRoasterData(response.data[0])
      // showNotification(response.message, "Success")
    } else {
      // showNotification(response.message, "Error")
    }
  }

  const loadLeaveDetails = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      const filter = response.data.filter(emp => emp._id === employee_id)
      setLeaveDetails(filter[0])
    } else {
      // showNotification(response.message, "Error")
    }
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col>
              <WelcomeComp />
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody
                  style={{
                    height: "293px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>Roster</CardTitle>
                  <div style={{ overflowX: "auto" }}>
                    <Table
                      style={{ width: "", height: "162px" }}
                      className="table table-striped table-bordered mb-0"
                    >
                      <thead>
                        <tr style={{ height: "64px", width: "50px" }}>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Sat</th>
                          <th>Sun</th>
                          <th>Mon</th>
                          <th>Tue</th>
                          <th>Wed</th>
                          <th>Thu</th>
                          <th>Fri</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: "64px", width: "50px" }}>
                          <td>
                            {(roasterData && roasterData.code) || "unavailable"}
                          </td>
                          <td>{(roasterData && roasterData.name) || name}</td>
                          <td>
                            {(roasterData &&
                              roasterData.saturday &&
                              roasterData.saturday.name) ||
                              "Off"}
                          </td>
                          <td>
                            {(roasterData &&
                              roasterData.sunday &&
                              roasterData.sunday.name) ||
                              "Off"}
                          </td>
                          <td>
                            {(roasterData &&
                              roasterData.monday &&
                              roasterData.monday.name) ||
                              "Off"}
                          </td>
                          <td>
                            {(roasterData &&
                              roasterData.tuesday &&
                              roasterData.tuesday.name) ||
                              "Off"}
                          </td>
                          <td>
                            {(roasterData &&
                              roasterData.wednesday &&
                              roasterData.wednesday.name) ||
                              "Off"}
                          </td>
                          <td>
                            {(roasterData &&
                              roasterData.thursday &&
                              roasterData.thursday.name) ||
                              "Off"}
                          </td>
                          <td>
                            {(roasterData &&
                              roasterData.friday &&
                              roasterData.friday.name) ||
                              "Off"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    height: "250px",
                    overflowX: "auto",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>Leave Details</CardTitle>
                  <div>
                    <Table className="table table-striped table-bordered mb-0">
                      <thead>
                        <tr style={{ height: "64px" }}>
                          <th>Leave Name</th>
                          <th>Alloted</th>
                          <th>Used</th>
                          <th>Remaining</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ height: "55px" }}>
                          <td>Annual Leave</td>
                          <td>
                            {leaveDetails &&
                              leaveDetails.allotted_leave.annual_leave}
                          </td>
                          <td>
                            {(leaveDetails &&
                              leaveDetails.allotted_leave.annual_leave -
                                leaveDetails.leave.annual_leave) ||
                              0}
                          </td>
                          <td>
                            {(leaveDetails &&
                              leaveDetails.leave.annual_leave) ||
                              0}
                          </td>
                        </tr>
                        <tr>
                          <td>Sick Leave</td>
                          <td>
                            {leaveDetails &&
                              leaveDetails.allotted_leave.sick_leave}
                          </td>
                          <td>
                            {(leaveDetails &&
                              leaveDetails.allotted_leave.sick_leave -
                                leaveDetails.leave.sick_leave) ||
                              0}
                          </td>
                          <td>
                            {(leaveDetails && leaveDetails.leave.sick_leave) ||
                              0}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {role === "Cashier" && (
            <Row>
              <Col lg={12}>
                <DashboardTableStatus />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
