import React from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"

const Pie = (props) => {
  let data = props.data
  let chartdata = [
    { value: 0, name: props.t("Games") },
    { value: 0, name: props.t("Items") },
    { value: 0, name: props.t("Packages") }
  ];
  if (data) {
    chartdata = [
      { name: props.t("Games"), value: data.total_game_sale_amount },
      { name: props.t("Items"), value: data.total_item_sale_amount },
      { name: props.t("Packages"), value: data.total_pkg_sale_amount },
    ];
  }
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [
        props.t("Games"),
        props.t("Items"),
        props.t("Packages"),
      ],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: ["#4471B7", "#EF7E2D","#66C2A5"],
    series: [
      {
        name: props.name,
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: chartdata,          
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withTranslation()(Pie)));
