import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  ModalBody,
  FormGroup,
  ModalHeader,
  Modal,
  CardSubtitle,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import exportFromJSON from "export-from-json"
import paginationFactory from "react-bootstrap-table2-paginator"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"

const AddGames = props => {
  document.title = "Add Game"

  const refContainer = useRef(null)

  const { SearchBar } = Search
  const [isSquareImg, setIsSquareImage] = useState(false)
  const [allGames, setAllGames] = useState([])
  const [image, setImage] = useState()
  const [update_image, setUpdate_image] = useState()
  const [price, setPrice] = useState()
  const [minutes, setMinutes] = useState(60)
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [imageValidation, setImageValidation] = useState(true)


  useEffect(() => {
    loadAllGames()
  }, [])




  const handleDownload = async () => {
    const fileName = "All Game";
    const exportType = "xls";

    let data_to_export = allGames;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Price_for_normal: v.price_for_regular,
          Pprice_for_friend: v.price_for_friend,
          Status: v.is_active ? "Yes" : "No",
          Name: v.name,
          Description: v.description,
          Duration: v.duration,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: allGames && allGames.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  //form submit
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + 'games/insert'
    if (isSquareImg === false) {
      showWarning("Pls upload a square image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc")
      return;
    }   else if(imageValidation === false){
        showWarning('File size is too large. Please choose a smaller file (max 500 KB).');
        return;
      }  else {
      // // setIsSquareImage(false)
      // const branch_details = {
      //   name: "Rendezvous",
      // }
      let formData = new FormData();
      // formData.append('branch_details', JSON.stringify(branch_details));
      formData.set("name", v.name);
      formData.set("description", v.description);
      formData.set("price_for_friend", v.price_for_friend);
      formData.set("price_for_regular", v.price_for_regular);
      formData.set("price_for_vip", 0);
      formData.set("duration", Number(60));
      formData.append("document_file", image);

      postSubmitForm_withformdata(url, formData).then(response => {
        if (response.status === 1) {
          loadAllGames()
          refContainer.current.reset()
          setImage()
          setPrice()
          showNotification(response.message, "Success")
        } else {
          showNotification(response.message, "Error")
        }
      })
    }
  }

  //getall
  const loadAllGames = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "games/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      preloader(false)
      setAllGames(response.data)
      // console.log(response.data);
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image }
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        ></img>
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }

  //Actions
  const [editModal, setEditModal] = useState()
  const [selectedUser, setSelectedUser] = useState()
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedUser(row)
          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
              handleValidDelete(row)
          }}
      ></i> */}
        <i>
          <div className="form-switch ms-2" dir="ltr">
            <input
              type="checkbox"
              style={{height:"15px", width:"30px"}}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>
      </span>
    )
  }

  //Update
  const handleValidUpdate = async (e, v) => {
    if (v.document_file !== "" && isSquareImg === false) {
      // console.log("helllo")
      showNotification("Pls upload a square image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc")
      return;
    } else {
    try {
      let formData = new FormData();
      formData.set("_id", selectedUser._id)
      formData.set("name", v.name);
      formData.set("description", v.description);
      formData.set("price_for_friend", v.price_for_friend);
      formData.set("price_for_regular", v.price_for_regular);
      formData.set("duration", selectedUser.duration?selectedUser.duration:0);
      if (document_file === "") {
        formData.set("document_file", selectedUser.image);
      } else {
        formData.append('document_file', update_image);
      }

      let url = process.env.REACT_APP_BASEURL + "games/update"
      let response = await postSubmitForm_withformdata(url, formData)
      if (response.status === 1) {
        loadAllGames()
        setEditModal(!editModal)
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }}

  //Delete
  const handleValidDelete = async game => {
    let url = process.env.REACT_APP_BASEURL + "games/activate_deactivate_item"
    // let url ="http://localhost:3002/item_category/delete_item_category"
    let response = await postSubmitForm(url, {
      id: game._id,
      is_active: game.is_active === true ? false : true
    })
    if (response.status === 1) {
      loadAllGames()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }


  // function to get resolution of the image from input field
  const handleImageReso = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxSizeInBytes = 277 + (500 * 1024); // 500 KB
      // console.log(maxSizeInBytes,"max");
      // console.log(file.size,"file size");
      if (file.size > maxSizeInBytes) {
        // File size exceeds the limit, display an alert and clear state
        showWarning('File size is too large. Please choose a smaller file (max 500 KB).');
        setImageValidation(false)
       
      } else {
        setImageValidation(true)
        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
            const resolution = {
              width: img.width,
              height: img.height
            };
            if (img.width === img.height) {
              // console.log("setting true");
              setIsSquareImage(true);
            } else {
              // Image resolution is not square, display an alert and clear state
              showWarning("Please upload a square Image. Eg: 400 x 400, 350 x 350, 1000 x 1000 etc");
              setIsSquareImage(false);
              set_document_file(null); // Clear the state by setting it to null
            }
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    }else{
      setIsSquareImage(true);
      setImageValidation(true)
    }
  };
 

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },

      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Description"),
      dataField: "description",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Regular Price"),
      dataField: "price_for_regular",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Friend Price"),
      dataField: "price_for_friend",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Duration"),
      dataField: "duration",
      sort: false,
      formatter: (cell, row) => {
        return row.duration && row.duration + " Minutes"
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Action"),
      dataField: "",
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs 
            title={props.t("Games")} 
            breadcrumbItem1={props.t("Add/Update Games")} 
            alternateTitle={props.t("Add Game")} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                // style={{
                //   color: "white",
                //   background:
                //     "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                //   boxShadow:
                //     "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                // }}

                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>

                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name *")}
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Game Name")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Enter a Valid Game Name")}
                        />

                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="description"
                          label={props.t("Description")}
                          className="form-control"
                          type="textarea"
                          placeholder={props.t("Enter Description")}
                          validate={{ required: { value: false } }}
                          errorMessage={props.t("Description cannot be empty")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3}>

                        <Label>{props.t("Price for Regular *")} </Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="price_for_regular"
                          // label={props.t("Price")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Price")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Enter a Price")}
                        />

                      </Col>

                      <Col lg={3}>

                        <Label>{props.t("Price for Friends *")}  </Label>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="price_for_friend"
                          // label={props.t("Price")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Price")}
                          onChange={(e) => { setIsSquareImage(true); }}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Enter a Price")}
                        />

                      </Col>





                      <Col lg={6}>
                      <Label>{props.t("Upload Image *")}<span style={{fontSize:"10px", color:"red"}}>&nbsp;(max 500kb, square images)</span> </Label>
                        <AvField
                          name="document_file"
                          // label={props.t("Upload Image")}
                          className="btn btn-primary btn-sm"
                          type="file"
                          onChange={e => { setIsSquareImage(false); handleImageReso(e); setImage(e.target.files[0]) }}
                          placeholder={props.t("Uplaod Image")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Upload a Image")}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      
                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "115px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Add Game")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                      

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>

                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Games")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                              style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                              type="submit"
                              className="btn btn-sm btn-secondary"
                              onClick={handleDownload}
                              disabled={allGames.length<0}
                            >
                              <i className="mdi mdi-file-export" /> &nbsp;
                              {props.t("Export CSV")}
                            </Button>
                          </Col>
                    </Row>
                  </CardTitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allGames && allGames}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>

                        
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update  - ")}
                      {selectedUser && selectedUser.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>

                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          value={selectedUser.name}
                                          className="form-control"
                                          type="text"
                                          placeholder={props.t("Enter Game Name")}
                                          validate={{ required: { value: true } }}
                                          errorMessage={props.t("Please Enter a Valid Game Name")}
                                        />

                                      </Col>

                                      <Col lg={6}>
                                        <AvField
                                          name="description"
                                          label={props.t("Description")}
                                          className="form-control"
                                          value={selectedUser.description}
                                          type="textarea"
                                          placeholder={props.t("Enter Description")}
                                          validate={{ required: { value: false } }}
                                          errorMessage={props.t("Description cannot be empty")}
                                        />
                                      </Col>
                                    </Row>

                                    <Row>

                                      <Col lg={6}>

                                        <Label>{props.t("Price for Regular")} </Label>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="price_for_regular"
                                          value={selectedUser.price_for_regular}
                                          className="form-control"
                                          type="number"
                                          placeholder={props.t("Enter Price")}
                                          validate={{ required: { value: true } }}
                                          errorMessage={props.t("Please Enter a Price")}
                                        />

                                      </Col>

                                      <Col lg={6}>

                                        <Label>{props.t("Price for Friends")}  </Label>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="price_for_friend"
                                          value={selectedUser.price_for_friend}
                                          className="form-control"
                                          type="number"
                                          placeholder={props.t("Enter Price")}
                                          // onChange={(e)=>{setIsSquareImage(true);}}
                                          validate={{ required: { value: true } }}
                                          errorMessage={props.t("Please Enter a Price")}
                                        />

                                      </Col>
                                    </Row>

                                    <Row>
                                      {/* <Col lg={6}>
                                      <AvField
                                        name="price"
                                        label={props.t("Price")}
                                        className="form-control"
                                        type="number"
                                        value={selectedUser.price}
                                        placeholder="Enter Price"
                                        validate={{ required: { value: true } }}
                                        errorMessage={props.t("Please Enter a Price")}
                                      />
                                    
                                    </Col> */}

                                      <Col lg={6}>
                                        <AvField
                                          name="document_file"
                                          label={props.t("Upload Image")}
                                          className="btn btn-primary btn-sm"
                                          type="file"
                                          value={selectedUser.document_file}
                                          onChange={e => { setUpdate_image(e.target.files[0]); handleImageReso(e) }}
                                          placeholder={props.t("Uplaod Image")}
                                        //   validate={{ required: { value: true } }}
                                        //   errorMessage={props.t("Please Upload a Image")}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AddGames)))
