import React, { useEffect, useState, useRef } from "react"
//i18n
import { withTranslation } from "react-i18next"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import withRouter from "../components/Common/withRouter"

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"

import { postSubmitForm } from "../helpers/backend_helper"
import SimpleBar from "simplebar-react";
import BackgroundImage from "../assets/images/Background.png"
import { Link } from "react-router-dom"
import moment from "moment"

const Notification = props => {

  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState()

  const role = (localStorage.getItem("role"))

  useEffect(() => {
loadAllNotifications()
  }, [])

//All Notification
const loadAllNotifications = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/get_all"
    let response = await postSubmitForm(url, {})
    if (response.status === 1) {
      setNotifications(response.data)
      // console.log(notifications)
    } else {
      console.log("Error")
    }
  }

  const handleSetNotificationsRead = async () => {
    let url = process.env.REACT_APP_BASEURL + "notifications/set_read"
    const response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      loadAllNotifications()
    } else {
      console.log("Error")
    }
  }
  function findDateInSentence(sentence) {
    const datePattern = /\b(\d{4}[-\/]\d{2}[-\/]\d{2})|(\d{2}[-\/]\d{2}[-\/]\d{4})\b/g;    
    const matches = sentence.match(datePattern);
        if (matches) {
        return matches;
    } else {
        return "No dates found in the sentence.";
    }
}

  const getLink = (notification) => {
    let pageLink = ""
    if (role === "Cashier" || role === "Manager"||role === "Admin"||role==="Owner") {
      switch (notification.action) {
        case "Booking Received":
          pageLink = "/all-booking"
          break;
          case "refund processed successfully": pageLink = `/refund_invoice?booking_id=` + notification.others
          break;
          case "Booking Updated": pageLink = "/add-reservation"
          break;
          case "Check In Successful":pageLink= "/add-reservation"
          break;
          case "Booking Cancel": pageLink = "/add-reservation"
          break;
          case "Check Out Successful": pageLink = "/all-booking"
          break;
          case "Daily Closure with Remarks" : 
          let Date = findDateInSentence(body)
          pageLink = "/daily_closure?Date="+Date
          break;
      }
    }
    if (role === "Kitchen") {
      switch (notification.action) {
        case "Kitchen Order":
          pageLink = "/new-orders?type="+"Kitchen"
          break;
      }
    }
    if (role === "Cafeteria") {
      switch (notification.action) {
        case "Cafe Order":
          pageLink = "/new-orders?type="+"Cafe"
          break;
      }
    }
    return pageLink;
  }


  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Notifications")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody
                  style={{
                    height:"auto",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      {/* <Col lg={6}>
                        {props.t("All Notifications")}
                      </Col> */}

                     
                    </Row>

                  </CardTitle>
                          
              <SimpleBar style={{ height: "auto" }}>
                {notifications &&
                  notifications.map((notification, ind) => {
                    return (
                      <>
                      {notification.action==="refund processed successfully" ? (
                        <a
                          onClick={() => {
                            setMenu(prev => (prev = !prev))
                            handleSetNotificationsRead()
                          }}
                          href={getLink(notification)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-reset notification-item"
                        >
                          <div
                            className={
                              notification.is_read === true
                                ? "d-flex bg-danger text-dark bg-opacity-25"
                                : "d-flex bg-success text-dark bg-opacity-10"
                            }
                          >
                            <div className="avatar-xs me-3">
                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className="bx bx-cart" />
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mt-0 mb-1">
                                {notification.title}
                              </h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-1">{notification.body}</p>
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline"></i>{" "}
                                  {moment(notification.createdAt).fromNow()}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      ):(<Link
                        onClick={() => {
                          setMenu(prev => (prev = !prev))
                          handleSetNotificationsRead()
                        }}
                        to={getLink(notification)}
                        className="text-reset notification-item"
                      >
                        <div
                          className={
                            notification.is_read === true
                              ? "d-flex bg-danger text-dark bg-opacity-25"
                              : "d-flex bg-success text-dark bg-opacity-10"
                          }
                        >
                          <div className="avatar-xs me-3">
                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                              <i className="bx bx-cart" />
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1">
                              {notification.title}
                            </h6>
                            <div className="font-size-12 text-muted">
                              <p className="mb-1">{notification.body}</p>
                              <p className="mb-0">
                                <i className="mdi mdi-clock-outline"></i>{" "}
                                {moment(notification.createdAt).fromNow()}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>)}
                      </>)
                  })}
              </SimpleBar>


           
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(connect(null, {})(withTranslation()(Notification)))
