import React, { useState, useEffect } from 'react';
import './CircularMenu.css';
import quick from "../../assets/images/quick_menu.png"
import { Link } from 'react-router-dom';

const CircularMenu = ({ links, hrf }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const i18nextLng = localStorage.getItem("i18nextLng");

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1024) 
  }

  useEffect(() => {
    handleWindowSizeChange() 
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange) 
    }
  }, [])
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // const handleKeyDown = (e) => {
  //   if (e.altKey && e.key === 'Alt') {
  //     document.addEventListener('keydown', handleKeyDown);
  //     toggleMenu();
  //   }
  // };

  useEffect(() => {
    if (i18nextLng === "qr") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }

  }, [i18nextLng]);


  return (
    <>
      <div className={`overlay ${isOpen ? 'visible' : ''}`} onClick={toggleMenu}></div>
      <div className={`futuristic-menu ${isOpen ? 'open' : ''} ${i18nextLng === 'qr' ? 'rtl' : ''}`}  style={{ width: isOpen || isMobile? '' : '0' }}> 
        <div className={`menu-button ${i18nextLng === 'qr' ? 'menu-button-rtl' : ''}`} onClick={toggleMenu}>
          <div className={`bar glow ${i18nextLng === 'qr' ? 'rtl' : ''}`}>
            <img style={{ width: "35px" }} src={quick} alt='quick menu' />
          </div>
        </div>
        <div className={`menu-items ${i18nextLng === 'qr' ? 'menu-items-rtl' : ''}`}>
          {links.map((link, index) => (
            <div className="menu-item" key={index} >
              <Link to={hrf[index]} onClick={toggleMenu}>
                <span style={{ color: "white" }}>
                  {link}
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CircularMenu;
