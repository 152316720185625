import React, { useState, useRef, useEffect } from "react";
import axios from "axios";


import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    CardTitle,

    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Spinner,
    UncontrolledAccordion

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { postSubmitForm } from "../../helpers/backend_helper";
import BackgroundImage from "../../assets/images/Background.png"
import { showPhotoFormatter } from "../../helpers/SelectImageFormatter";
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll";




const GeneratePO = (props) => {

    const { SearchBar } = Search
    const refContainer = useRef(null)
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
    const [editModal, setEditModal] = useState();
    const [allSuppliers, setAllSuppliers] = useState([]);
    const [accessoryItems, setAccessoryItems] = useState([]);
    const [cafeItems, setCafeItems] = useState([]);
    const [kitchenItems, setKitchenItems] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const [open, setOpen] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [keyChange, setKeyChange] = useState(0);
    const [validationError, setValidationError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingItems, setIsLoadingItems] = useState(true);
    const [branches, setBranches] = useState()
    const [selectedBranch, setSelectedBranch] = useState()

    //All Branch
    const loadAllBranches = async () => {
        let url = process.env.REACT_APP_BASEURL + "branches/getall"
        let response = await postSubmitForm(url, "")
        if (response && response.status === 1) {
            setBranches(response.data)
            // console.log(response.data)
        } else {
            console.log("Error");
        }
    }
    const calculateTotalPrice = () => {
        if (selectedItems.length === 0) {
            return 0; // If there are no items, the total price is 0.
        }

        // Sum up the total_price property for each item in the selectedItems array.
        const total = selectedItems.reduce((acc, item) => acc + item.total_price, 0).toFixed(2);
        // console.log(total, "total price")
        return total;
    };

    const totalBill = calculateTotalPrice();


    const handleQuantityChange = (e, rowIndex, row, editorProps) => {

        const { value } = e.target;
        const updatedData = { ...row };
        const rowData = updatedData;
        rowData.quantity.in_unit = +value;


        editorProps.onUpdate(+value);

        let a = selectedItems;

        if (row.sub_unit === "" || row.sub_unit === null) {

            a.forEach(item => {
                if (item._id === row._id) {

                    item.total_price = +value * rowData.cost_price
                }
            })
        } else {

            a.forEach(item => {
                if (item._id === row._id) {

                    item.total_price = (((+value) * Number(rowData.formula) + Number(rowData.quantity.in_sub_unit)) * (rowData.cost_price / rowData.formula))
                }
            })
        }


        setSelectedItems(prev => { prev = [...a]; return prev })

    };

    const handleSubUnitQuantityChange = (e, rowIndex, row, editorProps) => {
        const { value } = e.target;
        const updatedData = { ...row };
        const rowData = updatedData;

        if (value === '') {
          editorProps.onUpdate(rowData.quantity.in_sub_unit);
          return;
        }
      
        rowData.quantity.in_sub_unit = +value;
        let subUnits = 0;
        let units = 0;
        if (+value >= Number(row.formula)) {
          subUnits = +value % Number(row.formula);
          units = Math.floor(+value / Number(row.formula));
        } else {
          subUnits = +value;
        }
        editorProps.onUpdate(subUnits);
        let a = selectedItems;
        a.forEach(item => {
          if (item._id === row._id) {
            if (+value >= +row.formula) {
              item.quantity.in_unit += units;
              item.total_price = (item.quantity.in_unit * rowData.cost_price) + (subUnits * (rowData.cost_price / rowData.formula));
            } else {
                item.total_price = (item.quantity.in_unit * rowData.cost_price) + (subUnits * (rowData.cost_price / rowData.formula));
            }
          }
        });
        setSelectedItems(prev => {
          prev = [...a];
          return prev;
        });
      };

    const handlePriceChange = (e, rowIndex, row, editorProps) => {

        const { value } = e.target;
        const updatedData = { ...row };
        const rowData = updatedData;
        rowData.price = +value;


        editorProps.onUpdate(+value);

        let a = selectedItems;

        if (row.sub_unit === "" || row.sub_unit === null) {

            a.forEach(item => {
                if (item._id === row._id) {

                    item.total_price = +value * rowData.quantity.in_unit
                }
            })

        } else {

            a.forEach(item => {
                if (item._id === row._id) {
                    item.total_price = +value / rowData.formula * ((Number(rowData.quantity.in_unit) * Number(rowData.formula)) + Number(rowData.quantity.in_sub_unit))
                }
            })
        }
        setSelectedItems(prev => { prev = [...a]; return prev })
    };


    useEffect(() => {
        if (selectedRow) {
            let isPresentFlag = false;
            let isPresent = selectedItems.filter((item) => item._id === selectedRow._id);
            isPresent.length > 0 ? isPresentFlag = true : isPresentFlag = false;

            if (isPresentFlag) {

                let newPayload = selectedItems.filter((item) => item._id !== isPresent[0]._id);
                setSelectedItems(newPayload);
            } else {
                setSelectedItems([...selectedItems, { ...selectedRow }]);
            }
        } else {
            let url = process.env.REACT_APP_BASEURL
            function getAllSuppliers(token) {
                axios.post(
                    url + `suppliers/getall`
                    // `http://localhost:3002/suppliers/getall`
                    , {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then(res => { setIsLoading(prev => prev = false);
                    const filter = res.data.data.filter(supp=>supp.is_active===true);
                     setAllSuppliers(filter); 
                    }).catch(err => console.log(err))
            }

            axios.post(
                url + `items/getall_item`
                // `http://localhost:3002/items/getall_item`
                , {}, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }).then(res => {
                setIsLoadingItems(prev => prev = false)
                let accessory = res.data.data.filter(item => item.is_active !== false && item.section === "Accessories" && (item.is_household_item ===undefined || item.is_household_item !==true))
                setAccessoryItems(accessory);
                let cafe = res.data.data.filter(item => item.is_active !== false && item.section === "Cafe" && (item.is_household_item ===undefined || item.is_household_item !==true))
                setCafeItems(cafe);
                let kitchen = res.data.data.filter(item => item.is_active !== false && item.section === "Kitchen" && (item.is_household_item ===undefined || item.is_household_item !==true))
                setKitchenItems(kitchen);

            }).catch(err => console.log(err))

            getAllSuppliers(userToken);
        }
        loadAllBranches()
    }, [selectedRow, checkboxState, keyChange])

    // function to generate purchase order
    function generatePurchaseOrder() {


        const hasZeroValue = selectedItems.some((item) => item.cost_price === 0 || (+item.quantity.in_unit === 0 && +item.quantity.in_sub_unit === 0));

        // If any row has editable fields with value 0, show validation error
        if (hasZeroValue) {
            setValidationError(true);
            return;
        } else {
            setValidationError(false);
        }



        if (selectedItems.length > 0) {
            // loop to calc sum of all item cost_prcie
            let sum = 0;
            for (let i = 0; i < selectedItems.length; i++) {

                sum += selectedItems[i].total_price
            }


            const payloadPO = {
                po_id: (Math.random() * 1000).toFixed(2),
                items: selectedItems.map(item => item),
                items_total_price: sum,
                deliveryDetails: [],
                supplier: {
                    id: selectedSupplier._id,
                    is_active: selectedSupplier.is_active,
                    name: selectedSupplier.name,
                    contact_person: selectedSupplier.contact_person,
                    mobile: selectedSupplier.mobile,
                    phone: selectedSupplier.phone,
                    email: selectedSupplier.email,
                    // section: selectedSupplier.section,
                    address: selectedSupplier.address,

                },
                branch_details: {
                    branch_id: selectedBranch._id,
                    name: selectedBranch.name,
                    address: selectedBranch.address,
                    email: selectedBranch.email,
                    mobile: selectedBranch.mobile,
                },
                is_email_sent: false,

            }


            let url = process.env.REACT_APP_BASEURL

            axios.post(
                url + `purchase_orders/add_order`
                // `http://localhost:3002/purchase_orders/add_order`
                , payloadPO, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }).then(res => {
                showNotification(res.data.message, res.data.status === 0 ? "Error" : "Success");


                selectedItems.forEach(item => {

                    if (item.section === "Cafe") {

                        setCafeItems(prev => { prev[item.rowInd] = !prev[item.rowInd]; return prev })
                    } else if (item.section === "Accessories") {
                        setAccessoryItems(prev => { prev[item.rowInd] = !prev[item.rowInd]; return prev })
                    } else if (item.section === "Kitchen") {
                        setKitchenItems(prev => { prev[item.rowInd] = !prev[item.rowInd]; return prev })
                    }
                })


                setSelectedItems(prev => prev = []);
                // setSupplierIndex(prev => prev = "")

            }).catch(err => console.log(err))

        } else {
            showNotification("No Items In Table", "Error")
        }

    }

    // ----------------------------------------------------


    // function handle submit
    const handleValidSubmit = async (e, v, token, status) => {
        const object = {
            name: v.supplier_name,
        }
    }



    function editFormatter(cell, row) {
        return (
            <span className="text-info">

                <i
                    className="fas fa-trash ms-3 fs-5 text-danger"
                    title="Click to Delete"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setSelectedItems((prevSelected) => prevSelected.filter(item => item._id !== row._id));
                    }}
                ></i>
            </span>
        )
    }

    // main table columns
    const columns = [

        {
            dataField: "_id",
            hidden: true
        },
        {
            text: props.t("#"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            // dataField: "_id",
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
            editable: false
        },
        {
            text: props.t("Item id"),
            //formatter: employeeFormatter,
            dataField: "_id",
            sort: true,
            hidden: true,
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
            editable: false
        },

        {
            text: props.t("Item"),
            //formatter: employeeFormatter,
            dataField: "name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            editable: false,
        },
        {
            text: props.t("Category"),
            //formatter: employeeFormatter,
            dataField: "category",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            editable: false
        },
        {
            text: props.t("Section"),
            //formatter: employeeFormatter,
            dataField: "section",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            editable: false
        },
        {
            text: props.t("Formula"),
            //formatter: employeeFormatter,
            dataField: "formula",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },

        {
            text: props.t('Qty. (In Unit)'),
            formatter: (cell, row) => {
                return <span style={{ color: row.quantity.in_unit === 0 ? "#E57373" : "", fontWeight: row.quantity.in_unit === 0 ? 600 : 0}} >
                    {`${row.quantity.in_unit} ${row.unit}`}
                    </span>;
            },
            dataField: 'quantity.in_unit',
            // sort: true,
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editable: true,
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{ width: "50px", height: "40px", border: "1px solid lightGray", padding: "5px", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    type="number"
                    border={`1px solid ${value === 0 ? 'red' : 'lightGray'}`}
                    defaultValue={value}
                    onBlur={(e) => {
                        handleQuantityChange(e, rowIndex, row, editorProps);
                    }}
                    onFocus={(e) => e.target.select()}
                    autoFocus
                />
            ),
        },
        {
            text: "Qty. (in sub-unit)",
            dataField: 'quantity.in_sub_unit',
            formatter: (cell, row) => {
                return <span style={{ color: row.quantity.in_sub_unit === 0 ? "#E57373" : "", fontWeight: row.quantity.in_sub_unit === 0 ? 600 : 0}} >
                    {(row.sub_unit === "" || row.sub_unit === null) ? <i className="fa fa-ban" /> : `${row.quantity.in_sub_unit} ${row.sub_unit}`}</span>;
            },
            // sort: true,
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editable: (cell, row) => ((row.sub_unit === "" || row.sub_unit === null) ? false : true),
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{ width: "50px", height: "40px", border: "1px solid lightGray", padding: "5px", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    type="number"
                    border={`1px solid ${value === 0 ? 'red' : 'lightGray'}`}
                    defaultValue={value}
                    onBlur={(e) => {
                        handleSubUnitQuantityChange(e, rowIndex, row, editorProps);
                    }}
                    onFocus={(e) => e.target.select()}
                    autoFocus
                />
            ),
            validator: (newValue, row, column) => {

                // if (     +newValue >     Number(       +row.formula * +row.quantity.in_unit + row.quantity.in_sub_unit     )   ) {
                //   return {
                //     valid: false,
                //     message: "Cannot exceed available quantity",
                //   }
                // }
               
                if (+newValue < 0) {
                  return {
                    valid: false,
                    message: "Cannot be a negative value",
                  }
                }
                if (isNaN(newValue)) {
                  return {
                    valid: false,
                    message: "Only numbers are allowed.",
                  }
                }
                if (newValue % 1 != 0) {
                  return {
                    valid: false,
                    message: "Decimals not allowed.",
                  }
                }
                return true
               }
        },
        {
            text: props.t('Price'),
            dataField: 'cost_price',
            formatter: (cell, row) => {
                return (
                    <p style={{ color: row.cost_price === 0 ? "#E57373" : "", fontWeight: row.cost_price === 0 ? 600 : 0, width: "50px" }}>{row.cost_price}</p>
                )
            },
            sort: true,
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editable: true,

            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
                <input
                    style={{ width: "50px", height: "40px", border: "1px solid lightGray", padding: "5px", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                    type="number"
                    defaultValue={value}
                    // onBlur={(e) => { handlePriceChange(e, rowIndex, row, editorProps); setEnterPriceArr(prev => { prev = { ...prev, [row._id]: row.cost_price === 0 ? false : true } }) }}
                    onBlur={(e) => { handlePriceChange(e, rowIndex, row, editorProps) }}
                    onFocus={(e) => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),

        },
        {
            text: props.t('Total'),
            formatter: (cell, row) => {
                return <p>{row.total_price.toFixed(2)}</p>;
            },
            dataField: 'total_price',
            sort: true,
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editable: false,
        },
        {
            text: props.t("Action"),
            formatter: editFormatter,
            dataField: "",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
            editable: false
        },
    ]
    // ------------------------

    const Items = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            text: "S.N",
            //   headerStyle: (colum, colIndex) => {
            //     return { width: "1%" }
            //   },
        },

        {
            dataField: "image_url",
            text: "Image",
            formatter: showPhotoFormatter,
            //   headerStyle: (colum, colIndex) => {
            //     return { width: "7%" }
            //   },
        },

        {
            dataField: "name",
            text: "Item",
            formatter: (cell, row) => row.name,
            //   headerStyle: (colum, colIndex) => {
            //     return { width: "7%" }
            //   },
        },

        {
            dataField: "section",
            formatter: (cell, row) => row.section,
            sort:true,
            text: "section",
            //   headerStyle: (colum, colIndex) => {
            //     return { width: "3%" }
            //   },
        },

        {
            text: props.t("Available QTY"),
            formatter: (cell, row) => {
                return (
                    (row.sub_unit === "" || row.sub_unit === null) ? row.available.in_unit === 0 ? <p style={{ color: "#E57373", fontWeight: 600 }}> Out of Stock </p> : <p>{row.available.in_unit + " " + row.unit}</p> : (row.available.in_unit === 0 && row.available.in_sub_unit === 0) ? <p style={{ color: "#E57373", fontWeight: 600 }}>Out of Stock</p> : <p>{row.available.in_unit + " " + row.unit + " " + row.available.in_sub_unit + " " + row.sub_unit}</p>
                )
            },
            dataField: "available.in_unit",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
    ]

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectAll: true,
        selected: selectedItems.map(item => item._id),
        onSelect: (row, isSelect, rowIndex, e) => {
            updateSelectedItems(row, isSelect);
        },
    };

    function updateSelectedItems(row, isSelect) {
        setSelectedItems((prevSelected) => {
            if (isSelect) {
                if (!prevSelected.some(item => item._id === row._id)) {
                    return [...prevSelected, row];
                }
            } else {
                return prevSelected.filter(item => item._id !== row._id);
            }
            return prevSelected;
        });
    }


    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",


    };
    // -------------------------------------------------------------------
    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Inventory" alternateTitle="Generate PO" breadcrumbItem1="Generate PO" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v, userToken) }} ref={refContainer}>
                                        <Row>
                                            <Col lg={3}>
                                                <AvField
                                                    label={props.t("Select Supplier")}
                                                    className="form-control"
                                                    type="select"
                                                    name="supplier_name"
                                                    onChange={(e) => { setSelectedSupplier(allSuppliers[e.target.value]) }}
                                                    errorMessage="select supplier"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                >
                                                    <option value="">{props.t("--Select Supplier--")}</option>
                                                    {isLoading && <option value=""><Spinner /></option>}
                                                    {allSuppliers.map((supp, index) => <option key={index + 1} value={index} >{supp.name} </option>)}
                                                </AvField>

                                                {/* </div> */}
                                            </Col>

                                            <Col lg={3}>

                                                <AvField
                                                    label={props.t("Select Branch")}
                                                    className="form-control"
                                                    name="branch_details"
                                                    type="select"
                                                    validate={
                                                        { required: true }
                                                    }
                                                    errorMessage="Please Select Branch"
                                                    onChange={(e) => setSelectedBranch(branches.find(b => b.name === e.target.value))}
                                                >
                                                    <option>{props.t("--Select Branch--")}</option>
                                                    {branches && branches.map((branch) => {
                                                        return (
                                                            <option key={branch._id} value={branch.name}>{branch.name}</option>
                                                        )
                                                    })}


                                                </AvField>

                                                {/* </div> */}
                                            </Col>

                                            <Col md={4} style={{ display: "flex", flexDirection: "column", width: "150px" }}  >
                                                <label style={{ height: "20.5px", width: "50px", }} className="form-label">

                                                </label>
                                                <Button
                                                    type="submit"
                                                    onClick={() => selectedSupplier && selectedBranch ? setEditModal(prev => prev = !prev) : null}
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "115px",
                                                        marginTop: "-0.2rem",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    {props.t("Add Item")}
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>
                                        </Row>

                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{boxShadow:"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}} >
                                    <CardTitle>{props.t("Cart Items")} </CardTitle>
                                    <ToolkitProvider
                                        keyField={"_id"}
                                        // data={selectedItems}
                                        data={selectedItems}
                                        columns={columns}
                                        search
                                    // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                                    >
                                        {propst => (
                                            <div>
                                                <Row>
                                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                        <SearchBar
                                                            className="form-control"
                                                            {...propst.searchProps}
                                                            // style={{ width: "340px" }}
                                                            placeholder="search"
                                                        />
                                                    </Col>
                                                </Row>

                                                <BootstrapTable
                                                    {...propst.baseProps}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                    <Row>
                                        <Col lg={12} style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}>
                                            <span> Total Price :</span><span style={{ color: "black", fontWeight: "bolder" }}>  {totalBill} </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ display: "flex", justifyContent: "center" }}>
      <Button
                                                onClick={() => generatePurchaseOrder()}
                                                style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "100px",
                                                    // marginTop: "-0.2rem",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }}
                                                onMouseEnter={e => {
                                                    e.target.style.transform = "scale(1.05)"
                                                }}
                                                onMouseLeave={e => {
                                                    e.target.style.transform = "scale(1)"
                                                }}
                                            >
                                                {props.t("Submit")}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        background:
                                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                        pointerEvents: "none",
                                                    }}
                                                ></div>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {validationError && (
                                        <Row>
                                            <Col style={{ display: "flex", justifyContent: "center", color: "red" }}>
                                                Please fill Price & Quantity for all Items.
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>


            <Row>
                <Col>
                    <Modal
                        style={{ maxWidth: "750px" }}
                        isOpen={editModal}
                        toggle={() => setEditModal(prev => prev = !prev)}
                    >
                        <ModalHeader toggle={() => setEditModal(prev => prev = !prev)} >
                            {props.t("Add Items")}
                        </ModalHeader>
                        <ModalBody>
                            {isLoadingItems === true ? <Spinner /> : <Row>
                                <Col lg={12}>

                                    {/* -----------------------------ACCORDIANS--------------------------------------------- */}

                                    {/* <Accordion 
                                    open={open} 
                                    flush
                                    toggle={toggle}> */}
                                        <UncontrolledAccordion >

                                        {/* Accessories  */}
                                        <AccordionItem>
                                            <AccordionHeader targetId="1">{props.t("Accessory Items")}</AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <Card>
                                                    <CardBody>

                                                        <ToolkitProvider
                                                            keyField="_id"
                                                            data={accessoryItems}
                                                            columns={Items || []}
                                                            search
                                                        >
                                                            {propst => (
                                                                <div>
                                                                     <Row>
                                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                            <SearchBar
                                                                                className="form-control"
                                                                                {...propst.searchProps}
                                                                                // style={{ width: "340px" }}
                                                                                placeholder="search"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <BootstrapTable
                                                                        {...propst.baseProps}
                                                                        selectRow={selectRow}
                                                                        noDataIndication={props.t("No data to display.")}
                                                                        striped
                                                                        hover
                                                                        condensed
                                                                    />
                                                                </div>
                                                            )}
                                                        </ToolkitProvider>
                                                        {/* <CardTitle></CardTitle> */}
                                                        {/* <ItemTable selectedItems={selectedItems} key={keyChange} setKeyChange={setKeyChange} setCheckboxState={setCheckboxState} setEditModal={setEditModal} setSelectedRow={setSelectedRow} accessoryItems={accessoryItems} checkboxState={checkboxState} editModal={editModal} category={"Accessories"} /> */}
                                                    </CardBody>
                                                </Card>
                                            </AccordionBody>
                                        </AccordionItem>
                                         {/* Cafe  */}
                                        <AccordionItem>
                                            <AccordionHeader targetId="2">{props.t("Cafe Items")}</AccordionHeader>
                                            <AccordionBody accordionId="2">
                                                <Card>
                                                    <CardBody>
                                                        <ToolkitProvider
                                                            keyField="_id"
                                                            data={cafeItems}
                                                            columns={Items || []}
                                                            search
                                                        >
                                                            {propst => (
                                                                <div>
                                                                    <Row>
                                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                            <SearchBar
                                                                                className="form-control"
                                                                                {...propst.searchProps}
                                                                                // style={{ width: "340px" }}
                                                                                placeholder="search"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <BootstrapTable
                                                                        {...propst.baseProps}
                                                                        selectRow={selectRow}
                                                                        noDataIndication={props.t("No data to display.")}
                                                                        striped
                                                                        hover
                                                                        condensed
                                                                    />
                                                                </div>
                                                            )}
                                                        </ToolkitProvider>
                                                        {/* <CardTitle></CardTitle> */}
                                                        {/* <ItemTable selectedItems={selectedItems} key={keyChange} setKeyChange={setKeyChange} setCheckboxState={setCheckbox_1State} setEditModal={setEditModal} setSelectedRow={setSelectedRow} accessoryItems={accessoryItems} checkboxState={checkbox_1State} editModal={editModal} category={"Cafe"} /> */}
                                                    </CardBody>
                                                </Card>
                                            </AccordionBody>
                                        </AccordionItem>
                                         
                                         {/* Kitchen  */}
                                        <AccordionItem>
                                            <AccordionHeader targetId="3">{props.t("Kitchen Items")}</AccordionHeader>
                                            <AccordionBody accordionId="3">
                                                <Card>
                                                    <CardBody>
                                                        <ToolkitProvider
                                                            keyField="_id"
                                                            data={kitchenItems}
                                                            columns={Items || []}
                                                            search
                                                        >
                                                            {propst => (
                                                                <div>
                                                                    <Row>
                                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                            <SearchBar
                                                                                className="form-control"
                                                                                {...propst.searchProps}
                                                                                // style={{ width: "340px" }}
                                                                                placeholder="search"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <BootstrapTable
                                                                        {...propst.baseProps}
                                                                        selectRow={selectRow}
                                                                        noDataIndication={props.t("No data to display.")}
                                                                        striped
                                                                        hover
                                                                        condensed
                                                                    />
                                                                </div>
                                                            )}
                                                        </ToolkitProvider>

                                                    </CardBody>
                                                </Card>
                                            </AccordionBody>
                                        </AccordionItem>
                                        </UncontrolledAccordion>
                                    {/* </Accordion> */}



                                </Col>
                            </Row>}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(GeneratePO)))
