
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Select from 'react-select';
import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Label,
    CardTitle,
    Button,
    Modal,
    ModalHeader,
    ModalBody,

} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"
//i18next
import { connect } from "react-redux"
import showNotification from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import cellEditFactory from 'react-bootstrap-table2-editor';
import preventValueChangeOnScroll from"../../helpers/PreventValueOnScroll"
import { postSubmitForm } from "../../helpers/backend_helper";
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader";


const KitchenWithdraw = (props) => {

    const { SearchBar } = Search
    const refContainer = useRef(null)
    const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
    const [editModal, setEditModal] = useState();
    const [allItems, setAllItems] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedItems, setSelectedItems] = useState([]);;
    const [keyChange, setKeyChange] = useState(0);
    const [checkboxState, setCheckboxState] = useState([]);
    const [validationError, setValidationError] = useState(false);
    const [branch, setBranch] = useState()
    const [selectedBranch, setSelectedBranch] = useState();
    const [customStyles, setCustomStyles] = useState({});


    useEffect(() => {

        setCustomStyles(prev => prev =
        {
            control: (styles) => ({ ...styles, color: "white" }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                isDisabled = (data.available?.in_unit === 0 && data.available?.in_sub_unit === 0) ? true : false
                const color = "white";
                return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? "#f0b4b4"
                        : isSelected
                            ? "white"
                            : isFocused
                                ? "#566ce3"
                                : undefined,
                    color: isFocused ? "white" : "black",
                    cursor: isDisabled ? 'not-allowed' : 'default',
                    ':active': {
                        ...styles[':active'],
                        backgroundColor: !isDisabled
                            ? isSelected
                                ? "white"
                                : "#566ce3"
                            : undefined,
                    },
                };
            },
            multiValue: (styles, { data }) => {
                let color = "";
                if (data.available.in_unit !== 0 && data.available.in_sub_unit !== 0) {
                    color = "#eff2f7";
                } else if (data.available.in_unit === 0 && data.available.in_sub_unit === 0) {
                    color = "#f0b4b4"
                }
                return {
                    ...styles,
                    backgroundColor: color,
                };
            },
            multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: "black",
            }),
            multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: "black",
                ':hover': {
                    backgroundColor: "#f0b4b4",
                    color: 'red',
                },
            }),
        }
        )
    }, [selectedItems])

    useEffect(()=>{
loadAllItems();
    },[])
    

    const loadAllItems = async () => {
        setAllItems([])
        preloader(true)
        let url = process.env.REACT_APP_BASEURL + "bookings/get_available_items"
        let response = await postSubmitForm(url, "")
        if (response && response.status === 1) {
            preloader(false)
              const FilterData = response.data.filter(item=>item.section !== "Accessories" && item.is_for_use===true && item.is_active === true).map((item) => {
                return {
                    ...item, remarks: "", new_available: {
                        in_unit: "",
                        in_sub_unit: ""
                    },
                    label: (item.available.in_unit === 0 && item.available.in_sub_unit === 0) ? `${item.name}  [${item.section}] [Out of Stock]` : `${item.name}-->[${item.section}] `,
                    value: item.name
                }
            })
            setAllItems(FilterData || [] )
        } else {
            preloader(false)
          setLoading(false);
          showNotification(response.message,"Error")
        }
    }
    
    const columnsAccessoryItems = [

        {
            dataField: "_id",
            hidden: true
        },
        {
            text: props.t("#"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
        },
        {
            text: props.t("Item id"),
            dataField: "_id",
            // sort: true,
            hidden: true,
        },
        {
            text: props.t("Image"),
            dataField: "image_url",
            formatter: (cell, row) => {
                // console.log(row)
                return (
                    <img className="avatar-md rounded-circle img-thumbnail" src={row.image_url} alt={row.name + "image"} />
                )
            },
        },
        {
            text: props.t("Item"),
            //formatter: employeeFormatter,
            dataField: "name",
            sort: true,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "8%" }
            // },
        },
        {
            text: props.t("Available QTY"),

            formatter: (cell, row) => {
                return (
                    // console.log(row)
                    row.available.in_unit === 0 && row.available.in_sub_unit === 0 ? <p style={{color:"#E57373", fontWeight:"bold"}}>Out Of Stock</p> :
                    <p>{`${row.available.in_unit} ${row.unit} ${row.sub_unit? row.available.in_sub_unit : ""} ${row.sub_unit ? row.sub_unit : ""}`}</p>
                )
            },
            dataField: "available.in_unit",
            sort: true,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "8%" }
            // },
        },

    ]

    const calculateTotalPrice = () => {
        if (selectedItems.length === 0) {
            return 0; // If there are no items, the total price is 0.
        }

        // Sum up the total_price property for each item in the selectedItems array.
        const total = selectedItems.reduce((acc, item) => acc + item.total_price, 0).toFixed(2);
        // console.log(total, "total price")
        return total;
    };

    const totalBill = calculateTotalPrice();

    //All Branch
    const loadAllBranches = async () => {
        let url = process.env.REACT_APP_BASEURL + "branches/getall"
        let response = await postSubmitForm(url, "")
        if (response && response.status === 1) {
            setBranch(response.data)
        } else {
            console.log("Error");
        }
    }

    const handleQuantityChange = (e, rowIndex, row, editorProps) => {
        const { value } = e.target
        const updatedData = { ...row }
        const rowData = updatedData
        rowData.quantity.in_unit = +value

        editorProps.onUpdate(+value)
        let a = selectedItems;

        a.forEach(item => {
            if (item._id === row._id) {
                if (row.sub_unit === "" || row.sub_unit === null) {
                    item.total_price = +value * row.cost_price
                } else {
                    item.total_price =
                        (+value * Number(rowData.formula) +
                            Number(rowData.quantity.in_sub_unit)) *
                        (row.cost_price / rowData.formula)
                }
            }
        })
        // console.log(a,"a")
        setSelectedItems(prev => { prev = [...a]; return prev })
    };

    const handleSubUnitQuantityChange = (e, rowIndex, row, editorProps) => {
    const { value } = e.target;
    const updatedData = { ...row };
    updatedData.quantity.in_sub_unit = +value;
    
    let subUnits = 0;
    let units = 0;
    
    if (+value >= Number(row.formula)) {
      subUnits = +value % Number(row.formula);
      units = Math.floor(+value / Number(row.formula));
    } else {
      subUnits = +value;
    }
    
    editorProps.onUpdate(subUnits);
    
    let a = [...selectedItems]; 
    
    a.forEach(item => {
      if (item._id === row._id) {
        if (+value >= +row.formula) {
          item.quantity.in_unit += units;
          item.quantity.in_sub_unit = subUnits;
          item.total_price = ((item.quantity.in_unit * Number(row.formula) + subUnits) * (row.cost_price / Number(row.formula)));
        } else {
          item.total_price = ((item.quantity.in_unit * Number(row.formula) + subUnits) * (row.cost_price / Number(row.formula)));
        }
      }
    });
  
    setSelectedItems([...a]); 
  };


    useEffect(() => {

        if (selectedRow) {
            let isPresentFlag = false;
            let isPresent = selectedItems.filter((item) => item._id === selectedRow._id);
            isPresent.length > 0 ? isPresentFlag = true : isPresentFlag = false;

            if (isPresentFlag) {
                let newPayload = selectedItems.filter((item) => item._id !== isPresent[0]._id);
                setSelectedItems(newPayload);
            } else {
                setSelectedItems([...selectedItems, { ...selectedRow }]);
            }
        } 
        loadAllBranches()
    }, [selectedRow, checkboxState, keyChange])




    // function to generate purchase order
    function generateOrder() {
        const hasZeroValue = selectedItems.some((item) => item.quantity.in_unit === 0 && item.quantity.in_sub_unit === 0);
        if (hasZeroValue) {
            setValidationError(true);
        } else {
            setValidationError(false);
            if (selectedItems.length > 0) {
                let sum = 0;
                for (let i = 0; i < selectedItems.length; i++) {
                    sum += selectedItems[i].total_price
                }
                const paylod = {
                    item_details: selectedItems.map(item => {
                        return {
                            item_id: item._id,
                            name: item.name,
                            image_url: item.image_url,
                            section: item.section,
                            unit: item.unit,
                            sub_unit: item.sub_unit,
                            expiry_date: item.expiry_date,
                            formula: item.formula,
                            category: item.category,
                            threshold: item.threshold,
                            price: item.cost_price,
                            total_price: +item.total_price,
                            quantity: {
                                in_unit: +item.quantity.in_unit,
                                in_sub_unit: +item.quantity.in_sub_unit,
                            },
                            available: {
                                in_unit: +item.available.in_unit,
                                in_sub_unit: +item.available.in_sub_unit,
                            },
                            new_available: (() => {
                                const formula = item.formula || 1
                                const availableInSubUnits =item.available.in_unit * formula + item.available.in_sub_unit
                                const quantityInSubUnits = item.quantity.in_unit * formula + item.quantity.in_sub_unit
                                const newAvailableInSubUnits = availableInSubUnits - quantityInSubUnits
                                const newAvailableInUnits = Math.floor(newAvailableInSubUnits / formula)
                                const newAvailableSubUnits = newAvailableInSubUnits % formula
                                return {
                                  in_unit: !item.is_household_item ?  newAvailableInUnits : 0, 
                                  in_sub_unit: !item.is_household_item ? newAvailableSubUnits : 0,
                                }
                              })(),
                        }
                    }
                    ),
                    is_for_use: true,
                    branch_details: {
                        branch_id: localStorage.getItem("role") === "Owner" ? selectedBranch._id : branch[0]?._id,
                        name: localStorage.getItem("role") === "Owner" ? selectedBranch.name : branch[0]?.name,
                        address: localStorage.getItem("role") === "Owner" ? selectedBranch.address : branch[0]?.address,
                        email: localStorage.getItem("role") === "Owner" ? selectedBranch.email :branch[0]?.email,
                        mobile: localStorage.getItem("role") === "Owner" ? selectedBranch.mobile :branch[0]?.mobile,
                    },
                    order_created_by: localStorage.getItem("name"),
                    order_price: +totalBill,
                    remarks: "",
                   
                }
               preloader(true)
                let url = process.env.REACT_APP_BASEURL
                axios.post(
                    url + `internal_orders/raise_request`
                    // `http://localhost:3002/item_orders/insert`
                    , paylod, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }).then(res => {
                    preloader(false)
                    showNotification(res.data.message, res.data.status === 0 ? "Error" : "Success");                    
                    loadAllItems();
                    setSelectedItems(prev => { prev = []; return prev });
                    setCustomerName(null)
                    refContainer.current.reset()
                    setSelectedRow(null)
                    setSelectedBranch(prev => prev = "")
                }).catch((err) => {preloader(false);console.log(err)})
            } else {
                preloader(false)
                showNotification("No Items In Table", "Error")
            }
        }
    }

    const handleValidSubmit = () => {

    }

    function editFormatter(cell, row) {
        return (
            <span className="text-info">
                <i
                    className="fas fa-trash ms-3 fs-5 text-danger"
                    title="Click to Delete"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setSelectedRow(prev => prev = { ...row })
                       if (row.section === "Accessories") {

                            setCheckboxState(prev => { prev[row.rowInd] = !prev[row.rowInd]; return prev })
                        }
                    }}
                ></i>
            </span>
        )
    }

    

    const columns = [

        {
            dataField: "item_id",
            hidden: true
        },
        {
            text: props.t("#"),
            formatter: (cell, row, rowIndex) => {
                return rowIndex + 1
            },
            headerStyle: (colum, colIndex) => {
                return { width: "2%" }
            },
            editable: false
        },
        {
            text: props.t("Item id"),
            dataField: "item_id",
            sort: true,
            hidden: true,
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
            editable: false
        },
        {
            text: props.t("Image"),
            formatter: (cell, row) => {
                return (
                    <img className="avatar-md rounded-circle img-thumbnail" src={row.image_url} alt={row.name + "image"} />
                )
            },
            dataField: "image_url",
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
            editable: false
        },
        {
            text: props.t("Item"),
            dataField: "name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            editable: false,
        },
        {
            text: props.t("Section"),
            dataField: "section",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "4%" }
            },
            editable: false
        },
        {
            text: props.t("Category"),
            dataField: "category",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            editable: false
        }, 
        {
            text: props.t("Formula"),
            dataField: "formula",
            sort: false,
            editable: false,
            formatter: (cell, row) => {
              return (
                <>
                  {row.sub_unit
                    ? "1 " + row.unit + " = " + row.formula + " " + row.sub_unit
                    : "1 " + row.unit}
                </>
              )
            },
            headerStyle: (colum, colIndex) => {
              return { width: "7%" }
            },
          },
      
          {
            dataField: "available.in_unit",
            text: "Available ",
            formatter: (cell, row) => {
                return (
                  <>
                    {row.available?.in_unit +
                      "  " +
                      row.unit +
                      " " +
                      (row.sub_unit && row.sub_unit != "undefined"
                        ? row.available.in_sub_unit + " " + row.sub_unit
                        : "")}
                  </>
                )
            
            
            },
            headerStyle: (colum, colIndex) => {
              return { width: "7%" }
            },
            editable: false,
          },
        {
            text: props.t('Quantity In Unit'),
            dataField: 'quantity.in_unit',
            formatter: (cell, row) => {
                return <p style={{ color: row.quantity.in_unit === 0 ? "#E57373" : "", fontWeight: row.quantity.in_unit === 0 ? 600 : 0}} >{`${row.quantity.in_unit} ${row.unit}`}</p>;
            },
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                        <input
                        onWheel={preventValueChangeOnScroll}
                            style={{
                                width: "50px",
                                height: "40px",
                                border: "1px solid lightGray",
                                padding: "5px",
                                borderRadius: "5px",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            }}
                            type="number"
                            defaultValue={value}
                            onBlur={(e) => {
                                handleQuantityChange(e, rowIndex, row, editorProps);
                                if (+e.target.value > +row.available.in_unit)  return
                                editorProps.onUpdate(+e.target.value)
                            }}
                            onFocus={(e) => e.target.select()} // Retain focus on input field
                            autoFocus
                        />
               
                ),
                validator: (newValue, row, column) => {
                    if (+newValue > Number(row.available.in_unit)) {
                      return {
                        valid: false,
                        message: "Cannot exceed available units",
                      };
                    }
                
                    if (+newValue < 0) {
                      return {
                        valid: false,
                        message: "Cannot be a negative value",
                      };
                    }
                    if (isNaN(newValue)) {
                      return {
                        valid: false,
                        message: "Only numbers are allowed.",
                      };
                    }
                    if (newValue % 1 != 0) {
                      return {
                        valid: false,
                        message: "Decimals not allowed.",
                      };
                    }
                  return true;
                }, 
        
        },
        {
            text: props.t('Qty. (in sub unit)'),
            formatter: (cell, row) => {
                return <p style={{ color: row.quantity.in_sub_unit === 0 ? "#E57373" : "", fontWeight: row.quantity.in_sub_unit === 0 ? 600 : 0, width: "50px" }} >{`${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>;
            },
            dataField: 'quantity.in_sub_unit',
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editable: (cell, row) => ((row.sub_unit === "" || row.sub_unit === null) ? false : true),
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => {
                const availableItems = row.available;
                const isAvailable = availableItems && availableItems.in_unit > 0 || availableItems.in_sub_unit;

                if (isAvailable) {
                    return (
                        <input
                            style={{
                                width: "50px",
                                height: "40px",
                                border: "1px solid lightGray",
                                padding: "5px",
                                borderRadius: "5px",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            }}
                            type="number"
                            defaultValue={value}
                            onBlur={e => {
                                handleSubUnitQuantityChange(e, rowIndex, row, editorProps)
                            }}
                            onFocus={e => e.target.select()} // Retain focus on input field
                            autoFocus
                        />
                    );
                } else {
                    return <div style={{ color: "red" }}>Not Available</div>;
                }
            },
            validator: (newValue, row, column) => {
                if (     +newValue >     Number(       +row.formula * +row.available.in_unit + +row.available.in_sub_unit     )   ) {
                  return {
                    valid: false,
                    message: "Cannot exceed available quantity",
                  }
                }
                if (
                  +newValue >
                  Number(
                    (+row.available.in_unit - +row.quantity.in_unit) * +row.formula +
                      +row.available.in_sub_unit
                  )
                ) {
                  return {
                    valid: false,
                    message: "Cannot exceed more",
                  }
                }
                if (+newValue < 0) {
                  return {
                    valid: false,
                    message: "Cannot be a negative value",
                  }
                }
                if (isNaN(newValue)) {
                  return {
                    valid: false,
                    message: "Only numbers are allowed.",
                  }
                }
                if (newValue % 1 != 0) {
                  return {
                    valid: false,
                    message: "Decimals not allowed.",
                  }
                }
                return true
               }
        },
        {
            text: props.t('Cost Price'),
            dataField: 'cost_price',
            sort:true,
            formatter: (cell, row) => {
                return (
                    <>
                        {row.cost_price + " " + "/" + "" + row.unit}
                    </>
                )
            },
            headerStyle: (column, colIndex) => {
                return { width: '8%' };
            },
            editable: false,
        },
        {
            text: props.t('Total'),
            dataField: 'total_price',
            sort: true,
            formatter: (cell, row) => {
                return <p>{row.total_price?.toFixed(2)}</p>;
            },
            headerStyle: (column, colIndex) => {
                return { width: '4%' };
            },
            editable: false,
        },
        {
            text: props.t("Action"),
            formatter: editFormatter,
            dataField: "",
            // sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
            editable: false
        },
    ]
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: selectedItems.map(item => item._id),
        hideSelectAll: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            updateSelectedItems(row, isSelect);
        },
        nonSelectableStyle: { backgroundColor: 'gray' },
        nonSelectable: (row) => {
            return row.available?.in_unit === 0 || row.available?.in_sub_unit === 0;
        }
    };

    function updateSelectedItems(row, isSelect) {
        setSelectedItems((prevSelected) => {
            if (isSelect) {
                if (!prevSelected.some(item => item._id === row._id)) {
                    return [...prevSelected, row];
                }
            } else {
                return prevSelected.filter(item => item._id !== row._id);
            }
            return prevSelected;
        });
    }

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };


    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Inventory" breadcrumbItem1="Kitchen Withdraw" alternateTitle="Kitchen Withdraw" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody

                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v, userToken) }} ref={refContainer}>
                                        <Row >


                                          {localStorage.getItem("role") !== "Owner" &&  <Col lg={4} style={{ color: "black" }}>

                                                <Label style={{ color: "white" }}>{props.t("Select Items")}</Label>
                                                <Select
                                                    isDisabled={selectedBranch === null}
                                                    placeholder={props.t("Select Items")}
                                                    value={selectedItems}
                                                    isSearchable={true}
                                                    classNamePrefix="select"
                                                    options={allItems}
                                                    styles={customStyles}
                                                    onChange={(selected, singleValue) => {
                                                        if (singleValue.action === "clear") {
                                                            setSelectedItems(prev => prev = []);
                                                            return;
                                                        }
                                                        if (singleValue.action === "remove-value") {
                                                            let newPayload = selectedItems.filter(item => singleValue.removedValue.name !== item.name);
                                                            setSelectedItems(prev => prev = [...newPayload]);
                                                            return;
                                                        }
                                                        (singleValue?.option?.available?.in_unit === 0 && singleValue?.option?.available?.in_sub_unit === 0) ? "" : setSelectedItems(prev => prev = [...selected])
                                                    }}
                                                    isMulti
                                                />
                                            </Col>}

                                            {localStorage.getItem("role") === "Owner" && <Col lg={4}>
                                                <AvField
                                                    label={props.t("Branch")}
                                                    className="form-control"
                                                    name="branch_details"
                                                    type="select"
                                                    onChange={(e) => setSelectedBranch(branch.find(b => b.name === e.target.value))}
                                                >
                                                    <option>{props.t("--Select Branch--")}</option>
                                                    {branch && branch.map((branch) => {
                                                        return (
                                                            <option key={branch._id} value={branch.name}>{branch.name}</option>
                                                        )
                                                    })}


                                                </AvField>
                                            </Col>}


                                            {localStorage.getItem("role") === "Owner" && <Col lg={4} >

                                                <Button
                                                    type="submit"
                                                    onClick={() => setEditModal(prev => prev = !prev)}
                                                    disabled={!selectedBranch}
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "100px",
                                                        marginTop: "1.58rem",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    {props.t("Show")}
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                                {/* </div> */}
                                            </Col>}
                                        </Row>
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <CardTitle>{props.t("Cart Item")} </CardTitle>
                                    <ToolkitProvider
                                        keyField="_id"
                                        data={selectedItems}
                                        columns={columns}
                                        search
                                    // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                                    >
                                        {propst => (
                                            <div>
                                                <Row>
                                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                        <SearchBar
                                                            className="form-control"
                                                            {...propst.searchProps}
                                                            // style={{ width: "340px" }}
                                                            placeholder="search"
                                                        />
                                                    </Col>
                                                </Row>
                                                <BootstrapTable
                                                    {...propst.baseProps}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                    <Row>
                                        <Col lg={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            Total Price : &nbsp; <span style={{ color: "black", fontWeight: "bolder" }}>  {totalBill} </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ display: "flex", justifyContent: "center" }}>


                                            <Button
                                                onClick={() => generateOrder()}
                                                style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "117px",
                                                    // marginTop: "-0.2rem",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }}
                                                onMouseEnter={e => {
                                                    e.target.style.transform = "scale(1.05)"
                                                }}
                                                onMouseLeave={e => {
                                                    e.target.style.transform = "scale(1)"
                                                }}
                                            >
                                                {props.t("Withdraw")}
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        left: "0",
                                                        right: "0",
                                                        bottom: "0",
                                                        background:
                                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                        pointerEvents: "none",
                                                    }}
                                                ></div>
                                            </Button>
                                        </Col>
                                    </Row>

                                    {/* Display validation error message if necessary */}
                                    {validationError && (
                                        <Row>
                                            <Col style={{ display: "flex", justifyContent: "center", color: "red" }}>
                                                {props.t("Please fill  all required fields.")}
                                            </Col>
                                        </Row>
                                    )}
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>


            <Row>
                <Col>
                    <Modal
                        size="lg"
                        isOpen={editModal}
                        toggle={() => setEditModal(prev => prev = !prev)}
                    >
                        <ModalHeader toggle={() => setEditModal(prev => prev = !prev)} >
                            {props.t("Select Items")}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg={12}>

                                    <Card>
                                        <CardBody>
                                            {/* <CardTitle></CardTitle> */}
                                            {/* <KitchenItemTable selectedItems={selectedItems} key={keyChange} setKeyChange={setKeyChange} setCheckboxState={setCheckbox_2State} setEditModal={setEditModal} setSelectedRow={setSelectedRow} allItems={allItems} checkboxState={checkbox_2State} editModal={editModal} category={"Accessories"} /> */}
                                     
                                            <ToolkitProvider
                            keyField={"_id"}
                            data={allItems||[]}
                            columns={columnsAccessoryItems}
                            search
                        >
                            {propst => (
                                <div>
                                    <Row>
                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                            <SearchBar
                                                className="form-control"
                                                {...propst.searchProps}
                                                placeholder="search"
                                            />
                                        </Col>
                                    </Row>

                                    <BootstrapTable
                                        {...propst.baseProps}
                                        selectRow={selectRow}
                                        noDataIndication={props.t("No data to display.")}
                                        striped
                                        hover
                                        condensed
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>


            {/* <CustomerTable key={keyChange} setCheckboxState={setCheckboxState} setEditModal={setEditModal} setSelectedRow={setSelectedRow} allItems={allItems} checkboxState={checkboxState} editModal={editModal} /> */}


        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(KitchenWithdraw)))

