import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm} from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"; 
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"


const PaymentMethods = props => {

  const editorRef = useRef()
 const { SearchBar } = Search

  const refContainer = useRef(null)
  const [isSquareImg, setIsSquareImage] = useState(false)
  const [document_file_update, setDocument_file_update] = useState()
  const [allMethods, setAllMethods] = useState([])
  const [document_file, set_document_file] = useState()
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedRow, setSelectedRow] = useState();



  const handleDownload = async () => {
    const fileName = "All Payment Methods";
    const exportType = "xls";
  
    let data_to_export = allMethods;
  
    if(data_to_export){
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
        data.push({
         Name: v.name
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  } 


  //for formula 


  useEffect(()=>{
    loadAllPaymentMethods();
  },[])
  

 
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: selectedRow && selectedRow.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


//load All Coupons

  const loadAllPaymentMethods = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "payment_methods/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false)
      setAllMethods(response.data)
    } else {
      preloader(false)
//showNotification(response.message, "Error")
    }
  }

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];
    set_document_file(file);
  };

  const [editModal, setEditModal] = useState()
  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedRow(row)
          }}
        ></i>
        
      </span>
    )
  }

   // function to get resolution of the image from input field
   const handleImageReso = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const resolution = {
            width: img.width,
            height: img.height
          };
          if (img.width === img.height) {
            // console.log("setting true")
            setIsSquareImage(true)
          }
          // console.log('Image resolution:', resolution);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };


  // Insert
  const handleValidSubmit = async (e, v) => {
   let url = process.env.REACT_APP_BASEURL + "payment_methods/insert"

     let formData = new FormData;
     formData.set("name",v.name);
     formData.append("document_file",document_file);
    postSubmitForm(url,formData).then(response =>{
    if(response.status === 1){
        loadAllPaymentMethods();
        refContainer.current.reset();
        showNotification(response.message, "Success")
    }
    else {
        showNotification(response.message, "Error")
    }
})
  }

  //Update
  const handleValidUpdate = async (e, v) => {

      try {
        let formData = new FormData();
        formData.set("_id",selectedRow._id)
        formData.set("name",v.name)
        formData.set("document_file",document_file_update)
        if (document_file === "") {
          formData.set("document_file", selectedRow.image_url);
        } else {
          formData.set("document_file", document_file_update);
        }
      
        let url = process.env.REACT_APP_BASEURL + "payment_methods/update"
        let response = await postSubmitForm(url, formData)
        if (response.status === 1) {
          loadAllPaymentMethods()
          setEditModal(!editModal)
          showNotification(response.message, "Success")
        } else {
          showNotification(response.message, "Error")
        }
      } catch (error) {
        showNotification(error.message, "Error")
      }
  }

 


 //for round image view
 function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.document_file || row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }


  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize +(+rowIndex +1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
        text: props.t("Image"),
        formatter: showPhotoFormatter,
        dataField: "image_url",
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
   
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
};


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Payment Methods" breadcrumbItem1="Payment Methods" />


           <Row>
           <Col lg={12}>
             <Card>
               <CardBody  
                
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
               >
                 <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                   <Row>
                     <Col lg={6}>
                    
                         <AvField
                           label={props.t("Name")}
                           className="form-control"
                           type="text"
                           placeholder="Enter Payment method"
                           onChange={()=>setIsSquareImage(true)}
                           name="name"
                           validate={{ required: { value: true } }}
                           errorMessage={props.t("Name cannot be empty")}
                         />
                   
                     </Col>

                     <Col lg={3}>
                      

                      <AvField
                        label={props.t("Upload Image")}
                        className="btn btn-primary btn-sm"
                        name="document_file"
                        type="file"
                        onChange={(e) => {setIsSquareImage(false); handleImageReso(e)   ; handleDocumentChange(e);  }}
                        validate={{
                          required: { value:true},
                        }}
                        errorMessage={props.t("Please Upload Image")}
                       >
                        {props.t("Upload File")}
                      </AvField>

                  </Col>
                     

                   </Row>
                

                  <hr />
                   <Row>

                     
                       <Col lg={12} className="d-flex justify-content-center">
                      
                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "120px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Add Method")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                       </Col>
                       
                    
                   </Row>
                 </AvForm>
               </CardBody>
             </Card>
           </Col>
         </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Methods")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}

                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={allMethods.length<0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>

                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all Methods")}
                  </CardSubtitle>
                  <ToolkitProvider 
                    keyField="_id"
                    columns={columns}
                    data={allMethods && allMethods}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable 
                         
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

               

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update - ")}
                      {selectedRow && selectedRow.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedRow && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate} >
                                  <Row>
                                      <Col lg={6} >
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          type="text"
                                          value={selectedRow?.name}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Change Image")}
                                          className="btn btn-primary btn-sm"
                                          name="document_file"
                                          type="file"
                                          onChange={e => { setDocument_file_update(e.target.files[0]);}}

                                        >
                                          {props.t("Upload File")}
                                        </AvField>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                                 
                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "120px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>

         
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(PaymentMethods)))
