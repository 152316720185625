import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
  Table,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import swal from "sweetalert2"

const Salary_Slip = props => {
  // useState variables...
  const [allEmployees, setAllEmployees] = useState([])
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [employeeData, setEmployeeData] = useState([])

  const [year, setYear] = useState("")
  const [showHeading, setShowHeading] = useState(false)
  const [month, setMonth] = useState("")
  const [printModal, setPrintModal] = useState()
  // -----------------------------------------------------------------------------------------

  const { SearchBar } = Search
  //meta title
  document.title = "Add Users | Rendezvous - Game Parlour"

  // useEffect(() => {

  // }, [])

  const refContainer = useRef(null)

  useEffect(() => {
    // console.log(employeeData, "employee data")
    let url = process.env.REACT_APP_BASEURL

    axios
      .post(
        url + `employees/getall`,
        { is_active: true },
        {
          headers: {
            Authorization: `Bearer ${employeeToken}`,
          },
        }
      )
      .then(res => {
        console.log(res, "employee data")
        setAllEmployees(res.data.data)
      })
      .catch(err => console.log(err))
  }, [employeeData])

  // -------------------------------------------------------------------------------------
  function handleGetData(payload, token) {
    let url = process.env.REACT_APP_BASEURL

    axios
      .post(url + `generatesalaries/get_by_employee_id`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        console.log(res)
        showNotification(
          res.data.message,
          res.data.status === 0 ? "Error" : "Success"
        )
        setShowHeading(true)
        setEmployeeData(res.data.data.employee_details[0])
      })
      .catch(err => console.log(err))
  }
  // ---------------------------------------------------------------------------------------------------

  // console.log(totalAllow, totalDed, grossSalary, netSalary, "totals of salaries")

  const handleValidSubmit = async (e, v) => {
    // let url = process.env.REACT_APP_BASEURL + "adminusers/inser"
    // let formData = new FormData();
    // formData.set("month", v.month)
    // // formData.set("username", v.username);
    // // formData.set("pwd", v.pwd);
    // // formData.set("role", v.role);
    // console.log(formData,"form data")

    const object = {
      date: v.month + "-01",
      employee_id: v.employee_id,
    }

    // console.log("Date", object)

    const months = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    }

    // getting previous months date
    var currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - 1)
    var year = currentDate.getFullYear()
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0")
    var day = currentDate.getDate().toString().padStart(2, "0")

    var formattedDate = year + "-" + month + "-" + "01"

    setYear(year)
    setMonth(months[object.date.slice(5, 7)])

    // console.log(Number(object.date.slice(5,7)))
    if (
      object.date === formattedDate ||
      Number(object.date.slice(5, 7)) < Number(month)
    ) {
      handleGetData(object, employeeToken)
    } else {
      showNotification(_, "Salary data not present yet.")
    }

    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error hello")
    //   }
    // })
  }

  const handlePrint = () => {
    const content = document.getElementById("forPrint")
    const printWindow = window.open(
      "",
      "_blank",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    )
    printWindow.document.write(`
        <html>

        <head>
            <title>Salary Slip</title>
        </head>
        
        <body>
        
        <div style="border:5px solid black; padding:10px" >
        <h2 style="text-align:center" >Salary Slip for ${month} ${year}</h2>
        
        
                <div style="display:flex;gap:10px; width:100%; margin-bottom: 20px;">
        
        
        
        
                    <table style="width:100%; border-collapse:collapse; text-align: center;"  border="1px">
                        <thead>
                            <tr>
                                <th colSpan="4">Employee Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="font-weight: bold;" >Employee Name/Code</td>
                                <td>${
                                  employeeData.employee_name +
                                    "/" +
                                    employeeData.employee_code ||
                                  "no value present"
                                }</td>
                                <td style="font-weight: bold;" >Designation</td>
                                <td>${
                                  employeeData.designation || "no value present"
                                }</td>
                                </tr>
                            <tr>
                                <td style="font-weight: bold;" >Date Of Joining</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.date_of_joining &&
                                    employeeData.date_of_joining.slice(
                                      0,
                                      10
                                    )) ||
                                  "no value present"
                                }</td>
                                <td style="font-weight: bold;" >Date of Birth</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.date_of_birth &&
                                    employeeData.date_of_birth.slice(0, 10)) ||
                                  "no value present"
                                }</td>
                            </tr>
                            <tr>
                            <td style="font-weight: bold;" >Basic Salary(BS)</td>
                            <td>${
                              employeeData.basic_salary || "no value present"
                            }</td>
                            <td style="font-weight: bold;" >Number Of Days</td>
                            <td>${
                              employeeData.number_of_days || "no value present"
                            }</td>
                            </tr>
                        </tbody>
                    </table>
        
        
        
        
        
                   
        
                </div>
        
        
                <div style="display:flex; gap:10px;">
        
        
                    <table style="width:50%;border-collapse:collapse; text-align: center;" border="1px">
                        <thead>
                            <tr>
                                <th colSpan="2">Allowances</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
        
                                <td style="width:50%">Food Allowance</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.allowance &&
                                    employeeData.allowance.food) ||
                                  0
                                }</td>
                            </tr>
        
                            <tr>
        
                                <td style="width:50%">Housing Allowance</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.allowance &&
                                    employeeData.allowance.housing) ||
                                  0
                                }</td>
                            </tr>
        
                            <tr>
                                <td style="width:50%">Overtime Allowance</td>
        
                                <td>${
                                  (employeeData &&
                                    employeeData.allowance &&
                                    employeeData.allowance.overtime) ||
                                  0
                                }</td>
                            </tr>
        
                            <tr>
                                <td style="width:50%">Other Allowance</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.allowance &&
                                    employeeData.allowance.other) ||
                                  0
                                }</td>
        
                            </tr>
        
                            <tr>
        
                                <td style="width:50%">Arrear Allowance</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.allowance &&
                                    employeeData.allowance.arrear) ||
                                  0
                                }</td>
                            </tr>
        
                            <tr>
        
                                <td style="width:50%">Special Allowance</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.allowance &&
                                    employeeData.allowance.special) ||
                                  0
                                }</td>
                            </tr>
                            <tr>
        
        
                            </tr>
                            <tr>
        
                                <td style="width:50%; font-weight: bold;">Total Allowance(TA)</td>
                                <td style="font-weight: bold;" >${
                                  (employeeData &&
                                    employeeData.total_allowance) ||
                                  0
                                }</td>
                            </tr>
        
                        </tbody>
                    </table>
        
        
        
        
        
        
                    <table style="width:50%; border-collapse:collapse; text-align: center;" border="1px">
                        <thead>
                            <tr>
                                <th colSpan="2">Deductions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  >
                                <td style="width:50%">Loan</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.deduction &&
                                    employeeData.deduction.loan) ||
                                  0
                                }</td>
                            </tr>
                            <tr  >
                                <td style="width:50%">Undertime</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.deduction &&
                                    employeeData.deduction.undertime) ||
                                  0
                                }</td>
                            </tr>
                            <tr  >
                                <td style="width:50%">Punishment</td>
                                <td>${
                                  (employeeData &&
                                    employeeData.deduction &&
                                    employeeData.deduction.punishment) ||
                                  0
                                }</td>
                            </tr>
                            <tr  >
        
                            </tr>
                            <tr  >
                                <td style="width:50%; font-weight: bold; ">Total Deductions(TD)</td>
                                <td style="font-weight: bold;">${
                                  (employeeData &&
                                    employeeData.total_deduction) ||
                                  0
                                }</td>
                            </tr>
                        </tbody>
                    </table>
        
        
        
        
        
        
        
        
                </div>
        
                <div style="display:flex; gap:10px" >
        
                    <table style="width:100%; text-align: center; border-collapse:collapse; margin:auto; margin-top: 50px;" border="1px">
                        <thead>
                            <tr>
                                <th style=" width:25%" >Net Salary(BS+TA-TD)</th>
                                <th style="width:25%" >${
                                  (employeeData && employeeData.net_salary) || 0
                                }</th>
                                
                            </tr>
                        </thead>
                        
                    </table>

                    <table style="width:100%; text-align: center; border-collapse:collapse; margin:auto; margin-top: 50px;" border="1px">
                        <thead>
                            <tr>
                                
                                <th style=" width:25%" >Net Salary RoundOff</th>
                                <th style="width:25%;">${
                                  (employeeData &&
                                    employeeData.net_salary_round_off) ||
                                  0
                                }</th>
                            </tr>
                        </thead>
                        
                    </table>
        
        
                </div>
        
        
            </div>
        
        </body>
        
        </html>
        `)
    printWindow.document.close()
    printWindow.print()
    printWindow.focus()
  }
  // ---------------------------------------------------------------

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Salary")}
            alternateTitle={props.t("View Salary")}
            breadcrumbItem1={props.t("View Salary")}
          />

          {/* <Row style={{ border: "1px solid black" }} > */}
          <Col>
            <Card>
              <CardBody
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
              >
                <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                  <Row>
                    <Col lg={3}>
                      <div className="mx-2">
                        <label className="form-label">
                          {props.t("Select Employee")}
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          name="employee_id"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Select Employee")}
                        >
                          <option value="">{props.t("Select Employee")}</option>

                          {allEmployees &&
                            allEmployees.map(emp => (
                              <option value={emp._id} key={emp._id}>
                                {emp.name}
                              </option>
                            ))}
                        </AvField>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className="mx-2">
                        <label className="form-label">
                          {props.t("View Salary Slip by Month")}
                        </label>
                        <AvField
                          className="form-control"
                          // type="select"
                          // tag={Flatpickr}
                          type="month"
                          name="month"
                          placeholder="select month"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Select Month")}
                          // dateFormat="F Y"
                          // options={{

                          //     onDayCreate: (dObj, dStr, fp, dayElem) => {
                          //         dayElem.addEventListener('mousedown', (e) => {
                          //             e.preventDefault(); // Prevent default click behavior
                          //         });
                          //     },
                          //     dateFormat: 'M Y', // Display format for selected date
                          //     // minDate: 'today', // Disable previous dates (optional)

                          // }}
                        />
                      </div>
                    </Col>
                    <Col
                      md={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                      }}
                    >
                      <label
                        style={{ height: "20.5px", width: "50px" }}
                        className="form-label"
                      ></label>
                      <Button
                        type="submit"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "100px",
                          marginTop: "-0.2rem",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Show")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>
                    </Col>

                    <Col style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        style={{ height: "20.5px" }}
                        className="form-label"
                      ></label>
                      <Button
                        onClick={handlePrint}
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#36B94D",
                          border: "none",
                          color: "white",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "100px",
                          marginTop: "-0.2rem",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Print")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
          {/* </Row> */}

          {/* <Row style={{ marginTop: '30px' }} >
                        <Col>
                            <Button style={{ width: "100px", backgroundColor: "#7e7e7e" }} type="submit"
                                // color="primary"
                                // className="btn btn-info btn-md"
                                onClick={handlePrint}
                            >
                                Print
                            </Button>
                        </Col>
                    </Row> */}

          <Card style={{ marginTop: "10px", paddingTop: "10px" }} id="forPrint">
            <CardBody
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              }}
            >
              {showHeading && (
                <CardTitle>
                  <h2
                    style={{ textAlign: "center" }}
                  >{`Salary Slip for ${month}  ${year}`}</h2>
                </CardTitle>
              )}

              <Row>
                <Col style={{ marginTop: "30px" }}>
                  <Row>
                    <Col>
                      <Table
                        id="table"
                        border="1px"
                        style={{ textAlign: "center" }}
                      >
                        <thead>
                          <tr>
                            <th colSpan="4">Employee Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              Employee Name/Code
                            </td>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              {(employeeData &&
                                employeeData.employee_name &&
                                employeeData.employee_name +
                                  "/" +
                                  employeeData.employee_code) ||
                                ""}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              Designation
                            </td>
                            <td>{employeeData.designation}</td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              Date Of Joining
                            </td>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              {employeeData &&
                                employeeData.date_of_joining &&
                                employeeData.date_of_joining.slice(0, 10)}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              Date of Birth
                            </td>
                            <td>
                              {employeeData &&
                                employeeData.date_of_birth &&
                                employeeData.date_of_birth.slice(0, 10)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              Basic Salary{"(BS)"}
                            </td>
                            <td style={{ borderRight: "1px solid lightGray" }}>
                              {employeeData.basic_salary}
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "25%",
                              }}
                            >
                              Number Of Days
                            </td>
                            <td style={{ width: "25%" }}>
                              {employeeData.number_of_days}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>

                {/* <Col style={{ marginTop: "30px" }} >

                                    <Row>
                                        <Col>
                                            <Table border="1px" >
                                                <thead>
                                                    <tr style={{ textAlign: "center" }} >
                                                        <th colSpan="2" >Employee Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr>
                                                        <td style={{ borderRight: "1px solid lightGray", fontWeight: "bold", width: "50%" }} >Date of Birth</td>
                                                        <td style={{ textAlign: "right" }} >10 July 2000</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderRight: "1px solid lightGray", fontWeight: "bold", width: "50%" }} >Designation</td>
                                                        <td style={{ textAlign: "right" }} >Operator</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderRight: "1px solid lightGray", fontWeight: "bold", width: "50%" }} >Basic Salary{"(BS)"}</td>
                                                        <td style={{ textAlign: "right" }} >10 QAR</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Col> */}
              </Row>

              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Table border="1px" style={{ textAlign: "center" }}>
                        <thead>
                          <tr style={{ textAlign: "center" }}>
                            <th colSpan="2">Allowances</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Food{" "}
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.food) ||
                                0}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Housing
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.housing) ||
                                0}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Overtime
                            </td>

                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.overtime) ||
                                0}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Other
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.other) ||
                                0}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Arrear
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.arrear) ||
                                0}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Special
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.special) ||
                                0}
                            </td>
                          </tr>
                          <tr style={{ borderBottom: "1px solid lightGray" }}>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Loan Arrear
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.allowance &&
                                employeeData.allowance.loan_arrear) ||
                                0}
                            </td>
                          </tr>

                          <tr style={{ border: "1px solid lightGray" }}>
                            <td
                              style={{
                                fontWeight: "bold",
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Total Allowance(TA)
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {(employeeData && employeeData.total_allowance) ||
                                0}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row>
                    <Col>
                      <Table border="1px" style={{ textAlign: "center" }}>
                        <thead>
                          <tr>
                            <th colSpan="2" style={{ textAlign: "center" }}>
                              Deductions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Loan
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.deduction &&
                                employeeData.deduction.loan) ||
                                0}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Undertime
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.deduction &&
                                employeeData.deduction.undertime) ||
                                0}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderRight: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Punishment
                            </td>
                            <td style={{}}>
                              {(employeeData &&
                                employeeData.deduction &&
                                employeeData.deduction.punishment) ||
                                0}
                            </td>
                          </tr>
                          <tr style={{ height: "180px" }}></tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                border: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              Total Deduction(TD)
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                border: "1px solid lightGray",
                                width: "50%",
                              }}
                            >
                              {(employeeData && employeeData.total_deduction) ||
                                0}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Table>
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid lightGray",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <td
                          style={{
                            fontWeight: "bold",
                            width: "25%",
                            borderRight: "1px solid lightGray",
                          }}
                        >
                          Net Salary(BS + TA - TD)
                        </td>
                        <td
                          style={{
                            width: "25%",
                            borderRight: "1px solid lightGray",
                          }}
                        >
                          {(employeeData && employeeData.net_salary) || 0}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col>
                  <Table>
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid lightGray",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        <td
                          style={{
                            borderRight: "1px solid lightGray",
                            width: "25%",
                          }}
                        >
                          Net Salary RoundOff
                        </td>
                        <td style={{ width: "25%" }}>
                          {(employeeData &&
                            employeeData.net_salary_round_off) ||
                            0}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {/* -------------------------SALARY DETAILS--------------------------------------------- */}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Salary_Slip)))
