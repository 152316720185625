import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";

const SearchItem = (props) => {


    const [query, setQuery] = useState('');
    const [result,setResult] = useState([])
    const token = localStorage.getItem("userToken")

    const debounceDelay = 500; // Adjust the debounce delay as per your requirement

    const { cameWithRow,selectedPO,setPromotionalGoods, results, setResults, selectedFreeItems, setSelectedFreeItems, userToken } = props;
// console.log(result);

   
    useEffect(() => {
        // Function to fetch results using the API
        const fetchResults = async () => {
            const payload = { name: query }
            axios.post(
                `https://api-rendezvous.mhsinfotech.qa/purchase_orders/search_item`,
                //`http://localhost:3002/purchase_orders/search_item`,
                 payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => { console.log(res); setResult(res.data.data); }).catch(err => console.log(err));
        };

        // Debounce the API call to avoid making requests on every keystroke
        const debounceTimer = setTimeout(() => {
            fetchResults();
        }, debounceDelay);

        // Clear the timer when the component is unmounted or the query changes
        return () => clearTimeout(debounceTimer);
    }, [query]);

    const handleInputChange = (newValue) => {
    //    console.log(newValue);
        setQuery(newValue);
    };

    const handleOptionSelect = (e, selectedOption) => {
  
    //   console.log(selectedOption,"opions")
    };

    return (
        <Select
        isMulti
        
           // inputValue={query}
            onInputChange={handleInputChange}
            options={result.map((result) => ({ label: result.name, value: result.id }))}
            onChange={(e) => handleOptionSelect(e, results)}
        />
    );
};

export default SearchItem;