import { SHIFT_API_ERROR, SUBMIT_SHIFT, SUBMIT_SHIFT_SUCCESS } from "./actionTypes";

const initialState= {
    error:null,
    loading:false,
};

const shift = (state = initialState, action) => {
    switch (action.type){
         case SUBMIT_SHIFT:
            return{
                ...state,
                loading:true,
                error:null,
            };
            case SUBMIT_SHIFT_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    error:null,
                    shift:action.payload,
                };
                case SHIFT_API_ERROR:
                    return{
                        ...state,
                        loading:false,
                        error:action.payload,
                    };
                default:
                    state = { ...state };
    }
    return state;
};

export default shift;