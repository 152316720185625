import React from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"

const GamePie = (props) => {
  let data = props.data
  
  let chartdata = [
    { value: 0, name: props.t("Bowling") },
    { value: 0, name: props.t("Billiard") },
    { value: 0, name: props.t("Carrom") },
    { value: 0, name: props.t("Card") },
    { value: 0, name: props.t("Snooker vip") },
    { value: 0, name: props.t("snooker") },
    { value: 0, name: props.t("Table tennis") },
    { value: 0, name: props.t("PC Steering Wheell") },
    { value: 0, name: props.t("Diamond billiard") },
    { value: 0, name: props.t("PC") },
    { value: 0, name: props.t("play station 5") },
    { value: 0, name: props.t("Foosball") },

  ];
  if (data) {
    chartdata = [
      { name: props.t("Bowling"), value: data.bowling },
      { name: props.t("Billiard"), value: data.billiard },
      { name: props.t("Carrom"), value: data.carrom },
      { name: props.t("Card"), value: data.card },
      { name: props.t("Snooker vip"), value: data.snooker_vip },
      { name: props.t("snooker"), value: data.snooker },
      { name: props.t("Table tennis"), value: data.table_tennis },
      { name: props.t("PC Steering Wheell"), value: data.pc_steering_wheel },
      { name: props.t("Diamond billiard"), value: data.diamond_billiard },
      { name: props.t("PC"), value: data.pc },
      { name: props.t("play station 5"), value: data.play_station_5 },
      { name: props.t("Foosball"), value: data.foosball },

    ];
  }
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [
        props.t("Bowling"),
        props.t("Billiard"),
        props.t("Carrom"),
        props.t("Card"),
        props.t("Snooker vip"),
        props.t("snooker"),
        props.t("Table tennis"),
        props.t("PC Steering Wheell"),
        props.t("Diamond billiard"),
        props.t("PC"),
        props.t("play station 5"),
        props.t("Foosball"),
    
      ],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: ["#FF5733", "#3366FF", "#FFCC33", "#66FF99", "#33567F", "#33FFCC", "#9595DC","#33FF66", "#CC33FF", "#E77C29" ,"#0B9AA2","#A78B45"],
    series: [
      {
        name: props.name,
        type: "pie",
        radius: "55%",
        center: ["60%", "60%"],
        data: chartdata,          
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withTranslation()(GamePie)));
