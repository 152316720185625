import { LOAD_ROSTER,LOAD_ROSTER_SUCCESS,LOAD_ROSTER_ERROR } from "./actionTypes";
  
  const initialState = {
    roster: {},
    isLoading: false,
    isError: false
  };
  
  const employeeRoster = (state = initialState, action) => {

    const {type,payload} = action;
    switch (type) {
      
      case LOAD_ROSTER:
        state = {
          ...state,
          isLoading: true,
        };
        break;
      case LOAD_ROSTER_SUCCESS:
        state = {
          ...state,
          isLoading: false,
          roster: payload,
        };
        break;
  
      case LOAD_ROSTER_ERROR:
        state = {
          ...state,
          isLoading: false,
          isError: true
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default employeeRoster;
  