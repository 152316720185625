import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
import moment from "moment"
import logo from "../../assets/images/Logo.png"
import preloader from "../../helpers/Loader"
// import Flatpickr from "react-flatpickr"
const CostingReport = props => {

    const { SearchBar } = Search

    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [section, setSection] = useState("");
    const [fromdate, setFromdate] = useState(new Date())
    const [todate, setTodate] = useState(new Date())
    const code = localStorage.getItem("code")
    const [costingReport, setCostingReport] = useState([])
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

    const handleDownload = async () => {
        const fileName = "Costing Report";
        const exportType = "xls";

        let data_to_export = section !== ""
            ? costingReport.filter(item => item.item_details?.section === section)
            : costingReport
        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    Po_ID: v.po_id,
                    Price: v.items_total_price,
                    Status: v.po_status,
                    Supplier:v.supplier?.name,
                    Date: v.createdAt ? moment(v.createdAt).format("DD-MM-YYYY") : "",
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    useEffect(() => {
        handleValidSubmit()
    }, [])


    // fetch data
    const handleValidSubmit = async () => {
        let dateFrom = moment(fromdate);
        let dateTo = moment(todate);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
        preloader(true)
        const payload = {
            date_from: moment(fromdate).format("YYYY-MM-DD"),
            date_to: moment(todate).format("YYYY-MM-DD"),
        }
        let url = process.env.REACT_APP_BASEURL + "reports/costing"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            preloader(false)
            setCostingReport(prev => prev = response.data)
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
    }

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const imageHeight = "105px";
        const imageWidth = "110px";
        const borderradius = "50%";
        const background = "#000000";
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 140px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Costing Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function () {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };


    const handleOpenPurchaseOrder = (po_id ) => {
        if (po_id) {
          return (<>
           <i
            style={{ color: "blue", cursor: "pointer", justifyContent: "center", display: 'flex' }}>
            <a href={"/Purchase-order?po_id=" + po_id } target="_blank" rel="noreferrer">
              {po_id}
            </a>
            </i>
            </>
          )
        }
        return <span style={{ color: "gray" }}>Not available</span>
    }


    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("#"),
            footer:false,
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
        },
        {
            text: props.t("PO-ID"),
            dataField: "po_id",
            sort: true,
            formatter: handleOpenPurchaseOrder ,
            footer:false,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Po Status"),
            dataField: "po_status",
            sort:true,
            footer:false
        },

        {
            text: props.t("Supplier"),
            dataField: "supplier.name",
            sort: true,
            filter: selectFilter({
                options: () => {
                  const unique = [
                    ...new Map(
                      [
                        ...new Set(
                          costingReport.map(({ supplier:value }) => ({
                            value: value.name,
                            label: value.name,
                          }))
                        ),
                      ].map((item) => [item["value"], item])
                    ).values(),
                  ];
                  unique.sort(dynamicSort("label"));
                  return unique;
                },
              }),
            footer:false
        },
        {
            text: props.t("Date"),
            dataField: "createdAt",
            formatter:(cell,row)=>{
                return moment(row.createdAt).format("YYYY-MM-DD")
            },
            sort: true,
            footer:false
        },
        {
            text: props.t("Price"),
            dataField: "items_total_price",
            formatter:(cell,row)=>{
                return row.items_total_price.toFixed(2)
            },
            sort: false,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
        },

    ]


    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
          <>
            {/* {row.po_status === "Partially Delivered" && ( */}
            <Card >
              <div className="row "  >
                <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                     
                  {row.delivery_details.length > 0 && (
                    <CardBody>
                      <div className="table-responsive ">
                        <CardTitle>
                          <h5>Received Items</h5>
                        </CardTitle>
                        <table
                          id="example"
                          className="table table-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead className="bg-light">
                            <tr>
                              <th>S.N</th>
                              <th>Name</th>
                              <th>Received Quantity</th>
                              <th>Received date</th>
                              <th>Sub Total(QR)</th>
                            </tr>
                          </thead>
                          <tbody style={{ backgroundColor: "white" }}>
                            {row?.delivery_details.map((item, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>
                                  {item.receive.in_unit} &nbsp; {item.unit} &nbsp;{" "}
                                  {item.receive.in_sub_unit
                                    ? item.receive.in_sub_unit
                                    : null}{" "}
                                  &nbsp; {item.sub_unit}{" "}
                                </td>
                                <td>
                                  {moment(item?.received_date).format("DD-MM-YYYY")}
                                </td>
                                <td>{item?.total_price.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  )}
                </div>
              </div>
            </Card>
            {/* )} */}
          </>
        ),
      }

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };


    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="PO Costing Report" breadcrumbItem="Costing Report" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "black",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row >
                                            <Col md={3}>
                                                <label style={{ color: "white" }} className="form-label">
                                                    {props.t("From Date")}
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    options={{
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        setFromdate(new Date(dateString))
                                                        const date = new Date(dateString);
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1;
                                                        const year = date.getFullYear();
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)
                                                    }}
                                                    value={fromdate}
                                                    name="date_from"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col md={3} >

                                                <label style={{ color: "white" }} className="form-label">
                                                    {props.t("To Date")}
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        setTodate(new Date(dateString))
                                                        const date = new Date(dateString);
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1;
                                                        const year = date.getFullYear();
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                                    }}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}
                                                    name="date_to"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>



                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "128px",
                                                    marginTop: "1rem",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }} type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                  <div id="printDiv">
                                        <ToolkitProvider
                                            keyField="_id"
                                            columns={columns}
                                            data={costingReport && costingReport || []}
                                            noDataIndication={props.t("No data to display.")}
                                            search

                                        >
                                            {propst => (
                                                <div>
                                                    <AvForm>
                                                        <Row className="hide">
                                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                <SearchBar {...propst.searchProps} />
                                                            </Col>
                                                            <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={() => { printDiv("printDiv") }}
                                                    disabled={costingReport.length <= 0}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={costingReport.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                    <BootstrapTable

                                                        {...propst.baseProps}
                                                        filter={filterFactory()}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        expandRow={expandRow}
                                                        hover
                                                        condensed
                                                    // pagination={paginationFactory(options)}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>





                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(CostingReport)))
