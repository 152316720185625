import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const ExpiredItems = props => {

    const editorRef = useRef()
    const { SearchBar } = Search
    const [section, setSection] = useState("");
    //meta title
    // document.title = "Add Item | Rendezvous - Game Parlour"
    const refContainer = useRef(null)

    const handleDownload = async () => {
        const fileName = "Expired_Items";
        const exportType = "xls";

        // let data_to_export = expiredItems;
        let data_to_export = section!=="" 
        ? expiredItems.filter(item=>item.section===section) 
        : expiredItems

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v,index) {
                data.push({
                    // Serial_Number: index+1,
                    Batch_Number: v.batch,
                    Item_Name: v.item_details.name,
                    Expiry_Date: moment(v.expiry_date).format('YYYY-MM-DD'),
                    Avaialble_Quantity: `${v.item_detail.available.in_unit} ${v.item_details.unit} ${v.item_details.sub_unit? v.item_details.available.in_sub_unit : ""} ${v.item_details.sub_unit ? v.item_details.sub_unit : ""}`
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    function formatDate(date) {
        if (!date) return null
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
    }

   
   

    const [expiredItems, setExpiredItems] = useState([])
    const [selecteditem, setSelectedItem] = useState()
    const [selectedItems, setSelectedItems] = useState([])
    const [editModal, setEditModal] = useState()
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [selectedCoupon, setSelectedCoupon] = useState();

    useEffect(() => {
        loadAllExpiredItems()
    }, [])

    // console.log(selectedItems,"sI");



    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: selectedCoupon && selectedCoupon.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };


   
    const loadAllExpiredItems = async () => {
        preloader(true)
        let url = process.env.REACT_APP_BASEURL + "reports/expired_items"
        const response = await postSubmitForm(url, {})
        if (response && response.status === 1) {
            preloader(false)
            // console.log(response.data,"expired iexpiredItems  setExpiredItems(response.data)")
            setExpiredItems(prev => prev = response.data)
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
    }

  

    

    

    //Update
    const handleUpdate = async (e,v) => {
        let url = process.env.REACT_APP_BASEURL + "item_inventory/update_expiry"
        const payload ={
            iteminventory_id: selecteditem._id,
            expiry_date: v.expiry_date
        }
        // console.log(payload,"pl");
        // return;
        let response = await postSubmitForm(url, payload)
        if (response.status === 1) {
            setEditModal(!editModal)
            setSelectedItem()
            showNotification("Success", "Success")
            loadAllExpiredItems()
        } else {
            showNotification(response.message, "Error")
        }
    }

    //Discard
    const handleDiscard = async () => {
        let url = process.env.REACT_APP_BASEURL + "item_inventory/discard"
        const payload = {
            iteminventory_ids: selectedItems,
        }
        // console.log(payload,"SelectedItems");
        // return
        let response = await postSubmitForm(url, payload)
        if (response.status === 1) {
            setSelectedItems([prev=>!prev])
            showNotification(response.message, "Success")
            loadAllExpiredItems()
        } else {
            showNotification(response.message, "Error")
        }
    }


     //Action
  function editFormatter(cell, row) {
    return (
    <div className="no-select">
          <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-edit fs-4 mt-1"
          title="Update Expiry"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            // console.log(row);
             e.stopPropagation()
            setEditModal(!editModal)
            setSelectedItem(row)
            // setPackage_Item_Details(row.item_details)
            // setPackage_Game_Details(row.game_details)
          }}
        />

      </span>
    </div>
    )
  }



    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("#"),
            // headerStyle: (colum, colIndex) => {
            //     return { width: "1%" }
            // },
        },
        {
            text: props.t("Batch"),
            dataField: "batch",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Item Name"),
            dataField: "item_details.name",
            sort: false,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "5%" }
            // },
        },
        {
            text: props.t("Section"),
            dataField: "section",
            filter: selectFilter({
                style:{ marginLeft:"5px"},
                options: [
                  { value: "Accessories", label: "Accessories" },
                  { value: "Cafe", label: "Cafe" },
                  { value: "Kitchen", label: "Kitchen" },
                ],
                placeholder: "ALL",
                onFilter: (value) => {
                    setSection( value )
                  } 
              }),
            // headerStyle: (colum, colIndex) => {
            //     return { width: "5%" }
            // },
        },
      
        {
            text: props.t("Quantity"),
            dataField: "expiry_date",
            formatter: (cell, row) => {
                return  <p>{`${row.item_details.available.in_unit} ${row.item_details.unit} ${row.item_details.sub_unit? row.item_details.available.in_sub_unit : ""} ${row.item_details.sub_unit ? row.item_details.sub_unit : ""}`}</p>
            },
            sort: true,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "5%" }
            // },
        },
        {
            text: props.t("Expiry Date"),
            dataField: "expiry_date",
            formatter: (cell, row) => {
                return  moment(row.expiry_date).format('YYYY-MM-DD')
            },
            sort: true,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "5%" }
            // },
        },
        {
            dataField: "",
            text: props.t("Action"),
            formatter: editFormatter,
            sort: false,
            // headerStyle: (colum, colIndex) => {
            //   return { width: "2%" }
            // },
          },
      
    ]

   

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        // style: { backgroundColor: '#c8e6c9' },
        hideSelectAll: true,
        selected: selectedItems?.map(item => item._id),
        onSelect: (row, isSelect, rowIndex, e) => {
            updateSelectedItems(row, isSelect);
        },
        // nonSelectable: [columns.findIndex(column => column.text === 'Action')],
       
    };


    function updateSelectedItems(row, isSelect) {
        setSelectedItems((prevSelected) => {
            if (isSelect) {
                if (!prevSelected?.some(item => item._id === row._id)) {
                    return [...prevSelected, row];
                }
            } else {
                return prevSelected?.filter(item => item._id !== row._id);
            }
            return prevSelected;
        });
    }


    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Inventory" breadcrumbItem1="Expired Items" alternateTitle="Expired Items"/>

                   
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={8}>
                                                {props.t("All Expired Items")}
                                            </Col>

                                            <Col  style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "green", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={expiredItems.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                     

                                         {selectedItems.length > 0 ? (
                                               <Button
                                                   style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" ,marginLeft:"10px"}}
                                                   type="submit"
                                                   className="btn btn-sm btn-secondary"
                                                   onClick={()=>handleDiscard()}
                                                   disabled={expiredItems.length <= 0}
                                               >
                                                   <i className="bx bxs-trash" /> &nbsp;
                                                   {props.t("Discard Items")}
                                               </Button>
                                        

                                         ) : null}
                                         </Col>
                                        </Row>
                                    </CardTitle>
                                    {/* <CardSubtitle className="mb-3">
                                        {props.t("View all expired items")}
                                    </CardSubtitle> */}
                                    <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={expiredItems && expiredItems}
                                        search

                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row>
                                                        <Col lg={3} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: ""}} />
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    // striped
                                                    selectRow={selectRow}
                                                    // hover
                                                    condensed
                                                    pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal
            size="md"
            isOpen={editModal}
            toggle={() => setEditModal(prev => prev = !prev )}
          >
            <ModalHeader toggle={() => setEditModal(prev => prev = !prev )} >
              {props.t("Batch") }&nbsp;<span style={{fontWeight:"bold"}}>{selecteditem && selecteditem?.batch} </span>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e,v) => {
                          handleUpdate(e,v)
                        }}
                      >

                      <Row>
                      <Col>
                      <Label style={{fontSize:"15px"}}>{props.t("Current Expiry Date") }
                      <span style={{marginLeft:"10px", fontWeight:"bold"}}>
                      {selecteditem?.expiry_date ? moment(selecteditem.expiry_date).format("DD-MM-YYYY") : ""}
                      </span></Label>
                      </Col>
                      </Row>

                        <Row className="mt-4 " style={{fontSize:"15px"}}>
                          <Col>
                            <Label >{props.t("Change Expiry Date")}</Label>
                            <AvField
                              className="form-control"
                              type="select"
                              tag={Flatpickr}
                            //   onChange={console.log(selecteditem,"SSSS")}
                              options={{
                                minDate: 'today',
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                    dayElem.addEventListener('mousedown', (e) => {
                                        e.preventDefault(); // Prevent default click behavior
                                    });
                                },
                            }}
                            //   value={selecteditem && selecteditem?.expiry_date || 0}
                              
                              name="expiry_date"
                              placeholder="YYYY-MM-DD"
                              errorMessage={props.t("Expiry Date cannot be empty")}
                              validate={{
                                required: { value:  true  },
                              }}
                            />
                          </Col>

                        </Row>

                        <Row style={{ display: "flex", justifyContent: "center", marginTop: "30px" }} >
                          <Col md={6} style={{ display: "flex", justifyContent: "center" }}  >

                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                          </Col>
                        </Row>


                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(ExpiredItems)))
