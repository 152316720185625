import React, { useState, useRef, useEffect } from "react"
import { Card,Spinner, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm, AvValidator } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json" 
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"; 
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

const UsedInventory = props => {

  const [isLoading, setIsLoading] = useState(true);
 
  const { SearchBar } = Search
  // meta title
  document.title = "Item Inventory | Rendezvous - Game Parlour"
  const refContainer = useRef(null)

  const handleDownload = async () => {
    const fileName = "All Cafe Items";
    const exportType = "xls";
  
    let data_to_export = allInventoryItems;
  
    if(data_to_export){
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
        data.push({
          Name: v.name,
          Unit: v.unit,
          Sub_Unit: v.sub_unit,
          Formula : v.formula,
          Threshold: v.threshold,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  } 

  //Format Date
  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  
  const [allInventoryItem, setAllInventoryItem] = useState()


 
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allInventoryItem && allInventoryItem.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  
 

  useEffect(() => {
    loadAllInventoryItem();
  }, [])


  const [allInventoryItems, setAllInventoryItems] = useState([])
  // console.log(allInventoryItems)
  const loadAllInventoryItem = async () => {
    let url = process.env.REACT_APP_BASEURL + "kitchen_item_order/getall"
    //let url = "http://localhost:3002/item_inventory/get_all_items"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      // console.log(response.data);
      // const filter = response.data.filter(status => status.item_details.order_status==="Completed")
      setAllInventoryItems(response.data);
      // console.log(response.data)
      setIsLoading(prev => prev = false)
    } else {
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  const [Row2, setRow2] = useState([])
//  console.log(allInventoryItem)
  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-file font-size-15"
          title="View Details"
          style={{ cursor: "pointer", color:"blue" }}
          onClick={() => {
            setEditModal(!editModal)
            setAllInventoryItem(row)
            // console.log(row.item_details)
         //   setRow2(prev => prev = [...prev,row.quantity_in_every_batch])
         setRow2(row.item_details)
          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
      </span>
    )
  }

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        ></img>
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }
 



  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize +(+rowIndex +1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    // {
    //   text: props.t("Image"),
    //   formatter: showPhotoFormatter,
    //   dataField: "item_details.image",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    {
      text: props.t("Order ID"),
      dataField: "order_id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Order Created By"),
      dataField: "order_created_by.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //     text: props.t("Available"),
    //     dataField: "total_quantity_in_unit" ,
    //     sort: false,
    //     formatter: (cell, row) => {
    //       return (
    //       <>
    //           {row.total_quantity_in_unit + "  " + row.unit +" "+ row.total_quantity_in_sub_unit + " " + row.sub_unit}
    //       </>
    //       );
    //     },
    //     headerStyle: (colum, colIndex) => {
    //       return { width: "5%" }
    //     },
    //   },
    // {
    //   text: props.t("Threshold"),
    //   dataField: "threshold",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "2%" }
    //   },
    // },
    // {
    //     text: props.t("Formula"),
    //     dataField: "formula",
    //     sort: false,
    //     formatter: (cell,row)=>{
    //         return (
    //             <>
    //             {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
    //             </>
    //         )
    //     },
    //     headerStyle: (colum, colIndex) => {
    //       return { width: "5%" }
    //     },
    //   },
    {
        text: props.t("Cost"),
        dataField: "order_price",
        sort: false,
        formatter:(cell,row)=>{
          return (
            <>
            {row.order_price ? row.order_price : 0}
            </>
          )
        },
        headerStyle: (colum, colIndex) => {
          return { width: "3%" }
        },
      },
    // {
    //     text: props.t("Expiry Date"),
    //     dataField: "expiry_date",
    //     formatter:(cell, row) => {
    //      const date = row.expiry_date;
    //      const formattedDate = moment(date).format('DD-MM-YYYY')
    //      return formattedDate;
    //     },
    //    // sort: false,
    //     headerStyle: (colum, colIndex) => {
    //       return { width: "5%" }
    //     },
    //   },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
  ]

  //Detail View
  const itemDetails = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize +(+rowIndex +1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
   
    {
      text: props.t("Items"),
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

     {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "item_details.image",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
        text: props.t("Quantity"),
        dataField: "quantity" ,
        sort: false,
        formatter: (cell, row) => {
          return (
          <>
              {row.quantity.in_unit + "  " + row.unit +
              " "+ row.quantity.in_sub_unit + " " + row.sub_unit}
          </>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },

    // {
    //     text: props.t("Expiry Date"),
    //     dataField: "expiry_date",
    //     formatter:(cell, row) => {
    //      const date = row.expiry_date? row.expiry_date : null;
    //     const  formattedDate = formatDate(date) 
    //      return formattedDate;
    //     },
    //    // sort: false,
    //     headerStyle: (colum, colIndex) => {
    //       return { width: "5%" }
    //     },
    //   },
      // {
      //   text: props.t("Entry Date"),
      //   dataField: "entry_date",
      //   formatter:(cell, row) => {
      //    const date = row.entry_date;
      //    const formattedDate = moment(date).format('YYYY-MM-DD')
      //    return formattedDate;
      //   },
       // sort: false,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: "5%" }
      //   },
      // },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Inventory" breadcrumbItem1="Used Inventory"/>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={4}>
                      {props.t("Used Inventory Items")}
                      </Col>

                      <Col lg={4}>
                      {props.t("Branch")}
                      </Col>
                     


                     
                    </Row>
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                    {props.t("View Used Inventory Items")}
                  </CardSubtitle> */}
                  <ToolkitProvider 
                    keyField="_id"
                    columns={columns}
                    data={allInventoryItems && allInventoryItems}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                            {/* <Col lg={4}>
                              <Input
                                label={props.t("Select Branch")}
                                style={{ width: "340px" }}
                                className="form-control"
                                type="select"
                                name="branch"
                                errorMessage="Please Select Branch"
                                validate={{
                                  required: { value: true },
                                }}
                              >
                                <option value="">{props.t("All")}</option>
                                <option value="Rendezvous">{props.t("Rendezvous")}</option>
                              </Input>                    
                            </Col> */}
                          </Row>
                        </AvForm>
                        <BootstrapTable 
                         
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="xl"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Item Details - ")}
                      {allInventoryItem && allInventoryItem.order_id}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                <ToolkitProvider 
                                    keyField="_id"
                                    columns={itemDetails}
                                    data={Row2}
                                   

                  >
                    {propst => (
                      <div>
                        <BootstrapTable 
                         
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(UsedInventory)))
