


import { LOAD_ROSTER_OWNER,LOAD_ROSTER_SUCCESS_OWNER,LOAD_ROSTER_ERROR_OWNER } from "./actionTypes";
import {
  loadRosterSuccessOwner,
  loadRosterErrorOwner
} from "./action";

import { takeEvery, put, call } from "redux-saga/effects";
import { postSubmitForm } from "../../helpers/backend_helper";



function* getOwnerRosterData({ payload }) {

  let url = process.env.REACT_APP_BASEURL
  // console.log(payload, "payload")
  try {



    const response = yield call(postSubmitForm, url + `rosters/getall`, payload);

    // console.log(response)

    if (response.status === 1) {

      // console.log("response is here owner")
      yield put(loadRosterSuccessOwner(response.data));
    } else {

      yield put(loadRosterErrorOwner());
    }
  } catch (error) {

    yield put(loadRosterErrorOwner(error));
  }
}


function* ownerRosterSaga() {

  yield takeEvery(LOAD_ROSTER_OWNER, getOwnerRosterData);
}

export default ownerRosterSaga;
