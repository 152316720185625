import React, { useState, useRef, useEffect } from "react"
import axios, { all } from "axios"
import "./allBooking.css"
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import 'tippy.js/themes/material.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/translucent.css';
import 'tippy.js/themes/light.css';
import BackgroundImage from "../../assets/images/Background.png"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  FormGroup,
  Label
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"

//i18next
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import Loader from "../../helpers/Loader"

// const refContainer = useRef(null)

const GameBookingsTable = props => {

  const [selectedGameTableId, setSelectedGameTableId] = useState([])
  const [calendarEvents, setCalendarEvents] = useState([])
  const [tableOptions, setTableOptions] = useState([])
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )



  const loadTableOptions = async () => {
    Loader(true)
    const url = process.env.REACT_APP_BASEURL + "game_tables/getall"
    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${employeeToken}`,
          },
        }
      )
      if (response.data.status === 1) {
        Loader(false)
        setTableOptions(response.data.data)
      }
    } catch (error) {
      Loader(false)
      console.error("Error fetching table options:", error)
    }
  }

  const loadGameBookingDetails = async () => {
    const url =
      process.env.REACT_APP_BASEURL + "bookings/get_game_booking_details"

    try {
      // Loader(true)
      const response = await axios.post(
        url,
        {
          game_id: selectedGameTableId,
        },
        {
          headers: {
            Authorization: `Bearer ${employeeToken}`,
          },
        }
      )

      if (response.data.status === 1) {
        // Loader(false)
        const fetchedBookings = response.data.data
        // console.log(fetchedBookings, "BOOKINGs")
        setCalendarEvents(fetchedBookings.map(convertToCalendarEvent))
      }
    } catch (error) {
      // Loader(false)
      console.error("Error fetching data:", error)
    }
  }

  const handleSelectAllTables = () => {
    if (selectedGameTableId.length === tableOptions.length) {
      setSelectedGameTableId([])
    } else {
      setSelectedGameTableId(tableOptions.map(table => table.game.game_id))
    }
  }
  const handleTableSelection = tableId => {
    if (selectedGameTableId.includes(tableId)) {
      setSelectedGameTableId(prevSelectedIds =>
        prevSelectedIds.filter(id => id !== tableId)
      )
    } else {
      setSelectedGameTableId(prevSelectedIds => [...prevSelectedIds, tableId])
    }
  }

  const convertToCalendarEvent = booking => {
    return {
      title: `${booking.customer_details.name}`,
      start: booking.start_time,
      end: booking.end_time,
      backgroundColor: getBookingBackgroundColor(booking.booking_status),
      extendedProps: {
        tableNumber: booking?.game_details[0]?.table_number, 
        customerName: booking?.customer_details?.name,
        customerType: booking?.customer_details?.customer_type,
        status: booking?.booking_status,
        gameNames: booking?.game_details,
        package: booking?.package_details[0]?.game_details,
      }
    }
  }

  const getBookingBackgroundColor = bookingStatus => {
    switch (bookingStatus) {
      case "Booked":
        return "#9ec3ff"
      case "Completed":
        return "#9873AC"
      case "In Process":
        return "#fffd9e"
      case "Cancelled":
        return "#ff9e9e"
      case "Checked out":
        return "#b0ff9e"
      case "Unpaid":
        return "#C5C5C5"
      default:
        return "#ffffff"
    }
  }

  useEffect(() => {
    loadTableOptions()
  }, [])

  useEffect(() => {
    loadGameBookingDetails()
  }, [selectedGameTableId]) 

  
  useEffect(() => {
    setSelectedGameTableId(tableOptions.map(table => table.game.game_id))
  }, [tableOptions])

  const renderEventContent = eventInfo => {
    const startTime = eventInfo.event.start.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    const endTime = eventInfo.event.end?.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  
    return {
      html: `
        <div style="font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          ${startTime}-${endTime} Booking for ${eventInfo.event.title}
        </div>
      `,
    };
  };

  const handleEventMouseEnter = async (mouseEnterInfo) => {
    try {
     
  
      const gameDetails = mouseEnterInfo.event.extendedProps.gameNames.map(game => `
        Game Name: ${game.game.name} - (Table Number: ${game.table_number})
    
      `).join('<br/>');
  
      const packageDetails = mouseEnterInfo.event.extendedProps.package?.map(game => `
      Game Name: ${game.name} - (Table Number: ${game.table_number})
  
    `).join('<br/>');
  
      const make_content = `
        <b>
        ${mouseEnterInfo.event.start.toLocaleTimeString(navigator.language, {
          hour: "numeric",
          minute: "2-digit",
        })} - ${mouseEnterInfo.event.end.toLocaleTimeString(navigator.language, {
          hour: "numeric",
          minute: "2-digit",
        })} </b> <br/>
        <b>Customer Name:</b> ${mouseEnterInfo.event.extendedProps.customerName} 
        [ ${mouseEnterInfo.event.extendedProps.customerType} ] <br/>
        <b>Status:</b> ${mouseEnterInfo.event.extendedProps.status} <br/>
        <b>Game Details:</b> ${gameDetails || "No Game Found"} <br/>
        <b>Package Details :</b> ${packageDetails || "No Packages Found"} <br/>
      `;
  
      tippy(mouseEnterInfo.el, {
        content: make_content,
        allowHTML: true,
        theme: "light-border",
      });
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleEventMouseLeave = () => {
    // const additionalDetails = document.querySelector('.additional-details');
    // additionalDetails.innerHTML = '';
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
};

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Booking"
            alternateTitle="Table Status"
            breadcrumbItem1="Table Status"
          />
          <Row>
            <Col>
              <Card>
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}

                
                >
                  <Row >
                    <Col lg={2} style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                  
                    <FormGroup>
            <Label>{props.t("Tables")}</Label>
            <div>
              <label>
                <Input
                  className="is-untouched is-pristine av-valid form-check-input"
                  type="checkbox"
                  checked={selectedGameTableId.length === tableOptions.length}
                  onChange={handleSelectAllTables}
                />{" "}
                Select All
              </label>
              <br />
              <Row>
                {tableOptions.map(table => (
                  <label key={table.game.game_id}>
                    <Input
                      className="is-untouched is-pristine av-valid form-check-input"
                      type="checkbox"
                      checked={selectedGameTableId.includes(table.game.game_id)}
                      onChange={() => handleTableSelection(table.game.game_id)}
                    />{" "}
                    Table {table.table_number}
                  </label>
                ))}
              </Row>
            </div>
          </FormGroup>
                    </Col>
                    <Col lg={9}>
                    <FullCalendar
                      height="auto"
                      slotEventOverlap={false}
                      navLinks={true}
                      nowIndicator={true}
                      // ref={refContainer}
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      initialView="timeGridDay"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      slotDuration="00:15:00"
                      dateClick={info => {
                        const clickedDate = info.date // This is the clicked date and time
                        // console.log("Clicked Date and Time:", clickedDate);
                        openBookingModal(clickedDate)
                      }} //to open a modal for booking
                      events={calendarEvents}
                      eventContent={renderEventContent}
                      eventMouseEnter={handleEventMouseEnter}
                      eventMouseLeave={handleEventMouseLeave}
                      // eventClick={e => showBookingDetails(e, "")}
                      eventBorderColor="red"
                      eventTextColor="#000000"
                      eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: "short",
                      }}
                      eventDisplay="block"
                      eventDurationEditable={false}
                      
                     
                    />
                    </Col>
                    
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  connect(null, {})(withTranslation()(GameBookingsTable))
)
