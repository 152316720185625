import React from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"

const LineBar = (props) => {
let data = props.line_data;
let dates = [];
let counts = [];
let values = [];

    if (data) {
          data.forEach((element) => {
          dates.push(element.date_of_sale);
          counts.push(element.count);
          values.push(element.value);
        });
    
    }
   const options = {
    grid: {
      zlevel: 0,
      x: 80,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    toolbox: {
      // orient: "center",
      // left: 0,
      // top: 20,
      feature: {
        magicType: { show: true, type: ["line", "bar"], title: { line: "For line chart", bar: "For bar chart" } },

        saveAsImage: { show: true, title: "Download Image" },
      },
    },
    color: ["#3c4ccf", "#02a499", "#38a4f8"],
    xAxis: [
      {
        type: "category",
        data: dates,
        axisPointer: {
          type: "shadow",
        },
        axisLine: {
          lineStyle: {
            color: "#8791af",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: props.type ? props.yAxisLabel : "Total Sale",
        min: 0,
        //max: 500000,
        minInterval: props.type === "monthly_income_trend" ? 450000 : 1,
        // maxInterval: 50000,
        //interval: 450000,
        axisLabel: {
          formatter: "QR {value}",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
   
    ],
    series: [
      {
        name: props.type ? props.yAxisLabel : "Sale",
        type: "bar",
        data: values,
      },
    ],
    textStyle: {
      color: ["#74788d"],
    },  
  }


  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withTranslation()(LineBar)));
