import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { postSubmitForm } from "../../helpers/backend_helper";
import {
  activate_deactivateEmployeeSuccess,
  addEmployeeApiError,
  loadAddEmployeeSuccess,
  submitAddEmployeeSuccess,
  updateEmployeeSuccess,
} from "./actions";
import {
  ACTIVATE_DEACTIVATE_EMPLOYEE,
  LOAD_ADDEMPLOYEE,
  SUBMIT_ADDEMPLOYEE,
  UPDATE_EMPLOYEE,
} from "./actionTypes";
import Swal from "sweetalert2";

function showNotification(message, type) {
  if (type === "Success") Swal.fire(type, message, "success");
  else Swal.fire(type, message, "error");
}

//Insert a new employee
function* submitAddEmployee({ payload: { addEmployee, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/insert";

    const response = yield call(postSubmitForm, url, addEmployee);

    if (response.status === 1) {
      showNotification("User Added Successfully.", "Success");
      yield put(submitAddEmployeeSuccess(response));
    } else {
      console.log(response);
      showNotification(response.message, "Error");
      yield put(addEmployeeApiError(response.message));
    }
  } catch (error) {
    showNotification("Something went wrong!", "Error");
    
    yield put(addEmployeeApiError(error));
  }
}
export function* watchSubmitAddUser() {
  yield takeEvery(SUBMIT_ADDEMPLOYEE, submitAddEmployee);
}

//get all employee
function* loadAddEmployee({ payload: {} }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/getall";
    const response = yield call(postSubmitForm, url, {});

    if (response.status === 1) {
      yield put(loadAddEmployeeSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(addEmployeeApiError(response));
    }
  } catch (error) {
    showNotification(error.message, "Error");
    yield put(addEmployeeApiError(error));
  }
}
export function* watchLoadAddEmployee() {
  yield takeEvery(LOAD_ADDEMPLOYEE, loadAddEmployee);
}

//Update employee
function* updateEmployee({ payload: { employee, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/update";

    const response = yield call(postSubmitForm, url, employee);

    if (response.status === 1) {
      showNotification("Employee Updated Successfully.", "Success");
      yield put(updateEmployeeSuccess(response));
    } else {
      console.log(response);
      showNotification(response.message, "Error");
      yield put(addEmployeeApiError(response.message));
    }
  } catch (error) {
    showNotification("Something went wrong!", "Error");
    yield put(addEmployeeApiError(error));
  }
}
export function* watchUpdateEmployee() {
  yield takeEvery(UPDATE_EMPLOYEE, updateEmployee);
}


//active and inactive
function* activate_deactivateEmployee({
  payload: { employee_status, history },
}) {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/activate_deactivate";
    console.log("emp", employee_status);
    const response = yield call(postSubmitForm, url, employee_status);

    if (response.status === 1) {
      employee_status.is_active
        ? showNotification("User Activated Successfully.", "Success")
        : showNotification("User Deactivated Successfully.", "Success");
      yield* loadrefreshEmployee();
      yield put(activate_deactivateEmployeeSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(addEmployeeApiError(response.message));
    }
  } catch (error) {
    showNotification("Something went wrong!", "Error");
    yield put(addEmployeeApiError(error));
  }
}
export function* watchActivate_deactivateEmployee() {
  yield takeEvery(ACTIVATE_DEACTIVATE_EMPLOYEE, activate_deactivateEmployee);
}
function* loadrefreshEmployee() {
  try {
    let url = process.env.REACT_APP_BASEURL + "employees/getall";
    const response = yield call(postSubmitForm, url, {});

    if (response.status === 1) {
      yield put(loadAddEmployeeSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(addEmployeeApiError(response));
    }
  } catch (error) {
    showNotification(error.message, "Error");
    yield put(addEmployeeApiError(error));
  }
}

function* EmployeeSaga() {
  yield all([fork(watchSubmitAddUser)]);
  yield all([fork(watchLoadAddEmployee)]);
  yield all([fork(watchUpdateEmployee)]);
  yield all([fork(watchActivate_deactivateEmployee)]);
}

export default EmployeeSaga;
