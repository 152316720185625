import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm, AvValidator } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json" 
import { formatDate } from "@fullcalendar/core"
import moment from "moment"


const AddInventory = props => {
  const editorRef = useRef()
  const { SearchBar } = Search
  // meta title
  document.title = "Add Item Inventory | Rendezvous - Game Parlour"
  const refContainer = useRef(null)

  const handleDownload = async () => {
    const fileName = "All Cafe Items";
    const exportType = "xls";
  
    let data_to_export = allInventoryItems;
  
    if(data_to_export){
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
        data.push({
          Name: v.name,
          Unit: v.unit,
          Sub_Unit: v.sub_unit,
          Formula : v.formula,
          Threshold: v.threshold,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  } 


  //for formula 
  const [unit, setUnit] = useState()
  const [sub_unit, setSub_unit] = useState()
  const [Formula, setFormula] = useState()
  const [isSubUnitEnabled, setIsSubUnitEnabled] = useState(false);
  
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  
  const [allInventoryItem, setAllInventoryItem] = useState()
  const [isSquareImg, setIsSquareImage] = useState(false)

 
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allInventoryItem && allInventoryItem.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  const handleDocumentChange = (event) => {
    const file = event.target.files[0];
    set_document_file(file);
  };

  useEffect(() => {
    loadAllInventoryItem();
  }, [])


  const [allInventoryItems, setAllInventoryItems] = useState([])
  const loadAllInventoryItem = async () => {
    //let url = process.env.REACT_APP_BASEURL + "cafe_items/getall"
    let url = "http://localhost:3002/item_inventory/get_all_items"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      setAllInventoryItems(response.data)
    } else {
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setAllInventoryItem(row)
          }}
        ></i>
        <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i>
      </span>
    )
  }

  // Insert
  const handleValidSubmit = async (e, v) => {
    //let url = process.env.REACT_APP_BASEURL + "cafe_items/insert"
     let url = "http://localhost:3002/item_inventory/insert";
      let data_send = {};
      data_send.item_details = {item : v.item};
      data_send.section = v.section;
      data_send.quantity = {unit:v.unit, sub_unit: v.sub_unit};
      data_send.expiry_date = v.expiry_date;
      data_send.cost_price = v.cost_price;
      data_send.selling_price = v.selling_price;
      postSubmitForm(url, data_send).then(response => {
        if (response.status === 1) {
          loadAllInventoryItem()
          refContainer.current.reset()
          showNotification(response.message, "Success")
        } else {
          showNotification(response.message, "Error")
        }
      })
  }

  //Update
  const handleValidUpdate = async (e, v) => {
   
        // let formData = new FormData()
        // formData.set("id", allInventoryItem._id)
        // formData.set("name", v.name)
        // formData.set("unit", v.unit)
        // formData.set("sub_unit", v.sub_unit)
        // formData.set("formula", v.formula)
        // formData.set("threshold", v.threshold)
        let data_send = {};
        data_send.item_details = {item : v.item};
        data_send.section = v.section;
        data_send.quantity = {unit:v.unit, sub_unit: v.sub_unit};
        data_send.expiry_date = v.expiry_date;
        data_send.cost_price = v.cost_price;
        data_send.selling_price = v.selling_price;
      
        //let url = process.env.REACT_APP_BASEURL + "cafe_items/update"
        let url = "http://localhost:3002/item_inventory/update_item";
        let response = await postSubmitForm(url, data_send)
        if (response.status === 1) {
          loadAllInventoryItem()
          setEditModal(!editModal)
          showNotification(response.message, "Success")
        } else {
          showNotification(response.message, "Error")
        }
      }
  

  //Delete
  const handleValidDelete = async allInventoryItem => {
    let url = process.env.REACT_APP_BASEURL + "cafe_items/delete"
    // let url = "http://localhost:3002/cafe_items/delete";
    let response = await postSubmitForm(url, {
      id: allInventoryItem._id,
    })
    if (response.status === 1) {
      loadAllInventoryItem()
    } else {
      showNotification(response.message, "Error")
    }
  }

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        ></img>
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        >
        </span>
      </>
    )
  }

  // function to get resolution of the image from input field

  const handleImageReso = (event) => {
    // console.log(event.target.files, "image files array")
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const resolution = {
            width: img.width,
            height: img.height
          };
          if (img.width === img.height) {
            // console.log("setting true")
            setIsSquareImage(true)
          }
          // console.log('Image resolution:', resolution);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  // ---------------------------------------------------------------------

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize +(+rowIndex +1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "item_details.image",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "item_details.item",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Unit"),
      dataField: "quantity.unit",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub-Unit"),
      dataField: "quantity.sub_unit",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Cost Price"),
      dataField: "cost_price",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Selling Price"),
      dataField: "selling_price",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
        text: props.t("Expiry Date"),
        dataField: "expiry_date",
        formatter:(cell, row) => {
         const date = row.expiry_date;
         const formattedDate = moment(date).format('DD-MM-YYYY')
         return formattedDate;
        },
       // sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Inventory" breadcrumbItem="Add Item Inventory" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>
                     
                      <Col md="6">
                           <AvField
                            label={props.t("Item Details")}
                            className="form-control"
                            type="text"
                            placeholder="Select Item"
                            name="item"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Item Details cannot be empty")}
                          >                           
                            </AvField>
                      
                      </Col>

                      <Col md="6">

                           <AvField
                            label={props.t("Section")}
                            className="form-control"
                            type="select"
                            placeholder="Select Section"
                            name="section"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Section cannot be empty")}
                          >
                            <option value="">{props.t("--Select Section--")}</option>
                            <option value="Accessory">{props.t("Accessories")}</option>
                            <option value="Cafe">{props.t("Cafe")}</option>
                            <option value="Kitchen">{props.t("Kitchen")}</option>
                        
                            </AvField>
                      
                      </Col>


                     
                   
                    <Col md="6">
                        <AvField
                          className="form-control"
                          label={props.t("Unit")}
                          type="text"
                          placeholder="KG"
                          name="unit"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Unit can not be empty")}
                        />
                    
                    </Col>

                    <Col md="6">
                        <AvField
                          className="form-control"
                          label={props.t("Sub-Unit")}
                          type="text"
                          placeholder="GRM"
                          name="sub_unit"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Sub-Unit can not be empty")}
                        />
                    
                    </Col>

                    <Col md="6">

                          <AvField
                            label={props.t("Cost Price")}
                            className="form-control"
                            type="number"
                            name="cost_price"
                            placeholder="Enter Cost Price per Unit"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Cost Price cannot be empty")}
                          />
                  
                      </Col>
                           
                      <Col md="6">
                     
                     <AvField
                       label={props.t("Selling Price")}
                       className="form-control"
                       type="number"
                       name="selling_price"
                       placeholder="Enter Selling Price"
                       validate={{ required: { value: true } }}
                       errorMessage={props.t("Selling Price cannot be empty")}
                     />
              
                 </Col>
                   

                    <Col md="6">
                        <AvField
                            label={props.t("Expiry Date")}
                            className="form-control"
                            name="expiry_date"
                            type="date"
                            validate={{
                              required: { value: true },
                            }}
                            errorMessage={props.t("Expiry Date cannot be empty ")}
                         />                     
                     
                      </Col> 
                     </Row>
                    <Row>
                      <Col lg={12} style={{display:"flex", justifyContent:"center"}}>
                       
                          <Button type="submit" className="btn btn-info">
                            Add Inventory 
                          </Button>
                      
                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Inventory Items")}
                      </Col>

                      {/* <Col lg={6} style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button 
                    type="submit"
                    className="btn btn-sm btn-secondary"
                    onClick={handleDownload}
                     >
                      <i className="mdi mdi-file-export" /> &nbsp;
                       {props.t("Export CSV")}
                    </Button> 
                    </Col> */}
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all existing Inventory Items")}
                  </CardSubtitle>
                  <ToolkitProvider 
                    keyField="_id"
                    columns={columns}
                    data={allInventoryItems && allInventoryItems}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable 
                         
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Item - ")}
                      {allInventoryItem && allInventoryItem.name}
                    </ModalHeader>
                    <ModalBody>
                      {allInventoryItem && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate} model={allInventoryItem}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage="Name cannot be empty."
                                          value={allInventoryItem.name}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="unit"
                                          label={props.t("Unit")}
                                          placeholder={props.t("Enter Unit")}
                                          type="text"
                                          errorMessage="Unit cannot be empty."
                                          value={allInventoryItem.unit}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="sub_unit"
                                          label={props.t("Sub Unit")}
                                          placeholder={props.t("Enter Sub Unit")}
                                          type="text"
                                          errorMessage="Sub Unit cannot be empty."
                                          value={allInventoryItem.sub_unit}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          name="formula"
                                          label={props.t("Formula")}
                                          placeholder={props.t("Enter Formula")}
                                          type="number"
                                          errorMessage="Formula cannot be empty."
                                          value={allInventoryItem.formula}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6} >
                                        <AvField

                                          name="threshold"
                                          label={props.t("Threshold")}
                                          placeholder={props.t("Enter Threshold")}
                                          type="number"
                                          errorMessage="Threshold cannot be empty."
                                          value={allInventoryItem.threshold}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Change Image")}
                                          className="btn btn-primary btn-sm"
                                          name="document_file"
                                          type="file"
                                          //value={allInventoryItem.image_url}
                                          onChange={e => { setdocument_file_update(e.target.files[0]); handleImageReso(e) }}
                                        // validate={{
                                        //   required: { value: true },
                                        // }}
                                        // errorMessage={props.t("Please Upload Item Image")}
                                        >
                                          {props.t("Upload File")}
                                        </AvField>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color=""
                                              className="mr-1 btn btn-primary"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AddInventory)))
