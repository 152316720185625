import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  ModalBody,
  FormGroup,
  ModalHeader,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import exportFromJSON from "export-from-json"
import withRouter from "../../components/Common/withRouter"
import showNotification from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter,selectFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const UpdateCategory = props => {

  document.title = "Update Category"

const refContainer = useRef(null)

const { SearchBar } = Search
const [section, setSection] = useState("");

useEffect(()=>{
    loadAllCategories()
    },[])
    
    const handleDownload = async () => {
      const fileName = "Categories";
      const exportType = "xls";
  
      // let data_to_export = allCategory;
      let data_to_export = section!=="" 
        ? allCategory.filter(item=>item.section===section) 
        : allCategory
  
      if (data_to_export) {
        var data = [];
        JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
          data.push({
             Name:v.name,
             Section: v.section
          });
        });
        exportFromJSON({ data, fileName, exportType });
      }
    }
      //getall
      const [allCategory, setAllCategory] = useState([])
      const loadAllCategories = async () => {
        preloader(true)
        let url = process.env.REACT_APP_BASEURL + "item_category/get_all_item_category"
      //let url = "http://localhost:3002/item_category/get_all_item_category"
        let response = await postSubmitForm(url, "")
        if (response.status === 1) {
          preloader(false)
          setAllCategory(response.data)
        } else {
          preloader(false)
          showNotification(response.message, "Error")
        }
      }
    
      const [editModal, setEditModal] = useState()
      const [selectedUser, setSelectedUser] = useState()
      function editFormatter(cell, row) {
        return (
          <span className="text-info d-flex justify-content-evenly">
            <i
              className="bx bxs-edit fs-5 mt-1"
              title="Click to Edit"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEditModal(!editModal)
                setSelectedUser(row)
              }}
            ></i>
             {/* <i
                className="fas fa-trash ms-3 fs-5 text-danger"
                title="Click to Delete"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    handleValidDelete(row)
                }}
        ></i> */}
            <i>
          <div className="form-switch ms-2" dir="ltr">
              <input
                type="checkbox"
                style={{height:"15px", width:"30px"}}
                title={row.is_active?"Click to Deactivate .":"Activate"}
                className="form-check-input"
                id={"customSwitchsize1" + row._id}
                onClick={() => { 
                  handleValidDelete(row)
                }}
                checked={row.is_active}
              />
              <label
                title="Click to change status."
                className="custom-control-label"
                htmlFor={"customSwitch1" + row._id}
                style={{ fontWeight: "100", cursor: "pointer" }}
              ></label>
            </div>
          </i>
          </span>
        )
      }
    
      //Update
      const handleValidUpdate = async (e, v) => {
        try {
          const object = {
           _id: selectedUser._id,
            name: v.name,
          }
         // console.log("object",object);
        // let url = "http://localhost:3002/item_category/update_item_category"
          let url = process.env.REACT_APP_BASEURL + "item_category/update_item_category"
          let response = await postSubmitForm(url, object)
          if (response.status === 1) {
            loadAllCategories()
            setEditModal(!editModal)
            showNotification(response.message, "Success")
          } else {
            showNotification(response.message, "Error")
          }
        } catch (error) {
          showNotification(error.message, "Error")
        }
      }
    //Delete
        const handleValidDelete = async category => {
            let url = process.env.REACT_APP_BASEURL + "item_category/activate_deactivate_item_category"
          // let url ="http://localhost:3002/item_category/delete_item_category"
            let response = await postSubmitForm(url, {
            _id: category._id,
            is_active : category.is_active===true? false : true
            })
            if (response.status === 1) {
            loadAllCategories()
             showNotification(response.message, "Success")
            } else {
            showNotification(response.message, "Error")
            }
        }
      const columns = [
        {
          dataField: "_id",
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
          },
          text: props.t("#"),
          headerStyle: (colum, colIndex) => {
            return { width: "0.5%" }
          },
        },
        {
          text: props.t("Name"),
          //formatter: employeeFormatter,
          dataField: "name",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
            text: props.t("Section"),
            dataField: "section",
            filter: selectFilter({
              style:{marginLeft:"10px"},
              options: [
                { value: "Accessories", label: "Accessories" },
                { value: "Cafe", label: "Cafe" },
                { value: "Kitchen", label: "Kitchen" },
              ],
              placeholder: "ALL",
              onFilter: (value) => {
                setSection( value )
              } 
            }),
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
        {
          dataField: "",
          text: props.t("Action"),
          formatter: editFormatter,
          sort: false,
          headerStyle: (colum, colIndex) => {
            return { width: "2%" }
          },
        },
      ]

      const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs 
            title={props.t("Item Category")}
            breadcrumbItem1={props.t("View/Update Category")}
            alternateTitle={props.t("Update Category")}/>

          <Row>
            <Col lg={12}>
              <Card>
             
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Item Categories")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                           style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={allCategory.length<=0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>

                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allCategory && allCategory || []}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{display:"flex", alignItems:"flex-start"}}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Category - ")}
                      {selectedUser && selectedUser.name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>

                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage={props.t("Name cannot be empty")}
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          value={selectedUser.name}
                                        />
                                      </Col>
                                      

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(UpdateCategory)))
