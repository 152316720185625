import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
    Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import BackgroundImage from "../../assets/images/Background.png"
//i18n
import { withTranslation } from "react-i18next";
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"

const Attendance = props => {
  //meta title
  document.title = "Attendance | Rendezvous - Game Parlour"

  useEffect(()=>{
    setCurrentMonth(month);
    loadAttendance()
  },[])

  const [employee_id, setEmployeeId] = useState(localStorage.getItem("employee_id"));
    // Dynamic Moth Year
  const [currentMonth, setCurrentMonth] = useState('');
  const currentDate = new Date();
  const options = { month:'long', year:'numeric'}
  const month = currentDate.toLocaleString('default',options);

const [attendance, setAttendance] = useState([]) 
const loadAttendance = async () => {
    const object ={
        employee_id:employee_id,
        from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to_date: new Date()
    }
let url= process.env.REACT_APP_BASEURL + "attendances/getall_by_employee_id"
let response = await postSubmitForm(url,object)
if(response.status === 1){
    // console.log("res",response);
    setAttendance(response.data)
}else{
    showNotification(response.message,"Error")
}
}
function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    return [ day,month,year].join("-")
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
  }
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid>
          <Breadcrumbs
            title={props.t("Attendance")}
            breadcrumbItem={props.t("Attendance")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}}>
                  <CardTitle>Attendance | {currentMonth}</CardTitle>
                  <div style={{ overflowX: "auto" }}>
                    <Table className="table table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Shift</th>
                          <th>Day Status</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                          <th>Late In</th>
                          <th>Early Out</th>
                          <th>System Overtime</th>
                          <th>Approved Overtime</th>
                          <th>Approved Undertime</th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendance.map((attendance,ind)=>{
                            return(
                                <tr key={ind}>
                             <td>{formatDate(attendance.attendance_date) }  </td> 
                                <td>{attendance.shift_name || 'NA'}</td>
                                <td>
                                <Badge
                                className={`fs-5 badge-soft-${attendance.day_status === "P" ? "success" : "danger" }`}
                              >
                                {attendance.day_status}
                              </Badge>
                                 </td>
                                <td>{attendance.in_time || "NA"}</td>
                                <td>{attendance.out_time || "NA"}</td>
                                <td>{attendance.late_in || "NA"}</td>
                                <td>{attendance.early_out || "NA"}</td>
                                <td>{attendance.system_overtime || "NA"}</td>
                                <td>0</td>
                                <td>0</td>
                              </tr>
                            )
                        })}
                    
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Attendance.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Attendance)