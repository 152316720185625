import React, { useState, useRef, useEffect } from "react"
import axios, { all } from "axios"
import "./allBooking.css"
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light.css"
import "tippy.js/themes/material.css"
import "tippy.js/themes/translucent.css"
import "tippy.js/themes/light-border.css"
import styles from "./Schedular.module.css"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Container,
  Input,
  FormGroup,
  Label,
  CardTitle,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Badge,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import withRouter from "../../components/Common/withRouter"

//i18next
import { connect } from "react-redux"
import showNotification from "../../helpers/show_notificaton"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/backend_helper"

// import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import cellEditFactory from "react-bootstrap-table2-editor"
import moment, { duration } from "moment"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import Select from "react-select"
import classnames from "classnames"
import { sortBy } from "lodash"
import ViewUpdateBooking from "./ViewUpdateBooking"
import Loader from "../../helpers/Loader"
import BackgroundImage from "../../assets/images/Background.png"
import SelectPhotoFormatter from "../../helpers/SelectImageFormatter"
import AddQuantity from "./functions/AddQuantity"
import AddGameDuration from "./functions/AddGameDuration"
import CalcEndTime from "./functions/CalcEndTime"
import swal from "sweetalert2"
import { showWarning } from "../../helpers/show_notificaton"
import CheckTableAvailability from "./functions/CheckTableAvailability"
import preloader from "../../helpers/Loader"

const SequentialReservation = props => {
  const { SearchBar } = Search

  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [bookings, setBookings] = useState()
  const [calendarEvents, setCalendarEvents] = useState()
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [toggleModal, setToggleModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedMobileNumber, setSelectedMobileNumber] = useState("")

  const [selected_game, setSelected_game] = useState([])
  const [allGame, setAllGames] = useState([])
  const [all_Items, setAll_Items] = useState([])
  const [selected_item, setSelected_item] = useState([])
  const [allPackages, setAllPackages] = useState([])
  const [selected_package, setSelected_package] = useState([])
  const [startTime, setStartTime] = useState()
  const [prevStart, setPrevStart] = useState()
  const [endTime, setEndTime] = useState()
  const [gameStartTime, setGameStartTime] = useState()
  const [gameEndTime, setGameEndTime] = useState()
  const [selectedGamePrice, setSelectedGamePrice] = useState(0)
  const [selectedItemPrice, setSelectedItemPrice] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  // console.log("GT", grandTotal)
  const refSelectGame = useRef(null)
  const refContainer = useRef()

  const [customerArr, setCustomerArr] = useState([])
  const [query, setQuery] = useState("")
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"))
  const [results, setResults] = useState({
    customer_details: [],
    package_details: [],
  })
  const [packageModal, setPackageModal] = useState(false)
  const [addCustomerModal, setAddCustomerModal] = useState(false)
  const [addGamedetailsModal, setAddGamedetailsModal] = useState(false)
  const [addItemdetailsModal, setAddItemdetailsModal] = useState(false)
  const [addPackagedetailsModal, setAddPackagedetailsModal] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [addBookingClickTracker, setAddBookingClickTracker] = useState(0)
  // pacakge -- item_details and game_details array state

  const [game_details, set_game_details] = useState([])
  const [item_details, set_item_details] = useState([])

  const [bookingDetailsModal, setBookingDetailsModal] = useState(false)
  const [bookedItemDetails, setBookedItemsDetails] = useState([])
  const [bookedGameDetails, setBookedGameDetails] = useState([])
  const [bookedPackageDetails, setBookedPackageDetails] = useState([])
  const [bookedCustomerDetails, setBookedCustomerDetails] = useState({})
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState()
  const [ab, setAB] = useState({})
  const [cd, setCD] = useState({})
  const [itemVisible, setItemVisible] = useState(true)
  const [gameVisible, setGameVisible] = useState(false)
  const [selectedPreviousPackage, setSelectedPreviousPackage] = useState([])
  const [selectedPreviousItems, setSelectedPreviousItems] = useState([])
  const [selectedPreviousGames, setSelectedPreviousGames] = useState([])
  const [keyChange, setKeyChange] = useState(0)
  const [customerValidation, setCustomerValidation] = useState(false)
  const [gameList, setGameList] = useState([])
  const [allTheGames, setAllTheGames] = useState([])
  const [isoDate2, setIsoDate2] = useState("")
  const [availableTables, setAvailableTables] = useState([])
  const [selectedTable, setSelectedTable] = useState([])
  const [tablesToDisplay, setTablesDisplay] = useState([])
  const [allTableData, setAllTableData] = useState([])
  const [saveAllAvailable, setAllAvailable] = useState([])

  const [availablePrevPkgGameTables, setAvailablePrevPkgGameTables] = useState([
    { label: "pratek" },
    { label: "shekhar" },
    { label: "talha" },
  ])

  const [gameValidation, setGameValidation] = useState(false)
  const [itemValidation, setItemValidation] = useState(false)
  const [prevPkgValidation, setPrevPkgValidation] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [cardType, setCardType] = useState(null)
  const [cards, setCards] = useState([
    { label: "Visa", value: "Visa" },
    { label: "Master Card", value: "Master Card" },
  ])
  const [cardModal, setCardModal] = useState(false)
  const [branches, setBranches] = useState()
  const [allBranchDetails, setAllBranchDetails] = useState([])
  const [cardDetails, setCardDetails] = useState({
    card_holder_name: "",
    card_number: "",
    card_expiry: "",
    card_cvv: "",
  })
  const refContainer2 = useRef(null)
  const [clickedCvv, setClickedCvv] = useState(false)
  const [cardLogo, setCardLogo] = useState("")
  const [prevPkgAvailableTables, setPrevPkgAvailableTables] = useState([])
  const [prevPkgGameSelectModal, setPrevPkgGameSelectModal] = useState(false)
  const [prevPkgSelectedTable, setPrevPkgSelectedTable] = useState([])
  const [preSave, setPreSave] = useState([])
  const [preSaveCustomerPkg, setPreSaveCustomerPkg] = useState([])
  const [preSaveColor, setPreSaveColor] = useState([])
  const [customStyles, setCustomStyles] = useState({})
  const [cardValidation, setCardValidation] = useState(false)

  // -------------------------------STATES--------------------------------------
  const [itemsInTable, setItemsInTable] = useState([])
  const [subUnit, setSubUnit] = useState(0)
  const [unit, setUnit] = useState(0)
  const [GameDuration, setGameDuration] = useState(30)
  const [GamesInTable, setGamesInTable] = useState([])
  const [displayGameTable, setDisplayGameTable] = useState(false)
  const [displayItemTable, setDisplayItemTable] = useState(false)
  const [displayCustomerPackageTable, setDisplayCustomerPackageTable] =
    useState(false)
  const [cardNumber, setCardNumber] = useState("")
  const [allGameTables, setAllGameTables] = useState([])
  const [bookingEndTime, setBookingEndTime] = useState("")
  const [durationWarning, setDurationWarning] = useState(null)
  const [maxTime, setMaxTime] = useState(0)
  const [itemUnitValidation, setItemUnitValidation] = useState(null)
  const [itemSubUnitValidation, setItemSubUnitValidation] = useState(null)
  const [tableStyles, setTableStyles] = useState({})

  //to track the selected games in table;
  const [finalSelectedGames, setFinalSelectedGames] = useState([])

  // ------------ADD CUSTOMER USESTATE'S--------------------------------------------------


  const [is_wallet, setIs_Wallet] = useState(false)
  const [dummyPkg, setDummyPkg] = useState([])
  //onchange tracker
  const [onChangeTrack, setOnChangeTrack] = useState(false);

  useEffect(() => {
    loadAllCustomers()
    loadAllBranches()
    loadAllGameTable()
    loadAllPackages()
  }, [addCustomerModal])

  const loadAllPackages = async () => {
    let url = process.env.REACT_APP_BASEURL + "packages/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      response.data.map(pkg => {
        pkg.label = (
          <span style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{pkg.package_name}</span>
            <span>{` [${moment(pkg.createdAt).format("DD-MM-YYYY")}]`}</span>
          </span>
        )
        pkg.value = pkg.package_name
      })
      const active = response.data.filter(cust => cust.is_active === true)
      setDummyPkg(active)
    } else {
      console.log("Error")
    }
  }

  useEffect(() => {
    // console.log(preSave)
    setCustomStyles(
      prev =>
        (prev = {
          control: styles => ({ ...styles, color: "white" }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // console.log(isDisabled, "disabled")
            const color = "white"
            return {
              ...styles,
              backgroundColor: isDisabled
                ? "gray"
                : isSelected
                ? "white"
                : isFocused
                ? "#566ce3"
                : undefined,
              color: isFocused ? "white" : "black",
              cursor: isDisabled ? "not-allowed" : "default",
              ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                  ? isSelected
                    ? "white"
                    : "#566ce3"
                  : undefined,
              },
            }
          },
          multiValue: (styles, { data }) => {
            // const color = "#eff2f7";
            let color = ""
            let isSelectedGameNoTables = []
            if (preSaveColor.length === 0) {
              color = "#eff2f7"
            } else {
              isSelectedGameNoTables = preSaveColor.some(
                selected => selected === data.label.props.children[1]
              )
              color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
            }
            return {
              ...styles,
              backgroundColor: color,
            }
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "black",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "black",
            ":hover": {
              backgroundColor: "#f0b4b4",
              color: "red",
            },
          }),
        })
    )
  }, [preSaveColor])

  // --------------------------------ALL CONSOLE LOGS---------------------------------------------------------
  // console.log(selectedGamePrice, "selected game price")
  // console.log(selected_item, "selected item");
  // console.log(finalSelectedGames,"final selected games")
  // console.log(selectedPreviousPackage?.package_details?.item_details ,"prev items array")
  // console.log(GamesInTable, "game in table")
  // console.log(selected_game,"selcted game")
  // console.log(selectedTable,"selcted table")
  // console.log(GamesInTable, "game in table");
  // console.log(availableTables, "available tables")
  // console.log(allTableData,"all table data")
  // console.log(selectedTable, "selected table");
  // console.log(GamesInTable,"games in table");
  // console.log(allTableData, "all table data")
  // console.log(cards, "cards ")
  // console.log(preSaveColor, "pre save color")
  // console.log(prevPkgAvailableTables, "avaialbelt ables")
  // console.log(results, "results");
  // console.log(selected_package, "selected package")
  // console.log(selected_item, "selected items")
  // console.log(selectedBranch,"selected branch");
  // console.log(selected_game, "selected game");
  // console.log(branches,"all branches details")
  // console.log(cardDetails, "card details")
  // console.log(selectedPreviousPackage, "selected prev pkg");
  // console.log(allGame, "all game");
  // console.log(gameList, "game list");
  // console.log(selectedCustomer, "selectedCustomer")
  // console.log(selected_item, "selected item");
  // console.log(itemsInTable, "item in table")
  // console.log(selectedCustomer, "selected customer")
  // console.log(selected_game, "selected game")
  // console.log(calendarEvents, "calendar events")
  // console.log(game_details,"game details");
  // console.log(item_details, "item details")
  // console.log(bookings, "all bookings");
  // console.log(bookedCustomerDetails, "booked customer details");
  // console.log(bookedGameDetails, "booked game details")
  // console.log(bookedPackageDetails, "booked package details");
  // console.log(allGame, "all game");
  // console.log(all_Items, "all items");\
  // console.log(selectedGamePrice, "selected game price");
  // console.log(isoDate2,"isoDate2")
  // console.log(allPackages, "all previous packages");
  // console.log(tablesToDisplay, "table to display");
  // console.log(allTableData, "all table data")
  // console.log(availableTables, "availalbe tables")
  // console.log(availablePrevPkgGameTables, "avlble prev pkg game tables");
  // console.log(selectedPreviousPackage,"selcted prev pkg")
  // console.log(selected_package, "selected package")
  // console.log(itemsInTable, "items in table")
  // console.log(noTableFlag[data.label],'no table flag value')

  //------------------------------------------------------------------------------------------------------
  function DeleteSelectedItem(cell, row) {
    // console.log(row, "row");

    const handleDelete = () => {
      setItemsInTable(prevItems => {
        return prevItems.filter(item => item._id !== row._id)
      })
      setAll_Items(prev => (prev = [...prev, row]))
    }

    return (
      <i
        className="fas fa-trash ms-3 fs-5 text-danger"
        title="Click to Receive"
        style={{ cursor: "pointer" }}
        onClick={handleDelete}
      ></i>
    )
  }

  //if a selected item is removed then the unit and sub-unit field values should reset
  useEffect(() => {
    if (selected_item.length === 0) {
      setUnit(prev => (prev = 0))
      setSubUnit(prev => (prev = 0))
      setItemUnitValidation(prev => (prev = null))
      setItemSubUnitValidation(prev => (prev = null))
    }
  }, [selected_item])

  //setting booking end time to empty, if the GamesInTAble array is empty, cuz the end time depends on game table durations
  useEffect(() => {
    let localVarMaxTime = 0

    if (GamesInTable.length === 0) {
      setBookingEndTime(prev => (prev = ""))
      setMaxTime(prev => (prev = 0))
    } else {
      //iterating through GamesInTable array to find the max time
      GamesInTable.forEach(gameTable => {
        if (localVarMaxTime < gameTable.game.duration) {
          localVarMaxTime = gameTable.game.duration
          setMaxTime(prev => (prev = gameTable.game.duration))
        }
      })

      //calling the calcEndTime function to calculate the end time on the basis of the
      //new max time after a gameTable has been deleted from the GamesInTable array
      if (localVarMaxTime > 0) {
        CalcEndTime(
          startTime,
          localVarMaxTime,
          setBookingEndTime,
          bookingEndTime
        )
      }
    }

    //setting finalSelectedGames from GamesInTable:
    setFinalSelectedGames(prev => (prev = [...GamesInTable]))
  }, [GamesInTable])

  useEffect(() => {
    if (GamesInTable.length === 0) {
      setGameValidation(prev => (prev = false))
    }
    let grandTotal = 0
    GamesInTable.forEach(gameTable => (grandTotal += gameTable.game.sub_total))
    itemsInTable.forEach(item => (grandTotal += item.sub_total))

    setGrandTotal(prev => (prev = grandTotal))
  }, [GamesInTable])

  useEffect(() => {
    if (itemsInTable.length === 0) {
      setItemValidation(prev => (prev = false))
    }
    let grandTotal = 0
    itemsInTable.forEach(item => (grandTotal += item.sub_total))
    GamesInTable.forEach(gameTable => (grandTotal += gameTable.game.sub_total))

    setGrandTotal(prev => (prev = grandTotal))
  }, [itemsInTable])

  useEffect(() => {
    //items quantity field validation code below.............
    const startTime1 = new Date(startTime)

    // Get the current time
    const currentTime = new Date()

    // Calculate the difference in minutes
    const timeDifferenceInMinutes = Math.floor(
      (startTime1 - currentTime) / (1000 * 60)
    )

    // Check if the difference is less than 120 minutes
    if (timeDifferenceInMinutes >= 120 && cardNumber.length < 4) {
      setCardValidation(prev => (prev = true))
      return
    } else {
      setCardValidation(prev => (prev = false))
    }

    if (timeDifferenceInMinutes >= 120 && cardNumber.length !== 4) {
      setCardValidation(prev => (prev = true))
      return
    } else {
      setCardValidation(prev => (prev = false))
    }
  }, [cardNumber])

  useEffect(() => {
    //items quantity field validation code below.............
    let itemValidationCount = 0
    itemsInTable?.forEach(item => {
      if (item.quantity.in_unit === 0 && item.quantity.in_sub_unit === 0) {
        itemValidationCount += 1
      }
    })

    if (itemValidationCount > 0) {
      setItemValidation(prev => (prev = true))
      return
    } else {
      setItemValidation(prev => (prev = false))
    }
  }, [itemsInTable])

  useEffect(() => {
    // //game minutes field validation state set to default 'false'....
    let gameValidationCount = 0
    tablesToDisplay?.forEach(game => {
      if (game.game.duration === 0) {
        gameValidationCount += 1
      }
    })

    if (gameValidationCount > 0) {
      setGameValidation(prev => (prev = true))
      return
    } else {
      setGameValidation(prev => (prev = false))
    }
  }, [selectedTable])

  useEffect(() => {
    // //game minutes field validation state set to default 'false'....
    let gameValidationCount = 0
    tablesToDisplay?.forEach(game => {
      if (game.game.duration === 0) {
        gameValidationCount += 1
      }
    })

    if (gameValidationCount > 0) {
      setGameValidation(prev => (prev = true))
      return
    } else {
      setGameValidation(prev => (prev = false))
    }
  }, [selectedTable])


  const debounceDelay = 500

  // function to display previous package items or games table in modal pacakge tabs
  function displayItemOrGAme(tableName) {
    if (tableName === "item") {
      setItemVisible(prev => (prev = true))
      setGameVisible(prev => (prev = false))
    } else {
      setItemVisible(prev => (prev = false))
      setGameVisible(prev => (prev = true))
    }
  }

  const loadAllBranches = async () => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      Loader(false)
      setAllBranchDetails(prev => (prev = response.data))

      let branchList = response.data.map(branch => ({
        label: branch.name,
        value: branch.value,
      }))

      setBranches(branchList)
    } else {
      Loader(false)
      console.log("Error")
    }
  }

  const loadAllGameTable = async () => {
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_games_insert"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      //   console.log(response.data,"all game tables");
      let activeTables = response.data.filter(game => game.is_active === true && game.is_reserved_for_open===false)
      // console.log(activeTables, "active tables")
      setAllGameTables(activeTables)
    } else {
      console.log("err")
      showNotification(response.message, "Error")
    }
  }

  //this is function is called when add booking button is clicked...
  function handleValidSubmit(e, v) {
    //VALIDATIONS----------------------------------------

    if (selectedCustomer === null) {
      setCustomerValidation(prev => (prev = true))
      return
    }

    let gameValidationCount = 0
    let itemValidationCount = 0
    let prevPkgGameValidationCount = 0
    let prevPkgItemValidationCount = 0
    let prevPkgGameMissedDetail = 0

    tablesToDisplay?.forEach(game => {
      if (game.game.duration === 0) {
        gameValidationCount += 1
      }
    })

    if (gameValidationCount > 0) {
      setGameValidation(prev => (prev = true))
      return
    } else {
      setGameValidation(prev => (prev = false))
    }

    itemsInTable?.forEach(item => {
      if (item.quantity.in_unit === 0 && item.quantity.in_sub_unit === 0) {
        itemValidationCount += 1
      }
    })

    if (itemValidationCount > 0) {
      setItemValidation(prev => (prev = true))
      return
    } else {
      setItemValidation(prev => (prev = false))
    }

    if (results.package_details.length > 0 && selected_package.label) {
      selectedPreviousPackage?.package_details?.item_details?.forEach(item => {
        if (item.quantity.in_unit > 0 || item.quantity.in_sub_unit > 0) {
          prevPkgItemValidationCount += 1
          return
        }
      })

      selectedPreviousPackage?.package_details?.game_details?.forEach(item => {
        if (
          item.requested_duration > 0 &&
          (item.table_number ? item.table_number?.length > 0 : false)
        ) {
          prevPkgGameValidationCount += 1
          // return;
        } else if (
          item.requested_duration > 0 ||
          (item.table_number ? item.table_number?.length > 0 : false)
        ) {
          prevPkgGameMissedDetail += 1
        }
      })

      if (
        prevPkgGameValidationCount === 0 &&
        prevPkgItemValidationCount === 0
      ) {
        setPrevPkgValidation(prev => (prev = true))
        return
      } else if (prevPkgGameMissedDetail > 0) {
        setPrevPkgValidation(prev => (prev = true))
        return
      } else if (
        prevPkgGameValidationCount > 0 ||
        prevPkgItemValidationCount > 0
      ) {
        setPrevPkgValidation(prev => (prev = false))
        // return;
      }
    }

    const startTime1 = new Date(startTime)

    // Get the current time
    const currentTime = new Date()

    // Calculate the difference in minutes
    const timeDifferenceInMinutes = Math.floor(
      (startTime1 - currentTime) / (1000 * 60)
    )

    // Check if the difference is less than 120 minutes
    if (timeDifferenceInMinutes >= 120 && cardNumber.length < 4) {
      setCardValidation(prev => (prev = true))
      return
    } else {
      setCardValidation(prev => (prev = false))
    }

    if (timeDifferenceInMinutes >= 120 && cardNumber.length !== 4) {
      setCardValidation(prev => (prev = true))
      return
    } else {
      setCardValidation(prev => (prev = false))
    }

    //VALIDATIONS END HERE ===================================================================================
    // console.log(selected_package, "SP")
    //CALCULATING END TIME ===========================================================================================
    function calculateEndTime() {
      let prevPkgGames =
        selectedPreviousPackage?.package_details?.game_details || []

      let gameArray = [...prevPkgGames, ...tablesToDisplay]

      let maxMinutes = 0
      // Check if there are game details

      if (gameArray && gameArray.length > 0) {
        // Find the maximum minute value among game details
        for (const gameDetail of gameArray) {
          if (gameDetail?.requested_duration > maxMinutes) {
            maxMinutes = gameDetail.requested_duration
          }
          if (gameDetail?.game?.duration > maxMinutes) {
            maxMinutes = gameDetail.game.duration
          }
        }

        // Convert start_time to a Date object
        let startTimeValue = new Date(startTime)

        // Calculate the end time by adding maxMinutes
        startTimeValue.setMinutes(
          startTimeValue.getMinutes() + (maxMinutes || 60)
        )

        // Format the end time as dd-mm-yyyy --:--
        const dd = String(startTimeValue.getDate()).padStart(2, "0")
        const mm = String(startTimeValue.getMonth() + 1).padStart(2, "0") // Month is 0-based
        const yyyy = startTimeValue.getFullYear()
        const hh = String(startTimeValue.getHours()).padStart(2, "0")
        const min = String(startTimeValue.getMinutes()).padStart(2, "0")

        const endTimeFormatted = `${dd}-${mm}-${yyyy} ${hh}:${min}`
        const isoDate = `${endTimeFormatted.substr(
          6,
          4
        )}-${endTimeFormatted.substr(3, 2)}-${endTimeFormatted.substr(
          0,
          2
        )}T${endTimeFormatted.substr(11)}:00`
        return isoDate
      } else {
        // return null; // No game details, end time cannot be calculated
        let startTimeValue = new Date(startTime)

        // Calculate the end time by adding maxMinutes
        startTimeValue.setMinutes(
          startTimeValue.getMinutes() + (maxMinutes === 0 ? 60 : maxMinutes)
        )

        // Format the end time as dd-mm-yyyy --:--
        const dd = String(startTimeValue.getDate()).padStart(2, "0")
        const mm = String(startTimeValue.getMonth() + 1).padStart(2, "0") // Month is 0-based
        const yyyy = startTimeValue.getFullYear()
        const hh = String(startTimeValue.getHours()).padStart(2, "0")
        const min = String(startTimeValue.getMinutes()).padStart(2, "0")

        const endTimeFormatted = `${dd}-${mm}-${yyyy} ${hh}:${min}`
        const isoDate = `${endTimeFormatted.substr(
          6,
          4
        )}-${endTimeFormatted.substr(3, 2)}-${endTimeFormatted.substr(
          0,
          2
        )}T${endTimeFormatted.substr(11)}:00`
        return isoDate
      }
    }

    const endTimeValue = calculateEndTime()

    //CALCULATE END TIME ENDS HERE ======================================================================================================================================

    let prevPkgItemsDetails = []
    let prevPkgGamesDetails = []

    if (selectedPreviousPackage) {
      prevPkgItemsDetails =
        selectedPreviousPackage?.length === 0
          ? []
          : selectedPreviousPackage?.package_details?.item_details.filter(
              item =>
                item?.quantity?.in_unit > 0 || item?.quantity?.in_sub_unit > 0
            )
      prevPkgGamesDetails =
        selectedPreviousPackage?.length === 0
          ? []
          : selectedPreviousPackage?.package_details?.game_details.filter(
              item =>
                item?.requested_duration > 0 &&
                (item.table_number ? item.table_number?.length > 0 : false)
            )
    }

    let totalTime = 0

    if (tablesToDisplay?.length > 0) {
      totalTime = tablesToDisplay.reduce(
        (acc, obj) => acc + obj?.game?.duration,
        0
      )
    }

    if (prevPkgGamesDetails?.length > 0) {
      totalTime += prevPkgGamesDetails.reduce(
        (acc, obj) => acc + obj?.requested_duration,
        0
      )
    }

    // setting branch all details of selected branch in a local variable:
    let localBranch // Declare localBranch outside the if statement
    if (localStorage.getItem("role") === "Owner") {
      localBranch = allBranchDetails.filter(
        branch => selectedBranch.label === branch.label
      )
    }
    const payload = {
      item_total_price: selectedItemPrice,
      game_total_price: selectedGamePrice,
      remarks: v.remarks,
      item_details: itemsInTable
        .filter(
          item => item.quantity.in_unit !== 0 || item.quantity.in_sub_unit !== 0
        )
        .map(item => {
          return {
            item_id: item._id,
            image_url: item.image_url,
            name: item.name,
            section: item.section,
            unit: item.unit,
            sub_unit: item.sub_unit,
            expiry_date: item.expiry_date,
            formula: item.formula,
            category: item.category,
            threshold: item.threshold,
            price: item.selling_price,
            image_url: item.image_url,
            total_price: item.total_price,
            sub_total: +item.sub_total.toFixed(2),
            quantity: {
              in_unit: item.quantity.in_unit,
              in_sub_unit: item.quantity.in_sub_unit,
            },
            available: {
              in_unit: item.available.in_unit,
              in_sub_unit: item.available.in_sub_unit,
            },
            new_available: (() => {
              // Check if there's a formula; default to 1 if not provided
              const formula = item.formula || 1

              // Convert available to sub-units
              const availableInSubUnits =
                item.available.in_unit * formula + item.available.in_sub_unit

              // Convert quantity to sub-units
              const quantityInSubUnits =
                item.quantity.in_unit * formula + item.quantity.in_sub_unit

              // Calculate the new available sub-units
              const newAvailableInSubUnits =
                availableInSubUnits - quantityInSubUnits

              // Convert new available sub-units back to units and sub-units
              const newAvailableInUnits = Math.floor(
                newAvailableInSubUnits / formula
              )
              const newAvailableSubUnits = newAvailableInSubUnits % formula

              return {
                in_unit: newAvailableInUnits,
                in_sub_unit: newAvailableSubUnits,
              }
            })(),
          }
        }),
      package_details:
        prevPkgItemsDetails?.length === 0 && prevPkgGamesDetails?.length === 0
          ? []
          : [
              {
                _id: selected_package._id,
                package_id: selected_package?.value,
                package_name: selected_package?.label,
                item_details: prevPkgItemsDetails,
                game_details: prevPkgGamesDetails,
              },
            ],
      game_details: GamesInTable,
      total_price: +grandTotal,
      customer_details: {
        _id: results.customer_details[0]._id,
        customer_type: selectedCustomer.type,
        name: selectedCustomer.value,
        mobile: selectedCustomer.mobile,
        wallet: results.customer_details[0].wallet,
        is_wallet_eligible: results.customer_details[0].is_wallet_eligible,
      },
      branch_details: {
        branch_id:
          localStorage.getItem("role") === "Owner"
            ? localBranch[0]._id
            : allBranchDetails[0]._id,
        name:
          localStorage.getItem("role") === "Owner"
            ? localBranch[0].name
            : allBranchDetails[0].name,
        address:
          localStorage.getItem("role") === "Owner"
            ? localBranch[0].address
            : allBranchDetails[0].address,
        email:
          localStorage.getItem("role") === "Owner"
            ? localBranch[0].email
            : allBranchDetails[0].email,
        mobile:
          localStorage.getItem("role") === "Owner"
            ? localBranch[0].mobile
            : allBranchDetails[0].mobile,
      },
      // card_details: cardDetails.card_number === "" ? {} : cardDetails,
      card_details: cardNumber,
      start_time: new Date(startTime),
      // userTimeZoneOffset: timeZone,
      end_time:
        bookingEndTime === ""
          ? new Date(endTimeValue)
          : new Date(bookingEndTime),
      is_advance_booking: "false",
      total_time: totalTime,
    }
    // console.log(payload, "Booking payload")
    // return

    //IF THERE ARE NO GAMES, THEN CANNOT DO BOOKING ---------------------------------------------------------
    if (payload?.package_details?.length > 0) {
      if (
        payload.game_details?.length === 0 &&
        payload?.package_details[0]?.game_details.length === 0
      ) {
        showWarning(
          "Booking can't be done without games, please select a game !",
          "Warning"
        )
        // setSelectedPreviousPackage(prev => prev = [])
        return
      }
    } else {
      if (payload?.game_details?.length === 0) {
        showWarning(
          "Booking can't be done without games, please select a game !",
          "Warning"
        )
        // setSelectedPreviousPackage(prev => prev = [])
        return
      }
    }
    //---------------------------------------------------------------------------------------------------------------

    // console.log(payload, "payload")
    // return;

    //CALLING INSERT BOOKING API.....

    let url = process.env.REACT_APP_BASEURL

    axios
      .post(
        url + `bookings/insert`,

        payload,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(res => {
        //RESETTING ALL THE STATES TO DEFAULT

        setAddBookingClickTracker(prev => (prev += 1))
        setToggleModal(prev => (prev = !prev))
        loadAllBookings()
        setSelectedPreviousGames(prev => (prev = []))
        setSelectedPreviousItems(prev => (prev = []))
        setSelected_game(prev => (prev = []))
        setSelected_item(prev => (prev = []))
        setSelectedCustomer(prev => (prev = null))
        setSelected_package(prev => (prev = []))
        setSelectedTable(prev => (prev = []))
        setAvailableTables(prev => (prev = []))
        setTablesDisplay(prev => (prev = []))
        setGrandTotal(prev => (prev = 0))
        setSelectedGamePrice(prev => (prev = 0))
        setSelectedItemPrice(prev => (prev = 0))
        setItemVisible(prev => (prev = true))
        setGameVisible(prev => (prev = false))
        setCardType(prev => (prev = null))
        setSelectedBranch(prev => (prev = null))
        setCardValidation(prev => (prev = false))
        setItemsInTable(prev => (prev = []))
        setGamesInTable(prev => (prev = []))
        setDisplayGameTable(prev => (prev = false))
        setDisplayItemTable(prev => (prev = false))
        setCustomerValidation(prev => (prev = false))
        setDisplayCustomerPackageTable(prev => (prev = false))
        setDurationWarning(prev => (prev = false))
        setGameDuration(prev => (prev = 30))
        setGameEndTime(prev => (prev = null))
        setGameStartTime(prev => (prev = null))
        setItemUnitValidation(prev => (prev = null))
        setItemSubUnitValidation(prev => (prev = null))
        setSelectedPreviousPackage(prev => (prev = []))
        setCustomStyles(
          prev =>
            (prev = {
              control: styles => ({ ...styles, color: "white" }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                const color = "white"
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? "gray"
                    : isSelected
                    ? "white"
                    : isFocused
                    ? "#566ce3"
                    : undefined,
                  color: isFocused ? "white" : "black",
                  cursor: isDisabled ? "not-allowed" : "default",
                  ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled
                      ? isSelected
                        ? "white"
                        : "#566ce3"
                      : undefined,
                  },
                }
              },
              multiValue: (styles, { data }) => {
                let color = ""
                let isSelectedGameNoTables = []
                if (preSaveColor.length === 0) {
                  color = "#eff2f7"
                } else {
                  isSelectedGameNoTables = preSaveColor.some(
                    selected => selected === data.label.props.children[1]
                  )
                  color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
                }
                return {
                  ...styles,
                  backgroundColor: color,
                }
              },
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: "black",
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: "black",
                ":hover": {
                  backgroundColor: "#f0b4b4",
                  color: "red",
                },
              }),
            })
        )
        if (res.data.status === 0) {
          showWarning(res.data.message)
          return
        } else {
          showNotification(
            res.data.message,
            res.data.status === 1 ? "Success" : "Error"
          )
        }
      })
      .catch(err => {
        console.log(err)
        showNotification(err.response?.data?.message, "Error")
      })
  }

  const handleMobileNumberChange = event => {
    const selectedNumber = event.target.value
    setSelectedMobileNumber(selectedNumber)
    fetchCustomerData(selectedNumber)
  }

  // GAMES

  useEffect(() => {
    loadAllGames()
    loadAllItems()
    loadAllGame()
  }, [selectedCustomer])

  const loadAllGames = async () => {
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_games"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      response.data.map(game => {
        game.label = game.table_number
        game.table_id = game._id
      })

      //setting the subtotal based on customer type, for all the game since the default price is already set from the backend

      if (selectedCustomer?.type === "Freind") {
        response.data.forEach(game => {
          game.game.sub_total = game.game.price_for_friend
        })
      } else if (selectedCustomer?.type === "Regular") {
        response.data.forEach(game => {
          game.game.sub_total = game.game.price_for_regular
        })
      }

      setAllGames(response.data)
      let GameArr = response.data.map(game => ({
        label: game.label,
        value: game.label,
      }))

      setSelected_game(prev => (prev = [])) //clearing the selected game state so that there is no duplicacy in game dropdown list
      setGameList(prev => (prev = GameArr)) //setting the game dropdown list
    } else {
      showNotification(response.message, "Error")
    }
  }

  // fetching all the games from game master

  const loadAllGame = async () => {
    let url = process.env.REACT_APP_BASEURL + "games/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      let customGameList = response.data
        .filter(game => game.is_active !== false)
        .map(game => ({
          label: (
            <>
              {SelectPhotoFormatter(game)}
              {game.name}
            </>
          ),
          value: game.name,
          id: game._id,
        }))
      setAllTheGames(prev => (prev = customGameList))
    } else {
      // showNotification(response.message, "Error")
      showWarning(response.message, "Warning")
    }
  }

  //Items
  const loadAllItems = async () => {
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_items"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      const filterData = response.data.filter(
        item => (item.is_active === true) && (item.is_menu_item === true || item.is_household_item === true)
      )
      filterData.map(item => {
        item.label = (
          <>
            {SelectPhotoFormatter(item)}
            {/* {`${item.name}-->[${item.section}] `}*/}
            { (item.available.in_unit === 0 && item.available.in_sub_unit === 0 && item.is_household_item!==true)  ? `${item.name}  [${item.section}] [Out of Stock]` : `${item.name}-->[${item.section}] `}
          </>
        )
        item.value = item.name
      })
      setAll_Items(filterData|| [])
      // console.log(all_Items)
    } else {
      console.log("Error")
    }
  }

  async function fetchAvailableGameTables(selectedGame, selectedArray) {
    let payload = {
      start_time: new Date(isoDate2),
      game_id: selectedGame.option.id,
    }

    // console.log(payload, "data payload")

    let selectedTable = allGameTables.filter(
      gameTable => selectedGame.option.id === gameTable.game.game_id 
    )

    let tableList = selectedTable.map(game => ({
      label: game.table_number,
      value: game.table_number,
      id: game._id,
      name: game.game.name,
      is_occupied: game.is_occupied
    }))

    let newAvailableTables = []
    let flag = false

    if (GamesInTable.length > 0) {
      for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < GamesInTable.length; j++) {
          if (tableList[i].label === GamesInTable[j].table_number) {
            flag = true
            break
          }
        }
        if (flag === true) {
          flag = false
        } else {
          newAvailableTables.push(tableList[i])
        }
      }

      tableList = newAvailableTables
    }
    setAvailableTables(prev => (prev = [...prev, ...tableList]))
    setAllAvailable(prev => (prev = [...prev, ...tableList]))
    setAllTableData(prev => (prev = [...selectedTable]))
    let newPreSaveColor = preSaveColor.filter(
      color => color !== selectedGame.option.label.props.children[1]
    )
    setPreSaveColor(prev => (prev = newPreSaveColor))
  }

  // ----------------------------------------------------

  function setTableData(packageObject) {
    let selectedPreviousPackage = results?.package_details?.find(
      item => packageObject?.label === item.package_details.package_name
    )

    selectedPreviousPackage?.package_details.item_details.forEach(item => {
      item.quantity = { in_unit: 0, in_sub_unit: 0 }
      item.new_available = { in_unit: 0, in_sub_unit: 0 }
      item.sub_total = 0
    })

    selectedPreviousPackage?.package_details.game_details.forEach(item => {
      item.new_duration = 0
      item.sub_total = 0
      item.requested_duration = 0
    })

    setSelectedPreviousPackage(prev => (prev = selectedPreviousPackage))
  }

  // --------------------------------------------------------------------------------

  const handleGameSelect = selected => {
    const gameTotal =
      selected.length === 0
        ? 0
        : selected.reduce((total, Obj) => total + Obj.game.sub_total, 0)

    setSelectedGamePrice(gameTotal)
    setGrandTotal(prev => (prev += gameTotal))
  }

  const handleItemSelect = selected => {
    const itemTotal = selected.reduce(
      (total, item) => total + item.sub_total,
      0
    )

    setSelectedItemPrice(prev => (prev = itemTotal))
    setGrandTotal(prev => (prev += itemTotal))
  }

  const loadAllBookings = async ( start,end) => {
    const url = process.env.REACT_APP_BASEURL + "bookings/get_datewise"
    try {
      preloader(true)
      let payload = {
        date_from: moment(start).format("YYYY-MM-DD"),
        date_to: moment(end).format("YYYY-MM-DD"),
      }
      const response = await axios.post(
        url,
        payload,
        {
          headers: {
            Authorization: `Bearer ${employeeToken}`,
          },
        }
      )
      // console.log(response.data.data, "all bookings")
      let customTitle
      let customTitle_2
      if (response.data.status === 1) {
        preloader(false)
        // console.log(response.data, "all booking response")
        const events = response.data.data
          .filter(
            item =>
              item.is_package_only === false && item.is_item_only === false
          )
          .map(event => {
            if (event.booking_status === "Completed") {
              event.backgroundColor = "#9873AC" //getRandomColor();
            } else if (event.booking_status === "Booked") {
              event.backgroundColor = "#9ec3ff"
            } else if (event.booking_status === "In Process") {
              event.backgroundColor = "#fffd9e"
            } else if (event.booking_status === "Cancelled") {
              event.backgroundColor = "#ff9e9e"
            } else if (event.booking_status === "Checked out") {
              event.backgroundColor = "#b0ff9e"
            } else if (event.booking_status === "Unpaid") {
              event.backgroundColor = "#C5C5C5"
            }

            customTitle = `${event.customer_details?.name} - ${event?.start_time}`
            // customTitle_2 = `${event.customer_details?.name} - ${event?.start_time}`
            return {
              id: event._id,
              title: event.customer_details?.name,
              start: event.start_time,
              end: event.end_time,
              backgroundColor: event.backgroundColor,
              extendedProps: {
                tableNumber: event?.game_details[0]?.table_number,
                customerName: event?.customer_details?.name,
                customerType: event?.customer_details?.customer_type,
                status: event?.booking_status,
                gameNames: event?.game_details,
                package: event?.package_details[0]?.game_details,
              },
            }
          })

        let customBookingData = response.data.data.map(
          booking => (booking = { ...booking, customTitle: customTitle })
        )

        //  console.log(customBookingData,"custome booking data")

        setBookings(prev => (prev = customBookingData))
        setCalendarEvents(events)
        setKeyChange(prev => (prev += 1))
      } else {
        preloader(false)
        showNotification(response.data.message, "Error")
      }
    } catch (error) {
      preloader(false)
      console.error("Error fetching data:", error)
      showNotification("An error occurred while fetching data.", "Error")
    }
  }
  // console.log(bookings,"all bookings")

  const renderEventContent = eventInfo => {
    const startTime = eventInfo.event.start.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })
    const endTime = eventInfo.event.end?.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    })

    // const tables = eventInfo.event._def.extendedProps.gameNames?.map(table => {
    //   return `<div>${table.table_number}</div>`;
    // }).join('');
    const tables = eventInfo.event._def.extendedProps.gameNames?.length>0 
    ?
    eventInfo.event._def.extendedProps.gameNames.map(table => `${table.table_number}`).join(', ')
    :
    eventInfo.event._def.extendedProps.package.map(table => `${table.table_number}`).join(', ');
    // <span>${startTime} - ${endTime}</span> - <span>${eventInfo.event.title} Tables: ${tables}</span>
    return {
      html: `
        <div style="font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          <span>${tables}</span>
          
          
        </div>
      `,
    }
  }

  // ------------------------------ FETCHING DETAILS FOR SELECTED CUSTOMER ----------------------------------------

  useEffect(() => {
    //first time get all details when compenent loads, to display the customer list
    // loadAllBookings()

    const fetchResults = async () => {
      const url = process.env.REACT_APP_BASEURL
      const payload = { mobile_no: query }
      try {
        const response = await axios.post(
          url + `bookings/get_customer_packages_booking`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        const filter = response.data.data.customer_details.filter(
          cust => cust.is_active === true
        )
        setCustomerArr(filter)
      } catch (err) {
        console.log(err)
      }
    }

    fetchResults()
  }, [])

  // console.log("Results", results);

  useEffect(() => {
    //load details of selected customer

    let payload = ""
    if (selectedCustomer !== null) {
      payload = { mobile_no: selectedCustomer.mobile }
    } else {
      // console.log("hello")
      return
    }
    // console.log(payload, "payload")

    const fetchResults = async () => {
      let url = process.env.REACT_APP_BASEURL

      axios
        .post(
          url + `bookings/get_customer_packages_booking`,
          // `http://localhost:3002/purchase_orders/search_item`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then(res => {
          console.log(res.data, "pkg")
          setResults(res.data.data)
          set_item_details(
            prev =>
              (prev =
                res.data.data.package_details[0]?.package_details?.item_details)
          )
          set_game_details(
            prev =>
              (prev =
                res.data.data?.package_details[0]?.package_details
                  ?.game_details)
          )

          //  const packages = res.data.data.package_details.map(pkg => {

          //       return {
          //      label : pkg.label = (
          //               <span style={{display:"flex", justifyContent:"space-between"}}>
          //                 <span>{pkg?.package_details?.package_name}</span>
          //                 <span>{` [${moment(pkg.expiry_date).format("DD-MM-YYYY")}]`}</span>
          //               </span>
          //             ),
          //       value:  pkg.value = pkg?.package_details?.package_name,
          //       _id: pkg.package_details?.package_details?._id
          //       }
          //     })
          const packages = res.data.data.package_details.map(pkg => {
            return {
              label: pkg.package_details.package_name,
              value: pkg.package_details.package_id,
              _id: pkg.package_details._id,
            }
          })

          // console.log(res.data.data.package_details,"response")
          setAllPackages(packages)
        })
        .catch(err => console.log(err))
    }

    const debounceTimer = setTimeout(() => {
      fetchResults()
    }, debounceDelay)

    return () => clearTimeout(debounceTimer)
  }, [selectedCustomer])

  // --------------------------------------------------------------------------------

  useEffect(() => {
    loadAllBookings()
  }, [setAddBookingClickTracker])

  function openBookingModal(info) {
    // console.log(info, "info")
    const date = new Date(info)
    const userTimeZoneOffset = new Date(info).getTimezoneOffset()

    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()

    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`

    const isoDate = `${formattedDate.substr(6, 4)}-${formattedDate.substr(
      3,
      2
    )}-${formattedDate.substr(0, 2)}T${formattedDate.substr(11)}:00`
    const isoDate2 = date.toISOString()
    console.log(isoDate)
    setIsoDate2(prev => (prev = isoDate2))
    setStartTime(prev => (prev = isoDate))
    setPrevStart(prev => (prev = isoDate))
    // setTimezone(prev => prev = userTimeZoneOffset)

    setToggleModal(prev => (prev = !prev))
  }

  const handleMobileInputChange = e => {
    setSelectedTable(prev => (prev = []))
    setAvailableTables(prev => (prev = []))
    setTablesDisplay(prev => (prev = []))
    setAllTableData(prev => (prev = []))
  }
  // ----------------------------------------------------------


  function showPhotoFormatterpkggame(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
         <br />
        {row.table_number } {`${row.name}`}
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

  function showGamePhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.game.image || row.game.image}
          alt={row.game.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <br />
        {row.game?.name} {`(${row.table_number})`}
      </>
    )
  }

  // -----------------------------------------------------------

  const PackageItemDetails = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Name"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Quantity"),
      dataField: "quantity.in_sub_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_sub_unit + " " + row.sub_unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  const PackageGameDetails = [
    {
      dataField: "game_id",
      hidden: true,
    },
    {
      dataField: "game_id",
      formatter: (cell, row, rowIndex) => {
        // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Minutes"),
      dataField: "minutes",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Price"),
      dataField: "price",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  function handleNewQuantityUnitChange(e, rowIndex, row, editorProps) {
    const { value } = e.target

    editorProps.onUpdate(+value)

    let a = selectedPreviousPackage

    // console.log(a, "aaaaa")

    a.package_details.item_details.forEach(item => {
      if (item.item_id === row.item_id) {
        if (row.sub_unit === "" || row.sub_unit === null) {
          const formula = item.formula || 1

          // Convert available to sub-units
          const availableInSubUnits =
            item.available.in_unit * formula + item.available.in_sub_unit

          // Convert quantity to sub-units
          const quantityInSubUnits =
            item.quantity.in_unit * formula + item.quantity.in_sub_unit

          // Calculate the new available sub-units
          const newAvailableInSubUnits =
            availableInSubUnits - quantityInSubUnits

          // Convert new available sub-units back to units and sub-units
          const newAvailableInUnits = Math.floor(
            newAvailableInSubUnits / formula
          )
          const newAvailableSubUnits = newAvailableInSubUnits % formula

          row.new_available.in_unit = newAvailableInUnits
          row.new_available.in_sub_unit = newAvailableSubUnits
        } else {
          const formula = item.formula || 1

          // Convert available to sub-units
          const availableInSubUnits =
            item.available.in_unit * formula + item.available.in_sub_unit

          // Convert quantity to sub-units
          const quantityInSubUnits =
            item.quantity.in_unit * formula + item.quantity.in_sub_unit

          // Calculate the new available sub-units
          const newAvailableInSubUnits =
            availableInSubUnits - quantityInSubUnits

          // Convert new available sub-units back to units and sub-units
          const newAvailableInUnits = Math.floor(
            newAvailableInSubUnits / formula
          )
          const newAvailableSubUnits = newAvailableInSubUnits % formula

          row.new_available.in_unit = newAvailableInUnits
          row.new_available.in_sub_unit = newAvailableSubUnits
        }
      }
    })

    setSelectedPreviousPackage(prev => {
      prev = { ...a }
      return prev
    })
  }

  function handleNewQuantitySubUnitChange(e, rowIndex, row, editorProps) {
    const { value } = e.target
    if (
      +value >
      Number(+row.formula * +row.available.in_unit + +row.available.in_sub_unit)
    ) {
      editorProps.onUpdate(+value)
      return
    }

    let subUnits = 0
    let units = 0

    if (+value >= Number(row.formula)) {
      subUnits = +value % Number(row.formula)
      units = Math.floor(+value / Number(row.formula))
    } else {
      subUnits = +value
    }

    editorProps.onUpdate(subUnits)

    let a = selectedPreviousPackage

    a.package_details.item_details.forEach(item => {
      if (item.item_id === row.item_id) {
        if (
          +value >= +row.formula &&
          item.quantity.in_unit + units <= row.available.in_unit
        ) {
          item.quantity.in_unit += units
        }
        const formula = item.formula || 1
        const availableInSubUnits = item.available.in_unit * formula + item.available.in_sub_unit
        const quantityInSubUnits = item.quantity.in_unit * formula + item.quantity.in_sub_unit
        const newAvailableInSubUnits = availableInSubUnits - quantityInSubUnits
        const newAvailableInUnits = Math.floor(newAvailableInSubUnits / formula)
        const newAvailableSubUnits = newAvailableInSubUnits % formula
        row.new_available.in_unit = newAvailableInUnits
        row.new_available.in_sub_unit = newAvailableSubUnits
      }
    })

    setSelectedPreviousPackage(prev => {
      prev = { ...a }
      return prev
    })
  }

  //  function to remove the on-scroll value change from editable table field
  function preventValueChangeOnScroll(e) {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  //FOR ADD NEW PACKAGE DETAILS
  const addPackageItemDetailsColumn = [
    {
      dataField: "item_id",
      hidden: true,
    },
    {
      dataField: "item_id",
      formatter: (cell, row, rowIndex) => {
        // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      editable: false,
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      text: props.t("Formula"),
      dataField: "formula",
      sort: false,
      editable: false,
      formatter: (cell, row) => {
        return (
          <>
            {row.sub_unit
              ? "1 " + row.unit + " = " + row.formula + " " + row.sub_unit
              : "1 " + row.unit}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
    },
    // {
    //   text: props.t("Available Quantity(in unit)"),
    //   dataField: "available.in_unit",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     return row.available.in_unit + " " + row.unit
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    //   editable: false,
    // },
    // {
    //   text: props.t("Available Quantity(in sub unit)"),
    //   dataField: "available.in_sub_unit",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     return row.available.in_sub_unit + " " + row.sub_unit
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    //   editable: false,
    // },
    {
      dataField: "available.in_unit",
      text: "Available",
      formatter: (cell, row) => {
        if(row.is_household_item===false || row.is_household_item===undefined){
          return (
            <>
              {row.available?.in_unit +
                "  " +
                row.unit +
                " " +
                (row.sub_unit && row.sub_unit != "undefined"
                  ? row.available.in_sub_unit + " " + row.sub_unit
                  : "")}
            </>
          )
        }else{
          return "N/A"
        }
      
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      text: props.t("Qty.(in unit)"),
      dataField: "quantity.in_unit",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.quantity.in_unit === 0 ? "#E57373" : "",
              fontWeight: row.quantity.in_unit === 0 ? 600 : 0,
              width: "50px",
            }}
          >{`${row.quantity.in_unit} ${row.unit}`}</p>
        )
      },
      sort: false,
      validator: (newValue, row, column) => {
        const isHouseholdItem = row.is_household_item === true;
        if (!isHouseholdItem) {
          if (+newValue > Number(row.available.in_unit)) {
            return {
              valid: false,
              message: "Cannot exceed available units",
            };
          }
      
          if (+newValue < 0) {
            return {
              valid: false,
              message: "Cannot be a negative value",
            };
          }
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: "Only numbers are allowed.",
            };
          }
          if (newValue % 1 != 0) {
            return {
              valid: false,
              message: "Decimals not allowed.",
            };
          }
        }
        return true;
      }, 
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          onWheel={preventValueChangeOnScroll}
          // ref={inputRef}
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          defaultValue={value}
          onBlur={e => {
            handleNewQuantityUnitChange(e, rowIndex, row, editorProps)
            // editorProps.onUpdate(+e.target.value)

            // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: true,
    },
    {
      text: props.t("Qty.(in sub unit)"),
      dataField: "quantity.in_sub_unit",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.quantity.in_sub_unit === 0 ? "#E57373" : "",
              fontWeight: row.quantity.in_sub_unit === 0 ? 600 : 0,
              width: "50px",
            }}
          >{`${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>
        )
      },
      sort: false,
      validator: (newValue, row, column) => {
        const isHouseholdItem = row.is_household_item === true;
       if(!isHouseholdItem){
        if (     +newValue >     Number(       +row.formula * +row.available.in_unit + +row.available.in_sub_unit     )   ) {
          return {
            valid: false,
            message: "Cannot exceed available quantity",
          }
        }
        if (
          +newValue >
          Number(
            (+row.available.in_unit - +row.quantity.in_unit) * +row.formula +
              +row.available.in_sub_unit
          )
        ) {
          return {
            valid: false,
            message: "Cannot exceed more",
          }
        }
        if (+newValue < 0) {
          return {
            valid: false,
            message: "Cannot be a negative value",
          }
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          }
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
       }
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          onWheel={preventValueChangeOnScroll}
          // ref={inputRef}
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          defaultValue={value}
          onBlur={e => {
            handleNewQuantitySubUnitChange(e, rowIndex, row, editorProps)
            // editorProps.onUpdate(+e.target.value)

            // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: true,
    },
    // {
    //   text: props.t("Selling Price"),
    //   dataField: "selling_price",
    //   sort: false,
    //   // formatter: (cell, row) => {
    //   //   return row.available.in_sub_unit + " " + row.sub_unit
    //   // },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    //   editable: false
    // },
    // {
    //   text: props.t("Sub Total"),
    //   dataField: "sub_total",
    //   sort: false,
    //   // formatter: (cell, row) => {
    //   //   return row.available.in_sub_unit + " " + row.sub_unit
    //   // },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    //   editable: false
    // },
  ]

  // -----------------------------------------------------------------------------------

  async function fetchAvailableTablesForPrevPkgGames(row) {
    let payload = {
      start_time: new Date(isoDate2),

      end_time: new Date(bookingEndTime),
      game_id: row.game_id,
    }

    let url = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"

    let response = await postSubmitForm(url, payload)

    if (response && response.status === 1) {
      // console.log(response.data, 'data of available tables');
      // console.log(finalSelectedGames, "final selected games");

      let flag = false
      let freeTable = []

      //this nested for loop will extract the tables not selected by the user in games from the available free tables that we get from available tables API.

      for (let i = 0; i < response.data.length; i++) {
        for (let j = 0; j < finalSelectedGames.length; j++) {
          if (
            response.data[i].table_number === finalSelectedGames[j].table_number
          ) {
            flag = true
            break
          }
        }

        if (flag === true) {
          flag = false
        } else {
          freeTable.push(response.data[i])
        }
      }

      freeTable = freeTable.map(game => ({
        label: game.table_number,
        value: game.table_number,
        id: game._id,
        name: game.game.name,
      }))

      setPrevPkgAvailableTables(prev => (prev = freeTable))
    } else {
      // console.log("Error")
      showWarning(response.message, "Warning")
    }
  }

  // -----------------------------------------------------------------------------------

  const  handleRequestedGameDuration = async(e, rowIndex, row, editorProps)=> {
    // return
    const { value } = e.target
    const requestDura = +value;      
    const startTIme = new Date(startTime);
    const updatedET = new Date(startTIme.getTime() + requestDura * 60000);
    row.table_start_time = startTIme
    let payload = {
      start_time: startTIme,
      end_time: updatedET,
      game_id: row.game_id,
  };

  let PieUrl =
      process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(PieUrl, payload)
    if ( res && res.status === 1 &&
      res.data.some(item => item.table_number === row.table_number)
    ) {
    const duration = +value 
    editorProps.onUpdate(duration)
    let a = selectedPreviousPackage
    const NewStartTime = new Date(startTIme)
    const durationInMinutes = duration
    const NewendTime = new Date(
      NewStartTime.getTime() + durationInMinutes * 60000
    )
    if (+value > 0) {
      a.package_details.game_details.forEach(item => {
        if (item.game_id === row.game_id) {
          item.new_duration = row.duration - +value
          item.table_end_time = NewendTime
        }
      })
    } else if (+value === 0) {
      a.package_details.game_details.forEach(item => {
        if (item.game_id === row.game_id) {
          item.table_number = ""
        }
      })
    }
    const minimumStartTime = findMinimumStartTime(GamesInTable);
   
    if (minimumStartTime) {
      const newFormattedTime = moment(minimumStartTime).format("YYYY-MM-DD HH:mm:ss");
      setStartTime(newFormattedTime);
    } else {

      console.log("No start times available in game tables");
    }
    const maxEndTime = findMaxEndTime(GamesInTable);
    if (maxEndTime) {
      const newFormattedTime = moment(maxEndTime).format("YYYY-MM-DD HH:mm:ss");
      setBookingEndTime(newFormattedTime);
    } else {
      setBookingEndTime(prev=>prev)
      console.log("No End times available in game tables");
    }
    
  }else{
    showWarning("Table is busy", "warning")
  }
 }

  const handlePackageStartTime= async (e, rowIndex, row, editorProps, value) => {
    // console.log(row,"RRRRRR");
    // return
    const { value: selectedValue } = e.target
    if (!selectedValue || isNaN(Date.parse(selectedValue))) {
      return
    }

    const durationInMinutes = row.requested_duration
    const NewstartTime = new Date(selectedValue)
    const NewendTime = new Date(
      NewstartTime.getTime() + durationInMinutes * 60000
    )
    row.table_end_time = NewendTime
 
    
    let payload = {
      start_time: NewstartTime,
      end_time: NewendTime,
      game_id: row.game_id,
    }
    let PieUrl =
      process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(PieUrl, payload)
    if (
      res &&
      res.status === 1 &&
      res.data.some(item => item.table_number === row.table_number)
    ) {
      const updatedTableData = { ...row }
      const rowData = updatedTableData
      rowData.table_start_time = selectedValue
      editorProps.onUpdate(new Date(selectedValue))
      const minimumStartTime = findMinimumStartTimePKG(selectedPreviousPackage);
     console.log(minimumStartTime,"MSt");
      if (minimumStartTime) {
        const newFormattedTime = moment(minimumStartTime).format("YYYY-MM-DD HH:mm:ss");
        setStartTime(newFormattedTime);
      } else {

        console.log("No start times available in game tables");
      }
      const maxEndTime = findMaxEndTime(GamesInTable);
      if (maxEndTime) {
        const newFormattedTime = moment(maxEndTime).format("YYYY-MM-DD HH:mm:ss");
        setBookingEndTime(newFormattedTime);
      } else {
        setBookingEndTime(prev=>prev)
        console.log("No End times available in game tables");
      }
    } else {
      showWarning("Table is busy", "warning")
    }
  }


  const addPackageGameDetailsColumn = [
    {
      dataField: "game_id",
      hidden: true,
    },
    {
      dataField: "game_id",
      formatter: (cell, row, rowIndex) => {
        // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      editable: false,
    },
    {
      text: props.t("Game"),
      formatter: showPhotoFormatterpkggame,
      dataField: "image",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      text: props.t("Total Duration"),
      dataField: "duration",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
   
    // {
    //   text: props.t("Available Duration"),
    //   dataField: "new_duration"||0,
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    //   editable: false,
    // },
   
    {
      dataField: "table_start_time",
      text: "Start Time",
      formatter: (cell, row) => {
        return moment(row?.table_start_time).format("HH:mm")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        if (row.table_start_time || new Date (startTime)) {
          return (
            <input
              style={{
                width: "100px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="datetime-local"
              defaultValue={row.table_start_time || new Date (startTime)}
              onBlur={e => {
                handlePackageStartTime(e, rowIndex, row, editorProps, value)
              }}
              onFocus={e => e.target.select()}
              autoFocus
            />
          )
        } else {
          return <div style={{ color: "red" }}>Not Available</div>
        }
      },
      editable: true,
    },

    {
      text: props.t("Requested Duration"),
      dataField: "requested_duration",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.requested_duration === 0 ? "#E57373" : "",
              fontWeight: row.requested_duration === 0 ? 600 : 0,
              width: "50px",
            }}
          >{`${row.requested_duration} min`}</p>
        )
      },
      sort: false,
      validator: (newValue, row, column) => {
        if (+row.duration === 0 && +newValue > 0) {
          return {
            valid: false,
            message: "Game fully consumed",
          }
        }
        if (+newValue > +row.duration) {
          return {
            valid: false,
            message: "can't exceed available duration",
          }
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          }
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          onWheel={preventValueChangeOnScroll}
          // ref={inputRef}
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          defaultValue={value}
          onBlur={e => {
            handleRequestedGameDuration(e, rowIndex, row, editorProps)
            // fetchAvailableTablesForPrevPkgGames(row);
            // editorProps.onUpdate(+e.target.value)
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: true,
    },
    {
      dataField: "table_end_time",
      text: "End Time",
      formatter: (cell, row) => {
        return moment(row?.table_end_time).format("HH:mm")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      text: props.t("Selected Table"),
      dataField: availablePrevPkgGameTables.length > 0 ? "table_number" : "",
      // formatter: (cell, row) => {
      //   return  <Badge className="badge-soft-danger">{"Not selected yet"}</Badge>

      // },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      text: props.t("Tables"),
      dataField: prevPkgAvailableTables.length > 0 ? "table_number" : "",
      formatter: (cell, row) => {
        return (
          <Button
            disabled={row.duration === 0}
            onClick={() => {
              setPrevPkgGameSelectModal(prev => (prev = !prev))
              fetchAvailableTablesForPrevPkgGames(row)
            }}
            style={{ height: "40px" }}
          >
            Select
          </Button>
        )
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
  ]

  // TAB funtions

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function showBookingDetails(clickInfo, id) {
    setBookingDetailsModal(prev => (prev = !prev))

    const selectedBookingEvent = bookings.find(
      booking =>
        booking._id === (id === "" ? clickInfo?.event?._def?.publicId : id)
    )

    // console.log(selectedBookingEvent, "selected booking event")
    setSelectedCalendarEvent(prev => (prev = selectedBookingEvent))
    setBookedCustomerDetails(
      prev => (prev = selectedBookingEvent?.customer_details)
    )
    setBookedItemsDetails(prev => (prev = selectedBookingEvent?.item_details))
    setBookedGameDetails(prev => (prev = selectedBookingEvent?.game_details))
    setBookedPackageDetails(
      prev => (prev = selectedBookingEvent?.package_details)
    )

    // ----------------------------------------------
  }

  //load All Customers
  const [allCustomer, setAllCustomer] = useState([])
  const loadAllCustomers = async () => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "customers/getall"
    let response = await postSubmitForm(url, "")
    const sortedList = sortBy(response.data, "name")
    if (response.status === 1) {
      Loader(false)
      const filtered = response.data.filter(cust => cust.is_active === true)
      setCustomerArr(filtered)
      setAllCustomer(sortedList)
    } else {
      Loader(false)
      showNotification(response.message, "Error")
    }
  }

  // insert Customer
  const handleSubmit = async (e, v) => {
    try {
      const object = {
        name: v.name,
        mobile: v.mobile,
        email: v.email,
        qatar_id: v.qatar_id,
        passport: v.passport,
        date_of_birth: v.date_of_birth,
        address: v.address,
        is_wallet_eligible: is_wallet,
        customer_type: v.customer_type || "Regular",
      }
      // console.log(object);
      let url = process.env.REACT_APP_BASEURL + "customers/insert"
      // let url = "http://localhost:3002/customers/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          refContainer.current.reset()
          showNotification(res.message, "Success")
          setAddCustomerModal(false)
          loadAllCustomers()
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
      console.log(error)
    }
  }

  // booked bookings details table -------------------------------
  const bookedPackageDetailscolumns = [
    {
      dataField: "pacakge_id",
      hidden: true,
    },
    {
      dataField: "package_name",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: "S.N",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      dataField: "package_name",
      text: "Package Name",
    },
    {
      dataField: "item_details",
      text: "Item Details",
      formatter: (cell, row) => {
        return (
          <ul>
            {cell.map((item, index) => (
              <li key={index}>
                <strong>{item.name}</strong>
                <br />
                Selling Price: {item.selling_price}
                {/* <br /> */}
                {/* Cost Price: {item.cost_price} */}
                <br />
                Section: {item.section}
                <br />
                Quantity:{" "}
                {item.quantity?.in_unit +
                  " " +
                  item?.unit +
                  " " +
                  item.quantity?.in_sub_unit +
                  " " +
                  item?.sub_unit}
              </li>
            ))}
          </ul>
        )
      },
    },
    {
      dataField: "game_details",
      text: "Game Details",
      formatter: cell => {
        return (
          <ul>
            {cell.map((game, index) => (
              <li key={index}>
                <strong>{game.name}</strong>
                <br />
                Price: {game.price}
                {/* <br /> */}
                {/* Duration: {game.minutes} */}
                <br />
                Requested Duration: {game.requested_duration}
              </li>
            ))}
          </ul>
        )
      },
    },
  ]
  // -----------------------------------------------------------------

  function DeleteSelectedGame(cell, row) {
    const handleDelete = () => {
      setGamesInTable(prevItems => {
        return prevItems.filter(item => item._id !== row._id)
      })
      // calculateEndTime()
      // calculateStartTime()
      }
    return (
      <i
        className="fas fa-trash ms-3 fs-5 text-danger"
        title="Click to Receive"
        style={{ cursor: "pointer" }}
        onClick={handleDelete}
      ></i>
    )
  }

  const  handleDurationChange  = async (e, rowIndex, row, editorProps)=>{
    const { value } = e.target
    const requestDura = (value);      
    const endTime = new Date(row.game.table_start_time);
    const updatedET = new Date(endTime.getTime() + requestDura * 60000);

    let payload = {
        start_time: row.game.table_start_time,
        end_time: updatedET,
        game_id: row.game.game_id,
    };

    let PieUrl =
      process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(PieUrl, payload)
    if ( res && res.status === 1 &&
      res.data.some(item => item.table_number === row.table_number)
    ) {
    const duration = +value 
    editorProps.onUpdate(duration)

    const NewStartTime = new Date(row?.game.table_start_time)
    const durationInMinutes = row?.game.duration
    const NewendTime = new Date(
      NewStartTime.getTime() + durationInMinutes * 60000
    )

    let updatedGamesInTable = GamesInTable.map(item => {
      if (item._id === row._id) {
        if (row.game.name.includes("VIP")) {
          let price = 0
          if (selectedCustomer?.type === "Friend") {
            price = row.game.price_for_friend
          } else if (selectedCustomer?.type === "Regular") {
            price = row.game.price_for_regular
          } else if (selectedCustomer?.type === "VIP") {
            price = 0
          }
          if (+value > 60) {
            let sixtyMin = 60
            let rem = +value - sixtyMin
            item.game.sub_total = (150 / 60) * sixtyMin + (100 / 60) * rem
            item.game.sub_total = +item.game.sub_total.toFixed(2)
          } else {
            item.game.sub_total = (150 / 60) * +value
            item.game.sub_total = +item.game.sub_total.toFixed(2)
          }
        } else {
          let price = 0
          if (selectedCustomer?.type === "Friend") {
            price = row.game.price_for_friend
          } else if (selectedCustomer?.type === "Regular") {
            price = row.game.price_for_regular
          } else if (selectedCustomer?.type === "VIP") {
            price = 0
          }
          item.game.sub_total = (price / 60) * +value
          item.game.sub_total = +item.game.sub_total.toFixed(2)
        }
        item.game.duration = duration
        item.game.table_end_time = NewendTime.toISOString()
        // CalcEndTime(
        //   startTime,
        //   +e.target.value,
        //   setBookingEndTime,
        //   bookingEndTime
        // )
      }
      return item
    })
    // console.log(GamesInTable,"GTTT");
    const maxEndTime = findMaxEndTime(GamesInTable);
    if (maxEndTime) {
      const newFormattedTime = moment(maxEndTime).format("YYYY-MM-DD HH:mm:ss");
      setBookingEndTime(newFormattedTime);
    } else {
      setBookingEndTime(prev=>prev)
      console.log("No End times available in game tables");
    }
    handleGameSelect(updatedGamesInTable)
    }else{
      showWarning("Table is busy","warning")
    }

   
  }

  const handleQuantityChange = (e, rowIndex, row, editorProps) => {
    const { value } = e.target

    editorProps.onUpdate(+value)

    if (selectedCustomer?.type === "VIP") {
      return
    }

    let a = itemsInTable

    a.forEach(item => {
      if (item._id === row._id) {
        if (row.sub_unit === "" || row.sub_unit === null) {
          item.sub_total = +value * row.selling_price
          item.sub_total = +item.sub_total.toFixed(2)
        } else {
          item.sub_total =
            (+value * Number(row.formula) + Number(row.quantity.in_sub_unit)) *
            row.selling_price
          item.sub_total = +item.sub_total.toFixed(2)
        }
      }
    })

    setItemsInTable(prev => {
      prev = [...a]
      return prev
    })
    handleItemSelect(a)
  }

  const handleSubUnitQuantityChange = (e, rowIndex, row, editorProps) => {
    let { value } = e.target

    if (
      +value >
      Number(+row.formula * +row.available.in_unit + +row.available.in_sub_unit)
    ) {
      editorProps.onUpdate(+value)
      return
    }

    let subUnits = 0
    let units = 0

    if (+value >= Number(row.formula)) {
      subUnits = +value % Number(row.formula)
      units = Math.floor(+value / Number(row.formula))
    } else {
      subUnits = +value
    }

    editorProps.onUpdate(subUnits)

    let a = itemsInTable

    a.forEach(item => {
      if (item._id === row._id) {
        if (
          +value >= +row.formula &&
          item.quantity.in_unit + units <= row.available.in_unit
        ) {
          item.quantity.in_unit += units
        }

        if (selectedCustomer?.type === "VIP") {
          return
        }

        item.sub_total =
          (subUnits + Number(row.quantity.in_unit) * Number(row.formula)) *
          row.selling_price
        item.sub_total = +item.sub_total.toFixed(2)
      }
    })

    setItemsInTable(prev => {
      prev = [...a]
      return prev
    })
    handleItemSelect(a)
  }

  const findMinimumStartTime = (gameTables) => {
    let gamStartTime = null;
    let pkgStartTime = null;
  
    if (selectedPreviousPackage?.package_details?.game_details?.length > 0) {
      selectedPreviousPackage?.package_details?.game_details.forEach((table) => {
        const tableStartTime = new Date(table.table_start_time);
        if (!pkgStartTime || tableStartTime < pkgStartTime) {
          pkgStartTime = tableStartTime;
        }
      });
    }
  
    gameTables.forEach((table) => {
      const tableStartTime = new Date(table.game.table_start_time);
      if (!gamStartTime || tableStartTime < gamStartTime) {
        gamStartTime = tableStartTime;
      }
    });
  
    if (pkgStartTime && pkgStartTime < gamStartTime) {
      return pkgStartTime;
    } else {
      return gamStartTime;
    }
  };

  const findMinimumStartTimePKG = (gameTables) => {
    const pkgStartTime = gameTables?.package_details?.game_details.reduce((minStartTime, table) => {
        const tableStartTime = new Date(table.table_start_time);

        if (!minStartTime || tableStartTime < minStartTime) {
            return tableStartTime;
        } else {
            return minStartTime;
        }
    }, null);

    return pkgStartTime;
  };
  
  const findMaxEndTime = (gameTables) => {
    let gamEndTime = null;
    let pkgEndTime = null;
  
    if (selectedPreviousPackage?.package_details?.game_details?.length > 0) {
      selectedPreviousPackage.package_details.game_details.forEach((table) => {
        const tableEndTime = new Date(table.table_end_time);
        if (!pkgEndTime || tableEndTime > pkgEndTime) {
          pkgEndTime = tableEndTime;
        }
      });
    }
  
    gameTables.forEach((table) => {
      const tableEndTime = new Date(table.game.table_end_time);
      if (!gamEndTime || tableEndTime > gamEndTime) {
        gamEndTime = tableEndTime;
      }
    });
  
    if (pkgEndTime && pkgEndTime > gamEndTime) {
      return pkgEndTime;
    } else {
      return gamEndTime;
    }
  };

  const handleStartTime = async (e, rowIndex, row, editorProps, value) => {
    const { value: selectedValue } = e.target
    if (!selectedValue || isNaN(Date.parse(selectedValue))) {
      return
    }

    const durationInMinutes = row.game.duration
    const NewstartTime = new Date(selectedValue)
    const NewendTime = new Date(
      NewstartTime.getTime() + durationInMinutes * 60000
    )
    row.game.table_end_time = NewendTime
 
    
    let payload = {
      start_time: NewstartTime,
      end_time: NewendTime,
      game_id: row.game.game_id,
    }
    let PieUrl =
      process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(PieUrl, payload)
    if (
      res &&
      res.status === 1 &&
      res.data.some(item => item.table_number === row.table_number)
    ) {
      const updatedTableData = { ...row }
      const rowData = updatedTableData
      rowData.game.table_start_time = selectedValue
      editorProps.onUpdate(new Date(selectedValue))
      const minimumStartTime = findMinimumStartTime(GamesInTable);
     
      if (minimumStartTime) {
        const newFormattedTime = moment(minimumStartTime).format("YYYY-MM-DD HH:mm:ss");
        setStartTime(newFormattedTime);
      } else {

        console.log("No start times available in game tables");
      }
      const maxEndTime = findMaxEndTime(GamesInTable);
      if (maxEndTime) {
        const newFormattedTime = moment(maxEndTime).format("YYYY-MM-DD HH:mm:ss");
        setBookingEndTime(newFormattedTime);
      } else {
        setBookingEndTime(prev=>prev)
        console.log("No End times available in game tables");
      }
    } else {
      showWarning("Table is busy", "warning")
    }
  }

  const addGameDetailsColumns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: "S.N",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      editable: false,
    },

    {
      dataField: "game.image",
      text: "Game",
      formatter: showGamePhotoFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
   
    {
      dataField:
        selectedCustomer?.type === "Friend"
          ? "game.price_for_friend"
          : selectedCustomer?.type === "Regular"
          ? "game.price_for_regular"
          : "game.price_for_vip",
      // formatter: (cell, row) => selectedCustomer === "Friend" ? row.game.price_for_friend : row.game.price_for_regular,
      text: "Game Price",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "game.table_start_time",
      text: "Start Time",
      formatter: (cell, row) => {
        return moment(row.game?.table_start_time).format("HH:mm")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      // validator: (newValue, row, column) => {
      //     if (newValue < startTime) {
      //       return {
      //         valid: false,
      //         message: "Time cannot be less than booking start time",
      //       }
      //     }
      //     return true
      //   },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        if (row.game?.table_start_time) {
          return (
            <input
              style={{
                width: "100px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="datetime-local"
              defaultValue={value}
              onBlur={e => {
                handleStartTime(e, rowIndex, row, editorProps, value)
              }}
              onFocus={e => e.target.select()}
              autoFocus
            />
          )
        } else {
          return <div style={{ color: "red" }}>Not Available</div>
        }
      },
      editable: true,
    },

    {
      dataField: "game.duration",
      text: "Duration",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.game.duration === 0 ? "#E57373" : "",
              fontWeight: row.game.duration === 0 ? 600 : 0,
              width: "50px",
            }}
          >{`${row.game.duration} min`}</p>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      validator: (newValue, row, column) => {
        //console.log(newValue)
        if (+newValue < 30) {
          return {
            valid: false,
            message: "Minimum Playing Time is 30 Minutes",
          }
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          }
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => (
        <input
          // disabled={selectedCustomer?.type === "VIP"}
          onWheel={preventValueChangeOnScroll}
          style={{
            width: "50px",
            height: "40px",
            border: "1px solid lightGray",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          type="number"
          defaultValue={value}
          onBlur={e => {
            //console.log(e.target.value)
            handleDurationChange(e, rowIndex, row, editorProps)
            // editorProps.onUpdate(+e.target.value)
          }}
          onFocus={e => e.target.select()} // Retain focus on input field
          autoFocus
        />
      ),

      editable: true,
    },
    {
      dataField: "game.table_end_time",
      text: "End Time",
      formatter: (cell, row) => {
        return moment(row.game?.table_end_time).format("HH:mm")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "game.sub_total",
      formatter: (cell, row) => row.game.sub_total,
      text: "Sub Total",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "",
      formatter: DeleteSelectedGame,
      text: "Action",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
  ]

  const addItemsDetailsColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: "S.N",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      editable: false,
    },

    {
      dataField: "image_url",
      text: "Image",
      formatter: showPhotoFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },

    {
      dataField: "name",
      text: "Item Name",
      formatter: (cell, row) => row.name,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },

    // {
    //   dataField: "Category",
    //   text: "category",
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "7%" }
    //   },
    //   editable: false
    // },
    {
      dataField: "Section",
      formatter: (cell, row) => row.section,
      text: "section",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "available.in_unit",
      text: "Available",
      formatter: (cell, row) => {
        if(row.is_household_item===false || row.is_household_item===undefined){
          return (
            <>
              {row.available?.in_unit +
                "  " +
                row.unit +
                " " +
                (row.sub_unit && row.sub_unit != "undefined"
                  ? row.available.in_sub_unit + " " + row.sub_unit
                  : "")}
            </>
          )
        }else{
          return "N/A"
        }
      
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "selling_price",
      formatter: (cell, row) =>
        selectedCustomer?.type === "VIP" ? 0 : row?.selling_price,
      text: "Selling Price",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      dataField: "quantity.in_unit",
      text: "Qty.(in unit)",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.quantity.in_unit === 0 ? "#E57373" : "",
              fontWeight: row.quantity.in_unit === 0 ? 600 : 0,
              // width: "50px",
            }}
          >{`${row.quantity.in_unit} ${row.unit}`}</p>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: true,
      validator: (newValue, row, column) => {
        const isHouseholdItem = row.is_household_item === true;
        if (!isHouseholdItem) {
          if (+newValue > Number(row.available.in_unit)) {
            return {
              valid: false,
              message: "Cannot exceed available units",
            };
          }
      
          if (+newValue < 0) {
            return {
              valid: false,
              message: "Cannot be a negative value",
            };
          }
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: "Only numbers are allowed.",
            };
          }
          if (newValue % 1 != 0) {
            return {
              valid: false,
              message: "Decimals not allowed.",
            };
          }
        }
        return true;
      },   
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const isHouseholdItem = row.is_household_item === true;
        if (!isHouseholdItem) {
          const availableItems = row.available;
          const isAvailable = availableItems && availableItems.in_unit > 0;
      
          if (isAvailable) {
            return (
              <input
                style={{
                  width: "50px",
                  height: "40px",
                  border: "1px solid lightGray",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                type="number"
                onBlur={(e) => {
                  handleQuantityChange(e, rowIndex, row, editorProps);
                }}
                onFocus={(e) => e.target.select()} // Retain focus on input field
                autoFocus
              />
            );
          } else {
            return <div style={{ color: "red" }}>Not Available</div>;
          }
        } else {
          return (
            <input
              style={{
                width: "50px",
                height: "40px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="number"
              onBlur={(e) => {
                handleQuantityChange(e, rowIndex, row, editorProps);
              }}
              onFocus={(e) => e.target.select()} 
              autoFocus
            />
          );
        }
      },
    },

    {
      dataField: "quantity.in_sub_unit",
      text: "Qty.(in sub-unit)",
      formatter: (cell, row) => {
        if(row.formula<=1){
          return "N/A"
        }
        return (
          <p
            style={{
              color: row.quantity.in_sub_unit === 0 ? "#E57373" : "",
              fontWeight: row.quantity.in_sub_unit === 0 ? 600 : 0,
              width: "50px",
            }}
          >{`${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: (cell, row) =>
        row.sub_unit === "" || row.sub_unit === null ? false : true,
      validator: (newValue, row, column) => {
        const isHouseholdItem = row.is_household_item === true;
       if(!isHouseholdItem){
        if (     +newValue >     Number(       +row.formula * +row.available.in_unit + +row.available.in_sub_unit     )   ) {
          return {
            valid: false,
            message: "Cannot exceed available quantity",
          }
        }
        if (
          +newValue >
          Number(
            (+row.available.in_unit - +row.quantity.in_unit) * +row.formula +
              +row.available.in_sub_unit
          )
        ) {
          return {
            valid: false,
            message: "Cannot exceed more",
          }
        }
        if (+newValue < 0) {
          return {
            valid: false,
            message: "Cannot be a negative value",
          }
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          }
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
       }
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const isHouseholdItem = row.is_household_item === true;
        if(!isHouseholdItem){
          const availableItems = row.available
          const isAvailable =  (availableItems && availableItems.in_unit > 0) ||  availableItems.in_sub_unit
          if (isAvailable ) {
            return (
              <input
                style={{
                  width: "50px",
                  height: "40px",
                  border: "1px solid lightGray",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                type="number"
                onBlur={e => {
                  handleSubUnitQuantityChange(e, rowIndex, row, editorProps)
                }}
                onFocus={e => e.target.select()} 
                autoFocus
              />
            )
          } else {
            return <div style={{ color: "red" }}>Not Available</div>
          }
        }else{
          return (
            <input
              style={{
                width: "50px",
                height: "40px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="number"
              onBlur={e => {
                handleSubUnitQuantityChange(e, rowIndex, row, editorProps)
              }}
              onFocus={e => e.target.select()} // Retain focus on input field
              autoFocus
            />
          )
        }
      
      },
    },
    {
      dataField: "sub_total",
      formatter: (cell, row) => row.sub_total,
      text: "Sub Total",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      text: "Action",
      dataField: "",
      formatter: DeleteSelectedItem,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      sort: false,
      editable: false,
    },
  ]

  // -------------------------------------------------------------------

  const handleEventMouseEnter = async mouseEnterInfo => {
    try {
      const gameDetails = mouseEnterInfo.event.extendedProps.gameNames
        ?.map(
          game => `
        Game Name: ${game.game.name} - (Table Number: ${game.table_number})
    
      `
        )
        .join("<br/>")

      const packageDetails = mouseEnterInfo.event.extendedProps.package
        ?.map(
          game => `
      Game Name: ${game.name} - (Table Number: ${game.table_number})
  
    `
        )
        .join("<br/>")

      // console.log( mouseEnterInfo.event.extendedProps)
      // console.log(packageDetails)

      const make_content = `
        <b>
        ${mouseEnterInfo.event.start.toLocaleTimeString(navigator.language, {
          hour: "numeric",
          minute: "2-digit",
        })} - ${mouseEnterInfo.event.end.toLocaleTimeString(
        navigator.language,
        {
          hour: "numeric",
          minute: "2-digit",
        }
      )} </b> <br/>
        <b>Customer Name:</b> ${
          mouseEnterInfo.event.extendedProps.customerName
        } 
        [ ${mouseEnterInfo.event.extendedProps.customerType} ] <br/>
        <b>Status:</b> ${mouseEnterInfo.event.extendedProps.status} <br/>
        <b>Game Details:</b> ${gameDetails || "No Game Found"} <br/>
        <b>Package Details :</b> ${packageDetails || "No Packages Found"} <br/>
      `

      tippy(mouseEnterInfo.el, {
        content: make_content,
        allowHTML: true,
        theme: "light-border",
        // size: "large"
      })
    } catch (error) {
      console.error(error)
    }
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  function handleCardDetails(e, v) {
    //  console.log(v,"card details form object")

    if (
      (cardDetails.card_holder_name.length === 0 ||
        cardDetails.card_number.length === 0 ||
        cardDetails.card_cvv.length === 0 ||
        cardDetails.card_expiry.length === 0) &&
      cardType !== null
    ) {
      setCardValidation(prev => (prev = true))
      return
    } else {
      setCardValidation(prev => (prev = false))
    }
    setClickedCvv(prev => (prev = false))
    setCardDetails(prev => (prev = v))
    showNotification("Card Details Added !", "Success")
    setCardModal(prev => (prev = !prev))
  }


  useEffect(() => {
    
    setTableStyles(prev => prev =
    {
        control: (styles) => ({ ...styles, color: "white" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // console.log(data,"data")
            isDisabled = data.is_occupied === true ? true : false
            const color = "white";
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? "#f0b4b4"
                    : isSelected
                        ? "white"
                        : isFocused
                            ? "#566ce3"
                            : undefined,
                color: isFocused ? "white" : "black",
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? "white"
                            : "#566ce3"
                        : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            // const color = "#eff2f7";
            let color = "";
            if (data.is_occupied ===false) {

                color = "#eff2f7";
            } else if (data.is_occupied === true) {
                color = "#f0b4b4"
            }
            return {
                ...styles,
                backgroundColor: color,
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "black",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "black",
            ':hover': {
                backgroundColor: "#f0b4b4",
                color: 'red',
            },
        }),
    }
    )
}, [selectedCustomer])



  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Add Reservation"
            alternateTitle="Add Reservation"
            breadcrumbItem1="Custom"
          />
          <Card>
            <CardBody
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              }}
            >
              <FullCalendar
                // id="tooltipId"
                customButtons={{
                  MyCustomButton: {
                    text: "Instant Reservation",
                    buttonText: ``,
                    click: () => openBookingModal(new Date()),
                  },
                }}
                height="auto"
                slotEventOverlap={false}
                navLinks={true}
                nowIndicator={true}
                ref={refContainer}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridDay"
                headerToolbar={{
                  left: "prev,next today MyCustomButton",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                slotDuration="00:15:00"
                dateClick={info => {
                  const clickedDate = info.date
                  const currentDate = new Date() // Get the current date and time

                  // clickedDate.setSeconds(0);
                  // clickedDate.setMilliseconds(0);
                  // currentDate.setSeconds(0);
                  // currentDate.setMilliseconds(0);

                  if (clickedDate >= currentDate) {
                    // Check if the clicked date is current or future
                    openBookingModal(clickedDate)
                  } else {
                    // showNotification("booking not allowed in past", "Error")
                    showWarning("booking not allowed in past", "Warning")
                  }
                }} //to open a modal for booking
                events={calendarEvents && calendarEvents}
                // eventTimeFormat={{
                //   hour: '2-digit',
                //   minute: '2-digit',
                //   hour12: false, 
                // }}
                eventContent={renderEventContent}
                eventClick={e => showBookingDetails(e, "")}
                eventBorderColor="red"
                eventTextColor="#000000"
                eventTimeFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: "short",
                  hour12: false,
                }}
                eventMouseEnter={handleEventMouseEnter}
                // eventMouseLeave={handleEventMouseLeave}

                //   eventTimeFormat={{
                //     hour: '2-digit',
                //     minute: '2-digit',
                //     hour12: false, // Set this to false for 24-hour format
                // }}
                eventDisplay="block"
                eventDurationEditable={false}
                datesSet={(arg) => {
                  const { start, end } = arg;
                  loadAllBookings(start, end);
                }}
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      {/* ------------------------------------------add new booking modal --------------------------------- */}
      <Modal
        size="xl"
        isOpen={toggleModal}
        toggle={() => {
          //RESETTING ALL THE STATES TO DEFAULT

          setToggleModal(prev => (prev = !prev))
          setSelectedPreviousGames(prev => (prev = []))
          setSelectedPreviousItems(prev => (prev = []))
          setSelected_game(prev => (prev = []))
          setSelected_item(prev => (prev = []))
          setSelectedCustomer(prev => (prev = null))
          setSelected_package(prev => (prev = []))
          setSelectedTable(prev => (prev = []))
          setAvailableTables(prev => (prev = []))
          setTablesDisplay(prev => (prev = []))
          setGrandTotal(prev => (prev = 0))
          setSelectedGamePrice(prev => (prev = 0))
          setSelectedItemPrice(prev => (prev = 0))
          setItemVisible(prev => (prev = true))
          setGameVisible(prev => (prev = false))
          setGameValidation(prev => (prev = false))
          setItemValidation(prev => (prev = false))
          setPrevPkgValidation(prev => (prev = false))
          setCardType(prev => (prev = null))
          setSelectedBranch(prev => (prev = null))
          setCardValidation(prev => (prev = false))
          setItemsInTable(prev => (prev = []))
          setGamesInTable(prev => (prev = []))
          setDisplayGameTable(prev => (prev = false))
          setDisplayItemTable(prev => (prev = false))
          setCustomerValidation(prev => (prev = false))
          setDisplayCustomerPackageTable(prev => (prev = false))
          setDurationWarning(prev => (prev = false))
          setGameDuration(prev => (prev = 30))
          setItemUnitValidation(prev => (prev = null))
          setItemSubUnitValidation(prev => (prev = null))
          setSelectedPreviousPackage(prev => (prev = []))
          setCustomStyles(
            prev =>
              (prev = {
                control: styles => ({ ...styles, color: "white" }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  // console.log(isDisabled, "disabled")
                  const color = "white"
                  return {
                    ...styles,
                    backgroundColor: isDisabled
                      ? "gray"
                      : isSelected
                      ? "white"
                      : isFocused
                      ? "#566ce3"
                      : undefined,
                    color: isFocused ? "white" : "black",
                    cursor: isDisabled ? "not-allowed" : "default",
                    ":active": {
                      ...styles[":active"],
                      backgroundColor: !isDisabled
                        ? isSelected
                          ? "white"
                          : "#566ce3"
                        : undefined,
                    },
                  }
                },
                multiValue: (styles, { data }) => {
                  // const color = "#eff2f7";
                  let color = ""
                  let isSelectedGameNoTables = []
                  if (preSaveColor.length === 0) {
                    color = "#eff2f7"
                  } else {
                    isSelectedGameNoTables = preSaveColor.some(
                      selected => selected === data.label.props.children[1]
                    )
                    color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
                  }
                  return {
                    ...styles,
                    backgroundColor: color,
                  }
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "black",
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "black",
                  ":hover": {
                    backgroundColor: "#f0b4b4",
                    color: "red",
                  },
                }),
              })
          )
        }}
      >
        <ModalHeader
          toggle={() => {
            //RESETTING ALL THE STATES TO DEFAULT

            setToggleModal(prev => (prev = !prev))
            setSelectedPreviousGames(prev => (prev = []))
            setSelectedPreviousItems(prev => (prev = []))
            setSelected_game(prev => (prev = []))
            setSelected_item(prev => (prev = []))
            setSelectedCustomer(prev => (prev = null))
            setSelected_package(prev => (prev = []))
            setSelectedTable(prev => (prev = []))
            setAvailableTables(prev => (prev = []))
            setTablesDisplay(prev => (prev = []))
            setGrandTotal(prev => (prev = 0))
            setSelectedGamePrice(prev => (prev = 0))
            setSelectedItemPrice(prev => (prev = 0))
            setGameValidation(prev => (prev = false))
            setItemValidation(prev => (prev = false))
            setPrevPkgValidation(prev => (prev = false))
            setCardType(prev => (prev = null))
            setSelectedBranch(prev => (prev = null))
            setCardValidation(prev => (prev = false))
            setItemsInTable(prev => (prev = []))
            setGamesInTable(prev => (prev = []))
            setDisplayGameTable(prev => (prev = false))
            setDisplayItemTable(prev => (prev = false))
            setCustomerValidation(prev => (prev = false))
            setDisplayCustomerPackageTable(prev => (prev = false))
            setDurationWarning(prev => (prev = false))
            setGameDuration(prev => (prev = 30))
            setItemUnitValidation(prev => (prev = null))
            setItemSubUnitValidation(prev => (prev = null))
            setSelectedPreviousPackage(prev => (prev = []))
            setCustomStyles(
              prev =>
                (prev = {
                  control: styles => ({ ...styles, color: "white" }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    // console.log(isDisabled, "disabled")
                    const color = "white"
                    return {
                      ...styles,
                      backgroundColor: isDisabled
                        ? "gray"
                        : isSelected
                        ? "white"
                        : isFocused
                        ? "#566ce3"
                        : undefined,
                      color: isFocused ? "white" : "black",
                      cursor: isDisabled ? "not-allowed" : "default",
                      ":active": {
                        ...styles[":active"],
                        backgroundColor: !isDisabled
                          ? isSelected
                            ? "white"
                            : "#566ce3"
                          : undefined,
                      },
                    }
                  },
                  multiValue: (styles, { data }) => {
                    // const color = "#eff2f7";
                    let color = ""
                    let isSelectedGameNoTables = []
                    if (preSaveColor.length === 0) {
                      color = "#eff2f7"
                    } else {
                      isSelectedGameNoTables = preSaveColor.some(
                        selected => selected === data.label.props.children[1]
                      )
                      color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
                    }
                    return {
                      ...styles,
                      backgroundColor: color,
                    }
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: "black",
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: "black",
                    ":hover": {
                      backgroundColor: "#f0b4b4",
                      color: "red",
                    },
                  }),
                })
            )
          }}
        >
          Add New Booking
        </ModalHeader>
        <ModalBody>
          <>
            <Row>
              {isLoading === true ? (
                <Spinner />
              ) : (
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <AvForm
                          onValidSubmit={handleValidSubmit}
                          ref={refContainer}
                        >
                          <Row>
                            <Col lg={4}>
                              <Label>Start Time</Label>

                              <Input
                                className="form-control"
                                name="start_time"
                                label="Date Time"
                                type="datetime-local"
                                value={startTime}
                                disabled={true}
                                onChange={e =>
                                  setStartTime(prev => (prev = e.target.value))
                                }
                              />
                            </Col>

                            <Col lg={4}>
                              <Label>End Time</Label>

                              <Input
                                disabled={true}
                                className="form-control"
                                name="start_time"
                                label="Date Time"
                                type="datetime-local"
                                value={bookingEndTime}
                                onChange={e =>
                                  setBookingEndTime(
                                    prev => (prev = e.target.value)
                                  )
                                }
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            {localStorage.getItem("role") === "Owner" && (
                              <Col lg={4}>
                                <Label>Branch</Label>
                                <Select
                                  placeholder="Select Branch"
                                  // onInputChange={handleBranchSelect}
                                  onChange={selected => {
                                    setSelectedBranch(selected)
                                    selected === null
                                      ? `${setSelectedCustomer(
                                          prev => (prev = null)
                                        )} ${setSelected_game(
                                          prev => (prev = [])
                                        )} ${setAllTheGames(
                                          prev => (prev = [])
                                        )} ${setAvailableTables(
                                          prev => (prev = [])
                                        )} ${setSelectedTable(
                                          prev => (prev = [])
                                        )} ${setSelected_item(
                                          prev => (prev = [])
                                        )} ${setItemsInTable(
                                          prev => (prev = [])
                                        )} ${setGrandTotal(
                                          prev => (prev = 0)
                                        )} ${setCardType(
                                          prev => (prev = null)
                                        )} ${setSelected_package(
                                          prev => (prev = [])
                                        )} ${setCardDetails(
                                          prev => (prev = {})
                                        )} ${setPrevPkgValidation(
                                          prev => (prev = false)
                                        )} ${setGameValidation(
                                          prev => (prev = false)
                                        )} ${setItemValidation(
                                          prev => (prev = false)
                                        )} ${setCardValidation(
                                          prev => (prev = false)
                                        )} ${setCardNumber(
                                          prev => (prev = "")
                                        )} ${setGamesInTable(
                                          prev => (prev = [])
                                        )} ${setItemsInTable(
                                          prev => (prev = [])
                                        )} ${setDisplayCustomerPackageTable(
                                          prev => (prev = false)
                                        )} ${setSelectedPreviousPackage(
                                          prev => (prev = [])
                                        )}`
                                      : ""
                                  }}
                                  value={selectedBranch}
                                  options={branches}
                                  isClearable={true}
                                  isSearchable
                                  required
                                />
                              </Col>
                            )}

                            <Col
                              lg={
                                localStorage.getItem("role") === "Owner" ? 4 : 8
                              }
                            >
                              <Label>Customer</Label>
                              <Select
                                isDisabled={
                                  localStorage.getItem("role") === "Owner"
                                    ? selectedBranch === null
                                    : false
                                }
                                placeholder={props.t("Select Customer")}
                                value={selectedCustomer}
                                // styles={{
                                //   menu: provided => ({
                                //     ...provided,
                                //     height: '150px',
                                //     overflow: 'auto'
                                //   })
                                // }}
                                // type="number"
                                // value={selectedMobileNumber}
                                onInputChange={handleMobileInputChange}
                                options={customerArr.map(result => ({
                                  label: `${result.name} [${result.mobile}] [${result.customer_type}]`,
                                  value: result.name,
                                  mobile: result.mobile,
                                  type: result.customer_type,
                                }))}
                                onChange={(selected, removed) => {
                                  setSelectedCustomer(prev => (prev = selected))
                                  setCustomerValidation(prev => (prev = false))
                                  setCardDetails(prev => (prev = {}))
                                  setGrandTotal(prev => (prev = 0))
                                  setGamesInTable(prev => (prev = []))
                                  setItemsInTable(prev => (prev = []))
                                  setDisplayCustomerPackageTable(
                                    prev => (prev = false)
                                  )
                                  setSelected_item(prev => (prev = []))
                                  setSelected_package(prev => (prev = []))
                                  setCardNumber(prev => (prev = ""))
                                  setDurationWarning(prev => (prev = false))
                                  setSelectedGamePrice(prev => (prev = 0))
                                  setSelectedItemPrice(prev => (prev = 0))

                                  setCustomStyles(
                                    prev =>
                                      (prev = {
                                        control: styles => ({
                                          ...styles,
                                          color: "white",
                                        }),
                                        option: (
                                          styles,
                                          {
                                            data,
                                            isDisabled,
                                            isFocused,
                                            isSelected,
                                          }
                                        ) => {
                                          // console.log(isDisabled, "disabled")
                                          const color = "white"
                                          return {
                                            ...styles,
                                            backgroundColor: isDisabled
                                              ? "gray"
                                              : isSelected
                                              ? "white"
                                              : isFocused
                                              ? "#566ce3"
                                              : undefined,
                                            color: isFocused
                                              ? "white"
                                              : "black",
                                            cursor: isDisabled
                                              ? "not-allowed"
                                              : "default",
                                            ":active": {
                                              ...styles[":active"],
                                              backgroundColor: !isDisabled
                                                ? isSelected
                                                  ? "white"
                                                  : "#566ce3"
                                                : undefined,
                                            },
                                          }
                                        },
                                        multiValue: (styles, { data }) => {
                                          // const color = "#eff2f7";
                                          let color = ""
                                          let isSelectedGameNoTables = []
                                          if (preSaveColor.length === 0) {
                                            color = "#eff2f7"
                                          } else {
                                            isSelectedGameNoTables =
                                              preSaveColor.some(
                                                selected =>
                                                  selected ===
                                                  data.label.props.children[1]
                                              )
                                            color = isSelectedGameNoTables
                                              ? "#f0b4b4"
                                              : "#eff2f7"
                                          }
                                          return {
                                            ...styles,
                                            backgroundColor: color,
                                          }
                                        },
                                        multiValueLabel: (
                                          styles,
                                          { data }
                                        ) => ({
                                          ...styles,
                                          color: "black",
                                        }),
                                        multiValueRemove: (
                                          styles,
                                          { data }
                                        ) => ({
                                          ...styles,
                                          color: "black",
                                          ":hover": {
                                            backgroundColor: "#f0b4b4",
                                            color: "red",
                                          },
                                        }),
                                      })
                                  )
                                }}
                              />

                              {customerValidation && (
                                <Row>
                                  <Col style={{ color: "red" }}>
                                    Customer not selected
                                  </Col>
                                </Row>
                              )}
                            </Col>

                            <Col
                              lg={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "22px",
                              }}
                            >
                              <Button
                                // style={{ width: "100px", marginTop:"-0.09rem", backgroundColor: "#62FF3E",  }}
                                className="btn btn-success waves-effect waves-light btn-sm"
                                style={{
                                  fontSize: "12px",
                                  width: "150px",
                                  backgroundColor: " #36B94D",
                                  color: "white",
                                  marginTop: "-0.09rem",
                                  boxShadow:
                                    "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                                onClick={() =>
                                  setAddCustomerModal(prev => (prev = !prev))
                                }
                              >
                                <i
                                  className="mdi mdi-file-plus"
                                  style={{
                                    fontSize: "15px",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                Add New
                              </Button>
                            </Col>
                          </Row>

                          <br />

                          <Row>
                            <Col lg={4}>
                              <Label>Game</Label>

                              <Select
                                isDisabled={selectedCustomer === null}
                                name="games"
                                label={props.t("Games")}
                                placeholder={props.t("Games")}
                                // type="text"

                                onChange={(selected, removed) => {
                                  if (removed.action === "remove-value") {
                                    let tableToDisplay = tablesToDisplay.filter(
                                      table =>
                                        removed.removedValue.label.props
                                          .children[1] !== table.game.name
                                    )

                                    setTablesDisplay(
                                      prev => (prev = tableToDisplay)
                                    )

                                    // let newSelectedGames = selected_game.filter(table => table.id !== removed.removedValue.id)
                                    setSelected_game(prev => (prev = []))

                                    // setAllTheGames(prev => [...prev, removed.removedValue]);

                                    let newSelectedTables =
                                      selectedTable.filter(
                                        table =>
                                          removed.removedValue.label.props
                                            .children[1] !== table.name
                                      )
                                    setSelectedTable(
                                      prev => (prev = newSelectedTables)
                                    )

                                    let newAvailableTables =
                                      availableTables.filter(
                                        table =>
                                          table.name !==
                                          removed.removedValue.label.props
                                            .children[1]
                                      )
                                    setAvailableTables(
                                      prev => (prev = newAvailableTables)
                                    )

                                    let newAllTableData = allTableData.filter(
                                      table =>
                                        table.game.name !==
                                        removed.removedValue.label.props
                                          .children[1]
                                    )
                                    setAllTableData(
                                      prev => (prev = newAllTableData)
                                    )

                                    handleGameSelect(tableToDisplay)
                                  }

                                  if (removed.action === "select-option") {
                                    let newPayload = allTheGames.filter(
                                      game =>
                                        game.label.props.children[1] ===
                                        removed.option.label.props.children[1]
                                    )

                                    setSelected_game(
                                      prev =>
                                        (prev = selected[selected.length - 1])
                                    )

                                    // let newGameList = allTheGames.filter(game => game.label.props.children[1] !== newPayload[0].label.props.children[1])
                                    // setAllTheGames(prev => prev = newGameList);

                                    // function call to fetch available game tables
                                    fetchAvailableGameTables(removed, selected)
                                  }

                                  if (removed.action === "clear") {
                                    setSelected_game(prev => (prev = []))
                                    // setAllTheGames(prev => prev = [...prev, ...removed.removedValues])
                                    setSelectedTable(prev => (prev = []))
                                    setAvailableTables(prev => (prev = []))
                                    setTablesDisplay(prev => (prev = []))
                                    setAllTableData(prev => (prev = []))
                                    handleGameSelect([])

                                    // //game minutes field validation state set to default 'false'....
                                    // setGameValidation(prev => prev = false)
                                  }

                                  // setSelected_game(selected)
                                }}
                                options={allTheGames}
                                // ref={refSelectGame}
                                value={selected_game}
                                styles={customStyles}
                                isMulti
                                isSearchable
                                required
                              />

                              {gameValidation && (
                                <Row>
                                  <Col style={{ color: "red" }}>
                                    Enter Duration for all Games
                                  </Col>
                                </Row>
                              )}
                            </Col>

                            <Col lg={2}>
                              <Label>Tables</Label>
                              <Select
                                isDisabled={selectedCustomer === null}
                                name="tables"
                                label={props.t("Tables")}
                                placeholder={props.t("Tables")}
                                // type="text"

                                onChange={(selected, removed) => {
                                  if (removed.action === "remove-value") {
                                    let tableToDisplay = tablesToDisplay.filter(
                                      table =>
                                        removed.removedValue.id !== table._id
                                    )

                                    setTablesDisplay(
                                      prev => (prev = tableToDisplay)
                                    )

                                    // let newSelectedTables = selectedTable.filter(table => table.id !== removed.removedValue.id)
                                    setSelectedTable(prev => (prev = []))

                                    // setAvailableTables(prev => [...prev, removed.removedValue]);

                                    handleGameSelect(tableToDisplay)
                                  }

                                  if (removed.action === "select-option") {
                                    let tableToDisplay = allTableData.filter(
                                      table => table._id === removed.option.id
                                    )

                                    setTablesDisplay(
                                      prev =>
                                        (prev = [...prev, ...tableToDisplay])
                                    )

                                    setSelectedTable(
                                      prev =>
                                        (prev = [selected[selected.length - 1]])
                                    )

                                    // let newAvailableTables = availableTables.filter(table => table.id !== removed.option.id);
                                    // setAvailableTables(prev => prev = newAvailableTables)
                                  }

                                  if (removed.action === "clear") {
                                    setSelectedTable(prev => (prev = []))
                                    // setAvailableTables(prev => prev = [...prev, ...removed.removedValues])

                                    setTablesDisplay(prev => (prev = []))

                                    handleGameSelect([])

                                    //game minutes field validation state set to default 'false'....
                                    // setGameValidation(prev => prev = false)
                                  }

                                  // setSelected_game(selected)
                                }}
                                options={availableTables}
                                // ref={refSelectGame}
                                styles={tableStyles}
                                value={selectedTable}
                                isMulti
                                isSearchable
                                required
                              />
                            </Col>

                            {/* Duration Input Field */}

                            <Col lg={2}>
                              <Label>Duration</Label>
                              <Input
                                onWheel={preventValueChangeOnScroll}
                                disabled={selectedCustomer === null}
                                placeholder="Duration"
                                type="number"
                                value={GameDuration > 0 ? GameDuration : ""}
                                onChange={e => {
                                  
                                  if (
                                    +e.target.value < 30 &&
                                    e.target.value.length !== 0
                                  ) {
                                    setDurationWarning(prev => (prev = true))
                                  } else {
                                    setDurationWarning(prev => (prev = false))
                                  }

                                  if (
                                    e.target.value === "" &&
                                    GamesInTable.length === 0
                                  ) {
                                    setBookingEndTime(prev => (prev = ""))
                                    setMaxTime(prev => (prev = 0))
                                  } else {
                                    if (maxTime < +e.target.value) {
                                      setMaxTime(
                                        prev => (prev = +e.target.value)
                                      )
                                      CalcEndTime(
                                        startTime,
                                        +e.target.value,
                                        setBookingEndTime,
                                        bookingEndTime
                                      )
                                    }
                                  }

                                  setGameDuration(
                                    prev => (prev = +e.target.value)
                                  )
                                  setGameStartTime(
                                    prev => (prev = new Date(startTime))
                                  )
                                  setGameEndTime(
                                    prev =>
                                      (prev = new Date(
                                        new Date(startTime).setMinutes(
                                          new Date(startTime).getMinutes() +
                                            +e.target.value 
                                        )
                                      ))
                                  )
                                  setOnChangeTrack(true)
                                  // CalcEndTime(startTime, +e.target.value, setBookingEndTime, bookingEndTime)
                                }}
                              />

                              {durationWarning && (
                                <Row>
                                  <Col style={{ color: "red" }}>
                                    Can't be less than 30
                                  </Col>
                                </Row>
                              )}
                            </Col>

                            {/* Add Game Button */}

                            <Col
                              lg={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "22px",
                                // border:"1px solid black",
                                height: "62px",
                              }}
                            >
                             <Button
                              className="btn btn-primary waves-effect waves-light btn-sm "
                               style={{
                               display: `${GameDuration >= 30 &&
                                selected_game.value?.length > 0 &&
                                selectedTable?.length > 0
                                 ? "block"
                                 : "none" }`,
        background: "#556EE6",
        fontSize: "10px",
        width: "150px",
        boxShadow:
            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
        transition: "transform 0.3s ease",
    }}
    onMouseEnter={e => {
        e.target.style.transform = "scale(1.05)"
    }}
    onMouseLeave={e => {
        e.target.style.transform = "scale(1)"
    }}
    onClick={async () => {

      if (onChangeTrack === true) {

        let payload = {
          start_time: new Date(startTime),
          end_time: new Date(bookingEndTime),
          game_id: selected_game.id,
        }
        // console.log(payload,"time payload")

        let url =
          process.env.REACT_APP_BASEURL +
          "bookings/table_bookings_by_date"
        let response = await postSubmitForm(
          url,
          payload
        )
        // console.log(response);
        if (response.status === 1) {
          let tableArray = response.data.filter(
            res =>
              res.table_number ===
              selectedTable[0].label
          )
          if (tableArray.length > 0) {
            let userSelectedTable =
              allTableData.filter(
                table =>
                  selectedTable[
                    selectedTable.length - 1
                  ].id === table._id
              )

            AddGameDuration(
              userSelectedTable[0],
              GameDuration,
              gameStartTime,
              gameEndTime,
              selectedCustomer,
              setGrandTotal,
              setSelectedGamePrice
            )

            setGamesInTable(
              prev =>
                (prev = [
                  ...prev,
                  ...userSelectedTable,
                ])
            )
            setSelected_game(prev => (prev = []))
            setSelectedTable(prev => (prev = []))
            setAvailableTables(prev => (prev = []))
            setGameDuration(prev => (prev = 30))
            setDisplayGameTable(prev => (prev = true))
            setDurationWarning(prev => (prev = false))
            setGameEndTime(prev => (prev = null))
            setOnChangeTrack(prev=> (prev= false))
            setGameStartTime(prev => (prev = null))
            showWarning(
              "Table is Available",
              "Success"
            )
            // return "yes"
          } else {
            showWarning("Table is occupied")
            return console.log("no")
          }
        } else {
          // console.log(response, "hello")
          showWarning("Warning")
          return "no"
        }
      } else {
    
        if (maxTime < 30) {
            setMaxTime(prev => (prev = 30))
            CalcEndTime(startTime, 30, setBookingEndTime, bookingEndTime)
        }

        setGameDuration(prev => (prev = 30))
        setGameStartTime(prev => (prev = new Date(startTime)))
        setGameEndTime(
            prev =>
                (prev = new Date(
                    new Date(startTime).setMinutes(
                        new Date(startTime).getMinutes() + 30
                    )
                ))
        )

        let payload = {
            start_time: new Date(startTime),
            end_time: new Date(bookingEndTime),
            game_id: selected_game.id,
        }

        // console.log(payload, "time payload")

        let url =
            process.env.REACT_APP_BASEURL +
            "bookings/table_bookings_by_date"
        let response = await postSubmitForm(url, payload)

        if (response.status === 1) {
            let tableArray = response.data.filter(
                res => res.table_number === selectedTable[0].label
            )
            if (tableArray.length > 0) {
                let userSelectedTable = allTableData.filter(
                    table =>
                        selectedTable[selectedTable.length - 1].id === table._id
                )

               
                setGameDuration(prev => (prev = 30))
                AddGameDuration(
                    userSelectedTable[0],
                    30, 
                    new Date(startTime),
                    new Date(
                        new Date(startTime).setMinutes(
                            new Date(startTime).getMinutes() + 30
                        )
                    ),
                    selectedCustomer,
                    setGrandTotal,
                    setSelectedGamePrice
                )

                setGamesInTable(prev => [...prev, ...userSelectedTable])
                setSelected_game(prev => (prev = []))
                setSelectedTable(prev => (prev = []))
                setAvailableTables(prev => (prev = []))
                setGameDuration(prev => (prev = 30))
                setDisplayGameTable(prev => (prev = true))
                setDurationWarning(prev => (prev = false))
                setGameEndTime(prev => (prev = null))
                setGameStartTime(prev => (prev = null))
                setOnChangeTrack(prev=> (prev= false))
                showWarning("Table is Available", "Success")
            } else {
                showWarning("Table is occupied")
                return console.log("no")
            }
        } else {
            showWarning("Warning")
            return "no"
        }
      }
    }}
>
    <i
        className="mdi mdi-file-plus"
        style={{
            fontSize: "15px",
            marginRight: "4px",
        }}
    ></i>
    Add Table
</Button>

                            </Col>
                          </Row>

                          {/* Selected Game's Table Display */}
                          {displayGameTable && GamesInTable.length > 0 && (
                            <>
                              <br />
                              <Row>
                                <Col>
                                  <BootstrapTable
                                    // onTableChange={handleTableSubmit}
                                    // ref={refItemTable}
                                    keyField="_id"
                                    cellEdit={cellEditFactory({
                                      mode: "click",
                                      blurToSave: true,
                                    })}
                                    data={GamesInTable}
                                    columns={addGameDetailsColumns}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}

                          <br />

                          <Row>
                            <Col lg={4}>
                              <Label>Items</Label>
                              <Select
                                isDisabled={selectedCustomer === null}
                                name="items"
                                label={props.t("Items")}
                                placeholder={props.t("Items")}
                                type="text"
                                onChange={(selected, removed) => {
                                  if (removed.action === "remove-value") {
                                    let newPayload = selected_item.filter(
                                      item =>
                                        removed.removedValue.name !== item.name
                                    )
                                    setSelected_item(prev => (prev = []))
                                    return
                                  }
                                  if (removed.action === "select-option") {
                                    // console.log(removed, "removed")
                                    setSelected_item(
                                      prev => (prev = [removed.option])
                                    )
                                    return
                                  }
                                  if (removed.action === "clear") {
                                    setSelected_item([])
                                    return
                                  }
                                  // setSelected_game(selected)
                                }}
                                options={all_Items}
                                // ref={refSelectGame}
                                value={selected_item}
                                isMulti
                                isClearable
                                isSearchable
                                required
                              />

                              {itemValidation && (
                                <Row>
                                  <Col style={{ color: "red" }}>
                                    Enter Quantity for all Items
                                  </Col>
                                </Row>
                              )}
                            </Col>

                            <Col lg={2}>
                              <Label>
                                Unit
                                <span
                                  style={{ fontSize: "10px", color: "red" }}
                                >
                                  {selected_item.length > 0 &&
                                  (selected_item[0]?.is_household_item ===
                                    false ||
                                    selected_item[0]?.is_household_item ===
                                      undefined)
                                    ? `${selected_item[0]?.available?.in_unit} ${selected_item[0]?.unit}`
                                    : ""}
                                </span>
                              </Label>
                              <Input
                                onWheel={preventValueChangeOnScroll}
                                value={unit === 0 ? "Unit" : unit}
                                disabled={selectedCustomer === null}
                                placeholder="Unit"
                                type="number"
                                onChange={e => {
                                  if (selected_item[0].is_household === false) {
                                    if (+e.target.value <= selected_item[0].available.in_unit) {
                                      setUnit(prev => (prev = e.target.value));
                                      setItemUnitValidation(prev => (prev = false));
                                    } else {
                                      setUnit(prev => (prev = e.target.value));
                                      setItemUnitValidation(prev => (prev = true));
                                    }
                                  } else {
                                    setUnit(prev => (prev = e.target.value));
                                  }
                                }}
                              />

                              {itemUnitValidation && (
                                <Row>
                                  <Col
                                    style={{
                                      color: "red",
                                      textAlign: "center",
                                    }}
                                  >
                                    Exceeded Units
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <Col lg={2}>
                              {/* if no sub-unit is present for the selected item then turn its font color to red to highlight and tell the user, an also disable the field */}
                              <Label
                                style={{
                                  color:
                                    selected_item[0]?.sub_unit === "" ||
                                    selected_item[0]?.sub_unit === null
                                      ? "red"
                                      : "#495057ff",
                                }}
                              >
                                {selected_item[0]?.sub_unit === "" ||
                                selected_item[0]?.sub_unit === null
                                  ? "No Sub-Unit"
                                  : "Sub-Unit"}

                                <span
                                  style={{ fontSize: "10px", color: "red" }}
                                >
                                  &nbsp;
                                  {selected_item.length > 0 &&
                                  (selected_item[0]?.is_household_item ===
                                    false ||
                                    selected_item[0]?.is_household_item ===
                                      undefined)
                                    ? selected_item[0]?.sub_unit === "" ||
                                      selected_item[0]?.sub_unit === null
                                      ? ""
                                      : `${selected_item[0]?.available?.in_sub_unit} ${selected_item[0]?.sub_unit}`
                                    : ""}
                                </span>
                              </Label>
                              <Input
                                onWheel={preventValueChangeOnScroll}
                                value={subUnit === 0 ? "Sub-Unit" : subUnit}
                                disabled={
                                  selectedCustomer === null ||
                                  selected_item[0]?.sub_unit === "" ||
                                  selected_item[0]?.sub_unit === null
                                }
                                placeholder={
                                  selected_item[0]?.sub_unit === "" ||
                                  selected_item[0]?.sub_unit === null
                                    ? "No Sub-Unit"
                                    : "Sub-Unit"
                                }
                                type="number"
                                onChange={e => {
                                  if (
                                    +e.target.value <=
                                    selected_item[0].formula *
                                      selected_item[0].available.in_unit +
                                      selected_item[0].available.in_sub_unit
                                  ) {
                                    setItemSubUnitValidation(
                                      prev => (prev = false)
                                    )
                                    setSubUnit(prev => (prev = e.target.value))
                                  } else {
                                    setSubUnit(prev => (prev = e.target.value))
                                    setItemSubUnitValidation(
                                      prev => (prev = true)
                                    )
                                  }
                                }}
                              />

                              {itemSubUnitValidation && (
                                <Row>
                                  <Col
                                    style={{
                                      color: "red",
                                      textAlign: "center",
                                    }}
                                  >
                                    Exceeded Sub-Units
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <Col
                              lg={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "22px",
                                height: "62px",
                              }}
                            >
                              <Button
                                disabled={
                                  selected_item[0]?.sub_unit === "" ||
                                  selected_item[0]?.sub_unit === null
                                    ? itemUnitValidation === true
                                    : itemUnitValidation === true ||
                                      itemSubUnitValidation === true
                                }
                                className="btn btn-primary waves-effect waves-light btn-sm "
                                style={{
                                  display: `${
                                    selected_item.length > 0 ? "block" : "none"
                                  }`,

                                  background: "#556EE6",
                                  fontSize: "10px",
                                  width: "150px",

                                  boxShadow:
                                    "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                                onClick={() =>
                                  // setAddItemdetailsModal(prev => (prev = !prev))
                                  {
                                    AddQuantity(
                                      selected_item,
                                      unit,
                                      subUnit,
                                      setGrandTotal,
                                      selectedCustomer,
                                      setSelectedItemPrice
                                    )
                                    setItemsInTable(
                                      prev =>
                                        (prev = [
                                          ...prev,
                                          selected_item[
                                            selected_item.length - 1
                                          ],
                                        ])
                                    )
                                    let newAllItems = all_Items.filter(
                                      item => item._id !== selected_item[0]._id
                                    )
                                    setAll_Items(prev => (prev = newAllItems))
                                    setSelected_item(prev => (prev = []))
                                    setUnit(prev => (prev = 0))
                                    setSubUnit(prev => (prev = 0))
                                    setDisplayItemTable(prev => (prev = true))
                                  }
                                }
                              >
                                <i
                                  className="mdi mdi-file-plus"
                                  style={{
                                    fontSize: "15px",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                Add Item
                              </Button>
                            </Col>
                          </Row>

                          {/* Selected Item's Table Display */}

                          {displayItemTable && itemsInTable.length > 0 && (
                            <>
                              <br />
                              <Row>
                                <Col>
                                  <BootstrapTable
                                    // onTableChange={handleTableSubmit}
                                    // ref={refItemTable}
                                    keyField="_id"
                                    cellEdit={cellEditFactory({
                                      mode: "click",
                                      blurToSave: true,
                                    })}
                                    data={itemsInTable}
                                    columns={addItemsDetailsColumn}
                                  />
                                </Col>
                              </Row>
                            </>
                          )}

                          <br />
                          <Row>
                            {" "}
                            {/*    <Col lg={8}>
                                                            <Label>Customer Package</Label>
                                                            <Select
                                                                isDisabled={selectedCustomer === null}
                                                                name="package"
                                                                label={props.t("Package")}
                                                                placeholder={props.t("Select Customer Package")}
                                                                type="text"

                                                                options={dummyPkg}
                                                                value={selected_package}
                                                                isMulti
                                                                isClearable={true}
                                                                isSearchable
                                                                required

                                                            />
                                                            {prevPkgValidation && (
                                                                <Row>
                                                                    <Col style={{ color: "red" }}>
                                                                        Enter details of Items OR Games
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                            </Col>  */}
                            <Col lg={8}>
                              <Label>Customer Package</Label>
                              <Select
                                isDisabled={selectedCustomer === null}
                                name="package"
                                label={props.t("Package")}
                                placeholder={props.t("Select Customer Package")}
                                type="text"
                                onChange={(selected, removed) => {
                                  if (removed.action === "remove-value") {
                                    setSelected_package(prev => (prev = []))
                                    setDisplayCustomerPackageTable(
                                      prev => (prev = false)
                                    )
                                    return
                                  }

                                  if (removed.action === "clear") {
                                    // calculateEndTime()
                                    setSelected_package(prev => (prev = []))
                                    setTableData(prev => (prev = []))
                                    setDisplayCustomerPackageTable(
                                      prev => (prev = false)
                                    )

                                    return
                                  }

                                  if (removed.action === "select-option") {
                                    setSelected_package(removed.option)

                                    let newAvailablePkg = allPackages.filter(
                                      pkg => pkg.id !== removed.option._id
                                    )
                                    setAllPackages(
                                      prev =>
                                        (prev = [
                                          ...newAvailablePkg,
                                          ...preSaveCustomerPkg,
                                        ])
                                    )

                                    setTableData(removed.option)
                                    setDisplayCustomerPackageTable(
                                      prev => (prev = false)
                                    )
                                    return
                                  }
                                }}
                                // ref={refSelectGame}
                                options={allPackages}
                                value={selected_package}
                                isMulti
                                isClearable={true}
                                isSearchable
                                required
                              />
                              {prevPkgValidation && (
                                <Row>
                                  <Col style={{ color: "red" }}>
                                    Enter details of Items OR Games
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <Col
                              lg={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "22px",
                                height: "62px",
                              }}
                            >
                              <Button
                                className="btn btn-primary waves-effect waves-light btn-sm "
                                style={{
                                  display: `${
                                    selected_package?.label ? "block" : "none"
                                  }`,

                                  background: "#556EE6",
                                  fontSize: "10px",
                                  width: "150px",
                                  boxShadow:
                                    "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                                onClick={() => {
                                  // setAddPackagedetailsModal(prev => (prev = !prev))

                                  // toggleVertical(1)
                                  // -------------------------------------------------------------
                                  setDisplayCustomerPackageTable(
                                    prev => (prev = true)
                                  )
                                }}
                              >
                                <i
                                  className="mdi mdi-file-plus"
                                  style={{
                                    fontSize: "15px",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                Add Details
                              </Button>
                            </Col>
                          </Row>

                          {displayCustomerPackageTable && (
                            <>
                              <br />
                              <Row>
                                <Col>
                                  <CardTitle>Items</CardTitle>
                                  <ToolkitProvider
                                    keyField="item_id"
                                    columns={addPackageItemDetailsColumn}
                                    data={
                                      selectedPreviousPackage?.package_details
                                        ?.item_details || []
                                    }
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col
                                              lg={4}
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <SearchBar
                                                {...propst.searchProps}
                                                style={{ width: "340px" }}
                                              />
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable
                                          {...propst.baseProps}
                                          noDataIndication={props.t(
                                            "No data to display."
                                          )}
                                          striped
                                          hover
                                          condensed
                                          // selectRow={selectRowItems}
                                          // onSelect={handleRowSelectItems}
                                          // rowStyle={rowStyle}
                                          cellEdit={cellEditFactory({
                                            mode: "click",
                                            blurToSave: true,
                                          })}
                                          // pagination={paginationFactory(options)}
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </Col>
                              </Row>
                            </>
                          )}

                          {displayCustomerPackageTable && (
                            <>
                              <br />
                              <Row>
                                <Col>
                                  <CardTitle>Games</CardTitle>
                                  <ToolkitProvider
                                    keyField="game_id"
                                    columns={addPackageGameDetailsColumn}
                                    data={
                                      selectedPreviousPackage?.package_details
                                        ?.game_details || []
                                    }
                                    search
                                  >
                                    {propst => (
                                      <div>
                                        <AvForm>
                                          <Row>
                                            <Col
                                              lg={4}
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <SearchBar
                                                {...propst.searchProps}
                                                style={{ width: "340px" }}
                                              />
                                            </Col>
                                          </Row>
                                        </AvForm>
                                        <BootstrapTable
                                          {...propst.baseProps}
                                          noDataIndication={props.t(
                                            "No data to display."
                                          )}
                                          striped
                                          hover
                                          condensed
                                          // selectRow={selectRowGames}
                                          cellEdit={cellEditFactory({
                                            mode: "click",
                                            blurToSave: true,
                                          })}
                                          // pagination={paginationFactory(options)}
                                        />
                                      </div>
                                    )}
                                  </ToolkitProvider>
                                </Col>
                              </Row>
                            </>
                          )}

                          <br />

                          <Row>
                            <Col lg={8}>
                              <Label>Card Details </Label>
                              <Input
                                disabled={selectedCustomer === null}
                                type="number"
                                placeholder="Last 4 digits of card number"
                                onChange={e =>
                                  setCardNumber(prev => (prev = e.target.value))
                                }
                                value={cardNumber}
                                maxLength={4}
                              />
                              {cardValidation && (
                                <Row>
                                  <Col style={{ color: "red" }}>
                                    Enter Card Details
                                  </Col>
                                </Row>
                              )}
                            </Col>

                            <Col
                              lg={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "22px",
                              }}
                            >
                              <Button
                                className="btn btn-primary waves-effect waves-light btn-sm "
                                style={{
                                  display: `${
                                    cardType === null ? "none" : "block"
                                  }`,

                                  background: "#556EE6",
                                  fontSize: "10px",
                                  width: "95px",

                                  boxShadow:
                                    "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                                onClick={() =>
                                  setCardModal(prev => (prev = !prev))
                                }
                              >
                                <i
                                  className="mdi mdi-file-plus"
                                  style={{
                                    fontSize: "15px",
                                    marginRight: "4px",
                                  }}
                                ></i>
                                Add Details
                              </Button>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg={8}>
                              <AvField
                                disabled={selectedCustomer === null}
                                name="remarks"
                                label={props.t("Remarks")}
                                placeholder="Enter Remarks(Optional)"
                                type="textarea"
                                validate={{ required: { value: false } }}
                                // errorMessage={props.t("Select Game")}
                              />
                            </Col>
                            <Col lg={4} style={{ color: "red" }}>
                              <Label style={{ color: "red" }}>
                                Total Price{" "}
                                <span style={{ fontSize: "10px" }}>
                                  {selectedCustomer?.type === "VIP"
                                    ? "(No Charges For VIP Customer)"
                                    : ""}
                                </span>{" "}
                              </Label>
                              <AvField
                                readOnly
                                style={{ color: "red", fontWeight: "bold" }}
                                name="total_price"
                                // label={props.t(`Total Price ${selectedCustomer?.type === "VIP" ? "(No Charges For VIP Customer)" : ""}`)}
                                placeholder={props.t("0")}
                                type="number"
                                value={grandTotal.toFixed(2)}
                                validate={{ required: { value: false } }}
                                errorMessage={props.t(
                                  "Select Game, Item, Package"
                                )}
                              />
                            </Col>
                          </Row>
                          {/* <br /> */}

                          <Row>
                            <Button
                              type="submit"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "128px",
                                marginTop: "1rem",
                                margin: "auto",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              {props.t("Add Booking")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>

                            {/* </Col> */}
                          </Row>

                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "5px",
                            }}
                          >
                            {itemUnitValidation && (
                              // <Row >
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                Exceeded Units
                              </Col>
                            )}

                            {itemSubUnitValidation && (
                              // <Row >
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                Exceeded Sub-Units
                              </Col>
                            )}

                            {prevPkgValidation && (
                              // <Row >
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                Enter details of Items OR Games
                              </Col>
                            )}

                            {itemValidation && (
                              // <Row >
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                Enter Quantity for all Items
                              </Col>
                            )}

                            {gameValidation && (
                              // <Row >
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                Enter Duration for all Games
                              </Col>
                              // </Row>
                            )}

                            {cardValidation && (
                              // <Row >
                              <Col
                                style={{ color: "red", textAlign: "center" }}
                              >
                                Enter Card Details
                              </Col>
                              // </Row>
                            )}
                          </Row>
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Row>
          </>
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={cardModal}
        toggle={() => {
          setCardModal(prev => (prev = !prev))
          setClickedCvv(prev => (prev = false))

          const startTime1 = new Date(startTime)

          // Get the current time
          const currentTime = new Date()

          // Calculate the difference in minutes
          const timeDifferenceInMinutes = Math.floor(
            (startTime1 - currentTime) / (1000 * 60)
          )

          // Check if the difference is less than 120 minutes
          if (timeDifferenceInMinutes >= 120 && cardType === null) {
            setCardValidation(prev => (prev = true))
          } else {
            setCardValidation(prev => (prev = false))
          }
          //---------------------------------------------------------------------------------------------

          //to check the card details validation, all field values have been entered, code below.....

          if (
            (cardDetails.card_holder_name.length === 0 ||
              cardDetails.card_number.length === 0 ||
              cardDetails.card_cvv.length === 0 ||
              cardDetails.card_expiry.length === 0) &&
            cardType !== null
          ) {
            setCardValidation(prev => (prev = true))
          } else {
            setCardValidation(prev => (prev = false))
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            setCardModal(prev => (prev = !prev))
            setClickedCvv(prev => (prev = false))
            const startTime1 = new Date(startTime)

            // Get the current time
            const currentTime = new Date()

            // Calculate the difference in minutes
            const timeDifferenceInMinutes = Math.floor(
              (startTime1 - currentTime) / (1000 * 60)
            )

            // Check if the difference is less than 120 minutes
            if (timeDifferenceInMinutes >= 120 && cardType === null) {
              setCardValidation(prev => (prev = true))
            } else {
              setCardValidation(prev => (prev = false))
            }
            //-------------------------------------------------------------------------------

            //to check the card details validation, all field values have been entered, code below.....

            if (
              (cardDetails.card_holder_name.length === 0 ||
                cardDetails.card_number.length === 0 ||
                cardDetails.card_cvv.length === 0 ||
                cardDetails.card_expiry.length === 0) &&
              cardType !== null
            ) {
              setCardValidation(prev => (prev = true))
            } else {
              setCardValidation(prev => (prev = false))
            }
          }}
        >
          Card Details
        </ModalHeader>

        <ModalBody>
          <Row
            className={styles.flip_card}
            onClick={() => setClickedCvv(prev => (prev = !prev))}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Col
              className={styles.flip_card_inner}
              style={{
                transform:
                  clickedCvv === true ? "rotateY(180deg)" : "rotateY(0deg)",
              }}
            >
              <Card
                className={styles.flip_card_front}
                style={{
                  boxShadow: "1px 5px 6px 0px black",
                  background:
                    "linear-gradient(-45deg, rgba(0, 40, 80, 1) 50%, #F93A5C 50%)",
                  borderRadius: "10px",
                }}
              >
                <CardBody>
                  <Row>
                    <Col>
                      <div
                        style={{
                          height: "50px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/chip.png"
                          alt="chip_image"
                          style={{
                            position: "relative",
                            top: "-5px",
                            width: "100px",
                          }}
                        />

                        <img
                          src={cardLogo}
                          alt="card_type_image"
                          style={{
                            position: "relative",
                            right: "10px",
                            width: "90px",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "25px", textAlign: "left" }}>
                    <Col>
                      <Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          color: "white",
                        }}
                      >
                        Card Number
                      </Label>
                      <p style={{ marginTop: "-10px", color: "white" }}>
                        {!cardDetails.card_number
                          ? "XXXX XXXX XXXX XXXX"
                          : cardDetails.card_number}
                      </p>
                    </Col>
                  </Row>

                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Col lg={8} style={{ textAlign: "left", color: "white" }}>
                      <Label style={{ fontWeight: "bold", fontSize: "15px" }}>
                        Card Holder's Name
                      </Label>
                      <p style={{ marginTop: "-10px" }}>
                        {!cardDetails.card_holder_name
                          ? "XXXXXX XXXX"
                          : cardDetails.card_holder_name}
                      </p>
                    </Col>

                    <Col style={{ textAlign: "right" }}>
                      <Label
                        style={{
                          textAlign: "left",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Expiry
                      </Label>

                      <p style={{ marginTop: "-10px", color: "white" }}>
                        {!cardDetails.card_expiry
                          ? "XXXX-XX"
                          : cardDetails.card_expiry}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card
                className={styles.flip_card_back}
                style={{
                  marginTop: "-250px",
                  boxShadow: "1px 5px 6px 0px black",
                  background:
                    "linear-gradient(-45deg,rgba(0, 40, 80, 1) 50%, #F93A5C 50%)",
                  height: "230px",
                  borderRadius: "10px",
                }}
              >
                <p
                  style={{
                    backgroundColor: "black",
                    width: "100%",
                    marginTop: "30px",
                    height: "40px",
                    color: "black",
                  }}
                >
                  r
                </p>

                <CardBody>
                  <Row>
                    <Col>
                      <div style={{ display: "flex", marginTop: "-10px" }}>
                        <p
                          style={{
                            width: "80%",
                            borderRadius: "5px 0 0 5px",
                            height: "35px",
                            backgroundColor: "white",
                            color: "black",
                            fontSize: "20px",
                            textTransform: "uppercase",
                            letterSpacing: "0.1em",
                            overflow: "hidden",
                          }}
                        >
                          {!cardDetails.card_holder_name
                            ? "XXXXXX XXXX"
                            : cardDetails.card_holder_name}
                        </p>

                        <p
                          style={{
                            width: "20%",
                            overflow: "hidden",
                            fontSize: "20px",
                            letterSpacing: "0.1em",
                            borderRadius: "0 5px 5px 0px",
                            height: "35px",
                            backgroundColor: "gray",
                          }}
                        >
                          {!cardDetails.card_cvv ? "XXX" : cardDetails.card_cvv}
                        </p>
                      </div>
                      <p
                        style={{
                          textAlign: "right",
                          marginRight: "30px",
                          marginTop: "-15px",
                        }}
                      >
                        CVV
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <AvForm onValidSubmit={handleCardDetails} ref={refContainer2}>
            <Row>
              <Col>
                <AvField
                  label="Card Holder's Name"
                  name="card_holder_name"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  errorMessage="Enter Card Holder's Name"
                  value={cardDetails.card_holder_name}
                  placeholder="Card Holder's Name"
                  onChange={e => {
                    setCardDetails(
                      prev =>
                        (prev = { ...prev, card_holder_name: e.target.value })
                    )
                    setClickedCvv(prev => (prev = false))
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AvField
                  label="Card Number"
                  value={cardDetails.card_number}
                  onWheel={preventValueChangeOnScroll}
                  name="card_number"
                  type="text"
                  placeholder="Card Number"
                  validate={{
                    required: { value: true },
                    maxLength: {
                      value: 19,
                      errorMessage: props.t("Maximum 16 digits allowed."),
                    },
                  }}
                  onChange={e => {
                    let inputValue = e.target.value.replace(/ /g, "") // Remove existing hyphens
                    let formattedValue = ""

                    for (let i = 0; i < inputValue.length; i++) {
                      if (i > 0 && i % 4 === 0) {
                        formattedValue += " "
                      }
                      formattedValue += inputValue[i]
                    }

                    // console.log(formattedValue);
                    setCardDetails(
                      prev => (prev = { ...prev, card_number: formattedValue })
                    )
                    setClickedCvv(prev => (prev = false))
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AvField
                  label="Card Expiry"
                  value={cardDetails.card_expiry}
                  name="card_expiry"
                  type="month"
                  placeholder="Expiry"
                  validate={{
                    required: { value: true },
                  }}
                  errorMessage="Enter Card Expiry"
                  onChange={e => {
                    setCardDetails(
                      prev => (prev = { ...prev, card_expiry: e.target.value })
                    )
                    setClickedCvv(prev => (prev = false))
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AvField
                  label="CVV Number"
                  value={cardDetails.card_cvv}
                  onWheel={preventValueChangeOnScroll}
                  name="card_cvv"
                  type="text"
                  placeholder="CVV"
                  validate={{
                    required: { value: true },
                    maxLength: {
                      value: 3,
                      errorMessage: props.t("Maximum 3 digits allowed."),
                    },
                  }}
                  onChange={e => {
                    setCardDetails(
                      prev => (prev = { ...prev, card_cvv: e.target.value })
                    )
                    setClickedCvv(prev => (prev = true))
                    const startTime1 = new Date(startTime)

                    // Get the current time
                    const currentTime = new Date()

                    // Calculate the difference in minutes
                    const timeDifferenceInMinutes = Math.floor(
                      (startTime1 - currentTime) / (1000 * 60)
                    )

                    // Check if the difference is less than 120 minutes
                    if (timeDifferenceInMinutes >= 120 && cardType === null) {
                      setCardValidation(prev => (prev = true))
                      // console.log("card required")
                      return
                    } else {
                      setCardValidation(prev => (prev = false))
                    }
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  type="submit"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "#fdd005",
                    border: "none",
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "500",
                    width: "128px",
                    marginTop: "1rem",
                    display: "block",
                    margin: "auto",
                    boxShadow:
                      "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                    transition: "transform 0.3s ease",
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = "scale(1.05)"
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = "scale(1)"
                  }}
                >
                  {props.t("Add Card")}
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                      pointerEvents: "none",
                    }}
                  ></div>
                </Button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={packageModal}
        toggle={() => setPackageModal(prev => (prev = !prev))}
      >
        <ModalHeader toggle={() => setPackageModal(prev => (prev = !prev))}>
          {`Previous Packages`}
        </ModalHeader>
        <div>
          <ModalBody>
            <>
              <Row>
                <Col md="3">
                  <Nav pills className="flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Items
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Games
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <ToolkitProvider
                        keyField="item_id"
                        columns={PackageItemDetails}
                        data={item_details?.length > 0 ? item_details : []}
                        search
                      >
                        {propst => (
                          <div>
                            <AvForm>
                              <Row>
                                <Col
                                  lg={4}
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <SearchBar
                                    {...propst.searchProps}
                                    style={{ width: "340px" }}
                                  />
                                </Col>
                              </Row>
                            </AvForm>
                            <BootstrapTable
                              {...propst.baseProps}
                              noDataIndication={props.t("No data to display.")}
                              striped
                              hover
                              condensed
                              // selectRow={selectRowItems}
                              // rowStyle={rowStyle}
                              // pagination={paginationFactory(options)}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </TabPane>
                    <TabPane tabId="2">
                      {/* Game Details */}
                      <ToolkitProvider
                        keyField="game_id"
                        columns={PackageGameDetails}
                        data={game_details?.length > 0 ? game_details : []}
                        search
                      >
                        {propst => (
                          <div>
                            <AvForm>
                              <Row>
                                <Col
                                  lg={4}
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <SearchBar
                                    {...propst.searchProps}
                                    style={{ width: "340px" }}
                                  />
                                </Col>
                              </Row>
                            </AvForm>
                            <BootstrapTable
                              {...propst.baseProps}
                              noDataIndication={props.t("No data to display.")}
                              striped
                              hover
                              condensed
                              selectRow={selectRowGames}
                              // pagination={paginationFactory(options)}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </>
          </ModalBody>
        </div>
      </Modal>

      {/* add new customer modal --------------------------------------------------------------------------------- */}
      <Modal
        size="md"
        isOpen={addCustomerModal}
        toggle={() => setAddCustomerModal(prev => (prev = !prev))}
      >
        <ModalHeader toggle={() => setAddCustomerModal(prev => (prev = !prev))}>
          {`Add New Customer`}
        </ModalHeader>
        <div>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={handleSubmit} ref={refContainer}>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            label={props.t("Name")}
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Name cannot be empty")}
                          />
                        </Col>
                        <br />
                        <Col lg={6}>
                          <AvField
                            onWheel={preventValueChangeOnScroll}
                            label={props.t("Mobile")}
                            className="form-control"
                            type="number"
                            name="mobile"
                            placeholder="Enter Phone Number"
                            validate={{
                              required: { value: true },
                              maxLength: {
                                value: 13,
                                errorMessage: props.t(
                                  "Maximum 13 digits allowed."
                                ),
                              },
                            }}
                            errorMessage={props.t(
                              "Phone number cannot be empty"
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={6}>
                          <AvField
                            name="customer_type"
                            label={props.t("Customer Type")}
                            type="select"
                            defaultValue="Regular"
                            placeholder={props.t("--Select Sponser--")}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select a customer type")}
                          >
                            <option value="">Select Type</option>
                            <option value="Regular">
                              {props.t("Regular")}
                            </option>
                            <option value="Friend">{props.t("Friend")}</option>
                            <option value="VIP">VIP</option>
                          </AvField>
                        </Col>
                      </Row>
                      <Row>
                        <Button
                          type="submit"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "150px",

                            margin: "auto",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          {props.t("Add Customer")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>

      {/* view Booking details modal ----------------------------------------------------------------------------- */}

      <Modal
        size="xl"
        isOpen={bookingDetailsModal}
        toggle={() => setBookingDetailsModal(prev => (prev = !prev))}
      >
        <ModalHeader
          toggle={() => setBookingDetailsModal(prev => (prev = !prev))}
        >
          {"Booking Details"}
        </ModalHeader>

        <div>
          <ViewUpdateBooking
            bookingDetailsModal={bookingDetailsModal}
            setBookingDetailsModal={setBookingDetailsModal}
            bookedPackageDetailscolumns={bookedPackageDetailscolumns}
            bookedPackageDetails={bookedPackageDetails}
            PackageItemDetails={PackageItemDetails}
            bookedItemDetails={bookedItemDetails}
            PackageGameDetails={PackageGameDetails}
            bookedGameDetails={bookedGameDetails}
            selectedCalendarEvent={selectedCalendarEvent}
            setBookedGameDetails={setBookedGameDetails}
            setBookedItemsDetails={setBookedItemsDetails}
            setBookedPackageDetails={setBookedPackageDetails}
            endTime={endTime}
            loadAllBookings={loadAllBookings}
            keyChange={keyChange}
            setKeyChange={setKeyChange}
            showBookingDetails={showBookingDetails}
            addPackageItemDetailsColumn={addPackageItemDetailsColumn}
            addPackageGameDetailsColumn={addPackageGameDetailsColumn}
          />
        </div>
      </Modal>

      {/*--------------------------- Add Game Table Duration Details Modal -------------------------*/}

      <Modal
        size="xl"
        isOpen={addGamedetailsModal}
        toggle={() => {
          setAddGamedetailsModal(prev => (prev = !prev))
          let gameValidationCount = 0
          tablesToDisplay?.forEach(game => {
            if (game.game.duration === 0) {
              gameValidationCount += 1
            }
          })

          if (gameValidationCount > 0) {
            setGameValidation(prev => (prev = true))
            return
          } else {
            setGameValidation(prev => (prev = false))
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            setAddGamedetailsModal(prev => (prev = !prev))
            let gameValidationCount = 0
            tablesToDisplay?.forEach(game => {
              if (game.game.duration === 0) {
                gameValidationCount += 1
              }
            })

            if (gameValidationCount > 0) {
              setGameValidation(prev => (prev = true))
              return
            } else {
              setGameValidation(prev => (prev = false))
            }
          }}
        >
          {`Add/View Game Details`}
        </ModalHeader>
        <div>
          <ModalBody>
            <BootstrapTable
              //  onTableChange={handleTableSubmit}
              // ref={refGameTable}
              keyField="_id"
              data={tablesToDisplay || []}
              columns={addGameDetailsColumns}
              searchs
              cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
            />

            <ModalFooter>
              <Row>
                <Col
                  lg={12}
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-end",
                  }}
                >
                  <span>Game Grand Total Price :</span>
                  <span style={{ color: "black", fontWeight: "bolder" }}>
                    {" "}
                    {selectedGamePrice.toFixed(2)}{" "}
                  </span>
                </Col>
              </Row>
            </ModalFooter>
          </ModalBody>
        </div>
      </Modal>

      {/*--------------------------- Add Item order quantity Details Modal -------------------------*/}

      <Modal
        size="xl"
        isOpen={addItemdetailsModal}
        toggle={() => {
          setAddItemdetailsModal(prev => (prev = !prev))

          //items quantity field validation code below.............
          let itemValidationCount = 0
          itemsInTable?.forEach(item => {
            if (
              item.quantity.in_unit === 0 &&
              item.quantity.in_sub_unit === 0
            ) {
              itemValidationCount += 1
            }
          })

          if (itemValidationCount > 0) {
            setItemValidation(prev => (prev = true))
            return
          } else {
            setItemValidation(prev => (prev = false))
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            setAddItemdetailsModal(prev => (prev = !prev))

            //items quantity field validation code below.............
            let itemValidationCount = 0
            itemsInTable?.forEach(item => {
              if (
                item.quantity.in_unit === 0 &&
                item.quantity.in_sub_unit === 0
              ) {
                itemValidationCount += 1
              }
            })

            if (itemValidationCount > 0) {
              setItemValidation(prev => (prev = true))
              return
            } else {
              setItemValidation(prev => (prev = false))
            }
          }}
        >
          {`Add/View Item Order Details`}
        </ModalHeader>
        <div>
          <ModalBody>
            {/* <BootstrapTable
                            // onTableChange={handleTableSubmit}
                            // ref={refItemTable}
                            keyField="_id"
                            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                            data={selected_item}
                            columns={addItemsDetailsColumn}
                        /> */}
            <ModalFooter>
              <Row>
                <Col
                  lg={12}
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-end",
                  }}
                >
                  <span>Item Grand Total Price :</span>
                  <span style={{ color: "black", fontWeight: "bolder" }}>
                    {" "}
                    {selectedItemPrice}{" "}
                  </span>
                </Col>
              </Row>
            </ModalFooter>
          </ModalBody>
        </div>
      </Modal>

      {/* select prev package tables */}

      <Modal
        size="sm"
        isOpen={prevPkgGameSelectModal}
        toggle={() => {
          setPrevPkgGameSelectModal(prev => (prev = !prev))
          setPrevPkgSelectedTable(prev => (prev = []))
          setPrevPkgAvailableTables(prev => (prev = []))
        }}
      >
        <ModalHeader
          toggle={() => {
            setPrevPkgGameSelectModal(prev => (prev = !prev))
            setPrevPkgSelectedTable(prev => (prev = []))
            setPrevPkgAvailableTables(prev => (prev = []))
          }}
        >
          {`Select Package Game Tables`}
        </ModalHeader>

        <ModalBody style={{ height: "300px" }}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Available Tables</Label>
                      <Select
                        isDisabled={selectedCustomer === null}
                        name="tables"
                        label={props.t("Tables")}
                        placeholder="Tables" // type="text"
                        onChange={(selected, removed) => {
                          // console.log(removed, "removed")

                          if (removed.action === "remove-value") {
                            let newSelectedTables = prevPkgSelectedTable.filter(
                              table => table.id !== removed.removedValue.id
                            )
                            setPrevPkgSelectedTable(
                              prev => (prev = newSelectedTables)
                            )

                            setPrevPkgAvailableTables(prev => [
                              ...prev,
                              removed.removedValue,
                            ])
                          }

                          if (removed.action === "clear") {
                            setPrevPkgSelectedTable(prev => (prev = []))
                            setPrevPkgAvailableTables(
                              prev =>
                                (prev = [...prev, ...removed.removedValues])
                            )
                            setPreSave(prev => (prev = []))
                            let a = selectedPreviousPackage
                            a.package_details.game_details.forEach(function (
                              game
                            ) {
                              if (
                                game.table_number ===
                                removed.removedValues[0].label
                              ) {
                                game.table_number = ""
                                game.table_id = ""
                              }
                            })
                            setSelectedPreviousPackage(prev => {
                              prev = { ...a }
                              return prev
                            })
                            return
                          }

                          setPrevPkgSelectedTable(prev => (prev = selected))
                          let newAvailableTables =
                            prevPkgAvailableTables.filter(
                              table => table.id !== selected.id
                            )

                          setPrevPkgAvailableTables(
                            prev => (prev = [...newAvailableTables, ...preSave])
                          )
                          setPreSave(prev => (prev = [selected]))

                          let a = selectedPreviousPackage

                          a.package_details.game_details.forEach(function (
                            game
                          ) {
                            if (game.name === selected.name) {
                              game.table_number = selected.label
                              game.table_id = selected.id
                            }
                          })
                          setSelectedPreviousPackage(prev => {
                            prev = { ...a }
                            return prev
                          })
                        }}
                        options={prevPkgAvailableTables}
                        // ref={refSelectGame}
                        value={prevPkgSelectedTable}
                        // isMulti
                        isClearable={true}
                        isSearchable
                        required
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* View Package Details Modal */}

      <Modal
        size="xl"
        isOpen={addPackagedetailsModal}
        toggle={() => {
          setAddPackagedetailsModal(prev => (prev = !prev))
          setItemVisible(prev => (prev = true))
          setGameVisible(prev => (prev = false))

          let prevPkgGameValidationCount = 0
          let prevPkgItemValidationCount = 0
          let prevPkgGameMissedDetail = 0

          if (results.package_details.length > 0 && selected_package.label) {
            selectedPreviousPackage?.package_details?.item_details?.forEach(
              item => {
                if (
                  item.quantity.in_unit > 0 ||
                  item.quantity.in_sub_unit > 0
                ) {
                  prevPkgItemValidationCount += 1
                  return
                }
              }
            )

            selectedPreviousPackage?.package_details?.game_details?.forEach(
              item => {
                if (
                  item.requested_duration > 0 &&
                  (item.table_number ? item.table_number?.length > 0 : false)
                ) {
                  prevPkgGameValidationCount += 1
                  // return;
                } else if (
                  item.requested_duration > 0 ||
                  (item.table_number ? item.table_number?.length > 0 : false)
                ) {
                  prevPkgGameMissedDetail += 1
                }
              }
            )

            if (
              prevPkgGameValidationCount === 0 &&
              prevPkgItemValidationCount === 0
            ) {
              setPrevPkgValidation(prev => (prev = true))
              return
            } else if (prevPkgGameMissedDetail > 0) {
              setPrevPkgValidation(prev => (prev = true))
              return
            } else if (
              prevPkgGameValidationCount > 0 ||
              prevPkgItemValidationCount > 0
            ) {
              setPrevPkgValidation(prev => (prev = false))
              // return;
            }
          }
        }}
      >
        <ModalHeader
          toggle={() => {
            setAddPackagedetailsModal(prev => (prev = !prev))
            setItemVisible(prev => (prev = true))
            setGameVisible(prev => (prev = false))

            let prevPkgGameValidationCount = 0
            let prevPkgItemValidationCount = 0
            let prevPkgGameMissedDetail = 0

            if (results.package_details.length > 0 && selected_package.label) {
              selectedPreviousPackage?.package_details?.item_details?.forEach(
                item => {
                  if (
                    item.quantity.in_unit > 0 ||
                    item.quantity.in_sub_unit > 0
                  ) {
                    prevPkgItemValidationCount += 1
                    return
                  }
                }
              )

              selectedPreviousPackage?.package_details?.game_details?.forEach(
                item => {
                  if (
                    item.requested_duration > 0 &&
                    (item.table_number ? item.table_number?.length > 0 : false)
                  ) {
                    prevPkgGameValidationCount += 1
                    // return;
                  } else if (
                    item.requested_duration > 0 ||
                    (item.table_number ? item.table_number?.length > 0 : false)
                  ) {
                    prevPkgGameMissedDetail += 1
                  }
                }
              )

              if (
                prevPkgGameValidationCount === 0 &&
                prevPkgItemValidationCount === 0
              ) {
                setPrevPkgValidation(prev => (prev = true))
                return
              } else if (prevPkgGameMissedDetail > 0) {
                setPrevPkgValidation(prev => (prev = true))
                return
              } else if (
                prevPkgGameValidationCount > 0 ||
                prevPkgItemValidationCount > 0
              ) {
                setPrevPkgValidation(prev => (prev = false))
                // return;
              }
            }
          }}
        >
          {`Previous Package: ${selected_package?.label}`}
        </ModalHeader>
        <div>
          <ModalBody>
            <>
              <Row>
                <Col md="2">
                  <Nav pills className="flex-column">
                    {[selected_package]?.map((item, index) => (
                      <NavItem key={index + 1}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === index + 1,
                          })}
                          // onClick={() => {
                          //   setTableData(item)
                          //   setverticalActiveTab(prev => prev = index+1)
                          //   // toggleVertical(index + 1);
                          // }}
                        >
                          {item?.label}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    {[selected_package]?.map((item, index) => (
                      <TabPane key={index + 1} tabId={index + 1}>
                        <Row>
                          <Col lg={3}>
                            <AvForm>
                              <AvField
                                label={props.t("Select")}
                                className="form-control"
                                name="selectItemOrGame"
                                type="select"
                                // validate={
                                //   { required: false }
                                // }
                                // errorMessage="Please Select Branch"
                                onChange={e =>
                                  displayItemOrGAme(e.target.value)
                                }
                              >
                                <option value="item">Item</option>
                                <option value="game">Game</option>
                              </AvField>
                            </AvForm>
                          </Col>
                        </Row>
                        <br /> <br />
                        {itemVisible && (
                          <Row>
                            <Col>
                              <ToolkitProvider
                                keyField="item_id"
                                columns={addPackageItemDetailsColumn}
                                data={
                                  selectedPreviousPackage?.package_details
                                    ?.item_details || []
                                }
                                search
                              >
                                {propst => (
                                  <div>
                                    <AvForm>
                                      <Row>
                                        <Col
                                          lg={4}
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <SearchBar
                                            {...propst.searchProps}
                                            style={{ width: "340px" }}
                                          />
                                        </Col>
                                      </Row>
                                    </AvForm>
                                    <BootstrapTable
                                      {...propst.baseProps}
                                      noDataIndication={props.t(
                                        "No data to display."
                                      )}
                                      striped
                                      hover
                                      condensed
                                      // selectRow={selectRowItems}
                                      // onSelect={handleRowSelectItems}
                                      // rowStyle={rowStyle}
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                      })}
                                      // pagination={paginationFactory(options)}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        )}
                        {gameVisible && (
                          <Row>
                            <Col>
                              <ToolkitProvider
                                keyField="game_id"
                                columns={addPackageGameDetailsColumn}
                                data={
                                  selectedPreviousPackage?.package_details
                                    ?.game_details || []
                                }
                                search
                              >
                                {propst => (
                                  <div>
                                    <AvForm>
                                      <Row>
                                        <Col
                                          lg={4}
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <SearchBar
                                            {...propst.searchProps}
                                            style={{ width: "340px" }}
                                          />
                                        </Col>
                                      </Row>
                                    </AvForm>
                                    <BootstrapTable
                                      {...propst.baseProps}
                                      noDataIndication={props.t(
                                        "No data to display."
                                      )}
                                      striped
                                      hover
                                      condensed
                                      // selectRow={selectRowGames}
                                      cellEdit={cellEditFactory({
                                        mode: "click",
                                        blurToSave: true,
                                      })}
                                      // pagination={paginationFactory(options)}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        )}
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
              </Row>
            </>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(
  connect(null, {})(withTranslation()(SequentialReservation))
)
