import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  ModalBody,
  FormGroup,
  ModalHeader,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import exportFromJSON from "export-from-json"
import withRouter from "../../components/Common/withRouter"
import showNotification from "../../helpers/show_notificaton"
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter, selectFilter  } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const Designation = props => {
  useEffect(() => {
    loadAllDesignations()
  }, [])
  const { SearchBar } = Search
  const refContainer = useRef(null)

const handleDownload = async () => {
  const fileName = "All Designations";
  const exportType = "xls";

  let data_to_export = allDesignations;

  if(data_to_export){
    var data = [];
    JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
      data.push({
        Name: v.name,
        Medical_Required:v.is_medicalfitid_required ? "Yes" : "No",
        Driving_License_Required : v.is_driving_license_required ? "Yes" : "No"
      });
    });
    exportFromJSON({ data, fileName, exportType });
  }
} 
  //Boolean Values
  const [isMedi, setIsMedi] = useState(false)
  const [isDri, setIsDri] = useState(false)

  const [allDesignations, setAllDesignation] = useState([])
  const loadAllDesignations = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "designations/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      preloader(false)
      setAllDesignation(response.data)
      // console.log(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  const [allDesignation, setallDesignations] = useState()
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly" >
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setallDesignations(row)
            setUpdateMedical(row.is_medicalfitid_required)
            setUpdateDriving(row.is_driving_license_required)
          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
         

        <div className="form-switch ms-2" dir="ltr">
          <input
            type="checkbox"
            style={{height:"15px", width:"30px"}}
            title={row.is_active?"Click to Deactivate .":"Activate"}
            className="form-check-input"
            id={"customSwitchsize1" + row._id}
            onClick={() => { 
              handleValidDelete(row)
            }}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ fontWeight: "100", cursor: "pointer" }}
          ></label>
        </div>
       
      </span>
    )
  }

  //form submit
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "designations/insert"
    const object = {
      name: v.name,
      is_medicalfitid_required: isMedi,
      is_driving_license_required: isDri,
    }
    postSubmitForm(url, object).then(response => {
      if (response.status === 1) {
        loadAllDesignations()
        refContainer.current.reset()
        setIsDri(false)
        setIsMedi(false)
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    })
  }

  const [updateMedical, setUpdateMedical] = useState()
  const [updateDriving, setUpdateDriving] = useState()
  //Update
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: allDesignation._id,
        name: v.name,
        is_medicalfitid_required: updateMedical,
        is_driving_license_required: updateDriving,
      }

      let url = process.env.REACT_APP_BASEURL + "designations/update"
      let response = await postSubmitForm(url, object)
      if (response.status === 1) {
        loadAllDesignations()
        //  setUpdateMedical(allDesignation.is_medicalfitid_required)
        //  setUpdateDriving(allDesignation.is_driving_license_required)

        setEditModal(!editModal)
        refContainer.current.reset()
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }

  //Delete
  const handleValidDelete = async designation => {
    let url = process.env.REACT_APP_BASEURL + "designations/activate_deactivate"
    let response = await postSubmitForm(url, {
      id: designation._id,
      is_active : designation.is_active===true? false : true
    })
    if (response.status === 1) {
      loadAllDesignations()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }

  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Designation"),
      //formatter: employeeFormatter,
      dataField: "name",
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", marginLeft:"8px"
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Medical ID Required"),
      dataField: "is_medicalfitid_required",
      sort: false,
      filter: selectFilter({
        options: [
          {value: true, label :"Yes"},
          {value: false, label :"No"},
        ],
        placeholder:"ALL",
        style: { height: "", marginLeft:"8px",}
      }),
      formatter: cell => (cell ? "Yes" : "No"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Driving License Required"),
      //formatter: employeeFormatter,
      dataField: "is_driving_license_required",
      filter: selectFilter({
        options: [
          {value: true, label :"Yes"},
          {value: false, label :"No"},
        ],
        placeholder:"ALL",
        style: { height: "", marginLeft:"8px",}
      }),
      formatter: cell => (cell ? "Yes" : "No"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Action"),
      dataField: "",
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",  
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Designations" breadcrumbItem1="Add Designation" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  // style={{
                    
                  //   color: "white",
                  // background:
                  //   "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  // boxShadow:
                  //   "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  // }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    {/* <CardTitle>{props.t("Add Designation")}</CardTitle> */}
                    <CardSubtitle className="mb-3">
                      {/* {props.t(
                        "Enter the following details to add a New Designation"
                      )} */}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Designation *")}
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Designation")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Designation cannot be empty")}
                        />
                      </Col>
                      <Col lg={6} >
                        <Label>&nbsp;</Label>
                  
                        <AvCheckboxGroup name="checkboxGroup" inline  >
                          <AvCheckbox
                          
                            label={props.t("Medical Fit ID required")}
                            value="is_medicalfitid_required"
                            checked={isMedi}
                            //defaultValue={false}
                            onChange={e => setIsMedi(e.target.checked)}
                          />
                          <AvCheckbox
                            label={props.t("Driving License required")}
                            value="is_driving_license_required"
                            checked={isDri}
                            //defaultValue={false}
                            onChange={e => setIsDri(e.target.checked)}
                          />
                        </AvCheckboxGroup>
                      </Col>
                  
                    </Row>

        
                    <Row>
                      <Col lg={12} style={{display:"flex", justifyContent:"center"}}>  
                        
                        
                                     <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        
                      </Col>
                      
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
             
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                
                >
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Designations")}
                      </Col>

                      <Col lg={6} style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button
                     style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}

                    type="submit"
                    className="btn btn-sm btn-secondary"
                    onClick={handleDownload}
                     >
                      <i className="mdi mdi-file-export" /> &nbsp;
                       {props.t("Export CSV")}
                    </Button> 
                    </Col>
                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allDesignations && allDesignations}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{display:"flex", alignItems:"flex-start"}}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          filter={filterFactory()}
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Designation - ")}
                      {allDesignation && allDesignation.name}
                    </ModalHeader>
                    <ModalBody>
                      {allDesignation && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          name="name"
                                          label={props.t("Name")}
                                          placeholder={props.t("Enter Name")}
                                          type="text"
                                          errorMessage={props.t("Name cannot be empty.")}
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          value={allDesignation.name}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <Label>&nbsp;</Label>
                                        <br />
                                        <AvCheckboxGroup name="groupcheck" inline  >
                                          <AvCheckbox
                                            label={props.t("Medical Fit ID required")}
                                            value="is_medicalfitid_required"
                                            checked={updateMedical}
                                            // checked={allDesignation.is_medicalfitid_required}
                                            onChange={e =>
                                              setUpdateMedical(e.target.checked)
                                            }
                                          />
                                          <AvCheckbox
                                            name="is_driving_license_required"
                                            label={props.t("Driving License required")}
                                            value="is_driving_license_required"
                                            checked={updateDriving}
                                            //checked={allDesignation.is_driving_license_required}
                                            onChange={e =>
                                              setUpdateDriving(e.target.checked)
                                            }
                                          />
                                        </AvCheckboxGroup>
                                      </Col>

                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color=""
                                              className="mr-1 btn btn-primary"
                                              style={{width:"auto"}}
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Designation)))
