import React, { useRef, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  Button,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Label,
  FormGroup,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import Flatpickr from "react-flatpickr"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import axios from "axios"
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter"
import BackgroundImage from "../../assets/images/Background.png"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
//import moment from "moment"
import moment from "moment-timezone"
import preloader from "../../helpers/Loader"
import { postSubmitForm } from "../../helpers/backend_helper"

const EditAttendance = props => {
  const [data, setData] = useState([])
  const [shifts, setShifts] = useState({})

  const [attendanceDate, setAttendanceDate] = useState(new Date())
  const [tracker, setTracker] = useState(false)
  const [isNotCurrentDate, setIsNotCurrentDate] = useState(false)

  const [editModal, setEditModal] = useState()
  const [editInTimeModal, setEditInTimeModal] = useState(false)
  const [editOutTimeModal, setEditOutTimeModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [attendancelogs, setAttendanceLogs] = useState([])
  const [selectedLog, setSelectedLog] = useState()

  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [markUndertime, setMarkUndertime] = useState({ aksfjaslkf: false })
  const [isLoading, setIsLoading] = useState(false)

  const refContainer = useRef(null)
  const { SearchBar } = Search

  useEffect(() => {
    const getShifts = token => {
      let url = process.env.REACT_APP_BASEURL

      axios
        .post(
          url + `shifts/getall`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(res => setShifts(res.data.data))
        .catch(err => console.log(err))
    }
    getShifts(employeeToken)
  }, [])

  const fetchAttendance = (object, token) => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL
    axios
      .post(
        url + "attendances/getall_by_date",

        object,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        preloader(false)
        setData(res.data.data)
        setIsLoading(prev => (prev = false))
      })
      .catch(err => {
        preloader(false)
        console.log(err)
      })
  }

  useEffect(() => {
    const object = {
      attendance_date: new Date().toISOString().split("T")[0],
    }
    const token = employeeToken
    fetchAttendance(object, token)
  }, [])

  const updateAttendance = (object, token) => {
    let url = process.env.REACT_APP_BASEURL
    axios
      .post(
        url + "attendances/update",

        object,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        if (res.data.status === 1) {
          // console.log(res,"Res");
          showNotification(res.data.message, "Success")
          setTracker(true)
          let object = {
            attendance_date: moment().format("YYYY-MM-DD"),
          }
          fetchAttendance(object, token)
        } else {
          showNotification(res.data.message, "Error")
          return
        }
      })
      .catch(err => {
        console.log(err)
        showNotification(err, "Error")
      })
  }
  // ------------------------------------------------------------------------------------------------------

  const filterShiftId = shiftName => {
    //console.log("shifts", shifts)
    return shifts.filter(shift => shift.name === shiftName)[0]
  }
  //  -------------------------------------------------------------------------------------------
  const loadAttendancelogs = async row => {
    let object = {
      employee_code: row.employee_code,
      attendance_date: row.attendance_date,
    }
    let url = process.env.REACT_APP_BASEURL + "attendances/get_logs"
    const response = await postSubmitForm(url, object)
    if (response && response.status === 1) {
      setAttendanceLogs(response.data)
    } else {
      setAttendanceLogs([])
      // showNotification(response.message, "Error")
    }
  }

  const handleValidUpdateTime = async (e, v, type) => {
    if (selectedLog === "" || selectedLog === undefined) {
      showWarning("Can't update, No changes made ", "warning")
      return
    }
    const shiftObject = filterShiftId(selectedUser.shift_name)
    let payload = {}
    if (type === "Intime") {
      payload = {
        employee_id: selectedUser.employee_id,
        attendance_date: selectedUser.attendance_date,
        day_status: selectedUser?.day_status,
        shift_id: shiftObject?._id,
        in_time: selectedLog,
        out_time: selectedUser?.out_time,
      }
    } else if (type === "Outtime") {
      payload = {
        employee_id: selectedUser.employee_id,
        attendance_date: selectedUser.attendance_date,
        day_status: selectedUser?.day_status,
        shift_id: shiftObject?._id,
        in_time: selectedUser?.in_time,
        out_time: selectedLog,
        is_approved: selectedUser.is_approved,
        undertime: selectedUser.undertime,
      }
    }

    let url = process.env.REACT_APP_BASEURL + "attendances/update"
    const resp = await postSubmitForm(url, payload)
    if (resp && resp.status === 1) {
      showNotification(resp.message, "Success")
      let obje = {
        attendance_date: selectedUser?.attendance_date,
      }
      setAttendanceLogs([])
      setSelectedLog()
      setEditInTimeModal(false)
      setEditOutTimeModal(false)
      fetchAttendance(obje, employeeToken)
    } else {
      showNotification(resp.message, "Error")
    }
  }

  // console.log(attendancelogs,"At");
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        {row.day_status !== "Off" && (
          <i
            className="bx bxs-edit fs-5 mt-1"
            title="Click to Edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditModal(!editModal)
              setSelectedUser(row)
              setMarkUndertime(row.mark_undertime)
            }}
          ></i>
        )}
      </span>
    )
  }

  function editInTimeFormatter(cell, row) {
    return (
      <span>
        {row.in_time === null ? (
          <i
            className="bx bxs-edit fs-5 mt-1 text-danger"
            title="Click to Edit"
            style={{ cursor: "pointer", color: "burlywood" }}
            onClick={() => {
              setEditInTimeModal(true)
              setSelectedUser(row)
              loadAttendancelogs(row)
            }}
          />
        ) : (
          <p
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              setEditInTimeModal(true)
              setSelectedUser(row)
              loadAttendancelogs(row)
            }}
          >
            {row.in_time}
          </p>
        )}
      </span>
    )
  }

  function editOutTimeFormatter(cell, row) {
    return (
      <span>
        {row.out_time === null && row.in_time !== null ? (
          <i
            className="bx bxs-edit fs-5 mt-1 text-danger"
            title="Click to Edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setEditOutTimeModal(true)
              setSelectedUser(row)
              loadAttendancelogs(row)
            }}
          />
        ) : (
          <p
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => {
              setEditOutTimeModal(true)
              setSelectedUser(row)
              loadAttendancelogs(row)
            }}
          >
            {row.out_time}
          </p>
        )}
      </span>
    )
  }

  // console.log(selectedUser, "selected user")
  // ---------------------------------------------------------------------------------

  // handle valid submit to validate the inputs given to the form or if the form is empty
  const handleValidSubmit = async (e, v, token, status) => {
    let object = {}
    if (status === "modalUpdate") {
      const dateString = selectedUser.attendance_date
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1 // Months are zero-based, so add 1
      const year = date.getFullYear()

      // Format the date as "dd-mm-yyyy"
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`

      const shiftObject = filterShiftId(selectedUser.shift_name)
      // console.log("shiftId:", shiftObject)
      object = {
        employee_id: selectedUser.employee_id,
        attendance_date: formattedDate,
        shift_id: shiftObject._id,
        in_time: v.in_time,
        out_time: v.out_time,
        day_status: v.day_status,
        is_approved: v.is_approved,
        remarks: v.remarks,
        undertime: markUndertime === false ? 0 : v.undertime,
        mark_undertime:
          markUndertime === false ? false : v.undertime > 0 ? true : false,
      }
      // console.log(object, "modal object data")
      updateAttendance(object, token)
      // console.log("below update attendance")
      setEditModal(!editModal)
      refContainer.current.reset()
    } else {
      object = {
        attendance_date: v.attendance_date,
      }
      // console.log("HI");
      setAttendanceDate(object)
      fetchAttendance(object, token)
    }
    // setApiDatePayload(object)
    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error")
    //   }
    // })
  }

  const handleSubmit = (e, v, token) => {
    if (
      moment(v.attendance_date).format("YYYY-MM-DD") !==
      moment().format("YYYY-MM-DD")
    ) {
      setIsNotCurrentDate(true)
    } else {
      setIsNotCurrentDate(false)
    }
    let object = {
      attendance_date: moment(v.attendance_date).format("YYYY-MM-DD"),
    }
    // console.log(attendanceDate);
    fetchAttendance(object, token)
  }
  // function to extract the yy-mm-dd format of the date

  function dateFormatter(cell, row) {
    return row.attendance_date.slice(0, 10)
  }

  // -----------------------------------------------

  function isApprovedFormatter(cell, row) {
    return row.is_approved === true ? "Yes" : "No"
  }
  // --------------------------------------------------

  function dayStatusFormatter(cell, row) {
    return row.day_status && row.day_status === "P" ? (
      <Badge className={`fs-6 badge-soft-success`}>{row.day_status}</Badge>
    ) : row.day_status &&
      (row.day_status === "AL" ||
        row.day_status === "SL" ||
        row.day_status === "AL/P" ||
        row.day_status === "SL/P" ||
        row.day_status === "Off/P" ||
        row.day_status === "LWP/P") ? (
      <Badge className={`fs-6 badge-soft-warning`}>{row.day_status}</Badge>
    ) : row.day_status === "Off" ? (
      <Badge className={`fs-6 badge-soft-black`}>{row.day_status}</Badge>
    ) : row.day_status === "LWP" ? (
      <Badge className={`fs-6 badge-soft-danger`}>{row.day_status}</Badge>
    ) : row.day_status === null && isNotCurrentDate ? (
      <Badge className={`fs-6 badge-soft-danger`}>A</Badge>
    ) : null
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "employee_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    // {
    //   text: props.t("Employee Code"),
    //   //formatter: employeeFormatter,
    //   dataField: "employee_code",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" }
    //   },
    // },
    {
      text: props.t("Name/Code"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      formatter: (cell, row) => {
        return row.employee_name + "" + "(" + row.employee_code + ")"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Attendance Date"),
      formatter: dateFormatter,
      dataField: "attendance_date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Shift"),
      //formatter: employeeFormatter,
      dataField: "shift_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("In Time"),
      formatter: editInTimeFormatter,
      dataField: "in_time",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
    {
      text: props.t("Out Time"),
      formatter: editOutTimeFormatter,
      dataField: "out_time",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
    {
      text: props.t("Early In"),
      formatter: (cell, row) => {
        return row.late_in < 0 ? Math.abs(row.late_in) : ""
      },
      dataField: "late_in",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Late In"),
      formatter: (cell, row) => {
        return row.late_in > 0 ? Math.abs(row.late_in) : ""
      },
      dataField: "late_in",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Early Out"),
      formatter: (cell, row) => {
        return row.early_out > 0 ? Math.abs(row.early_out) : ""
      },
      dataField: "early_out",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Late Out"),
      formatter: (cell, row) => {
        return row.early_out < 0 ? Math.abs(row.early_out) : ""
      },
      dataField: "early_out",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("System Overtime"),
      //formatter: employeeFormatter,
      dataField: "system_overtime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Approved Overtime"),
      // formatter: (col, row) => row.approved_overtime || '0',
      dataField: "approved_overtime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Approved Undertime"),
      // formatter: (col, row) => row.approved_undertime || '0',
      dataField: "undertime",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Day Status"),
      formatter: dayStatusFormatter,
      dataField: "day_status",
      sort: true,
      // filter: textFilter({
      //   caseSensitive: false,
      //   style: {
      //     height: "20px",
      //   }
      // }),
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Is Appd."),
      formatter: isApprovedFormatter,
      dataField: "is_approved",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    // {
    //   text: props.t("Remarks"),
    //   //formatter: employeeFormatter,
    //   dataField: "remarks",
    //   // sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" }
    //   },
    // },
    {
      text: props.t("Action"),
      formatter: editFormatter,
      // dataField: "",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  console.log(selectedLog, "SL")
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Attendance" breadcrumbItem1="Edit Attendance" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm
                    onValidSubmit={(e, v) => {
                      setIsLoading(prev => (prev = true))
                      handleSubmit(e, v, employeeToken)
                    }}
                    ref={refContainer}
                  >
                    <Row>
                      <Col md={2}>
                        <AvField
                          label={props.t("Attendance Date")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={attendanceDate}
                          options={{
                            maxDate: "today",
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="attendance_date"
                          placeholder="Select Attendance Date"
                          errorMessage="Select Attendance Date"
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col md={2}>
                        <AvField
                          label={props.t("Select Branch")}
                          className="form-control"
                          type="select"
                          name="branch"
                          errorMessage="Please Select Branch"
                          validate={{
                            required: { value: false },
                          }}
                        >
                          <option value="">{props.t("All")}</option>
                          <option value="Rendezvous">
                            {props.t("Rendezvous")}
                          </option>
                        </AvField>
                      </Col>

                      <Col
                        md={4}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label
                          style={{ height: "20.5px" }}
                          className="form-label"
                        ></label>
                        <div style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "-0.2rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Show")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>{props.t("Search by any keyword")} </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    data={data}
                    columns={columns}
                    search
                  >
                    {propst => (
                      <div>
                        <Row>
                          <Col
                            lg={4}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <SearchBar
                              className="form-control"
                              {...propst.searchProps}
                              // style={{ width: "340px" }}
                              placeholder="search"
                            />
                          </Col>
                        </Row>

                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        <Col>
          <Modal
            size="lg"
            isOpen={editModal}
            toggle={() => setEditModal(prev => (prev = !prev))}
          >
            <ModalHeader toggle={() => setEditModal(prev => (prev = !prev))}>
              Update Attendance of {selectedUser && selectedUser.employee_name}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v, employeeToken, "modalUpdate")
                        }}
                      >
                        <Row>
                          <Col>
                            <label htmlFor="">In Time</label>
                            <AvField
                              className="form-control"
                              type="text"
                              tag={Flatpickr}
                              value={
                                (selectedUser && selectedUser.in_time) || 0
                              }
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true,
                              }}
                              name="in_time"
                              placeholder="In Time"
                              errorMessage="In Time cannot be Empty"
                              validate={{
                                required: {
                                  value:
                                    selectedUser && selectedUser.in_time
                                      ? true
                                      : true,
                                },
                              }}
                            />
                          </Col>

                          <Col>
                            <label htmlFor="">Out Time</label>
                            <AvField
                              className="form-control"
                              type="text"
                              tag={Flatpickr}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true,
                              }}
                              value={
                                (selectedUser && selectedUser.out_time) || 0
                              }
                              name="out_time"
                              placeholder="Out Time"
                              // errorMessage="Out Time cannot be Empty"
                              // validate={{
                              //   required: { value: selectedUser && selectedUser.out_time ? true : true },
                              // }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          {/* <Col>
                            <label htmlFor="">Day Status</label>
                            <AvField
                              className="form-control"
                              type="select"


                              value={selectedUser && selectedUser.day_status || "A"}
                              name="day_status"
                              placeholder="Day Status"
                              errorMessage="Day Status cannot be Empty"
                              validate={{
                                required: { value: selectedUser && selectedUser.day_status ? true : true },
                              }}
                            >
                              <option value="P">Present</option>
                              <option value="A">Absent</option>
                              <option value="LWP">LWP</option>
                            </AvField>
                          </Col> */}

                          <Col lg={6}>
                            <label htmlFor="">Is Approved</label>
                            <AvField
                              className="form-control"
                              type="select"
                              value={
                                selectedUser && selectedUser.is_approved
                                  ? "true"
                                  : "false" || "false"
                              }
                              name="is_approved"
                              placeholder="Is Approved"
                              errorMessage="Is Approved cannot be Empty"
                              validate={{
                                required: {
                                  value:
                                    selectedUser && selectedUser.is_approved
                                      ? false
                                      : false,
                                },
                              }}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </AvField>
                          </Col>

                          <Col lg={6}>
                            <label htmlFor="">Remarks</label>
                            <AvField
                              className="form-control"
                              type="textarea"
                              value={
                                (selectedUser && selectedUser.remarks) || ""
                              }
                              name="remarks"
                              placeholder="Remarks"
                              // errorMessage="Remarks cannot be Empty"
                              // validate={{
                              //   required: { value: selectedUser && selectedUser.remarks ? true : false },
                              // }}
                            />
                          </Col>
                        </Row>

                        <Row style={{ dsiplay: "flex" }}>
                          <Col lg={6}>
                            <Col>
                              {/* <FormGroup check> */}
                              <Label check>
                                <Input
                                  style={{ border: "1px solid #ced4da" }}
                                  type="checkbox"
                                  value={markUndertime}
                                  checked={markUndertime}
                                  // defaultChecked={markUndertime[selectedUser._id] || false}
                                  onChange={e =>
                                    setMarkUndertime(e.target.checked)
                                  }
                                />{" "}
                                Mark Undertime
                              </Label>
                              {/* </FormGroup> */}
                            </Col>

                            <Col>
                              <Col>
                                <AvField
                                  style={{ marginTop: "8px" }}
                                  className="form-control"
                                  type="text"
                                  disabled={markUndertime ? false : true}
                                  value={
                                    (selectedUser && selectedUser.undertime) ||
                                    "0"
                                  }
                                  name="undertime"
                                  placeholder="Undertime in minutes"
                                  // errorMessage="Remarks cannot be Empty"
                                  // validate={{
                                  //   required: { value: selectedUser && selectedUser.remarks ? true : false },
                                  // }}
                                />
                              </Col>
                            </Col>
                          </Col>
                        </Row>

                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Col
                            md={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="submit"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "100px",
                                // marginTop: "1.6rem",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              {props.t("Update")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      </Row>

      {/* updateInTime */}
      <Modal
        size="md"
        isOpen={editInTimeModal}
        toggle={() => {
          setEditInTimeModal(false)
          setSelectedUser(null)
        }}
      >
        <ModalHeader
          toggle={() => {
            setEditInTimeModal(false)
            setSelectedUser(null)
          }}
        >
          Update In Time of - {selectedUser && selectedUser.employee_name}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidUpdateTime(e, v, "Intime")
                    }}
                  >
                    <Row>
                      <Col lg={6} md={6}>
                        <AvField
                          label={props.t("In Time")}
                          className="form-control w-75"
                          type="text"
                          tag={Flatpickr}
                          value={
                            selectedLog ? selectedLog : selectedUser?.in_time
                          }
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            time_24hr: true,
                          }}
                          // disabled={attendancelogs?.length>0}
                          name="in_time"
                          onChange={e => {
                            let formattedTime = new Date(e)
                            let GetTime = moment(formattedTime).format("HH:mm")
                            setSelectedLog(GetTime)
                          }}
                          placeholder="In Time"
                          validate={{
                            required: {
                              value: selectedLog
                                ? selectedLog
                                : selectedUser?.in_time
                                ? false
                                : true,
                            },
                          }}
                          // errorMessage="In Time cannot be Empty"
                        />
                      </Col>

                      <Col lg={6} md={6}>
                        <Label>{props.t("Attendance Logs")}</Label>
                        <AvRadioGroup
                          name="in_time"
                          // value={selectedUser?.in_time}
                          // validate={{
                          //   required: {
                          //     value:
                          //       selectedUser && selectedUser.in_time
                          //         ? false
                          //         : true,
                          //   },
                          // }}
                          // errorMessage="Pick one!"
                        >
                          {attendancelogs?.map((log, index) => {
                            return (
                              <>
                                <AvRadio
                                  key={index + 1}
                                  label={moment(log.log_datetime)
                                    .tz("Asia/Qatar")
                                    .format("HH:mm  ( YYYY-MM-DD )")}
                                  value={moment(log.log_datetime)
                                    .tz("Asia/Qatar")
                                    .format("HH:mm")}
                                  onChange={e => setSelectedLog(e.target.value)}
                                />
                              </>
                            )
                          })}
                        </AvRadioGroup>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <Col
                        md={6}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="submit"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "100px",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          {props.t("Update")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      {/* updateOutTime */}
      <Modal
        size="md"
        isOpen={editOutTimeModal}
        toggle={() => {
          setEditOutTimeModal(false)
          setSelectedUser(null)
        }}
      >
        <ModalHeader
          toggle={() => {
            setEditOutTimeModal(false)
            setSelectedUser(null)
          }}
        >
          Update Out Time of - {selectedUser && selectedUser.employee_name}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidUpdateTime(e, v, "Outtime")
                    }}
                  >
                    <Row>
                      <Col lg={6} md={6}>
                        <AvField
                          label={props.t("Out Time")}
                          className="form-control fw-bold w-75"
                          type="text"
                          // disabled={attendancelogs?.length>0}
                          tag={Flatpickr}
                          value={
                            selectedLog ? selectedLog : selectedUser?.out_time
                          }
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            time_24hr: true,
                          }}
                          name="out_time"
                          onChange={e => {
                            let formattedTime = new Date(e)
                            let GetTime = moment(formattedTime).format("HH:mm")
                            setSelectedLog(GetTime)
                          }}
                          placeholder="Out Time"
                          // validate={{
                          //   required: { value: selectedLog ? selectedLog : selectedUser?.out_time ? false : true },
                          // }}
                          // errorMessage="In Time cannot be Empty"
                        />
                      </Col>

                      <Col lg={6} md={6} className="fw-bold">
                        <Label>{props.t("Attendance Logs")}</Label>
                        <AvRadioGroup
                          name="out_time"
                          // value={selectedUser?.out_time}
                          // validate={{
                          //   required:{value :selectedUser && selectedUser.out_time ? false : true}
                          // }}
                          errorMessage="Pick one!"
                        >
                          {attendancelogs?.map((log, index) => {
                            return (
                              <>
                                <AvRadio
                                  key={index + 1}
                                  label={moment(log.log_datetime)
                                    .tz("Asia/Qatar")
                                    .format("HH:mm  ( YYYY-MM-DD )")}
                                  value={moment(log.log_datetime)
                                    .tz("Asia/Qatar")
                                    .format("HH:mm")}
                                  onChange={e => setSelectedLog(e.target.value)}
                                />
                              </>
                            )
                          })}
                        </AvRadioGroup>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <Col
                        md={6}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="submit"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "100px",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          {props.t("Update")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(EditAttendance)))
