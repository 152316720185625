import React, { useEffect, useState, useRef } from "react"
import ReactAvatarEditor from "react-avatar-editor"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//i18next
import { withTranslation } from "react-i18next"
import { Country } from "country-state-city"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Container,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import BackgroundImage from "../../assets/images/Background.png"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import showNotification from "../../helpers/show_notificaton"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/backend_helper"
import makeAnimated from "react-select/animated"


const AddEmployees = props => {
  //meta title
  document.title = "Add Employee"
  useEffect(() => {
    setAllCountries(Country.getAllCountries())
    loadAllBranches()
    loadAllDesignations()
  }, [])
  const refContainer = useRef(null)
  const refNewBranchForm = useRef(null)
  const refSelectBranch = useRef(null)
  const [allCountries, setAllCountries] = useState([])
  const [branch, setBranch] = useState()
  const [selectedBranch, setSelectedBranch] = useState()
  //field Validation
  const [qatarID, setQatarID] = useState()
  const [passport, setPassport] = useState()
  const [medicalfitid, setMedicalfitid] = useState()
  const [healthid, setHealthid] = useState()
  const [drivinglicense, setDrivinglicense] = useState()
  const [insurance, setInsurance] = useState()
  const [qatarid_doc_url, set_qatarid_doc_url] = useState()
  const [passport_doc_url, set_passport_doc_url] = useState()
  const [healthcardid_doc_url, set_healthcardid_doc_url] = useState()
  const [medicalfitid_doc_url, set_medicalfitid_doc_url] = useState()
  const [driving_license_doc_url, set_driving_license_doc_url] = useState()
  const [insurance_policy_doc_url, set_insurance_policy_doc_url] = useState()

  // Validation for selected designation
  const [selectedDesignation, setSelectedDesignation] = useState("")
  const [medicalRequired, setMedicalRequired] = useState(false)
  const [drivingLicenseRequired, setDrivingLicenseRequired] = useState(false)

  const handleDesignationChange = event => {
    const selectedValue = event.target.value
    const selectedDesignation = allDesignations.find(
      designation => designation.name === selectedValue
    )

    setSelectedDesignation(selectedValue)
    setMedicalRequired(
      selectedDesignation.is_medicalfitid_required ? true : false
    )
    setDrivingLicenseRequired(
      selectedDesignation.is_driving_license_required ? true : false
    )
  }

  //for Avatar
  const editorRef = useRef()
  const [scale, setScale] = useState(1)
  const [rotate] = useState(0)
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
  const [selectedPhoto, setSelectedPhoto] = useState()
  const [borderRadius] = useState(0)
  const [allowZoomOut] = useState(false)

  const onPhotoChange = event => {
    setSelectedPhoto(event.target.files[0])
  }

  const handleFileChange = event => {
    event.target.files[0]
  }

  const handleScale = e => {
    const scale = parseFloat(e.target.value)
    setScale(scale)
  }

  //All Designations
  const [allDesignations, setAllDesignations] = useState([])
  const loadAllDesignations = async () => {
    let url = process.env.REACT_APP_BASEURL + "designations/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      const filter = response.data.filter(desig => desig.is_active===true)
        setAllDesignations(filter)
    } else {
      showNotification(response.message, "Error")
    }
  }
  // console.log(selectedBranch)
  //All Branches
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      setBranch(response.data)
      // console.log(response.data)
    } else {
      console.log("Error");
    }
  }

  function preventValueChangeOnScroll(e) {

    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current     
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
 
  // src to file function
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType })
      })
  }

  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "employees/insert"
    const boldRedText = `<br /><span style="color: red;"><b>Make sure to mark the shift for newly inserted Employee</b></span>`;
    let obj = {
      name: v.name,
      code: v.code,
      branch_details: {
        branch_id: selectedBranch._id,
        name: selectedBranch.name,
        address: selectedBranch.address,
        email: selectedBranch.email,
        mobile: selectedBranch.mobile,
      },
      address: v.address,
      date_of_birth: v.date_of_birth,
      date_of_joining: v.date_of_joining,
      qatarid: v.qatarid,
      qatarid_expirydate: v.qatarid_expirydate,
      passport: v.passport,
      passport_expirydate: v.passport_expirydate,
      healthcardid: v.healthcardid,
      healthcardid_expirydate: v.healthcardid_expirydate,
      medicalfitid: v.medicalfitid,
      medicalfitid_expirydate: v.medicalfitid_expirydate,
      driving_license_id: v.driving_license_id,
      driving_license_id_expirydate: v.driving_license_id_expirydate,
      insurance_policy_no: v.insurance_policy_no,
      insurance_policy_expirydate: v.insurance_policy_expirydate,
      mobile: v.mobile,
      email: v.email,
      designation: v.designation,
      sponser: v.sponser,
      nationality: v.nationality,
      annual_leave: v.annual_leave,
      sick_leave: v.sick_leave,
      basic_salary: v.basic_salary,
      food_allowance: v.food_allowance,
      housing_allowance: v.housing_allowance,
      other_allowance: v.other_allowance,
    }

    postSubmitForm(url, obj).then(response => {
      if (response.status === 1 && response.data) {
        url = process.env.REACT_APP_BASEURL + "employees/upload_document"
        if (qatarid_doc_url) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "qatarid_doc_url")
          formData.append("document_file", qatarid_doc_url)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              //setSelectedPhoto()
            }
          })
        }

        if (passport_doc_url) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "passport_doc_url")
          formData.append("document_file", passport_doc_url)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              //setSelectedPhoto()
            }
          })
        }

        if (healthcardid_doc_url) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "healthcardid_doc_url")
          formData.append("document_file", healthcardid_doc_url)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              //setSelectedPhoto()
            }
          })
        }

        if (medicalfitid_doc_url) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "medicalfitid_doc_url")
          formData.append("document_file", medicalfitid_doc_url)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              //setSelectedPhoto()
            }
          })
        }

        if (driving_license_doc_url) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "driving_license_doc_url")
          formData.append("document_file", driving_license_doc_url)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              //setSelectedPhoto()
            }
          })
        }

        if (insurance_policy_doc_url) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "insurance_policy_doc_url")
          formData.append("document_file", insurance_policy_doc_url)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              //setSelectedPhoto()
            }
          })
        }

        srcToFile(
          editorRef.current.getImageScaledToCanvas().toDataURL("image/png"),
          "new_image.png",
          "image/png"
        ).then(function (file) {
          let formData = new FormData()

          formData.set("id", response.data._id)
          formData.set("document_name", "image_url")
          formData.append("document_file", file)

          postSubmitForm_withformdata(url, formData).then(response => {
            if (response.status === 1 && response.data) {
              setSelectedPhoto()
            }
          })
        })

        // postSubmitForm_withformdata(url, formData).then(response => {
        //   if (response.status === 1 && response.data) {
        //     setSelectedPhoto()
        //   }
        // })
        loadAllBranches()
        loadAllDesignations()
        
        showNotification(response.message + " "+ boldRedText, "Success")
        // refSelectBranch.current.select.clearValue()
        refContainer.current.reset()
      } else {
        showNotification(response.message, "Error")
      }
    })
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };


  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Employees")}
            breadcrumbItem1={props.t("Add Employee")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <CardTitle>{props.t("Add Employee")}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {props.t(
                        "Enter the following details to add a new employee"
                      )}
                    </CardSubtitle>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name *")}
                          placeholder={props.t("Enter Name")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Name cannot be empty")}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="code"
                          label={props.t("Employee Code *")}
                          placeholder={props.t("Enter Employee code")}
                          type="text"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Employee code cannot be empty"
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          className="form-control"
                          name="date_of_birth"
                          label={props.t("Date of Birth *")}
                          placeholder={props.t("Select date of Birth")}
                          type="select"
                          tag={Flatpickr}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Date of Birth cannot be empty"
                          )}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          className="form-control"
                          name="date_of_joining"
                          label={props.t("Date of Joining *")}
                          placeholder={props.t("Select Date of Joining")}
                          type="select"
                          tag={Flatpickr}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Date of Joining cannot be empty"
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="email"
                          label={props.t("Email *")}
                          placeholder={props.t("Enter email *")}
                          type="email"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Email cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="mobile"
                          label={props.t("Mobile *")}
                          type="number"

                          placeholder={props.t("Enter mobile number")}
                          validate={{
                            required: { value: true },
                            maxLength: {
                              value: 13,
                              errorMessage: props.t(
                                "Maximum 13 Digits are allowed."
                              ),
                            },
                          }}
                          errorMessage={props.t("Phone number cannot be empty")}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="address"
                          label={props.t("Address *")}
                          type="textarea"
                          placeholder={props.t("Enter Address")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Address cannot be empty")}
                        />
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="sponser"
                          label={props.t("Sponser *")}
                          type="select"
                          placeholder={props.t("--Select Sponser--")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Sponser cannot be empty")}
                        >
                          <option value="">
                            -- {props.t("Select Sponser")} --
                          </option>
                          <option value="Rendezvous">
                            {props.t("Rendezvous")}
                          </option>
                          <option value="Outside">{props.t("Outside")}</option>
                        </AvField>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        {/* <Row>
                              <Col lg={9}>
                                <Label>{props.t("Branches")}</Label>
                                <Select
                                  ref={refSelectBranch}
                                  value={selectedBranches}
                                  isMulti={true}
                                  onChange={selected => {
                                    setSelectedBranches(selected)
                                  }}
                                  options={allBranches}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  required
                                />
                              </Col>
                              <Col lg={3}>
                                <Label>&nbsp;</Label>
                                <br />
                                <Link
                                  className="btn btn-primary waves-effect waves-light btn-sm"
                                  onClick={() => {
                                    setNewBranchModal(!newBranchModal)
                                  }}
                                >
                                  <i className="mdi mdi-file-plus"></i>{" "}
                                  {props.t("Add New")}
                                </Link>
                              </Col>
                            </Row> */}
                        <AvField
                          label={props.t("Branch")}
                          className="form-control"
                          name="branch_details"
                          type="select"
                          onChange={(e) => setSelectedBranch(branch.find(b => b.name === e.target.value))}
                        >
                          <option>{props.t("--Select Branch--")}</option>
                          {branch && branch.map((branch) => {
                            return (
                              <option key={branch._id} value={branch.name}>{branch.name}</option>
                            )
                          })}
                        </AvField>
                      </Col>
                      <Col lg={6}>
                        <AvField
                          name="designation"
                          label={props.t("Designation *")}
                          type="select"
                          onChange={handleDesignationChange}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(" Select Designation")}
                        >
                          <option value="">
                            -- {props.t("Select Designation")} --
                          </option>
                          {allDesignations &&
                            allDesignations.map(designation => (
                              <option
                                key={designation._id}
                                value={designation._name}
                              >
                                {designation.name}
                              </option>
                            ))}
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="qatarid"
                          label={props.t("Qatar ID *")}
                          type="number"
                          placeholder={props.t("Enter Qatar ID ")}
                          errorMessage={props.t(
                            "Both Qatar ID and Passport Number cannot be empty."
                          )}
                          onChange={e => {
                            setQatarID(e.target.value)
                          }}
                          validate={{
                            required: { value: passport ? false : true },
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <AvField
                              name="qatarid_expirydate"
                              label={props.t("Qatar ID Expiry Date *")}
                              placeholder={props.t(
                                "Enter Qatar ID expiry date"
                              )}
                              className="form-control"
                              tag={Flatpickr}
                              options={{
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  dayElem.addEventListener("mousedown", e => {
                                    e.preventDefault() // Prevent default click behavior
                                  })
                                },
                              }}
                              type="select"
                              validate={{
                                required: { value: passport ? false : true },
                              }}
                              errorMessage={props.t(
                                "Select Qatar ID Expiry Date"
                              )}
                            />
                          </Col>
                          <Col lg={4}>
                            <Label>&nbsp;</Label>
                            <br />
                            <AvField
                              className="btn btn-primary btn-sm"
                              name="qatarid_doc_url"
                              type="file"
                              onChange={e => {
                                set_qatarid_doc_url(e.target.files[0])
                              }}
                              validate={{
                                required: { value: passport ? false : true },
                              }}
                              errorMessage={props.t("Please Upload Qatar ID")}
                            >
                              {props.t("Upload File")}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="passport"
                          label={props.t("Passport No. *")}
                          type="text"
                          placeholder={props.t("Enter passport No.")}
                          errorMessage={props.t(
                            "Both Qatar ID and Passport Number cannot be empty."
                          )}
                          onChange={e => {
                            setPassport(e.target.value)
                          }}
                          validate={{
                            required: { value: qatarID ? false : true },
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <AvField
                              name="passport_expirydate"
                              label={props.t("Passport Expiry Date *")}
                              placeholder={props.t(
                                "Enter passport expiry date"
                              )}
                              className="form-control"
                              tag={Flatpickr}
                              options={{
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  dayElem.addEventListener("mousedown", e => {
                                    e.preventDefault() // Prevent default click behavior
                                  })
                                },
                              }}
                              type="select"
                              validate={{
                                required: { value: qatarID ? false : true },
                              }}
                              errorMessage={props.t(
                                "Select Passport Expiry Date"
                              )}
                            />
                          </Col>
                          <Col lg={4}>
                            <Label>&nbsp;</Label>
                            <br />

                            <AvField
                              className="btn btn-primary wave-effect btn-sm"
                              name="passport_doc_url"
                              type="file"
                              onChange={e => {
                                set_passport_doc_url(e.target.files[0])
                              }}
                              validate={{
                                required: { value: qatarID ? false : true },
                              }}
                              errorMessage={props.t("Please Upload Passport")}
                            >
                              {props.t("Upload File")}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="healthcardid"
                          label={props.t("Health Card No.")}
                          type="text"
                          placeholder={props.t("Enter Health Card No.")}
                          onChange={e => setHealthid(e.target.value)}
                          validate={{}}
                        />
                      </Col>

                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <AvField
                              name="healthcardid_expirydate"
                              label={props.t("Health Card Expiry Date")}
                              placeholder={props.t("Enter expiry date")}
                              className="form-control"
                              tag={Flatpickr}
                              options={{
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  dayElem.addEventListener("mousedown", e => {
                                    e.preventDefault() // Prevent default click behavior
                                  })
                                },
                              }}
                              type="select"
                              validate={{
                                required: { value: healthid ? true : false },
                              }}
                              errorMessage={props.t(
                                "Select Health Card Expiry Date"
                              )}
                            />
                          </Col>
                          <Col lg={4}>
                            <Label>&nbsp;</Label>
                            <br />

                            <AvField
                              className="btn btn-primary btn-sm"
                              name="healthcardid_doc_url"
                              type="file"
                              onChange={e => {
                                set_healthcardid_doc_url(e.target.files[0])
                              }}
                              validate={{
                                required: { value: healthid ? true : false },
                              }}
                              errorMessage={props.t(
                                "Please Upload Health Card"
                              )}
                            >
                              {props.t("Upload File")}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="insurance_policy_no"
                          label={props.t("Insurance Policy No.")}
                          type="text"
                          placeholder={props.t("Enter insurance no")}
                          onChange={e => setInsurance(e.target.value)}
                          validate={{}}
                        />
                      </Col>

                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <AvField
                              name="insurance_policy_expirydate"
                              label={props.t("Insurance Policy Expiry Date")}
                              placeholder={props.t(
                                "Enter Inurance Policy Expiry Date"
                              )}
                              className="form-control"
                              tag={Flatpickr}
                              options={{
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  dayElem.addEventListener("mousedown", e => {
                                    e.preventDefault() // Prevent default click behavior
                                  })
                                },
                              }}
                              type="select"
                              validate={{
                                required: { value: insurance ? true : false },
                              }}
                              errorMessage={props.t(
                                "Select Insurance Expiry Date"
                              )}
                            />
                          </Col>
                          <Col lg={4}>
                            <Label>&nbsp;</Label>
                            <br />

                            <AvField
                              className="btn btn-primary btn-sm"
                              name="insurance_policy_doc_url"
                              type="file"
                              onChange={e => {
                                set_insurance_policy_doc_url(e.target.files[0])
                              }}
                              validate={{
                                required: { value: insurance ? true : false },
                              }}
                              errorMessage={props.t("Please Upload Insurance")}
                            >
                              {props.t("Upload File")}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="medicalfitid"
                          label={props.t("Medical Fit ID")}
                          onChange={e => setMedicalfitid(e.target.value)}
                          type="text"
                          placeholder={props.t("Enter Medical fit ID")}
                          //validate={{}}
                          validate={{
                            required: { value: medicalRequired ? true : false },
                          }}
                          errorMessage={props.t("Enter Medical Fit ID")}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <AvField
                              name="medicalfitid_expirydate"
                              label={props.t("Medical Fit ID Expiry Date")}
                              className="form-control"
                              tag={Flatpickr}
                              options={{
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  dayElem.addEventListener("mousedown", e => {
                                    e.preventDefault() // Prevent default click behavior
                                  })
                                },
                              }}
                              type="select"
                              placeholder={props.t("Enter expirydate")}
                              validate={{
                                required: {
                                  value: medicalfitid ? true : false,
                                },
                              }}
                              errorMessage={props.t(
                                " Select Medical Expiry Date"
                              )}
                            />
                          </Col>
                          <Col lg={4}>
                            <Label>&nbsp;</Label>
                            <br />

                            <AvField
                              className="btn btn-primary btn-sm"
                              name="medicalfitid_doc_url"
                              type="file"
                              onChange={e => {
                                set_medicalfitid_doc_url(e.target.files[0])
                              }}
                              validate={{
                                required: {
                                  value: medicalfitid ? true : false,
                                },
                              }}
                              errorMessage={props.t("Please Upload Medical")}
                            >
                              {props.t("Upload File")}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="driving_license_id"
                          label={props.t("Driving License No.")}
                          type="text"
                          placeholder={props.t("Enter driving License no.")}
                          onChange={e => setDrivinglicense(e.target.value)}
                          validate={{
                            required: {
                              value: drivingLicenseRequired ? true : false,
                            },
                          }}
                          errorMessage={props.t("Enter Driving License ID")}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col lg={8}>
                            <AvField
                              name="driving_license_id_expirydate"
                              label={props.t("Driving License Expiry Date")}
                              className="form-control"
                              tag={Flatpickr}
                              options={{
                                onDayCreate: (dObj, dStr, fp, dayElem) => {
                                  dayElem.addEventListener("mousedown", e => {
                                    e.preventDefault() // Prevent default click behavior
                                  })
                                },
                              }}
                              type="select"
                              placeholder={props.t(
                                "Enter Driving license expiry date"
                              )}
                              validate={{
                                required: {
                                  value: drivinglicense ? true : false,
                                },
                              }}
                              errorMessage={props.t("Select Expiry Date")}
                            />
                          </Col>
                          <Col lg={4}>
                            <Label>&nbsp;</Label>
                            <br />

                            <AvField
                              className="btn btn-primary btn-sm"
                              name="driving_license_doc_url"
                              type="file"
                              validate={{
                                required: {
                                  value: drivinglicense ? true : false,
                                },
                              }}
                              onChange={e => {
                                set_driving_license_doc_url(e.target.files[0])
                              }}
                              errorMessage={props.t(" Please Upload Document ")}
                            >
                              {props.t("Upload File")}
                            </AvField>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="basic_salary"
                          label={props.t("Basic Salary")}
                          type="number"
                          placeholder={props.t("Enter Basic Salary")}
                          defaultValue="0"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Basic Salary cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="food_allowance"
                          label={props.t("Food Allowance")}
                          type="number"
                          placeholder={props.t("Enter Food Allowance")}
                          defaultValue="0"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Food Allowance cannot be empty"
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="housing_allowance"
                          label={props.t("Housing Allowance")}
                          type="number"
                          placeholder={props.t("Enter Housing Allowance")}
                          defaultValue="0"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Housing Allowance cannot be empty"
                          )}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="other_allowance"
                          label={props.t("Other Allowance")}
                          type="number"
                          defaultValue="0"
                          placeholder={props.t("Enter Other Allowance")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t(
                            "Other Allowance cannot be empty"
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="annual_leave"
                          label={props.t("Annual Leave")}
                          type="number"
                          defaultValue="21"
                          placeholder={props.t("Enter Annual Leave")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Annual Leave cannot be empty")}
                        />
                      </Col>
                      <Col lg={6}>
                        <AvField
                          onWheel={preventValueChangeOnScroll}
                          name="sick_leave"
                          label={props.t("Sick Leave")}
                          type="number"
                          defaultValue="21"
                          placeholder={props.t("Enter sick leave")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Sick Leave cannot be empty")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <AvField
                          name="nationality"
                          label={props.t("Nationality")}
                          type="select"
                          defaultValue="Qatar"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Select Nationality")}
                        >
                          <option value="">
                            -- {props.t("Select Nationality")} --
                          </option>

                          {allCountries &&
                            allCountries.map((item, ind) => {
                              return (
                                <option key={ind} value={item.name}>
                                  {item.name}
                                </option>
                              )
                            })}
                        </AvField>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col lg={6}>
                        <ReactAvatarEditor
                          ref={editorRef}
                          scale={parseFloat(scale)}
                          width={100}
                          height={100}
                          position={position}
                          onPositionChange={position => {
                            setPosition(position)
                          }}
                          rotate={rotate}
                          borderRadius={100 / (100 / borderRadius)}
                          image={selectedPhoto && selectedPhoto}
                          validate={{}}
                          className="editor-canvas"
                        />
                        <br />
                        <br />
                        <input
                          name="scale"
                          type="range"
                          onChange={handleScale}
                          min={allowZoomOut ? "0.1" : "1"}
                          max="2"
                          step="0.01"
                          defaultValue="1"
                        />
                        <br />
                        <br />
                        <input
                          type="file"
                          id="resume"
                          className="form-control-file"
                          onChange={onPhotoChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <hr />
                        <FormGroup className="mb-0 text-center">
                          <Row>
                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(connect(null, {})(withTranslation()(AddEmployees)))
