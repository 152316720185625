import React, { useEffect, useRef, useState } from "react"
import axios from "axios"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  CardTitle,
  Input,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import BackgroundImage from "../../assets/images/Background.png"


// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import Loader from "../../helpers/Loader"
import exportFromJSON from "export-from-json"
import { formatDate } from "@fullcalendar/core"
import moment from "moment"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";


const Loan = (props) => {
  const { SearchBar } = Search
  const refContainer = useRef(null)
  const [minDateVal, setMinDateVal] = useState("");
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [modal, setModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false)
  const [remark, setRemark] = useState("")
  const [flag, setFlag] = useState(false)
  const [align, setAlign] = useState("flex-end");
  const [csvBtnWidth, setCsvBtnWidth] = useState("140px")
  const [filterStatus, setFilterStatus] = useState("")
  const [filterData, setFilterData] = useState([]);
  const [DatePayload, setDatePayload] = useState({});
  const [currentDatePayload, setCurrentDatePayload] = useState({});
  const [allemployeesLoan, setAllEmployeesLoan] = useState([])
  const [selectedRow, setSelectedRow] = useState();
  const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
  const [fromDateHeading, setFromDateHeading] = useState("");
  const [toDateHeading, setToDateHeading] = useState("");
  const [resetBtnClickedTracker, setResetBtnClickedTracker] = useState(0)
  const [status, setStatus] = useState("");


  // console.log(filterData, "filter data")
  // console.log(allemployeesLoan, "all loans")

  useEffect(() => {

    if (DatePayload.startDate) {


    } else {

      let url = process.env.REACT_APP_BASEURL
      axios.post(url + "loans/pending", {}, {
        headers: {
          Authorization: `Bearer ${employeeToken}`,
        },
      }).then(res => {


        setFilterData([])
        setFilterStatus("")
        setAllEmployeesLoan(res.data.data)
      }).catch(err => console.log(err))

    }


  }, [resetBtnClickedTracker])

  // Function to handle viewport width changes
  function handleViewportChange() {

    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (viewportWidth < 992 && flag === false) {
      setAlign("flex-start")
      setCsvBtnWidth("180px")
      // flag = true;
      setFlag(true)
    }
  }

  function setFlagTrue() {


    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth >= 992 && flag === true) {
      setAlign("flex-end")
      setCsvBtnWidth("140px")

      setFlag(false)
    }
  }

  // Attach the event listener to the window's resize event
  window.addEventListener('resize', flag === false ? handleViewportChange : setFlagTrue);



  //Download Excel
  const handleDownload = async (e, v) => {
    const fileName = "All Loan";
    const exportType = "xls";

    // let data_to_export = allemployeesLoan;
    let data_to_export = status!=="" 
        ? allemployeesLoan.filter(item=>item.loan_status===status) 
        : allemployeesLoan

    if (data_to_export.length > 0) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.employee_name,
          Code: v.employee_code,
          Loan_Status: v.is_loan_active ? "Active" : "Completed",
          Loan_Amount: v.loan_amount,
          EMI: v.emi_amount,
          Tenure: v.tenure,
          Apply_Date: v.laon_apply_date,
          Approve_Date: v.laon_approval_date,
          Remaining_Loan: v.remaining_loan,
          Remaining_Tenure: v.remaining_tenure
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  //Update Loan

  // const [editLoanModal, setEditLoanModal] = useState()
  const [completedLoansModal, setCompletedLoansModal] = useState(false)


  function editFormatter(cell, row) {

    return (
      <div
        className="d-flex"
        style={{ width: "100%", gap: "10px" }}
      >
        <i
        size="md"
          className="me-2 fs-5 fa fa-check"
         style={{color:"#42eb60", cursor:"pointer"}}
          onClick={() => {
            setSelectedRow(row)
            setApproveModal(!approveModal)
}}
          disabled={
          row.loan_status === "Approved" || row.loan_status === "Rejected"
        }
        >
          
        </i>

        <i
      className="ms-2 fs-5 fa fa-times"
          size="md"
        style={{color:"red", cursor:"pointer"}}
          onClick={() => {
            setSelectedRow(row)
            setRejectModal(!rejectModal)
          }}
          disabled={
          row.loan_status === "Approved" || row.loan_status === "Rejected"
        }
        >
          
        </i>
        </div>
     
    )

  }
  const [loanModal, setLoanModal] = useState(false);
  const toggleLoanModal = () => {
    setLoanModal(!loanModal);
  };

  const handleValidUpdate = async () => {


    if (!selectedRow) {
      return; // Prevent updating if the row object is undefined
    }
    // Access the _id from the row object
    const { _id } = selectedRow;
    // let url = "http://localhost:3002/loans/update";
    let url = process.env.REACT_APP_BASEURL + "loans/update"
    const object = {
      id: _id,
      loan_status: "Approved",
    };
    const response = await postSubmitForm(url, object);
    if (response && response.status === 1) {
      refContainer.current.reset();

      if (DatePayload.startDate) {
        loadAllEmployeesLoan(_, DatePayload);
        showNotification(response.message, "Success");
      } else {
        setResetBtnClickedTracker(prev => prev += 1)

      }
    } else {
      showNotification(response.message, "Error");
    }
  };


  //Reject
  const toggleModal = () => {
    setModal(!modal)
  }
  const handleReject = () => {
    toggleModal()
  }
  const handleRejectSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "loans/update"
    //let url = "http://localhost:3002/loans/update"
    console.log(selectedRow, "selected row")
    const { _id } = selectedRow;
    const object = {
      id: _id,
      remark: remark,
      loan_status: "Rejected",
    }
    const response = await postSubmitForm(url, object)
    if (response && response.status === 1) {

      if (DatePayload.startDate) {
        loadAllEmployeesLoan(_, DatePayload);
        showNotification(response.message, "Success");
      } else {
        setResetBtnClickedTracker(prev => prev += 1)

      }
      
    } else {
      showNotification(response.message, "Error")
    }
    toggleModal()
  }

  //Insert
  const handlSubmit = (e, v) => {
    toggleLoanModal();
  }
  //select employee
  function handleSelect(data) {
    setSelectedEmployee(data);
  }


  //Al Employees Loan table
  const [allCompletedLoan, setAllCompletedLoan] = useState([])
  const loadAllCompletedLoan = async () => {
    let url = process.env.REACT_APP_BASEURL + "loans/completed"
    // let url = "http://localhost:3002/loans/completed"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      setAllCompletedLoan(response.data)
      console.log(response.data, "loan data")
    } else {
      showNotification(response.message, "Error")
    }
  }

  //Al Employees Loan table

  const loadAllEmployeesLoan = async (e, v) => {
    Loader(true)
    const object = {
      startDate: v.startDate,
      endDate: v.endDate,
    }


    const from_Date = object.startDate.slice(8, 10)
    const to_Date = object.endDate.slice(8, 10)

    const month = object.startDate.slice(5, 7)
    const date = new Date(`2000-${month}-01`);
    const monthString = date.toLocaleString('default', { month: 'short' });

    const toMonth = object.endDate.slice(5, 7)
    const toDate = new Date(`2000-${toMonth}-01`);
    const toMonthString = toDate.toLocaleString('default', { month: 'short' });

    const year = object.endDate.slice(0, 4);


    setFromDateHeading(`${from_Date} ${monthString}`)
    setToDateHeading(`${to_Date} ${toMonthString}`)


    setDatePayload(object)


    let url = process.env.REACT_APP_BASEURL + "loans/getall_by_date_range"
    // let url = "http://localhost:3002/loans/getall_by_date_range"
    const response = await postSubmitForm(url, object)
    if (response && response.status === 1) {
      Loader(false)
      setFilterData([])
      setFilterStatus("")
      setAllEmployeesLoan(response.data)
      // setPageReloadExecuter(prev => prev += 1)
      // console.log(response.data, "loan data")
    } else {
      Loader(false)
      showNotification(response.message, "Error")
    }
  }


  // const handleFilterChange = status => {
  //   setFilterStatus(status)
  //   const filterData = status !== "All"
  //     ? allemployeesLoan.filter(
  //       loan => loan.loan_status === status
  //     )
  //     : allemployeesLoan

  //   console.log(filterData, "filterd data based on status filter")
  //   setFilterData(filterData)
  // }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Name/Code"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      formatter: (cell, row) => {
        return row.employee_name + "" + "(" + row.employee_code + ")"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "loan_amount",
      text: props.t("Loan Amount"),
      formatter: (cell, row) => cell,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Applied Date",
      dataField: props.t("loan_apply_date"),
      formatter: (cell, row) => {
        const applyDate = row.loan_apply_date;
        const formattedDate = moment(applyDate).format('YYYY-MM-DD');
        return formattedDate;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Approved Date",
      dataField: props.t("loan_approval_date"),
      formatter: (cell, row) => {

        const approveDate = row.loan_approval_date ? row.loan_approval_date : null;
        if (approveDate === null) {
          return;
        }
        const formattedDate = moment(approveDate).format('YYYY-MM-DD');
        return formattedDate;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "emi_amount",
      text: props.t("EMI"),
      formatter: (cell, row) => cell,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "tenure",
      text: props.t("Tenure"),
      sort: true,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "loan_reason",
      text: props.t("Reason"),
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "remaining_loan",
      text: props.t("Remaining Loan   "),
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "remaining_tenure",
      text: props.t("Remaining Tenure   "),
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Status"),
      dataField: "loan_status",
      formatter: (cell, row) => {
        return (
          <>
            <Badge className={`fs-10 badge-soft-${row.loan_status === "Approved" ? "success" : row.loan_status === "Pending" ? "warning" : row.loan_status === "Rejected" ? "danger" : null}`}>
              {row.loan_status}
            </Badge> <br />
            {row.remark && "Remark's : " + row.remark}
          </>
        )
      },
      filter: selectFilter({
        options: [
          { value: "Approved", label: "Approved" },
          { value: "Pending", label: "Pending" },
          { value: "Rejected", label: "Rejected" },
        ],
        defaultValue:"Pending",
        placeholder: "ALL",
        onFilter: (value) => {
          setStatus( value )
        } 
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },

    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },

  ]
  // ----------------------------------------------------

  const completedLoansColumns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Employee Code"),
      //formatter: employeeFormatter,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "employee_name",
      text: props.t("Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "loan_amount",
      text: props.t("Loan Amount"),
      formatter: (cell, row) => cell || '0',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "emi_amount",
      text: props.t("EMI"),
      formatter: (cell, row) => cell || '0',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "tenure",
      text: props.t("Tenure"),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "remaining_loan",
      text: props.t("Remaining Loan   "),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "remaining_tenure",
      text: props.t("Remaining Tenure   "),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "",
      text: props.t("Status"),
      formatter: (cell, row) => {
        return (
          <Badge className="fs-6 badge-soft-success" >Completed</Badge>
        )
      },

      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },

  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content"
       style={backgroundImageStyle}
      >
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Salary")}
            breadcrumbItem1={props.t("Approve Loan")}
            alternateTitle="Approve Loan"
          />


          {/* //All Loan */}
          <Row>
            <Col>
              <Card>
                <CardBody
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <Row style={{ display: "flex", justifyContent: "space-between" }} >


                    <AvForm onValidSubmit={loadAllEmployeesLoan} ref={refContainer}>

                      <Row>
                        {/* <Col lg={8}>
                          <Row> */}
                            <Col lg={3}>
                              <AvField
                                className="form-control"
                                type="select"
                                tag={Flatpickr}
                                label="From Date"

                                options={{

                                  onDayCreate: (dObj, dStr, fp, dayElem) => {
                                    dayElem.addEventListener('mousedown', (e) => {
                                      e.preventDefault(); // Prevent default click behavior
                                    });
                                  },
                                }}
                                onChange={(v) => {

                                  // if (minDateOnChangeActive === true) {


                                  const dateString = v;


                                  const date = new Date(dateString);

                                  // Extract the date components
                                  const day = date.getDate();
                                  const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                  const year = date.getFullYear();

                                  // Format the date as "dd-mm-yyyy"
                                  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                  // console.log(formattedDate); // Output: 26-12-2023
                                  setMinDateVal(formattedDate);
                                  setDisabledStatus(false)
                                  //   setMaxDateOnChangeActive(false)
                                  // }
                                }}
                                name="startDate"
                                placeholder="Select From Date"
                                errorMessage="Select From Date."
                                validate={{
                                  required: { value: true },
                                }}


                              />
                            </Col>
                            <Col lg={3}>
                              <AvField
                                className="form-control"
                                type="select"
                                tag={Flatpickr}
                                label="To Date"
                                onChange={(v) => {

                                  // if (maxDateOnChangeActive === true) {


                                  const dateString = v;


                                  const date = new Date(dateString);

                                  // Extract the date components
                                  const day = date.getDate();
                                  const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                  const year = date.getFullYear();

                                  // Format the date as "dd-mm-yyyy"
                                  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                  // console.log(formattedDate); // Output: 26-12-2023
                                  // setMaxDateVal(formattedDate)

                                  //   setMinDateOnChangeActive(false)
                                  // }
                                }}
                                disabled={disabledStatus}
                                options={{
                                  minDate: minDateVal,
                                  onDayCreate: (dObj, dStr, fp, dayElem) => {
                                    dayElem.addEventListener('mousedown', (e) => {
                                      e.preventDefault(); // Prevent default click behavior
                                    });
                                  },

                                }}

                                name="endDate"
                                placeholder="Select To Date"
                                errorMessage="Select To Date."
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </Col>
                            {localStorage.getItem("role") === "Owner" && <Col lg={3}>
                 
                            <AvField
                              label={props.t("Select Branch")}
                              className="form-control"
                              type="select"
                              name="branch"
                              errorMessage="Please Select Branch"
                              validate={{
                                required: { value: false },
                              }}
                            >
                              <option value="">{props.t("All")}</option>
                              <option value="Rendezvous">{props.t("Rendezvous")}</option>
                            </AvField>

                          
                          </Col>}


                            <Col lg={3}>
                            <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.5rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Show")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                            </Col>
                          {/* </Row>
                        </Col> */}

                        {/* <Col lg={4} style={{ display: "flex", justifyContent: `${align}`, height: "40px", marginTop: "15px" }} >

                          <Button className="btn btn-info"
                            onClick={() => { setCompletedLoansModal(!completedLoansModal); loadAllCompletedLoan() }} >Completed Loans</Button>

                        </Col> */}

                      </Row>
                    </AvForm>


                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {/* <label className="d-flex justify-content-center fs-2">Arear, Special and Punishment Record</label> */}
              <Card>
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >

                  <Row >
                    <Col lg={10}>
                      <CardTitle className="fs-3" >
                        {fromDateHeading !== "" ? `${fromDateHeading} - ${toDateHeading}` : "All Time Pending"}
                      </CardTitle>
                    </Col>


                    <Col lg={2} style={{ display: "flex", justifyContent: `${align}` }} >
                      <Button
                        style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                        type="submit"
                        className="btn btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export" /> &nbsp;
                        {props.t("Export CSV")}
                      </Button>
                    </Col>

                  </Row>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allemployeesLoan}
                    search
                    //isGlobalFilter={true}
                    isAddUserList={true}
                    // handleUserClick={handleUserClicks}
                    customPageSize={10}
                  // className="custom-header-css"

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            {/* <label className="form-label">
                              {props.t("Search By Employee Name")}
                            </label> */}
                            <Col lg={6} style={{ display: "flex", gap: "10px" }}>
                              <SearchBar
                                {...propst.searchProps}

                              />
                              {/* <Button style={{height:"38px",padding:"3px"}} className="btn btn-info" onClick={() => { loadAllEmployeesLoan(_, currentDatePayload) }}  >Reset To Current Month</Button> */}
                            </Col>
                            {/* <Col  >
                            </Col> */}

                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed

                        //   cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                  {/* update Loan */}
                  {/* <Modal
                    size="lg"
                    isOpen={editLoanModal}
                    toggle={() => setEditLoanModal(!editLoanModal)}
                  >
                    <ModalHeader toggle={() => setEditLoanModal(!editLoanModal)}>
                      {props.t("Review Loan Details - ")} {selectedRow && selectedRow.employee_name}
                    </ModalHeader>
                    <ModalBody>
                      {selectedRow && (
                        <Row>
                          <Col xlg={12}>
                            <Card>
                              <CardBody>
                                <AvForm onValidSubmit={handleValidUpdate} ref={refContainer}>
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        name="loan_amount"
                                        label={props.t("Loan Amount")}
                                        placeholder={props.t("Enter Loan Amount")}
                                        type="number"
                                        errorMessage="Loan Amount cannot be empty."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={selectedRow && selectedRow.loan_amount}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <AvField
                                        name="emi_amount"
                                        label={props.t("EMI")}
                                        placeholder={props.t("Enter EMI")}
                                        type="number"
                                        errorMessage="EMI cannot be empty."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={selectedRow && selectedRow.emi_amount}
                                      />
                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        name="tenure"
                                        label={props.t("Tenure")}
                                        placeholder={props.t("Enter Tenure")}
                                        type="number"
                                        errorMessage="Tenure cannot be empty."
                                        validate={{
                                          required: { value: true },
                                        }}
                                        value={selectedRow && selectedRow.tenure}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>

                                    <Col lg={12}>
                                      <hr />
                                      <FormGroup className="mb-0 text-center">
                                        <div>
                                          <Button
                                           
                                            type="submit"
                                            color=""
                                            className="mr-1 btn btn-primary"
                                          >
                                            {props.t("Approve")}
                                          </Button>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      )}
                    </ModalBody>
                  </Modal> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Modal isOpen={loanModal} toggle={toggleLoanModal} size="lg">
            <ModalHeader toggle={toggleLoanModal}>Add Loan</ModalHeader>
            <ModalBody>
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <AvForm onValidSubmit={AddLoan} ref={refContainer}>

                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="loan_amount"
                              type="number"
                              label={props.t("Loan Amount")}
                              placeholder={props.t("")}
                              validate={{ required: { value: true, errorMessage: 'Please type Loan Amout' } }}
                            />
                          </Col>
                          <Col lg={6}>
                            <AvField
                              name="emi_amount"
                              type="number"
                              label={props.t("EMI Amount")}
                              placeholder={props.t("")}
                              validate={{ required: { value: true, errorMessage: 'Please type EMI Amount' } }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6}>
                            <AvField
                              name="tenure"
                              type="number"
                              label={props.t("Tenure")}
                              placeholder={props.t("")}
                              validate={{ required: { value: true, errorMessage: 'Please type Tenure' } }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={12}>
                            <FormGroup className="mb-0 text-center">
                              <Button type="submit" color="primary" className="mr-1"  >
                                {props.t("Submit")}
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ModalBody>
          </Modal> */}

          <Row>
            <Col>
              <Modal
                size="lg"
                isOpen={completedLoansModal}
                toggle={() => setCompletedLoansModal(!completedLoansModal)}
              >
                <ModalHeader toggle={() => setCompletedLoansModal(!completedLoansModal)} >
                  Completed Loans
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <CardTitle>
                            <Row>
                              <Col lg={6}>
                                {props.t("All Completed Loans")}
                              </Col>

                              <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                  type="submit"
                                  className="btn btn-sm btn-secondary"
                                  name="completed"
                                  onClick={handleDownload}
                                >
                                  <i className="mdi mdi-file-export" /> &nbsp;
                                  {props.t("Export CSV")}
                                </Button>
                              </Col>
                            </Row>
                          </CardTitle>
                          <ToolkitProvider
                            keyField="_id"
                            columns={completedLoansColumns}
                            data={allCompletedLoan}
                            search
                            isAddUserList={true}
                            customPageSize={10}
                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar
                                        {...propst.searchProps}
                                        placeholder="search"
                                      />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable
                                  {...propst.baseProps}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed

                                //   cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
          {/* Approve Modal */}
          < Modal isOpen={approveModal} toggle={() => setApproveModal(!approveModal)} >
            <ModalHeader toggle={() => setApproveModal(!approveModal)}>Approve Loan</ModalHeader>
            <ModalBody>
              <div>
                <label className="form-label">Are you sure ?</label>
                {/* <Input
              type="number"
              className="form-control"
              value={approved}
              onChange={(e) => setApproved(e.target.value)}
            /> */}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setApproveModal(!approveModal)}>
                Cancel
              </Button>
              <Button color="success" onClick={() => { setApproveModal(!approveModal); handleValidUpdate(selectedRow) }}>
                Approve
              </Button>
            </ModalFooter>
          </Modal >

          {/* Reject Modal */}
          < Modal
            isOpen={rejectModal}
            toggle={() => setRejectModal(!rejectModal)}
            className="d-flex align-items-center justify-content-center"
          >
            <ModalHeader toggle={() => setRejectModal(!rejectModal)}>Reject Loan</ModalHeader>
            <ModalBody>
              <Input
                type="textarea"
                placeholder="Enter remark"
                value={remark}
                onChange={e => setRemark(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setRejectModal(!rejectModal)}>
                Cancel
              </Button>
              <Button color="danger" onClick={() => { setRejectModal(!rejectModal); handleRejectSubmit(selectedRow) }}>
                Reject
              </Button>
            </ModalFooter>
          </Modal >
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(connect(null, {})(withTranslation()(Loan)))
