import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import exportFromJSON from "export-from-json"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr";
import moment from "moment"

const Expense = props => {
  const { SearchBar } = Search
  const [allEmployees, setAllEmployees] = useState([])
  const [allSources, setAllSources] = useState([])
  const [selectedSource, setSelectedSource] = useState({})
  const [allExpenses, setAllExpenses] = useState([])


  useEffect(() => {
    loadAllSources()
    loadAllExpenses()
  }, [])

  const refContainer = useRef(null)

  const handleDownload = async () => {
    const fileName = "All_Expenses";
    const exportType = "xls";

    let data_to_export = allExpenses;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Source: v.source.name,
          Description: v.source.description,
          Amount: v.amount,
          Transaction_Date:v.date_of_transaction!=="" ? moment(v.date_of_transaction).format("DD-MM-YYYY") : "" 
          
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  
  const loadAllSources = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "sources/getall"
    const response = await postSubmitForm(url, {})

    if (response && response.status === 1) {
      preloader(false)
      setAllSources(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }


 


  const loadAllExpenses = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "expenses/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false)
      setAllExpenses(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }


  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "expenses/insert"
    const object = {
      source:selectedSource,
      amount:v.amount,
      date_of_transaction:v.date_of_transaction
    }

    postSubmitForm(url, object).then(api_response => {
      if (api_response.status == 1 && api_response.data) {
        refContainer.current.reset()
        loadAllExpenses()
        showNotification(api_response.message, "Success")
      } else {
        showNotification(api_response.message, "Error")
      }
    })
  }


  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: "Source",
      //formatter: employeeFormatter,
      dataField: "source.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "source.description",
      text: props.t("Description"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "date_of_transaction",
      text: props.t("Transaction Date"),
      formatter:(cell,row)=>{
       
          return moment(row.date_of_transaction).format("DD-MM-YYYY")

      },
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Expense")}
            breadcrumbItem1={props.t("Add Expense")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    //   color: "white",
                    background:
                        "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                       
                          <label className="form-label text-white">
                            {props.t("Source *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            name="source"
                            placeholder={props.t("--Select Source--")}
                            errorMessage={props.t("Please Select Source")}
                            onChange={event => {
                              const selectedSource = allSources.find(src => src._id === event.target.value);
                              setSelectedSource(selectedSource)
                            }}
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">
                              {props.t("--Select Source--")}
                            </option>
                            {allSources &&
                              allSources.map(src => (
                                <option value={src._id} key={src._id}> {src.name} </option>
                              ))}
                          </AvField>
                  
                      </Col>
                      <Col lg={6}>
                
                          <label className="form-label text-white">
                            {props.t("Amount*")}
                          </label>
                          <AvField
                            className="form-control"
                            type="number"
                            name="amount"
                            placeholder={props.t("Enter Amount")}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Please Select Amount")}
                          >

                          </AvField>
            
                      </Col>
                    </Row>
                    <Col lg={6}>

                      <Label style={{ color: "White" }}>
                        {props.t("Transaction Date*")}
                      </Label>

                      <AvField
                        className="form-control"
                        type="select"
                        tag={Flatpickr}
                        options={{
                          onDayCreate: (dObj, dStr, fp, dayElem) => {
                            dayElem.addEventListener('mousedown', (e) => {
                              e.preventDefault(); // Prevent default click behavior
                            });
                          },
                        }}

                        name="date_of_transaction"
                        placeholder="Select Date"
                        errorMessage="Please Select Date"
                        validate={{
                          required: { value: true },
                        }}
                      />

                    </Col>
                    <Row>

                    </Row>

                    <Row>

                      <Col lg={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "1.6rem",
                              margin: "auto",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Submit")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Expenses")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all Expenses")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allExpenses && allExpenses}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Expense)))
