import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import logo from "../../../assets/images/Logo.png"
import BackgroundImage from "../../../assets/images/Background.png"
import preloader from "../../../helpers/Loader"

const viewdailyClosure = props => {

    const code = localStorage.getItem("code")
    const { SearchBar } = Search

    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [fromdate, setFromdate] = useState(new Date())
    const [todate, setTodate] = useState(new Date())

    const handleDownload = async () => {
        // console.log("hello")
        const fileName = "daily_closure_report";
        const exportType = "xls";

        let data_to_export = dailyClosureDetails;

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    closerDate: moment(v.closerDate).format("YYYY-MM-DD"),
                    is_saved: v.is_saved,
                    total_income: v.total_income,
                    net_income: v.total_net_income,
                    products_sale: v.products_sold,
                    game_sale: v.games_sale,
                    package_sale: v.package_sold,
                    refund: v.total_refund,
                    extra_income: v.total_extra_amount+v.total_difference_amount,
                    completed_bookings: v.completed_bookings,
                    cancelled_bookings: v.cancelled_bookings,
                    customers_visited: v.customers_visited,
                    paid_by_cash: v.paid_by_cash,
                    cash_in_hand: v.cash_in_hand,
                    // remarks: v.remarks,
                    paid_by_card: v.paid_by_card,
                    wallet_credit_total: v.wallet_credit_total,
                    wallet_debit_total: v.wallet_debit_total
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }
      const [dailyClosureDetails, setDailyClosureDetails] = useState([])

    useEffect(() => {
        handleValidSubmit();
    }, [])

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const imageHeight = "105px";
        const imageWidth = "110px";
        const borderradius = "50%";
        const background = "#000000";
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 140px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Daily Closure Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function () {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };


    const loadDailyClosureDetails = async (payload) => {
       try {
        let dateFrom = moment(payload.from_date);
        let dateTo = moment(payload.to_date);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
        preloader(true)
        let url = process.env.REACT_APP_BASEURL + "reports/view_daily_closers"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            setDailyClosureDetails(prev => prev = response.data)
            preloader(false)
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
       } catch (error) {
        preloader(false)
        showNotification(error.message,"Error")
       }
    }

    // fetch data
    const handleValidSubmit = async () => {
        const object = {
            from_date: moment(fromdate).format("YYYY-MM-DD"),
            to_date: moment(todate).format("YYYY-MM-DD")
        }
        loadDailyClosureDetails(object);
    }

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                
                return rowIndex+1
            },
            text: props.t("#"),
            footer: "",
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
        },
        {
            text: props.t("Date"),
            dataField: "closerDate",
            formatter: (cell, row) => {
                return moment(row.closerDate).format("YYYY-MM-DD")
            },
            footer: "",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            text: props.t("Total Income"),
            dataField: "total_income",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Net Income"),
            dataField: "total_net_income",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Refund"),
            dataField: "total_refund",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Extra Income"),
            dataField: "total_extra_amount",
            formatter:(cell,row)=>{
                return row?.total_difference_amount+row?.total_extra_amount
            },
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Item Sale"),
            dataField: "products_sold",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Game Sale"),
            dataField: "games_sale",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Package Sale"),
            dataField: "package_sold",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Cash"),
            dataField: "paid_by_cash",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },

        {
            text: props.t("Card"),
            dataField: "paid_by_card",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item || 0, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Wallet Credit"),
            dataField: "wallet_credit_total",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Wallet Debit"),
            dataField: "wallet_debit_total",
            sort: true,
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },

        {
            text: props.t("Cash in Hand"),
            dataField: "cash_in_hand",
            sort: true,
            formatter:(cell,row)=>{
                return row.cash_in_hand.toFixed()
            },
            footer: columnData => columnData.reduce((acc, item) => acc + item, 0),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
          {
            text: props.t("Breakup of Cashier "),
            dataField: "cashier_break_up",
            formatter: (cell, row) => {
                return (
                    <>
                    {row.cashier_break_up.length>0 && (
                          <div className="container mt-2" style={{ fontSize: '12px'}}>
                          <div className="row" style={{ fontSize: '12px',fontWeight:"bold",textDecoration:"underline" }} >
                              <div className="col">Cashier</div>
                              <div className="col">A/C</div>
                              <div className="col">C/H</div>
                              {/* <div className="col">Card</div> */}
                              <div className="col">Remarks</div>
                          </div>
                          {row?.cashier_break_up?.map((item, index) => (
                              <div className="row cash-breakup-row" key={index}>
                                  <div className="col">{item.cashier}</div>
                                  <div className="col">{item.actual_cash}</div>
                                  <div className="col">{item.cash_in_hand}</div>
                                  {/* <div className="col">{item.actual_card}</div> */}
                                  <div className="col">{item.remarks}</div>
                              </div>
                          ))}
                      </div>
                    )}
                    </>
                );
            },
            footer:false,
            headerStyle: (colum, colIndex) => {
              return { width: "25%" }
            },
          },

    ]   

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="View Daily Closure" breadcrumbItem1="View Daily Closure" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row >
                                            <Col md={3}>
                                                <label className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={fromdate}
                                                    options={{

                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {

                                                        // if (minDateOnChangeActive === true) {


                                                        const dateString = v;


                                                        const date = new Date(dateString);
                                                        setFromdate(date)
                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)
                                                        //   setMaxDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    name="from_date"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col md={3}>

                                                <label className="form-label">
                                                    To Date
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {

                                                        // if (maxDateOnChangeActive === true) {


                                                        const dateString = v;


                                                        const date = new Date(dateString);
                                                        setTodate(date)
                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        // setMaxDateVal(formattedDate)

                                                        //   setMinDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}

                                                    name="to_date"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />


                                            </Col>

                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "128px",
                                                        marginTop: "1rem",
                                                        // margin: "auto",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >

                                    <div id="printDiv">
                                        <ToolkitProvider
                                            keyField="_id"
                                            columns={columns}
                                            data={dailyClosureDetails && dailyClosureDetails}
                                            search

                                        >
                                            {propst => (
                                                <div>
                                                    <AvForm>
                                                        <Row className="hide">
                                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                            </Col>
                                                            <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                <Button
                                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                                    className="btn btn-sm btn-secondary"
                                                                    onClick={() => { printDiv("printDiv") }}
                                                                    disabled={dailyClosureDetails.length <= 0}
                                                                >
                                                                    <i
                                                                        className="fa fa-print " aria-hidden="true"
                                                                        onClick={() => { printDiv("printDiv") }}
                                                                    />&nbsp; {props.t("Print")}
                                                                </Button>
                                                                <Button
                                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                                    type="submit"
                                                                    className="btn btn-sm btn-secondary"
                                                                    onClick={handleDownload}
                                                                    disabled={dailyClosureDetails.length <= 0}
                                                                >
                                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                                    {props.t("Export CSV")}
                                                                </Button>
                                                            </Col>

                                                        </Row>
                                                    </AvForm>
                                                    <BootstrapTable

                                                        {...propst.baseProps}
                                                        filter={filterFactory()}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        hover
                                                        condensed
                                                    // pagination={paginationFactory(options)}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(viewdailyClosure)))
