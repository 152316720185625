import React from 'react'
import { Badge, Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import withRouter from './Common/withRouter'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import "../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import logo from "../assets/images/Logo.png"
import { AvForm } from 'availity-reactstrap-validation'

const SpentDetails = ({ details, toggle, isOpen, t }) => {

    const handleOpenInvoice = (cell, row) => {
            return (<>
                <i
                    style={{ color: "blue", cursor: "pointer" }}>
                    <a href={"/invoice_details?booking_id=" + row.booking_id} target="_blank" rel="noreferrer">
                        {row.invoice_no}
                    </a>
                </i>
            </>
            )
    }
    // Print
    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const imageHeight = "105px";
        const imageWidth = "110px";
        const borderradius = "50%";
        const background = "#000000";
        printWindow.document.write(`
   
       <html>
        <title> Rendezvous - Game Parlour</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
         .hide{
            display:none;
         }
         .react-bootstrap-table-pagination {
            display: none; 
        }
        .heading{
            display: flex;
            align-items: center;
        }
        h2 {
            margin: 0; 
        }
        #logoImg {
            height: ${imageHeight};
            width: ${imageWidth};
            background-color: ${background};
            border-radius: ${borderradius};
            margin-right: 200px; 
        }
        </style>
       </head>
    <body>
   <div class="heading">
      <img src=${logo} id="logoImg" alt="Logo" >
      <h2> ${divName}</h2>
   </div>
  ${content.innerHTML}
    </body>
       </html>

    `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function () {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    }
    // Export CSV
    const handleDownloadSpentDetails = async () => {
        const fileName = "Spent Details";
        const exportType = "xls";
        let data_to_export = details
        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    Invoice: v.invoice_no,
          ItemTotal: v.item_total_price,
          GameTotal: v.game_total_price,
          DiscountAmount: v.discount_amount,
          PaidAmount: v.final_amount,
          Transactiondate: moment(v.createdAt).format("YYYY-MM-DD HH:mm:ss")
        });
            });
            exportFromJSON({ data, fileName, exportType });
        }
        exportFromJSON({ data, fileName, exportType });
    };
    const Column = [
        {
          dataField: "_id",
          hidden: true,
        },
        {
          dataField: "_id",
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1;
          },
          text: t("#"),
          headerStyle: (colum, colIndex) => {
            return { width: "2%" };
          },
          footer:false
        },
        {
          dataField: "invoice_no",
          text: t("Invoice"),
          formatter: handleOpenInvoice ,
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "8%" };
          },
          footer:false
        },
        {
          text: t("Games"),
          dataField: "game_details",
          formatter: (gameDetails, row) => {
            if (gameDetails && gameDetails.length > 0) {
              return gameDetails.map((game, id) => (
                <Badge key={id} color="primary" className="me-1">
                  {game.game.name}
                </Badge>
              ))
            }
            return (
              <Badge style={{ cursor: "not-allowed" }} color="secondary">
                No Games
              </Badge>
            )
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
          footer:false
        },
        {
          text: t("Items"),
          dataField: "item_details",
          formatter: (itemDetails, row) => {
            if (itemDetails && itemDetails.length > 0) {
              return itemDetails.map((item, id) => (
                <Badge key={id} color="info" className="me-1">
                  {item.name}
                </Badge>
              ))
            }
    
            return (
              <Badge style={{ cursor: "not-allowed" }} color="secondary">
                No Items
              </Badge>
            )
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
          footer:false
        },
        {
          text: t("Packages"),
          dataField: "package_details",
          formatter: (packageDetails, row) => {
            if (packageDetails && packageDetails.length > 0) {
              return packageDetails.map((packages, id) => (
                <Badge
                  className="glow-effect me-1"
                  key={id}
                  color="success"
                  onClick={() => handlePackageBadgeClick(packages)}
                >
                  {packages.package_name}
                </Badge>
              ));
            }
            return (
              <Badge className="cursor-not-allowed" color="secondary">
                No Packages
              </Badge>
            );
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" };
          },
          footer:false
        },
        {
          dataField: "item_total_price",
          text: t("Items Total"),
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" };
            },
          footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
        },
        {
          dataField: "game_total_price",
          text: t("Games Total"),
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" };
            },
          footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
        },
        {
          dataField: "discount_amount",
          text: t("Discount Amount"),
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" };
            },
          footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
        },
        {
          dataField: "final_amount",
          text: t("Final Amount"),
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" };
            },
          footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
        },
    
        {
          dataField: "createdAt",
          text: t("Transaction Date"),
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" };
          },
          formatter: (cell, row) => {
            return moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
          },
          footer:false
          //   style: { cursor: "pointer" },
        },
    
      ];

    return (
        <>
            <Modal
                size="xl"
                isOpen={isOpen}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle} >
                    {t("Spent Breakup")}
                </ModalHeader >
                <ModalBody style={{ minHeight: "200px" }}>


                    <div id="Spent Breakup">
                        <ToolkitProvider
                            keyField="_id"
                            data={details || []}
                            columns={Column}
                            noDataIndication={t(
                                "No data to display."
                            )}
                            //   bootstrap4
                            search
                        >
                            {(propst) => (
                                <div>
                                    <AvForm>
                                        <Row className="hide">


                                            <Col lg={12} style={{ display: "flex", justifyContent: "flex-end",marginBottom:"2px" }}>

                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    disabled={details?.length <= 0}
                                                    onClick={() => { printDiv("Spent Breakup") }}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("Spent Breakup") }}
                                                    />&nbsp; {t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownloadSpentDetails}
                                                    disabled={details.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {t("Export CSV")}
                                                </Button>

                                            </Col>
                                        </Row>
                                    </AvForm>

                                    <BootstrapTable
                                        striped
                                        keyField="_id"
                                        hover
                                        condensed
                                        {...propst.baseProps}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </div>
                </ModalBody>
            </Modal>

        </>
    )
}

export default withRouter(connect(null, {})(withTranslation()(SpentDetails)))