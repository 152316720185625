import React from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"

const ItemPie = (props) => {
  let data = props.data
  
  let chartdata = [
      { value: 0, name: props.t("Accessories") },
      { value: 0, name: props.t("Cafe") },
      { value: 0, name: props.t("Food") },
      { value: 0, name: props.t("Kitchen") },

  ];
  if (data) {
    chartdata = [
      { name: props.t("Accessories"), value: data.accessories },
      { name: props.t("Food"), value: data.food },
     
    ];
  }
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: [
        props.t("Accessories"),  
        props.t("Food"),  
      ],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: ["#FF5733", "#3366FF"],
    series: [
      {
        name: props.name,
        type: "pie",
        radius: "55%",
        center: ["60%", "60%"],
        data: chartdata,          
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default withRouter(connect(null, {})(withTranslation()(ItemPie)));
