import React, { useEffect, useState } from 'react';

const ViewFiles = () => {
  const [docsLink, setDocsLink] = useState('');
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const documentUrl = urlParams.get('docs');
    setDocsLink(documentUrl);

    if (documentUrl) {
      const extension = documentUrl.split('.').pop().toLowerCase();
      if (extension === 'pdf') {
        setFileType('pdf');
      } else {
        setFileType('image');
      }
    }
  }, []);

  const renderFile = () => {
    if (fileType === 'pdf') {
      return (
        <iframe
          src={`https://docs.google.com/viewer?url=${docsLink}&embedded=true`}
          title="PDF Viewer"
          width="100%"
      height="800px"
          style={{ border: 'none' }}
        />
      );
    } else if (fileType === 'image') {
      return <>
      <div style={{margin: "auto", width:"fit-content", marginTop:"100px"}}>
      <img src={docsLink} alt="img" />
      </div>
      </>;
    } else {
      return <p>No file available</p>;
    }
  };

  return <>{renderFile()}</>;
};

export default ViewFiles;
