import React from 'react';
import PropTypes from 'prop-types';
import './CustomButton.css';

const Button = ({ children, className, type, onClick, onSubmit, ...props }) => {
  const handleClick = (e) => {
    e.preventDefault(); 
    if (onClick) {
      onClick(e);
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault(); 
    if (onSubmit) {
      onSubmit(e);
    }
  };
// console.log(type,"type prop")
// console.log(onClick,"click prop")
if(onSubmit){
  return (
    
    <button
      className={`button-71 ${className}`}
      type={type}
      // onClick={handleClick || handleSubmit}
      onSubmit={handleSubmit}
      {...props}
    >
      {children}
    </button>
  );
}else{

  return (
    
      <button
        className={`button-71 ${className}`}
        type={type}
        onClick={handleClick}
        // onSubmit={handleSubmit}
        {...props}
      >
        {children}
      </button>
    );
}
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit']),
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
