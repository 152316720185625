import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import exportFromJSON from "export-from-json"
import withRouter from "../../components/Common/withRouter"
import showNotification from "../../helpers/show_notificaton"
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter,selectFilter } from 'react-bootstrap-table2-filter';
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const AddCategory = props => {
  document.title = "Add New Category"

  const refContainer = useRef(null)

  const { SearchBar } = Search

  useEffect(() => {
    loadAllCategories()
  }, [])


  //form submit
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + 'item_category/insert'
    //let url = "http://localhost:3002/item_category/insert"
    const object = {
      section: v.section,
      name: v.name,
    }
    //  console.log("object",object)
    postSubmitForm(url, object).then(response => {
      if (response.status === 1) {
        loadAllCategories()
        refContainer.current.reset()
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    })
  }

  //getall
  const [allCategory, setAllCategory] = useState([])
  const loadAllCategories = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "item_category/get_all_item_category"
    // let url = "http://localhost:3002/item_category/get_all_item_category"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      preloader(false)
      setAllCategory(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }


  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Section"),
      //formatter: employeeFormatter,
      dataField: "section",
      filter: selectFilter({
        style:{marginLeft:"10px"},
        options: [
          { value: "Accessories", label: "Accessories" },
          { value: "Cafe", label: "Cafe" },
          { value: "Kitchen", label: "Kitchen" },
        ],
        placeholder: "ALL",
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]
  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs 
            title={props.t("Item Category")}
            breadcrumbItem1={props.t("Add New Category")} 
            alternateTitle={props.t("Add Category")} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>

                      <Col lg={6}>
                        <AvField
                          name="section"
                          label={props.t("Select Section *")}
                          className="form-control"
                          type="select"
                          placeholder={props.t("Select Category Section")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Please Select Category Section")}
                        >
                          <option value="">{props.t("--Select Section--")}</option>
                          <option value="Accessories">{props.t("Accessories")}</option>
                          <option value="Cafe">{props.t("Cafe")}</option>
                          <option value="Kitchen">{props.t("Kitchen")}</option>
                        </AvField>
                      </Col>

                      <Col lg={6}>
                        <AvField
                          name="name"
                          label={props.t("Name *")}
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Category")}
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Category cannot be empty")}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>


                      <Button
                        type="submit"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "100px",
                          marginTop: "1.6rem",
                          margin: "auto",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseEnter={e => {
                          e.target.style.transform = "scale(1.05)"
                        }}
                        onMouseLeave={e => {
                          e.target.style.transform = "scale(1)"
                        }}
                      >
                        {props.t("Submit")}
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            background:
                              "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                            pointerEvents: "none",
                          }}
                        ></div>
                      </Button>



                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>

                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}

                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Categories")}
                      </Col>
                    </Row>
                  </CardTitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allCategory && allCategory}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AddCategory)))
