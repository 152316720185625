import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import exportFromJSON from "export-from-json"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"


const Users = props => {
  const { SearchBar } = Search
  //meta title
  document.title = "Add Users | Rendezvous - Game Parlour"
  const [editModal, setEditModal] = useState()
const [selectedUser, setSelectedUser] = useState()
const [passwordModal, setPasswordModal] = useState()
  useEffect(() => {
    loadAllEmployees()
    loadAllUsers()
  }, [])

  const refContainer = useRef(null)

  const role = localStorage.getItem("role")
  
  const handleDownload = async () => {
    const fileName = "All_Users";
    const exportType = "xls";

    let data_to_export = selectedUser;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Employee_Name:v.employee_details.name,
          Role:v.role,
          Username:v.username,
          Status:v.status,
          Mobile: v.employee_details.mobile
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };
  const [allEmployees, setAllEmployees] = useState([])
  const loadAllEmployees = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "employees/get_to_create_user"
    const response = await postSubmitForm(url, {})

    if (response && response.status === 1) {
      preloader(false)
      setAllEmployees(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  
  const [allUsers, setAllUsers] = useState([])
  const loadAllUsers = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "adminusers/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      const filter = response.data.filter(user=>user.username!=="admin")
      preloader(false)
      setAllUsers(role==="Admin" ? response.data : filter)
    } else {
      preloader(false)
      console.log("Error");
      // showNotification(response.message, "Error")
    }
  }
  
  function showStatusFormatter(cell, row) {
    if (row.is_active) {
      return (
        <span className="font-size-12 badge-soft-success badge badge-success badge-pill">
          {props.t("Active")}
        </span>
      )
    } else {
      return (
        <span className="font-size-12 badge-soft-danger badge badge-danger badge-pill">
          {props.t("Inactive")}
        </span>
      )
    }
  }
  function PasswordFormatter(cell, row) {
   return (
     <span className="text-info d-flex justify-content-evenly">
       <i
         className="fas fa-key text-dark fs-5 mt-1"
         title="Change Password"
         style={{ cursor: "pointer" }}
         onClick={() => {
           setPasswordModal(!passwordModal)
           setSelectedUser(row)
         }}
       /></span>
   )
  }
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/insert"
   
    const object = {
      employee_id: v.employee_id,
      username: v.username,
      pwd: v.pwd,
      role: v.role,
    }
    //console.log("Data", object)
    postSubmitForm(url, object).then(api_response => {
      if (api_response.status == 1 && api_response.data) {
        refContainer.current.reset()
        loadAllEmployees()
        loadAllUsers()
        showNotification(api_response.message, "Success")
      } else {
        showNotification(api_response.message, "Error")
      }
    })
  }

  const handleValidUpdate = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/update"
   
    const object = {
      username: selectedUser?.username,
      role: v.role,
    }

    // console.log("Data", object)
    // return;
    postSubmitForm(url, object).then(api_response => {
      if (api_response.status == 1 && api_response.data) {
        refContainer.current.reset()
        setEditModal(prev=>!prev)
        loadAllEmployees()
        loadAllUsers()
        showNotification(api_response.message, "Success")
      } else {
        showNotification(api_response.message, "Error")
      }
    })
  }

  //UpdatePassword
  const handleValidUpdatePassword = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/resetpassword"
   
    const object = {
      username: selectedUser?.username,
      newpwd : v.newpwd ,
    }

    // console.log("Data", object)
    // return;
    postSubmitForm(url, object).then(api_response => {
      if (api_response.status === 1) {
        refContainer.current.reset()
        showNotification(api_response.message, "Success")
        setPasswordModal(prev=>!prev)
        loadAllEmployees()
        loadAllUsers()
      } else {
        showNotification(api_response.message, "Error")
      }
    })
  }

   //Delete
   const handleValidDelete = async selectedUser => {
    let url = process.env.REACT_APP_BASEURL + "adminusers/activate_deactivate"
    let response = await postSubmitForm(url, {
      username: selectedUser.username,
      is_active: selectedUser.is_active === true ? false : true
    })
    if (response.status === 1) {
      showNotification(response.message, "Success")
      loadAllUsers()
    } else {
      showNotification(response.message, "Error")
    }
  }


  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedUser(row)
          }}
        ></i>
        
        <i>
          <div className="form-switch ms-2" dir="ltr">
            <input
              type="checkbox"
              style={{height:"15px", width:"30px"}}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>
      </span>
    )
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      // headerStyle: (colum, colIndex) => {
      //   return { width: "3%" }
      // },
    },
    {
      text: "Username",
      //formatter: employeeFormatter,
      dataField: "username",
      sort: true,
      // headerStyle: (colum, colIndex) => {
      //   return { width: "8%" }
      // },
    },
    {
      dataField: "role",
      text: props.t("Role"),
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", marginLeft:"5px"
      }}),
      // headerStyle: (colum, colIndex) => {
      //   return { width: "12%" }
      // },
    },
    {
      dataField: "employee_details.name",
      text: props.t("Name"),
      sort: true,
      // headerStyle: (colum, colIndex) => {
      //   return { width: "12%" }
      // },
    },
    {
      dataField: "employee_details.mobile",
      text: props.t("Mobile"),
      // sort: true,
      // headerStyle: (colum, colIndex) => {
      //   return { width: "12%" }
      // },
    },
    {
      // dataField: "status",
      text: props.t("Status"),
      formatter: showStatusFormatter,
      sort: true,
      // headerStyle: (colum, colIndex) => {
      //   return { width: "%" }
      // },
    },
    {
      text: props.t("Password"),
      formatter: PasswordFormatter,
      sort: false,
      hidden: (role==="Admin"||role==="Manager"||role==="Owner") ? false : true
      // headerStyle: (colum, colIndex) => {
      //   return { width: "2%" }
      // },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      // headerStyle: (colum, colIndex) => {
      //   return { width: "4%" }
      // },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem1={props.t("Add User")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                 
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                 
                          <label className="form-label">
                            {props.t("Employee *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            name="employee_id"
                            placeholder={props.t("--Select Employee--")}
                            errorMessage={props.t("Select Employee.")}
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">
                              {props.t("--Select Employee--")}
                            </option>
                            {allEmployees &&
                              allEmployees.map(emp => (
                                <option value={emp._id} key={emp._id}>
                                  {emp.name}
                                </option>
                              ))}
                          </AvField>
                    
                      </Col>
                      <Col lg={6}>
                          <label className="form-label">
                            {props.t("Role *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            name="role"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Role")}
                          >
                            <option value="">{props.t("Select Role")}</option>

                            {/* {allDesignations && 
                                allDesignations.map((role)=>(
                                  <option value={role.name} key={role._id}>{role.name}</option>
                                ))} */}
                            <option value="Accountant">
                              {props.t("Accountant")}
                            </option>
                            <option value="Cafeteria">
                              {props.t("Cafeteria")}
                            </option>
                            <option value="Cashier">
                              {props.t("Cashier")}
                            </option>
                            <option value="Kitchen">
                              {props.t("Kitchen")}
                            </option>
                            <option value="Manager">
                              {props.t("Manager")}
                            </option>
                            <option value="Store Keeper">
                              {props.t("Store Keeper")}
                            </option>
                            <option value="Super User">
                              {props.t("Super User")}
                            </option>
                            <option value="Assistant">
                              {props.t("Assistant")}
                            </option>
                            <option value="Owner">
                              {props.t("Owner")}
                            </option>
                          </AvField>
                      </Col>
                    </Row>
      
                    <Row>
                      <Col lg={6}>
                          <label className="form-label">
                            {props.t("Username *")}
                          </label>
                          <AvField
                            className="form-control"
                            name="username"
                            type="text"
                            placeholder={props.t("Enter Username")}
                            errorMessage={props.t("Username cannot be empty")}
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9a-zA-Z]+$",
                                errorMessage: props.t(
                                  "Cannot use space/special characters."
                                ),
                              },
                            }}
                          />
                      </Col>
                      <Col lg={6}>
                          <label className="form-label">
                            {props.t("Password *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="password"
                            placeholder={props.t("Enter Password")}
                            name="pwd"
                            validate={{
                              required: { value: true },
                              minLength: {
                                value: 5,
                                errorMessage: props.t(
                                  "Minimum 5 characters required."
                                ),
                              },
                            }}
                            errorMessage={props.t("Password cannot be empty.")}
                          />
                      </Col>
                    </Row>
           
                    <Row>
                      <Col lg={4}>
                        <div className="mx-2"></div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                           <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Users")}
                      </Col>

                      <Col lg={6} style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button 
                    style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                    type="submit"
                    className="btn btn-sm btn-secondary"
                    onClick={handleDownload}
                     >
                      <i className="mdi mdi-file-export" /> &nbsp; 
                       {props.t("Export CSV")}
                                </Button> 
                    </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your Existing Users here")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allUsers && allUsers}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{display:"flex", alignItems:"flex-start"}}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>

              {/* Edit Modal */}
          <Modal
                    size="md"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update - ")}
                      {selectedUser && selectedUser.username}
                    </ModalHeader>
                    <ModalBody>
                      {selectedUser && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate} >
                                    <Row>
                                      
                                      <Col lg={12}>
         
                          <label className="form-label">
                            {props.t("Role *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            name="role"
                            value={selectedUser?.role}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Role")}
                          >
                            <option value="">{props.t("Select Role")}</option>

                           
                            <option value="Accountant">
                              {props.t("Accountant")}
                            </option>
                            <option value="Cafeteria">
                              {props.t("Cafeteria")}
                            </option>
                            <option value="Cashier">
                              {props.t("Cashier")}
                            </option>
                            <option value="Kitchen">
                              {props.t("Kitchen")}
                            </option>
                            <option value="Manager">
                              {props.t("Manager")}
                            </option>
                            <option value="Store Keeper">
                              {props.t("Store Keeper")}
                            </option>
                            <option value="Super User">
                              {props.t("Super User")}
                            </option>
                            <option value="Assistant">
                              {props.t("Assistant")}
                            </option>
                            <option value="Owner">
                              {props.t("Owner")}
                            </option>
                          </AvField>
           
                      </Col>
                               




                                    </Row>

                                    
                                   
                                   

                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color=""
                                              style={{width:"auto"}}
                                              className="mr-1 btn btn-primary"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

              {/* Password modal */}
          <Modal
            size="md"
            isOpen={passwordModal}
            toggle={() => setPasswordModal(!passwordModal)}
          >
            <ModalHeader toggle={() => setPasswordModal(!passwordModal)}>
              {props.t("Update Password for - ")}
           <strong>   {selectedUser && selectedUser.username}</strong>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <>
                  <Row>
                    <Col xlg={12}>
                      <Card>
                        <CardBody>
                          <AvForm onValidSubmit={handleValidUpdatePassword} >
                            <Row>
                              <Col lg={12}>
                                <AvField
                                  name="newpwd"
                                  label={props.t("New Password")}
                                  placeholder={props.t("Enter new password")}
                                  type="text"
                                  validate={{
                                    required: { value: true },
                                    // minLength: {
                                    //   value: 5,
                                    //   errorMessage: props.t("Min 5 chars."),
                                    // },
                                    // maxLength: {
                                    //   value: 12,
                                    //   errorMessage: props.t("Max 12 chars."),
                                    // },
                                  }}
                                  errorMessage={props.t("New password cannot be empty")}
                                />
                              </Col>
                             

                            </Row>





                            <Row>
                              <Col lg={12}>
                                <hr />
                                <FormGroup className="mb-0 text-center">
                                  <div>
                                    <Button
                                      type="submit"
                                      color=""
                                      style={{ width: "auto" }}
                                      className="mr-1 btn btn-primary"
                                    >
                                      {props.t("Update")}
                                    </Button>{" "}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </AvForm>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Users)))
