import {
    postSubmitForm,
    postSubmitForm_withformdata,
} from "../../../helpers/backend_helper"
import { showWarning } from "../../../helpers/show_notificaton";
import axios from "axios";

async function CheckTableAvailability(startTime, bookingEndTime, selected_game, selectedTable, token) {



    // Convert start_time to ISO date string
    // const isoStartDate = new Date(startTime).toISOString().split('T')[0];

    // Convert end_time to ISO date string
    // const isoEndDate = new Date(bookingEndTime).toISOString().split('T')[0];



    // console.log(payload, "payload")



    // let res = await axios.post(url, payload, {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //     },
    // }).then(response => {

    //     if (response.data.status == 1) {
    //         console.log(response.data.data, "tables data")
    //         let tableArray = response.data.data.filter(res => res.table_number === selectedTable[0].label);
    //         if (tableArray.length > 0) {
    //             showWarning("Table is Available", "Success");
    //             return "yes"
    //         } else {
    //             showWarning("Table is occupied", "Warning");
    //             return "no"
    //         }
    //     } else {
    //         // console.log(response,"hello")
    //         showWarning(response.data.message, "Warning");
    //         return "no"
    //     }

    // }).catch(err => showWarning(err.message, "Warning"));

    // console.log(res,"res yes no")

    let payload = {
        start_time: startTime,
        end_time: bookingEndTime,
        game_id: selected_game.id
    }

    let url = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let response = postSubmitForm(url, payload)
    if (response.status == 1) {
        // console.log(response.data, "tables data")
        let tableArray = response.data.filter(res => res.table_number === selectedTable[0].label);
        if (tableArray.length > 0) {
            showWarning("Table is Available", "Success");
            return "yes"
        } else {
            showWarning("Table is occupied", "Warning");
            return "no"
        }


    } else {
        // console.log(response, "hello")
        showWarning(response.message, "Warning");
        return "no"
    }

}

export default CheckTableAvailability;