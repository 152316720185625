import React, { useState, useEffect, useRef } from "react"
import exportFromJSON from "export-from-json"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Badge,
  Label,
} from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import { AvField, AvForm } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import BackgroundImage from "../../assets/images/Background.png"
import Select from "react-select"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import moment from "moment"

const ApplyLoanForAll = (props) => {

  const refContainer = useRef(null);
  const [loanAmount, setLoanAmount] = useState(0);
  const [tenureDuration, setTenureDuration] = useState(0);

  const [allEmployees, setAllEmployees] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [apicall, setApicall] = useState(false);
  const [allApplied_Loan, setAllApplied_Loan] = useState([])
  const [status, setStatus] = useState("");
  const { SearchBar } = Search

  useEffect(() => {
    loadAllEmployees();
    loadAll_Appplied_Loan();
  }, [])
  const loadAllEmployees = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall"
    let response = await postSubmitForm(url, "")

    if (response.status === 1) {
      response.data.map(employee => {
        employee.label = employee.name,
          employee.value = employee.name
      })
      const active = response.data.filter(employee => employee.is_active === true)
      setAllEmployees(active)

    } else {
      showNotification(response.message, "Error")
    }
  }
  const handleValidSubmit = async (e, v) => {
    setApicall(true);
    try {
      const obj = {
        employee_id: selectedEmployee?._id,
        loan_apply_date: new Date(),
        loan_amount: v.loan_amount,
        emi_amount: v.emi_amount,
        tenure: v.tenure,
        loan_reason: v.loan_reason,
      }
      // console.log(obj,'objobjobj')
      // return
      let url = process.env.REACT_APP_BASEURL + "loans/insert"
      let response = await postSubmitForm(url, obj)
      if (response.status === 1) {
        refContainer.current.reset()
        setSelectedEmployee()
        setLoanAmount(0);
        setTenureDuration(0)
        loadAll_Appplied_Loan()
        showNotification(response.message, "Success")
      } else {
        showWarning(response.message, "warning")
      }
    } catch (error) {
      showNotification(error, "Error")
    }
  }
  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // filter: "brightness(70%) blur(5px)",
  }
  // ___________________________________________-
  const loadAll_Appplied_Loan = async () => {
    let url = process.env.REACT_APP_BASEURL
    try {
      const response = await postSubmitForm(url + "loans/getall_appliedBy");
      if(response.status===1){
        setAllApplied_Loan(response.data);
      }else{
        showNotification(response.message,"Error")
      }
    } catch (error) {
      showNotification(error,"Error")
    }
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Name/Code"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      formatter: (cell, row) => {
        return row.employee_name + "" + "(" + row.employee_code + ")"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "loan_amount",
      text: props.t("Loan Amount"),
      formatter: (cell, row) => cell,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Applied Date",
      dataField: props.t("loan_apply_date"),
      formatter: (cell, row) => {
        const applyDate = row.loan_apply_date;
        const formattedDate = moment(applyDate).format('YYYY-MM-DD');
        return formattedDate;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: "Approved Date",
      dataField: props.t("loan_approval_date"),
      formatter: (cell, row) => {

        const approveDate = row.loan_approval_date ? row.loan_approval_date : null;
        if (approveDate === null) {
          return;
        }
        const formattedDate = moment(approveDate).format('YYYY-MM-DD');
        return formattedDate;
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "emi_amount",
      text: props.t("EMI"),
      formatter: (cell, row) => cell,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "tenure",
      text: props.t("Tenure"),
      sort: true,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "loan_reason",
      text: props.t("Reason"),
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "remaining_loan",
      text: props.t("Remaining Loan   "),
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "remaining_tenure",
      text: props.t("Remaining Tenure   "),
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Status"),
      dataField: "loan_status",
      formatter: (cell, row) => {
        return (
          <>
            <Badge className={`fs-10 badge-soft-${row.loan_status === "Approved" ? "success" : row.loan_status === "Pending" ? "warning" : row.loan_status === "Rejected" ? "danger" : null}`}>
              {row.loan_status}
            </Badge> <br />
            {row.remark && "Remark's : " + row.remark}
          </>
        )
      },
      filter: selectFilter({
        options: [
          { value: "Approved", label: "Approved" },
          { value: "Pending", label: "Pending" },
          { value: "Rejected", label: "Rejected" },
        ],
        defaultValue: "Pending",
        placeholder: "ALL",
        onFilter: (value) => {
          setStatus(value)
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },

    },
  ]

  const handleDownload = async () => {
    const fileName = "All Applied Loans";
    const exportType = "xls";

    let data_to_export = allApplied_Loan;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Employye_Name: v.employee_name,
          Employye_Code: v.employee_code,
          LoanAmount: v.loan_amount,
          Applied_Date: moment(v.loan_apply_date).format("YYYY-MM-DD"),
          Approval_Date: v.loan_approval_date ?  moment(v.loan_approval_date).format("YYYY-MM-DD"): "N/A",
          EMI_Amount: v.emi_amount,
          Tenure: v.tenure,
          Remaining_Tenure: v.remaining_tenure,
          Loan_Status: v.loan_status,

        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Loan Application"
            breadcrumbItem="Loan Application"
          />

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <div className="mb-3">
                    <AvForm
                      onValidSubmit={(apicall &&
                        selectedEmployee === "" || selectedEmployee === null)
                        ? showWarning("Please Select Employee", "warning")
                        : handleValidSubmit
                      }
                      ref={refContainer}
                    >
                      <Row>
                        <Col lg={4}>
                          <Label>{props.t("Select Employee*")}</Label>
                          <Select
                            value={selectedEmployee || []}
                            onChange={selected => {
                              setSelectedEmployee(selected)
                            }}
                            options={allEmployees}
                            closeMenuOnSelect={true}
                            required
                          />
                        </Col>

                        <Col lg={4}>
                          <AvField
                            type="number"
                            name="loan_amount"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9a-zA-Z]+$",
                                errorMessage: props.t(
                                  "Cannot use space/special characters."
                                ),
                              },
                            }}
                            label="Loan Amount"
                            placeholder={props.t("Enter Loan Amount")}
                            onChange={e => setLoanAmount(e.target.value)}
                            errorMessage="Loan Amount is required"
                          />
                        </Col>

                        <Col lg={4}>
                          <AvField
                            type="number"
                            name="tenure"
                            label="Tenure"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9a-zA-Z]+$",
                                errorMessage: props.t(
                                  "Cannot use space/special characters."
                                ),
                              },
                            }}
                            placeholder={props.t("Enter Tenure")}
                            onChange={e => setTenureDuration(e.target.value)}
                            errorMessage="Loan Amount is required"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <AvField
                            type="number"
                            name="emi_amount"
                            label="EMI Amount"
                            placeholder={props.t("Enter EMI Amount")}
                            disabled="true"
                            value={(loanAmount / tenureDuration).toFixed(2)}
                            validate={{
                              required: { value: true },
                            }}
                            errorMessage="EMI Amount is required"
                          />
                        </Col>

                        <Col lg={4}>
                          <AvField
                            type="textarea"
                            placeholder={props.t("Enter Loan Reason")}
                            name="loan_reason"
                            label="Reason"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={4}>
                          <div className="mx-2"></div>
                        </Col>
                        <Col lg={4}>
                          <div
                            className="mt-4 mx-4"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              type="submit"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "100px",
                                marginTop: "0rem",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              {props.t("Submit")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-4 mx-4"></div>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br />
          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle className="fs-5" >View all applied loan application here!</CardTitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns} y
                    data={allApplied_Loan}
                    search
                    isAddUserList={true}
                    customPageSize={10}
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            {/* <label className="form-label">
                              {("Search By Employee Name")}
                            </label> */}
                            <Col lg={6} style={{ display: "flex", }}>
                              <SearchBar
                                {...propst.searchProps}
                              />
                            </Col>
                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}
export default withRouter(connect(null, {})(withTranslation()(ApplyLoanForAll)))
