import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"
import classname from "classnames"

//i18n
import { useSSR, withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [bookings, setBookings] = useState(false)
  const [reservation, setReservation] = useState(false)
  const [salary, setSalary] = useState(false)
  const [attendance, setAttendance] = useState(false)
  const [attendanceMenu, setAttendanceMenu] = useState(false)
  const [game_Master, setGame_Master] = useState(false)
  const [customer_master, setCustomer_Master] = useState(false)
  const [orders, setOrders] = useState(false)
  const [items, setItems] = useState(false)
  const [packages, setPackages] = useState(false)
  const [Inventory, setInventory] = useState(false)
  const [master_categories, setMaster_categories] = useState(false)
  const [master_supplier, setMaster_supplier] = useState(false)
  const [master_Inventory, setMaster_Inventory] = useState(false)
  const [shop, setShop] = useState(false)
  const [component, setcomponent] = useState(false)
  const [updateBookings, setUpdateBookings] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [ifOwner, setIfOwner] = useState("")
  const [ifAdmin, setIfAdmin] = useState("")
  const [overtime, setOvertime] = useState(false)
  const [leave, setLeave] = useState(false)
  const [purchaseOrders, setPurchaseOrders] = useState(false)
  const [itemMaster, setItemMaster] = useState(false)
  const [closure, setClosure] = useState(false)
  const [wallet, setWallet] = useState(false)
  const [expenseMaster, setExpenseMaster] = useState(false)
  const [dashboardRoute, setDashboardRoute] = useState("/dashboard")

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  useEffect(() => {
      setIfOwner((localStorage.getItem("role") === "Owner" ? true : false)),
      setIfAdmin((localStorage.getItem("role") === "Admin" ? true : false)),
      setDashboardRoute(
        (localStorage.getItem("role") === "Owner" || localStorage.getItem("role") === "Manager" || localStorage.getItem("role") === "Admin" )
          ? "/dashboard"
          : "/employee-dashboard"
      )
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  const role = localStorage.getItem("role")

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    to={dashboardRoute}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-home-circle me-2"></i>

                    {props.t("Home")}
                  </Link>
                </li>

                {ifOwner === false &&
                localStorage.getItem("role") === "Cashier" ? (
                  <>
                    {/* Attendance View */}
                    <li className="nav-item dropdown">
                      <Link
                        // to="/attendance"
                        to="/view-emp-attendance"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fa fa-user-circle me-2"></i>

                        {props.t("Attendence")}
                      </Link>
                    </li>
                    {/* Add Customer  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/add-customer"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-user-circle me-2"></i>

                        {props.t("Add Customer")}
                      </Link>
                    </li>

                    {/* Bookings */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setBookings(!bookings)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Booking")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: bookings,
                        })}
                      >
                        <Link to="/add-reservation" className="dropdown-item">
                          {props.t("Add Reservation")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setUpdateBookings(!updateBookings)
                            }}
                          >
                            {props.t("Update Booking")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: updateBookings,
                            })}
                          >
                            <Link to="/add-games" className="dropdown-item">
                              {props.t("Add Games")}
                            </Link>

                            <Link to="/add-items" className="dropdown-item">
                              {props.t("Add Items")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/check-out" className="dropdown-item">
                          {props.t("Check Out")}
                        </Link>

                        <Link to="/all-booking" className="dropdown-item">
                          {props.t("ALL Bookings")}
                        </Link>
                      </div>
                    </li>

                    {/* sales */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setShop(!shop)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cart me-2"></i>
                        {props.t("Sales")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: shop })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItems(!items)
                            }}
                          >
                            {props.t("Items")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: items,
                            })}
                          >
                            <Link to="/sell-items" className="dropdown-item">
                              {props.t("Sell Items")}
                            </Link>

                            <Link
                              to="/item-sold-only"
                              className="dropdown-item"
                            >
                              {props.t("All Sold Items ")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setPackages(!packages)
                            }}
                          >
                            {props.t("Packages")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: packages,
                            })}
                          >
                            <Link to="/sell-packages" className="dropdown-item">
                              {props.t("Sell Packages")}
                            </Link>

                            <Link
                              to="/all-sold-packages"
                              className="dropdown-item"
                            >
                              {props.t("All Sold Packages")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setWallet(!wallet)
                            }}
                          >
                            {props.t("Wallet")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: wallet,
                            })}
                          >
                            <Link
                              to="/wallet-transactions"
                              className="dropdown-item"
                            >
                              {props.t("Wallet Transaction")}
                            </Link>
                            <Link
                              to="/wallet-history"
                              className="dropdown-item"
                            >
                              {props.t("Wallet History")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Closure */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setClosure(!closure)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cart me-2"></i>
                        {props.t("Closure")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: closure,
                        })}
                      >
                        <Link to="/daily_closure" className="dropdown-item">
                          {props.t("Daily Closure")}
                        </Link>

                        <Link to="/shift_closure" className="dropdown-item">
                          {props.t("Shift Closure")}
                        </Link>
                      </div>
                    </li>

                    {/* Apply */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setAttendance(!attendance)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Apply")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        {/* Apply Leave */}
                        <Link to="/apply-leave-owner" className="dropdown-item">
                          {props.t("Leave")}
                        </Link>
                        {/* Apply Overtime */}

                        <Link to="/apply-loan-owner" className="dropdown-item">
                          {props.t("Loan")}
                        </Link>
                        {/* Apply_Overtime */}
                        <Link
                          to="/apply-overtime-owner"
                          className="dropdown-item"
                        >
                          {props.t("Overtime")}
                        </Link>
                      </div>
                    </li>
                  </>
                ) : null}

                {ifOwner === false &&
                localStorage.getItem("role") === "Accountant" ? (
                  <>
                    {/* Sales  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setShop(!shop)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cart me-2"></i>
                        {props.t("Bookings & Sales")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: shop })}
                      >
                        <Link to="/all-booking" className="dropdown-item">
                          {props.t("ALL Bookings")}
                        </Link>
                        <Link to="/item-sold-only" className="dropdown-item">
                          {props.t("All Sold Items ")}
                        </Link>
                        <Link to="/all-sold-packages" className="dropdown-item">
                          {props.t("All Sold Packages")}
                        </Link>
                        <Link to="/wallet-history" className="dropdown-item">
                          {props.t("Wallet History")}
                        </Link>
                      </div>
                    </li>
                    {/* Inventory */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setInventory(!Inventory)
                        }}
                        className="nav-link dropdown-togglez arrow-none "
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Inventory")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: Inventory,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setPurchaseOrders(!purchaseOrders)
                            }}
                          >
                            {props.t("Purchase Orders")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: purchaseOrders,
                            })}
                          >
                            <Link to="/generate_po" className="dropdown-item">
                              {props.t("Generate")}
                            </Link>

                            <Link
                              to="/all_purchase_order"
                              className="dropdown-item"
                            >
                              {props.t("View All")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/view_inventory" className="dropdown-item">
                          {props.t("View Inventory")}
                        </Link>
                        <Link to="/used_items" className="dropdown-item">
                          {props.t("View Used Items")}
                        </Link>
                      </div>
                    </li>
                    {/* Attendance  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setAttendance(!attendance)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Attendance")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setAttendanceMenu(!attendanceMenu)
                            }}
                          >
                            {props.t("Attendance")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: attendanceMenu,
                            })}
                          >
                            {/* <Link to="/mark-attendance" className="dropdown-item">
                            {props.t("Insert Attendance")}
                          </Link> */}

                            <Link
                              to="/edit_attendance"
                              className="dropdown-item"
                            >
                              {props.t("View/Edit Attendance")}
                            </Link>

                            <Link
                              to="/view-attendance"
                              className="dropdown-item"
                            >
                              {props.t("View Logs")}
                            </Link>
                          </div>
                        </div>
                        <Link to="/apply-leave-owner" className="dropdown-item">
                          {props.t("Apply Leave")}
                        </Link>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setOvertime(!overtime)
                            }}
                          >
                            {props.t("Overtime")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: overtime,
                            })}
                          >
                            <Link
                              to="/apply-overtime-owner"
                              className="dropdown-item "
                            >
                              {props.t("Apply Overtime")}
                            </Link>
                            <Link
                              to="/approve_overtime"
                              className="dropdown-item "
                            >
                              {props.t("Approve Overtime")}
                            </Link>
                            {/* <Link to="/mark-attendance" className="dropdown-item">
                            {props.t("Insert Attendance")}
                          </Link> */}
                          </div>
                        </div>

                        <Link to="/roster" className="dropdown-item">
                          {props.t("Roster")}
                        </Link>
                      </div>
                    </li>
                    {/* Salaray  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-togglez arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setSalary(!salary)
                        }}
                      >
                        <i className="bx bx-money me-2"></i>
                        {props.t("Salary")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: salary })}
                      >
                        <Link to="/generate-salary" className="dropdown-item">
                          {props.t("Generate salary")}
                        </Link>
                        <Link to="/view-salary" className="dropdown-item">
                          {props.t("View salary")}
                        </Link>
                        <Link to="/apply-loan-owner" className="dropdown-item">
                          {props.t("Apply Loan")}
                        </Link>
                        <Link to="/arrear-deduction" className="dropdown-item">
                          {props.t("Arrear/Deduction")}
                        </Link>
                        <Link to="/completed_loan" className="dropdown-item">
                          {props.t("Completed Loan")}
                        </Link>
                      </div>
                    </li>
                    {/* Closing  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setClosure(!closure)
                        }}
                      >
                        <i className="mdi mdi-cart-plus me-2"></i>
                        {props.t("Closing")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: closure,
                        })}
                      >
                        <Link to="/daily_closure" className="dropdown-item">
                          {props.t("Daily Closure")}
                        </Link>
                      </div>
                    </li>
                    {/* Master Data */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcomponent(!component)
                        }}
                      >
                        <i className="bx bx-collection me-2"></i>
                        {props.t("Master Data")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: component,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setform(!form)
                            }}
                          >
                            {props.t("Employees")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: form,
                            })}
                          >
                            <Link
                              to="/add_new_employee"
                              className="dropdown-item"
                            >
                              {props.t("Add New Employee")}
                            </Link>
                            <Link to="/view_employee" className="dropdown-item">
                              {props.t("View/Update Employee")}
                            </Link>
                            <Link to="/designation" className="dropdown-item">
                              {props.t("Designation")}
                            </Link>
                            <div className="dropdown">
                              <div
                                className={classname("dropdown-menu", {
                                  show: table,
                                })}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <Link to="/holiday_master" className="dropdown-item">
                          {props.t("Holiday Master")}
                        </Link>

                        <Link to="/shift" className="dropdown-item">
                          {props.t("Shift")}
                        </Link>
                      </div>
                    </li>
                  </>
                ) : null}

                {ifOwner === false &&
                (role === "Kitchen" || role === "Cafeteria") ? (
                  <>
                  
                    {/* Attendance View */}
                    <li className="nav-item dropdown">
                      <Link
                        // to="/attendance"
                        to="/view-emp-attendance"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fa fa-user-circle me-2"></i>

                        {props.t("Attendence")}
                      </Link>
                    </li>
                    {/* Apply Leave */}
                    {/* <li className="nav-item dropdown">
                      <Link
                        to="/apply-leave"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fa fa-child me-2"></i>

                        {props.t("Apply Leave")}
                      </Link>
                    </li> */}
                    {/* Loan */}
                    {/* <li className="nav-item dropdown">
                      <Link
                        to="/apply-loan"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fas fa-hand-holding-usd me-2"></i>

                        {props.t("Apply Loan")}
                      </Link>
                    </li> */}
                    {/* Inventory */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setInventory(!Inventory)
                        }}
                        className="nav-link dropdown-togglez arrow-none "
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Inventory")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: Inventory,
                        })}
                      >
                        <Link
                          to="/employee-kitchen-order"
                          className="dropdown-item"
                        >
                          {props.t("Kitchen Withdraw")}
                        </Link>
                      </div>
                    </li>
                    {/* Sales */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/sell-items"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-cart me-2"></i>

                        {props.t("Sell Items")}
                      </Link>
                    </li>
                    {/* Orders  */}
                    <li className="nav-item dropdown">
                      <Link
                        to={
                          role === "Kitchen"
                            ? "/new-orders?type=" + "Kitchen"
                            : "/new-orders?type=" + "Cafe"
                        }
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-receipt me-2"></i>

                        {props.t("Orders")}
                      </Link>
                    </li>
                    {/* Apply  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setAttendance(!attendance)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Apply")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        {/* Apply Overtime */}
                        <Link to="/apply-overtime" className="dropdown-item">
                          {props.t("Overtime")}
                        </Link>
                        {/* Apply Leave */}

                        <Link to="/apply-leave" className="dropdown-item">
                          {props.t("Leave")}
                        </Link>
                        {/* Apply Loan */}
                        <Link to="/apply-loan" className="dropdown-item">
                          {props.t("Loan")}
                        </Link>
                      </div>
                    </li>
                  </>
                ) : null}

                {ifOwner === false &&
                localStorage.getItem("role") === "Store Keeper" ? (
                  <>
                    {/* Add Customer  */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/add-customer"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="bx bx-user-circle me-2"></i>

                        {props.t("Add Customer")}
                      </Link>
                    </li>

                    {/* Attendance View */}
                    <li className="nav-item dropdown">
                      <Link
                        // to="/attendance"
                        to="/view-emp-attendance"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        <i className="fa fa-user-circle me-2"></i>

                        {props.t("Attendence")}
                      </Link>
                    </li>

                    {/* Apply */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setAttendance(!attendance)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Apply")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        {/* <Link to="/roster" className="dropdown-item">
                        {props.t("Roster")}
                      </Link> */}

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setOvertime(!overtime)
                            }}
                          >
                            {props.t("Overtime")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: overtime,
                            })}
                          >
                            <Link
                              to="/apply-overtime-owner"
                              className="dropdown-item"
                            >
                              {props.t("Apply")}
                            </Link>

                            {/* <Link to="/approve_overtime" className="dropdown-item">
                            {props.t("Approve")}
                          </Link> */}
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setLeave(!leave)
                            }}
                          >
                            {props.t("Leave")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: leave,
                            })}
                          >
                            <Link
                              to="/apply-leave-owner"
                              className="dropdown-item"
                            >
                              {props.t("Apply")}
                            </Link>

                            {/* <Link to="/approve_leave" className="dropdown-item">
                            {props.t("Approve")}
                          </Link> */}
                          </div>
                        </div>
                        <Link to="/apply-loan-owner" className="dropdown-item">
                          {props.t("Apply Loan")}
                        </Link>
                      </div>
                    </li>
                    {/* MasterData */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcomponent(!component)
                        }}
                      >
                        <i className="bx bx-collection me-2"></i>
                        {props.t("Master Data")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: component,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setMaster_supplier(!master_supplier)
                            }}
                          >
                            {props.t("Supplier")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_supplier,
                            })}
                          >
                            <Link to="/add_supplier" className="dropdown-item">
                              {props.t("Add Supplier")}
                            </Link>
                            <Link
                              to="/update_supplier"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Supplier")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItemMaster(!itemMaster)
                            }}
                          >
                            {props.t("Item")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: itemMaster,
                            })}
                          >
                            <Link to="/add_item" className="dropdown-item">
                              {props.t("Add/Update Item")}
                            </Link>
                            <Link
                              to="/unit-of-measurement"
                              className="dropdown-item"
                            >
                              {props.t("Unit Of Measurement")}
                            </Link>

                            <Link to="/add_category" className="dropdown-item">
                              {props.t("Add Item Category")}
                            </Link>
                            <Link
                              to="/update_category"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Category")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* Inventory */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setInventory(!Inventory)
                        }}
                        className="nav-link dropdown-togglez arrow-none "
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Inventory")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: Inventory,
                        })}
                      >
                        <Link
                          to="/all_purchase_order"
                          className="dropdown-item"
                        >
                          {props.t("All Purchase Order")}
                        </Link>

                        <Link to="/view_inventory" className="dropdown-item">
                          {props.t("View Inventory")}
                        </Link>

                        <Link to="/expired_items" className="dropdown-item">
                          {props.t("Expired Items")}
                        </Link>
                      </div>
                    </li>
                  </>
                ) : null}

                {ifOwner === false &&
                (localStorage.getItem("role") === "Manager"||localStorage.getItem("role") === "Super User") ? (
                  <>
                  {/* Bookings */}
                  <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setBookings(!bookings)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Booking")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: bookings,
                        })}
                      >
                        <Link to="/add-reservation" className="dropdown-item">
                          {props.t("Add Reservation")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setUpdateBookings(!updateBookings)
                            }}
                          >
                            {props.t("Update Booking")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: updateBookings,
                            })}
                          >
                            <Link to="/add-games" className="dropdown-item">
                              {props.t("Add Games")}
                            </Link>

                            <Link to="/add-items" className="dropdown-item">
                              {props.t("Add Items")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/check-out" className="dropdown-item">
                          {props.t("Check Out")}
                        </Link>
                        {localStorage.getItem("role")==="Super User" && (
                           <Link to="/refund" className="dropdown-item">
                           {props.t("Refund")}
                         </Link>
                        )}
                       
                        <Link to="/all-booking" className="dropdown-item">
                          {props.t("ALL Bookings")}
                        </Link>
                      </div>
                    </li>
                    {/* Sales */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setShop(!shop)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cart me-2"></i>
                        {props.t("Sales")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: shop })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItems(!items)
                            }}
                          >
                            {props.t("Items")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: items,
                            })}
                          >
                            <Link to="/sell-items" className="dropdown-item">
                              {props.t("Sell Items")}
                            </Link>

                            <Link
                              to="/item-sold-only"
                              className="dropdown-item"
                            >
                              {props.t("All Sold Items ")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setPackages(!packages)
                            }}
                          >
                            {props.t("Packages")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: packages,
                            })}
                          >
                            <Link to="/sell-packages" className="dropdown-item">
                              {props.t("Sell Packages")}
                            </Link>

                            <Link
                              to="/all-sold-packages"
                              className="dropdown-item"
                            >
                              {props.t("All Sold Packages")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* Attendance */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setAttendance(!attendance)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Attendance")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setAttendanceMenu(!attendanceMenu)
                            }}
                          >
                            {props.t("Attendance")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: attendanceMenu,
                            })}
                          >
                            {/* <Link to="/mark-attendance" className="dropdown-item">
                              {props.t("Insert Attendance")}
                            </Link> */}

                            <Link
                              to="/edit_attendance"
                              className="dropdown-item"
                            >
                              {props.t("View/Edit Attendance")}
                            </Link>

                            <Link
                              to="/view-attendance"
                              className="dropdown-item"
                            >
                              {props.t("View Logs")}
                            </Link>
                          </div>
                        </div>
                        <Link to="/roster" className="dropdown-item">
                          {props.t("Roster")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setOvertime(!overtime)
                            }}
                          >
                            {props.t("Overtime")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: overtime,
                            })}
                          >
                            <Link
                              to="/apply-overtime-owner"
                              className="dropdown-item"
                            >
                              {props.t("Apply")}
                            </Link>

                            <Link
                              to="/approve_overtime"
                              className="dropdown-item"
                            >
                              {props.t("Approve")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setLeave(!leave)
                            }}
                          >
                            {props.t("Leave")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: leave,
                            })}
                          >
                            <Link
                              to="/apply-leave-owner"
                              className="dropdown-item"
                            >
                              {props.t("Apply")}
                            </Link>

                            <Link to="/approve_leave" className="dropdown-item">
                              {props.t("Approve")}
                            </Link>
                          </div>
                        </div>

                        {/* <Link to="/overtime_calculation" className="dropdown-item disabled">
                      {props.t("Overtime calculation")}
                    </Link> */}
                      </div>
                    </li>
                    {/* Salary */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-togglez arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setSalary(!salary)
                        }}
                      >
                        <i className="bx bx-money me-2"></i>
                        {props.t("Salary")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: salary })}
                      >
                        {/* <Link to="/generate-salary" className="dropdown-item">
                        {props.t("Generate salary")}
                      </Link> */}
                        <Link to="/view-salary" className="dropdown-item">
                          {props.t("View salary")}
                        </Link>
                        <Link to="/apply-loan-owner" className="dropdown-item">
                          {props.t("Apply Loan")}
                        </Link>
                        <Link to="/loan" className="dropdown-item">
                          {props.t("Approve Loan")}
                        </Link>
                        <Link to="/completed_loan" className="dropdown-item">
                          {props.t("Completed Loan")}
                        </Link>
                      </div>
                    </li>
                    {/* Inventory */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setInventory(!Inventory)
                        }}
                        className="nav-link dropdown-togglez arrow-none "
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Inventory")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: Inventory,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setPurchaseOrders(!purchaseOrders)
                            }}
                          >
                            {props.t("Purchase Orders")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: purchaseOrders,
                            })}
                          >
                            <Link to="/generate_po" className="dropdown-item">
                              {props.t("Generate")}
                            </Link>

                            <Link
                              to="/all_purchase_order"
                              className="dropdown-item"
                            >
                              {props.t("View All")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setOrders(!orders)
                            }}
                          >
                            {props.t("Internal Uses")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: orders,
                            })}
                          >
                            <Link
                              to="/kitchen_withdraw"
                              className="dropdown-item"
                            >
                              {props.t("Kitchen Withdraw")}
                            </Link>
                            <Link
                              to="/accessories-order"
                              className="dropdown-item"
                            >
                              {props.t("Accessories Withdraw")}
                            </Link>
                            <Link to="/used_items" className="dropdown-item">
                              {props.t("View Used Items")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/view_inventory" className="dropdown-item">
                          {props.t("View Inventory")}
                        </Link>
                        <Link to="/expired_items" className="dropdown-item">
                          {props.t("Expired Items")}
                        </Link>
                      </div>
                    </li>
                    {/* MasterData */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcomponent(!component)
                        }}
                      >
                        <i className="bx bx-collection me-2"></i>
                        {props.t("Master Data")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: component,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setform(!form)
                            }}
                          >
                            {props.t("Employees")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: form,
                            })}
                          >
                            <Link
                              to="/add_new_employee"
                              className="dropdown-item"
                            >
                              {props.t("Add New Employee")}
                            </Link>
                            <Link to="/view_employee" className="dropdown-item">
                              {props.t("View/Update Employee")}
                            </Link>
                            <Link to="/designation" className="dropdown-item">
                              {props.t("Designation")}
                            </Link>
                            <div className="dropdown">
                              <div
                                className={classname("dropdown-menu", {
                                  show: table,
                                })}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <Link to="/users" className="dropdown-item">
                          {props.t("Users")}
                        </Link>
                        <Link to="/holiday_master" className="dropdown-item">
                          {props.t("Holiday Master")}
                        </Link>

                        <Link to="/shift" className="dropdown-item">
                          {props.t("Shift")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setMaster_supplier(!master_supplier)
                            }}
                          >
                            {props.t("Supplier")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_supplier,
                            })}
                          >
                            <Link to="/add_supplier" className="dropdown-item">
                              {props.t("Add Supplier")}
                            </Link>
                            <Link
                              to="/update_supplier"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Supplier")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItemMaster(!itemMaster)
                            }}
                          >
                            {props.t("Item")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: itemMaster,
                            })}
                          >
                            <Link to="/add_item" className="dropdown-item">
                              {props.t("Add/Update Item")}
                            </Link>
                            <Link
                              to="/unit-of-measurement"
                              className="dropdown-item"
                            >
                              {props.t("Unit Of Measurement")}
                            </Link>

                            <Link to="/add_category" className="dropdown-item">
                              {props.t("Add Item Category")}
                            </Link>
                            <Link
                              to="/update_category"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Category")}
                            </Link>
                          </div>
                        </div>

                        {/* customer MAster */}

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setCustomer_Master(!customer_master)
                            }}
                          >
                            {props.t("Customer")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: customer_master,
                            })}
                          >
                            <Link
                              to="/add-customer"
                              className="dropdown-item dropdown-toggle arrow-none"
                            >
                              {props.t("Add/Update Customer")}
                            </Link>

                            <Link
                              to="/wallet-history"
                              className="dropdown-item"
                            >
                              {props.t("Wallet History")}
                            </Link>

                            <Link
                              to="/wallet-transactions"
                              className="dropdown-item"
                            >
                              {props.t("Wallet Transaction")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setGame_Master(!game_Master)
                            }}
                          >
                            {props.t("Games")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: game_Master,
                            })}
                          >
                            <Link
                              to="/add_games"
                              className="dropdown-item dropdown-toggle arrow-none"
                            >
                              {props.t("Add/Update Game")}
                            </Link>

                            <Link
                              to="/add_game_tables"
                              className="dropdown-item"
                            >
                              {props.t("Game Tables")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setExpenseMaster(!expenseMaster)
                            }}
                          >
                            {props.t("Expenses")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: expenseMaster,
                            })}
                          >
                            <Link to="/add_expense" className="dropdown-item">
                              {props.t("Add Expense")}
                            </Link>
                            <Link to="/add_source" className="dropdown-item">
                              {props.t("Add Source")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setClosure(!closure)
                            }}
                          >
                            {props.t("Closure")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: closure,
                            })}
                          >
                            <Link to="/daily_closure" className="dropdown-item">
                              {props.t("Daily Closure")}
                            </Link>
                            <Link to="/shift_closure" className="dropdown-item">
                              {props.t("Shift Closure")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/coupons" className="dropdown-item">
                          {props.t("Coupons")}
                        </Link>
                        <Link to="/add_packages" className="dropdown-item">
                          {props.t("Packages")}
                        </Link>
                        <Link to="/payment_methods" className="dropdown-item">
                          {props.t("Payment Methods")}
                        </Link>
                      </div>
                    </li>
                  </>
                ) : null}
                {/* ------------------------------------------------------------------------------------ */}

                {ifOwner && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setBookings(!bookings)
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Booking")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: bookings })}
                    >
                      <Link to="/add-reservation" className="dropdown-item">
                        {props.t("Add Reservation")}
                      </Link>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setUpdateBookings(!updateBookings)
                          }}
                        >
                          {props.t("Update Booking")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: updateBookings,
                          })}
                        >
                          <Link to="/add-games" className="dropdown-item">
                            {props.t("Add Games")}
                          </Link>

                          <Link to="/add-items" className="dropdown-item">
                            {props.t("Add Items")}
                          </Link>
                        </div>
                      </div>

                      <Link to="/check-out" className="dropdown-item">
                        {props.t("Check Out")}
                      </Link>

                      <Link to="/all-booking" className="dropdown-item">
                        {props.t("ALL Bookings")}
                      </Link>
                    </div>
                  </li>
                )}

                {/* Sales */}
                {ifOwner && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setShop(!shop)
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-cart me-2"></i>
                      {props.t("Sales")} <div className="arrow-down"></div>
                    </Link>
                    <div className={classname("dropdown-menu", { show: shop })}>
                      <Link to="/item-sold-only" className="dropdown-item">
                        {props.t("All Sold Items ")}
                      </Link>
                      <Link to="/all-sold-packages" className="dropdown-item">
                        {props.t("All Sold Packages")}
                      </Link>

                      {/* <div className="dropdown">
                        <Link to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setItems(!items)
                          }}
                        >
                          {props.t("Items")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div className={classname("dropdown-menu", { show: items })}>

                          <Link to="/sell-items" className="dropdown-item">
                            {props.t("Sell Items")}
                          </Link>

                          <Link to="/item-sold-only" className="dropdown-item">
                            {props.t("All Sold Items ")}
                          </Link>
                        </div>

                      </div> */}

                      {/* <div className="dropdown">
                        <Link to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setPackages(!packages)
                          }}
                        >
                          {props.t("Packages")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div className={classname("dropdown-menu", { show: packages })}>

                          <Link to="/sell-packages" className="dropdown-item">
                            {props.t("Sell Packages")}
                          </Link>

                          <Link to="/all-sold-packages" className="dropdown-item">
                            {props.t("All Sold Packages")}
                          </Link>
                        </div>

                      </div> */}
                    </div>
                  </li>
                )}

                {/* Inventory */}
                {ifOwner && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setInventory(!Inventory)
                      }}
                      className="nav-link dropdown-togglez arrow-none "
                    >
                      <i className="bx bx-customize me-2"></i>
                      {props.t("Inventory")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: Inventory,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setPurchaseOrders(!purchaseOrders)
                          }}
                        >
                          {props.t("Purchase Orders")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: purchaseOrders,
                          })}
                        >
                          <Link to="/generate_po" className="dropdown-item">
                            {props.t("Generate")}
                          </Link>

                          <Link
                            to="/all_purchase_order"
                            className="dropdown-item"
                          >
                            {props.t("View All")}
                          </Link>
                        </div>
                      </div>

                      {/* <Link to="/generate_po" className="dropdown-item">
                        {props.t("Generate PO")}
                      </Link>
                      <Link to="/all_purchase_order" className="dropdown-item">
                        {props.t("All Purchase Order")}
                      </Link> */}

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setOrders(!orders)
                          }}
                        >
                          {props.t("Internal Uses")}{" "}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: orders,
                          })}
                        >
                          {/* <Link to="/customer_order" className="dropdown-item">
                            {props.t("Customer Order")}
                          </Link> */}
                          <Link
                            to="/kitchen_withdraw"
                            className="dropdown-item"
                          >
                            {props.t("Kitchen Withdraw")}
                          </Link>
                          <Link
                            to="/accessories-order"
                            className="dropdown-item"
                          >
                            {props.t("Accessories Withdraw")}
                          </Link>
                          <Link to="/used_items" className="dropdown-item">
                            {props.t("View Used Items")}
                          </Link>
                        </div>
                      </div>

                      <Link to="/view_inventory" className="dropdown-item">
                        {props.t("View Inventory")}
                      </Link>
                      <Link to="/expired_items" className="dropdown-item">
                        {props.t("Expired Items")}
                      </Link>
                      {/* <Link to="#" className="dropdown-item">
                            {props.t("Extend Expiry")}
                      </Link> */}
                    </div>
                  </li>
                )}

                {/* Attendance  */}
                {ifOwner && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setAttendance(!attendance)
                      }}
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-user me-2"></i>
                      {props.t("Attendance")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: attendance,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setAttendanceMenu(!attendanceMenu)
                          }}
                        >
                          {props.t("Attendance")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div
                          className={classname("dropdown-menu", {
                            show: attendanceMenu,
                          })}
                        >
                          {/* <Link to="/mark-attendance" className="dropdown-item">
                            {props.t("Insert Attendance")}
                          </Link> */}

                          <Link to="/edit_attendance" className="dropdown-item">
                            {props.t("View/Edit Attendance")}
                          </Link>

                          <Link to="/view-attendance" className="dropdown-item">
                            {props.t("View Logs")}
                          </Link>
                        </div>
                      </div>
                      {/* <Link to="/edit_attendance" className="dropdown-item">
                        {props.t("Edit attendance")}
                      </Link>
                      <Link to="/view-attendance" className="dropdown-item">
                        {props.t("Attendance Logs")}
                      </Link> */}
                      <Link to="/roster" className="dropdown-item">
                        {props.t("Roster")}
                      </Link>

                      {/* <div className="dropdown">
                        <Link to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setOvertime(!overtime)
                          }}
                        >
                          {props.t("Overtime")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div className={classname("dropdown-menu", { show: overtime })}>

                          <Link to="/apply-overtime-owner" className="dropdown-item">
                            {props.t("Apply")}
                          </Link>

                          <Link to="/approve_overtime" className="dropdown-item">
                            {props.t("Approve")}
                          </Link>

                        </div>
                      </div>

                      <div className="dropdown">
                        <Link to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setLeave(!leave)
                          }}
                        >
                          {props.t("Leave")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div className={classname("dropdown-menu", { show: leave })}>

                          <Link to="/apply-leave-owner" className="dropdown-item">
                            {props.t("Apply")}
                          </Link>

                          <Link to="/approve_leave" className="dropdown-item">
                            {props.t("Approve")}
                          </Link>

                        </div>
                      </div> */}

                      {/* <Link to="/approve_overtime" className="dropdown-item ">
                        {props.t("Approve Overtime")}
                      </Link> */}
                      {/* <Link to="/approve_leave" className="dropdown-item ">
                        {props.t("Approve leave")}
                      </Link> */}
                      {/* <Link to="/overtime_calculation" className="dropdown-item disabled">
                      {props.t("Overtime calculation")}
                    </Link> */}
                    </div>
                  </li>
                )}

                {/* salary */}
                {ifOwner && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-togglez arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setSalary(!salary)
                      }}
                    >
                      <i className="bx bx-money me-2"></i>
                      {props.t("Salary")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: salary })}
                    >
                      {/* <Link to="/generate-salary" className="dropdown-item">
                        {props.t("Generate salary")}
                      </Link> */}
                      <Link to="/view-salary" className="dropdown-item">
                        {props.t("View salary")}
                      </Link>
                      <Link to="/arrear-deduction" className="dropdown-item">
                        {props.t("Arrear/Deduction")}
                      </Link>
                      <Link to="/loan" className="dropdown-item">
                        {props.t("Approve Loan")}
                      </Link>
                      <Link to="/completed_loan" className="dropdown-item">
                        {props.t("Completed Loan")}
                      </Link>
                    </div>
                  </li>
                )}

                {/* Masters */}
                {ifOwner && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setcomponent(!component)
                      }}
                    >
                      <i className="bx bx-collection me-2"></i>
                      {props.t("Master Data")}{" "}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: component,
                      })}
                    >
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setform(!form)
                          }}
                        >
                          {props.t("Employees")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", { show: form })}
                        >
                          <Link
                            to="/add_new_employee"
                            className="dropdown-item"
                          >
                            {props.t("Add New Employee")}
                          </Link>
                          <Link to="/view_employee" className="dropdown-item">
                            {props.t("View/Update Employee")}
                          </Link>
                          <Link to="/designation" className="dropdown-item">
                            {props.t("Designation")}
                          </Link>
                          <div className="dropdown">
                            <div
                              className={classname("dropdown-menu", {
                                show: table,
                              })}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <Link to="/users" className="dropdown-item">
                        {props.t("Users")}
                      </Link>

                      <Link to="/holiday_master" className="dropdown-item">
                        {props.t("Holiday Master")}
                      </Link>

                      <Link to="/shift" className="dropdown-item">
                        {props.t("Shift")}
                      </Link>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setMaster_supplier(!master_supplier)
                          }}
                        >
                          {props.t("Supplier")}{" "}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: master_supplier,
                          })}
                        >
                          <Link to="/add_supplier" className="dropdown-item">
                            {props.t("Add Supplier")}
                          </Link>
                          <Link to="/update_supplier" className="dropdown-item">
                            {props.t("View/Update Supplier")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setItemMaster(!itemMaster)
                          }}
                        >
                          {props.t("Item")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: itemMaster,
                          })}
                        >
                          <Link to="/add_item" className="dropdown-item">
                            {props.t("Add/Update Item")}
                          </Link>
                          <Link
                            to="/unit-of-measurement"
                            className="dropdown-item"
                          >
                            {props.t("Unit Of Measurement")}
                          </Link>

                          <Link to="/add_category" className="dropdown-item">
                            {props.t("Add Item Category")}
                          </Link>
                          <Link to="/update_category" className="dropdown-item">
                            {props.t("View/Update Category")}
                          </Link>
                        </div>
                      </div>
                      {/* <Link to="/add_item" className="dropdown-item">
                        {props.t("Item")}
                      </Link>
                      <Link to="/unit-of-measurement" className="dropdown-item">
                        {props.t("Unit Of Measurement")}
                      </Link>
                      */}

                      {/* customer MAster */}

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setCustomer_Master(!customer_master)
                          }}
                        >
                          {props.t("Customer")}{" "}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: customer_master,
                          })}
                        >
                          <Link
                            to="/add-customer"
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Add/Update Customer")}
                          </Link>

                          <Link to="/wallet-history" className="dropdown-item">
                            {props.t("Wallet History")}
                          </Link>

                          <Link
                            to="/wallet-transactions"
                            className="dropdown-item"
                          >
                            {props.t("Wallet Transaction")}
                          </Link>
                        </div>
                      </div>

                      {/* <div className="dropdown">
                            <Link
                              className="dropdown-item dropdown-toggle arrow-none"
                              to="/#"
                              onClick={e => {
                                e.preventDefault()
                                setMaster_Items(!master_Items)
                              }}
                            >
                              <span key="t-email-templates">
                                {props.t("Items")}
                              </span>
                              <div className="arrow-down"></div>
                            </Link>

                            <div  className={classname("dropdown-menu", { show: master_Items, })} >

                              <Link   to="/add_item"   className="dropdown-item"    >
                                {props.t("Add Items")}
                              </Link> */}

                      {/* <Link to="/cafe_item" className="dropdown-item">
                                {props.t("Cafe Items")}
                              </Link>

                              <Link to="/kitchen_item" className="dropdown-item" >
                                {props.t("Kitchen Items")}
                              </Link> */}

                      {/* </div>
                          </div> */}

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setGame_Master(!game_Master)
                          }}
                        >
                          {props.t("Games")} <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: game_Master,
                          })}
                        >
                          <Link
                            to="/add_games"
                            className="dropdown-item dropdown-toggle arrow-none"
                          >
                            {props.t("Add/Update Game")}
                          </Link>

                          <Link to="/add_game_tables" className="dropdown-item">
                            {props.t("Game Tables")}
                          </Link>
                        </div>
                      </div>
                      {/* <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault()
                            setMaster_categories(!master_categories)
                          }}
                        >
                          <span key="t-email-templates">
                            {props.t("Item Category")}
                          </span>
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: master_categories,
                          })}
                        >
                          <Link to="/add_category" className="dropdown-item">
                            {props.t("Add Item Category")}
                          </Link>
                          <Link to="/update_category" className="dropdown-item">
                            {props.t("View/Update Category")}
                          </Link>
                         
                        </div>
                      </div> */}

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setExpenseMaster(!expenseMaster)
                          }}
                        >
                          {props.t("Expenses")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: expenseMaster,
                          })}
                        >
                          <Link to="/add_expense" className="dropdown-item">
                            {props.t("Add Expense")}
                          </Link>
                          <Link to="/add_source" className="dropdown-item">
                            {props.t("Add Source")}
                          </Link>
                        </div>
                      </div>
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setClosure(!closure)
                          }}
                        >
                          {props.t("Closure")}{" "}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: closure,
                          })}
                        >
                          <Link to="/daily_closure" className="dropdown-item">
                            {props.t("Daily Closure")}
                          </Link>
                          <Link to="/shift_closure" className="dropdown-item">
                            {props.t("Shift Closure")}
                          </Link>
                        </div>
                      </div>
                      <Link to="/add_packages" className="dropdown-item">
                        {props.t("Packages")}
                      </Link>

                      <Link to="/coupons" className="dropdown-item">
                        {props.t("Coupons")}
                      </Link>
                      <Link to="/payment_methods" className="dropdown-item">
                        {props.t("Payment Methods")}
                      </Link>
                    </div>
                  </li>
                )}

                {/* AdminRole */}

                {ifAdmin && (
                  <>
                    {/* Bookings */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setBookings(!bookings)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Booking")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: bookings,
                        })}
                      >
                        {/* <div className="dropdown">
                        <Link to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            setReservation(!reservation)
                          }}
                        >
                          {props.t("Reservation")}
                          <div className="arrow-down"></div>
                        </Link>

                        <div className={classname("dropdown-menu", { show: reservation })}>

                          <Link  to="/add-reservation" className="dropdown-item">
                            {props.t("Group Reservation")}
                          </Link>

                          <Link to="/sequntial-reservation" className="dropdown-item">
                            {props.t("Sequential Reservation")}
                          </Link>
                          
                        </div>

                      </div> */}

                        <Link to="/add-reservation" className="dropdown-item">
                          {props.t("Add Reservation")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setUpdateBookings(!updateBookings)
                            }}
                          >
                            {props.t("Update Booking")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: updateBookings,
                            })}
                          >
                            <Link to="/add-games" className="dropdown-item">
                              {props.t("Add Games")}
                            </Link>

                            <Link to="/add-items" className="dropdown-item">
                              {props.t("Add Items")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/check-out" className="dropdown-item">
                          {props.t("Check Out")}
                        </Link>

                        <Link to="/all-booking" className="dropdown-item">
                          {props.t("ALL Bookings")}
                        </Link>
                      </div>
                    </li>

                    {/* Sales */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setShop(!shop)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-cart me-2"></i>
                        {props.t("Sales")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: shop })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItems(!items)
                            }}
                          >
                            {props.t("Items")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: items,
                            })}
                          >
                            <Link to="/sell-items" className="dropdown-item">
                              {props.t("Sell Items")}
                            </Link>

                            <Link
                              to="/item-sold-only"
                              className="dropdown-item"
                            >
                              {props.t("All Sold Items ")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setPackages(!packages)
                            }}
                          >
                            {props.t("Packages")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: packages,
                            })}
                          >
                            <Link to="/sell-packages" className="dropdown-item">
                              {props.t("Sell Packages")}
                            </Link>

                            <Link
                              to="/all-sold-packages"
                              className="dropdown-item"
                            >
                              {props.t("All Sold Packages")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Inventory */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setInventory(!Inventory)
                        }}
                        className="nav-link dropdown-togglez arrow-none "
                      >
                        <i className="bx bx-customize me-2"></i>
                        {props.t("Inventory")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: Inventory,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setPurchaseOrders(!purchaseOrders)
                            }}
                          >
                            {props.t("Purchase Orders")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: purchaseOrders,
                            })}
                          >
                            <Link to="/generate_po" className="dropdown-item">
                              {props.t("Generate")}
                            </Link>

                            <Link
                              to="/all_purchase_order"
                              className="dropdown-item"
                            >
                              {props.t("View All")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setOrders(!orders)
                            }}
                          >
                            {props.t("Internal Uses")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: orders,
                            })}
                          >
                            <Link
                              to="/kitchen_withdraw"
                              className="dropdown-item"
                            >
                              {props.t("Kitchen Withdraw")}
                            </Link>
                            <Link
                              to="/accessories-order"
                              className="dropdown-item"
                            >
                              {props.t("Accessories Withdraw")}
                            </Link>
                            <Link to="/used_items" className="dropdown-item">
                              {props.t("View Used Items")}
                            </Link>
                          </div>
                        </div>

                        <Link to="/view_inventory" className="dropdown-item">
                          {props.t("View Inventory")}
                        </Link>
                        <Link to="/expired_items" className="dropdown-item">
                          {props.t("Expired Items")}
                        </Link>
                        {/* <Link to="#" className="dropdown-item">
                            {props.t("Extend Expiry")}
                      </Link> */}
                      </div>
                    </li>

                    {/* Attendance */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setAttendance(!attendance)
                        }}
                        className="nav-link dropdown-togglez arrow-none"
                      >
                        <i className="bx bx-user me-2"></i>
                        {props.t("Attendance")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: attendance,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setAttendanceMenu(!attendanceMenu)
                            }}
                          >
                            {props.t("Attendance")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: attendanceMenu,
                            })}
                          >
                            {/* <Link to="/mark-attendance" className="dropdown-item">
                            {props.t("Insert Attendance")}
                          </Link> */}

                            <Link
                              to="/edit_attendance"
                              className="dropdown-item"
                            >
                              {props.t("View/Edit Attendance")}
                            </Link>

                            <Link
                              to="/view-attendance"
                              className="dropdown-item"
                            >
                              {props.t("View Logs")}
                            </Link>
                          </div>
                        </div>
                        {/* <Link to="/edit_attendance" className="dropdown-item">
                        {props.t("Edit attendance")}
                      </Link>
                      <Link to="/view-attendance" className="dropdown-item">
                        {props.t("Attendance Logs")}
                      </Link> */}
                        <Link to="/roster" className="dropdown-item">
                          {props.t("Roster")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setOvertime(!overtime)
                            }}
                          >
                            {props.t("Overtime")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: overtime,
                            })}
                          >
                            <Link
                              to="/apply-overtime-owner"
                              className="dropdown-item"
                            >
                              {props.t("Apply")}
                            </Link>

                            <Link
                              to="/approve_overtime"
                              className="dropdown-item"
                            >
                              {props.t("Approve")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setLeave(!leave)
                            }}
                          >
                            {props.t("Leave")}
                            <div className="arrow-down"></div>
                          </Link>

                          <div
                            className={classname("dropdown-menu", {
                              show: leave,
                            })}
                          >
                            <Link
                              to="/apply-leave-owner"
                              className="dropdown-item"
                            >
                              {props.t("Apply")}
                            </Link>

                            <Link to="/approve_leave" className="dropdown-item">
                              {props.t("Approve")}
                            </Link>
                          </div>
                        </div>

                        {/* <Link to="/approve_overtime" className="dropdown-item ">
                        {props.t("Approve Overtime")}
                      </Link> 
                       <Link to="/approve_leave" className="dropdown-item ">
                        {props.t("Approve leave")}
                      </Link> */}
                        {/* <Link to="/overtime_calculation" className="dropdown-item disabled">
                      {props.t("Overtime calculation")}
                    </Link> */}
                      </div>
                    </li>

                    {/* Salary */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-togglez arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setSalary(!salary)
                        }}
                      >
                        <i className="bx bx-money me-2"></i>
                        {props.t("Salary")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: salary })}
                      >
                        <Link to="/generate-salary" className="dropdown-item">
                          {props.t("Generate salary")}
                        </Link>
                        <Link to="/view-salary" className="dropdown-item">
                          {props.t("View salary")}
                        </Link>
                        <Link to="/arrear-deduction" className="dropdown-item">
                          {props.t("Arrear/Deduction")}
                        </Link>
                        <Link to="/loan" className="dropdown-item">
                          {props.t("Approve Loan")}
                        </Link>
                        <Link to="/completed_loan" className="dropdown-item">
                          {props.t("Completed Loan")}
                        </Link>
                      </div>
                    </li>

                    {/* Masters */}
                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcomponent(!component)
                        }}
                      >
                        <i className="bx bx-collection me-2"></i>
                        {props.t("Master Data")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: component,
                        })}
                      >
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setform(!form)
                            }}
                          >
                            {props.t("Employees")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: form,
                            })}
                          >
                            <Link
                              to="/add_new_employee"
                              className="dropdown-item"
                            >
                              {props.t("Add New Employee")}
                            </Link>
                            <Link to="/view_employee" className="dropdown-item">
                              {props.t("View/Update Employee")}
                            </Link>
                            <Link to="/designation" className="dropdown-item">
                              {props.t("Designation")}
                            </Link>
                            <div className="dropdown">
                              <div
                                className={classname("dropdown-menu", {
                                  show: table,
                                })}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <Link to="/users" className="dropdown-item">
                          {props.t("Users")}
                        </Link>

                        <Link to="/holiday_master" className="dropdown-item">
                          {props.t("Holiday Master")}
                        </Link>

                        <Link to="/shift" className="dropdown-item">
                          {props.t("Shift")}
                        </Link>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setMaster_supplier(!master_supplier)
                            }}
                          >
                            {props.t("Supplier")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: master_supplier,
                            })}
                          >
                            <Link to="/add_supplier" className="dropdown-item">
                              {props.t("Add Supplier")}
                            </Link>
                            <Link
                              to="/update_supplier"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Supplier")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItemMaster(!itemMaster)
                            }}
                          >
                            {props.t("Item")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: itemMaster,
                            })}
                          >
                            <Link to="/add_item" className="dropdown-item">
                              {props.t("Add/Update Item")}
                            </Link>
                            <Link
                              to="/unit-of-measurement"
                              className="dropdown-item"
                            >
                              {props.t("Unit Of Measurement")}
                            </Link>

                            <Link to="/add_category" className="dropdown-item">
                              {props.t("Add Item Category")}
                            </Link>
                            <Link
                              to="/update_category"
                              className="dropdown-item"
                            >
                              {props.t("View/Update Category")}
                            </Link>
                          </div>
                        </div>

                        {/* customer MAster */}

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setCustomer_Master(!customer_master)
                            }}
                          >
                            {props.t("Customer")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: customer_master,
                            })}
                          >
                            <Link
                              to="/add-customer"
                              className="dropdown-item dropdown-toggle arrow-none"
                            >
                              {props.t("Add/Update Customer")}
                            </Link>

                            <Link
                              to="/wallet-history"
                              className="dropdown-item"
                            >
                              {props.t("Wallet History")}
                            </Link>

                            <Link
                              to="/wallet-transactions"
                              className="dropdown-item"
                            >
                              {props.t("Wallet Transaction")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setGame_Master(!game_Master)
                            }}
                          >
                            {props.t("Games")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: game_Master,
                            })}
                          >
                            <Link
                              to="/add_games"
                              className="dropdown-item dropdown-toggle arrow-none"
                            >
                              {props.t("Add/Update Game")}
                            </Link>

                            <Link
                              to="/add_game_tables"
                              className="dropdown-item"
                            >
                              {props.t("Game Tables")}
                            </Link>
                          </div>
                        </div>

                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setExpenseMaster(!expenseMaster)
                            }}
                          >
                            {props.t("Expenses")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: expenseMaster,
                            })}
                          >
                            <Link to="/add_expense" className="dropdown-item">
                              {props.t("Add Expense")}
                            </Link>
                            <Link to="/add_source" className="dropdown-item">
                              {props.t("Add Source")}
                            </Link>
                          </div>
                        </div>
                        <div className="dropdown">
                          <Link
                            to="/#"
                            className="dropdown-item dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setClosure(!closure)
                            }}
                          >
                            {props.t("Closure")}{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: closure,
                            })}
                          >
                            <Link to="/daily_closure" className="dropdown-item">
                              {props.t("Daily Closure")}
                            </Link>
                            <Link to="/shift_closure" className="dropdown-item">
                              {props.t("Shift Closure")}
                            </Link>
                          </div>
                        </div>
                        <Link to="/add_packages" className="dropdown-item">
                          {props.t("Packages")}
                        </Link>
                        <Link to="/coupons" className="dropdown-item">
                          {props.t("Coupons")}
                        </Link>
                        <Link to="/payment_methods" className="dropdown-item">
                          {props.t("Payment Methods")}
                        </Link>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
