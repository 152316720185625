import React, { useState, useEffect } from "react"
import axios from "axios"
import border from "../../assets/images/Border.png"
import "./allBooking.css"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import swal from "sweetalert2"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/backend_helper"
import Swal from "sweetalert2"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import { Link } from "react-router-dom"
import Loader from "../../helpers/Loader"
import Select from "react-select"

const AddItems = props => {
  const [gameDetails, setGameDetails] = useState([])
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItemDetails, setSelectedItemDetails] = useState(null)
  const [selectedGameIndex, setSelectedGameIndex] = useState(null)
  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const [saveselectedGameIndex, setSaveselectedGameIndex] = useState(null)
  const [updatedItemDetails, setUpdatedItemDetails] = useState([])
  const [bookingId, setBookingId] = useState("")
  const [totalPrice, setTotalPrice] = useState(0)
  const [itemTotalPrice, setItemTotalPrice] = useState(0)
  const [prevItemTotalPrice, setPrevItemTotalPrice] = useState(0)
  const [allItems, setAllItems] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [verticalActiveTab, setVerticalActiveTab] = useState("1")
  const [isQuantityChanging, setIsQuantityChanging] = useState(false)
  const [originalItemQuantities, setOriginalItemQuantities] = useState({})
  const [verticalActiveTabWithIcon, setVerticalActiveTabWithIcon] =
    useState("1")
  const [toggleModal, setToggleModal] = useState(false)

  //Search items states
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("")
  const [searchTimeout, setSearchTimeout] = useState(null)

  const [customertype, setCustomertype] = useState("")

  //select section state
  const [selectedSection, setSelectedSection] = useState(null)

  useEffect(() => {
    loadGameBookingDetails()
    loadAllItems()
    // setItemTotalPrice(0)
  }, [])
  // console.log(allItems,"all items")
  const loadAllItems = async () => {
    // Loader(true)
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_items"
    const requestData = {
      search: searchQuery,
    }
    let response = await postSubmitForm(url, requestData)
    if (response && response.status === 1) {
      Loader(false)
      let items = response.data.filter(item => (item.is_active === true) && (item.is_menu_item === true || item.is_household_item === true)
    )   
       let updatedItems = items?.forEach(item => {
        for (let i = 0; i < updatedItemDetails?.length; i++) {
          if (updatedItemDetails[i]._id === item._id) {
            item.quantity.in_sub_unit =
              updatedItemDetails[i].quantity.in_sub_unit
            item.sub_total = updatedItemDetails[i].sub_total
          }
        }
        return items
      })
      // console.log(updatedItems,"items")
      if (selectedSection !== null && selectedSection.value === "Cafe") {
        const filtered = (
          updatedItems?.length > 0 ? updatedItems : items
        )?.filter(
          item =>
            item.section === selectedSection?.value ||
            (item.section === "Kitchen" &&
              item.is_menu_item !== false &&
              item.is_active !== false)
        )
        setAllItems(filtered)
      } else if (
        selectedSection !== null &&
        selectedSection.value === "Accessories"
      ) {
        const filtered = (
          updatedItems?.length > 0 ? updatedItems : items
        )?.filter(
          item =>
            item.section === selectedSection?.value && item.is_active !== false
        )
        setAllItems(filtered)
      } else {
        setAllItems(items)
      }
    } else {
      // Loader(false)
      setAllItems([])
      console.log("Error")
    }
  }

  useEffect(() => {
    loadAllItems()

    //  console.log(selectedSection, "SELECTED SECTION")
  }, [selectedSection])

  useEffect(() => {
    if (searchQuery !== debouncedSearchQuery) {
      clearTimeout(searchTimeout)
      const timeout = setTimeout(() => {
        setDebouncedSearchQuery(searchQuery)
      }, 300)

      setSearchTimeout(timeout)
    }
  }, [searchQuery])

  useEffect(() => {
    loadAllItems()
  }, [debouncedSearchQuery])

  const handleSearchInputChange = event => {
    const newSearchQuery = event.target.value
    setSearchQuery(newSearchQuery)
  }

  // const handleSearch = () => {
  //   loadAllItems();
  // };

  const loadGameBookingDetails = async () => {
    const url = process.env.REACT_APP_BASEURL + "bookings/get_in_process"

    try {
      Loader(true)
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${employeeToken}`,
          },
        }
      )

      if (response.data.status === 1) {
        // Loader(false)
        const fetchedBookings = response.data.data

        // const filteredBookings = fetchedBookings.filter(
        //   booking => booking.game_details.length > 0
        // )

        const responseData = fetchedBookings.map(booking => {
          return {
            booking_id: booking._id,
            booking_received_by: booking.booking_received_by,
            booking_status: booking.booking_status,
            branch_details: booking.branch_details,
            check_in: booking.check_in,
            check_out: booking.check_out,
            end_time: booking.end_time,
            game_total_price: booking.game_total_price,
            is_advance_booking: booking.is_advance_booking,
            is_item_only: booking.is_item_only,
            is_package_only: booking.is_package_only,
            is_refund_requested: booking.is_refund_requested,
            package_details: booking.package_details,
            package_total_price: booking.package_total_price,
            payment_details: booking.payment_details,
            payment_status: booking.payment_status,
            remarks: booking.remarks,
            start_time: booking.start_time,
            total_time: booking.total_time,
            total_price: booking.total_price,
            item_details: booking.item_details || [],
            item_total_price: booking.item_total_price || 0,
            customer_details: booking.customer_details || [],

            game_details: booking.game_details || [],
          }
        })

        setIsLoading(false)
        setGameDetails(responseData)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    // console.log(updatedItemDetails, "Helloo Man")
  }, [updatedItemDetails])
  let booking
  const openItemDetailsModal = index => {
    booking = gameDetails[index]
    setSelectedGameIndex(null)
    setSelectedItemDetails(null)
    setUpdatedItemDetails(null)
    setVerticalActiveTab("1")
    setSearchQuery("")
    setTotalPrice(0)

    loadAllItems()
    setSelectedGameIndex(index)
    setPrevItemTotalPrice(gameDetails[index]?.item_total_price)
    setSelectedItemDetails(gameDetails[index]?.item_details)
    setCustomertype(gameDetails[index]?.customer_details.customer_type)
    setTotalPrice(gameDetails[index]?.total_price)
    // setItemTotalPrice(initialItemTotalPrice);
    // Filter out items from the "In Process" tab and initialize updatedItemDetails with an empty array

    setUpdatedItemDetails([])
    // const initialItemTotalPrice = updatedItemDetails.reduce(
    //   (total, item) => total + (item.sub_total || 0),
    //   0
    // );
  }

  // console.log(customertype, "type")
  // console.log(prevItemTotalPrice, " Already item Total Price");

  // console.log(selectedGameIndex, "GAME INDEX")

  const closeItemDetailsModal = () => {
    // setSelectedGameIndex(null)
    // setSelectedItemDetails(null)
    // setUpdatedItemDetails(null)
    // setVerticalActiveTab("1")
    // setSearchQuery("")
    // setTotalPrice(0)
    // setItemTotalPrice(0);
    // setSelectedItemIndex("")
    // loadGameBookingDetails()
    // loadAllItems()
  }

  const handleAddItemClick = item => {
    // console.log(updatedItemDetails, "UPDATED")
    if (item && item.name) {
      setSelectedItem(item)
      setSelectedItemIndex(item._id)
      setOriginalItemQuantities(prevQuantities => ({
        ...prevQuantities,
        [item._id]: item.quantity.in_sub_unit,
      }))

      // Update selectedItemDetails to the selected item's details
      const selectedItemDetailsCopy = { ...selectedItemDetails }
      selectedItemDetailsCopy[item._id] = selectedItemDetailsCopy[item._id]
        ? selectedItemDetailsCopy[item._id] + 1
        : 1
      setSelectedItemDetails(selectedItemDetailsCopy)
    }
  }

  const handleIncrement = item => {
    const updatedItems = [...allItems]
    console.log(updatedItems, "up")
    const index = updatedItems.findIndex(i => i._id === item._id)
    if (item.is_household_item) {
      updatedItems[index].quantity.in_unit += 1;
      updatedItems[index].sub_total = 
        updatedItems[index].selling_price * updatedItems[index].quantity.in_unit;
  
      updatedItems[index].sub_total = updatedItems[index].sub_total.toFixed(2);
      updatedItems[index].sub_total = Number(updatedItems[index].sub_total);
  
      setIsQuantityChanging(true);
      setSelectedItemIndex(item._id);
  
      const updatedItemTotalPrice = updatedItems.reduce(
        (total, item) => 
          total + (customertype !== "VIP" ? item.sub_total : 0),
        0
      );
  
      setUpdatedItemDetails(updatedItems);
      setItemTotalPrice(updatedItemTotalPrice);
    } else {
      let availableInSubUnits;
      if (item.formula > 0 && item.formula !== "") {
        availableInSubUnits = item.available.in_unit * item.formula;
      } else {
        availableInSubUnits = item.available.in_unit;
      }
  
      const isSubUnitIncrement = item.formula > 0 && item.formula !== "";
  
      if (
        isSubUnitIncrement
          ? updatedItems[index].quantity.in_sub_unit < availableInSubUnits
          : updatedItems[index].quantity.in_unit < availableInSubUnits
      ) {
        if (isSubUnitIncrement) {
          updatedItems[index].quantity.in_sub_unit += 1;
        } else {
          updatedItems[index].quantity.in_unit += 1;
        }
  
        if (customertype !== "VIP") {
          if (isSubUnitIncrement) {
            updatedItems[index].sub_total =
              updatedItems[index].selling_price *
              updatedItems[index].quantity.in_sub_unit;
          } else {
            updatedItems[index].sub_total =
              updatedItems[index].selling_price *
              updatedItems[index].quantity.in_unit;
          }
  
          updatedItems[index].sub_total = updatedItems[index].sub_total.toFixed(2);
          updatedItems[index].sub_total = Number(updatedItems[index].sub_total);
        } else {
          updatedItems[index].sub_total = 0;
        }
  
        setIsQuantityChanging(true);
        setSelectedItemIndex(item._id);
  
        const updatedItemTotalPrice = updatedItems.reduce(
          (total, item) =>
            total +
            (customertype !== "VIP"
              ? item.sub_total
              : isSubUnitIncrement
              ? item.sub_total * item.quantity.in_sub_unit
              : item.sub_total * item.quantity.in_unit),
          0
        );
  
        setUpdatedItemDetails(updatedItems);
        setItemTotalPrice(updatedItemTotalPrice);
      } else {
        swal.fire(
          "Item out of stock",
          "Cannot add more, Out of stock for now.",
          "warning"
        );
      }
    }
  };

  const handleDecrement = item => {
    const updatedItems = [...allItems]
    const index = updatedItems.findIndex(i => i._id === item._id)

    const isSubUnitDecrement = item.formula > 0 && item.formula !== ""

    if (
      isSubUnitDecrement
        ? updatedItems[index].quantity.in_sub_unit > 0
        : updatedItems[index].quantity.in_unit > 0
    ) {
      const newQuantity = isSubUnitDecrement
        ? updatedItems[index].quantity.in_sub_unit - 1
        : updatedItems[index].quantity.in_unit - 1

      isSubUnitDecrement
        ? (updatedItems[index].quantity.in_sub_unit = newQuantity)
        : (updatedItems[index].quantity.in_unit = newQuantity)

      if (customertype !== "VIP") {
        if (isSubUnitDecrement) {
          updatedItems[index].sub_total =
            updatedItems[index].selling_price *
            updatedItems[index].quantity.in_sub_unit
        } else {
          updatedItems[index].sub_total =
            updatedItems[index].selling_price *
            updatedItems[index].quantity.in_unit
        }

        updatedItems[index].sub_total = updatedItems[index].sub_total.toFixed(2)
        updatedItems[index].sub_total = Number(updatedItems[index].sub_total)
      } else {
        updatedItems[index].sub_total = 0
      }

      setIsQuantityChanging(true)
      setSelectedItemIndex(item._id)

      const updatedItemTotalPrice = updatedItems.reduce(
        (total, item) =>
          total +
          (customertype !== "VIP"
            ? item.sub_total
            : isSubUnitDecrement
            ? item.sub_total * item.quantity.in_sub_unit
            : item.sub_total * item.quantity.in_unit),
        0
      )

      const clampedTotalPrice = Math.max(updatedItemTotalPrice, 0)
      const finalTotalPrice =
        Math.abs(clampedTotalPrice) < 1e-10 ? 0 : clampedTotalPrice

      setItemTotalPrice(finalTotalPrice)
      setUpdatedItemDetails(updatedItems)
    } else {
      swal.fire(
        "Item quantity already at minimum",
        "Cannot decrement further.",
        "warning"
      )
    }
  }

  const total_price = totalPrice + itemTotalPrice
  // console.log(total_price, "TOTAL PRICE")
  // const handleItemClick = item => {
  //   setSelectedItem(item)
  // }

  // const calculateTotalPrice = () => {
  //   let total = 0
  //   allItems.forEach(item => {
  //     if (selectedItemDetails[item._id] > 0) {
  //       total += item.sub_total * selectedItemDetails[item._id]
  //     }
  //   })
  //   return total
  // }

  useEffect(() => {
    const originalQuantities = {}
    allItems.forEach(item => {
      originalQuantities[item._id] =
        item.formula > 0 && item.formula !== ""
          ? item.quantity.in_sub_unit
          : item.quantity.in_unit
    })

    setOriginalItemQuantities(originalQuantities)
    // console.log(originalItemQuantities, "ORIGINAL")
  }, [allItems])

  const confirmChanges = async () => {
    // console.log(updatedItemDetails, "UPPPPPPPPPP")
    if (selectedGameIndex !== null && updatedItemDetails !== null) {
      // const selectedItemDetails = allItems.find((i) => i._id === selectedItem._id);
      const finalItemTotalPrice = updatedItemDetails.reduce(
        (total, item) => total + (item.sub_total || 0),
        0
      )
      setItemTotalPrice(finalItemTotalPrice)

      const url =
        process.env.REACT_APP_BASEURL + "bookings/insert_item_in_booking"

      try {
        const bookingId = gameDetails[selectedGameIndex]?.booking_id

        const itemsWithChangedQuantity = updatedItemDetails
          .filter(
            item => item.quantity.in_sub_unit > 0 || item.quantity.in_unit > 0
          )
          .map(item => {
            return {
              item_id: item._id,
              image_url: item.image_url,
              name: item.name,
              section: item.section,
              unit: item.unit,
              sub_unit: item.sub_unit,
              expiry_date: item.expiry_date,
              formula: item.formula,
              category: item.category,
              threshold: item.threshold,
              price: item.selling_price,

              sub_total: item.sub_total,

              quantity: {
                in_unit: item.quantity.in_unit,
                in_sub_unit: item.quantity.in_sub_unit,
              },
              available: {
                in_unit: item.available.in_unit,
                in_sub_unit: item.available.in_sub_unit,
              },
              new_available: (() => {
                const formula = item.formula || 1
                const availableInSubUnits =
                  item.available.in_unit * formula + item.available.in_sub_unit
                const quantityInSubUnits =
                  item.quantity.in_unit * formula + item.quantity.in_sub_unit
                const newAvailableInSubUnits =
                  availableInSubUnits - quantityInSubUnits
                const newAvailableInUnits = Math.floor(
                  newAvailableInSubUnits / formula
                )
                const newAvailableSubUnits = newAvailableInSubUnits % formula
                return {
                  in_unit: newAvailableInUnits,
                  in_sub_unit: newAvailableSubUnits,
                }
              })(),
            }
          })
        // console.log(itemsWithChangedQuantity, "Items With Quantity Changed")
        // return
        const response = await axios.post(
          url,
          {
            booking_id: bookingId,
            item: itemsWithChangedQuantity,
            item_total_price: +itemTotalPrice + prevItemTotalPrice,
            total_price: +total_price,
          },
          {
            headers: {
              Authorization: `Bearer ${employeeToken}`,
            },
          }
        )

        if (response.data.status === 1) {
          swal.fire("Success", response.data.message, "success")
          loadGameBookingDetails()
          setToggleModal(false)
          loadAllItems()
        } else {
          swal.fire("Error", response.data.message, "error")
        }
      } catch (error) {
        console.error("Error updating item details:", error)
      }
    }
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab)
    }
  }

  const handleSectionChange = option => {
    setSelectedSection(option)
  }

  const options = [
    { value: "Cafe", label: "Food" },
    { value: "Accessories", label: "Accessories" },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  const maxWidth =
    window.innerWidth < 768
      ? "100%"
      : window.innerWidth < 1200
      ? "750px"
      : "1100px"

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Booking"
            alternateTitle="In Process Bookings"
            breadcrumbItem1="In Process Bookings"
          />
          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <Row>
                    <Col lg={12} style={{ margin: "auto" }}>
                      <Row
                        style={{ display: "flex", gap: "50px", margin: "auto" }}
                      >
                        {gameDetails.length === 0 && (
                          <Col
                            lg={12}
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              paddingTop: "15px",
                            }}
                          >
                            <h5>No in process bookings found.</h5>
                          </Col>
                        )}
                        <Row
                          style={{
                            display: "flex",
                            margin: "auto",
                            gap: "35px",
                            justifyContent: "center",
                          }}
                        >
                          {gameDetails?.map((booking, index) => (
                            <Col
                              sm="6"
                              md="5"
                              lg="3"
                              key={index}
                              style={{
                                paddingTop: "10px",
                                height: "238px",
                                borderRadius: "20px",
                                backgroundColor: "#f5f1f0",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.45) 0px 12.5px 10px -10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // console.log(gameDetails, "game details")
                                setToggleModal(prev => (prev = !prev))

                                openItemDetailsModal(index)
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                {/* Case 1: Display game_details */}
                                {booking.game_details.length > 0 && (
                                  <div
                                    className="scroll-container"
                                    // style={{
                                    //   justifyContent:
                                    //     booking.game_details.length >= 3 ? "unset" : "center",
                                    // }}
                                  >
                                    {booking.game_details.map(
                                      (game, gameIndex) => (
                                        <div
                                          className="game"
                                          key={gameIndex}
                                          // onClick={() => openItemDetailsModal(gameIndex)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={game.game.image}
                                            alt={`Game ${gameIndex}`}
                                            className="game-image"
                                          />
                                          <div className="table-number-overlay">
                                            <p
                                              style={{
                                                fontSize: booking.game_details.length>=2 ? "13px": "15px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {game?.table_number}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}

                                {/* Case 2: Display package_details */}
                                {booking.package_details.length > 0 && (
                                  <div
                                    className="scroll-container"
                                    // style={{
                                    //   justifyContent:
                                    //     booking.package_details[0].game_details.length >= 3
                                    //       ? "flex-start"
                                    //       : "center",
                                    // }}
                                  >
                                    {booking.package_details[0].game_details.map(
                                      (game, gameIndex) => (
                                        <div
                                          className="game"
                                          key={gameIndex}
                                          onClick={() =>
                                            openItemDetailsModal(gameIndex)
                                          }
                                        >
                                          <img
                                            src={game.image}
                                            alt={`Game ${gameIndex}`}
                                            className="game-image"
                                          />
                                          <div className="table-number-overlay">
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {game?.table_number} <br />{" "}
                                              <span
                                                style={{
                                                  fontSize: "10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                from package
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>

                              <Row>
                                <img
                                  style={{
                                    maxWidth: "55%",
                                    margin: "auto",
                                    opacity: "0.75",
                                    marginTop: "4px",
                                    marginBottom: "4px",
                                  }}
                                  src={border}
                                  alt="border"
                                />
                              </Row>

                              <div
                                style={{
                                  width: "auto",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  gap: "-3px",
                                  // paddingLeft: "20px",
                                }}
                              >
                                <Row>
                                  <h5
                                    style={{
                                      textAlign:"center",
                                      color: "#0b1f32",
                                      fontWeight: "600",
                                      // marginBottom: "-5px",
                                      whiteSpace: "nowrap",
                                      width: "15rem",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {booking.customer_details?.name &&
                                      booking.customer_details.customer_type &&
                                      `${booking.customer_details.name}`}
                                  </h5>
                                </Row>

                                <span
                                  style={{
                                    // textAlign:"center",
                                    color: "#0b1f32",
                                    fontWeight: "600",
                                    fontSize: "10px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {`${booking.customer_details?.mobile}`}
                                </span>
                                <span
                                  style={{
                                    color: "#0b1f32",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    marginBottom: "3px",
                                    fontStyle:"italic"
                                  }}
                                >
                                  {booking.customer_details?.name &&
                                    booking.customer_details.customer_type &&
                                    `${booking.customer_details.customer_type}`}
                                </span>
                              </div>
                              
                              <Row style={{ marginTop:"10px"}}> 
                                <Button
                                  className="btn btn-sm "
                                  style={{
                                    width: "auto",
                                    display: "block",
                                    margin: "auto",
                                    // marginTop: "-0.5rem",
                                    borderRadius: "16px",
                                    fontSize: "11px",
                                    backgroundColor: "#ff4539",
                                    border: "none",
                                    transition: "transform 0.3s ease",
                                    // cursor: "pointer",
                                  }}
                                  onMouseEnter={e => {
                                    e.target.style.transform = "scale(1.05)"
                                  }}
                                  onMouseLeave={e => {
                                    e.target.style.transform = "scale(1)"
                                  }}
                                >
                                  Add Items
                                </Button>

                                <Button
                                  style={{
                                    width: "auto",
                                    height: "25px",
                                    // marginTop: "-10px",
                                    display: "block",
                                    margin: "auto",
                                    borderRadius: "16px",
                                    boxShadow: "none",
                                    transition: "transform 0.3s ease",
                                  }}
                                  onMouseEnter={e => {
                                    e.target.style.transform = "scale(1.05)"
                                  }}
                                  onMouseLeave={e => {
                                    e.target.style.transform = "scale(1)"
                                  }}
                                  className="btn btn-success waves-effect waves-light btn-sm"
                                  // className="btn btn-danger"
                                  onClick={() => {
                                    // setStartTimeUpdateInputVisibility(prev => prev = !prev);
                                    // setStartTimeExistingInputVisibility(prev => prev = !prev)
                                  }}
                                >
                                  <Link
                                    style={{ color: "white" }}
                                    to="/check-out"
                                    state={{
                                      booking,
                                    }}
                                  >
                                    Instant Checkout
                                  </Link>
                                </Button>
                              </Row>
                            </Col>
                          ))}
                          <Col
                            sm="6"
                            md="5"
                            lg="3"
                            style={{ visibility: "none" }}
                          >
                            {/* This Col is empty and hidden */}
                          </Col>
                          <Col
                            sm="6"
                            md="5"
                            lg="3"
                            style={{ visibility: "none" }}
                          >
                            {/* This Col is empty and hidden */}
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {selectedGameIndex !== null &&
        gameDetails[selectedGameIndex]?.item_details && (
          <Modal
            style={{ maxWidth }}
            isOpen={toggleModal}
            toggle={() => {
              setToggleModal(prev => (prev = !prev))

              setSelectedGameIndex(null)
              setSelectedItemDetails(null)
              setUpdatedItemDetails(null)
              setSelectedSection(null)
              setVerticalActiveTab("1")
              setSearchQuery("")
              setTotalPrice(0)
              setItemTotalPrice(0)
              setSelectedItemIndex("")
              loadGameBookingDetails()
              loadAllItems()
            }}
          >
            <ModalHeader
              toggle={() => {
                setToggleModal(prev => (prev = !prev))
                setSelectedGameIndex(null)
                setSelectedItemDetails(null)
                setUpdatedItemDetails(null)
                setSelectedSection(null)
                setVerticalActiveTab("1")
                setSearchQuery("")
                setTotalPrice(0)
                setItemTotalPrice(0)
                setSelectedItemIndex("")
                loadGameBookingDetails()
                loadAllItems()
              }}
            >
              Item Details -{" "}
              <span style={{ fontWeight: "bold" }}>
                {gameDetails[selectedGameIndex].customer_details.name}
              </span>{" "}
              &nbsp;
              {gameDetails[selectedGameIndex].package_details.length > 0 ? (
                <span style={{ fontSize: "12px" }}>(Package Booking)</span>
              ) : (
                ""
              )}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="2">
                  <Nav pills className="flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Ordered Items
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Add Items
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
                <Col md="9">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <Row>
                        {gameDetails[selectedGameIndex]?.item_details.length ===
                          0 && (
                          <Col
                            lg={12}
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              paddingTop: "15px",
                            }}
                          >
                            <h5>No in process items found.</h5>
                          </Col>
                        )}
                        {gameDetails[selectedGameIndex]?.item_details.map(
                          (item, index) => (
                            <Col sm="6" md="4" lg="3" key={index}>
                              <div
                                style={{
                                  borderRadius: "15px",
                                  padding: "10px",
                                  marginBottom: "10px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                                }}
                              >
                                <img
                                  style={{
                                    maxWidth: "90%",
                                    height: "120px",
                                  }}
                                  src={item.image_url}
                                  alt={item.name}
                                />
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    marginTop: "2px",
                                  }}
                                >
                                  {item.name}
                                </p>
                                <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Sub Total: {`QR ${item.sub_total}`}
                                </p>
                                {/* <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Sub Total: {`QR ${item.sub_total}`}
                                </p> */}
                                <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-12px",
                                  }}
                                >
                                  Quantity:{" "}
                                  {item.quantity.in_unit === 0
                                    ? item.quantity.in_sub_unit
                                    : item.quantity.in_unit * item.formula}{" "}
                                  {item.sub_unit}
                                </p>

                                {/* <Button
                                  style={{
                                    width: "auto",
                                    height: "25px",
                                    // marginTop: "-10px",

                                    boxShadow: "none",
                                    transition: "transform 0.3s ease",
                                  }}
                                  onMouseEnter={e => {
                                    e.target.style.transform = "scale(1.05)"
                                  }}
                                  onMouseLeave={e => {
                                    e.target.style.transform = "scale(1)"
                                  }}
                                  className="btn btn-sm btn-danger"
                                  // className="btn btn-danger"
                                  onClick={() => {
                                    // setStartTimeUpdateInputVisibility(prev => prev = !prev);
                                    // setStartTimeExistingInputVisibility(prev => prev = !prev)
                                  }}
                                >
                                  Remove
                                </Button> */}
                              </div>
                            </Col>
                          )
                        )}
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row style={{ width: "110%" }}>
                        <Col md="6" xs="12" xl="4">
                          <h6>Search Items</h6>
                          <div className="d-flex justify-content-start mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                              value={searchQuery}
                              onChange={handleSearchInputChange}
                            />
                          </div>
                        </Col>
                        <Col md="6" xs="12" xl="4">
                          <Card>
                            <div className="mt-3 mt-md-0">
                              <h6>Select Section</h6>
                              <Select
                                placeholder="Select here..."
                                isClearable={true}
                                value={selectedSection}
                                onChange={handleSectionChange}
                                options={options}
                              />
                            </div>
                          </Card>
                        </Col>

                        {verticalActiveTab === "2" && (
                          <Col
                            md="6"
                            xs="12"
                            xl="4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                onClick={confirmChanges}
                                style={{
                                  position: "relative",
                                  overflow: "hidden",
                                  backgroundColor: "#fdd005",
                                  border: "none",
                                  color: "black",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  width: "100px",
                                  // marginTop: "-0.6rem",
                                  boxShadow:
                                    "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={e => {
                                  e.target.style.transform = "scale(1.05)"
                                }}
                                onMouseLeave={e => {
                                  e.target.style.transform = "scale(1)"
                                }}
                              >
                                Confirm
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    right: "0",
                                    bottom: "0",
                                    background:
                                      "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                    pointerEvents: "none",
                                  }}
                                ></div>
                              </Button>
                            </Col>

                            <Col lg={8}>
                              {customertype === "VIP" && (
                                <span
                                  style={{
                                    alignItems: "center",
                                    color: "#36B94D",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {`(No Charges for VIP)`}
                                </span>
                              )}
                              {customertype !== "VIP" && (
                                <>
                                  {" "}
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Items Total:
                                  </span>
                                  &nbsp;{" "}
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {" "}
                                    {`QR ${itemTotalPrice.toFixed(2)}`}
                                  </span>
                                  &nbsp; <br />
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {" "}
                                    Booking Total:
                                  </span>
                                  &nbsp;{" "}
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    {" "}
                                    {`QR ${total_price.toFixed(2)}`}
                                  </span>{" "}
                                </>
                              )}
                            </Col>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        {allItems.length === 0 && (
                          <Col
                            lg={12}
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              paddingTop: "15px",
                            }}
                          >
                            <h5>No items found.</h5>
                          </Col>
                        )}
                        {allItems.map((item, index) => (
                          <Col sm="6" md="4" lg="3" key={index}>
                            <div
                              style={{
                                borderRadius: "15px",
                                padding: "10px",
                                marginBottom: "10px",
                                boxShadow:
                                  selectedItemIndex === item._id
                                    ? "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
                                    : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                                cursor: "pointer",
                                textAlign: "center",
                              }}
                              onClick={() => handleAddItemClick(item)}
                            >
                              <img
                                style={{
                                  maxWidth: "90%",
                                  height: "120px",
                                  boxShadow:
                                    "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
                                }}
                                src={item.image_url}
                                alt={item.name}
                              />
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  marginTop: "2px",
                                }}
                              >
                                {item.name}
                              </p>
                              <p
                                style={{
                                  fontWeight: "500",
                                  marginTop: "-14px",
                                }}
                              >
                                Price: {`QR ${item.selling_price?item.selling_price:"N/A"}`}
                              </p>
                              {customertype === "VIP" ? (
                                <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  {/* Sub Total:{" "} */}
                                  {`No Charges for VIP`}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Sub Total:{" "}
                                  {`QR ${
                                    item.quantity.in_sub_unit > 0 ||
                                    item.quantity.in_unit > 0
                                      ? item.sub_total
                                      : 0
                                  }`}
                                </p>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <button
                                  style={{
                                    width: "35px",
                                    border: "none",
                                    borderRadius: "2px",
                                    fontSize: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "35px",
                                    backgroundColor: "#FA395D",
                                    color: "#fff",
                                  }}
                                  onClick={() => handleDecrement(item)}
                                >
                                  -
                                </button>

                                <span
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    color: "#556EE6",
                                  }}
                                >
                                  {item.quantity.in_sub_unit ||
                                    item.quantity.in_unit ||
                                    0}
                                </span>
                                <button
                                  style={{
                                    width: "35px",
                                    border: "none",
                                    borderRadius: "2px",
                                    fontSize: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "35px",
                                    backgroundColor: "#26D200",
                                    color: "#fff",
                                  }}
                                  onClick={() => handleIncrement(item)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter
              style={{ display: "flex", justifyContent: "space-between" }}
            ></ModalFooter>
          </Modal>
        )}
    </React.Fragment>
  )
}

export default AddItems
