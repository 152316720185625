import React, { useState, useEffect} from "react"
import {
  Col,
  Row,
} from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import logo from "../../assets/images/Logo.png"
import { Link } from "react-router-dom"
import { postSubmitForm } from "../../helpers/backend_helper"
import moment from "moment"

const pkgInvoice = props => {
  const [selectedRow, setselected_Row] = useState()


   function printInvoice() {
    window.print();
  }



useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("booking_id")) {
    loadData({ booking_id: urlParams.get("booking_id") });
  }
  
}, []);

  const loadData = async (data) => {
    let url = process.env.REACT_APP_BASEURL + "sales/re-print";
    const response = await postSubmitForm(url, data);
    if (response && response.status === 1) {
      setselected_Row(response.data[0]);
    } else {
      // showNotification(response.message, "Error");
    }
  };



  return (
    <React.Fragment>
     
     <div id="printDiv" style={{ width: "8.5cm", margin: "auto", marginTop: "70px" }}>
     <div className="row head" style={{ width: "100%", margin: "auto", height: "50px",borderBottom:"1px dotted #000" }}>
          <div className="col col-md-2 col-sm-2 col-2" style={{ display: "flex", justifyContent: "flex-start" }}>
            <img src={logo} style={{ height: "40px", borderRadius: " 50%", width: "90px", marginTop:"2px", backgroundColor: "#000000" }} />
          </div>
          <div className="col col-md-10 col-sm-10 col-10 pt-1" style={{ display: "flex", justifyContent: "flex-end" }}>
            <p className="" style={{ color: "black", fontSize: "14px" , fontWeight:"bold" }}>
              {selectedRow?.invoice_no} فاتورة رقم<br />
              {/* PO Id : {selectedRow.po_id}<br /> */}
              <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{moment(selectedRow?.createdAt).format("YYYY-MM-DD HH:mm")} [{selectedRow?.emp_details?.name} ]</span>
              {/* <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{name}</span> */}
            </p>
          </div>
        </div>
        <br />
        <strong style={{color:"black"}}>
      <div style={{margin:"0px 2px 0px 2px"}}>
      <div className="row" >
          <p><b>{props.t("Billed To")}</b></p>
        </div>
        <p className="mb-0 date" style={{ fontSize: "14px", marginTop: "-15px",width: "100%", margin:"auto",}}>
          <span >
            {props.t("Name")}: &nbsp;
            <b> {selectedRow?.customer_details?.name}</b>
          </span>
        </p>
        <p className="mb-0 d-flex justify-content-between" style={{ fontSize: "14px" }}>
          <span>
            <span>{props.t("Phone")}:</span> &nbsp;
            <b>{selectedRow?.customer_details?.mobile}</b>
          </span>
          <span><span>{props.t("Type")}:</span> &nbsp;
            <b> {selectedRow?.customer_details?.customer_type}</b></span>
        </p>
      </div>
        <div className="row mt-3 mb-0" style={{ fontSize: "14px",width: "100%", margin:"auto", }}>
          <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-12" >
            <div className="table-responsive" style={{ width:"109%", marginLeft:"-0.8rem"}}>

             

              {/* Package */}
              {selectedRow?.package_details?.length > 0 && (
        
                    <>
                    <div style={{margin:"0px 2px 0px 2px"}}>
                    <p style={{ marginBottom: "1px", borderBottom: "1px dotted #000" }}><b>{props.t("Package ")}</b></p>
                      <table id="example" className="table"   style={{color:"black"}}                   >
                        <tr style={{ borderBottom: "1px dotted #000" }}>
                          <td>رقم <br />{props.t("S.N")}</td>
                          <td>الوصف <br />{props.t("Description")}</td>
                          <td>{props.t("Total Minutes")}</td>
                          {/* <td>الكمية <br />{props.t("Qty.")}</td> */}
                          <td>{props.t("Validity")}</td>
                          <td>السعر <br />{props.t("Price")}</td>
                        </tr>
                
                        {selectedRow?.package_details?.map((pkg, index) => <>
                          <tr key={index + 1} >
                            <td>{index + 1}</td>
                            <td>{pkg?.package_name}</td>
                            <td>{pkg?.total_game_time}</td>
                            {/* <td>{pkg.total_item_count?.in_unit} Unit {pkg.total_item_count?.in_sub_unit} Sub Unit</td> */}
                            <td>{pkg?.validity}</td>
                            <td>{pkg?.price}</td>
                        
                          </tr>
                        
                        </>
                        )}


                      </table>
                    </div>
              
                </>
              )}
            </div>

            <div style={{ borderTop: "1px dotted #000", borderBottom: "1px dotted #000", textAlign: "right", }} className="row">

              <div className="col-md-11 col-sm-11 col-11" >
                Total Amount &nbsp; اجمالى القيمة :
              </div>

              <div className="col-md-1 col-sm-1 col-1" style={{ marginLeft: "-1.2rem", }}>
                {selectedRow?.total_amount || 0}
              </div>





              {selectedRow?.discount_amount > 0 && (
                <div className="row">
                  <div className="col-md-11 col-sm-11 col-11" style={{ marginLeft: "1.2rem", }}>
                    Discount Amount &nbsp;:
                  </div>


                  <div className="col-md-1 col-sm-1 col-1" style={{ marginLeft: "-1.4rem", }}>
                      {selectedRow?.discount_amount || 0}
                  </div>
                </div>
              )}

{selectedRow?.payment_details?.map((payment, index) => {
                const { _id, ...paymentDetailsWithoutId } = payment;

                let mappedString = '';
                for (let key in paymentDetailsWithoutId) {
                  let value = paymentDetailsWithoutId[key];
                  if (key === "Cash") {
                    return (


                      <>

                        <div className="row">
                          <div className="col-md-11 col-sm-11 col-11" style={{ marginLeft: "1.2rem", }}>
                            Paid By  {key} نقدا :
                          </div>


                          <div className="col-md-1 col-sm-1 col-1" style={{ marginLeft: "-1.4rem", }}>
                            {value}
                          </div>
                        </div>

                      </>

                    );
                    // mappedString += `Paid By ${key} ${cashArabicword} : ${value}\n`;
                  } else {
                    return (


                      <>

                        <div className="row">
                          <div className="col-md-11 col-sm-11 col-11" style={{ marginLeft: "1.2rem", }}>
                            Paid By  {key}  :
                          </div>


                          <div className="col-md-1 col-sm-1 col-1" style={{ marginLeft: "-1.4rem", }}>
                            {value}
                          </div>
                        </div>

                      </>

                    );
                    // mappedString += `Paid By ${key}  :  ${value}\n`;
                  }
                }






              })}


              <div className="row">
                <div className="col-md-11 col-sm-11 col-11" style={{ marginLeft: "1.2rem", }}>
                  Change &nbsp;الباقى :
                </div>


                <div className="col-md-1 col-sm-1 col-1" style={{ marginLeft: "-1.4rem", }}>
                  {selectedRow?.change_amount || 0}
                </div>
              </div>

              {selectedRow?.refund?.refund_amount>0 && (
                      <div className="row">
                        <div
                          className="col-md-11 col-sm-11 col-11"
                          style={{ marginLeft: "1.2rem" }}
                        >
                          Refund:
                        </div>

                        <div
                          className="col-md-1 col-sm-1 col-1"
                          style={{ marginLeft: "-1.4rem" }}
                        >
                          {selectedRow?.refund?.refund_amount}
                        </div>
                      </div>
                  )}


              <div className="row">
                <div className="col-md-11 col-sm-11 col-11" style={{ marginLeft: "1.2rem", }}>
                  Paid Amount &nbsp;:
                </div>


                <div className="col-md-1 col-sm-1 col-1" style={{ marginLeft: "-1.4rem", }}>
                  {selectedRow?.paid_amount || selectedRow?.final_amount}
                </div>
              </div>




              {/* {selectedRow?.bill_split_amount !== 0 && (
                <>
                  <div className="row">
                    <div className="col-sm-11 col-md-11" style={{ marginLeft: "1.4rem", }}>
                     
                        Split Amount :
                  
                    </div>
                    
                <div className="col-md-1 col-sm-1" style={{ marginLeft: "-1.4rem", }}>
                  {selectedRow?.bill_split_amount }
                </div>
                  </div>


                  <div className="row">
                    <div className="col-md-11 col-sm-11" style={{ marginLeft: "1.4rem", }}>
                      
                        {props.t("Split Between")} :
            
                      
                    </div>
                    <div className="col-md-1 col-sm-1" style={{ marginLeft: "-1.4rem", }}>
                  {selectedRow?.bill_split_between }
                </div>
                  </div>

                </>
              )} */}

            </div>

            <div className="row text-center">
              <div className="col col-lg-12 col-12">
                {props.t("Thank you")} <br />
                {props.t("Visit Again")}
              </div>
            </div>


          </div>
        </div>
        </strong>
      </div>
      <Row className="d-print-none col text-right pr-5 mt-5">
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <Link to="#" onClick={() => {
            printInvoice()
              // localStorage.removeItem("invoiceData")
          }} className="btn btn-primary">
            <i className="fa fa-print" /> {props.t("Print")}
          </Link>
        </Col>
      </Row>
      {/* </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(pkgInvoice)))
