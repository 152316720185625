import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import logo from "../../assets/images/Logo.png"
import { Link } from "react-router-dom"
import { formatDate } from "@fullcalendar/core"
import Loader from "../../helpers/Loader"
import BackgroundImage from "../../assets/images/Background.png"
import moment from "moment"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
const AllItemOrders = props => {

  const editorRef = useRef()
  const { SearchBar } = Search

  const refContainer = useRef(null)
  const [isSquareImg, setIsSquareImage] = useState(false)
  const [allOrders, setAllOrders] = useState([])
  const [selectedRow, setselected_Row] = useState()
  const [paymentDetails, setPaymentDetails] = useState()
  const [document_file, set_document_file] = useState()
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [item_Details, setItemDetails] = useState();
  const [printModal, setPrintModal] = useState()
  const [minDateVal, setMinDateVal] = useState("");
  const [fromdate, setFromdate] = useState(new Date())
  const [todate, setTodate] = useState(new Date())
  
  useEffect(()=>{
    handleValidSubmit();
},[])

  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    printWindow.document.write(`
   
       <html>
       <title>${divName}</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
        
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
        </style>
       </head>
    <body>
  ${content.innerHTML}
    </body>
       </html>

    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.focus();
  };

  // useEffect(() => {
  //   loadAllOrdres();
  // }, [])



  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: item_Details && item_Details.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  //load All Coupons

  const loadAllOrdres = async (payload) => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "sales/getall_item_sales"
    const response = await postSubmitForm(url, payload)
    if (response && response.status === 1) {
      setAllOrders(prev => prev = response.data)
      Loader(false)
      // console.log(response.data)
    } else {
      Loader(false)
      showNotification(response.message, "Error")
    }
  }

  const handleValidSubmit = async () => {
    const object = {
        date_from: moment(fromdate).format("YYYY-MM-DD"),
        date_to: moment(todate).format("YYYY-MM-DD")
    }
    loadAllOrdres(object);
}

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];
    set_document_file(file);
  };

  const [editModal, setEditModal] = useState()

  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-file font-size-15"
          title="View Items"
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => {
            setEditModal(!editModal)
            setItemDetails(row.item_details)
          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}

      </span>
    )
  }


  function invoiceFormatter(cell, row) {
    return (
      <i
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => {
          setPrintModal(prev => prev = !prev)
          setselected_Row(row)
          setPaymentDetails(row.payment_details)
        }}
      >
        {row.invoice_no}
      </i>
    );
  }
  function customerFormatter(cell, row) {
    return (
      <>
        {row.customer_details?.name}
        <br />
        {"(" + row.customer_details?.mobile + ")"}
      </>

    );
  }


  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.document_file || row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      formatter: (cell, row) => {
        return moment(row.createdAt).format("YYYY-MM-DD HH:mm")
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      text: props.t("Cashier"),
      dataField: "emp_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Invoice"),
      dataField: "invoice_no",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      formatter: invoiceFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Customer"),
      dataField: "customer_details.name",
      formatter: customerFormatter,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" }
      },
    },
    {
      text: props.t("Type"),
      dataField: "customer_details.customer_type",
      filter: selectFilter({
        options: [
          { value: "Regular", label: "Regular" },
          { value: "VIP", label: "VIP" },
          { value: "Friend", label: "Friend" },
        ],
        placeholder: "ALL",
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
    {
      text: props.t("Total Amount"),
      dataField: "total_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      dataField: "discount_amount",
      text: props.t("Discount Amount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      // headerAlign: (column, colIndex) => "right",
      // align: (cell, row, rowIndex, colIndex) => {
      //   return "right";
      // },
    },
    {
      text: props.t("Paid Amount"),
      dataField: "final_amount",
      sort: true,
      formatter: (cell, row) => {
        return row.final_amount || row.item_total_price
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Change"),
      dataField: "change_amount",
      sort: true,
      formatter: (cell, row) => {
        return row.change_amount.toFixed(2)
      },
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Refund"),
      dataField: "refund.refund_amount",
      sort: true,
      formatter: (cell, row) => {
        return row.refund?.refund_amount
      },
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    // {
    //   text: props.t("Remarks"),
    //   dataField: "remarks",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },

    {
      dataField: "",
      text: props.t("View Details"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]

  const PackageItemDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Item"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Section"),
      dataField: "section",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "quantity.in_unit",
      text: "Quantity",
      formatter: (cell, row) => {
        // if (row.quantity?.in_unit > 0 || row.quantiy?.in_sub_unit > 0) {
          return (
            <>
              {row.quantity?.in_unit +"  " +row.unit +" " +
                (row.sub_unit && row.sub_unit != "undefined" && row.quantity.in_sub_unit > 0
                  ? row.quantity.in_sub_unit + " " + row.sub_unit
                  : "")}
            </>
          )
        // } else {
        //   return "N/A"
        // }

      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
    {
      text: props.t("Selling Price"),
      dataField: "price",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub Total"),
      dataField: "sub_total",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]
  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

  };

  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem1="All Sold Items" title="Items" alternateTitle="All Sold Items" />
          <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row >
                                            <Col md={3}>
                                                <label className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={fromdate}
                                                    options={{

                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {

                                                        // if (minDateOnChangeActive === true) {


                                                        const dateString = v;


                                                        const date = new Date(dateString);
                                                        setFromdate(date)
                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate =` ${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        setMinDateVal(formattedDate);
                                                        // setDisabledStatus(false)
                                                        //   setMaxDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    name="from_date"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col md={3}>

                                                <label className="form-label">
                                                    To Date
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {

                                                        // if (maxDateOnChangeActive === true) {


                                                        const dateString = v;


                                                        const date = new Date(dateString);
                                                        setTodate(date)
                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate =` ${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        // setMaxDateVal(formattedDate)

                                                        //   setMinDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}

                                                    name="to_date"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />


                                            </Col>

                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "128px",
                                                        marginTop: "1rem",
                                                        // margin: "auto",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody

                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Sold Items ")}
                      </Col>
                    </Row>
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                    {props.t("View/Update all Methods")}
                  </CardSubtitle> */}
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allOrders && allOrders}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                          defaultSorted={ defaultSorted } 
                        />
                      </div>
                    )}
                  </ToolkitProvider>


                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
          >
            <ModalHeader toggle={() => setEditModal(!editModal)}>
              {props.t(" Item Details ")}
              {/* {item_Details && item_Details.oredr_id} */}
            </ModalHeader>
            <ModalBody>
              {item_Details && (
                <>
                  <Row>
                    <Col xlg={12}>
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="_id"
                            columns={PackageItemDetails}
                            data={item_Details && item_Details}
                            search

                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable

                                  {...propst.baseProps}
                                  filter={filterFactory()}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>

          {/* Print Receipt */}
          <Row>
            <Col>
              <Modal
                size="xl"
                isOpen={printModal}
                toggle={() => setPrintModal(prev => prev = !prev)}
              >
                <ModalHeader toggle={() => setPrintModal(prev => prev = !prev)} >
                </ModalHeader >
                <ModalBody>
                  <div id="printDiv">
                    {/* {selectedRow && ( */}
                    <div className="container pl-4  pr-0">
                      <div className="row head" style={{ backgroundColor: " #f0f0f0", height: "100px", borderRadius: "50px 0px 0px 50px" }}>
                        <div className="col col-md-2 col-sm-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                          <img src={logo} style={{ height: "105px", borderRadius: " 50%", width: "110px", backgroundColor: "#000000" }} />
                        </div>
                        <div className="col col-md-10 col-sm-10 pt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                          <p className="" style={{ color: "#000000", fontWeight: "500" }}>
                            {selectedRow?.invoice_no} فاتورة رقم<br />
                            {/* PO Id : {selectedRow.po_id}<br /> */}
                            <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{moment(selectedRow?.createdAt).format("YYYY-MM-DD HH:mm")} [{selectedRow?.emp_details?.name} ]</span>
                            {/* <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}></span> */}
                          </p>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="row ">
                        <h5> Billed To</h5>
                      </div>
                      <p className="mb-0 date">
                        <span style={{ fontWeight: 500 }}>
                          Name: &nbsp;
                          <b> {selectedRow?.customer_details.name}</b>
                        </span>
                      </p>
                      <p className="mb-0">
                        <span style={{ fontWeight: 500 }}>Phone:</span> &nbsp;
                        <b>{selectedRow?.customer_details.mobile}</b>
                      </p>
                      <p className="mb-0">
                        <span style={{ fontWeight: 500 }}>Type: </span> &nbsp;
                        <b> {selectedRow?.customer_details.customer_type}</b>
                      </p>
                      <hr />
                      <div className="row ">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">

                            {/* Foods */}
                            {selectedRow?.item_details?.length > 0 && selectedRow?.item_details?.some(item => item.section !== "Accessories") && (
                              <>
                                <div className="table-responsive">
                                  <h5><b>{props.t("Foods ")}</b></h5>
                                  <table id="example" className="table table-bordered"
                                    style={{ width: "100%" }}   >
                                    <thead className="bg-light"  >
                                      <tr>
                                        <th>  {props.t("S.N")}</th>
                                        <th> {props.t("Description")}</th>
                                        <th> {props.t("Qty.")}</th>
                                        <th> {props.t("Price")}</th>
                                        <th> {props.t("Sub Total")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedRow?.item_details?.filter(item => item.section !== "Accessories").map((item, index) =>
                                        <tr key={index + 1} >
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          <td>
                                            {item.quantity.in_unit > 0 && item.quantity.in_unit} {item.quantity.in_unit > 0 && item.unit} &nbsp;
                                            {item.quantity.in_sub_unit > 0 && item.quantity.in_sub_unit} {item.quantity.in_sub_unit > 0 && item.sub_unit}
                                          </td>
                                          {/* <td>{item.quantity.in_sub_unit} {item.sub_unit}</td> */}
                                          <td>{item.price}</td>
                                          <td>{item.sub_total}</td>
                                        </tr>)}
                                      <tr>
                                        <td colSpan={4} style={{ textAlign: "end", fontWeight: "bold" }} >{props.t("Foods Total")} </td>
                                        <td style={{ fontWeight: "bold" }}>{selectedRow?.item_details?.filter(item => item.section !== "Accessories").reduce((acc, curr) => acc + curr.sub_total, 0)}</td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}

                            {/* Accessories */}
                            {selectedRow?.item_details?.length > 0 && selectedRow?.item_details?.some(item => item.section === "Accessories") && (
                              <>
                                <div className="table-responsive">
                                  <h5><b>{props.t("Accessories ")}</b></h5>
                                  <table id="example"
                                    className="table table-bordered"
                                    style={{ width: "100%" }}   >
                                    <thead className="bg-light">
                                      <tr>
                                        <th> {props.t("S.N")}</th>
                                        <th> {props.t("Description")}</th>
                                        <th> {props.t("Section")}</th>
                                        <th> {props.t("Qty.")}</th>
                                        <th> {props.t("Price")}</th>
                                        <th> {props.t("Sub Total")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedRow?.item_details?.filter(item => item.section === "Accessories").map((item, index) =>
                                        <tr key={index + 1} >
                                          <td>{index + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item.section}</td>
                                          <td>
                                            {item.quantity.in_unit > 0 && item.quantity.in_unit} {item.quantity.in_unit > 0 && item.unit}
                                            {item.quantity.in_sub_unit > 0 && item.quantity.in_sub_unit} {item.quantity.in_sub_unit > 0 && item.sub_unit}
                                          </td>
                                          {/* <td>{item.quantity.in_sub_unit} {item.sub_unit}</td> */}
                                          <td>{item.price}</td>
                                          <td>{item.sub_total}</td>
                                        </tr>)}
                                      <tr>
                                        <td colSpan={5} style={{ textAlign: "end", fontWeight: "bold" }} >{props.t("Accessories Total")} </td>
                                        <td style={{ fontWeight: "bold" }}>{selectedRow?.item_details?.filter(item => item.section === "Accessories").reduce((acc, curr) => acc + curr.sub_total, 0)}</td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}

                          </div>
                          <hr />


                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Total Amount &nbsp; :&nbsp;
                                  {selectedRow?.total_amount || 0}
                                </span>
                              </p>
                            </div>
                          </div>

                          {selectedRow?.discount_amount !== 0 && (
                            <div className="row">
                              <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <p className="">
                                  <span style={{ fontWeight: 500 }}>
                                    {props.t("Discount Amount")} &nbsp; :&nbsp;
                                    {selectedRow?.discount_amount || 0}
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  {props.t("Paid Amount")} &nbsp; :&nbsp;
                                  {selectedRow?.paid_amount || selectedRow?.item_total_amount || selectedRow?.final_amount}
                                  {/* </b> */}
                                </span>
                              </p>
                            </div>
                          </div>


                          {paymentDetails?.map((payment, index) => {
                            const { _id, ...paymentDetailsWithoutId } = payment;

                            let mappedString = '';
                            for (let key in paymentDetailsWithoutId) {
                              let value = paymentDetailsWithoutId[key];
                              mappedString += `Paid By ${key}  :  ${value}\n`;
                            }


                            return (
                              // <div style={{display:"flex",flexDirection:"column", gap:"6px"}} key={index}>
                              <div key={index} className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <p className="">
                                  <span style={{ fontWeight: 500 }}>

                                    {mappedString}

                                  </span>
                                </p>
                              </div>
                              // </div>
                            );
                          })}

                          

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Change &nbsp; :&nbsp;
                                  {selectedRow?.change_amount || 0}
                                  {/* </b> */}
                                </span>
                              </p>
                            </div>
                          </div>
                          {selectedRow?.refund?.refund_amount > 0 && (
                           <div className="row">
                           <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                             <p className="">
                               <span style={{ fontWeight: 500 }}>
                               Refunded Amount &nbsp; :&nbsp;
                                 {selectedRow?.refund?.refund_amount}
                                 {/* </b> */}
                               </span>
                             </p>
                           </div>
                         </div>
                         )}

                         {selectedRow?.difference_amount > 0 && (
                           <div className="row">
                           <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                             <p className="">
                               <span style={{ fontWeight: 500 }}>
                                 Extra Income &nbsp; :&nbsp;
                                 {selectedRow?.difference_amount}
                                 {/* </b> */}
                               </span>
                             </p>
                           </div>
                         </div>
                         )}

                          <div className="row mb-5" style={{ display: "flex", justifyContent: "flex-end" }}>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}


                  </div>
                  <Row className="col text-right pr-5">
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      {/* <Link to="#" onClick={() => { printDiv("printDiv"); }} className="btn btn-primary">
                        <i className="fa fa-print" /> Print
                      </Link> */}
                      <a
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          marginBottom: "10px",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "190px",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}

                        className="btn btn-primary"
                        href={
                          "/invoice_details?booking_id=" + selectedRow?.booking_id
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        {props.t("Print")}
                      </a>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AllItemOrders)))
