import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import swal from "sweetalert2";
import React from "react"
// Login Redux States
import { CHANGE_PASSWORD, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, changePasswordSuccess, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { postSubmitForm, postSubmitFormNoAuth } from "../../../helpers/backend_helper"

import Dashboard from "../../../pages/Dashboard/index";
import Employee_Dashboard from "../../../pages/Employee_Dashboard/Employee_Dashboard";


// function for show notification popup window
function showNotification(message, type) {
  if (type === "Success") swal.fire(type, message, "success");
  else swal.fire(type, message, "error");
}

function* loginUser({ payload: { user, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/login"
    console.log("url", url)
    const response = yield call(postSubmitFormNoAuth, url, user)
    // console.log("responce from saga", response)
    if (response.status === 1) {
     // showNotification("Login successfully.", "Success")
      let routes = []
      const role = response.data.role

      const capitalizeFirstLetter = string => {
        let arr = string.split(" ")
        let caparr = arr.map(ele => ele.charAt(0).toUpperCase() + ele.slice(1))
        return caparr
      }
      let returned = capitalizeFirstLetter(role)
      let cap_role = returned.join(" ")

      if (cap_role === "Admin") {
        routes = [{ path: "/dashboard", component: <Dashboard /> }]
       }else if (role === "Manager") {
        routes = [{ path: "/dashboard", component: <Dashboard /> }]
      } else if (role === "Super User") {
        routes = [{ path: "/dashboard", component: <Dashboard /> }]
      } else if (role === "Owner") {
        routes = [{ path: "/dashboard", component: <Dashboard /> }]
      } else if (role === "Cashier") {
        routes = [{ path: "/employee-dashboard", component: <Employee_Dashboard /> }]
      } else if (role === "Accountant") {
        routes = [{ path: "/employee-dashboard", component: <Employee_Dashboard /> }]
      } else if (role === "Store Keeper") {
        routes = [{ path: "/employee-dashboard", component: <Employee_Dashboard /> }]
      } else if (role === "Cafeteria") {
        routes = [{ path: "/employee-dashboard", component: <Employee_Dashboard /> }]
      } else if (role === "Kitchen") {
        routes = [{ path: "/employee-dashboard", component: <Employee_Dashboard /> }]
      } else if (role === "Assistant") {
        routes = [{ path: "/employee-dashboard", component: <Employee_Dashboard /> }]
      } else if (role === "Manager") {
        routes = [{ path: "/dashboard", component: <Dashboard /> }]
      } 
      response.routes = routes;

      localStorage.setItem("username", response.data.username)
      localStorage.setItem("name", response.data.name)
      localStorage.setItem("mobile", response.data.mobile)
      localStorage.setItem("employee_id", response.data.employee_id)
      localStorage.setItem("role", response.data.role)
      localStorage.setItem("code", response.data.code)
      localStorage.setItem("userToken", response.data.token)
      yield put(loginSuccess(response));
      history(response.routes[0].path);
    } else {
      showNotification(response.message, "Error");
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* logoutUser({ payload: { history } }) {
//   try {
//     let url = process.env.REACT_APP_BASEURL + "adminusers/logout";
//     const token = localStorage.getItem("userToken");
//     if (token) {
//       const response = yield call(postSubmitForm, url, {});
//       if (response.status === 1) {
//         localStorage.clear();
//         localStorage.removeItem("userToken")
//         localStorage.removeItem("username")
//         localStorage.removeItem("name")
//         localStorage.removeItem("employee_id")
//         localStorage.removeItem("role")
//         localStorage.removeItem("mobile")
//         history("/login");
//     }   
//   }
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

function* changePassword({ payload: { password_details, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "adminusers/changepassword";

    const response = yield call(postSubmitForm, url, password_details);

    if (response.status === 1) {
      showNotification("Password changed successfully.", "Success");
      yield put(changePasswordSuccess(response));
    } else {
      showNotification(response.message, "Error");
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

//Logout
function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("userToken");
    localStorage.removeItem("username")
    localStorage.removeItem("name")
    localStorage.removeItem("employee_id")
    localStorage.removeItem("role")
    localStorage.removeItem("mobile")

    if (process.env.REACT_APP_BASEURL === "adminusers/logout") {
      const response = yield call(postSubmitForm.logout);
      yield put(logoutUserSuccess(response));
    }
    //console.log("history",history)
    history("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* logoutUser({ payload: { history } }) {
//   try {
//     localStorage.removeItem("authUser");

//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(fireBaseBackend.logout);
//       yield put(logoutUserSuccess(response));
//     }
//     history('/login');
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }


// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend();
//       const response = yield call(
//         fireBaseBackend.socialLoginUser,
//         data,
//         type,
//       );
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     } else {
//       const response = yield call(postSocialLogin, data);
//       localStorage.setItem("authUser", JSON.stringify(response));
//       yield put(loginSuccess(response));
//     }
//     history("/dashboard");
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(CHANGE_PASSWORD,changePassword);
}

export default authSaga;
