import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import cellEditFactory from "react-bootstrap-table2-editor"
import swal from "sweetalert2"
import axios from "axios"
import preventValueChangeOnScroll from "../../helpers/PreventValueOnScroll"
import BackgroundImage from "../../assets/images/Background.png"
import SelectPhotoFormatter from "../../helpers/SelectImageFormatter"
import preloader from "../../helpers/Loader"

const BuyItems = props => {
  document.title = "Sell Item"

  const refContainer = useRef(null)
  const { SearchBar } = Search
  const [selected_Customer, setSelected_Customer] = useState([])
  const [changeAmount, setChangeAmount] = useState(0);
  const [all_Items, setAll_Items] = useState([])
  const [selected_item, setSelected_item] = useState([])
  const [selectedItemPrice, setSelectedItemPrice] = useState(0)
  const [grandTotal, setGrandTotal] = useState("0")
  const [remarks, setRemarks] = useState()
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [postdiscountAmount, setPostdiscountAmount] = useState(0)
  const [discountInput, setDiscountInput] = useState("")
  const [discountTracker, setDiscountTracker] = useState(false)
  const [final_amount, setFinal_Amount] = useState()
  const [paid_by_wallet, setPaid_by_wallet] = useState("0")
  const [addCustomerModal, setAddCustomerModal] = useState(false)
  // Payment Methods
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
  const [formFieldValues, setFormFieldValues] = useState({})
  const [paymentDetails, setPaymentDetails] = useState([])
  //Package Validation
  const [packageValidation, setPackageValidation] = useState(true)
  const [remaining, setRemaining] = useState(0)
  const [branches, setBranches] = useState([])



  useEffect(() => {
    loadAllItems()
    loadAllBranches()
    loadAllCustomers()
    fetchPaymentMethods()
  }, [])

  useEffect(() => {
    const newGrandTotal = selectedItemPrice
    setGrandTotal(Math.round(newGrandTotal))
    setRemaining(Math.round(newGrandTotal))
  }, [selectedItemPrice])


  // insert Customer
  const handleAddCustomer = async (e, v) => {
    try {
      const object = {
        name: v.name,
        mobile: v.mobile,
        customer_type: v.customer_type || "Regular",
      }
      // console.log(object);
      let url = process.env.REACT_APP_BASEURL + "customers/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          refContainer.current.reset()
          showNotification(res.message, "Success")
          setAddCustomerModal(false)
          loadAllCustomers()
        } else {
          showNotification(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(error.message, "Error")
      console.log(error)
    }
  }

  const applyDiscount = async () => {
    if (!discountInput || typeof discountInput !== "string") {
      swal.fire({
        title: "Invalid Coupon Code",
        text: "Please enter a valid coupon code.",
        icon: "error",
      })
      return
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "coupons/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )

      if (response.data.status === 1) {
        const enteredCoupon = response.data.data.find(
          coupon => coupon.coupon_code === discountInput
        )

        if (enteredCoupon) {
          const couponDiscount = enteredCoupon.discount_percent
          setDiscountPercentage(couponDiscount)
          const discountedTotal = grandTotal * (1 - couponDiscount / 100)
          const discountAmount = (grandTotal - discountedTotal)
          setPostdiscountAmount(Math.round(discountAmount))
          setFinal_Amount(Math.round(discountedTotal))
          setRemaining(Math.round(discountedTotal))
          setDiscountTracker(true)

          swal.fire({
            title: "Coupon Applied",
            text: `Coupon "${discountInput}" applied successfully for ${couponDiscount}% discount.`,
            icon: "success",
          })
        } else {
          swal.fire({
            title: "Coupon Not Found",
            text: `Coupon "${discountInput}" is not valid.`,
            icon: "error",
          })
        }
      } else {
        swal.fire("Error", response.data.message, "error")
      }
    } catch (error) {
      if (error.response) {
        swal.fire("Error", error.response.data.message, "error")
      } else {
        swal.fire(
          "Error",
          "An error occurred while applying the coupons.",
          "error"
        )
      }
    }
  }
  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      setBranches(response.data)
    } else {
      console.log("Error")
    }
  }

  //Items
  const loadAllItems = async () => {
    // preloader(true)
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_items"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      const filterData = response.data.filter(
        item => (item.is_active === true) && (item.is_menu_item === true || item.is_household_item === true)
      )
      filterData.map(item => {
        item.label = (
          <>
            {SelectPhotoFormatter(item)}
            {/* {item.name} */}
         { (item.available.in_unit === 0 && item.available.in_sub_unit === 0 && item.is_household_item!==true)  ? `${item.name}  [${item.section}] [Out of Stock]` : `${item.name}-->[${item.section}] `}

          </>
        )
        item.value = item.name
      })
      setAll_Items(filterData || [])
    } else {
      // preloader(false)
      setAll_Items([])
      console.log("Error")
    }
  }

  //Payment Method
  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASEURL + "payment_methods/getall",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      )

      if (response.data.status === 1) {
        if (selected_Customer?.is_wallet_eligible === false) {
          const active = response.data.data.filter(
            e => e.name !== "Wallet" && e.is_active === true
          )
          active.sort((a, b) => a.name.localeCompare(b.name));
          setPaymentMethods(active)
        } else {
          const active = response.data.data.filter(e => e.is_active === true)
          active.sort((a, b) => a.name.localeCompare(b.name));
          setPaymentMethods(active)
        }
      } else {
      }
    } catch (error) {}
  }


  useEffect(() => {
    fetchPaymentMethods()
    setSelectedPaymentMethods([])
    refContainer.current.reset()
    setFormFieldValues({})
    setPaymentDetails([])
  }, [selected_Customer])

  //Handle Pya,ment Methods
  const handlePaymentMethodChange = selectedOptions => {
    const newPaymentDetails = selectedOptions.map(selectedOption => {
      const fieldName = `paymentMethod_${selectedOption.value}`
      const existingDetail = paymentDetails.find(
        detail => detail._id === selectedOption.value
      )
      return {
        _id: selectedOption.value,
        [selectedOption.label]: existingDetail
          ? existingDetail[selectedOption.label]
          : "",
      }
    })

    setPaymentDetails(newPaymentDetails)

    const totalPaidAmount = newPaymentDetails.reduce((total, paymentMethod) => {
      for (const key in paymentMethod) {
        if (key !== "_id") {
          total += parseFloat(paymentMethod[key]) || 0
        }
      }
      return total
    }, 0)
let Total = final_amount ? final_amount : grandTotal;
    const newRemainingPaidAmount =
      Total - totalPaidAmount - parseFloat(paid_by_wallet)
    setRemaining(newRemainingPaidAmount.toFixed(2))

    const unselectedOptions = selectedPaymentMethods.filter(
      option => !selectedOptions.includes(option)
    )
    unselectedOptions.forEach(unselectedOption => {
      const fieldName = `paymentMethod_${unselectedOption.value}`
      setFormFieldValues(prevValues => ({
        ...prevValues,
        [fieldName]: "",
      }))
    })

    setSelectedPaymentMethods(selectedOptions)
  }

  const calculateFinalPaidAmount = () => {
    let totalPaidAmount = 0
    selectedPaymentMethods.forEach(selectedOption => {
      const fieldName = `paymentMethod_${selectedOption.value}`
      const value = parseFloat(formFieldValues[fieldName]) || 0
      totalPaidAmount += Math.ceil(value)
    })
    const walletAmount = parseFloat(paid_by_wallet) || 0
    totalPaidAmount += walletAmount
    return Math.round(totalPaidAmount)
  }

  //load All Customers
  const [allCustomer, setAllCustomer] = useState([])
  const loadAllCustomers = async () => {
    let url = process.env.REACT_APP_BASEURL + "customers/getall"
    let response = await postSubmitForm(url, "")

    if (response.status === 1) {
      response.data.map(customer => {
        ;(customer.label = `${customer.name} [${customer.mobile}] [${customer.customer_type}]`),
          (customer.value = customer._id)
      })
      const active = response.data.filter(cust => cust.is_active === true)
      setAllCustomer(active)

      // console.log(response.data)
    } else {
      showNotification(response.message, "Error")
    }
  }

  let totalPaid = paymentDetails.reduce((total, paymentMethod) => {
    for (const key in paymentMethod) {
      if (key !== "_id") {
        total += parseFloat(paymentMethod[key]) || 0
      }
    }
    return Math.round(total)
  }, 0)

  function clearStates (){
    setSelected_item([])
    setChangeAmount(0)
    setFinalChange(0)
    setIs_Refund(false)
    setRefundAmount(0)
    setRefundRemarks("")
    setRefundType("")
    setIsNoChange(false)
    setSelectedItemPrice(0)
    handleItemSelect([])
    setSelected_Customer()
    loadAllItems()
    setPackageValidation(true)
    setPaymentDetails(prev=>prev=[])
    setFinal_Amount(prev => prev = 0)
    setDiscountInput(prev => prev = "")
    totalPaid = 0
    setGrandTotal((prev) => (prev = 0))
    setDiscountPercentage(0)
    setPostdiscountAmount(0)
    setSelectedPaymentMethods([])
    setFormFieldValues({})
    setGrandTotal("0")
    setDiscountTracker()
    setRemaining(0)
    refContainer.current.reset()
  }
  const [isNoChange, setIsNoChange] = useState(false)
  const [is_Refund, setIs_Refund] = useState(false)
  const [refundAmount, setRefundAmount] = useState(0)
  const [refundRemarks, setRefundRemarks] = useState("")
  const [refundType, setRefundType] = useState("")
  const handleValidSubmit = async (e, v) => {
  const invalidItems = selected_item.filter((item) => item.sub_total <= 0);
  if (invalidItems.length > 0) {
    const itemNames = invalidItems.map((item) => item.name).join(", ");
    showWarning(`Please ensure that the sub-total of the following items is greater than zero: ${itemNames}.`,"Warning");  return false; 
  }

      preloader(true)
      let url = process.env.REACT_APP_BASEURL + "bookings/insert"
      let payload1 = {
        is_item_only: true,
        total_price:
          selected_Customer?.customer_type === "VIP"
            ? 0
            : Math.round(selectedItemPrice) || 0,
        item_details: selected_item.map(item => {
          return {
            item_id: item._id,
            image_url: item.image_url,
            name: item.name,
            section: item.section,
            unit: item.unit,
            sub_unit: item.sub_unit,
            expiry_date: item.expiry_date,
            formula: item.formula,
            category: item.category,
            threshold: item.threshold,
            price: item.selling_price,
            sub_total: selected_Customer?.customer_type !=="VIP" ? item.sub_total : 0,
            quantity: {
              in_unit: item.quantity.in_unit,
              in_sub_unit: item.quantity.in_sub_unit,
            },
            available: {
              in_unit: item.available.in_unit,
              in_sub_unit: item.available.in_sub_unit,
            },
            new_available: (() => {
              const formula = item.formula || 1
              const availableInSubUnits = item.available.in_unit * formula + item.available.in_sub_unit
              const quantityInSubUnits = item.quantity.in_unit * formula + item.quantity.in_sub_unit
              const newAvailableInSubUnits = availableInSubUnits - quantityInSubUnits
              const newAvailableInUnits = Math.floor(newAvailableInSubUnits / formula)
              const newAvailableSubUnits = newAvailableInSubUnits % formula
              return {
                in_unit: !item.is_household_item ?  newAvailableInUnits : 0, 
                in_sub_unit: !item.is_household_item ? newAvailableSubUnits : 0,
              }
            })(),
          }
        }),
        package_details: [],
        game_details: [],
        customer_details: {
          _id: selected_Customer._id,
          name: selected_Customer.name,
          mobile: selected_Customer.mobile,
          customer_type: selected_Customer.customer_type,
          is_wallet_eligible: selected_Customer.is_wallet_eligible,
          wallet: selected_Customer?.wallet,
          address: selected_Customer.address,
        },
        branch_details: {
          branch_id: branches[0]?._id,
          is_active: branches[0]?.is_active,
          name: branches[0]?.name,
          address: branches[0]?.address,
          email: branches[0]?.email,
          mobile: branches[0]?.mobile,
        },
      }
      // return
      postSubmitForm(url, payload1)
        .then(response => {
          if (response.status === 1 && response.data) {
            let id = response.data._id
            url = process.env.REACT_APP_BASEURL + "sales/check_out"
            Object.assign(payload1,{
                  booking_id: id,
                  remarks: remarks,
                  item_total_price:selected_Customer?.customer_type === "VIP"? 0
                    : Math.round(selectedItemPrice) || 0,
                  difference:selected_Customer?.customer_type === "VIP" ? 0 : +(grandTotal - selectedItemPrice).toFixed(2),
                  paid_amount: selected_Customer?.customer_type === "VIP" ? 0 : Math.round(final_amount) ? Math.round(final_amount):  Math.round(grandTotal),
                  discount:selected_Customer?.customer_type === "VIP" ? 0 : +discountPercentage || 0,
                  discount_amount:selected_Customer?.customer_type === "VIP" ? 0 : +postdiscountAmount.toFixed(2) ||0,
                  payment_details:selected_Customer?.customer_type === "VIP" ? [] : paymentDetails,
                  total_amount:selected_Customer?.customer_type === "VIP" ? 0 : Math.round(grandTotal) || 0,
                  // final_amount: selected_Customer?.customer_type === "VIP" ? 0 : Math.round(totalPaid) || 0,
                  final_amount: selected_Customer?.customer_type === "VIP" ? 0 : final_amount ? Math.round(final_amount):  Math.round(grandTotal),
              change_amount: selected_Customer?.customer_type === "VIP" ? 0 : !isNoChange ? finalChange :0 ,
              difference_amount:selected_Customer?.customer_type === "VIP" ? 0 : isNoChange ? finalChange:0 ,

            })
            // console.log(payload1,"pl");return
            postSubmitForm(url, payload1).then(response => {
              const responseData = response.data
              if(response.status===1){
                const invoiceUrl = `${window.location.origin}/invoice_details?booking_id=` + responseData?.booking_id
                const newTab = window.open(`${invoiceUrl}`, "_blank")
                if (newTab) {
                  newTab.onload = () => {
                    newTab.postMessage(responseData, window.location.origin);
                  };
                  newTab.focus()
                }
                preloader(false)
               clearStates()
                showNotification("Item sold Successfull", "Success")
                window.location.reload()
              }else{
                showNotification(response.message,"Error")
              }
            })
          } else {
            preloader(false)
            showNotification(response.message, "Error")
          }
        })
        .catch(err => {
          preloader(false)
          console.log(err)
          // console.log(err.response.request.response.message, "Error")
        })    
  }

  
  const handleSubTotal = selected => {
    const itemTotal = selected.reduce(
      (total, item) => total + item.sub_total,
      0
    )
    setSelectedItemPrice(itemTotal)
  }

  const handleItemSelect = (selected) => {
    const updatedSelected = selected.map((item) => {
      const existingItem = selected_item.find((i) => i._id === item._id);  
      if (existingItem) {
        return existingItem;
      } else {
        if (item.is_household_item) {
          if (item.sub_unit === "" || item.sub_unit === null) {
            item.quantity.in_unit = 1;
            item.sub_total = item.selling_price * item.quantity.in_unit;
          } else {
            item.quantity.in_sub_unit = 1;
            item.sub_total = item.quantity.in_sub_unit * item.selling_price;
          }
        } else {
          if (item.sub_unit === "" || item.sub_unit === null) {
            if (item.available.in_unit > 0) {
              item.quantity.in_unit = 1;
              item.sub_total = item.selling_price * item.quantity.in_unit;
            } else {
              return item;
            }
          } else {
            if (item.available.in_unit > 0) {
              item.quantity.in_sub_unit = 1;
              item.sub_total = item.quantity.in_sub_unit * item.selling_price;
            } else if (item.available.in_sub_unit > 0) {
              item.quantity.in_sub_unit = 1;
              item.sub_total = item.quantity.in_sub_unit * item.selling_price;
            } else {
              return item;
            }
          }
        }
        return item;
      }
    });
    const itemTotal = updatedSelected.reduce(
      (total, item) => total + item.sub_total,
      0
    );
    setSelectedItemPrice(itemTotal);
    setSelected_item(updatedSelected);
  };
  
  const handleQuantityChange = (e, rowIndex, row, editorProps) => {
    const { value } = e.target
    const updatedData = { ...row }
    const rowData = updatedData
    rowData.quantity.in_unit = +value

    editorProps.onUpdate(+value)
    let a = selected_item

    a.forEach(item => {
      if (item._id === row._id) {
        if (row.sub_unit === "" || row.sub_unit === null) {
          item.sub_total = +value * row.selling_price
        } else {
          item.sub_total =
            (+value * Number(rowData.formula) +
              Number(rowData.quantity.in_sub_unit)) *
            row.selling_price
        }
      }
    })

    setSelected_item(prev => {
      prev = [...a]
      return prev
    })
    handleSubTotal(a)
  }

  const handleSubUnitQuantityChange = (e, rowIndex, row, editorProps) => {
    let { value } = e.target

    if (!row.is_household_item && +value >Number(+row.formula * +row.available.in_unit + +row.available.in_sub_unit)) {
      editorProps.onUpdate(+value)
      return
    }
    let subUnits = 0
    let units = 0
    if (+value >= Number(row.formula)) {
      subUnits = +value % Number(row.formula)
      units = Math.floor(+value / Number(row.formula))
    } else {
      subUnits = +value
    }
    editorProps.onUpdate(subUnits)
    let a = selected_item
    a.forEach(item => {
      if (item._id === row._id) {
        if ( !row.is_household_item && +value >= +row.formula && item.quantity.in_unit + units <= row.available.in_unit) {
          item.quantity.in_unit += units
        }else{
          item.quantity.in_unit += units
        }
        item.sub_total = (subUnits + Number(row.quantity.in_unit) * Number(row.formula)) *row.selling_price
        item.sub_total = +item.sub_total.toFixed(2)
      }
    })

    setSelected_item(prev => {
      prev = [...a]
      return prev
    })
    handleSubTotal(a)
  }

  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: "S.N",
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      editable: false,
    },

    {
      dataField: "image_url",
      text: "Image",
      formatter: showPhotoFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },

    {
      dataField: "name",
      text: "Item Name",
      formatter: (cell, row) => row.name,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },

    {
      dataField: "Section",
      formatter: (cell, row) => row.section,
      text: "Section",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
      editable: false,
    },

    {
      text: props.t("Formula"),
      dataField: "formula",
      sort: false,
      editable: false,
      formatter: (cell, row) => {
        if(row.formula<=1){
          return "N/A"
        }
        return (
          <>
            {row.sub_unit
              ? "1 " + row.unit + " = " + row.formula + " " + row.sub_unit
              : "1 " + row.unit}
          </>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
    },

    {
      dataField: "available.in_unit",
      text: "Available ",
      formatter: (cell, row) => {
        if(row.is_household_item===false || row.is_household_item===undefined){
          return (
            <>
              {row.available?.in_unit +
                "  " +
                row.unit +
                " " +
                (row.sub_unit && row.sub_unit != "undefined"
                  ? row.available.in_sub_unit + " " + row.sub_unit
                  : "")}
            </>
          )
        }else{
          return "N/A"
        }
      
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },

    {
      text: "Qty.(in unit)",
      dataField: "quantity.in_unit",
      formatter: (cell, row) => {
        return (
          <p
            style={{
              color: row.quantity.in_unit === 0 ? "#E57373" : "",
              fontWeight: row.quantity.in_unit === 0 ? 600 : 0,
              // width: "50px",
            }}
          >{`${row.quantity.in_unit} ${row.unit}`}</p>
        )
      },
      headerStyle: (column, colIndex) => {
        return { width: "7%" }
      },
      editable: true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const isHouseholdItem = row.is_household_item === true;

        if (!isHouseholdItem) {
          const availableItems = row.available;
          const isAvailable = availableItems && availableItems.in_unit > 0;
      
          if (isAvailable) {
            return (
              <input
                style={{
                  width: "50px",
                  height: "40px",
                  border: "1px solid lightGray",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                type="number"
                onBlur={(e) => {
                  handleQuantityChange(e, rowIndex, row, editorProps);
                }}
                onFocus={(e) => e.target.select()} // Retain focus on input field
                autoFocus
              />
            );
          } else {
            return <div style={{ color: "red" }}>Not Available</div>;
          }
        } else {
          // For household items, allow editing without availability check
          return (
            <input
              style={{
                width: "50px",
                height: "40px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="number"
              onBlur={(e) => {
                handleQuantityChange(e, rowIndex, row, editorProps);
              }}
              onFocus={(e) => e.target.select()} // Retain focus on input field
              autoFocus
            />
          );
        }
      },
      validator: (newValue, row, column) => {
        const isHouseholdItem = row.is_household_item === true;
        if (!isHouseholdItem) {
          if (+newValue > Number(row.available.in_unit)) {
            return {
              valid: false,
              message: "Cannot exceed available units",
            };
          }
      
          if (+newValue < 0) {
            return {
              valid: false,
              message: "Cannot be a negative value",
            };
          }
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: "Only numbers are allowed.",
            };
          }
          if (newValue % 1 != 0) {
            return {
              valid: false,
              message: "Decimals not allowed.",
            };
          }
        }
        return true;
      },      
    },

    {
      text: "Qty.(in sub-unit)",
      dataField: "quantity.in_sub_unit",
      formatter: (cell, row) => {
        if(row.formula<=1){
          return "N/A"
        }
        return (
          <p
            style={{
              color: row.quantity.in_sub_unit === 0 ? "#E57373" : "",
              fontWeight: row.quantity.in_sub_unit === 0 ? 600 : 0,
              width: "50px",
            }}
          >{`${row.quantity.in_sub_unit} ${row.sub_unit}`}</p>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: (cell, row) =>
        row.sub_unit === "" || row.sub_unit === null ? false : true,
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => {
        const isHouseholdItem = row.is_household_item === true;
        if(!isHouseholdItem){
          const availableItems = row.available
          const isAvailable =  (availableItems && availableItems.in_unit > 0) ||  availableItems.in_sub_unit
          if (isAvailable ) {
            return (
              <input
                style={{
                  width: "50px",
                  height: "40px",
                  border: "1px solid lightGray",
                  padding: "5px",
                  borderRadius: "5px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                }}
                type="number"
                // defaultValue={value}
                onBlur={e => {
                  handleSubUnitQuantityChange(e, rowIndex, row, editorProps)
                }}
                onFocus={e => e.target.select()} // Retain focus on input field
                autoFocus
              />
            )
          } else {
            return <div style={{ color: "red" }}>Not Available</div>
          }
        }else{
          return (
            <input
              style={{
                width: "50px",
                height: "40px",
                border: "1px solid lightGray",
                padding: "5px",
                borderRadius: "5px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              type="number"
              onBlur={e => {
                handleSubUnitQuantityChange(e, rowIndex, row, editorProps)
              }}
              onFocus={e => e.target.select()} // Retain focus on input field
              autoFocus
            />
          )
        }
      
      },
      validator: (newValue, row, column) => {
        const isHouseholdItem = row.is_household_item === true;
       if(!isHouseholdItem){
        if (     +newValue >     Number(       +row.formula * +row.available.in_unit + +row.available.in_sub_unit     )   ) {
          return {
            valid: false,
            message: "Cannot exceed available quantity",
          }
        }
        if (
          +newValue >
          Number(
            (+row.available.in_unit - +row.quantity.in_unit) * +row.formula +
              +row.available.in_sub_unit
          )
        ) {
          return {
            valid: false,
            message: "Cannot exceed more",
          }
        }
        if (+newValue < 0) {
          return {
            valid: false,
            message: "Cannot be a negative value",
          }
        }
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Only numbers are allowed.",
          }
        }
        if (newValue % 1 != 0) {
          return {
            valid: false,
            message: "Decimals not allowed.",
          }
        }
        return true
       }
      },
    },
    {
      text: "Rate",
      dataField: "selling_price",
      formatter: (cell, row) => row.selling_price,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      editable: false,
    },
    {
      dataField: "sub_total",
      formatter: (cell, row) => row.sub_total.toFixed(2),
      text: "Sub Total",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" }
      },
      editable: false,
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  const handleChangeAmount = ()=>{
    const change = Math.abs(
      final_amount
        ? final_amount - totalPaid
        : grandTotal - totalPaid
    )
    let famount = final_amount ? final_amount : grandTotal
    
    useEffect(()=>{
      let changeAfterRefund = 0;
      if(totalPaid > famount){
        setChangeAmount(prev=> prev = change)
        if(refundAmount>changeAmount){
          changeAfterRefund = totalPaidAmount - refund;
          setFinalChange(changeAfterRefund)
        }else{
          setFinalChange((prev)=>prev = change)
        }
      }else{
        // setRefundType("")
        setChangeAmount((prev)=>prev = 0)
        setFinalChange((prev)=>(prev = 0))
      }
    }, [change])
  }

  const [finalChange, setFinalChange] = useState(0)
  const handleRefundAmount = (e) =>{
    let refund = +e.target.value
    setRefundAmount(refund)
    let changeAfterRefund = 0;
    if(refund>changeAmount){
      changeAfterRefund = totalPaid - refund;
      setFinalChange(changeAfterRefund)
    }else {
      changeAfterRefund = changeAmount- refund 
      setFinalChange(changeAfterRefund)
    }
  }

  function handleRefundChecked(e){
    setRefundAmount(0)
    setRefundType("")
    setRefundRemarks("")
    setIs_Refund(e.target.checked)
  }

  function CheckAmounts (e,v){
    const isVipCustomer = selected_Customer?.customer_type === "VIP";
    const isWalletPayment = selectedPaymentMethods[0]?.label === "Wallet";
    const walletBalance = selected_Customer?.wallet||0;
    const requiredAmount = final_amount ? +final_amount : +grandTotal;
    const RemainingCreditLimit = walletBalance <=0 ? 3000 + walletBalance : 0
    const newBalace = walletBalance <=0 ? RemainingCreditLimit : walletBalance+3000
    const walletEntry = paymentDetails.find(entry => 'Wallet' in entry);
    const walletAmount = walletEntry ? walletEntry.Wallet : 0;  
    // return
    if (isVipCustomer) {
      handleValidSubmit(e, v);
    } else if (isWalletPayment) {
      if (walletAmount > newBalace) {
        showWarning("Wallet credit balance limit reached", "warning");
      } else if (totalPaid >= requiredAmount) {
        handleValidSubmit(e, v);
      } else {
        showNotification("Total amount does not match entered amount.", "Error");
      }
    } else {
      if (totalPaid >= requiredAmount) {
        handleValidSubmit(e, v);
      } else {
        showNotification("Total amount does not match entered amount.", "Error");
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Sales"
            breadcrumbItem1="Sell Items"
            alternateTitle="Sell Items"
          />
          <Row>
            <AvForm
              onValidSubmit={(e,v) => {CheckAmounts(e,v)}}
              ref={refContainer}
            >
              <Card>
                <CardBody
                  style={{
                    color: "black",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <Row>

                      <Col lg={4}>
                        {packageValidation ? (
                          <Label style={{ color: "red", marginBottom: "10px" }}>
                            {" "}
                            Please Select Customer
                          </Label>
                        ) : (
                          <Label style={{ color: "white" }}>Customer</Label>
                        )}
                        <Select
                          value={selected_Customer || []}
                          onChange={selected => {
                            setSelected_Customer(selected)
                            setSelectedPaymentMethods([])
                            setPackageValidation(false)
                          }}
                          options={allCustomer}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isClearable
                          //  closeMenuOnSelect={false}
                          // isLoading={selected_Customer?.length===0}
                        />
                      </Col>
                   

                      <Col lg={2}>
                        
                        <Button
                          onClick={() =>
                            setAddCustomerModal(prev => (prev = !prev))
                          }
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "120px",
                            marginTop: "1.7rem",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          <i
                            className="mdi mdi-file-plus"
                            style={{
                              fontSize: "15px",
                              marginRight: "2.5px",
                            }}
                          ></i>
                          {props.t("Add New")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>

                    <Col lg={6}>
                      <Label style={{ color: "white" }}>Items</Label>
                      <Select
                        onChange={selected => {
                          handleItemSelect(selected)
                        }}
                        options={all_Items || []}
                        value={(selected_item && selected_item) || []}
                        isMulti
                        isSearchable
                        isClearable
                        onClear={() => {
                          setSelected_item([])
                        }}
                        isDisabled={packageValidation}
                        closeMenuOnSelect={false}
                        required
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      }}
                    >
                      <CardTitle>
                        <Row>
                          <Col lg={6}>{props.t("Items")}</Col>
                        </Row>
                      </CardTitle>
                      <ToolkitProvider
                        keyField="_id"
                        data={selected_item && selected_item}
                        columns={columns || []}
                        search
                      >
                        {propst => (
                          <div>
                            <AvForm>
                            </AvForm>
                            <BootstrapTable
                              cellEdit={cellEditFactory({
                                mode: "click",
                                blurToSave: true,
                              })}
                              {...propst.baseProps}
                              noDataIndication={props.t("No data to display.")}
                              striped
                              hover
                              condensed
                            />
                          </div>
                        )}
                      </ToolkitProvider>

                      <Row>
                        <Col
                          lg={12}
                          style={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span> Total :</span>
                          <span
                            style={{ color: "black", fontWeight: "bolder" }}
                          >
                            {grandTotal}
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                      }}
                    >
                    
                          <Row>
                            <Col lg={3} md={3}className="mt-4">
                              <Label>Select Payment Method</Label>
                              <span
                                style={{
                                  fontSize: "10px",

                                  visibility: `${
                                    selectedPaymentMethods[0]?.label ===
                                    "Wallet"
                                      ? "unset"
                                      : "hidden"
                                  }`,
                                }}
                                className="text-muted ms-2"
                              >
                                {props.t("Balance")}:{"  "}
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                  }}
                                >
                                  {selected_Customer?.wallet}
                                </span>
                              </span>

                              <Select
                                options={paymentMethods?.map(method => ({
                                  value: method._id,
                                  label: method.name,
                                  image_url: method.image_url,
                                }))}
                                // placeholder={props.t(
                                //   "Select Payment Method"
                                // )}
                                isMulti
                                value={selectedPaymentMethods}
                                onChange={handlePaymentMethodChange}
                                isDisabled={
                                  selected_Customer?.customer_type === "VIP" ||
                                  selected_item?.length <= 0
                                    ? true
                                    : false
                                }
                              />
                            </Col>

                            <Col lg={3} md={3}>
                              <span
                                style={{
                                  fontSize: "10px",

                                  visibility: `${
                                    discountTracker && postdiscountAmount > 0
                                      ? "unset"
                                      : "hidden"
                                  }`,
                                }}
                                className="text-muted"
                              >
                                {props.t("Amount Reduced By")}:{"  "}
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "600",
                                  }}
                                >
                                  {postdiscountAmount}
                                </span>
                              </span>
                              <div
                                style={{
                                  height: "30px",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: "18px",
                                }}
                              >
                                <Label>Coupon</Label>
                                <Button
                                  style={{
                                    width: "100px",
                                    marginTop: "-10px",
                                    height: "25px",
                                    justifyContent: "center",
                                    boxShadow: "none",
                                    transition: "transform 0.3s ease",
                                  }}
                                  onMouseEnter={e => {
                                    e.target.style.transform = "scale(1.05)"
                                  }}
                                  onMouseLeave={e => {
                                    e.target.style.transform = "scale(1)"
                                  }}
                                  className="btn btn-info btn-sm "
                                  onClick={applyDiscount}
                                  disabled={discountTracker}
                                >
                                  Apply Coupon
                                </Button>
                              </div>

                              <AvField
                                id="discountInput"
                                name="discount"
                                type="text"
                                placeholder={props.t("Enter Coupon Code")}
                                value={discountInput}
                                onChange={e => setDiscountInput(e.target.value)}
                              />
                            </Col>

                            <Col lg={2} md={2} style={{ paddingTop: "19px" }}>
                              <AvField
                                name="remarks"
                                label={props.t("Remarks")}
                                placeholder="Enter Remarks (Optional)"
                                type="text"
                                value={remarks}
                                onChange={e => setRemarks(e.target.value)}
                                validate={{
                                  required: { value: false },
                                }}
                                // errorMessage={props.t("Select Game")}
                              />
                            </Col>

                            <Col lg={2} md={2} style={{ paddingTop: "20px" }}>
                              <AvField
                                id="paidAmount"
                                name="paidAmount"
                                label={props.t("Final Paid Amount")}
                                type="number"
                                placeholder={props.t("0")}
                                value={calculateFinalPaidAmount()}
                                disabled={true}
                                // onChange={e => setPaidAmount(e.target.value)}
                              />
                            </Col>
                            <Col lg={2} md={2} style={{ paddingTop: "20px" }}>
                              <AvField
                                id="paidAmount"
                                name="paidAmount"
                                label={props.t("Change Amount")}
                                type="number"
                                placeholder={props.t("0")}
                                value={isNoChange ? 0 : finalChange}
                                onChange={handleChangeAmount()}
                                disabled={true}
                              />
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "50px" }}>
                            {selectedPaymentMethods.map(selectedOption => (
                              <>
                              <Col lg={3} key={selectedOption.value}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={selectedOption.image_url}
                                    alt={selectedOption.label}
                                    style={{
                                      marginRight: "4px",
                                      width: "21px",
                                      height: "21px",
                                    }}
                                  />
                                  <label>{`Paid by ${selectedOption.label}`}</label>
                                </div>
                                <AvField
                                  onWheel={preventValueChangeOnScroll}
                                  name={`paymentMethod_${selectedOption.value}`}
                                  type="number"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value: "^[0-9a-zA-Z]+$",
                                      errorMessage: props.t(
                                        "Cannot use space/special characters."
                                      ),
                                    },
                                  }}
                                  errorMessage={props.t("Please Enter Price")}
                                  value={
                                    formFieldValues[
                                      `paymentMethod_${selectedOption.value}`
                                    ]
                                  }
                                  placeholder="0"
                                  onChange={e => {
                                    const fieldName = `paymentMethod_${selectedOption.value}`
                                    const newValue = parseFloat(e.target.value)
                                    setFormFieldValues(prevValues => ({
                                      ...prevValues,
                                      [fieldName]: newValue,
                                    }))

                                    setPaymentDetails(prevDetails => {
                                      const paymentMethodId =
                                        selectedOption.value
                                      const paymentMethodName =
                                        selectedOption.label
                                      const updatedDetails = [...prevDetails]
                                      const existingDetailIndex =
                                        updatedDetails.findIndex(
                                          detail =>
                                            detail._id === paymentMethodId
                                        )

                                      if (existingDetailIndex !== -1) {
                                        updatedDetails[existingDetailIndex][
                                          paymentMethodName
                                        ] = newValue
                                      } else {
                                        const newDetail = {
                                          _id: paymentMethodId,
                                          [paymentMethodName]: newValue,
                                        }
                                        updatedDetails.push(newDetail)
                                      }
                                      const totalPaidAmount = updatedDetails.reduce(
                                        (total, paymentMethod) => {
                                          for (const key in paymentMethod) {
                                            if (key !== "_id") {
                                              total +=
                                                parseFloat(paymentMethod[key]) || 0
                                            }
                                          }
                                          return total
                                        },
                                        0
                                      )
                                      let Total = final_amount ? final_amount : grandTotal
                                      const newRemainingPaidAmount = Total - totalPaidAmount - parseFloat(paid_by_wallet)
                                      setRemaining(newRemainingPaidAmount.toFixed(2))
                                      return updatedDetails
                                    })
                                  }}
                                />
                              </Col>
                              </>
                            ))}
                          </Row>
                      {is_Refund && (
                        <Row>
                          <Col lg={4} md={4} >
                            <AvField
                              name="refund_amount"
                              label={props.t("Refund Amount")}
                              value={refundAmount}
                              placeholder="Enter a refund amount"
                              type="number"
                              onChange={(e)=>handleRefundAmount(e)}
                              validate={{
                                required: { value: is_Refund },
                                max: {value: totalPaid,
                                  errorMessage: props.t("Refund amount cannot exceed the total paid amount")}
                              }}
                              errorMessage={props.t("Enter a refund amount")}
                            />
                          </Col>
                          <Col lg={4} md={4}>
                            <AvField type="select"
                              validate={{
                                required: { value: is_Refund },
                              }} name="refund_type" label="Refund" value={refundType} onChange={(e) => setRefundType(e.target.value)}>
                              <option value="">-- select refund type--</option>
                              <option value="Cash">By cash</option>
                              <option value="Card">By card</option>
                            </AvField>
                          </Col>
                          <Col lg={4} md={4} >
                            <AvField
                              name="refund_remarks"
                              label={props.t("Remarks ")}
                              value={refundRemarks}
                              validate={{
                                required: { value: is_Refund },
                              }}
                              onChange={(e) => setRefundRemarks(e.target.value)}
                              placeholder="Enter remarks if any"
                              type="textarea"
                            />
                          </Col>
                        </Row>
                      )}
                          {/* <Row>
                        <Col lg={6}>
                          <FormGroup
                            check
                            style={{ display: selected_Customer?.customer_type === "VIP" ? "none" : "flex", justifyContent: "start" }}
                          >
                            <Label check>
                              <Input
                                style={{ border: "0.5px solid black" }}
                                type="checkbox"
                                checked={is_Refund}
                                onChange={(e) =>handleRefundChecked(e)}
                              />
                              {props.t("Refund")}
                            </Label>
                            <Label check className="ms-5">
                              <Input
                                style={{ border: "0.5px solid black" }}
                                type="checkbox"
                                checked={isNoChange}
                                onChange={e =>
                                  setIsNoChange(e.target.checked)
                                }
                              />
                              {props.t("Change")}
                            </Label>
                          </FormGroup>
                        </Col>
                          </Row> */}

                          <Row>
                            <Col lg={5}>
                              <Label
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <h5>
                               Remaining Amount To Pay:
                                 
                                   <span
                                    style={{
                                      color:remaining <= 0
                                      ? "#36B94D"
                                      : "#FA395D",
                                      fontWeight: "bold",
                                      marginLeft: "5px",
                                    }}
                                  >
                                 {selected_Customer?.customer_type === "VIP" ? "QR 0" :`QR ${remaining == 0
                                  ? ""
                                  : remaining < 0
                                    ? "+"
                                    : ""
                                }${Math.abs(remaining)} ${remaining < 0? "": ""}` }
                                  </span>
                                </h5>
                              </Label>
                            </Col>
                            <Col lg={7}>
                          <FormGroup
                            check
                            style={{ display: selected_Customer?.customer_type === "VIP" ? "none" : "flex", justifyContent: "start" }}
                          >
                            {/* <Label check>
                              <Input
                                style={{ border: "0.5px solid black" }}
                                type="checkbox"
                                checked={is_Refund}
                                onChange={(e) =>handleRefundChecked(e)}
                              />
                              {props.t("Refund")}
                            </Label> */}
                            <Label check className="ms-5">
                              <Input
                                style={{ border: "0.5px solid black" }}
                                type="checkbox"
                                checked={isNoChange}
                                onChange={e =>
                                  setIsNoChange(e.target.checked)
                                }
                              />
                              {props.t("No Change")}
                            </Label>
                          </FormGroup>
                        </Col>
                           
                          </Row>
                          <Row></Row>
                

                      <Row>
                        <Button
                          style={{
                            width: "124px",
                            margin: "auto",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                          type="submit"
                          className="btn btn-dark waves-effect"
                          disabled={
                            selectedPaymentMethods?.length<=0 && selected_Customer?.customer_type !=="VIP" 
                          }
                        >
                          <i className="bx bx-cart  font-size-18 align-middle me-2"></i>{" "}
                          {props.t("Check Out")}
                        </Button>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </Row>

        

          {/* Add New Customer */}
          <Modal
            size="md"
            isOpen={addCustomerModal}
            toggle={() => setAddCustomerModal(prev => (prev = !prev))}
          >
            <ModalHeader
              toggle={() => setAddCustomerModal(prev => (prev = !prev))}
            >
              {`Add New Customer`}
            </ModalHeader>
            <div>
              <ModalBody>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <AvForm
                          onValidSubmit={handleAddCustomer}
                          ref={refContainer}
                        >
                          <Row>
                            <Col lg={6}>
                              <AvField
                                label={props.t("Name")}
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                validate={{ required: { value: true } }}
                                errorMessage={props.t("Name cannot be empty")}
                              />
                            </Col>
                            <br />
                            <Col lg={6}>
                              <AvField
                                label={props.t("Mobile")}
                                className="form-control"
                                type="number"
                                name="mobile"
                                placeholder="Enter Phone Number"
                                validate={{
                                  required: { value: true },
                                  maxLength: {
                                    value: 13,
                                    errorMessage: props.t(
                                      "Maximum 13 characters allowed."
                                    ),
                                  },
                                }}
                                errorMessage={props.t(
                                  "Phone number cannot be empty"
                                )}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <AvField
                                label={props.t("Customer Type")}
                                className="form-control"
                                type="select"
                                name="customer_type"
                                defaultValue="Regular"
                                placeholder="Select Customer Type"
                                // validate={{ required: { value: true } }}
                                // errorMessage={props.t("Customer Type cannot be empty")}
                              >
                                <option value="">
                                  {props.t("--Select Type--")}
                                </option>
                                <option value="Regular">
                                  {props.t("Regular")}
                                </option>
                                <option value="Friend">
                                  {props.t("Friend")}
                                </option>
                                <option value="VIP">{props.t("VIP")}</option>
                              </AvField>
                            </Col>
                          </Row>
                          <Row>
                            <Button
                              type="submit"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "150px",

                                margin: "auto",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                            >
                              {props.t("Add Customer")}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background:
                                    "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                  pointerEvents: "none",
                                }}
                              ></div>
                            </Button>
                          </Row>
                        </AvForm>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(BuyItems)))
