import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  InputGroup,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import { submitShift } from "../../store/actions"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { AvForm } from "availity-reactstrap-validation"
//Import FlatPicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import exportFromJSON from "export-from-json"
import moment from "moment"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import showNotification from "../../helpers/show_notificaton"
import preloader from "../../helpers/Loader"

const Shifts = props => {
  const { SearchBar } = Search

  const dispatch = useDispatch()

  const handleDownload = async () => {
    const fileName = "All Shifts";
    const exportType = "xls";
  
    let data_to_export = allshift;
  
    if(data_to_export){
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
        data.push({
          Name: v.name,
          In_Time: v.in_time,
          Out_Time: v.out_time,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  } 

  const [in_time, setIn_time] = useState("")
  const [out_time, setOut_time] = useState("")

  useEffect(() => {
    loadAllShifts()
  }, [])

  //insert
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      in_time: in_time,
      out_time: out_time,
    },
    validationSchema: Yup.object({
      in_time: Yup.string().required("Enter In Time"),
      out_time: Yup.string().required("Enter Out Time"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(submitShift(values))
      loadAllShifts();
      resetForm()
    },
  })

  //Delete
  const handleValidDelete = async shifts => {
    let url = process.env.REACT_APP_BASEURL + "shifts/activate_deactivate"
   // let url = "http://localhost:3002/shifts/delete"
    let response = await postSubmitForm(url, {
      id: shifts._id,
      is_active : shifts.is_active===true? false : true
    })
    if (response.status === 1) {
      loadAllShifts()
       showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }

  //getall shift 
  const [allshift, setAllShit] = useState([])
  const loadAllShifts = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "shifts/getall"
   // let url = "http://localhost:3002/shifts/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      preloader(false)
      setAllShit(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
         <div className="form-switch ms-2" dir="ltr">
          <input
            type="checkbox"
            style={{height:"15px", width:"30px"}}
            title={row.is_active?"Click to Deactivate .":"Activate"}
            className="form-check-input"
            id={"customSwitchsize1" + row._id}
            onClick={() => { 
              handleValidDelete(row)
            }}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ fontWeight: "100", cursor: "pointer" }}
          ></label>
        </div>
       
      </span>
    )
  }

  const columns = [
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text:props.t("Shift Name"),
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("In Time"),
      dataField: "in_time",
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", 
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Out Time"),
      dataField: "out_time",
      filter: textFilter({ 
        caseSensitive: false ,
        style: { height: "20px", 
      }}),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Duration"),
      dataField: "",
      formatter:(cell, row) =>{
        // const inTime = row.in_time;
        // const outTime = row.out_time;
    
        const format = 'HH:mm';
    
        const inTime = moment(row.in_time, format);
        const outTime = moment(row.out_time, format);
        
        if (inTime.isSame(outTime)) {
          return "24:00"; // Duration is 24 hours
        }    

        if (outTime.isBefore(inTime)) {
          outTime.add(1, 'day'); // Add 1 day to the out time
        }
        const duration = outTime.diff(inTime);
        const formattedDuration = moment.utc(duration).format("H:mm");
    
        return formattedDuration;
    

      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Action"),
      dataField: "",
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Shift" breadcrumbItem1="Add Shift" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={3}>

                        <div >
                        <label className="form-label">
                            {props.t("In Time")}
                                                    </label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder={props.t("Select In Time")}
                              value={in_time}
                              onChange={(timeSelect) => {
                                // setIn_time(e.);
                                let dateString = timeSelect;
                                const date = new Date(dateString);
                                const hours = date.getHours();
                                const minutes = date.getMinutes();
                                setIn_time((hours === 0 ? `00`:hours < 10 ? `0${hours}`:hours)+":"+ (minutes === 0 ? `00`:minutes < 10 ? `0${minutes}`:minutes))
                                // console.log("time:",(hours === 0 ? `00`:hours < 10 ? `0${hours}`:hours)+":"+ (minutes === 0 ? `00`:minutes < 10 ? `0${minutes}`:minutes));
                              }}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true
                              }}

                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg={3}>

                        <div >
                        <label className="form-label">
                          {props.t("Out Time")}
                        </label>
                          <InputGroup>
                          
                            <Flatpickr
                              // label="Out Time"
                              className="form-control d-block"
                              placeholder={props.t("Select Out Time")}
                              value={out_time}
                              onChange={(timeSelect) => {
                                let dateString = timeSelect;
                                const date = new Date(dateString);
                                const hours = date.getHours();
                                const minutes = date.getMinutes();
                                setOut_time((hours === 0 ? `00`:hours < 10 ? `0${hours}`:hours)+":"+ (minutes === 0 ? `00`:minutes < 10 ? `0${minutes}`:minutes));
                              }}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                                time_24hr: true
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg={3} style={{display:"flex", justifyContent:"flex-start"}}  >
                        
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                           <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "-0.9rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>
                      </Col>
                    </Row>

                  
                    <Row>
                      <Col lg={4}>
                        <div className="mt-2 mx-4"></div>
                      </Col>
                     
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
               boxShadow:
               "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
              }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Shifts")}
                      </Col>

                      <Col lg={6} style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button 
                     style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                    type="submit"
                    className="btn btn-sm btn-secondary"
                    onClick={handleDownload}
                     >
                      <i className="mdi mdi-file-export" /> &nbsp;
                       {props.t("Export CSV")}
                    </Button> 
                    </Col>
                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allshift && allshift}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col style={{display:"flex", alignItems:"flex-start"}}  >
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "248px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          filter={filterFactory()}
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Shifts)))
