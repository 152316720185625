import React from "react"


function SelectPhotoFormatter(item) {
    return (
      <>
        <img style={{ height: "30px", width:"30px"}}
          src={item.image_url || item.image }
          className="avatar-md rounded-circle img-thumbnail me-2"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

export default SelectPhotoFormatter

function showPhotoFormatter(cell, row) {
  return (
    <>
      <img
        src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
        alt={row.name}
        className="avatar-md rounded-circle img-thumbnail"
      />
      <span
        style={{ cursor: "pointer" }}
        className="font-size-12 badge-soft-info badge badge-success badge-pill"
      ></span>
    </>
  )
}
 export { showPhotoFormatter }