function preventValueChangeOnScroll(e) {

    e.target.blur()

    e.stopPropagation()

    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  export default preventValueChangeOnScroll

  // function DatatypeChecker(e) {

  //   let data = e.target.value;

  //   const numberValue = parseFloat(data);
  
  //   if (!isNaN(numberValue)) {
  //     console.log('The input is a number:', numberValue);
  //   } else if (data === 'true' || data === 'false') {
  //     console.log('The input is a boolean:', data === 'true');
  //   } else {
  //     alert("only numbers are allowed")
  //   }
  // }

  // export {DatatypeChecker}