import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import logo from "../../assets/images/Logo.png"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

const OutOfStockItems = props => {

    const editorRef = useRef()
    const { SearchBar } = Search
    const code = localStorage.getItem("code")
      const refContainer = useRef(null)

    const handleDownload = async () => {
        // console.log("hello")
        const fileName = "Out_of_Stock_Items";
        const exportType = "xls";


        let data_to_export = section!=="" 
        ? outOfStockItems.filter(item=>item.section===section) 
        : outOfStockItems

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v,index) {
                data.push({
                    // Serial_Number: index+1,
                    Section: v.section,
                    Item_Name: v.name,
                    // Expiry_Date: moment(v.expiry_date).format('YYYY-MM-DD'),
                    Quantity: `${v.available.in_unit} ${v.unit} ${v.sub_unit? v.available.in_sub_unit : ""} ${v.sub_unit ? v.sub_unit : ""}`
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    function formatDate(date) {
        if (!date) return null
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
    }

    function preventValueChangeOnScroll(e) {

        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current     
        // function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }
    

    const [outOfStockItems, setOutOfStockItems] = useState([])
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [selectedCoupon, setSelectedCoupon] = useState();
    const [section, setSection] = useState("");

    useEffect(() => {
        loadAllOutOfStockItems()
    }, [])

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            const imageHeight = "105px";
            const imageWidth = "110px"; 
            const borderradius = "50%";
            const background = "#000000"; 
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 120px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Out of Stock Items List</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function() {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: selectedCoupon && selectedCoupon.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };


    

    const loadAllOutOfStockItems = async () => {
        let url = process.env.REACT_APP_BASEURL + "reports/out_of_stock_items"
        const response = await postSubmitForm(url, {})
        if (response && response.status === 1) {
            // console.log(response.data,"out of stock items")

            let customArr = []

            for(let i=0; i<response.data.length; i++){

                for(let j=0; j<response.data[i].items.length; j++){
                    customArr = [...customArr,{...response.data[i].items[j]}]
                }
            }
            // console.log(customArr,"customArr")
            setOutOfStockItems(prev => prev = customArr)
        } else {
            showNotification(response.message, "Error")
        }
    }

    const [editModal, setEditModal] = useState()
    //Action
    function editFormatter(cell, row) {
        return (
            <span className="text-info d-flex">
                <i
                    className="bx bxs-edit fs-5 mt-1"
                    title="Click to Edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setEditModal(!editModal)
                        setSelectedCoupon(row)

                    }}
                ></i>
                {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
                <i>
                    <div className="form-switch ms-2" dir="ltr">
                        <input
                            type="checkbox"
                            title={row.is_active ? "Click to Deactivate ." : "Activate"}
                            className="form-check-input"
                            id={"customSwitchsize1" + row._id}
                            onClick={() => {
                                handleValidDelete(row)
                            }}
                            checked={row.is_active}
                        />
                        <label
                            title="Click to change status."
                            className="custom-control-label"
                            htmlFor={"customSwitch1" + row._id}
                            style={{ fontWeight: "100", cursor: "pointer" }}
                        ></label>
                    </div>
                </i>
            </span>
        )
    }

    // Insert
    const handleValidSubmit = async (e, v) => {
        let url = process.env.REACT_APP_BASEURL + "coupons/insert"

        const object = {
            coupon_code: v.coupon_code,
            discount_percent: v.discount_percent,
            coupon_expiry_date: v.coupon_expiry_date,
        }

        postSubmitForm(url, object).then(response => {
            if (response.status === 1) {
                loadoutOfStockItems();
                refContainer.current.reset();
                showNotification(response.message, "Success")
            }
            else {
                showNotification(response.message, "Error")
            }
        })
    }

    //Update
    const handleValidUpdate = async (e, v) => {

        try {
            const object = {
                id: selectedCoupon._id,
                coupon_code: v.coupon_code,
                discount_percent: v.discount_percent,
                coupon_expiry_date: v.coupon_expiry_date,

            }
            let url = process.env.REACT_APP_BASEURL + "coupons/update"
            let response = await postSubmitForm(url, object)
            if (response.status === 1) {
                loadoutOfStockItems()
                setEditModal(!editModal)
                showNotification(response.message, "Success")
            } else {
                showNotification(response.message, "Error")
            }
        } catch (error) {
            showNotification(error.message, "Error")
        }
    }

    //Delete
    const handleValidDelete = async coupon => {
        let url = process.env.REACT_APP_BASEURL + "coupons/activate_deactivate"
        let response = await postSubmitForm(url, {
            id: coupon._id,
            is_active: coupon.is_active === true ? false : true
        })
        if (response.status === 1) {
            showNotification("Success", "Success")
            loadoutOfStockItems()
        } else {
            showNotification(response.message, "Error")
        }
    }


    // console.log(selectedCoupon);


    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
        },
        {
            text: props.t("Section"),
            dataField: "section",
            filter: selectFilter({
                style:{ marginLeft:"5px"},
                options: [
                  { value: "Accessories", label: "Accessories" },
                  { value: "Cafe", label: "Cafe" },
                  { value: "Kitchen", label: "Kitchen" },
                ],
                placeholder: "ALL",
                onFilter: (value) => {
                    setSection( value )
                  }  
              }),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Item Name"),
            dataField: "name",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
       
        {
            text: props.t("Quantity"),
            dataField: "expiry_date",
            formatter: (cell, row) => {
                return  <p>{`${row.available.in_unit} ${row.unit} ${row.sub_unit? row.available.in_sub_unit : ""} ${row.sub_unit ? row.sub_unit : ""}`}</p>
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
      
    ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Out of Stock Items" breadcrumbItem1="Out of Stock Items" />

                    {/* <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                                        <Row>
                                            <Col lg={6}>

                                                <AvField
                                                    label={props.t("Coupon Code")}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter Coupon Code"
                                                    name="coupon_code"
                                                    validate={{ required: { value: true } }}
                                                    errorMessage={props.t("Coupon Code cannot be empty")}
                                                />

                                            </Col>
                                            <Col lg={6}>

                                                <AvField

                                                    onWheel={preventValueChangeOnScroll}
                                                    label={props.t("Discount in Percent")}
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Enter Discount"
                                                    name="discount_percent"
                                                    validate={{ required: { value: true } }}
                                                    errorMessage={props.t("Discount cannot be empty")}
                                                />
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col lg={6}>

                                                <AvField
                                                    label={props.t("Expiry Date")}
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    options={{
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener("mousedown", e => {
                                                                e.preventDefault() // Prevent default click behavior
                                                            })
                                                        },
                                                    }}
                                                    placeholder="Select Expiry Date"
                                                    name="coupon_expiry_date"
                                                    validate={{ required: { value: true } }}
                                                    errorMessage={props.t("Coupon Expiry Date cannot be empty")}
                                                />


                                            </Col>

                                        </Row>

                                        <hr />
                                        <Row>



                                            <Button type="submit" style={{ width: "128px", margin: "auto" }}>
                                                Add Coupon
                                            </Button>



                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={6}>
                                                {props.t("All Out Of Stock Items")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    disabled={outOfStockItems.length<=0}
                                                    onClick={() => { printDiv("printDiv") }}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={outOfStockItems.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    <CardSubtitle className="mb-3">
                                        {props.t("View all out of stock items")}
                                    </CardSubtitle>
                            <div id="printDiv">
                            <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={outOfStockItems && outOfStockItems}
                                        search

                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row className="hide">
                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    // pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                            </div>



                                    <Modal
                                        size="lg"
                                        isOpen={editModal}
                                        toggle={() => setEditModal(!editModal)}
                                    >
                                        <ModalHeader toggle={() => setEditModal(!editModal)}>
                                            {props.t("Update Item - ")}
                                            {selectedCoupon && selectedCoupon.coupon_code}
                                        </ModalHeader>
                                        <ModalBody>
                                            {selectedCoupon && (
                                                <>
                                                    <Row>
                                                        <Col xlg={12}>
                                                            <Card>
                                                                <CardBody>
                                                                    <AvForm onValidSubmit={handleValidUpdate} >
                                                                        <Row>
                                                                            <Col lg={6}>

                                                                                <AvField
                                                                                    label={props.t("Coupon Code")}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    placeholder="Enter Coupon Code"
                                                                                    name="coupon_code"
                                                                                    value={selectedCoupon.coupon_code}
                                                                                />

                                                                            </Col>
                                                                            <Col lg={6}>

                                                                                <AvField
                                                                                    onWheel={preventValueChangeOnScroll}
                                                                                    label={props.t("Discount in percent")}
                                                                                    className="form-control"
                                                                                    type="number"
                                                                                    placeholder="Enter Discount"
                                                                                    name="discount_percent"
                                                                                    value={selectedCoupon.discount_percent}
                                                                                />
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>

                                                                            <Col lg={6}>

                                                                                <AvField
                                                                                    label={props.t("Expiry Date")}
                                                                                    className="form-control"
                                                                                    type="date"
                                                                                    placeholder="Enter Expiry Date"
                                                                                    name="coupon_expiry_date"
                                                                                    tag={Flatpickr}
                                                                                    options={{
                                                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                                                            dayElem.addEventListener("mousedown", e => {
                                                                                                e.preventDefault() // Prevent default click behavior
                                                                                            })
                                                                                        },
                                                                                    }}
                                                                                    value={formatDate(selectedCoupon.coupon_expiry_date)}
                                                                                />


                                                                            </Col>

                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                <hr />
                                                                                <FormGroup className="mb-0 text-center">
                                                                                    <div>
                                                                                        <Button
                                                                                            type="submit"
                                                                                            color=""
                                                                                            className="mr-1 btn btn-primary"
                                                                                        >
                                                                                            {props.t("Update")}
                                                                                        </Button>{" "}
                                                                                    </div>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </AvForm>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </ModalBody>
                                    </Modal>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(OutOfStockItems)))
