import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  Button,
  Badge,
  Table,
  CardTitle,
  Form,
  FormGroup,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import swal from "sweetalert2"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from 'yup'
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import BackgroundImage from "../../assets/images/Background.png"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import withRouter from "../../components/Common/withRouter"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

const initialLeaveDetails = {
  leave_type: "",
  date_from: "",
  date_to: "",
  reason: "",
}


const Apply_Leave = (props) => {
  //usestate for showing alert and when apply leave application gets submitted
  const [showalert, setShowalert] = useState(false)
  // useState for fetching and storing the data of the apply leave table
  const [employee_id, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  )
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [appliedLeaveDetails, setAppliedLeaveDetails] = useState([]);
  const [errorFeildName, setErrorFeildName] = useState([])
  const [bool, setbool] = useState(false)
  const refContainer = useRef(null)

  const [minDateVal, setMinDateVal] = useState("")
  const [disabledStatus, setDisabledStatus] = useState(true)
  // ------------------------------------------------------------------------

  // useState variables for submitting the apply leave details
  const [LeaveDetailsForm, setLeaveDetailsForm] = useState(initialLeaveDetails)
  const [submitButtonClickedTracker, setSubmitButtonClickedTracker] = useState(0)
  // const [validationErrors, setValidationErrors] = useState({})
  // --------------------------------------------------

  // useEffect for fetching the data of previous leaves applied and their status

  // const schema = Yup.object().shape({
  //   leave_type: Yup.string().required('Leave type is required'),
  //   date_from: Yup.date().required('From date is required'),
  //   date_to: Yup.date().required('To date is required'),
  //   reason: Yup.string().required('Reason is required'),
  // })

  useEffect(() => {
    function getRoster(token) {
      let url = process.env.REACT_APP_BASEURL
      // console.log("token:",token)
      axios
        .post(
          url + `leaveapplications/get_by_employee`,
          { employee_id: employee_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(res => {
          // console.log("response from roster/getall route", res.data.data)
          setAppliedLeaveDetails(res.data.data)
        })
        .catch(err => console.log(err))
    }

    getRoster(employeeToken)
  }, [submitButtonClickedTracker])

  

  // -------------------------------------------------------------

  // function handle submit
  const handleValidSubmit = async (e, v, token) => { 
    let url = process.env.REACT_APP_BASEURL
    const object = {
      employee_id: employee_id,
      date_from: v.date_from,
      date_to: v.date_to,
      leave_type: v.leave_type,
      reason: v.reason,
    }
    // console.log("Data", object)
    axios
      .post(
        url + `leaveapplications/insert`,
        object,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        setShowalert(true)
        setSubmitButtonClickedTracker(submitButtonClickedTracker + 1)
        if(res.data?.status===1){
          showNotification(res.data?.message,"Success")
        }else{
          showWarning(res.data?.message,"warning")
        }
        if (refContainer.current) {
          refContainer.current.reset();
        }
      })
      .catch(err => {
       showNotification(err,"error")
        if (refContainer.current) {
          refContainer.current.reset();
        }
      })
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

  }
  // ------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Leave" breadcrumbItem="Leave Application Form" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}}>
                  <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken)} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                          <label className="form-label">
                            {props.t("From Date")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            name="date_from"
                            placeholder="Select Date"
                            errorMessage="Select From Date."
                            options={{
                              minDate: "today",
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                                
                              },
                            }}
                            onChange={(v) => {

                              const dateString = v;
                              const date = new Date(dateString);
                              // Extract the date components
                              const day = date.getDate();
                              const month = date.getMonth() + 1; // Months are zero-based, so add 1
                              const year = date.getFullYear();
                              // Format the date as "dd-mm-yyyy"
                              const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                              setMinDateVal(formattedDate);
                              setDisabledStatus(false)
                            }}
                            validate={{
                              required: { value: true },
                            }}
                          />

                      </Col>
                      <Col lg={6}>
                          <label className="form-label">
                            {props.t("To Date")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            name="date_to"
                            disabled={disabledStatus}
                            options={{
                              minDate: "today",
                               onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },
                              minDate: minDateVal
                            }}
                            placeholder="Select To Date"
                            validate={{ required: { value: true } }}
                            errorMessage="Select To Date"
                          >
                            <option value="">Select role</option>

                            {/* {allDesignations && 
                                allDesignations.map((role)=>(
                                  <option value={role.name} key={role._id}>{role.name}</option>
                                ))} */}

                          </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                          <label className="form-label">
                            {props.t("Leave Type")}
                          </label>
                          <AvField
                            className="form-control"
                            name="leave_type"
                            type="select"

                            errorMessage="Leave Type cannot be empty."
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">Select Leave Type</option>
                            <option value="Annual Leave">
                              Annual Leave
                            </option>
                            <option value="Sick Leave">
                              Sick Leave
                            </option>
                            <option value="LWP">
                              LWP
                            </option>

                          </AvField>
                      </Col>
                      <Col lg={6}>
                          <label className="form-label">
                            Reason
                          </label>
                          <AvField
                            className="form-control"
                            type="textarea"
                            placeholder="Enter Reason for Leave"
                            name="reason"
                            validate={{
                              required: { value: true }
                            }}
                            errorMessage="Reason cannot be empty."
                          />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mx-2"></div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className="mt-4 mx-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                         <Button
                                      type="submit"
                                    
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "-1rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                     Submit
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}}>
                  <CardTitle>Applied Leave Details</CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Applied Date</th>
                          <th>From date</th>
                          <th>To date</th>
                          <th>Type of leave</th>
                          <th>Remaining Annual Leave</th>
                          <th>Remaining Sick Leave</th>
                          <th>Reason</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appliedLeaveDetails.map((leave, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{leave.createdAt.slice(0, 10)}</td>
                            <td>{leave.date_from.slice(0, 10)}</td>
                            <td>{leave.date_to.slice(0, 10)}</td>
                            <td>{leave.leave_type}</td>
                            <td>{leave.remaining_annual_leave}</td>
                            <td>{leave.remaining_sick_leave}</td>
                            <td>{leave.reason}</td>
                            <td>
                              <div>
                                <Badge
                                  className={`font-size-12 badge-soft-${leave.status === "Approved"
                                    ? "success"
                                    : leave.status === "Pending"
                                      ? "warning"
                                      : leave.status === "Rejected"
                                        ? "danger"
                                        : null
                                    }`}
                                >
                                  {leave.status}
                                </Badge>
                                <div >
                                  <p style={{ fontSize: "11px", fontWeight: "500", marginTop: "0.4rem" }}>
                                    {leave.remark ? `Remark:   ${leave.remark} ` : ""}</p>

                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}

                        {/* <tr>
                          <th scope="row">2</th>
                          <td>12/05/2023</td>
                          <td>15/05/2023</td>
                          <td>20/05/2023</td>
                          <td>Sick Leave</td>
                          <td>
                            I am suffering from high fever and flu so i will not
                            be able to come to work.
                          </td>
                          <td>
                            <div>
                              <Badge className="font-size-12 badge-soft-danger">
                                Rejected
                              </Badge>
                            </div>
                          </td>
                        </tr> */}

                        {/* <tr>
                          <th scope="row">3</th>
                          <td>12/05/2023</td>
                          <td>15/05/2023</td>
                          <td>20/05/2023</td>
                          <td>Sick Leave</td>
                          <td>
                            I am suffering from high fever and flu so i will not
                            be able to come to work.
                          </td>
                          <td>
                            <div>
                              <Badge className="font-size-12 badge-soft-warning">
                                Pending
                              </Badge>
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default withRouter(connect(null, {})(withTranslation()(Apply_Leave)))
