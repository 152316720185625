import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input, TabPane, NavItem, NavLink, TabContent, Nav } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import classnames from "classnames";
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import logo from "../../assets/images/Logo.png"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
// import Flatpickr from "react-flatpickr"

const ServiceDetailsByCustomer = props => {

    const editorRef = useRef()
    const { SearchBar } = Search

    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
    const [viewServiceModal, setViewServiceModal] = useState(false);
    const [servicesArr, setServicesArr] = useState([]);
    const [viewGames, setViewGames] = useState([]);
    const [viewItems,setViewItems] = useState([])
    const [viewPackages, setViewPackages] = useState([])
    const [viewNestedPackageModal, setViewNestedPackageModal] = useState()
    const [packageItems, setPackageItems] = useState([])
    const [packageGames, setPackageGames] = useState([])
    const [customerType, setCustomerType] = useState("");
    const [viewGamesModal, setViewGamesModal] = useState(false);
    const [viewItemsModal, setViewItemsModal] = useState(false)
    const [viewPackageModal, setViewPackageModal] = useState()
    const [gameTranscation, setGameTransaction] = useState([])
    const [itemTranscation, setItemTransaction] = useState([])
    const [viewItemTransactionModal, setviewItemTransactionModal] = useState()
    const [viewGameTransactionModal, setviewGameTransactionModal] = useState()
    const [type, setType] = useState("");
    const code = localStorage.getItem("code")
    //meta title
    const refContainer = useRef(null)

    const handleDownload = async () => {
        // console.log("hello")
        const fileName = "Customer Booking Records";
        const exportType = "xls";

        // let data_to_export = top20Customers;
        let data_to_export = type!=="" 
        ? top20Customers.filter(item=>item.customer_type===type) 
        : top20Customers

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    // Serial_Number: index + 1,
                    Customer_Name: v.name,
                    Mobile: v.mobile,
                    Type: v.customer_type
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    function formatDate(date) {
        if (!date) return null
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
    }

    function preventValueChangeOnScroll(e) {

        // Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current     
        // function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            const imageHeight = "105px";
            const imageWidth = "110px"; 
            const borderradius = "50%";
            const background = "#000000"; 
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 100px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Service Details by Customer</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function() {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };

    const [top20Customers, setTop20Customers] = useState([])
    const [editModal, setEditModal] = useState()
    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [selectedCoupon, setSelectedCoupon] = useState();
    //Tabs
    const [verticalActiveTab, setverticalActiveTab] = useState("1");

    useEffect(() => {
        // loadAlltop20Customers()
    }, [])

  //Tabs Function
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: top20Customers && top20Customers.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };


    //load All Coupons

    const loadTop20Customers = async (payload) => {
        let url = process.env.REACT_APP_BASEURL + "reports/services_details_by_customer"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {


            // console.log(response.data, 'top 20 customer')
            setTop20Customers(prev => prev = response.data)
        } else {
            showNotification(response.message, "Error")
        }
    }

 

    // fetch data
    const handleValidSubmit = async (e, v, token) => {

        

        const object = {
            from_date: v.from_date,
            to_date: v.to_date
        }

        

        loadTop20Customers(object);

    }


    function showImagePhotoFormatter(cell, row) {
        return (
            <>
                <img
                    src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
                    alt={row.name}
                    className="avatar-md rounded-circle img-thumbnail"
                />
                <span
                    style={{ cursor: "pointer" }}
                    className="font-size-12 badge-soft-info badge badge-success badge-pill"
                ></span>
            </>
        )
    }

    function showPhotoFormatter(cell, row) {
        return (
            <>
                <img
                    src={row.game.image_url || row.game.image + "?" + new Date() + "=" + new Date()}
                    alt={row.game.name}
                    className="avatar-md rounded-circle img-thumbnail"
                />
                <span
                    style={{ cursor: "pointer" }}
                    className="font-size-12 badge-soft-info badge badge-success badge-pill"
                ></span>
            </>
        )
    }


    function bookingDetailsFormatter(cell, row) {
        return (
            <span className="text-info d-flex justify-content-center">
                <i
                    className="bx bxs-file fs-5 ms-0" aria-hidden="true"
                    title="Click to View Services"
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => {
                        setCustomerType(prev => prev = row.customer_type)
                        setServicesArr(prev => prev = row.services)
                        setViewServiceModal(prev => prev = !prev)
                    }}
                ></i>
            </span>
        )
    }

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
        },
        {
            text: props.t("Customer Name"),
            dataField: "name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Mobile"),
            dataField: "mobile",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },

        {
            text: props.t("Type"),
            dataField: "customer_type",
            filter: selectFilter({
                style:{ marginLeft:"5px"},
                options: [
                    { value: "VIP", label: "VIP" },
                  { value: "Regular", label: "Regular" },
                  { value: "Friend", label: "Friend" },
                ],
                placeholder: "ALL",
                onFilter: (value) => {
                    setType( value )
                  } 
              }),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("See Service Details"),
            dataField: "",
            formatter: bookingDetailsFormatter,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },

    ]

    function viewGamesDetails(cell, row) {
        return (
            <span className="text-info d-flex justify-content-center">
               {row?.games.length>0 ? 
                <i
                className="bx bxs-file fs-5 ms-0" aria-hidden="true"
                title="Click to View Services"
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                    setViewGames(prev => prev = row.games)
                    setViewGamesModal(prev => prev = !prev)

                }}
            ></i>
            :
            <p style={{display:"flex",justifyContent:"center", color:"black"}}>
            N/A</p>
                }
            </span>
        )
    }

    function viewItemsDetails(cell, row) {
        return (
            <span className="text-info d-flex justify-content-center">
             {row?.items.length>0 ?
               <i
               className="bx bxs-file fs-5 ms-0" aria-hidden="true"
               title="Click to View Services"
               style={{ cursor: "pointer", color: "blue" }}
               onClick={() => {
                   setViewItems(prev => prev = row.items)
                   setViewItemsModal(prev => prev = !prev)

               }}
           ></i>
           :
           <p style={{display:"flex",justifyContent:"center", color:"black"}}>
           N/A</p> 
            }
            </span>
        )
    }

    function viewPackage(cell, row) {
        return (
            <span className="text-info d-flex justify-content-center">
            {row?.packages.length>0 ?
                <i
                className="fas fa-box fs-5 ms-0 text-warning" aria-hidden="true"
                title="Click to View Package"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    setViewPackageModal(!viewPackageModal)
                    setViewPackages(prev => prev = row?.packages)
                }}
            ></i>
            :
            <p style={{display:"flex",justifyContent:"center", color:"black"}}>
                N/A</p>
            }
            </span>
        )
    }

    function viewPackageDetails(cell, row) {
        return (
            <span className="text-info d-flex justify-content-center">
                <i
                className="bx bxs-file fs-5 ms-0" aria-hidden="true"
                title="Click to View Details"
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                    // console.log(row);
                    setViewNestedPackageModal(!viewNestedPackageModal)
                    setPackageItems(prev => prev =row?.item_details)
                    setPackageGames(prev => prev =row?.game_details)
                }}
            ></i>
            </span>
        )
    }


    const bookingsColumn = [
        {
            dataField: "booking_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
        },
        {
            text: props.t("Invoice No."),
            dataField: "invoice_no",
            // formatter: viewGamesDetails,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("View Games"),
            dataField: "",
            formatter: viewGamesDetails,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("View Items"),
            dataField: "",
            formatter: viewItemsDetails,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },

        {
            text: props.t("View Package"),
            dataField: "",
            formatter: viewPackage,
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
     

    ]
// --------VIEW GAMES---------------------------------------------------------------------
    const viewGamesColumn = [
        {
            dataField: "game._id",
            hidden: true,
        },
        {
            dataField: "game._id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Image"),
            formatter: showPhotoFormatter,
            dataField: "game.image",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Table Name"),

            dataField: "table_number",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Game Name"),
            dataField: "game.name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },
        {
            text: props.t("Selling Price"),
            dataField: customerType === "Friend" ? "game.price_for_friend" : customerType === "Regular" ? "game.price_for_regular" : "game.price_for_vip",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },

        {
            text: props.t("Duration"),
            dataField: "game.duration",
            sort: false,
            editable: true,
            validator: (newValue, row, column) => {
                if (+newValue < 30) {
                    return {
                        valid: false,
                        message: "Minimum Playing Time is 30 Minutes",
                    };
                }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {
                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{
                        WebkitAppearance: "none",
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    onBlur={e => {
                        handleDurationChange(e, rowIndex, row, editorProps)
                        // editorProps.onUpdate(+e.target.value)
                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            dataField: "game.sub_total",
            formatter: (cell, row) => row.game.sub_total,
            text: "Sub Total",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,
        },
        

    ]

    // ----------VIEW ITEMS COLUMN-------------------------------------------------------------
    const viewItemsColumn = [
        {
            dataField: "item_id",
            hidden: true,
        },
        {
            dataField: "item_id",
            formatter: (cell, row, rowIndex) => {
                // const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("S.N"),
            headerStyle: (colum, colIndex) => {
                return { width: "1%" }
            },
            editable: false
        },
        {
            text: props.t("Image"),
            formatter: showImagePhotoFormatter,
            dataField: "image_url",
            //sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Name"),
            dataField: "name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
            editable: false
        },

        {
            dataField: "Section",
            formatter: (cell, row) => row.section,
            text: "section",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false
        },
        {
            dataField: "available.in_unit",
            text: "Available Units",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,

        },
        {
            dataField: "available.in_sub_unit",
            text: "Available Sub Units",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false,

        },
        {
            dataField: "price",
            // formatter: (cell, row) => row.price,
            text: "Selling Price",
            headerStyle: (colum, colIndex) => {
                return { width: "7%" }
            },
            editable: false
        },
        {
            text: props.t("Quantity In-Unit"),
            dataField: "quantity.in_unit",
            sort: false,
            validator: (newValue, row, column) => {

                if (+newValue > Number(row.available.in_unit)) {
                    return {
                        valid: false,
                        message: "Cannot exceed available units",
                    };
                }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {

                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            editable: true,
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    // ref={inputRef}
                    onWheel={preventValueChangeOnScroll}
                    // className={styles.noSpinner}
                    style={{

                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    // onChange={(e) => { handleQuantityChange(e, rowIndex, row, editorProps);  inputRef.current.blur(); }}
                    onBlur={e => {
                        handleQuantityChange(e, rowIndex, row, editorProps);
                        // editorProps.onUpdate(+e.target.value)

                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Quantity In-Sub-Unit"),
            dataField: "quantity.in_sub_unit",
            sort: false,

            editable: true,
            validator: (newValue, row, column) => {

                if (+newValue > Number(((+row.formula) * (+row.available.in_unit)) + (+row.available.in_sub_unit))) {
                    return {
                        valid: false,
                        message: "Cannot exceed available quantity",
                    };
                }
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: "Only numbers are allowed.",
                    };
                }
                if (newValue % 1 != 0) {

                    return {
                        valid: false,
                        message: "Decimals not allowed.",
                    };
                }
                return true;
            },
            editorRenderer: (
                editorProps,
                value,
                row,
                column,
                rowIndex,
                columnIndex
            ) => (
                <input
                    onWheel={preventValueChangeOnScroll}
                    style={{
                        width: "50px",
                        height: "40px",
                        border: "1px solid lightGray",
                        padding: "5px",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                    type="number"
                    defaultValue={value}
                    onBlur={e => {
                        handleSubUnitQuantityChange(e, rowIndex, row, editorProps);

                        // editorProps.onUpdate(+e.target.value)
                        // setEnterQtyArr(prev => { prev = { ...prev, [row._id]: row.quantity.in_unit === 0 ? false : true } })
                    }}
                    onFocus={e => e.target.select()} // Retain focus on input field
                    autoFocus
                />
            ),
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        // {
        //     dataField: "sub_total",
        //     formatter: (cell, row) => row.sub_total,
        //     text: "Sub Total",
        //     headerStyle: (colum, colIndex) => {
        //         return { width: "7%" }
        //     },
        //     editable: false
        // },
      
    ]

    // --------view packages table-------------------------------------------------------
    // const packagesColumn = [
    //     {
    //         dataField: "_id",
    //         hidden: true,
    //     },
    //     {
    //         dataField: "_id",
    //         formatter: (cell, row, rowIndex) => {
    //             const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
    //             return rowNumber;
    //         },
    //         text: props.t("S.N"),
    //         headerStyle: (colum, colIndex) => {
    //             return { width: "1%" }
    //         },
    //     },
    //     {
    //         text: props.t("Customer Name"),
    //         dataField: "name",
    //         sort: false,
    //         headerStyle: (colum, colIndex) => {
    //             return { width: "5%" }
    //         },
    //     },
    //     {
    //         text: props.t("Mobile"),
    //         dataField: "mobile",
    //         sort: false,
    //         headerStyle: (colum, colIndex) => {
    //             return { width: "5%" }
    //         },
    //     },

    //     {
    //         text: props.t("Type"),
    //         dataField: "customer_type",

    //         sort: false,
    //         headerStyle: (colum, colIndex) => {
    //             return { width: "5%" }
    //         },
    //     },
    //     {
    //         text: props.t("See Service Details"),
    //         dataField: "",
    //         formatter: bookingDetailsFormatter,
    //         sort: false,
    //         headerStyle: (colum, colIndex) => {
    //             return { width: "5%" }
    //         },
    //     },

    // ]

      const packages = [
        {
          dataField: "_id",
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1
          },
          text: props.t("#"),
          headerStyle: (column, collIndex) => {
            return { width: "3%" }
          },
        },
        {
          dataField: "package_id",
          text: props.t("Package ID"),
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
        {
          dataField: "",
          text: props.t("Action"),
          formatter: viewPackageDetails,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
      ]

      const PackageItemDetails = [

        {
          text: props.t("Image"),
          formatter: (cell,row)=>{
            return (
                <>
                    <img
                        src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
                        alt={row.name}
                        className="avatar-md rounded-circle img-thumbnail"
                    />
                    <span
                        style={{ cursor: "pointer" }}
                        className="font-size-12 badge-soft-info badge badge-success badge-pill"
                    ></span>
                </>
            )
          },
          dataField: "image_url",
          //sort: false,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
          text: props.t("Name"),
          dataField: "name",
          sort: false,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
    
        {
          text: props.t("Quantity Used"),
          dataField: "available.in_unit",
          sort: false,
          formatter: (cell, row) => {
            return row.available.in_unit + " " + row.unit + " " + row.available.in_sub_unit + " " + row.sub_unit
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },

        {
            dataField: "",
            text: props.t("Transactions"),
            formatter: (cell,row)=>{
                return (
                    <span className="text-info" style={{ display: "flex", justifyContent: "center" }}>
                      <i
                        className="fas fa-info-circle fs-5 "
                        title="View Transactions"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setviewItemTransactionModal(!viewItemTransactionModal)
                          setItemTransaction(row?.package_item_transactions)
                          
                        }}
                      ></i>
                    </span>
                  )
            },
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
    
      
      ]
    
      const PackageGameDetails = [
        {
          text: props.t("Image"),
           formatter: (cell,row)=>{
            return (
                <>
                    <img
                        src={row.image_url || row.image + "?" + new Date() + "=" + new Date()}
                        alt={row.name}
                        className="avatar-md rounded-circle img-thumbnail"
                    />
                    <span
                        style={{ cursor: "pointer" }}
                        className="font-size-12 badge-soft-info badge badge-success badge-pill"
                    ></span>
                </>
            )
          },
          dataField: "image",
          //sort: false,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
          text: props.t("Name"),
          dataField: "name",
          sort: false,
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
          text: props.t("Remaining Minutes"),
          dataField: "package_game_remaining",
          sort: false,
          style: (cell, row) => {
            return {
              textAlign: "center"
            };
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },

        {
            dataField: "",
            text: props.t("Transactions"),
            formatter: (cell,row)=>{
                return (
                    <span className="text-info" style={{ display: "flex", justifyContent: "center" }}>
                      <i
                        className="fas fa-info-circle fs-5 "
                        title="View Transactions"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setviewGameTransactionModal(!viewGameTransactionModal)
                          setGameTransaction(row?.package_game_transactions)
                          
                        }}
                      ></i>
                    </span>
                  )
            },
            headerStyle: (colum, colIndex) => {
              return { width: "5%" }
            },
          },
    
      ]

      const ItemTransactions = [

        {
          dataField: "transaction_type",
          text: props.t("Transaction Type"),
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
        {
          dataField: "transaction_date",
          text: props.t("Transaction Date"),
          formatter: (cell, row) => {
            return formatDate(row.transaction_date)
          },
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
        {
          text: props.t("Transaction Quantity"),
          dataField: "transaction_quantity",
          sort: false,
          formatter: (cell, row) => {
            return (
              <>
                {row.transaction_quantity.in_unit + "  " +"Unit" + " " + row.transaction_quantity.in_sub_unit + " " + "sub_unit"}
              </>
            );
          },
          headerStyle: (colum, colIndex) => {
            return { width: "5%" }
          },
        },
        {
          dataField: "transaction_remarks",
          text: props.t("Remarks"),
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
      ]
    
      const GameTransactions = [
    
        {
          dataField: "transaction_type",
          text: props.t("Transaction Type"),
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
        {
          dataField: "transaction_date",
          text: props.t("Transaction Date"),
          formatter: (cell, row) => {
            return formatDate(row.transaction_date)
          },
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
        {
          dataField: "duration",
          text: props.t("Duration"),
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
    
        {
          dataField: "transaction_remarks",
          text: props.t("Remarks"),
          headerStyle: (colum, colIndex) => {
            return { width: "8%" }
          },
        },
      ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    // console.log(packageItems,"packagItems");
    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Customer Sales Records" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken)} ref={refContainer}>
                                        <Row >
                                            <Col md={3}>
                                                <label className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}

                                                    options={{

                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {

                                                        // if (minDateOnChangeActive === true) {


                                                        const dateString = v;


                                                        const date = new Date(dateString);

                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)
                                                        //   setMaxDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    name="from_date"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col md={3}>

                                                <label className="form-label">
                                                    To Date
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    onChange={(v) => {

                                                        // if (maxDateOnChangeActive === true) {


                                                        const dateString = v;


                                                        const date = new Date(dateString);

                                                        // Extract the date components
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1; // Months are zero-based, so add 1
                                                        const year = date.getFullYear();

                                                        // Format the date as "dd-mm-yyyy"
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        // console.log(formattedDate); // Output: 26-12-2023
                                                        // setMaxDateVal(formattedDate)

                                                        //   setMinDateOnChangeActive(false)
                                                        // }
                                                    }}
                                                    // disabled={disabledStatus}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}

                                                    name="to_date"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />


                                            </Col>

                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "128px",
                                                        marginTop: "1rem",
                                                        // margin: "auto",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={6}>
                                                {props.t("All Services ")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    disabled={top20Customers.length<=0}
                                                    onClick={() => { printDiv("printDiv") }}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={top20Customers.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    {/* <CardSubtitle className="mb-3">
                                        {props.t("View Top 20 Customers")}
                                    </CardSubtitle> */}
                                  <div id="printDiv">
                                  <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={top20Customers && top20Customers || []}
                                        search

                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row className="hide">
                                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps}  />
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable

                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                    // pagination={paginationFactory(options)}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                  </div>


                                    <Modal
                                        size="lg"
                                        isOpen={viewServiceModal}
                                        toggle={() => setViewServiceModal(prev => prev = !prev)}
                                    >
                                        <ModalHeader toggle={() => setViewServiceModal(prev => prev = !prev)}>
                                            {props.t("Service Details ")}
                                            {servicesArr && servicesArr.name}
                                        </ModalHeader>
                                        <ModalBody>
                                            <ToolkitProvider
                                                keyField="booking_id"
                                                columns={bookingsColumn}
                                                data={servicesArr || []}
                                                search

                                            >
                                                {propst => (
                                                    <div>
                                                        <AvForm>
                                                            <Row>
                                                                <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                    <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                        <BootstrapTable

                                                            {...propst.baseProps}
                                                            filter={filterFactory()}
                                                            noDataIndication={props.t("No data to display.")}
                                                            striped
                                                            hover
                                                            condensed
                                                            pagination={paginationFactory(options)}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </ModalBody>
                                    </Modal>


                                    {/* view games modal */}
                                    <Modal
                                        size="lg"
                                        isOpen={viewGamesModal}
                                        toggle={() => setViewGamesModal(prev => prev = !prev)}
                                    >
                                        <ModalHeader toggle={() => setViewGamesModal(prev => prev = !prev)}>
                                            {props.t("Service Detail ")}

                                        </ModalHeader>
                                        <ModalBody>
                                            <ToolkitProvider
                                                keyField="booking_id"
                                                columns={viewGamesColumn}
                                                data={viewGames || []}
                                                search

                                            >
                                                {propst => (
                                                    <div>
                                                        <AvForm>
                                                            <Row>
                                                                <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                    <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                        <BootstrapTable

                                                            {...propst.baseProps}
                                                            filter={filterFactory()}
                                                            noDataIndication={props.t("No data to display.")}
                                                            striped
                                                            hover
                                                            condensed
                                                            pagination={paginationFactory(options)}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </ModalBody>
                                    </Modal>


                                    {/* view Items modal */}
                                    <Modal
                                        size="lg"
                                        isOpen={viewItemsModal}
                                        toggle={() => setViewItemsModal(prev => prev = !prev)}
                                    >
                                        <ModalHeader toggle={() => setViewItemsModal(prev => prev = !prev)}>
                                            {props.t("Service Detail  ")}

                                        </ModalHeader>
                                        <ModalBody>
                                            <ToolkitProvider
                                                keyField="booking_id"
                                                columns={viewItemsColumn}
                                                data={viewItems || []}
                                                search

                                            >
                                                {propst => (
                                                    <div>
                                                        <AvForm>
                                                            <Row>
                                                                <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                    <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                        <BootstrapTable

                                                            {...propst.baseProps}
                                                            filter={filterFactory()}
                                                            noDataIndication={props.t("No data to display.")}
                                                            striped
                                                            hover
                                                            condensed
                                                            pagination={paginationFactory(options)}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </ModalBody>
                                    </Modal>

                                     {/* View Packages */}

                                    <Modal
                                        size="lg"
                                        isOpen={viewPackageModal}
                                        toggle={() => { setViewPackageModal(!viewPackageModal); setPackageDetails() }}
                                    >
                                        <ModalHeader toggle={() => setViewPackageModal(!viewPackageModal)}>
                                        {props.t("Package Details  ")}
                                        </ModalHeader>
                                        <ModalBody>

                                            <>
                                            <Row>
                                                <Col xlg={12}>
                                                <Card>
                                                    <CardBody>
                                                    <ToolkitProvider
                                                        keyField="_id"
                                                        columns={packages}
                                                        data={viewPackages||[]}
                                                        search
                                                    >
                                                        {propst => (
                                                        <div>
                                                            <AvForm>
                                                            <Row>
                                                                <Col
                                                                lg={4}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "flex-start",
                                                                }}
                                                                >
                                                                <SearchBar
                                                                    {...propst.searchProps}
                                                                    style={{ width: "340px" }}
                                                                />
                                                                </Col>
                                                            </Row>
                                                            </AvForm>
                                                            <BootstrapTable
                                                            {...propst.baseProps}
                                                            noDataIndication={props.t("No data to display .")}
                                                            striped
                                                            hover
                                                            condensed
                                                            />
                                                        </div>
                                                        )}
                                                    </ToolkitProvider>
                                                    </CardBody>
                                                </Card>
                                                </Col>
                                            </Row>
                                            </>

                                        </ModalBody>
                                    </Modal>

                                    {/* View Package Details */}
                                    <Modal
                                    size="xl"
                                    isOpen={viewNestedPackageModal}
                                    toggle={() => { setViewNestedPackageModal(!viewNestedPackageModal);}}
                                >
                                    <ModalHeader toggle={() => setViewNestedPackageModal(!viewNestedPackageModal)}>
                                    {props.t("Details  ")}
                                    </ModalHeader>
                                    <ModalBody>
                                        <>
                                        
                                        <Row>
                                            <Col md="3">
                                            <Nav pills className="flex-column">
                                                <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                    "mb-2": true,
                                                    active: verticalActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                    toggleVertical("1");
                                                    }}
                                                >

                                                    Games
                                                </NavLink>
                                                </NavItem>



                                                <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                    "mb-2": true,
                                                    active: verticalActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                    toggleVertical("2");
                                                    }}
                                                >
                                                    Items
                                                </NavLink>
                                                </NavItem>


                                                

                                            </Nav>
                                            </Col>
                                            <Col md="9">
                                            <TabContent
                                                activeTab={verticalActiveTab}
                                                className="text-muted mt-4 mt-md-0"
                                            >
                                                <TabPane tabId="1">
                                                <ToolkitProvider
                                                    keyField="_id"
                                                    columns={PackageGameDetails}
                                                    data={packageGames && packageGames || []}
                                                    // data={[]}
                                                    search
                                                >
                                                    {propst => (
                                                    <div>
                                                        <AvForm>
                                                        <Row>
                                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                            </Col>
                                                        </Row>
                                                        </AvForm>
                                                        <BootstrapTable

                                                        {...propst.baseProps}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        hover
                                                        condensed
                                                        />
                                                    </div>
                                                    )}
                                                </ToolkitProvider>

                                                </TabPane>
                                                <TabPane tabId="2">
                                                {/* Item Details */}
                                                <ToolkitProvider
                                                    keyField="_id"
                                                    columns={PackageItemDetails}
                                                    data={packageItems && packageItems || []}
                                                    // data={[]}
                                                    search
                                                >
                                                    {propst => (
                                                    <div>
                                                        <AvForm>
                                                        <Row>
                                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                            </Col>
                                                        </Row>
                                                        </AvForm>
                                                        <BootstrapTable

                                                        {...propst.baseProps}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        hover
                                                        condensed
                                                        />
                                                    </div>
                                                    )}
                                                </ToolkitProvider>

                                                </TabPane>
                                            </TabContent>
                                            </Col>
                                        </Row>
                                        </>
                                    </ModalBody>
                                    </Modal>

                                       {/* view Item Transactions */}
                                        <Modal
                                            size="lg"
                                            isOpen={viewItemTransactionModal}
                                            toggle={() => setviewItemTransactionModal(!viewItemTransactionModal)}
                                        >
                                            <ModalHeader toggle={() => setviewItemTransactionModal(!viewItemTransactionModal)}>
                                            {props.t("Item Transactions  ")}
                                            </ModalHeader>
                                            <ModalBody>

                                                <>
                                                <Row>
                                                    <Col xlg={12}>
                                                    <Card>
                                                        <CardBody>
                                                        <ToolkitProvider
                                                            keyField="_id"
                                                            columns={ItemTransactions}
                                                            data={itemTranscation || []}
                                                            search
                                                        >
                                                            {propst => (
                                                            <div>
                                                                <AvForm>
                                                                <Row>
                                                                    <Col
                                                                    lg={4}
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "flex-start",
                                                                    }}
                                                                    >
                                                                    {/* <SearchBar
                                                                        {...propst.searchProps}
                                                                        style={{ width: "340px" }}
                                                                    /> */}
                                                                    </Col>
                                                                </Row>
                                                                </AvForm>
                                                                <BootstrapTable
                                                                {...propst.baseProps}
                                                                noDataIndication={props.t("No data to display .")}
                                                                striped
                                                                hover
                                                                condensed
                                                                />
                                                            </div>
                                                            )}
                                                        </ToolkitProvider>
                                                        </CardBody>
                                                    </Card>
                                                    </Col>
                                                </Row>
                                                </>
                        
                                            </ModalBody>
                                        </Modal>

                                        {/* view Game Transactions */}
                                        <Modal
                                            size="lg"
                                            isOpen={viewGameTransactionModal}
                                            toggle={() => setviewGameTransactionModal(!viewGameTransactionModal)}
                                        >
                                            <ModalHeader toggle={() => setviewGameTransactionModal(!viewGameTransactionModal)}>
                                            {props.t("Game Transactions ")}
                                            </ModalHeader>
                                            <ModalBody>
                                                <>
                                                <Row>
                                                    <Col xlg={12}>
                                                    <Card>
                                                        <CardBody>
                                                        <ToolkitProvider
                                                            keyField="_id"
                                                            columns={GameTransactions}
                                                            data={gameTranscation || []}
                                                            search
                                                        >
                                                            {propst => (
                                                            <div>
                                                                <AvForm>
                                                                <Row>
                                                                    <Col
                                                                    lg={4}
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "flex-start",
                                                                    }}
                                                                    >
                                                                    {/* <SearchBar
                                                                        {...propst.searchProps}
                                                                        style={{ width: "340px" }}
                                                                    /> */}
                                                                    </Col>
                                                                </Row>
                                                                </AvForm>
                                                                <BootstrapTable
                                                                {...propst.baseProps}
                                                                noDataIndication={props.t("No data to display .")}
                                                                striped
                                                                hover
                                                                condensed
                                                                />
                                                            </div>
                                                            )}
                                                        </ToolkitProvider>
                                                        </CardBody>
                                                    </Card>
                                                    </Col>
                                                </Row>
                                                </>

                                            </ModalBody>
                                        </Modal>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(ServiceDetailsByCustomer)))
