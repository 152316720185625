import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
import moment from "moment"
import logo from "../../assets/images/Logo.png"
import preloader from "../../helpers/Loader"
// import Flatpickr from "react-flatpickr"
const ExpirydateLogs = props => {

    const { SearchBar } = Search
    const code = localStorage.getItem("code")
    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [section, setSection] = useState("");
    const [fromdate, setFromdate] = useState(new Date())
    const [todate, setTodate] = useState(new Date())





    const handleDownload = async () => {

        const fileName = "Expiry Date Logs Report";
        const exportType = "xls";

        // let data_to_export = allExpiryLogs;
        let data_to_export = section !== ""
            ? allExpiryLogs.filter(item => item.itemDetails?.section === section)
            : allExpiryLogs
        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    Batch: v.itemDetails.batch,
                    Item_Name: v.itemDetails.item_details?.name,
                    Section: v.itemDetails.section,
                    Quantity: `${v.itemDetails.quantity.in_unit} ${v.itemDetails.item_details?.unit} ${v.itemDetails.item_details?.sub_unit ? v.itemDetails.quantity.in_sub_unit : ""} ${v.item_details?.sub_unit ? v.item_details?.sub_unit : ""}`,
                    Cost_Price: v.itemDetails.cost_price,
                    Selling_Price: v.itemDetails.selling_price,
                    Expiry_Date: v.itemDetails.expiry_date ? moment(v.itemDetails.expiry_date).format("DD-MM-YYYY") : "",
                    UpdatedBy: v.updatedBy,
                    Updated_On: v.updatedAt ? moment(v.updatedAt).format("DD-MM-YYYY") : "",
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }


    const [allExpiryLogs, setAllExpiryLogs] = useState([])
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: allExpiryLogs && allExpiryLogs.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };

    useEffect(() => {
        handleValidSubmit()
    }, [])


    const handleValidSubmit = async () => {
        let dateFrom = moment(fromdate);
        let dateTo = moment(todate);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
        preloader(true)
        const payload = {
            from_date: moment(fromdate).format("YYYY-MM-DD"),
            to_date: moment(todate).format("YYYY-MM-DD"),
        }
        let url = process.env.REACT_APP_BASEURL + "item_inventory/get_expiry_update_logs"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            preloader(false)
            setAllExpiryLogs(prev => prev = response.data)
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
    }

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const imageHeight = "105px";
        const imageWidth = "110px";
        const borderradius = "50%";
        const background = "#000000";
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 140px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Expiry Date Logs Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function () {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };

    console.log(section);

    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("#"),
            footer:false,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "1%" }
            // },
        },
        {
            text: props.t("Batch"),
            dataField: "itemDetails.batch",
            sort: true,
            footer:false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Item Name"),
            dataField: "itemDetails.item_details.name",
            sort: false,
            footer:false,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "5%" }
            // },
        },
        {
            text: props.t("Section"),
            dataField: "itemDetails.section",
            filter: selectFilter({
                style: { marginLeft: "5px" },
                options: [
                    { value: "Accessories", label: "Accessories" },
                    { value: "Cafe", label: "Cafe" },
                    { value: "Kitchen", label: "Kitchen" },
                ],
                placeholder: "ALL",
                onFilter: (value) => {
                    setSection(value)
                }
            }),
            footer:false,
            // headerStyle: (colum, colIndex) => {
            //     return { width: "5%" }
            // },
        },

        {
            text: props.t("Quantity"),
            dataField: "itemDetails.quantity.in_unit",
            formatter: (cell, row) => {
                return <p>{`${row.itemDetails.quantity.in_unit} ${row.itemDetails.item_details.unit} ${row.itemDetails.item_details.sub_unit ? row.itemDetails.quantity.in_sub_unit : ""} ${row.itemDetails.item_details.sub_unit ? row.itemDetails.item_details.sub_unit : ""}`}</p>
            },
            footer:false,
            sort: true,
        },

        {
            text: props.t("Cost Price"),
            dataField: "itemDetails.cost_price",
            sort: true,
            footer: columnData => {
                const filteredData = section ? allExpiryLogs.filter(data => data.itemDetails.section === section) : allExpiryLogs;
                return filteredData.reduce((acc, item) => acc + item?.itemDetails?.cost_price, 0).toFixed(2);
            }
        },
        {
            text: props.t("Selling Price"),
            dataField: "itemDetails.selling_price",
            sort: true,
            footer: columnData => {
                const filteredData = section ? allExpiryLogs.filter(data => data.itemDetails.section === section) : allExpiryLogs;
                return filteredData.reduce((acc, item) => acc + item?.itemDetails?.selling_price, 0).toFixed(2);
            }   
        },
        {
            text: props.t("Expiry Date"),
            dataField: "itemDetails.expiry_date",
            formatter: (cell, row) => {
                if(row.itemDetails.expiryDate!=="" || row.itemDetails.expiryDate!==null)
                return moment(row.itemDetails.expiry_date).format('YYYY-MM-DD')
            },
            sort: true,
            footer:false,
        },
        {
            text: props.t("Updated On"),
            dataField: "updatedAt",
            formatter: (cell, row) => {
                if(row.updatedAt!=="" || row.updatedAt!==null)
                return moment(row.updatedAt).format('YYYY-MM-DD')
            },
            sort: true,
            footer:false,
        },
        {
            text: props.t("Updated By"),
            dataField: "updatedBy",
            sort: true,
            footer:false,
        },

    ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };




    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Expiry Date Logs Report" breadcrumbItem="Expiry Date Logs Report" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "black",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row >
                                            <Col md={3}>
                                                <label style={{ color: "white" }} className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    options={{

                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {

                                                        const dateString = v;
                                                        setFromdate(new Date(dateString))
                                                        const date = new Date(dateString);
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1;
                                                        const year = date.getFullYear();

                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)

                                                    }}
                                                    value={fromdate}
                                                    name="date_from"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col md={3} >

                                                <label style={{ color: "white" }} className="form-label">
                                                    To Date
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        setTodate(new Date(dateString))
                                                        const date = new Date(dateString);
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1;
                                                        const year = date.getFullYear();
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                                    }}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}
                                                    name="date_to"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>



                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "128px",
                                                    marginTop: "1rem",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }} type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={6}>
                                                {props.t("All Expiry Date Logs ")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={() => { printDiv("printDiv") }}
                                                    disabled={allExpiryLogs.length <= 0}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={allExpiryLogs.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    {/* <CardSubtitle className="mb-3">
                                        {props.t("View All")}
                                    </CardSubtitle> */}
                                    <div id="printDiv">
                                        <ToolkitProvider
                                            keyField="_id"
                                            columns={columns}
                                            data={allExpiryLogs && allExpiryLogs || []}
                                            search

                                        >
                                            {propst => (
                                                <div>
                                                    <AvForm>
                                                        <Row className="hide">
                                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                    <BootstrapTable

                                                        {...propst.baseProps}
                                                        filter={filterFactory()}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        hover
                                                        condensed
                                                    // pagination={paginationFactory(options)}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>





                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(ExpirydateLogs)))
