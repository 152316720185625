import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CardTitle,
  CardSubtitle,
  Input,
  Spinner,
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import BootstrapTable from 'react-bootstrap-table-next';

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import { postSubmitForm } from "../../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, {
  showWarning,
} from "../../../helpers/show_notificaton"
import exportFromJSON from "export-from-json"
import logo from "../../../assets/images/Logo.png"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../../assets/images/Background.png"
import Select from "react-select"
import Linechart from "../../Chart/linechart"
import moment from "moment"
import ItemPie from "../../Chart/ItemPie"




export const TableLoader = () => {
  // Inline styles for the container
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh', // Full viewport height
  };

  // Inline styles for the spinner
  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
  };

  return (
    <Container style={containerStyle}>
      <Row>
        <Col className="d-flex justify-content-center">
          <Spinner animation="border" role="status" style={spinnerStyle}>
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </Container>
  );
};



const ItemSales = props => {
  const { SearchBar } = Search
  const [disabledStatus, setDisabledStatus] = useState(true)
  const [minDateVal, setMinDateVal] = useState("")
  const [branches, setBranches] = useState([])
  const [allItemsSales, setAllItemsSales] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalPage, setTotalPage] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(100)
  const [loading,setLoading] = useState(false);
  const [todayDate, setTodayDate] = useState(
    new Date().toISOString().split("T")[0]
  )
  const [piedata, setPiedata] = useState()
  const [chartData, setChartData] = useState("")
  const [fromdate, setFromdate] = useState({
    date_from: null,
    date_to: null,
  })
  const branchesRef = useRef([])
  const refContainer = useRef(null)
  const code = localStorage.getItem("code")

  const handleDownload = async () => {
    const fileName = "Items Sales Report"
    const exportType = "xls"

    let data_to_export = allItemsSales

    if (data_to_export) {
      var data = []
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          Branch: v.branch_details?.name,
          Item: v.item_details?.name,
          Section: v.item_details?.section,
          Formula: v.item_details?.formula,
          Units: v.item_details?.quantity?.in_unit,
          Sub_Units: v.item_details?.quantity?.in_sub_unit,
          Selling_Price: v.item_details?.price,
          Total_Price: v.item_details?.sub_total,
          Customer: v.customer_details?.name,
          Emp_Name: v.emp_details?.name,
          Date: moment(v.createdAt).format("YYYY-MM-DD"),
        })
      })
      exportFromJSON({ data, fileName, exportType })
    }
  }

  const printDiv = divName => {
    let content = document.getElementById(divName)
    var printWindow = window.open(
      "",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0"
    )
    const imageHeight = "105px"
    const imageWidth = "110px"
    const borderradius = "50%"
    const background = "#000000"
    printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            #sale{
                width: 150px;
                margin: auto;
             }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 200px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Items Sales Report</h2>
       </div>
       <h4 id="sale"> Total Sale : ${totalAmount || 0}</h4>
      ${content.innerHTML}
        </body>
           </html>
    
        `)
    printWindow.document.close()
    const logoImg = printWindow.document.getElementById("logoImg")
    logoImg.onload = function () {
      printWindow.focus()
      printWindow.print()
      printWindow.close()
    }
  }

  useEffect(() => {
    loadGameSalesReportOnFirst()
    loadAllBranches()
  }, [])
  useEffect(() => {
    if (fromdate.date_from && fromdate.date_to) {
      loadGameSalesReport(null, fromdate)
    }
  }, [pageNumber])

  const handlePageChange = page => {
    setPageNumber(page)
  }

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const goToNextPage = () => {
    if (pageNumber < totalPage) {
      setPageNumber(pageNumber + 1)
    }
  }

  const generatePageButtons = () => {
    const buttonsToShow = 5
    const totalPagesLeft = totalPage - pageNumber
    const startIndex = Math.max(1, pageNumber - Math.floor(buttonsToShow / 2))
    let endIndex = Math.min(totalPage, startIndex + buttonsToShow - 1)

    if (totalPagesLeft < Math.floor(buttonsToShow / 2)) {
      endIndex = totalPage
    }

    const pageButtons = []
    for (let i = startIndex; i <= endIndex; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          style={{
            margin: "0 5px",
            padding: "10px 15px",
            borderRadius: "5px",
            cursor: "pointer",
            backgroundColor: pageNumber === i ? "#556EE6" : "",
            color: pageNumber === i ? "white" : "black",
            border: "none",
            outline: "none",
            transition: "background-color 0.3s, color 0.3s",
          }}
          className={
            i === pageNumber
              ? "font-bold text-2xl rounded-x px-4 bg-blue-800 rounded-xl"
              : "font-semibold text-xl rounded-xl px-3  bg-blue-600"
          }
        >
          {i}
        </button>
      )
    }
    return pageButtons.length==0? <button
    style={{
      margin: '0 5px',
      padding: '10px 15px',
      borderRadius: '5px',
      cursor: 'pointer',
      backgroundColor: '#556EE6' ,
      color:'white',
      border: 'none',
      outline: 'none',
      transition: 'background-color 0.3s, color 0.3s',
    }}
    className={
      
        "font-bold text-2xl rounded-x px-4 bg-blue-800 rounded-xl"
       
    }
  >
    {1}
  </button>:pageButtons
  }


  const loadGameSalesReportOnFirst = async () => {
    let branchesResponse = await postSubmitForm(
      process.env.REACT_APP_BASEURL + "branches/getall",
      ""
    )
    if (branchesResponse && branchesResponse.status === 1) {
      let branchList = branchesResponse.data
      branchList.filter(brn => brn.name === "Rendezvous")
      setBranches(branchList)
      branchesRef.current = branchList
    } else {
      console.log("Error fetching branches")
    }
    setTodayDate(new Date().toISOString().split("T")[0])
    const payloadSales = {
      date_from: todayDate || v.date_from,
      date_to: todayDate || v.date_to,
      branch_id: branchesRef.current[0]?._id,
      type: "items",
      limit: 100,
      page: pageNumber,
    }

    setLoading(true)

    try {
    let salesUrl = process.env.REACT_APP_BASEURL + "reports/sales"
    let salesResponse = await postSubmitForm(salesUrl, payloadSales)

    if (salesResponse && salesResponse.status === 1) {
      // refContainer.current.reset();
      setAllItemsSales(salesResponse?.data)
      setTotalPage(salesResponse.totalPages)
      setTotalAmount(salesResponse.totalSaleAmount);
      setLoading(false)
    } else {
      setLoading(false)
      showNotification(salesResponse.message, "Error")
    }
  } catch (error) {
      setLoading(false)
  }
    const payload = {
      date_from: moment(todayDate).format("YYYY-MM-DD"),
      date_to: moment(todayDate).format("YYYY-MM-DD"),
    }
    let PieUrl = process.env.REACT_APP_BASEURL + "reports/items_sale_trend"
    let PieResponse = await postSubmitForm(PieUrl, payload)
    if (PieResponse && PieResponse.status === 1) {
      setPiedata(PieResponse.data)
      setChartData(PieResponse.charts)
    } else {
      showNotification(PieResponse.message, "Error")
    }
  }


  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      let branchList = response.data
      branchList.filter(brn => brn.name === "Rendezvous")
      setBranches(branchList)
      branchesRef.current = branchList
    } else {
      console.log("Error")
    }
  }

  const loadGameSalesReport = async (e, v) => {
    let dateFrom = moment(v.date_from);
    let dateTo = moment(v.date_to);
    let totalDays = dateTo.diff(dateFrom, 'days') + 1;
    
    if(totalDays>31){
        showWarning("Total number of days cannot be more than 31.", "Warning");
        return ;
    }
    if(e){
      setPageNumber(1)
    }
    setAllItemsSales([])
    let setDatePayload = {
      date_from: v.date_from,
      date_to: v.date_to,
    }

    setFromdate(setDatePayload)
    const payloadSales = {
      date_from: v.date_from,
      date_to: v.date_to,
      branch_id: branchesRef.current[0]?._id,
      type: "items",
      limit: 100,
      page: pageNumber,
    }
    setLoading(true)
    try {
    let url = process.env.REACT_APP_BASEURL + "reports/sales"
    const response = await postSubmitForm(url, payloadSales)
    if (response && response.status === 1) {
      // console.log({total:response.data.length});
      // refContainer.current.reset()
      setAllItemsSales(response.data)
      setTotalAmount(response.totalSaleAmount);
      setTotalPage(response.totalPages)
      setLoading(false)
    } else {
      showNotification(response.message, "Error")
    }

  } catch (error) {
      setLoading(false)
  }
    const payload = {
      date_from: v.date_from,
      date_to: v.date_to,
    }
    let PieUrl = process.env.REACT_APP_BASEURL + "reports/items_sale_trend"
    let PieResponse = await postSubmitForm(PieUrl, payload)
    if (PieResponse && PieResponse.status === 1) {
      setPiedata(PieResponse.data)
      setChartData(PieResponse.charts)
    } else {
      showNotification(PieResponse.message, "Error")
    }
  }

  const handleOpenInvoice = (cell, row) => {
    if (row.booking_id) {
      return (
        <>
          <i
            style={{
              color: "blue",
              cursor: "pointer",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <a
              href={"/invoice_details?booking_id=" + row.booking_id}
              target="_blank"
              rel="noreferrer"
            >
              {row.invoice_no}
            </a>
          </i>
        </>
      )
    }
    return <span style={{ color: "gray" }}>Not available</span>
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1)
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Invoice No"),
      dataField: "invoice_no",
      sort: true,
      formatter: handleOpenInvoice,
    },
    {
      text: props.t("Item Name"),
      dataField: "item_details.name",
      sort: false,
    },
    {
      text: props.t("Section"),
      dataField: "item_details.section",
      sort: true,
    },
    {
      text: props.t("Category"),
      dataField: "item_details.category",
      sort: false,
    },
    // {
    //   text: props.t("Price"),
    //   dataField: "item_details.price",
    //   sort: true,
    // },
    {
      text: props.t("Paid Amount"),
      dataField: "final_amount",
      sort: true,
    },
    {
      text: props.t("Customer Name"),
      dataField: "customer_details.name",
      sort: true,
    },
    {
      text: props.t("Customer Mobile"),
      dataField: "customer_details.mobile",
      sort: true,
    },
    {
      text: props.t("Employee"),
      dataField: "emp_details.name",
      sort: false,
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      sort: false,
      formatter: (cell, row) => {
        return moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
      },
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Items Sales Report"
            breadcrumbItem="Items Sales Report"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "black",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm
                    onValidSubmit={(e, v) => {
                      loadGameSalesReport(e, v)
                    }}
                    ref={refContainer}
                  >
                    <Row>
                      <Col lg={3}>
                        <label
                          style={{ color: "white" }}
                          className="form-label"
                        >
                          {props.t("From Date")}
                        </label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={todayDate}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          onChange={v => {
                            const dateString = v
                            const date = new Date(dateString)
                            // Extract the date components
                            const day = date.getDate()
                            const month = date.getMonth() + 1 // Months are zero-based, so add 1
                            const year = date.getFullYear()
                            // Format the date as "dd-mm-yyyy"
                            const formattedDate = `${year}-${month
                              .toString()
                              .padStart(2, "0")}-${day
                              .toString()
                              .padStart(2, "0")}`

                            // console.log(formattedDate); // Output: 26-12-2023
                            setMinDateVal(formattedDate)
                            setDisabledStatus(false)
                            //   setMaxDateOnChangeActive(false)
                            // }
                          }}
                          name="date_from"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col lg={3}>
                        <label
                          style={{ color: "white" }}
                          className="form-label"
                        >
                          {props.t("To Date")}
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={todayDate}
                          onChange={v => {
                            const dateString = v
                            const date = new Date(dateString)
                            const day = date.getDate()
                            const month = date.getMonth() + 1 // Months are zero-based, so add 1
                            const year = date.getFullYear()
                            const formattedDate = `${year}-${month
                              .toString()
                              .padStart(2, "0")}-${day
                              .toString()
                              .padStart(2, "0")}`
                          }}
                          options={{
                            minDate: minDateVal,
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="date_to"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      {/* <Col lg={4}>

                              <Label style={{color:"white"}}>Branch</Label>
                              <Select
                                placeholder="Select Branch"
                                onChange={(selected) => { (setSelectedBranch(selected))}}
                                value={selectedBranch}
                                options={branches}
                                isClearable={true}
                                isSearchable
                                required
                              />
                            </Col> */}

                      <Col
                        lg={2}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label
                          style={{ height: "0px" }}
                          className="form-label"
                        ></label>
                        <Button
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "128px",
                            marginTop: "1rem",
                            // margin: "auto",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          type="submit"
                          // color="primary"
                          // className="btn btn-info btn-md"
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          {props.t("Show")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <CardTitle>{props.t("Sales Chart")}</CardTitle>
                    </Col>
                  </Row>

                  <div id="line-chart" className="e-chart">
                    <Linechart line_data={chartData} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>{props.t("Items Chart")}</CardTitle>
                  <div id="pie-chart" className="e-chart">
                    <ItemPie data={piedata} name={props.t("Items Pie")} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>{props.t("Items Sales Report")}</Col>

                      <Col
                        lg={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{
                            width: "115px",
                            height: "35px",
                            background: "#1673F7",
                            border: "none",
                            fontSize: "12px",
                            marginRight: "10px",
                          }}
                          className="btn btn-sm btn-secondary"
                          disabled={allItemsSales.length <= 0}
                          onClick={() => {
                            printDiv("printDiv")
                          }}
                        >
                          <i
                            className="fa fa-print "
                            aria-hidden="true"
                            onClick={() => {
                              printDiv("printDiv")
                            }}
                          />
                          &nbsp; {props.t("Print")}
                        </Button>
                        <Button
                          style={{
                            width: "115px",
                            height: "35px",
                            background: "#F93A5C",
                            border: "none",
                          }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={allItemsSales.length <= 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                                        {props.t("View All")}
                                    </CardSubtitle> */}
                  <div id="printDiv">
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={allItemsSales || []}
                      search
                    >
                      {propst => (
                        <div>
                          <AvForm>
                            <Row className="hide">
                              {/* <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps}  />
                                                        </Col> */}
                              <Col lg={4}>
                                <Label className="fs-5">

                                  {props.t("Total Item Sale :")}&nbsp;{" "}
                                  <strong>{totalAmount.toFixed(2)}</strong>{" "}
                                </Label>
                              </Col>
                            </Row>
                          </AvForm>
                          <BootstrapTable
                            {...propst.baseProps}
                            noDataIndication={loading?<TableLoader/>:props.t("No data to display.")}
                            striped
                            hover
                            condensed
                            // pagination={ paginationFactory(options) }
                          />

                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            <button
                              onMouseOver={e => {
                                e.target.style.backgroundColor =
                                  pageNumber !== 1 ? "darkgray" : "lightgray"
                                e.target.style.color = "white"
                              }}
                              onMouseOut={e => {
                                e.target.style.backgroundColor = "lightgray"
                                e.target.style.color = "black"
                              }}
                              style={{
                                margin: "0 10px",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                backgroundColor: "lightgray",
                                color: "black",
                                border: "none",
                                outline: "none",
                                transition: "background-color 0.3s, color 0.3s",
                                opacity: pageNumber === 1 ? 0.5 : 1,
                                pointerEvents:
                                  pageNumber === 1 ? "none" : "auto",
                              }}
                              onClick={goToPrevPage}
                              disabled={pageNumber === 1}
                            >
                              PREV
                            </button>
                            <div style={{ display: "inline-block" }}>
                              {generatePageButtons()}
                            </div>
                            <button
                              onClick={goToNextPage}
                              disabled={pageNumber === totalPage}
                              style={{
                                margin: "0 10px",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                backgroundColor: "lightgray",
                                color: "black",
                                border: "none",
                                outline: "none",
                                transition: "background-color 0.3s, color 0.3s",
                                opacity: pageNumber === totalPage ? 0.5 : 1,
                                pointerEvents:
                                  pageNumber === totalPage ? "none" : "auto",
                              }}
                              onMouseOver={e => {
                                e.target.style.backgroundColor =
                                  pageNumber !== totalPage
                                    ? "darkgray"
                                    : "lightgray"
                                e.target.style.color = "white"
                              }}
                              onMouseOut={e => {
                                e.target.style.backgroundColor = "lightgray"
                                e.target.style.color = "black"
                              }}
                            >
                              NEXT
                            </button>
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ItemSales)))
