import React, { useState, useEffect } from "react"
import axios from "axios"
import border from "../../assets/images/Border.png"
import "./allBooking.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import swal from "sweetalert2"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../helpers/backend_helper"
import Swal from "sweetalert2"
import CustomButton from "../../components/Common/CustomButton"
import BackgroundImage from "../../assets/images/Background.png"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import moment, { duration } from "moment"
import { subtract } from "lodash"
import Loader from "../../helpers/Loader"
import { Link } from "react-router-dom"
import { logDOM } from "@testing-library/react"

const AddGames = props => {
  const [gameDetails, setGameDetails] = useState([])
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItemDetails, setSelectedItemDetails] = useState(null)
  const [selectedGameIndex, setSelectedGameIndex] = useState(null)
  const [updatedItemDetails, setUpdatedItemDetails] = useState([])
  const [itemTotalPrice, setItemTotalPrice] = useState(0)
  const [allItems, setAllItems] = useState([])
  const [verticalActiveTab, setVerticalActiveTab] = useState("1")
  const [originalItemQuantities, setOriginalItemQuantities] = useState({})
  const [toggleModal, setToggleModal] = useState(false)

  //Search items states
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("")
  const [searchTimeout, setSearchTimeout] = useState(null)

  const [tablesToDisplay, setTablesDisplay] = useState([])
  const [selectedTable, setSelectedTable] = useState([])
  const [availableTables, setAvailableTables] = useState([])
  const [saveAllAvailable, setAllAvailable] = useState([])
  const [selected_game, setSelected_game] = useState([])
  const [allTheGames, setAllTheGames] = useState([])
  const [allTableData, setAllTableData] = useState([])
  const [isoDate2, setIsoDate2] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [subTotal, setSubTotal] = useState({})
  const [durationValidation, setDurationValidation] = useState({})
  const [startValidation, setStartValidation] = useState(false)
  const [allBooking, setAllBooking] = useState(null)
  const [selectedBooking, setSelectedBooking] = useState([])
  const [bookingTotal, setBookingTotal] = useState(0)

  const [customStyles, setCustomStyles] = useState({})
  const [preSaveColor, setPreSaveColor] = useState([])
  const [startTableTime, setTableStartTime] = useState()
  const [endTableTime, setTableEndTime] = useState()
  const [disabletrack, setDisableTrack] = useState(false)

  // const[instant, setInstant] = useState({})

  useEffect(() => {
    // console.log(preSave)
    setCustomStyles(
      prev =>
        (prev = {
          control: styles => ({ ...styles, color: "white" }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // console.log(isDisabled, "disabled")
            const color = "white"
            return {
              ...styles,
              backgroundColor: isDisabled
                ? "black"
                : isSelected
                ? "white"
                : isFocused
                ? "#566ce3"
                : undefined,
              color: isFocused ? "white" : "black",
              cursor: isDisabled ? "not-allowed" : "default",
              ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                  ? isSelected
                    ? "white"
                    : "#566ce3"
                  : undefined,
              },
            }
          },
          multiValue: (styles, { data }) => {
            // const color = "#eff2f7";
            let color = ""
            let isSelectedGameNoTables = []
            if (preSaveColor.length === 0) {
              color = "#eff2f7"
            } else {
              isSelectedGameNoTables = preSaveColor.some(
                selected => selected === data.label
              )
              color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
            }
            return {
              ...styles,
              backgroundColor: color,
            }
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "black",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "black",
            ":hover": {
              backgroundColor: "#f0b4b4",
              color: "red",
            },
          }),
        })
    )
  }, [preSaveColor])

  // const [justifyContentStyles, setJustifyContentStyles] = useState({})

  useEffect(() => {
    loadGameBookingDetails()
    loadAllItems()
    setItemTotalPrice(0)
    loadAllGame()
  }, [])

  const loadAllGame = async () => {
    let url = process.env.REACT_APP_BASEURL + "games/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      // console.log(response.data, "all games data")
      let customGameList = response.data
        .filter(game => game.is_active !== false)
        .map(game => ({ label: game.name, value: game.name, id: game._id }))

      setAllTheGames(prev => (prev = customGameList))
    } else {
      showNotification(response.message, "Error")
    }
  }

  async function fetchAvailableGameTables(selectedGame) {
    // console.log(isoDate2, "iso date 2")
    let payload = {
      // start_time: new Date(isoDate2),
      game_id: selectedGame.option.id,
    }

    // return;
    let url = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"

    // let response = await postSubmitForm(url, payload);
    axios
      .post(url, payload, {
        headers: {
          Authorization: `Bearer ${employeeToken}`,
        },
      })
      .then(res => {
        //  res.data.data.forEach(item => (item.game.duration = 0))
        let Filterdata = res.data.data.filter((data)=>(data.is_active===true && data.is_reserved_for_open===false))
        Filterdata.forEach(item => (item.game.duration = 0))
        // let tableList = res.data.data .map(game => ({
        let tableList = Filterdata?.map(game => ({
          label: game.table_number,
          value: game.table_number,
          id: game._id,
          name: game.game.name,
        }))
        setAvailableTables(prev => (prev = [...prev, ...tableList]))
        setAllAvailable(prev => (prev = [...prev, ...tableList]))
        setAllTableData(prev => (prev = [...prev, ...res.data.data]))
        let newPreSaveColor = preSaveColor.filter(
          color => color !== selectedGame.option.label
        )
        setPreSaveColor(prev => (prev = newPreSaveColor))
      })
      .catch(err => {
        setPreSaveColor(prev => (prev = [...prev, selectedGame.option.label]))
        // setCurrentSelectedGame(prev => prev = selectedGame)
        showNotification(err?.response?.data?.message, "Error")
      })

    // if (response && response.status === 1) {
    //     // response.data.map(item => {
    //     //   item.label = item.name
    //     //   item.value = item._id
    //     // })
    //     if (response.data.length === 0) {
    //         showNotification("No Tables Found", "Error");
    //         return;
    //     }

    // response.data.forEach(item => item.game.duration = 0);
    // let tableList = response.data.map(game => ({ label: game.table_number, value: game.table_number, id: game._id, name: game.game.name }));
    // setAvailableTables(prev => prev = [...prev, ...tableList]);
    // setAllAvailable(prev => prev = [...prev, ...tableList])
    // setAllTableData(prev => prev = [...prev, ...response.data])
    //     // console.log(response.data, "available table data")

    // } else if(response && response.status === 0){
    //     console.log(response,"prateeek")
    // }
  }

  function preventValueChangeOnScroll(e) {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  // console.log(toggleModal, "modal state")
  const openBookingModal = (clickedBooking, index) => {
    const selectedBooking = allBooking.filter(
      booking => clickedBooking.booking_id === booking._id
    )
    localStorage.setItem("instantcheckout", JSON.stringify(selectedBooking[0]))

    //    setInstant(selectedBooking[0])
    setBookingTotal(prev => (prev = selectedBooking[0].total_price))
    setSelectedBooking(prev => (prev = selectedBooking))

    setSelectedCustomer(prev => (prev = clickedBooking.customer_details))
    setIsoDate2(prev => (prev = clickedBooking.start_time))

    // openItemDetailsModal(index)
  }
  // console.log(instant,"Instant")

  const loadAllItems = async () => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "bookings/get_available_items"
    const requestData = {
      search: searchQuery,
    }
    let response = await postSubmitForm(url, requestData)
    if (response && response.status === 1) {
      Loader(false)
      const items = response.data
      let updatedItems = response.data?.forEach(item => {
        for (let i = 0; i < updatedItemDetails?.length; i++) {
          if (updatedItemDetails[i]._id === item._id) {
            item.quantity.in_sub_unit =
              updatedItemDetails[i].quantity.in_sub_unit
            item.sub_total = updatedItemDetails[i].sub_total
          }
        }
      })
      setAllItems(
        (updatedItems?.length > 0 ? updatedItems : items)?.filter(
          item => item.section !== "Kitchen" && item.section !== "Accessories"
        )
      )
    } else {
      Loader(false)
      setAllItems([])
      console.log("Error")
    }
  }

  useEffect(() => {
    if (searchQuery !== debouncedSearchQuery) {
      clearTimeout(searchTimeout)
      const timeout = setTimeout(() => {
        setDebouncedSearchQuery(searchQuery)
      }, 300)

      setSearchTimeout(timeout)
    }
  }, [searchQuery])

  useEffect(() => {
    loadAllItems()
  }, [debouncedSearchQuery])

  const loadGameBookingDetails = async () => {
    const url = process.env.REACT_APP_BASEURL + "bookings/get_in_process"

    try {
      Loader(true)
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${employeeToken}`,
          },
        }
      )

      if (response.data.status === 1) {
        Loader(false)
        // console.log(response.data.data, "all booking data")
        setAllBooking(prev => (prev = response.data.data))
        const fetchedBookings = response.data.data
        // const filteredBookings = fetchedBookings.filter(
        //     booking => booking.game_details.length > 0
        // )

        const gameDetails = fetchedBookings.map(booking => {
          let game_details = booking.game_details.map(gameDetail => ({
            image: gameDetail?.game?.image,
            table_number: gameDetail?.table_number || "",
          }))
          // For packages
          return {
            start_time: booking.start_time,
            booking_id: booking._id,
            // total_price: booking.total_price,
            item_details: booking.item_details || [],
            package_details: booking.package_details || [],
            customer_details: booking.customer_details || [],
            game_details: game_details,
            booking_id: booking._id,
          }
        })

        setIsLoading(false)

        setGameDetails(gameDetails)
      }
    } catch (error) {
      Loader(false)
      console.error("Error fetching data:", error)
    }
  }
  console.log(gameDetails, "Hello")
  useEffect(() => {}, [updatedItemDetails])

  const openItemDetailsModal = index => {
    setSelectedGameIndex(index)
    setSelectedItemDetails(gameDetails[index]?.item_details)
    // setTotalPrice(gameDetails[index]?.total_price)

    // Filter out items from the "In Process" tab and initialize updatedItemDetails with an empty array

    setUpdatedItemDetails([])

    loadAllItems()
  }

  // console.log(selectedGameIndex, "GAME INDEX")

  let TotalGamePrice = tablesToDisplay.reduce(
    (acc, obj) => acc + obj.game.sub_total,
    0
  )
  let TotalTime = tablesToDisplay.reduce(
    (acc, obj) => acc + obj.game.duration,
    0
  )
  // console.log(TotalGamePrice, "games total price")

  const handleDurationChange = async (e, item) => {
    if (!item.game) {
      console.error("item.game is undefined")
      return
    }

    // console.log(item, "selected item")
    if (e.target.value < 30 || e.target.value === "") {
      setDurationValidation(
        prev => (prev = { ...prev, [item._id + item.game.name]: true })
      )

      return
    } else {
      setDurationValidation(
        prev => (prev = { ...prev, [item._id + item.game.name]: false })
      )
    }

    let price =
      selectedCustomer?.customer_type === "Friend"
        ? item.game.price_for_friend
        : selectedCustomer?.customer_type === "Regular"
        ? item.game.price_for_regular
        : item.game.price_for_vip

    if (item.game.name.includes("V.I.P")) {
      if (+e.target.value > 60) {
        let sixtyMin = 60
        let rem = +e.target.value - sixtyMin

        item.game.duration = +e.target.value
        item.game.sub_total = (150 / 60) * sixtyMin + (100 / 60) * rem
        item.game.sub_total = +item.game.sub_total.toFixed(2)
        // item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
      } else {
        item.game.duration = +e.target.value
        item.game.sub_total = (150 / 60) * +e.target.value
        item.game.sub_total = +item.game.sub_total.toFixed(2)
        // item.game.new_duration = (+item.game.duration) - (+item.game.requested_duration)
      }
    } else {
      item.game.duration = +e.target.value
      item.game.sub_total = +(+e.target.value * (price / 60)).toFixed(2)
    }
    const durationInMinutes = e.target.value
    const NewstartTime = item.game.table_start_time
    const NewendTime = new Date(
      NewstartTime.getTime() + durationInMinutes * 60000
    )
    setTableEndTime(prev => (prev = NewendTime))
    item.game.table_end_time = NewendTime
    let payload = {
      start_time: NewstartTime,
      end_time: NewendTime,
      game_id: item.game.game_id,
    }
    //    return console.log(payload, "PAYLOAD HANDLE DURATION")
    // console.log(row, "ROW");
    let PieUrl =
      process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(PieUrl, payload)
    if (
      res &&
      res.status === 1 &&
      res.data.some(table => table.table_number === item.table_number)
    ) {
      // console.log(row,"ROW");
      showWarning("Table is Available", "Success")
    } else {
      showWarning("Table is busy", "warning")
    }

    // setGameTotalPrice(prev => prev += item.game.sub_total);
    // setTotalTime(prev => prev = Number(e.target.value));

    setSubTotal(prev => ({
      ...prev,
      [item._id + item.game.name]: item.game.sub_total, // Update sub_total for the current item
    }))

    // const updatedMaxDuration = selectedBooking[0]?.game_details.reduce((max, item) => {
    //     const gameDuration = item.game.duration || 0;
    //     return Math.max(max, gameDuration);
    // }, 0);
    // const input = e.target.value;

    // const endTime = new Date(selectedBooking[0].end_time);
    // // console.log(startTime,"START TIME");
    // const maxDuration = parseInt(updatedMaxDuration);
    // if (!isNaN(maxDuration)) {
    // //  console.log(input, "E VALUE");
    // //  console.log( maxDuration, "max duration");
    //  const durationToUse = Math.max(updatedMaxDuration, input);
    // //  console.log(durationToUse,"duration to use")
    //   const updatedET = new Date(endTime.getTime() + durationToUse * 60000);

    //   setEnddTime(prev => prev = updatedET);

    // } else {
    //   console.error('Invalid input for minutes');
    // }
    // console.log(selectedBooking,"td");
  }
  // console.log(enddTime, "END TIME");

  const handleStartTime = async (e, item) => {
    if (!e.target.value || isNaN(Date.parse(e.target.value))) {
      return
    }
    if (new Date(e.target.value) < new Date(selectedBooking[0].start_time)) {
      setStartValidation(true)
    } else {
      setStartValidation(false)
    }
    const durationInMinutes = item.game.duration
    const NewstartTime = new Date(e.target.value)
    const NewendTime = new Date(
      NewstartTime.getTime() + durationInMinutes * 60000
    )
    item.game.table_end_time = NewendTime

    let payload = {
      start_time: NewstartTime,
      end_time: NewendTime,
      game_id: item.game.game_id,
    }
    // return console.log(payload, "PAYLOAD HANDLE START")
    // console.log(row, "ROW");
    let PieUrl =
      process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date"
    let res = await postSubmitForm(PieUrl, payload)
    if (res && res.status === 1 && res.data.some(item => item.table_number)) {
      // console.log(row,"ROW");
      const updatedTableData = { ...item }
      const rowData = updatedTableData
      rowData.game.table_start_time = new Date(e.target.value)
      const newFormattedTime = moment(NewstartTime).format(
        "YYYY-MM-DDTHH:mm:ss"
      )

      if (
        item.length >= 1 &&
        selectedBooking[0].start_time <= newFormattedTime
      ) {
        // console.log(1);
        setTableStartTime(selectedBooking[0].start_time)
      } else {
        // console.log(2);
        setTableStartTime(newFormattedTime)
        setDisableTrack(true)
      }
      // setGamesInTable(prev=> prev=[updatedTableData]);
    } else {
      showWarning("Table is busy", "warning")
    }
  }

  // console.log(endTableTime , "Table end time");
  // console.log(new Date(selectedBooking[0]?.end_time), "Booking end time")
  // console.log(new Date(selectedBooking[0]?.end_time)> endTableTime)
  // console.log(enddTime,"Edndd");
  // console.log(selectedBooking,"SB");

  // const total_price = totalPrice + itemTotalPrice
  // console.log(subTotal, "sub total")
  // console.log(tablesToDisplay, "talbes to display")
  // console.log(durationValidation, "duration validation")

  useEffect(() => {
    const originalQuantities = {}
    allItems.forEach(item => {
      originalQuantities[item._id] = item.quantity.in_sub_unit
    })

    setOriginalItemQuantities(originalQuantities)
  }, [allItems])

  const confirmChanges = async () => {
    let BlankDurationFieldFlag = false
    let noInputFlag = false

    for (let key in durationValidation) {
      if (durationValidation[key] === true) {
        BlankDurationFieldFlag = true
        break
      }
    }

    tablesToDisplay.forEach(item => {
      if (item.game.duration === 0) {
        noInputFlag = true
        return
      }
    })

    if (BlankDurationFieldFlag === true || noInputFlag === true) {
      showNotification("Enter duration of all selected Games", "Error")
      return
    }

    // if (selectedCustomer.customer_type !== "VIP") {

    //     tablesToDisplay.forEach(item => {

    //         if (!subTotal[item._id]) {
    //             BlankDurationFieldFlag = true;
    //             return;
    //         }
    //     });

    // if (BlankDurationFieldFlag === true && durationValidation[selectedBooking[0]._id] === true) {
    //     showNotification("Enter duration of all selected Games", "Error");
    //     return;
    // }
    // }

    const url = process.env.REACT_APP_BASEURL + "bookings/update"

    try {
      let payload = selectedBooking[0]
      payload.start_time = new Date(selectedBooking[0]?.start_time)
      payload.end_time =
        new Date(selectedBooking[0].end_time) > endTableTime
          ? new Date(selectedBooking[0].end_time)
          : endTableTime
      // payload.end_time = enddTime,
      payload.game_details.push(...tablesToDisplay)
      payload.booking_id = selectedBooking[0]._id
      payload.game_total_price += TotalGamePrice
      payload.total_price += TotalGamePrice
      payload.total_time += TotalTime
      // console.log(payload, "payload")
      // return;

      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${employeeToken}`,
        },
      })

      if (tablesToDisplay.length === 0) {
        showNotification("No Games Selected", "Error")
      } else if (response.data.status === 1 && tablesToDisplay.length > 0) {
        swal.fire("Success", response.data.message, "success")
        loadGameBookingDetails()
        setAvailableTables(prev => (prev = []))
        setSelectedTable(prev => (prev = []))
        setAllTheGames(prev => (prev = []))
        setSelected_game(prev => (prev = []))
        TotalGamePrice = 0
        TotalTime = 0
        setTablesDisplay(prev => (prev = []))
        setAllTableData(prev => (prev = []))
        setSubTotal(prev => (prev = {}))
        setDurationValidation(prev => (prev = {}))
        setTableStartTime()
        setTableEndTime()
        loadAllGame()
        setToggleModal(prev => (prev = !prev))
        setCustomStyles(
          prev =>
            (prev = {
              control: styles => ({ ...styles, color: "white" }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                // console.log(isDisabled, "disabled")
                const color = "white"
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? "gray"
                    : isSelected
                    ? "white"
                    : isFocused
                    ? "#566ce3"
                    : undefined,
                  color: isFocused ? "white" : "black",
                  cursor: isDisabled ? "not-allowed" : "default",
                  ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled
                      ? isSelected
                        ? "white"
                        : "#566ce3"
                      : undefined,
                  },
                }
              },
              multiValue: (styles, { data }) => {
                // const color = "#eff2f7";
                let color = ""
                let isSelectedGameNoTables = []
                if (preSaveColor.length === 0) {
                  color = "#eff2f7"
                } else {
                  isSelectedGameNoTables = preSaveColor.some(
                    selected => selected === data.label
                  )
                  color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
                }
                return {
                  ...styles,
                  backgroundColor: color,
                }
              },
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: "black",
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: "black",
                ":hover": {
                  backgroundColor: "#f0b4b4",
                  color: "red",
                },
              }),
            })
        )
      } else {
        swal.fire("Error", response.data.message, "error")
      }
    } catch (error) {
      console.error("Error updating item details:", error)
    }
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab)
    }
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  const maxWidth =
    window.innerWidth < 768
      ? "100%"
      : window.innerWidth < 1200
      ? "750px"
      : "1100px"

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Booking"
            alternateTitle="In Process Bookings"
            breadcrumbItem1="In Process Bookings"
          />
          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <Row>
                    <Col lg={12} style={{ margin: "auto" }}>
                      <Row
                        style={{ display: "flex", gap: "50px", margin: "auto" }}
                      >
                        {gameDetails.length === 0 && (
                          <Col
                            lg={12}
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              paddingTop: "15px",
                            }}
                          >
                            <h5>No in process bookings found.</h5>
                          </Col>
                        )}
                        <Row
                          style={{
                            display: "flex",
                            margin: "auto",
                            gap: "35px",
                            justifyContent: "center",
                          }}
                        >
                          {gameDetails?.map((booking, index) => (
                            <Col
                              sm="6"
                              md="5"
                              lg="3"
                              key={index}
                              style={{
                                cursor: "pointer",
                                paddingTop: "10px",
                                height: "238px",
                                borderRadius: "20px",
                                backgroundColor: "#f5f1f0",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.45) 0px 12.5px 10px -10px",
                              }}
                              onClick={() => {
                                setToggleModal(prev => (prev = !prev))
                                console.log(booking)
                                openBookingModal(booking, index)
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                {/* Case 1: Display game_details */}
                                {booking.game_details.length > 0 && (
                                  <div
                                    className="scroll-container"
                                    // style={{
                                    //   justifyContent:
                                    //     booking.game_details.length >= 3 ? "unset" : "center",
                                    // }}
                                  >
                                    {booking.game_details.map(
                                      (game, gameIndex) => (
                                        <div
                                          className="game"
                                          key={gameIndex}
                                          // onClick={() => openItemDetailsModal(gameIndex)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={game.image}
                                            alt={`Game ${gameIndex}`}
                                            className="game-image"
                                          />
                                          <div className="table-number-overlay">
                                            <p
                                              style={{
                                                fontSize: booking.game_details.length>=2 ? "13px": "15px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {game?.table_number}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}

                                {/* Case 2: Display package_details */}
                                {booking.package_details.length > 0 && (
                                  <div
                                    className="scroll-container"
                                    // style={{
                                    //   justifyContent:
                                    //     booking.package_details[0].game_details.length >= 3
                                    //       ? "flex-start"
                                    //       : "center",
                                    // }}
                                  >
                                    {booking.package_details[0].game_details.map(
                                      (game, gameIndex) => (
                                        <div
                                          className="game"
                                          key={gameIndex}
                                          onClick={() =>
                                            openItemDetailsModal(gameIndex)
                                          }
                                        >
                                          <img
                                            src={game.image}
                                            alt={`Game ${gameIndex}`}
                                            className="game-image"
                                          />
                                          <div className="table-number-overlay">
                                            <p
                                              style={{
                                                fontSize: "10px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {game?.table_number} <br />{" "}
                                              <span
                                                style={{
                                                  fontSize: "10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                from package
                                              </span>{" "}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>

                              <Row>
                                <img
                                  style={{
                                    maxWidth: "55%",
                                    margin: "auto",
                                    opacity: "0.75",
                                    marginTop: "4px",
                                    marginBottom: "4px",
                                  }}
                                  src={border}
                                  alt="border"
                                />
                              </Row>

                              <div
                                style={{
                                  // textAlign:"center",
                                  // border:"1px solid black",
                                  width: "auto",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  // paddingLeft: "20px",
                                  gap: "-3px",
                                }}
                              >
                                {/* <Row> */}
                                <Row>
                                  <h5
                                    style={{
                                      textAlign: "center",
                                      // border:"1px solid blue",
                                      margin: "auto",
                                      color: "#0b1f32",
                                      fontWeight: "600",
                                      // marginBottom: "-5px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {booking.customer_details?.name &&
                                      booking.customer_details.customer_type &&
                                      `${booking.customer_details.name}`}
                                  </h5>
                                </Row>
                                {/* </Row> */}

                                <span
                                  style={{
                                    // textAlign:"center",
                                    color: "#0b1f32",
                                    fontWeight: "600",
                                    fontSize: "10px",
                                  }}
                                >
                                  {`${booking.customer_details?.mobile}`}
                                </span>
                                <span
                                  style={{
                                    // textAlign:"center",
                                    color: "#0b1f32",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    marginBottom: "3px",
                                    fontStyle:"italic"
                                  }}
                                >
                                  {booking.customer_details?.name &&
                                    booking.customer_details.customer_type &&
                                    `${booking.customer_details.customer_type}`}
                                </span>
                              </div>

                              <Row
                                style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  marginTop:"10px"
                                }}
                              >
                              
                                  <Button
                                    className="btn btn-sm "
                                    style={{
                                      width: "auto",
                                      display: "block",
                                      margin: "auto",
                                      // marginTop: "-0.5rem",
                                      borderRadius: "16px",
                                      fontSize: "11px",
                                      backgroundColor: "#ff4539",
                                      border: "none",
                                      transition: "transform 0.3s ease",
                                      // cursor: "pointer",
                                    }}
                                    onMouseEnter={e => {
                                      e.target.style.transform = "scale(1.05)"
                                    }}
                                    onMouseLeave={e => {
                                      e.target.style.transform = "scale(1)"
                                    }}
                                    onClick={e => {
                                      e.stopPropagation()
                                      setToggleModal(prev => (prev = !prev))
                                      openBookingModal(booking, index)
                                    }}
                                  >
                                    Add Games
                                  </Button>
                         

                         
                                  <Button
                                style={{
                                  width: "auto",
                                  height: "25px",
                                  // marginTop: "-10px",
                                  display: "block",
                                  margin: "auto",
                                  borderRadius: "16px",
                                  boxShadow: "none",
                                  transition: "transform 0.3s ease",
                                }}
                                    onMouseEnter={e => {
                                      e.target.style.transform = "scale(1.05)"
                                    }}
                                    onMouseLeave={e => {
                                      e.target.style.transform = "scale(1)"
                                    }}
                                    className="btn btn-success waves-effect waves-light btn-sm"
                                    // className="btn btn-danger"
                                    onClick={() => {
                                      // setStartTimeUpdateInputVisibility(prev => prev = !prev);
                                      // setStartTimeExistingInputVisibility(prev => prev = !prev)
                                    }}
                                  >
                                    <Link
                                      style={{ color: "white" }}
                                      // state={{instant}}
                                      to="/check-out"
                                    >
                                      Instant Checkout
                                    </Link>
                                  </Button>
                              </Row>
                            </Col>
                          ))}
                          <Col
                            sm="6"
                            md="5"
                            lg="3"
                            style={{ visibility: "none" }}
                          >
                            {/* This Col is empty and hidden */}
                          </Col>
                          <Col
                            sm="6"
                            md="5"
                            lg="3"
                            style={{ visibility: "none" }}
                          >
                            {/* This Col is empty and hidden */}
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        style={{ maxWidth }}
        isOpen={toggleModal}
        toggle={() => {
          setToggleModal(prev => (prev = !prev))

          setAvailableTables(prev => (prev = []))
          setSelectedTable(prev => (prev = []))
          setAllTheGames(prev => (prev = []))
          setSelected_game(prev => (prev = []))
          TotalGamePrice = 0
          TotalTime = 0
          setTablesDisplay(prev => (prev = []))
          setAllTableData(prev => (prev = []))
          setSubTotal(prev => (prev = {}))
          setDurationValidation(prev => (prev = {}))
          loadAllGame()
          setCustomStyles(
            prev =>
              (prev = {
                control: styles => ({ ...styles, color: "white" }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  // console.log(isDisabled, "disabled")
                  const color = "white"
                  return {
                    ...styles,
                    backgroundColor: isDisabled
                      ? "gray"
                      : isSelected
                      ? "white"
                      : isFocused
                      ? "#566ce3"
                      : undefined,
                    color: isFocused ? "white" : "black",
                    cursor: isDisabled ? "not-allowed" : "default",
                    ":active": {
                      ...styles[":active"],
                      backgroundColor: !isDisabled
                        ? isSelected
                          ? "white"
                          : "#566ce3"
                        : undefined,
                    },
                  }
                },
                multiValue: (styles, { data }) => {
                  // const color = "#eff2f7";
                  let color = ""
                  let isSelectedGameNoTables = []
                  if (preSaveColor.length === 0) {
                    color = "#eff2f7"
                  } else {
                    isSelectedGameNoTables = preSaveColor.some(
                      selected => selected === data.label
                    )
                    color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
                  }
                  return {
                    ...styles,
                    backgroundColor: color,
                  }
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: "black",
                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  color: "black",
                  ":hover": {
                    backgroundColor: "#f0b4b4",
                    color: "red",
                  },
                }),
              })
          )
        }}
      >
        <ModalHeader
          toggle={() => {
            setToggleModal(prev => (prev = !prev))

            setAvailableTables(prev => (prev = []))
            setSelectedTable(prev => (prev = []))
            setAllTheGames(prev => (prev = []))
            setSelected_game(prev => (prev = []))
            TotalGamePrice = 0
            TotalTime = 0
            setTablesDisplay(prev => (prev = []))
            setAllTableData(prev => (prev = []))
            setSubTotal(prev => (prev = {}))
            setDurationValidation(prev => (prev = {}))
            loadAllGame()
            setCustomStyles(
              prev =>
                (prev = {
                  control: styles => ({ ...styles, color: "white" }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    // console.log(isDisabled, "disabled")
                    const color = "white"
                    return {
                      ...styles,
                      backgroundColor: isDisabled
                        ? "gray"
                        : isSelected
                        ? "white"
                        : isFocused
                        ? "#566ce3"
                        : undefined,
                      color: isFocused ? "white" : "black",
                      cursor: isDisabled ? "not-allowed" : "default",
                      ":active": {
                        ...styles[":active"],
                        backgroundColor: !isDisabled
                          ? isSelected
                            ? "white"
                            : "#566ce3"
                          : undefined,
                      },
                    }
                  },
                  multiValue: (styles, { data }) => {
                    // const color = "#eff2f7";
                    let color = ""
                    let isSelectedGameNoTables = []
                    if (preSaveColor.length === 0) {
                      color = "#eff2f7"
                    } else {
                      isSelectedGameNoTables = preSaveColor.some(
                        selected => selected === data.label
                      )
                      color = isSelectedGameNoTables ? "#f0b4b4" : "#eff2f7"
                    }
                    return {
                      ...styles,
                      backgroundColor: color,
                    }
                  },
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: "black",
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: "black",
                    ":hover": {
                      backgroundColor: "#f0b4b4",
                      color: "red",
                    },
                  }),
                })
            )
          }}
        >
          Game Details -{" "}
          <span style={{ fontWeight: "bold" }}>{selectedCustomer.name}</span>{" "}
          &nbsp;
          <span style={{ fontWeight: "500", color: "red" }}>
            (Booking started at:{" "}
            {moment(selectedBooking[0]?.start_time).format(" HH:mm")})
          </span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="2">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Booked Games
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleVertical("2")
                                        }}
                                    >
                                        Add Items
                                    </NavLink>
                                </NavItem> */}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Add Games
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="9">
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                <TabPane tabId="1">
                  <Row>
                    {selectedBooking[0]?.game_details?.length === 0 && (
                      <Col
                        lg={12}
                        style={{
                          margin: "auto",
                          textAlign: "center",
                          paddingTop: "15px",
                        }}
                      >
                        <h5>No Booked Games Found.</h5>
                      </Col>
                    )}
                    {selectedBooking[0]?.game_details.map((item, index) => (
                      <Col sm="6" md="4" lg="3" key={index}>
                        <div
                          style={{
                            borderRadius: "15px",
                            padding: "10px",
                            marginBottom: "10px",
                            cursor: "pointer",
                            textAlign: "center",
                            boxShadow:
                              "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                          }}
                        >
                          <img
                            style={{
                              maxWidth: "90%",
                              height: "120px",
                              display: "block",
                              margin: "auto",
                            }}
                            src={item.game.image}
                            alt={item.game.name}
                          />
                          <p
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              marginTop: "2px",
                              fontWeight: "bold",
                            }}
                          >
                            {item.table_number}
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              marginTop: "-6px",
                              fontWeight: "400",
                              color: "blue",
                            }}
                          >
                            ⌛
                            {moment(item.game.table_start_time).format("HH:mm")}{" "}
                            - {item.game.table_end_time ? moment(item.game.table_end_time).format("HH:mm") : "N/A"}
                          </p>
                          <p
                            style={{
                              fontWeight: "500",
                              marginTop: "-14px",
                            }}
                          >
                            Sub Total: {`QR ${item.game.sub_total}`}
                          </p>
                          {/* <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Sub Total: {`QR ${item.sub_total}`}
                                </p> */}
                          <p
                            style={{
                              fontWeight: "500",
                              marginTop: "-12px",
                            }}
                          >
                            Duration: {item.game.duration}{" "}
                          </p>

                          {/* <Button
                                                            style={{
                                                                width: "auto",
                                                                height: "25px",
                                                                // marginTop: "-10px",

                                                                boxShadow:
                                                                    "none",
                                                                transition: "transform 0.3s ease",
                                                            }}
                                                            onMouseEnter={e => {
                                                                e.target.style.transform = "scale(1.05)"
                                                            }}
                                                            onMouseLeave={e => {
                                                                e.target.style.transform = "scale(1)"
                                                            }}
                                                            className="btn btn-sm btn-danger"
                                                            // className="btn btn-danger"
                                                            onClick={() => {
                                                                // setStartTimeUpdateInputVisibility(prev => prev = !prev);
                                                                // setStartTimeExistingInputVisibility(prev => prev = !prev)
                                                            }}

                                                        >
                                                            Remove
                                                        </Button> */}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="6" xs="12" xl="4">
                          <Label>Game</Label>

                          <Select
                            // isDisabled={selectedCustomer === null}
                            // style={{ backgroundColor: "red" }}
                            name="games"
                            label={"Games"}
                            placeholder={"Select Games"}
                            onChange={(selected, removed) => {
                              if (removed.action === "remove-value") {
                                for (let key in subTotal) {
                                  if (
                                    key.includes(removed.removedValue.label)
                                  ) {
                                    delete subTotal[key]
                                  }
                                }
                                for (let key in durationValidation) {
                                  if (
                                    key.includes(removed.removedValue.label)
                                  ) {
                                    delete durationValidation[key]
                                  }
                                }
                                let tableToDisplay = tablesToDisplay.filter(
                                  table =>
                                    removed.removedValue.label !==
                                    table.game.name
                                )
                                // console.log(tableToDisplay, "tables to display")
                                setTablesDisplay(
                                  prev => (prev = tableToDisplay)
                                )

                                let newSelectedGames = selected_game.filter(
                                  table => table.id !== removed.removedValue.id
                                )
                                setSelected_game(
                                  prev => (prev = newSelectedGames)
                                )

                                setAllTheGames(prev => [
                                  ...prev,
                                  removed.removedValue,
                                ])

                                let newSelectedTables = selectedTable.filter(
                                  table =>
                                    removed.removedValue.label !== table.name
                                )
                                setSelectedTable(
                                  prev => (prev = newSelectedTables)
                                )

                                let newAvailableTables = availableTables.filter(
                                  table =>
                                    table.name !== removed.removedValue.label
                                )
                                setAvailableTables(
                                  prev => (prev = newAvailableTables)
                                )

                                let newAllTableData = allTableData.filter(
                                  table =>
                                    table.game.name !==
                                    removed.removedValue.label
                                )
                                setAllTableData(
                                  prev => (prev = newAllTableData)
                                )

                                // handleGameSelect(tableToDisplay)
                              }
                              if (removed.action === "select-option") {
                                let newPayload = allTheGames.filter(
                                  game => game.label === removed.option.label
                                )
                                setSelected_game(
                                  prev => (prev = [...prev, ...newPayload])
                                )
                                let newGameList = allTheGames.filter(
                                  game => game.label !== newPayload[0].label
                                )
                                setAllTheGames(prev => (prev = newGameList))
                                // function call to fetch available game tables
                                fetchAvailableGameTables(removed)
                              }
                              if (removed.action === "clear") {
                                setSubTotal(prev => (prev = {}))
                                setDurationValidation(prev => (prev = {}))
                                setSelected_game(prev => (prev = []))
                                setAllTheGames(
                                  prev =>
                                    (prev = [...prev, ...removed.removedValues])
                                )
                                setSelectedTable(prev => (prev = []))
                                setAvailableTables(prev => (prev = []))
                                setTablesDisplay(prev => (prev = []))
                                setAllTableData(prev => (prev = []))
                                // handleGameSelect([])
                              }
                              // setSelected_game(selected)
                            }}
                            options={allTheGames}
                            // ref={refSelectGame}
                            value={selected_game}
                            styles={customStyles}
                            isMulti
                            isSearchable
                            required
                          />
                        </Col>

                        <Col md="6" xs="12" xl="4">
                          <Label className="mt-3 mt-md-0">
                            Available Tables
                          </Label>
                          <Select
                            // isDisabled={selectedCustomer === null}
                            name="tables"
                            label={"Tables"}
                            placeholder={"Select Tables"}
                            // type="text"

                            onChange={(selected, removed) => {
                              // console.log(removed, "removed")

                              if (removed.action === "remove-value") {
                                // console.log(removed, "removed game")
                                delete subTotal[
                                  removed.removedValue.id +
                                    removed.removedValue.name
                                ]
                                delete durationValidation[
                                  removed.removedValue.id +
                                    removed.removedValue.name
                                ]

                                let tableToDisplay = tablesToDisplay.filter(
                                  table => removed.removedValue.id !== table._id
                                )
                                // console.log(tableToDisplay,"tables to display")
                                setTablesDisplay(
                                  prev => (prev = tableToDisplay)
                                )

                                let newSelectedTables = selectedTable.filter(
                                  table => table.id !== removed.removedValue.id
                                )
                                setSelectedTable(
                                  prev => (prev = newSelectedTables)
                                )

                                setAvailableTables(prev => [
                                  ...prev,
                                  removed.removedValue,
                                ])

                                // handleGameSelect(tableToDisplay)
                              }

                              if (removed.action === "select-option") {
                                let tableToDisplay = allTableData.filter(
                                  table => table._id === removed.option.id
                                )
                                // console.log(tableToDisplay,"table to display")
                                setTablesDisplay(
                                  prev => (prev = [...prev, ...tableToDisplay])
                                )

                                setSelectedTable(
                                  prev => (prev = [...prev, removed.option])
                                )

                                let newAvailableTables = availableTables.filter(
                                  table => table.id !== removed.option.id
                                )
                                setAvailableTables(
                                  prev => (prev = newAvailableTables)
                                )
                              }

                              if (removed.action === "clear") {
                                setSubTotal(prev => (prev = {}))
                                setDurationValidation(prev => (prev = {}))

                                setSelectedTable(prev => (prev = []))
                                setAvailableTables(
                                  prev =>
                                    (prev = [...prev, ...removed.removedValues])
                                )

                                setTablesDisplay(prev => (prev = []))

                                // handleGameSelect([])
                              }

                              // setSelected_game(selected)
                            }}
                            options={availableTables}
                            // ref={refSelectGame}
                            value={selectedTable}
                            isMulti
                            isSearchable
                            required
                          />
                        </Col>
                      </Row>
                      <br /> <br />
                      <Row>
                        {tablesToDisplay.length === 0 && (
                          <Col
                            lg={12}
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              paddingTop: "15px",
                            }}
                          >
                            <h5>No items found.</h5>
                          </Col>
                        )}
                        {tablesToDisplay.length > 0 &&
                          tablesToDisplay.map((item, index) => (
                            <Col sm="6" md="4" lg="3" key={index}>
                              <div
                                style={{
                                  borderRadius: "15px",

                                  padding: "10px",
                                  marginBottom: "10px",
                                  // boxShadow:
                                  //     selectedItemIndex === item._id
                                  //         ? "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
                                  //         : "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  width: "220px",
                                }}
                                // onClick={() => handleAddItemClick(item)}
                              >
                                <img
                                  style={{
                                    maxWidth: "110%",
                                    height: "130px",
                                    boxShadow:
                                      "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
                                  }}
                                  src={item.game.image}
                                  alt={item.game.name}
                                />
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    marginTop: "2px",
                                  }}
                                >
                                  {item.table_number}
                                </p>
                                <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Price:{" "}
                                  {`${
                                    selectedCustomer?.customer_type === "Friend"
                                      ? item.game.price_for_friend
                                      : selectedCustomer?.customer_type ===
                                        "Regular"
                                      ? item.game.price_for_regular
                                      : item.game.price_for_vip
                                  }`}
                                </p>

                                <p
                                  style={{
                                    fontWeight: "500",
                                    marginTop: "-14px",
                                  }}
                                >
                                  Sub Total:{" "}
                                  {`QR - ${
                                    subTotal[item._id + item.game.name] || 0
                                  }`}
                                </p>

                                <div
                                  style={{
                                    width: "auto",
                                    margin: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "16px",
                                  }}
                                >
                                  <Input
                                    style={{
                                      width: "150px",
                                      cursor: "pointer",
                                    }}
                                    placeholder="Select Start time"
                                    type="datetime-local"
                                    onChange={e => handleStartTime(e, item)}
                                  />

                                  {startValidation && (
                                    <p
                                      style={{ fontSize: "10px", color: "red" }}
                                    >
                                      {"cant be less than booking start time"}
                                    </p>
                                  )}
                                  <Input
                                    disabled={disabletrack ? false : true}
                                    style={{
                                      width: "150px",
                                      cursor: "pointer",
                                    }}
                                    placeholder="Enter minutes"
                                    onWheel={preventValueChangeOnScroll}
                                    type="number"
                                    onChange={e =>
                                      handleDurationChange(e, item)
                                    }
                                  />

                                  {durationValidation[
                                    item._id + item.game.name
                                  ] && (
                                    <p
                                      style={{ fontSize: "10px", color: "red" }}
                                    >
                                      {"cant be less than 30 min"}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {verticalActiveTab === "3" && (
            <Row style={{ width: "100%" }}>
              <Col lg={4} style={{}}></Col>

              <Col
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={confirmChanges}
                  disabled={startValidation ? true : false}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: "#fdd005",
                    border: "none",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "100px",
                    // marginTop: "-0.6rem",
                    boxShadow:
                      "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                    transition: "transform 0.3s ease",
                  }}
                  onMouseEnter={e => {
                    e.target.style.transform = "scale(1.05)"
                  }}
                  onMouseLeave={e => {
                    e.target.style.transform = "scale(1)"
                  }}
                >
                  Confirm
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      background:
                        "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                      pointerEvents: "none",
                    }}
                  ></div>
                </Button>
              </Col>

              <Col lg={4} style={{ textAlign: "right" }}>
                {selectedCustomer.customer_type === "VIP" && (
                  <span style={{ color: "#36B94D", fontWeight: "600" }}>
                    {" "}
                    {`(No Charges for VIP)`}
                  </span>
                )}
                {selectedCustomer.customer_type !== "VIP" && (
                  <>
                    {" "}
                    <span style={{ color: "black", fontWeight: "bolder" }}>
                      Games Total:
                    </span>
                    &nbsp;{" "}
                    <span style={{ color: "red", fontWeight: "bolder" }}>
                      {" "}
                      {`QR ${TotalGamePrice.toFixed(2)}`}
                    </span>
                    &nbsp; <br />
                    <span style={{ color: "black", fontWeight: "bolder" }}>
                      {" "}
                      Booking Total:
                    </span>
                    &nbsp;{" "}
                    <span style={{ color: "red", fontWeight: "bolder" }}>
                      {" "}
                      {`QR ${bookingTotal}`}
                    </span>{" "}
                  </>
                )}
              </Col>
            </Row>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default AddGames
