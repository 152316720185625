import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const Coupons = props => {

  const editorRef = useRef()
  const { SearchBar } = Search

  //meta title
  document.title = "Add Item | Rendezvous - Game Parlour"
  const refContainer = useRef(null)

  const handleDownload = async () => {
    const fileName = "Coupons";
    const exportType = "xls";

    let data_to_export = allCoupons;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          coupon_code: v.coupon_code,
          Discount_percent: v.discount_percent,
          coupon_expiry_date: formatDate(v.coupon_expiry_date)
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  function preventValueChangeOnScroll(e) {

    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current     
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  // ------------------------------------------------------------------------------

  //   const handleDownload = async () => {
  //     const fileName = "All Items";
  //     const exportType = "xls";

  //     let data_to_export = allCoupons;

  //     if(data_to_export){
  //       var data = [];
  //       JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
  //         data.push({
  //           Name: v.name,
  //           Unit: v.unit,
  //           category:v.category,
  //           Section: v.section,
  //           Sub_Unit: v.sub_unit,
  //           Formula : v.formula,
  //           Selling_Price: v.selling_price,
  //           Threshold: v.threshold,
  //           Menu_Item: v.is_menu_item,
  //         });
  //       });
  //       exportFromJSON({ data, fileName, exportType });
  //     }
  //   } 


  //for formula 

  const [allCoupons, setAllCoupons] = useState([])
  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedCoupon, setSelectedCoupon] = useState();

  useEffect(() => {
    loadAllCoupons();
  }, [])



  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: selectedCoupon && selectedCoupon.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  //load All Coupons

  const loadAllCoupons = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "coupons/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      preloader(false)
      setAllCoupons(response.data)
    } else {
      preloader(false)
      //showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info d-flex justify-content-evenly">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditModal(!editModal)
            setSelectedCoupon(row)

          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
        <i>
          <div className="form-switch ms-2" dir="ltr">
            <input
              type="checkbox"
              style={{height:"15px", width:"30px"}}
              title={row.is_active ? "Click to Deactivate ." : "Activate"}
              className="form-check-input"
              id={"customSwitchsize1" + row._id}
              onClick={() => {
                handleValidDelete(row)
              }}
              checked={row.is_active}
            />
            <label
              title="Click to change status."
              className="custom-control-label"
              htmlFor={"customSwitch1" + row._id}
              style={{ fontWeight: "100", cursor: "pointer" }}
            ></label>
          </div>
        </i>
      </span>
    )
  }

  // Insert
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "coupons/insert"

    const object = {
      coupon_code: v.coupon_code,
      discount_percent: v.discount_percent,
      coupon_expiry_date: v.coupon_expiry_date,
    }

    postSubmitForm(url, object).then(response => {
      if (response.status === 1) {
        loadAllCoupons();
        refContainer.current.reset();
        showNotification(response.message, "Success")
      }
      else {
        showNotification(response.message, "Error")
      }
    })
  }

  //Update
  const handleValidUpdate = async (e, v) => {

    try {
      const object = {
        id: selectedCoupon._id,
        coupon_code: v.coupon_code,
        discount_percent: v.discount_percent,
        coupon_expiry_date: v.coupon_expiry_date,

      }
      let url = process.env.REACT_APP_BASEURL + "coupons/update"
      let response = await postSubmitForm(url, object)
      if (response.status === 1) {
        loadAllCoupons()
        setEditModal(!editModal)
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }

  //Delete
  const handleValidDelete = async coupon => {
    let url = process.env.REACT_APP_BASEURL + "coupons/activate_deactivate"
    let response = await postSubmitForm(url, {
      id: coupon._id,
      is_active: coupon.is_active === true ? false : true
    })
    if (response.status === 1) {
      showNotification(response.message, "Success")
      loadAllCoupons()
    } else {
      showNotification(response.message, "Error")
    }
  }


  // console.log(selectedCoupon);


  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Coupon Code"),
      dataField: "coupon_code",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Discount"),
      dataField: "discount_percent",
      formatter: (cell, row) => {
        return row.discount_percent + " " + "%"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Coupon Expiry Date"),
      dataField: "coupon_expiry_date",
      sort: true,
      formatter: (cell, row) => {
        const ExpiryDate = row.coupon_expiry_date;
        const formattedDate = moment(ExpiryDate).format('YYYY-MM-DD');
        return formattedDate;
      },
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
};

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Coupon")}
            breadcrumbItem1={props.t("Add Coupon")} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                 style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>
                      <Col lg={6}>

                        <AvField
                          label={props.t("Coupon Code *")}
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter Coupon Code")}
                          name="coupon_code"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Coupon Code cannot be empty")}
                        />

                      </Col>
                      <Col lg={6}>

                        <AvField

                          onWheel={preventValueChangeOnScroll}
                          label={props.t("Discount in Percent *")}
                          className="form-control"
                          type="number"
                          placeholder={props.t("Enter Discount")}
                          name="discount_percent"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Discount cannot be empty")}
                        />
                      </Col>

                    </Row>
                    <Row>

                      <Col lg={6}>

                        <AvField
                          label={props.t("Expiry Date *")}
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          placeholder={props.t("Select Expiry Date")}
                          name="coupon_expiry_date"
                          validate={{ required: { value: true } }}
                          errorMessage={props.t("Coupon Expiry Date cannot be empty")}
                        />


                      </Col>

                    </Row>

                    <hr />
                    <Row>

                     

                    <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "120px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Add Coupon")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                     

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                
                >
                  <CardTitle>
                    <Row>

                      <Col lg={6}>
                        {props.t("All Coupons")}
                      </Col>

                      <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={allCoupons.length<=0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>

                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View/Update all Coupons")}
                  </CardSubtitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allCoupons && allCoupons}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable

                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>



                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Item - ")}
                      {selectedCoupon && selectedCoupon.coupon_code}
                    </ModalHeader>
                    <ModalBody>
                      {selectedCoupon && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate} >
                                    <Row>
                                      <Col lg={6}>

                                        <AvField
                                          label={props.t("Coupon Code")}
                                          className="form-control"
                                          type="text"
                                          placeholder={props.t("Enter Coupon Code")}
                                          name="coupon_code"
                                          value={selectedCoupon.coupon_code}
                                        />

                                      </Col>
                                      <Col lg={6}>

                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          label={props.t("Discount in percent")}
                                          className="form-control"
                                          type="number"
                                          placeholder={props.t("Enter Discount")}
                                          name="discount_percent"
                                          value={selectedCoupon.discount_percent}
                                        />
                                      </Col>

                                    </Row>
                                    <Row>

                                      <Col lg={6}>

                                        <AvField
                                          label={props.t("Expiry Date")}
                                          className="form-control"
                                          type="date"
                                          placeholder="Enter Expiry Date"
                                          name="coupon_expiry_date"
                                          tag={Flatpickr}
                                          options={{
                                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                                              dayElem.addEventListener("mousedown", e => {
                                                e.preventDefault() // Prevent default click behavior
                                              })
                                            },
                                          }}
                                          value={formatDate(selectedCoupon.coupon_expiry_date)}
                                        />


                                      </Col>

                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                          <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Coupons)))
