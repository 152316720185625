import { LOAD_ROSTER_OWNER,LOAD_ROSTER_SUCCESS_OWNER,LOAD_ROSTER_ERROR_OWNER } from "./actionTypes";
  
  const initialState = {
    roster: [],
    isLoading: false,
    isError: false
  };
  
  const ownerRoster = (state = initialState, action) => {

    const {type,payload} = action;
    switch (type) {
      
      case LOAD_ROSTER_OWNER:
        state = {
          ...state,
          isLoading: true,
        };
        break;
      case LOAD_ROSTER_SUCCESS_OWNER:
        state = {
          ...state,
          isLoading: false,
          roster: payload,
        };
        break;
  
      case LOAD_ROSTER_ERROR_OWNER:
        state = {
          ...state,
          isLoading: false,
          isError: true
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default ownerRoster;
  