


import { LOAD_ROSTER, LOAD_ROSTER_SUCCESS, LOAD_ROSTER_ERROR } from "./actionTypes";
import {
  loadRosterSuccess,
  loadRosterError
} from "./action";

import { takeEvery, put, call } from "redux-saga/effects";
import { postSubmitForm } from "../../helpers/backend_helper";



function* getEmployeeRosterData({ payload }) {
  // console.log(payload, "payload")
  try {



    const response = yield call(postSubmitForm, `https://api-rendezvous.mhsinfotech.qa/rosters/get_by_employee`, payload);

    console.log(response)

    if (response.status === 1) {

      console.log("response is here")
      yield put(loadRosterSuccess(response.data[0]));
    } else {

      yield put(loadRosterError());
    }
  } catch (error) {

    yield put(loadRosterError(error));
  }
}


function* employeeRosterSaga() {

  yield takeEvery(LOAD_ROSTER, getEmployeeRosterData);
}

export default employeeRosterSaga;
