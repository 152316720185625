import {
    SUBMIT_CAFE_ITEM,
    SUBMIT_CAFE_ITEM_SUCCESS,
    SUBMIT_CAFE_ITEM_ERROR,
    LOAD_CAFE_ITEM,
    LOAD_CAFE_ITEM_SUCCESS
} from "./actionTypes"

export const submitCafeItem = (addItem, history) =>{
    return{
        type: SUBMIT_CAFE_ITEM,
        payload:{ addItem, history },
    };
};

export const submitCafeItemSuccess = (addItem) =>{
    return{
        type: SUBMIT_CAFE_ITEM_SUCCESS,
        payload:{ addItem },
    };
};

export const submitCafeItemError = (error) =>{
    return{
        type: SUBMIT_CAFE_ITEM_ERROR,
        payload:{ error },
    };
}

export const loadCafeItem = () => {
    return {
      type: LOAD_CAFE_ITEM,
      payload: {},
    };
  };

  export const loadCafeItemSuccess = (items) => {
    return {
      type: LOAD_CAFE_ITEM_SUCCESS,
      payload: items,
    };
  };