import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input, Badge } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"

import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom"
import logo from "../../assets/images/Logo.png"
import { formatDate } from "@fullcalendar/core"
import Loader from "../../helpers/Loader"
import BackgroundImage from "../../assets/images/Background.png"
import moment from "moment"

const AllPackageOrders = props => {

  const { SearchBar } = Search


  const [allOrders, setAllOrders] = useState([])

  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [item_Details, setItemDetails] = useState();
  const [selectedRow, setselected_Row] = useState()
  const [paymentDetails, setPaymentDetails] = useState()
  const [printModal, setPrintModal] = useState()





  useEffect(() => {
    loadAllOrdres();
  }, [])



  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: item_Details && item_Details.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };


  //load All Coupons

  const loadAllOrdres = async () => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "sales/getall_package_sales"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      setAllOrders(response.data)
      Loader(false)
      // console.log(response.data)
    } else {
      Loader(false)
      //showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()

  function customerFormatter(cell, row) {
    return (
      <>
        {row.customer_details?.name}
        <br />
        {"(" + row.customer_details?.mobile + ")"}
      </>

    );
  }

  function invoiceFormatter(cell, row) {
    return (
      <i
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => {
          setPrintModal(prev => prev = !prev)
          setselected_Row(row)
          setPaymentDetails(row.payment_details)
        }}
      >
        {row.invoice_no}
      </i>
    );
  }

  //for round image view
  function showPhotoFormatter(cell, row) {
    return (
      <>
        <img
          src={row.document_file || row.image_url + "?" + new Date() + "=" + new Date()}
          alt={row.name}
          className="avatar-md rounded-circle img-thumbnail"
        />
        <span
          style={{ cursor: "pointer" }}
          className="font-size-12 badge-soft-info badge badge-success badge-pill"
        ></span>
      </>
    )
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      formatter: (cell, row) => {
        return moment(row.createdAt).format("YYYY-MM-DD HH:mm")
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      text: props.t("Cashier"),
      dataField: "emp_details.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Invoice"),
      dataField: "invoice_no",
      filter: textFilter({
        caseSensitive: false,
        style: {
          height: "20px",
        }
      }),
      formatter: invoiceFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Customer"),
      dataField: "customer_details.name",
      formatter: customerFormatter,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Type"),
      dataField: "customer_details.customer_type",
      filter: selectFilter({
        // style:{marginLeft:"10px"},
        options: [
          { value: "Regular", label: "Regular" },
          { value: "VIP", label: "VIP" },
          { value: "Friend", label: "Friend" },
        ],
        placeholder: "ALL",
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      dataField: "discount_amount",
      text: props.t("Discount Amount"),
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      // headerAlign: (column, colIndex) => "right",
      // align: (cell, row, rowIndex, colIndex) => {
      //   return "right";
      // },
    },

    {
      text: props.t("Paid Amount"),
      dataField: "paid_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Total Amount"),
      dataField: "total_amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Change"),
      dataField: "change_amount",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Refund"),
      dataField: "refund.refund_amount",
      sort: true,
      formatter: (cell, row) => {
        return row.refund?.refund_amount
      },
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    // {
    //   text: props.t("Remarks"),
    //   dataField: "remarks",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },

    {
      text: props.t("Packages"),
      dataField: "package_details",
      formatter: (packageDetails, row) => {
        if (packageDetails && packageDetails.length > 0) {
          return packageDetails.map((packages, id) => (
            <Badge
              className="glow-effect me-1"
              key={id}
              color="success"
              style={{ cursor: "none" }}
            // onClick={() => handlePackageBadgeClick(packages)}
            >
              {packages.package_name}
            </Badge>
          ));
        }
        return (
          <Badge className="cursor-not-allowed" color="secondary">
            No Packages
          </Badge>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },

    // {
    //   dataField: "",
    //   text: props.t("Action"),
    //   formatter: editFormatter,
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "4%" }
    //   },
    // },
  ]
  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
  }];

  const PackageItemDetails = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    {
      text: props.t("Image"),
      formatter: showPhotoFormatter,
      dataField: "image_url",
      //sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Name"),
      dataField: "name",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Unit"),
      dataField: "available.in_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_unit + " " + row.unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Sub-Unit"),
      dataField: "available.in_sub_unit",
      sort: false,
      formatter: (cell, row) => {
        return row.available.in_sub_unit + " " + row.sub_unit
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    // {
    //   text: props.t("Regular Price"),
    //   dataField: "selling_price",
    //   sort: false,
    //   formatter:(cell,row)=>{
    //     let price =  row.selling_price/row.formula;
    //     return price.toFixed(2) + " " + "Qr"
    //   },
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
  ]


  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",


  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs breadcrumbItem1="All Sold Packages" title="Sales" alternateTitle="All Sold Packages" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("All Sold Packages")}
                      </Col>


                    </Row>
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                    {props.t("View/Update all Methods")}
                  </CardSubtitle> */}
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allOrders && allOrders}
                    search

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable

                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          defaultSorted={ defaultSorted } 
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>


                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={editModal}
            toggle={() => setEditModal(!editModal)}
          >
            <ModalHeader toggle={() => setEditModal(!editModal)}>
              {props.t(" Item Details ")}
              {/* {item_Details && item_Details.oredr_id} */}
            </ModalHeader>
            <ModalBody>
              {item_Details && (
                <>
                  <Row>
                    <Col xlg={12}>
                      <Card>
                        <CardBody>
                          <ToolkitProvider
                            keyField="_id"
                            columns={PackageItemDetails}
                            data={item_Details && item_Details}
                            search

                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row>
                                    <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                      <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                    </Col>
                                  </Row>
                                </AvForm>
                                <BootstrapTable

                                  {...propst.baseProps}
                                  filter={filterFactory()}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                  pagination={paginationFactory(options)}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>

          {/* Print Receipt */}
          <Row>
            <Col>
              <Modal
                size="xl"
                isOpen={printModal}
                toggle={() => setPrintModal(prev => prev = !prev)}
              >
                <ModalHeader toggle={() => setPrintModal(prev => prev = !prev)} >
                </ModalHeader >
                <ModalBody>
                  <div>
                    {/* {selectedRow && ( */}
                    <div className="container pl-4  pr-0">
                      <div className="row head" style={{ backgroundColor: " #f0f0f0", height: "100px", borderRadius: "50px 0px 0px 50px" }}>
                        <div className="col col-md-2 col-sm-2" style={{ display: "flex", justifyContent: "flex-start" }}>
                          <img src={logo} style={{ height: "105px", borderRadius: " 50%", width: "110px", backgroundColor: "#000000" }} />
                        </div>
                        <div className="col col-md-10 col-sm-10 pt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                          <p className="" style={{ color: "#000000", fontWeight: "500" }}>
                            {selectedRow?.invoice_no} فاتورة رقم<br />
                            {/* PO Id : {selectedRow.po_id}<br /> */}
                            {/* <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "5px" }}>Date</span> */}
                            <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{moment(selectedRow?.createdAt).format("YYYY-MM-DD HH:mm")} [{selectedRow?.emp_details?.name} ]</span>
                          </p>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="row ">
                        <h5> Billed To</h5>
                      </div>
                      <p className="mb-0 date">
                        <span style={{ fontWeight: 500 }}>
                          Name: &nbsp;
                          <b> {selectedRow?.customer_details.name}</b>
                        </span>
                      </p>
                      <p className="mb-0">
                        <span style={{ fontWeight: 500 }}>Phone:</span> &nbsp;
                        <b>{selectedRow?.customer_details.mobile}</b>
                      </p>
                      <p className="mb-0">
                        <span style={{ fontWeight: 500 }}>Type: </span> &nbsp;
                        <b> {selectedRow?.customer_details.customer_type}</b>
                      </p>
                      <hr />
                      <div className="row ">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">
                            <h5>Packages</h5>
                            <table
                              id="example"
                              className="table table-bordered"
                              style={{ width: "100%" }}
                            >
                              <thead className="bg-light">
                                <tr>
                                  <th>S.N</th>
                                  <th>Name</th>
                                  <th>Validity (days)</th>
                                  <th>Sub Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedRow?.package_details.map((pkg, index) =>
                                  <tr key={index + 1} >

                                    <td>{index + 1}</td>
                                    <td>{pkg.package_name}</td>
                                    <td>{pkg.validity}</td>
                                    <td>{pkg.price}</td>

                                  </tr>)}
                                <tr>
                                  <td colSpan={3} style={{ textAlign: "end", fontWeight: "bold" }} >Package Total</td>
                                  <td style={{ fontWeight: "bold" }}>{selectedRow?.total_amount.toFixed(2)}</td>
                                </tr>
                                {/* <tr>
                                  <td>{index +1}</td>
                                  <td>Cake</td>
                                  <td>2</td>
                                  <td>0</td>
                                  <td>10</td>
                                  <td>20</td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>#IN_12344859</td>
                                  <td>Cookies</td>
                                  <td>4</td>
                                  <td>0</td>
                                  <td>10</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>#IN_12344810</td>
                                  <td>Burger</td>
                                  <td>3</td>
                                  <td>5</td>
                                  <td>10</td>
                                  <td>35</td>
                                </tr>
                                <tr>
                                  <td colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }} >Grand Total</td>
                                  <td style={{ fontWeight: "bold" }}>95.00</td>
                                </tr> */}
                              </tbody>
                            </table>

                            {/* <Row> */}
                            {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <span> Grand Total: &nbsp;</span><span style={{ color: "black", fontWeight: "bolder" }}>  {selectedRow.items_total_price} </span>
                          </div> */}
                            {/* </Row> */}

                          </div>
                          <hr />

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Total Amount &nbsp; :&nbsp;
                                  {selectedRow?.total_amount || 0}
                                </span>
                              </p>
                            </div>
                          </div>

                          {selectedRow?.discount !== 0 && (
                            <div className="row">
                              <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <p className="">
                                  <span style={{ fontWeight: 500 }}>
                                    Discount Rate &nbsp; :&nbsp;
                                    {selectedRow?.discount || 0}%
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}

                    <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Paid Amount &nbsp; :&nbsp;
                                  {selectedRow?.paid_amount || 0}
                                  {/* </b> */}
                                </span>
                              </p>
                            </div>
                          </div>


                          {paymentDetails?.map((payment, index) => {
                            const { _id, ...paymentDetailsWithoutId } = payment;

                            let mappedString = '';
                            for (let key in paymentDetailsWithoutId) {
                              let value = paymentDetailsWithoutId[key];
                              mappedString += `Paid By ${key} : ${value}\n`;
                            }


                            return (
                              // <div style={{display:"flex",flexDirection:"column", gap:"6px"}} key={index}>
                              <div key={index} className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <p className="">
                                  <span style={{ fontWeight: 500 }}>

                                    {mappedString}

                                  </span>
                                </p>
                              </div>
                              // </div>
                            );
                          })}

                        

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Change &nbsp; :&nbsp;
                                  {selectedRow?.change_amount}
                                  {/* </b> */}
                                </span>
                              </p>
                            </div>
                          </div>

                          {selectedRow?.refund?.refund_amount > 0 && (
                           <div className="row">
                           <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                             <p className="">
                               <span style={{ fontWeight: 500 }}>
                               Refunded Amount &nbsp; :&nbsp;
                                 {selectedRow?.refund?.refund_amount}
                                 {/* </b> */}
                               </span>
                             </p>
                           </div>
                         </div>
                         )}


                          {selectedRow?.difference_amount > 0 && (
                           <div className="row">
                           <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                             <p className="">
                               <span style={{ fontWeight: 500 }}>
                                 Extra Income &nbsp; :&nbsp;
                                 {selectedRow?.difference_amount}
                                 {/* </b> */}
                               </span>
                             </p>
                           </div>
                         </div>
                         )}


                          {/* 
                          {paymentDetails && paymentDetails.map((pmt,index)=>{
                            <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Paid By Card &nbsp; &nbsp;      <b> 
                                  {paymentDetails?.card|| 0}
                                   </b>
                                </span>
                              </p>
                            </div>
                          </div>
                          })}

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Paid By Card &nbsp; &nbsp;      <b> 
                                  {paymentDetails?.card|| 0}
                                   </b>
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Paid By Cash &nbsp; &nbsp;      <b> 
                                  {paymentDetails?.cash|| 0}
                                   </b>
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Paid By UPI &nbsp; &nbsp;      <b> 
                                  {paymentDetails?.Apple_Pay|| paymentDetails?.Google_Pay || 0}
                                   </b>
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col col-lg-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                              <p className="">
                                <span style={{ fontWeight: 500 }}>
                                  Paid By Wallet &nbsp;      <b> 
                                  {paymentDetails?.paid_by_wallet || 0}
                                     </b>
                                </span>
                              </p>
                            </div>
                          </div> */}




                          {/* <div className="row pl-3">
                            <div className="col col-md-8 col-sm-6 col-lg-6 col-xl-6 d-flex justify-content-around">
                              <h6> Total</h6>
                              <h6>
                                10
                              </h6>
                            </div>
                          </div>

                          <div className="row">
                          <div className="col col-md-8 col-sm-6 col-lg-6 col-xl-6 d-flex justify-content-around">
                              <h6> Total</h6>
                              <h6>
                                10
                              </h6>
                            </div>
                           
                          </div>

                          <div className="row">
                          <div className="col col-md-8 col-sm-6 col-lg-6 col-xl-6 d-flex justify-content-around">
                              <h6> Total</h6>
                              <h6>
                                10
                              </h6>
                            </div>
                           
                           
                          </div>

                          <div className="row pl-3">
                            <div className="col col-md-8 col-sm-8 col-lg-8 col-xl-8">
                              <h5> Additional Note</h5>
                              <p>
                                Payment with in 30 days upon delivery of the above items
                              </p>
                            </div>
                          
                          </div> */}
                          <div className="row mb-5" style={{ display: "flex", justifyContent: "flex-end" }}>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* )} */}


                  </div>
                  <Row className="col text-right pr-5">
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      {/* <Link to="#" onClick={() => { printDiv("printDiv"); }} className="btn btn-primary">
                        <i className="fa fa-print" /> Print
                      </Link> */}
                      <a
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          backgroundColor: "#fdd005",
                          border: "none",
                          marginBottom: "10px",
                          color: "black",
                          fontSize: "15px",
                          fontWeight: "500",
                          width: "190px",
                          boxShadow:
                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                          transition: "transform 0.3s ease",
                        }}

                        className="btn btn-primary"
                        href={
                          "/pkgInvoice?booking_id=" + selectedRow?.booking_id
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        {props.t("Print")}
                      </a>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(AllPackageOrders)))
