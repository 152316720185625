import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit//dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { sortBy } from "lodash"
import exportFromJSON from "export-from-json"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"

const HolidayMaster = props => {
  //meta title
  document.title = "Holiday Master | Rendezvous - Game Parlour"
  const refContainer = useRef(null)
  const [minDateVal, setMinDateVal] = useState("")
  const [disabledStatus, setDisabledStatus] = useState(true)
  useEffect(() => {
    loadAllHoliday()
  }, [])

  const { SearchBar } = Search

  const handleDownload = async () => {
    const fileName = "All Holidays"
    const exportType = "xls"

    let data_to_export = allHoliday

    if (data_to_export) {
      var data = []
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Full_Name: v.full_name,
          Short_Name: v.short_name,
          from_Date: formatDate(v.holiday_from_date),
          To_Date: formatDate(v.holiday_to_date),
        })
      })
      exportFromJSON({ data, fileName, exportType })
    }
  }

  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
        <div className="form-switch ms-2" dir="ltr">
          <input
            type="checkbox"
            style={{ height: "15px", width: "30px" }}
            title={row.is_active ? "Click to Deactivate ." : "Activate"}
            className="form-check-input"
            id={"customSwitchsize1" + row._id}
            onClick={() => {
              handleValidDelete(row)
            }}
            checked={row.is_active}
          />
          <label
            title="Click to change status."
            className="custom-control-label"
            htmlFor={"customSwitch1" + row._id}
            style={{ fontWeight: "100", cursor: "pointer" }}
          ></label>
        </div>
      </span>
    )
  }
  const [allHoliday, setAllHoliday] = useState([])
  const loadAllHoliday = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "holidays/getall"
    let response = await postSubmitForm(url, "")
    const sortedList = sortBy(response.data, "name")
    if (response.status === 1) {
      preloader(false)
      setAllHoliday(sortedList)
    } else {
      preloader(false)
      showNotification(error.message, "Error")
    }
  }

  const handleSubmit = async (e, v) => {
    try {
      const object = {
        full_name: v.full_name,
        short_name: v.short_name,
        holiday_from_date: v.holiday_from_date,
        holiday_to_date: v.holiday_to_date,
      }
      let url = process.env.REACT_APP_BASEURL + "holidays/insert"
      postSubmitForm(url, object).then(res => {
        if (res.status === 1) {
          // console.log(res)
          refContainer.current.reset()
          showNotification(res.message, "Success")
          loadAllHoliday()
        } else {
          // console.log(res)
          showWarning(res.message, "Error")
        }
      })
    } catch (error) {
      showNotification(res.error, "Error")
    }
  }

  //Deletse
  const handleValidDelete = async holiday => {
    let url = process.env.REACT_APP_BASEURL + "holidays/activate_deactivate"
    //let url = "http://localhost:3002/holidays/delete"
    let response = await postSubmitForm(url, {
      id: holiday._id,
      is_active: holiday.is_active === true ? false : true,
    })
    if (response.status === 1) {
      loadAllHoliday()
      showNotification(response.message, "Success")
    } else {
      showNotification(response.message, "Error")
    }
  }

  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [day, month, year].join("-")
  }

  const columns = [
    {
      text: props.t("S.N"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      headerStyle: (column, collIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Full Name"),
      dataField: "full_name",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Short Name"),
      dataField: "short_name",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("From Date"),
      dataField: "holiday_from_date",
      sort: true,
      formatter: cell => formatDate(cell),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "holiday_to_date",
      text: props.t("To Date"),
      sort: true,
      formatter: cell => formatDate(cell),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("No. of Days"),
      dataField: "",
      sort: true,
      formatter: (cell, row) => {
        const fromDate = new Date(row.holiday_from_date)
        const toDate = new Date(row.holiday_to_date)
        const numberOfDays = `${(toDate - fromDate) / (1000 * 3600 * 24) + 1}`
        return numberOfDays
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      style: (cell, row) => {
        return {
          textAlign: "center",
        }
      },
    },
    // {
    //   text: props.t("Action"),
    //   dataField: "",
    //   formatter: editFormatter,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "2%" }
    //   },
    // },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Holiday Master"
            breadcrumbItem1="Add Holiday"
            alternateTitle={props.t("Holiday")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm onValidSubmit={handleSubmit} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                        <div className=" mx-4">
                          <label className="form-label">
                            {props.t("Full Name *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="text"
                            name="full_name"
                            placeholder={props.t("Enter Full Name")}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Full Name cannot be empty")}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className=" mx-4">
                          <label className="form-label">
                            {props.t("Short Name *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="text"
                            name="short_name"
                            placeholder={props.t("Enter Short Name")}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Short Name cannot be empty")}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <div className="mx-4">
                          <label className="form-label">
                            {" "}
                            {props.t("From Date *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            options={{
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener("mousedown", e => {
                                  e.preventDefault() // Prevent default click behavior
                                })
                              },
                              minDate: new Date().fp_incr(1),
                            }}
                            onChange={v => {
                              const dateString = v
                              const date = new Date(dateString)
                              // Extract the date components
                              const day = date.getDate()
                              const month = date.getMonth() + 1 // Months are zero-based, so add 1
                              const year = date.getFullYear()

                              // Format the date as "dd-mm-yyyy"
                              const formattedDate = `${year}-${month
                                .toString()
                                .padStart(2, "0")}-${day
                                .toString()
                                .padStart(2, "0")}`
                              setMinDateVal(formattedDate)
                              setDisabledStatus(false)
                            }}
                            name="holiday_from_date"
                            placeholder={props.t("Please select Date")}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Date cannot be empty")}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mx-4">
                          <label className="form-label">
                            {" "}
                            {props.t("To Date *")}
                          </label>
                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            disabled={disabledStatus}
                            options={{
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener("mousedown", e => {
                                  e.preventDefault() // Prevent default click behavior
                                })
                              },
                              minDate: minDateVal,
                            }}
                            name="holiday_to_date"
                            placeholder={props.t("Please select Date")}
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Date cannot be empty")}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "100px",
                            marginTop: "1.6rem",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          {props.t("Submit")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </div>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>{props.t("All Holidays")}</Col>

                      <Col
                        lg={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{
                            width: "115px",
                            height: "35px",
                            background: "#F93A5C",
                            border: "none",
                          }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allHoliday}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display .")}
                          striped
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(HolidayMaster)))
