import React, { useState, useRef, useEffect } from "react"
import { Card,Spinner, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input, Badge } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm, AvValidator } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json" 
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"; 
import styles from "../Dashboard/Badge.module.css";
import Loader from "../../helpers/Loader"
import BackgroundImage from "../../assets/images/Background.png"

const ViewInventory = props => {

  const [isLoading, setIsLoading] = useState(true);
  const [section, setSection] = useState("");
  const { SearchBar } = Search
  // meta title
  document.title = "Item Inventory | Rendezvous - Game Parlour"
  const refContainer = useRef(null)

  const handleDownload = async () => {
    const fileName = "View Inventory";
    const exportType = "xls";
  
    // let data_to_export = allInventoryItems;
    let data_to_export = section!=="" 
        ? allInventoryItems.filter(item=>item.section===section) 
        : allInventoryItems
  
    if(data_to_export){
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v){
        data.push({
          Name: v.item_name,
          category: v.category,
          section:v.section,
          Formula: v.formula,
          Unit : v.unit,
          Sub_Unit : v.sub_unit,
          Threshold: v.threshold,
          Total_Units_Available: v.total_quantity_in_unit,
          Total_Sub_Units_Available: v.total_quantity_in_sub_unit,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  } 


  //for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  
  const [allInventoryItem, setAllInventoryItem] = useState()


  //Format Date
  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }
 
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  );

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page);
      setPageSize(sizePerPage);
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "All",
        value: allInventoryItem && allInventoryItem.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  };

  
 

  useEffect(() => {
    loadAllInventoryItem();
  }, [])


  const [allInventoryItems, setAllInventoryItems] = useState([])
  // console.log(allInventoryItems)
  const loadAllInventoryItem = async () => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "item_inventory/get_all_items"
    //let url = "http://localhost:3002/item_inventory/get_all_items"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      Loader(false)
      setAllInventoryItems(response.data);
      setIsLoading(prev => prev = false)
    } else {
      Loader(false)
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  const [Row2, setRow2] = useState([])
//  console.log(allInventoryItem)
  //Action
  function editFormatter(cell, row) {
    return (
      <span className="text-info">
        <i
          className="bx bxs-file font-size-15"
          title="View Details"
          style={{ cursor: "pointer", color:"blue" }}
          onClick={() => {
            setEditModal(!editModal)
            setAllInventoryItem(row)
         setRow2(row.quantity_in_every_batch)
          }}
        ></i>
        {/* <i
          className="fas fa-trash ms-3 fs-5 text-danger"
          title="Click to Delete"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleValidDelete(row)
          }}
        ></i> */}
      </span>
    )
  }

 



  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize +(+rowIndex +1);
        return rowNumber;
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
    // {
    //   text: props.t("Image"),
    //   formatter: showPhotoFormatter,
    //   dataField: "item_details.image",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    {
      text: props.t("Name"),
      dataField: "item_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
        text: props.t("Category"),
        dataField: "category",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "3%" }
        },
      },
    // {
    //   text: props.t("Unit"),
    //   dataField: "quantity.unit",
    //   sort: false,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "5%" }
    //   },
    // },
    {
        text: props.t("Available"),
        dataField: "total_quantity_in_unit" ,
        sort: true,
        formatter: (cell, row) => {
          if(row.total_quantity_in_unit <= row.threshold && row.sub_unit!==""){
            return (
              <>
                <Badge style={{ fontSize: "12px" }} className={`${styles.glowBadge} badge-soft-danger`}>{ row.total_quantity_in_unit + "  " + row.unit + " " + row.total_quantity_in_sub_unit + " " + row.sub_unit }</Badge>
              </>
            );
          } else if (row.total_quantity_in_unit <= row.threshold && row.sub_unit === "") {
            return (
              <>
                <Badge style={{ fontSize: "12px" }} className={`${styles.glowBadge} badge-soft-danger`}>{row.total_quantity_in_unit + "  " + row.unit}</Badge>
              </>
            );
          }
          else if (row.sub_unit!=="") {
            return (
              <>
                {row.total_quantity_in_unit + "  " + row.unit + " " + row.total_quantity_in_sub_unit + " " + row.sub_unit}
              </>
            );
          }
           else {
            return (
              <>
                {row.total_quantity_in_unit + "  " + row.unit}
              </>
            );
          }
         
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
    {
      text: props.t("Threshold"),
      dataField: "threshold",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
        text: props.t("Formula"),
        dataField: "formula",
        sort: false,
        formatter: (cell,row)=>{
          if (row.formula>0){
            return (
              <>
                {"1 " + row.unit + " = " + row.formula + " " + row.sub_unit}
              </>
            )
          }
          
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
    {
        text: props.t("Section"),
        dataField: "section",
        filter: selectFilter({
          style:{ marginLeft:"5px"},
          options: [
            { value: "Accessories", label: "Accessories" },
            { value: "Cafe", label: "Cafe" },
            { value: "Kitchen", label: "Kitchen" },
          ],
          placeholder: "ALL",
          onFilter: (value) => {
            setSection( value )
          } 
        }),
        headerStyle: (colum, colIndex) => {
          return { width: "2%" }
        },
      },
    // {
    //     text: props.t("Expiry Date"),
    //     dataField: "expiry_date",
    //     formatter:(cell, row) => {
    //      const date = row.expiry_date;
    //      const formattedDate = moment(date).format('DD-MM-YYYY')
    //      return formattedDate;
    //     },
    //    // sort: false,
    //     headerStyle: (colum, colIndex) => {
    //       return { width: "5%" }
    //     },
    //   },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
    },
  ]

  //Detail View
  const itemDetails = [
   
   
    {
      text: props.t("Batch"),
      dataField: "batch",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
        text: props.t("Quantity"),
        dataField: "quantity" ,
        sort: false,
        formatter: (cell, row) => {
          return (
          <>
              {row.quantity.in_unit + "  " + allInventoryItem.unit +
              " "+ row.quantity.in_sub_unit + " " + allInventoryItem.sub_unit}
          </>
          );
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
      {
        text: props.t("Cost Price"),
        dataField: "price",
        sort: true,
        formatter: (cell,row)=>{
          return(
            <>
            {row.price ? row.price : "0"}
            </>
          )
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
      {
        text: props.t("Selling Price"),
        dataField: "selling_price",
        sort: true,
        formatter: (cell,row)=>{
          return(
            <>
            {row.selling_price ? row.selling_price : "0"}
            </>
          )
        },
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
      {
        text: props.t("Remarks"),
        dataField: "",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
    {
        text: props.t("Expiry Date"),
        dataField: "expiry_date",
        formatter:(cell, row) => {
         const date = row.expiry_date? row.expiry_date : null;
         const formattedDate = formatDate(date)
         return formattedDate;
        },
       // sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "5%" }
        },
      },
      {
        text: props.t("Entry Date"),
        dataField: "entry_date",
        formatter:(cell, row) => {
         const date = row.entry_date;
         const formattedDate = formatDate(date)
         return formattedDate;
        },
       // sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: "6%" }
        },
      },
  ]

  // const rowStyle = row => {
  //   if (row.total_quantity_in_unit < row.threshold) {
  //     return { backgroundColor: "#ffcdd2" }
  //   }
  //   return {}
  // }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs title="Inventory" breadcrumbItem1="View Inventory" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                <CardTitle>
                    <Row>
                      <Col lg={4}>
                      {props.t("All Inventory Items")}
                      </Col>

                      {/* <Col lg={4}>
                      {props.t("Branch")}
                      </Col>
                      */}
                     
                    </Row>
                  </CardTitle>
                  {/* <CardSubtitle className="mb-3">
                    {props.t("View all existing Inventory Items")}
                  </CardSubtitle> */}
                  { isLoading === true ? <Spinner style={{display:"block",margin:"auto"}} />  : <ToolkitProvider 
                    keyField="_id"
                    columns={columns}
                    data={allInventoryItems && allInventoryItems}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                              <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                            </Col>
                            <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          disabled={!allInventoryItems}
                          onClick={handleDownload}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>

                          </Row>
                        </AvForm>
                        <BootstrapTable 
                         
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          // striped
                          // rowStyle={rowStyle}
                          // hover
                          condensed
                          filter={filterFactory()}
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>}

                  <Modal
                    size="xl"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Item Details - ")}
                      {allInventoryItem && allInventoryItem.item_name}
                    </ModalHeader>
                    <ModalBody>
                      {Row2 && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                <ToolkitProvider 
                                    keyField="_id"
                                    columns={itemDetails}
                                    data={Row2}
                                   

                  >
                    {propst => (
                      <div>
                        <BootstrapTable 
                         
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ViewInventory)))
