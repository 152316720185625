import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input, Badge } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import { postSubmitForm } from "../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
import moment from "moment"
import logo from "../../assets/images/Logo.png"
import preloader from "../../helpers/Loader"
// import Flatpickr from "react-flatpickr"
const LeaveReport = props => {

    const { SearchBar } = Search
    const [pending, setPending] = useState(0)
    const [approved, setApproved] = useState(0)
    const [Rejected, setRejected] = useState(0)
    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [fromdate, setFromdate] = useState(new Date())
    const [todate, setTodate] = useState(new Date())
const [status, setStatus] = useState("")
const [allLeaveData, setAllLeaveData] = useState([])
const code = localStorage.getItem("code")


    const handleDownload = async () => {

        const fileName = "Leave Report";
        const exportType = "xls";

        // let data_to_export = allLeaveData;
        let data_to_export = status !== ""
            ? allLeaveData.filter(item => item.status === status)
            : allLeaveData
        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                   Name:v.employee_name,
                   Code:v.employee_code,
                   Status:v.status,
                   LeaveType:v.leave_type,
                   FromDate:v.date_from?moment(v.date_form).format("YYYY-DD-MM"):"",
                   ToDate:v.date_to?moment(v.date_to).format("YYYY-DD-MM"):"",
                   Reason:v.reason,
                });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }



    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: allLeaveData && allLeaveData.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };

    useEffect(() => {
        handleValidSubmit()
    }, [])


    const handleValidSubmit = async () => {
        let dateFrom = moment(fromdate);
        let dateTo = moment(todate);
        let totalDays = dateTo.diff(dateFrom, 'days') + 1;
        
        if(totalDays>31){
            showWarning("Total number of days cannot be more than 31.", "Warning");
            return ;
        }
        preloader(true)
        const payload = {
            date_from: moment(fromdate).format("YYYY-MM-DD"),
            date_to: moment(todate).format("YYYY-MM-DD"),
        }
        let url = process.env.REACT_APP_BASEURL + "leaveapplications/getall_by_date_range"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            preloader(false)
            setAllLeaveData(prev => prev = response.data)
            const data = response.data
        const totalPending = data.reduce((total, item) => {
          if (item.status === 'Pending') { return total + 1; } return total;
        }, 0);
        setPending(totalPending);
        const totalApproved = data.reduce((total, item) => {
          if (item.status === 'Approved') { return total + 1; } return total;
        }, 0);
        setApproved(totalApproved);
        const totalRejected = data.reduce((total, item) => {
            if (item.status === 'Rejected') { return total + 1; } return total;
          }, 0);
        setRejected(totalRejected);
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
    }

    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const imageHeight = "105px";
        const imageWidth = "110px";
        const borderradius = "50%";
        const background = "#000000";
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 140px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Leave Count Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function () {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };


    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = rowIndex + 1
                return rowNumber
            },
            text: props.t("#"),
            headerStyle: (colum, colIndex) => {
                return { width: "3%" }
            },
        },
        {
            text: props.t("Employee Code"),
            //formatter: employeeFormatter,
            dataField: "employee_code",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "employee_name",
            text: props.t("Name"),
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "date_from",
            text: props.t("From Date"),
            formatter: (cell, row) => {
                return (
                    <p>{row.date_from.slice(0, 10)}</p>
                )
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "date_to",
            text: props.t("To Date"),
            formatter: (cell, row) => {
                return (
                    <p>{row.date_to.slice(0, 10)}</p>
                )
            },
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "12%" }
            },
        },
        {
            dataField: "leave_type",
            text: props.t("Type of Leave"),
            sort: true,
            formatter: (cell, row) => cell || '0',
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "reason",
            text: props.t("Reason"),
            sort: false,
            formatter: (cell, row) => cell || '0',
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },
        {
            dataField: "status",
            text: props.t("Status"),
            sort: false,
            formatter: (cell, row) => {
                return (
                    <>
                        <Badge
                            className={`font-size-12 badge-soft-${row.status === "Approved"
                                ? "success"
                                : row.status === "Pending"
                                    ? "warning"
                                    : row.status === "Rejected"
                                        ? "danger"
                                        : null
                                }`}
                        >
                            {row.status}
                        </Badge>

                        <div>
                            <p
                                style={{
                                    fontSize: "11px",
                                    fontWeight: "500",
                                    marginTop: "0.4rem",
                                }}
                            >
                                {row.remark
                                    ? `Remark:   ${row.remark} `
                                    : ""}
                            </p>
                        </div>
                    </>
                )
            },
            filter: selectFilter({
                options: [
                    { value: "Approved", label: "Approved" },
                    { value: "Pending", label: "Pending" },
                    { value: "Rejected", label: "Rejected" },
                ],
                defaultValue: 'Pending',
                placeholder: "ALL",
                onFilter: (value) => {
                    setStatus( value )
                  } 
            }),
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "8%" }
            },
        },

    ]


    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };




    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Leave Report" breadcrumbItem="Leave Report" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "black",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <Row >
                                            <Col md={3}>
                                                <label style={{ color: "white" }} className="form-label">
                                                    From Date
                                                </label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    options={{

                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                    }}
                                                    onChange={(v) => {

                                                        const dateString = v;
                                                        setFromdate(new Date(dateString))
                                                        const date = new Date(dateString);
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1;
                                                        const year = date.getFullYear();

                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                                                        setMinDateVal(formattedDate);
                                                        setDisabledStatus(false)

                                                    }}
                                                    value={fromdate}
                                                    name="date_from"
                                                    placeholder="Select From Date"
                                                    errorMessage="Select From Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>

                                            <Col md={3} >

                                                <label style={{ color: "white" }} className="form-label">
                                                    To Date
                                                </label>
                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    value={todate}
                                                    onChange={(v) => {
                                                        const dateString = v;
                                                        setTodate(new Date(dateString))
                                                        const date = new Date(dateString);
                                                        const day = date.getDate();
                                                        const month = date.getMonth() + 1;
                                                        const year = date.getFullYear();
                                                        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                                    }}
                                                    options={{
                                                        minDate: minDateVal,
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },

                                                    }}
                                                    name="date_to"
                                                    placeholder="Select To Date"
                                                    errorMessage="Select To Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />

                                            </Col>



                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "128px",
                                                    marginTop: "1rem",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }} type="submit"
                                                    // color="primary"
                                                    // className="btn btn-info btn-md"
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}

                                >
                                    <CardTitle>
                                        <Row>

                                            <Col lg={6}>
                                                {props.t("All Leave Details ")}
                                            </Col>

                                            <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={() => { printDiv("printDiv") }}
                                                    disabled={allLeaveData.length <= 0}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                                                <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={allLeaveData.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </CardTitle>
                                    {/* <CardSubtitle className="mb-3">
                                        {props.t("View All")}
                                    </CardSubtitle> */}
                                    <div id="printDiv">
                                        <ToolkitProvider
                                            keyField="_id"
                                            columns={columns}
                                            data={allLeaveData && allLeaveData || []}
                                            search

                                        >
                                            {propst => (
                                                <div>
                                                    <AvForm>
                                                        <Row className="hide">
                                                            <Col lg={2} style={{ display: "flex", alignItems: "flex-start" }}>
                                                                <SearchBar
                                                                    className="form-control"
                                                                    {...propst.searchProps}
                                                                    // style={{ width: "340px" }}
                                                                    placeholder="search"
                                                                />
                                                            </Col>
                                                            <Col lg={10} >
                                                                <Row style={{ fontSize: "20px", display: "flex", justifyContent: "space-around" }}>
                                                                    <Col ><Label>Pending : {pending}</Label></Col>
                                                                    <Col ><Label>Approved : {approved}</Label></Col>
                                                                    <Col > <Label>Rejected : {Rejected}</Label></Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </AvForm>
                                                    <BootstrapTable

                                                        {...propst.baseProps}
                                                        filter={filterFactory()}
                                                        noDataIndication={props.t("No data to display.")}
                                                        striped
                                                        hover
                                                        condensed
                                                    // pagination={paginationFactory(options)}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>





                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(LeaveReport)))
