import { SHIFT_API_ERROR, SUBMIT_SHIFT, SUBMIT_SHIFT_SUCCESS } from "./actionTypes"

export const submitShift = (addShift, history) => {
    return {
        type: SUBMIT_SHIFT,
        payload:{ addShift, history },
    };
};

export const submitShiftSuccess = (addShift) => {
    return {
        type:SUBMIT_SHIFT_SUCCESS,
        payload: { addShift},
    };
};

export const shiftApiError = (error) => {
    return {
        type:SHIFT_API_ERROR,
        payload:{error},
    };
};