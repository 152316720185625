import React, { useState, useEffect } from "react"
import { Col, Row, Container, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import logo from "../../assets/images/Logo.png"
import { Link } from "react-router-dom"
import moment from "moment"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"

const RefundInvoice = props => {
  const [selectedRow, setselected_Row] = useState()
  const [isLoading, setIsLoading] = useState(false)

  function printInvoice() {
    window.print()
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("booking_id")) {
      loadData({ booking_id: urlParams.get("booking_id") })
    }
  }, [])

  const loadData = async data => {
    setIsLoading(true)
    let url = process.env.REACT_APP_BASEURL + "refunds/refund_print"
    const response = await postSubmitForm(url, data)
    if (response && response.status === 1) {
      setIsLoading(false)
      setselected_Row(response.data[0])
    } else {
      setIsLoading(false)
      showNotification(response.message, "Error");
    }
  }

  return (
    <React.Fragment>
      {isLoading === true ? (
        <div  style={{
           display:"flex",
           flexDirection:"column",
           justifyContent:"center",
           alignItems:"center",
           gap:"1rem",
           marginTop:"7rem"
                     
        }}>
          <Spinner style={{ margin:"auto", height: "4rem", width: "4rem" }} />
          <p style={{ marginTop: "10px", textAlign: "center" }}>Generating bill, please wait...</p>
        </div>
      ) : (
        <>
 {selectedRow && (
  <div style={{ width: "8.5cm", margin: "auto", marginTop: "70px" }}>
  <div
    className="row head"
    style={{
      width: "100%",
      margin: "auto",
      height: "50px",
      borderBottom: "1px dotted #000",
    }}
  >
    <div
      className="col col-md-2 col-sm-2 col-2"
      style={{ display: "flex", justifyContent: "flex-start" }}
    >
      <img
        src={logo}
        style={{
          height: "40px",
          borderRadius: " 50%",
          width: "90px",
          marginTop: "2px",
          backgroundColor: "#000000",
        }}
      />
    </div>
    <div
      className="col col-md-10 col-sm-10 col-10 pt-1"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <p
        className="text-truncate"
        style={{ color: "black", fontSize: "14px", fontWeight: "bold" }}
      >
        {`#${selectedRow.refund_id}`} 
        <br />
        <span
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "",
          }}
        >
          {moment(selectedRow.createdAt).format("YYYY-MM-DD")} [{selectedRow?.refunder?.employee_name}]
        </span>
        {/* <span style={{ display: "flex", justifyContent: "flex-end", marginTop: "" }}>{name}</span> */}
      </p>
    </div>
  </div>
  <br />
  <strong style={{ color: "black" }}>
    <div style={{ margin: "0px 2px 0px 2px" }}>
      <div className="row">
        <p>
          <b>{props.t("Refunded To")}</b>
        </p>
      </div>
      <p
        className="mb-0 date"
        style={{
          fontSize: "14px",
          marginTop: "-15px",
          width: "100%",
          margin: "auto",
        }}
      >
        <span>
          {props.t("Name")}: &nbsp;
          <b>{selectedRow.customer_details.name}</b>
        </span>
      </p>
      <p
        className="mb-0 d-flex justify-content-between"
        style={{ fontSize: "14px" }}
      >
        <span>
          <span>{props.t("Phone")}:</span> &nbsp;
          <b>{selectedRow.customer_details.mobile}</b>
        </span>
        <span>
          <span>{props.t("Type")}:</span> &nbsp;
          <b>{selectedRow.customer_details.customer_type}</b>
        </span>
      </p>
    </div>
    <div
      className="row mt-3 mb-0"
      style={{ fontSize: "14px", width: "100%", margin: "auto" }}
    >
      <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-12">
        <div
          className="table-responsive"
          style={{
            width: "109%",
            marginLeft: "-0.8rem",
            color: "black",
          }}
        >


          {/* Foods */}


                <div style={{ marginLeft: "2px", marginRight: "2px" }}>
                <p
                    style={{
                      marginBottom: "5px",
                      borderBottom: "1px dotted #000",
                    }}
                  >
                    <b>{props.t("Refund Details ")}</b>
                  </p>
                  <Row sm={10} lg={10} md={10} style={{ width: "8.5cm"}}>
                    <Col sm={6} md={6} lg={6}>
                    <p><strong>{props.t("Refund Amount")}:</strong></p>
                    <p><strong>{props.t("Refund Against")}:</strong></p>
                    <p><strong>{props.t("Remarks")}:</strong></p>
                    </Col>
                    <Col sm={6} md={6}  lg={6}>
                    <p><strong>{selectedRow.refund_amount||0}</strong></p>
                    <p><strong>{selectedRow.invoice_no||"N/A"}</strong></p>
                    <p><strong>{selectedRow.refund_remarks||0}</strong></p>
                    </Col>
                  </Row>
                
                </div>
        
        </div>


        <div className="row text-center" style={{
                    //   marginBottom: "0px",
                      borderTop: "1px dotted #000",
                    }}>
          <div className="col col-lg-12 mt-2">
            {props.t("Thank you")} <br />
            {props.t("Visit Again")}
          </div>
        </div>
      </div>
    </div>
  </strong>
</div>
 )}
 </>
       )}

      {isLoading === true ? (
        <div></div>
      ) : ( 
        <Row className="d-print-none col text-right pr-5 mt-5">
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <Link
              to="#"
              onClick={() => {
                printInvoice()
                // localStorage.removeItem("invoiceData")
              }}
              className="btn btn-primary"
            >
              <i className="fa fa-print" /> {props.t("Print")}
            </Link>
          </Col>
        </Row>
       )} 
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(RefundInvoice)))
