import React, { useState, useRef, useEffect } from "react"
import { Card, CardBody, Col, Row, Container, Button, Form, Modal, ModalHeader, ModalBody, FormGroup, Label, CardTitle, CardSubtitle, Input } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import { postSubmitForm, postSubmitForm_withformdata } from "../../../helpers/backend_helper"
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../../helpers/show_notificaton"
import exportFromJSON from "export-from-json";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import logo from "../../../assets/images/Logo.png"
import BackgroundImage from "../../../assets/images/Background.png"
import preloader from "../../../helpers/Loader"
// import Flatpickr from "react-flatpickr"

const ShiftClosure = props => {


    const { SearchBar } = Search
    const [disabledStatus, setDisabledStatus] = useState(true);
    const [minDateVal, setMinDateVal] = useState("");
    const [shiftClosureData, setShiftClosureData] = useState([])

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [employee, setEmployee] = useState();
    const [shiftDate, setShiftDate] = useState();
    const [shiftTime, setShiftTime] = useState();
    const code = localStorage.getItem("code")

    const handleDownload = async () => {
        // console.log("hello")
        const fileName = "Shift_Closure";
        const exportType = "xls";

        let data_to_export = shiftClosureData;

        if (data_to_export) {
            var data = [];
            JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
                data.push({
                    // Serial_Number: index + 1,
                    Customer_Name: v.customer_details.name,
                    Mobile: v.customer_details.mobile,
                    Type: v.customer_details.customer_type
                 });
            });
            exportFromJSON({ data, fileName, exportType });
        }
    }

    function formatDate(date) {
        if (!date) return null
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
    }

    useEffect(()=>{
loadShiftClosuredetails()
    },[])
 
   
 


    const printDiv = (divName) => {
        let content = document.getElementById(divName);
        var printWindow = window.open("",
            "",
            "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            const imageHeight = "105px";
            const imageWidth = "110px"; 
            const borderradius = "50%";
            const background = "#000000"; 
        printWindow.document.write(`
       
           <html>
            <title> Rendezvous - Game Parlour-${code}</title>
           <head> 
            <style>
            table {
              table-layout: fixed;
              font-family: "Poppins", sans-serif;
              border: 1px solid #eff2f7;
              font-size: 12px;
              border-collapse: collapse;
              max-width: 100%;
              color: #495057;
            
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            
            for-print-heading {
              font-size: 16px;
              margin: 0 0 0 0;
              font-weight: bold;
            }
    
            for-print-sub_heading {
              font-size: 14px;
              margin: 0 0 0 0;
              font-weight: normal !important;
            }
            table td, table th {
              border: 1px solid #ddd;
              padding: 8px;
            }
            
            table tr:nth-child(even){background-color: #f2f2f2;}
            
            table tr:hover {background-color: #ddd;}
            
            table th {            
              text-align: left;
            }
             .head{
              display:flex;
              justify-content:space-between;
             }
             .hide{
                display:none;
             }
             .react-bootstrap-table-pagination {
                display: none; 
            }
            .heading{
                display: flex;
                align-items: center;
            }
            h2 {
                margin: 0; 
            }
            .employee{
                margin-top:12px;
                display:flex;
                justify-content:space-between;
            }
            #logoImg {
                height: ${imageHeight};
                width: ${imageWidth};
                background-color: ${background};
                border-radius: ${borderradius};
                margin-right: 140px; 
            }
            </style>
           </head>
        <body>
       <div class="heading">
    
       <img src=${logo} id="logoImg" alt="Logo" >
       <h2>  Shift Closure Report</h2>
       </div>
      ${content.innerHTML}
        </body>
           </html>
    
        `);
        printWindow.document.close();
        const logoImg = printWindow.document.getElementById('logoImg');
        logoImg.onload = function() {
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        };
    };


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} - {to} of {size} records.
        </span>
    );

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // hideSizePerPage: true,
        // hidePageListOnlyOnePage: true,
        onPageChange: (page, sizePerPage) => {
            setPageNumber(page);
            setPageSize(sizePerPage);
        },
        firstPageText: "First",
        prePageText: "Prev",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: "100",
                value: 100,
            },
            {
                text: "200",
                value: 200,
            },
            {
                text: "300",
                value: 300,
            },
            {
                text: "All",
                value: shiftClosureData && shiftClosureData.length,
            },
        ],
        hideSizePerPage: true,
        ignoreSinglePage: true,
    };


    const loadShiftClosuredetails = async () => {
        preloader(true)
        let payload = {
            date : new Date()
        }
        let url = process.env.REACT_APP_BASEURL + "reports/shift_closure"
        const response = await postSubmitForm(url, payload)
        if (response && response.status === 1) {
            preloader(false)
            setEmployee(response.shift_handover_by);
            setShiftTime(response.shift_handover_time);
            setShiftDate(response.shift_date)
            setShiftClosureData(prev => prev = response.data)
        } else {
            preloader(false)
            showNotification(response.message, "Error")
        }
    }

    const SaveShiftClosure = async () => {
        preloader(true)
        let payload ={
            date : new Date(),
            source : "save_shift_closure"
        }
        let url = process.env.REACT_APP_BASEURL + "reports/shift_closure";
        const response = await postSubmitForm(url, payload)
        if(response && response.status === 1){
            preloader(false)
            printDiv("printDiv");
        }else{
            preloader(false)
            showNotification(response.message,"Error")
        }
    }

 
    // const handleValidSubmit = async (e, v) => {
    //     const object = {
    //         date: v.date,
    //     }
    //     loadShiftClosuredetails(object);
    // }

 



    const columns = [
        {
            dataField: "_id",
            hidden: true,
        },
        {
            dataField: "_id",
            formatter: (cell, row, rowIndex) => {
                const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
                return rowNumber;
            },
            text: props.t("#"),
            headerStyle: (colum, colIndex) => {
                return { width: "0.5%" }
            },
        },
        
        {
            text: props.t("Item Name"),
            dataField: "name",
            sort: false,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Section"),
            dataField: "section",
            sort:true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
       
        {
            text: props.t("Threshold"),
            dataField: "threshold",
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Opening Count"),
            dataField: "opening_count.in_unit",
            sort: true,
            formatter: (cell, row) => {
                if(row.sub_unit!=="" || row.sub_unit!==null)
                return (
                    <>
                        {row.opening_count.in_unit + "  " + row.unit }
                    </>
                ); else{
                    return (
                        <>
                            {row.opening_count.in_unit + "  " + row.unit + " " + row.opening_count.in_sub_unit + " " + row.sub_unit}
                        </>
                    ); 
                }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },
        {
            text: props.t("Closing Count"),
            dataField: "closing_count.in_unit",
            sort: true,
            formatter: (cell, row) => {
                if(row.sub_unit!=="" || row.sub_unit!==null)
                return (
                    <>
                        {row.closing_count.in_unit + "  " + row.unit }
                    </>
                ); else{
                    return (
                        <>
                            {row.closing_count.in_unit + "  " + row.unit + " " + row.closing_count.in_sub_unit + " " + row.sub_unit}
                        </>
                    ); 
                }
            },
            headerStyle: (colum, colIndex) => {
                return { width: "5%" }
            },
        },

    ]

    const backgroundImageStyle = {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };

    return (
        <React.Fragment>
            <div className="page-content" style={backgroundImageStyle}>
                <Container fluid={true}>
                    <Breadcrumbs title="Shift Closure" breadcrumbItem1="View Shift Closure" />
                    {/* <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        color: "white",
                                        background:
                                            "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
                                        <Row >
                                            <Col md={3}>
                                         
                                                    <Label>{props.t("Select Date")}</Label>

                                                <AvField
                                                    className="form-control"
                                                    type="select"
                                                    tag={Flatpickr}
                                                    options={{
                                                        onDayCreate: (dObj, dStr, fp, dayElem) => {
                                                            dayElem.addEventListener('mousedown', (e) => {
                                                                e.preventDefault(); // Prevent default click behavior
                                                            });
                                                        },
                                                        maxDate: "today",
                                                          
                                                    }}
                                                   
                                                    name="date"
                                                    placeholder="Select Date"
                                                    errorMessage="Select Date."
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                            </Col>

                                          

                                            <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                                                <label style={{ height: "0px" }} className="form-label">

                                                </label>
                                                <Button type="submit"
                                                    style={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        backgroundColor: "#fdd005",
                                                        border: "none",
                                                        color: "black",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "128px",
                                                        marginTop: "1rem",
                                                        boxShadow:
                                                            "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                        transition: "transform 0.3s ease",
                                                    }}
                                                    onMouseEnter={e => {
                                                        e.target.style.transform = "scale(1.05)"
                                                    }}
                                                    onMouseLeave={e => {
                                                        e.target.style.transform = "scale(1)"
                                                    }}
                                                >
                                                    Show
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            right: "0",
                                                            bottom: "0",
                                                            background:
                                                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                                            pointerEvents: "none",
                                                        }}
                                                    ></div>
                                                </Button>

                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody
                                    style={{
                                        boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                    }}
                                >
                                    <CardTitle>
                                        <Row>

                                           

                                            

                                        </Row>
                                    </CardTitle>
                                  <div id="printDiv">
                                  <ToolkitProvider
                                        keyField="_id"
                                        columns={columns}
                                        data={shiftClosureData && shiftClosureData}
                                        search
                                    >
                                        {propst => (
                                            <div>
                                                <AvForm>
                                                    <Row className="hide">
                                                        
                                                        <Col lg={4}>
                                               <CardTitle>
                                               {props.t("View Shift Closure Records")}
                                               </CardTitle>
                                            </Col>
                                            <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                            <SearchBar {...propst.searchProps} style={{ width: "340px" }} />
                                                        </Col>
                                            <Col lg={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                style={{
                                                    position: "relative",
                                                    overflow: "hidden",
                                                    backgroundColor: "#fdd005",
                                                    border: "none",
                                                    marginBottom:"10px",
                                                    color: "black",
                                                    fontSize: "15px",
                                                    fontWeight: "500",
                                                    width: "190px",
                                                    boxShadow:
                                                        "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                                    transition: "transform 0.3s ease",
                                                }}
                                                onMouseEnter={e => {
                                                    e.target.style.transform = "scale(1.05)"
                                                }}
                                                onMouseLeave={e => {
                                                    e.target.style.transform = "scale(1)"
                                                }}
                                                disabled={shiftClosureData?.is_saved===true}
                                                onClick={SaveShiftClosure}
                                            >
                                                <i className="mdi mdi-content-save" /> &nbsp;
                                                {props.t("Save Closure")}
                                            </Button>
                                                {/* <Button
                                                    style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                    disabled={shiftClosureData.length <= 0}
                                                >
                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button> */}
                                            </Col>
                                                    </Row>
                                                    <Row className="mb-2 fs-5 employee">
                                                        <Col lg={3} md={3} sm={3} xs={3} >
                                                        <span style={{marginRight:"10px"}}>Cashier :</span>  <strong>    {employee} </strong>
                                                        </Col>
                                                        <Col lg={3} md={3} sm={3} xs={3} >
                                                        <span style={{marginRight:"10px"}}>Closure Date : </span> <strong>    {shiftDate} </strong>
                                                        </Col>
                                                        <Col lg={3} md={3} sm={3} xs={3} >
                                                        <span style={{marginRight:"10px"}}>Closure Time :</span><strong>    {shiftTime} </strong>
                                                        </Col>
                                                        <Col lg={3} md={3} sm={3} xs={3} >
                                                            Handover To: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                                <BootstrapTable
                                                    {...propst.baseProps}
                                                    filter={filterFactory()}
                                                    noDataIndication={props.t("No data to display.")}
                                                    striped
                                                    hover
                                                    condensed
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                  </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(null, {})(withTranslation()(ShiftClosure)))
