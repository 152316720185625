import React, { useEffect, useState, useRef } from "react"
import axios from "axios";
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardTitle,
} from "reactstrap"


import Flatpickr from "react-flatpickr";
// import showNotification from "../../helpers/show_notificaton";
import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import exportFromJSON from "export-from-json";
import { formatDate } from "@fullcalendar/core";

const Arrear_Special_Punishment = (props) => {

  const { SearchBar } = Search
  const refContainer = useRef(null);
  const [employeeToken, setEmployeeToken] = useState(localStorage.getItem("userToken"));
  const [actionVisible, setActionVisible] = useState(false);
  const [monthYear, setMonthYear] = useState("")
  const [arreadDeductionData, setArrearDeductionData] = useState([])
  const [selectedUserRow, setSelectedUserRow] = useState({})
  const [fetchedMonth, setFetchedMonth] = useState('');
  const [firstTimeApiCall, setFirstTimeApiCall] = useState(false)
  const [resetBtnClickedTracker, setResetBtnClickedTracker] = useState(0)
  const [flag, setFlag] = useState(false)
  const [align, setAlign] = useState("flex-end");
  const [csvBtnWidth, setCsvBtnWidth] = useState("auto")
  const [isGeneratedData, setIsGeneratedData] = useState({});


  // console.log(selectedUserRow,"selecteduser row")


  useEffect(() => {
    loadAllEmployees()

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    // console.log(formattedDate); // Outputs the date in "YYYY-MM-DD" format


    if (firstTimeApiCall === false) {
      const initialDatePayload = {

        month: formattedDate
      }
      // console.log(initialDatePayload)
      setFirstTimeApiCall(true);
      // setFetchedMonth(month)
      generatedSalary(initialDatePayload.month, employeeToken)
      handleGetAllData(initialDatePayload.month, employeeToken, month);
    };
  }, [actionVisible, resetBtnClickedTracker])

  function preventValueChangeOnScroll(e) {

    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current     
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  // ------------------------------------------------------------------------------

  // console.log(isGeneratedData, " is salary generated")
  // let flag = false;

  // Function to handle viewport width changes
  function handleViewportChange() {
    // Get the updated viewport width
    // console.log(" handle viewport change executed")
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    // Display the updated viewport width
    // console.log("Viewport width: " + viewportWidth, flag);

    if (viewportWidth < 992 && flag === false) {
      setAlign("flex-start")
      setCsvBtnWidth("180px")
      // flag = true;
      setFlag(true)
    }
  }

  function setFlagTrue() {

    // console.log(" set flag true executed")
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth >= 992 && flag === true) {
      setAlign("flex-end")
      setCsvBtnWidth("auto")
      // flag = false;
      setFlag(false)
    }
  }

  // Attach the event listener to the window's resize event
  window.addEventListener('resize', flag === false ? handleViewportChange : setFlagTrue);



  // Dynamic Moth Year

  const currentDate = new Date();
  const options = { month: 'long', year: 'numeric' }
  const month = currentDate.toLocaleString('default', options);



  const handleDownload = async () => {
    const fileName = "Arrear/Deduction details";
    const exportType = "xls";

    let data_to_export = arreadDeductionData;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Code: v.employee_code,
          Name: v.employee_name,
          Date: formatDate(v.date),
          Arrear: v.arrear,
          Punishment: v.punishment,
          Special: v.special
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }

  const [allemployees, setAllEmployees] = useState([])
  const loadAllEmployees = async () => {
    let url = process.env.REACT_APP_BASEURL + "employees/getall"
    //let url = "http://localhost:3002/employees/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      setAllEmployees(response.data)
    } else {
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()
  const [allDesignation, setallDesignations] = useState();

  function editFormatter(cell, row) {

    // if (localStorage.getItem("actionClick") === "true") {
    //   return (
    //     <span className="text-info">
    //       <i
    //         className="bx bxs-edit fs-5 mt-1"
    //         title="Click to Edit"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           setEditModal(!editModal)
    //         }}
    //       ></i>
    //     </span>
    //   )
    // } else {
    //   return (<span className="text">Salary Generated, Can't Edit</span>);
    // }

    return (
      <span className="text-info">
        <i
          className="bx bxs-edit fs-5 mt-1"
          title="Click to Edit"
          style={{ cursor: "pointer" }}
          onClick={() => {
            // console.log(isGeneratedData.is_salary_generated, "is generated")
            if (localStorage.getItem("is_generated") === "false") {
              // console.log(localStorage.getItem("actionClick"), "action click from local storage in edit formatter")
              setEditModal(!editModal)
              setSelectedUserRow(row)
            } else {
              // console.log(localStorage.getItem("actionClick"), "from locals storage")
              showNotification(_, "Salary generated, Can't Edit !")
            }
          }}
        ></i>
      </span>
    )
  }

  // funtion to submit month for viewing Arrear/deductions.
  const handleValidSubmit = async (e, v, token, actionType) => {
    // let url = process.env.REACT_APP_BASEURL + "adminusers/inser"
    // let formData = new FormData();
    // formData.set("employee", v.employee.name)
    // formData.set("username", v.username);
    // formData.set("pwd", v.pwd);
    // formData.set("role", v.role);
    // console.log(status, "status from handle submit")
    // console.log(actionType, "actiontype")
    let object = {};
    if (actionType === "update") {

      object = {
        "employee_id": selectedUserRow.employee_id,
        "date": month + "-01",
        "arrear": v.arrear,
        "special": v.special,
        "punishment": v.punishment
      }

      handleUpdateArrear(object, employeeToken);

    } else {

      const months = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
      }
      object = {
        month: v.arrear_month
      }
      // console.log(object, "selected month object")
      // finding the current month 
      const dateObj = new Date();
      const currentMonthNum = dateObj.getMonth();
      const currentYear = dateObj.getFullYear();
      const optionsLong = { month: 'long' };
      const optionsShort = { month: 'short' };
      const monthLong = dateObj.toLocaleString('en-US', optionsLong);
      const monthShort = dateObj.toLocaleString('en-US', optionsShort);
      // console.log(dateObj,"date obj")
      // console.log(currentMonthNum,"current month")
      // setFetchedMonth(months[object.month.slice(5, 7)] + " " + object.month.slice(0, 4))
      // console.log(monthLong, "long month string")
      // console.log(object,"date object")

      // console.log(object.month.slice(5, 7),"month number")
      // console.log(currentMonth,"current month")

      // 1console.log("extracted month from date number", months[object.month.slice(5, 7)])
      const displayMonthYear = months[object.month.slice(5, 7)] + " " + currentYear;
      //  console.log(displayMonthYear,"month year")


      // setMonthYear(months[object.month.slice(5, 7)] + " " + object.month.slice(0, 4))
      if (months[object.month.slice(5, 7)] === monthLong) {
        // console.log("current month")
        generatedSalary(object.month + "-01", employeeToken,)
        handleGetAllData(object.month + "-01", employeeToken, displayMonthYear)

      } else if (Number(object.month.slice(5, 7)) < currentMonthNum + 1) {
        // console.log("past months")
        // localStorage.setItem("actionClick", false)
        generatedSalary(object.month + "-01", employeeToken)
        handleGetAllData(object.month + "-01", employeeToken, displayMonthYear)


      } else {
        // console.log("future month selected")
        showNotification("Future Data Not Present", "Error")
        // handleGetAllData(object.month + "-01", employeeToken)
        // generatedSalary(object.month + "-01", employeeToken)
      }
    }
    // const currentMonth = currentDate.slice(5,8)
    // console.log(currentMonth,"current month")

    // setApiDatePayload(object)
    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error")
    //   }
    // })
  }
  // -----------------------------------------------------------------------------------------------------

  // function to call generate salary api to get the is_generated flag status
  async function generatedSalary(date, token) {

    let url = process.env.REACT_APP_BASEURL

    const payload = {
      date: date,
      source: "get_salaries"
    }
    await axios.post(url + `generatesalaries/generate_salary`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => { 
      // console.log(res);
       setIsGeneratedData(res.data.data); localStorage.setItem("is_generated", res.data.data.is_salary_generated) }).catch(err => console.log(err));

  }

  // ------------------------------------------------------------------------------------------
  // function to call the getall api on show button click
  function handleGetAllData(datePayload, token, displayMonthYear) {

    let url = process.env.REACT_APP_BASEURL

    // console.log(datePayload, "datepayload")
    axios.post(
      url + `arreardeductions/getall`,
      //`http://localhost:3002/arreardeductions/getall`,
      { date: datePayload }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => { 
      // console.log(res);
       setArrearDeductionData(res.data.data); setFetchedMonth(displayMonthYear) }).catch(err => console.log(err))

  }
  // ------------------------------------------------------------------------------------------

  // function to update the arrear/deductions of a particular employee
  function handleUpdateArrear(payload, token) {
    let url = process.env.REACT_APP_BASEURL

    axios.post(
      url + `arreardeductions/update`,
      //`http://localhost:3002/arreardeductions/update`,
      payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => { 
      // console.log(res);
      handleGetAllData(month + "-01", employeeToken); setEditModal(!editModal) }).catch(err => console.log(err))
  }
  // ------------------------------------------------------------------------------------------------------------
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("employee id"),
      //formatter: employeeFormatter,
      dataField: "employee_id",
      hidden: true,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Employee Code"),
      //formatter: employeeFormatter,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Name"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "arrear",
      text: props.t("Arrear"),
      formatter: (cell, row) => cell || '0',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Special"),
      formatter: (cell, row) => cell || '0',
      dataField: "special",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "punishment",
      text: props.t("Punishment"),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "",
      text: props.t("Action"),
      formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },


  ]

  // if (actionVisible === false) {
  //   const specialColumnIndex = columns.findIndex((column) => column.text === props.t("Action"));
  //   if (specialColumnIndex !== -1) {
  //     columns.splice(specialColumnIndex, 1);
  //   }
  // }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };

  function hello() {
    // console.log("clicked flatpicker")
  }
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle} >
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Salary")}
            alternateTitle={props.t("Arrear/Deduction")}
            breadcrumbItem1={props.t("Arrear/Deduction")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                 style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken, "")} ref={refContainer}>
                    <Row >

                      <Col md={2}>
                        <label className="form-label">
                          Month
                        </label>

                        <AvField
                          className="form-control"
                          type="month"
                          // tag={Flatpickr}
                          // options={{
                          //   dateFormat: 'M Y',
                          //   onDayCreate: (dObj, dStr, fp, dayElem) => {
                          //     dayElem.addEventListener('mousedown', (e) => {
                          //       e.preventDefault(); // Prevent default click behavior
                          //     });
                          //   },
                          // }}
                          // onClick={hello}
                          name="arrear_month"
                          placeholder="Select month"
                          errorMessage="Select month"
                          validate={{
                            required: { value: true },
                          }}
                        />

                      </Col>

                      <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                        <label style={{ height: "20.5px" }} className="form-label">

                        </label>
                        <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "-0.2rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Show")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>

                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {/* <label className="d-flex justify-content-center fs-2">Arear, Special and Punishment Record</label> */}

              <Card>
                <CardBody
                 style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >

                  <Row >
                    <Col lg={10}>
                      <CardTitle  >
                        <h2>{fetchedMonth}</h2>

                      </CardTitle>
                    </Col>


                    <Col lg={2} style={{ display: "flex", justifyContent: `${align}` }} >
                      <Button
                       style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                        type="submit"
                        className="btn btn-secondary"
                        onClick={handleDownload}
                      >
                        <i className="mdi mdi-file-export" /> &nbsp;
                        {props.t("Export CSV")}
                      </Button>
                    </Col>

                  </Row>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={arreadDeductionData}
                    search
                    isGlobalFilter={true}
                    isAddUserList={true}
                    // handleUserClick={handleUserClicks}
                    customPageSize={10}
                  // className="custom-header-css"

                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <label className="form-label">
                              {props.t("Search By Employee Name")}
                            </label>
                            <Col lg={2} style={{ display: "flex", }}>
                              <SearchBar
                                {...propst.searchProps}
                              // style={{ width: "340px" }}
                              />
                            </Col>
                            <Col  >
                              {/* <Button className="btn btn-info" onClick={() => { setResetBtnClickedTracker(prev => prev = prev + 1); setFirstTimeApiCall(false); }}  >Reset To Current Month</Button> */}
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed

                        />
                      </div>
                    )}
                  </ToolkitProvider>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {`Update Arrear/Deduction for ${selectedUserRow.employee_name}`}

                    </ModalHeader>
                    <ModalBody>
                      <>
                        <Row>
                          <Col xlg={12}>
                            <Card>
                              <CardBody
                             
                              >
                                <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken, "update")}>
                                  <Row>
                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        name="arrear"
                                        label={props.t("Arrear")}
                                        placeholder={props.t("Arrear")}
                                        type="number"
                                        validate={{
                                          required: { value: true }
                                        }}
                                        errorMessage="Enter Arrear"
                                        value={selectedUserRow.arrear || "0"}
                                      />
                                    </Col>

                                    <Col lg={6}>
                                      <AvField
                                        onWheel={preventValueChangeOnScroll}
                                        name="special"
                                        label={props.t("Special")}
                                        placeholder={props.t("Special")}
                                        type="number"
                                        validate={{
                                          required: { value: true }
                                        }}
                                        errorMessage="Enter Special"
                                        value={selectedUserRow.special || "0"}
                                      />
                                    </Col>
                                    <Row>
                                      <Col md={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          name="punishment"
                                          label={props.t("Punishment")}
                                          placeholder={props.t("Punishment")}
                                          type="number"
                                          validate={{
                                            required: { value: true }
                                          }}
                                          errorMessage="Enter Punishment"
                                          value={selectedUserRow.punishment || "0"}
                                        />
                                      </Col>
                                    </Row>
                                  </Row>
                                  <Row>
                                    <Col lg={12}>
                                      <hr />
                                      <FormGroup className="mb-0 text-center">
                                        <div>
                                        <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        // marginTop: "1.6rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Update")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(connect(null, {})(withTranslation()(Arrear_Special_Punishment)))
