import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer"

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer"

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer"

//employees
import Employee from "./employees/reducer"

//Suppliers
import Suppliers from "./supplier/reducer"

//Accessories Item
import AccessoriesItem from "./item/accessoriesItem/reducer"

//Cafe Item
import CafeItem from "./item/cafeItem/reducer"

//Kitch Item
import KitchenItem from "./item/kitchenItem/reducer"

//Shift
import Shift from "./shift/reducer"

// employee dashboard roster
import employeeRoster from "./employee-roster/reducer"

// owner dashboard roter
import ownerRoster from "./owner-roster/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,

  Employee,
  Suppliers,
 

  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,

  AccessoriesItem,
  CafeItem,
  KitchenItem,

  Shift,

  employeeRoster,

  ownerRoster
})

export default rootReducer
