import { LOAD_ROSTER_OWNER,LOAD_ROSTER_SUCCESS_OWNER,LOAD_ROSTER_ERROR_OWNER } from "./actionTypes";
  
  export const loadRosterOwner = () => {
    return {
      type: LOAD_ROSTER_OWNER,
      payload: {},
    };
  };
  
  export const loadRosterSuccessOwner = (rosterData) => {
    console.log(rosterData," from actions FROM OWNER")
    return {
      type: LOAD_ROSTER_SUCCESS_OWNER,
      payload: rosterData,
    };
  };
  
  export const loadRosterErrorOwner = (error) => {
    return {
      type: LOAD_ROSTER_ERROR_OWNER,
      payload: error,
    };
  };
  