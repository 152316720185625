import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Table,
  Badge,
  InputGroup,

} from "reactstrap"
import swal from "sweetalert2"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { postSubmitForm } from "../../helpers/backend_helper"
import { submit } from "redux-form"
import BackgroundImage from "../../assets/images/Background.png"
import Swal from "sweetalert2"
const backgroundImageStyle = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  // filter: "brightness(70%) blur(5px)",
}
const initialOvertime = {
  date: "",
  overtime_minutes: "",
}

const Apply_Overtime = () => {
  //usestate for showing alert and spinner when apply overtime application gets submitted
  const [showalert, setShowalert] = useState(false)


  // useState for fetching and storing the data of the apply overtime table
  const [employee_id, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  )
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [appliedOvertimeDetails, setAppliedOvertimeDetails] = useState([])
  const refContainer = useRef(null)
  // ------------------------------------------------------------------------

  // useState variables for submitting the apply overtime details
  const [overtimeForm, setOvertimeForm] = useState(initialOvertime)
  const [submitButtonClickedTracker, setSubmitButtonClickedTracker] =
    useState(0)
  // --------------------------------------------------

  // useEffect to fetch the overtime data by employee ID by calling the API
  useEffect(() => {
    function getRoster(token) {
      let url = process.env.REACT_APP_BASEURL
      // console.log("token:",token)
      axios
        .post(
          url + `overtimeapplications/get_by_employee`,
          { employee_id: employee_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(res => {
          // console.log(
          //   "response from overtimeapplications/get_by_employee route",
          //   res.data.data
          // )
          setAppliedOvertimeDetails(res.data.data)

        })
        .catch(err => console.log(err))
    }

    getRoster(employeeToken)
  }, [submitButtonClickedTracker])
  // --------------------------------------------------------------------------------------------

  // function to get all the overtime details
  function getOvertimeDetails(name, value) {
    setOvertimeForm({ ...overtimeForm, [name]: value })
  }
  // console.log(overtimeForm)
  // -----------------------------------------------------

  // function for posting new overtime Details to the server
  function submitOvertime(token) {
    let url = process.env.REACT_APP_BASEURL
    const payload = { ...overtimeForm, employee_id }
    // console.log("payload:", payload)
    axios
      .post(
        url + `overtimeapplications/insert`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowalert(true)
        // console.log(res)
        setSubmitButtonClickedTracker(submitButtonClickedTracker + 1)
        const status = response.data.status; 
  
        if (status === 1) {
          Swal.fire("Success", response.data.message, "success");
        } else {
          Swal.fire("Error", response.data.message, "error");
        }
      })
      .catch(err => console.log(err))
  }
  // -------------------------------------------------------------

  // console.log(submitButtonClickedTracker)

  // function handle submit
  const handleValidSubmit = async (e, v, token) => {
    // let url = process.env.REACT_APP_BASEURL + "adminusers/inser"
    // let formData = new FormData();
    // formData.set("employee", v.employee.name)
    // formData.set("username", v.username);
    // formData.set("pwd", v.pwd);
    // formData.set("role", v.role);

    let url = process.env.REACT_APP_BASEURL

    const object = {
      employee_id: employee_id,
      overtime_minutes: v.requestedOvertime,
      date: v.date
    }
    // console.log("Data", object)
    axios
      .post(
        url + `overtimeapplications/insert`,
        object,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowalert(true)
        // console.log(res, "overtime response")
        setSubmitButtonClickedTracker(submitButtonClickedTracker + 1)
        const status = response.data.status; 
        if (status === 1) {
          Swal.fire("Success", response.data.message, "success", );
        } else {
          Swal.fire("Error", response.data.message, "error");
        }
        if (refContainer.current) {
          refContainer.current.reset();
        }
      })
      .catch(err => {
        console.log(err);

        swal.fire({
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 2000
        });
        if (refContainer.current) {
          refContainer.current.reset();
        }
      })
    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error")
    //   }
    // })
  }

  return (
    <React.Fragment>
      <div className="page-content d-flex justify-content-center" style={backgroundImageStyle}>
        <div style={{ width: "100%" }}>
          <Container fluid={true}>
            <Breadcrumbs
              title="Apply Overtime"
              breadcrumbItem="Apply Overtime"
            />

            <Row>
              <Col>
                <Card>
                  <CardBody style={{ color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",

                  }}>

                    <div>

                      <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken)} ref={refContainer}>
                        <Row>

                          <Col lg={3}>
                            <div className="mx-2">
                              <label className="form-label">
                                Requested Overtime in Minutes
                              </label>
                              <AvField
                                className="form-control"
                                name="requestedOvertime"
                                type="number"
                                placeholder="Enter Overtime Minutes"
                                errorMessage="Requested Overtime cannot be empty."
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="mx-2">
                              <label className="form-label">
                                Date
                              </label>
                              <AvField
                                className="form-control"
                                type="select"
                                tag={Flatpickr}
                                options={{
                                  maxDate: "today",
                                  onDayCreate: (dObj, dStr, fp, dayElem) => {
                                    dayElem.addEventListener('mousedown', (e) => {
                                      e.preventDefault(); // Prevent default click behavior
                                    });
                                  },

                                }}
                                name="date"
                                placeholder="Select Date"
                                errorMessage="Select Overtime Date."
                                validate={{
                                  required: { value: true },
                                }}
                              />

                            </div>
                          </Col>

                          <Col lg={3} style={{ display: "flex", justifyContent: "flex-start" }} >
                            <div
                              className="mt-2 mx-3"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                             <Button
                                      type="submit"
                                    
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "0rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      Submit
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                            </div>
                          </Col>

                        </Row>

                        <Row>
                          <Col lg={4}>
                            <div className="mx-2"></div>
                          </Col>
                          {/* <Col lg={4}>
                            <div
                              className="mt-4 mx-4"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Button type="submit" className="btn btn-info btn-lg">
                                Submit
                              </Button>
                            </div>
                          </Col> */}
                          <Col lg={4}>
                            <div className="mt-4 mx-4"></div>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>


                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody style={{
                     boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
                  }}>
                    <CardTitle>Applied Overtime Details</CardTitle>
                    <div className="table-responsive">
                      <Table className="table table-striped table-bordered mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Applied Date</th>
                            <th>Requested Overtime</th>
                            <th>Status</th>
                            <th>Approved Overtime</th>
                          </tr>
                        </thead>
                        <tbody>
                          {appliedOvertimeDetails.map((overtime, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{overtime.date.slice(0, 10)}</td>
                              <td>{overtime.overtime_minutes}</td>
                              <td>
                                <div>
                                  <Badge
                                    className={`font-size-12 badge-soft-${overtime.status === "Approved"
                                      ? "success"
                                      : overtime.status === "Pending"
                                        ? "warning"
                                        : overtime.status === "Rejected"
                                          ? "danger"
                                          : null
                                      }`}
                                  >
                                    {overtime.status}
                                  </Badge>
                                  <div >
                                    <p style={{ fontSize: "11px", fontWeight: "500", marginTop: "0.4rem" }}>
                                      {overtime.remark ? `Remark:   ${overtime.remark} ` : ""}</p>
                                    {/* <p>
                                  {overtime.remark}
                                  </p> */}
                                  </div>

                                </div>
                              </td>
                              <td>
                                <p style={{ fontWeight: "600" }}>
                                  {overtime.approved_overtime ? `${overtime.approved_overtime}` : 0}
                                </p>
                              </td>
                            </tr>
                          ))}

                          {/* <tr>
                          <th scope="row">2</th>
                          <td>12/05/2023</td>
                          <td>15/05/2023</td>
                          <td>20/05/2023</td>
                          <td>Sick overtime</td>
                          <td>
                            I am suffering from high fever and flu so i will not
                            be able to come to work.
                          </td>
                          <td>
                            <div>
                              <Badge className="font-size-12 badge-soft-danger">
                                Rejected
                              </Badge>
                            </div>
                          </td>
                        </tr> */}

                          {/* <tr>
                          <th scope="row">3</th>
                          <td>12/05/2023</td>
                          <td>15/05/2023</td>
                          <td>20/05/2023</td>
                          <td>Sick overtime</td>
                          <td>
                            I am suffering from high fever and flu so i will not
                            be able to come to work.
                          </td>
                          <td>
                            <div>
                              <Badge className="font-size-12 badge-soft-warning">
                                Pending
                              </Badge>
                            </div>
                          </td>
                        </tr> */}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Apply_Overtime
