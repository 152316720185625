import React, { useState, useEffect, useRef } from "react"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Badge,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  Table,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"

// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import moment from "moment"
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
import Swal from "sweetalert2"
import preventValueChangeOnScrol from "../../helpers/PreventValueOnScroll"
import { showWarning } from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"

const ApproveOvertime = props => {
  const { SearchBar } = Search

  const [attendance, setAttendance] = useState([])
  const [modal, setModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)
  const [checkInListModal, setCheckInListModal] = useState(false)
  const [selectedOvertime, setSelectedOvertime] = useState(null)
  const [remark, setRemark] = useState("")
  const [approved, setApproved] = useState("")
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const refContainer = useRef(null)
  const [minDateVal, setMinDateVal] = useState("")
  const [apiDatePayload, setApiDatePayload] = useState({})
  const [disabledStatus, setDisabledStatus] = useState(true)
  const [section, setSection] = useState("")

  // Dynamic Month Year
  // const [currentMonth, setCurrentMonth] = useState('');
  const [fromDateHeading, setFromDateHeading] = useState("")
  const [toDateHeading, setToDateHeading] = useState("")

  const currentMoment = moment()
  const utcOffsetFormatted = currentMoment.format("Z")
  const currentDate = new Date()
  const startDate = new Date()
  startDate.setDate(1)
  const formattedSD = moment(startDate).format("YYYY-MM-DD")
  const formattedED = moment(currentDate).format("YYYY-MM-DD")

  useEffect(() => {
    const object = {
      from_date: formattedSD,
      to_date: formattedED,
      utc: `:00${utcOffsetFormatted}`,
    }
    fetchAttendance(object)
  }, [])

  // ------------------------------------------------------------------------------

  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleApproveModal = () => {
    setApproveModal(!approveModal)
  }

  const toggleCheckInListModal = () => {
    setCheckInListModal(prev => (prev = !prev))
  }

  const fetchAttendance = object => {
    let dateFrom = moment(object.from_date);
    let dateTo = moment(object.to_date);
    let totalDays = dateTo.diff(dateFrom, 'days') + 1;
    
    if(totalDays>31){
      showWarning("Total number of days cannot be more than 31.", "Warning");
      return ;
  }
    preloader(true)
    let url = process.env.REACT_APP_BASEURL
    const userToken = localStorage.getItem("userToken")
    axios
      .post(url + "overtimeapplications/getall_by_date_range", object, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then(res => {
        preloader(false)
        const applications = res.data.data.map(application => ({
          ...application,
          isApprovedOrRejected:
            application.status === "Approved" ||
            application.status === "Rejected",
        }))

        setAttendance(applications)
      })
      .catch(err => {
        preloader(false)
        // console.log(err)
        // if (refContainer.current) {
        //   refContainer.current.reset();
        //   setDisabledStatus(true)
        // }
      })
  }
  const [checkInList, setCheckInList] = useState()
  const loadBookingsRecord = async (id) => {
    let url = process.env.REACT_APP_BASEURL + "overtimeapplications/get_booking_details"
    const response = await postSubmitForm(url, {id:id})
    if (response && response.status === 1) {
      setCheckInList(response.data)
      toggleCheckInListModal()
    } else {
      showNotification(response.message, "Error")
    }
  }

  const handleApprove = overtime => {
    setSelectedOvertime(overtime)
    toggleApproveModal()
  }

  const handleApproveSubmit = () => {
    let url = process.env.REACT_APP_BASEURL
    const userToken = localStorage.getItem("userToken")
    const { _id } = selectedOvertime
    axios
      .post(
        url + "overtimeapplications/update_status",
        {
          id: selectedOvertime,
          status: "Approved",
          approved_overtime: approved,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(response => {
        const status = response.data.status

        if (status === 1) {
          Swal.fire(
            "Success",
            "Overtime status updated successfully.",
            "success"
          )
        } else {
          Swal.fire("Error", "Overtime status update failed.", "error")
        }

        if (apiDatePayload.from_date) {
          let object = {
            from_date: apiDatePayload.from_date,
            to_date: apiDatePayload.to_date,
            utc: `:00${utcOffsetFormatted}`,
          }
          fetchAttendance(object)
        } else {
          let object = {
            from_date: formattedSD,
            to_date: formattedED,
            utc: `:00${utcOffsetFormatted}`,
          }
          fetchAttendance(object)
        }

        toggleApproveModal()
      })
      .catch(error => {
        console.error(error)
        Swal.fire("Error", "Something went wrong.", "error")
      })
  }

  const handleReject = overtime => {
    setSelectedOvertime(overtime)
    toggleModal()
  }

  const handleRejectSubmit = () => {
    let url = process.env.REACT_APP_BASEURL
    const { _id } = selectedOvertime
    const userToken = localStorage.getItem("userToken")

    axios
      .post(
        url + "overtimeapplications/update_status",
        { id: _id, status: "Rejected", remark: remark },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(response => {
        const status = response.data.status

        if (status === 1) {
          Swal.fire(
            "Success",
            "Overtime application rejected. Remark updated.",
            "success"
          )
        } else {
          Swal.fire("Error", "Overtime status update failed.", "error")
        }
        // Refresh the overtime applications list
        if (apiDatePayload.from_date) {
          let object = {
            from_date: apiDatePayload.from_date,
            to_date: apiDatePayload.to_date,
            utc: `:00${utcOffsetFormatted}`,
          }
          fetchAttendance(object)
        } else {
          let object = {
            from_date: formattedSD,
            to_date: formattedED,
            utc: `:00${utcOffsetFormatted}`,
          }
          fetchAttendance(object)
        }
        toggleModal()
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleValidSubmit = async (e, v, token) => {
    const object = {
      from_date: v.from_date,
      to_date: v.to_date,
      utc: `:00${utcOffsetFormatted}`,
    }

    const from_Date = object.from_date.slice(8, 10)
    const to_Date = object.to_date.slice(8, 10)

    const month = object.from_date.slice(5, 7)
    const date = new Date(`2000-${month}-01`)
    const monthString = date.toLocaleString("default", { month: "short" })

    const toMonth = object.to_date.slice(5, 7)
    const toDate = new Date(`2000-${toMonth}-01`)
    const toMonthString = toDate.toLocaleString("default", { month: "short" })

    const year = object.to_date.slice(0, 4)

    setFromDateHeading(`${from_Date} ${monthString}`)
    setToDateHeading(`${to_Date} ${toMonthString}`)

    // setCurrentMonth(`${monthString} ${year}`)
    setApiDatePayload(object)
    fetchAttendance(object)
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: "#",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      dataField: "date",
      text: "Date",
      formatter: (cell, row) => {
        return moment(row.date).format("YYYY-MM-DD")
      },
      hidden: false,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      text: props.t("Name/Code"),
      //formatter: employeeFormatter,
      dataField: "employee_name",
      formatter: (cell, row) => {
        return row.employee_name + "" + "(" + row.employee_code + ")"
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "overtime_minutes",
      text: "Requested Overtime",
      formatter: (cell, row) => cell || "0",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "shift_name",
      text: "Shift",
     formatter: (cell, row) => {
        return row.shift_name ? row.shift_name : " "
      },
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "in_time",
      text: "In Time",
      sort: false,
      formatter: (cell, row) => cell,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      dataField: "out_time",
      text: "Out Time",
      // formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Early In"),
      formatter: (cell, row) => {
        return row.late_in < 0 ? Math.abs(row.late_in) : ""
      },
      dataField: "late_in",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Late In"),
      formatter: (cell, row) => {
        return row.late_in > 0 ? Math.abs(row.late_in) : ""
      },
      dataField: "late_in",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Early Out"),
      formatter: (cell, row) => {
        return row.early_out > 0 ? Math.abs(row.early_out) : ""
      },
      dataField: "early_out",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      text: props.t("Late Out"),
      formatter: (cell, row) => {
        return row.early_out < 0 ? Math.abs(row.early_out) : ""
      },
      dataField: "early_out",
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "system_overtime",
      text: "System Overtime",
      // formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "check_in_list",
      text: "Check-In List",
      formatter: (cell, row) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setCheckInList([])
              loadBookingsRecord(row._id)
              // toggleCheckInListModal()
              // setCheckInList(row.booking_details || [])
            }}
          >
            <p
              style={{
                fontSize: "12px",
                marginTop: "-3px",
              }}
            >
              View
            </p>{" "}
            <p style={{ color: "#50a5f1", fontSize: "18px" }}>
              <i className="bx bx-user-check md"></i>
            </p>
          </div>
        )
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => {
        if (row && row.status) {
          return (
            <Badge
              className={`font-size-12 badge-soft-${row.status === "Approved"
                  ? "success"
                  : row.status === "Pending"
                    ? "warning"
                    : row.status === "Rejected"
                      ? "danger"
                      : null
                }`}
            >
              {row.status}
            </Badge>
          )
        }
        return null
      },
      filter: selectFilter({
        options: [
          { value: "Approved", label: "Approved" },
          { value: "Pending", label: "Pending" },
          { value: "Rejected", label: "Rejected" },
        ],
        defaultValue: "All",
        placeholder: "ALL",
        onFilter: value => {
          setSection(value)
        },
      }),
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "actions",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex" style={{ width: "100%", gap: "10px" }}>
            <i
              size="md"
              className="me-2 fs-5 fa fa-check"
              style={{ color: "#42eb60", cursor: "pointer" }}
              onClick={() => handleApprove(row._id)}
              disabled={row.isApprovedOrRejected}
            ></i>

            <i
              className="ms-2 fs-5 fa fa-times"
              size="md"
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => handleReject(row)}
              disabled={row.isApprovedOrRejected}
            ></i>
          </div>
        )
      },
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "6%" }
      },
    },
    {
      dataField: "approved_overtime",
      text: "Approved Overtime",
      // formatter: editFormatter,
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Attendance"
            alternateTitle="Overtime Approval"
            breadcrumbItem1="Approve Overtime"
          />

          {/* <Button style={{ marginTop: '-10px', marginBottom: "15px", backgroundColor: "#143758",cursor:"auto" }} >
            Overtime Approval
           </Button> */}

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "white",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm
                    onValidSubmit={(e, v) =>
                      handleValidSubmit(e, v, employeeToken)
                    }
                    ref={refContainer}
                  >
                    <Row>
                      <Col md={3}>
                        <label className="form-label">
                          {props.t("From Date")}
                        </label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          value={formattedSD}
                          onChange={v => {
                            const dateString = v
                            const date = new Date(dateString)
                            const day = date.getDate()
                            const month = date.getMonth() + 1 // Months are zero-based, so add 1
                            const year = date.getFullYear()
                            const formattedDate = `${year}-${month
                              .toString()
                              .padStart(2, "0")}-${day
                                .toString()
                                .padStart(2, "0")}`
                            setMinDateVal(formattedDate)
                            setDisabledStatus(false)
                          }}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="from_date"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col md={3}>
                        <label className="form-label">
                          {props.t("To Date")}
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          disabled={disabledStatus}
                          value={formattedED}
                          options={{
                            maxDate:"today" ,
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="to_date"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col
                        md={4}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label
                          style={{ height: "20.5px" }}
                          className="form-label"
                        ></label>
                        <Button
                          type="submit"
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "100px",
                            marginTop: "-0.2rem",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          {props.t("Show")}
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle> {props.t("All Overtime Applications")}</CardTitle>

                  <Col>
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={attendance || []}
                      search
                      isGlobalFilter={true}
                      isAddUserList={true}
                      // handleUserClick={handleUserClicks}
                      customPageSize={10}
                    // className="custom-header-css"
                    >
                      {propst => (
                        <div>
                          <AvForm>
                            <Row>
                              {/* <label className="form-label">
                                {("Search By Employee Name")}
                              </label> */}
                              <Col lg={2} style={{ display: "flex" }}>
                                <SearchBar
                                  {...propst.searchProps}
                                // style={{ width: "340px" }}
                                />
                              </Col>
                              <Col>
                                {/* <Button className="btn btn-info" onClick={() => { setResetBtnClickedTracker(prev => prev = prev + 1); setFirstTimeApiCall(false); }}  >Reset To Current Month</Button> */}
                              </Col>
                            </Row>
                          </AvForm>
                          <BootstrapTable
                            {...propst.baseProps}
                            filter={filterFactory()}
                            noDataIndication={"No data to display."}
                            striped
                            hover
                            condensed
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* check-in list modal */}
      <Modal
        isOpen={checkInListModal}
        toggle={toggleCheckInListModal}
        size="lg"
      >
        <ModalHeader toggle={toggleCheckInListModal}>
          {props.t("Check-In Customer List")}
        </ModalHeader>
        <ModalBody>
          <Table className="table table-striped table-bordered">
            <thead className="bg-light">
              <tr>
                <th>#</th>
                <th>Invoice</th>
                <th>Customer</th>
                <th>Check In</th>
                <th>Check Out</th>
                <th>Employee</th>
              </tr>
            </thead>
            <tbody>
              {checkInList &&
                checkInList.map((item, inde) => {
                  return (
                    <>
                      <tr key={inde + 1}>
                        <td>{inde + 1}</td>
                        <td>
                          {item.invoice_no ? (
                            <a
                            href={"/invoice_details?booking_id=" + item._id}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: 'blue', textDecoration: 'underline' }}
                            >
                              {item.invoice_no}
                            </a>
                          ) : (
                            "NA"
                          )}
                        </td>
                        <td>{item.customer_details?.name}</td>
                        <td>
                          {item.check_in !== null
                            ? moment(item.check_in).format("YYYY-MM-DD HH:mm")
                            : "NA"}
                        </td>
                        <td>
                          {item.check_out !== null
                            ? moment(item.check_out).format("YYYY-MM-DD HH:mm")
                            : "NA"}
                        </td>
                        <td>{item.booking_received_by?.name}</td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>

      {/* Approve Modal */}
      <Modal isOpen={approveModal} toggle={toggleApproveModal}>
        <ModalHeader toggle={toggleApproveModal}>Approve Overtime</ModalHeader>
        <ModalBody>
          <div>
            <label className="form-label">Approved Overtime (minutes)</label>
            <Input
              onWheel={preventValueChangeOnScrol}
              type="number"
              className="form-control"
              value={approved}
              onChange={e => setApproved(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleApproveModal}>
            Cancel
          </Button>
          <Button color="success" onClick={handleApproveSubmit}>
            Approve
          </Button>
        </ModalFooter>
      </Modal>

      {/* Reject Modal */}
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="d-flex align-items-center justify-content-center"
      >
        <ModalHeader toggle={toggleModal}>Reject Overtime</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Enter remark"
            value={remark}
            onChange={e => setRemark(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleRejectSubmit}>
            Reject
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(ApproveOvertime)))
