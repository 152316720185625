import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../../helpers/show_notificaton"
import exportFromJSON from "export-from-json"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import BackgroundImage from "../../../assets/images/Background.png"

const UnderStock = props => {
  const editorRef = useRef()
  const { SearchBar } = Search
  const [underStockItems, setUnderStockItems] = useState([])
  //for pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [selectedCoupon, setSelectedCoupon] = useState()
  const [submitbtn, showSubmitbtn] = useState(false)
  //meta title
  // document.title = "Under Stock Report | Rendezvous - Game Parlour"

  const handleDownload = async () => {
    // console.log("hello")
    const fileName = "Daily Closure"
    const exportType = "xls"

    let data_to_export = underStockItems

    if (data_to_export) {
      var data = []
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          // Serial_Number: index+1,
          Section: v.section,
          Item_Name: v.name,
          // Expiry_Date: moment(v.expiry_date).format('YYYY-MM-DD'),
          Available: `${v.available.in_unit} ${v.unit} ${
            v.sub_unit ? v.available.in_sub_unit : ""
          } ${v.sub_unit ? v.sub_unit : ""}`,
        })
      })
      exportFromJSON({ data, fileName, exportType })
    }
  }

  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  useEffect(() => {
    loadAllProductForSale()
  }, [])

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  )

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page)
      setPageSize(sizePerPage)
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: selectedCoupon && selectedCoupon.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  }

  //load All UnderStock

  const loadAllProductForSale = async () => {
    let source = "get_daily_closer"
    let url = process.env.REACT_APP_BASEURL + "reports/daily_closure_details"
    const response = await postSubmitForm(url, { source })
    if (response && response.status === 1) {
      setUnderStockItems([response.data])
      showSubmitbtn(true)
    } else {
      showNotification(response.message, "Error")
    }
  }

  // console.log(underStockItems)
  const saveDailyCloser = async () => {
    let source = ""
    let url = process.env.REACT_APP_BASEURL + "reports/daily_closure_details"
    const response = await postSubmitForm(url, { source })
    if (response && response.status === 1) {
      setUnderStockItems([response.data])
      showNotification(response.message, "Success")
      loadAllProductForSale()
    } else {
      showNotification(response.message, "Error")
    }
  }

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1)
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "0.5%" }
      },
    },
    {
      text: props.t("Date"),
      dataField: "closerDate",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Total Income"),
      dataField: "total_income",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Products Sold"),
      dataField: "products_sold",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Completed Bookings"),
      dataField: "completed_bookings",
      sort: false,

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Cancelled Bookings"),
      dataField: "cancelled_bookings",
      sort: false,

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Customers Visited"),
      dataField: "customers_visited",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Paid By Cash"),
      dataField: "paid_by_cash",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },

    {
      text: props.t("Paid By Card"),
      dataField: "paid_by_card",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Wallet Transactions"),
      dataField: "wallet_transactions",
      sort: true,

      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Daily Closure"
            breadcrumbItem1="Daily Closure"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>{props.t("Daily Closure Details")}</Col>

                      <Col
                        lg={6}
                        style={{ display: "flex", justifyContent: "flex-end",gap:"3rem" }}
                      >
                         { submitbtn && <Button
                            style={{
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor: "#fdd005",
                                border: "none",
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "500",
                                width: "190px",
                                marginTop:"-1px",
                                boxShadow:
                                  "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                transition: "transform 0.3s ease",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                          onClick={saveDailyCloser}
                        >
                          <i className="mdi mdi-content-save" /> &nbsp;
                          {props.t("Save Daily Closure")}
                        </Button>}
                        <Button
                          style={{
                            width: "115px",
                            height: "35px",
                            background: "#F93A5C",
                            border: "none",
                          }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={underStockItems.length <= 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("Get daily closure details")}
                  </CardSubtitle>
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={underStockItems && underStockItems}
                    // data={[]}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                            
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          // pagination={paginationFactory(options)}
                        />
                      
                      </div>

                    )}
                  </ToolkitProvider>
                  
                </CardBody>
                

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(UnderStock)))
