import React, { useState, useRef, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  CardTitle,
  CardSubtitle,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../../components/Common/withRouter"
import {
  postSubmitForm,
  postSubmitForm_withformdata,
} from "../../../helpers/backend_helper"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification, { showWarning } from "../../../helpers/show_notificaton"
import exportFromJSON from "export-from-json"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import moment from "moment"
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import logo from "../../../assets/images/Logo.png"
import BackgroundImage from "../../../assets/images/Background.png"
import Select from "react-select"

const TotalPackageSold = props => {

  const { SearchBar } = Search

  const [disabledStatus, setDisabledStatus] = useState(true)
  const [minDateVal, setMinDateVal] = useState("")
  const [branches, setBranches] = useState("")
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [todayDate, setTodayDate] = useState(new Date().toISOString().split('T')[0]);
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const code = localStorage.getItem("code")
  const branchesRef = useRef([]);
  const refContainer = useRef(null)

  const handleDownload = async () => {
    // console.log("hello")
    const fileName = "Total Package Sold"
    const exportType = "xls"

    let data_to_export = top20Customers

    if (data_to_export) {
      var data = []
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          Branch: v.branch_name,
          Total_Package_sold: v.totals?.total_packages_sold,
          Total_Price: v.totals?.total_price,
        })
      })
      exportFromJSON({ data, fileName, exportType })
    }
  }

  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0");
        const imageHeight = "105px";
        const imageWidth = "110px"; 
        const borderradius = "50%";
        const background = "#000000"; 
    printWindow.document.write(`
   
       <html>
        <title> Rendezvous - Game Parlour-${code}</title>
       <head> 
        <style>
        table {
          margin-left:100px;
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
        
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
         .hide{
            display:none;
         }
         .react-bootstrap-table-pagination {
            display: none; 
        }
        .heading{
            display: flex;
            align-items: center;
        }
        h2 {
            margin: 0; 
        }
        #logoImg {
            height: ${imageHeight};
            width: ${imageWidth};
            background-color: ${background};
            border-radius: ${borderradius};
            margin-right: 140px; 
        }
        </style>
       </head>
    <body>
   <div class="heading">

   <img src=${logo} id="logoImg" alt="Logo" >
   <h2>  Total Packages Sold</h2>
   </div>
  ${content.innerHTML}
    </body>
       </html>

    `);
    printWindow.document.close();
    const logoImg = printWindow.document.getElementById('logoImg');
    logoImg.onload = function() {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };
};

  function formatDate(date) {
    if (!date) return null
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }

  function preventValueChangeOnScroll(e) {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }



  const [top20Customers, setTop20Customers] = useState([])
  //for pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [selectedCoupon, setSelectedCoupon] = useState()

  useEffect(() => {
    loadAllBranches()
    loadGameSalesReportOnFirst()
  }, [])

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  )

  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page)
      setPageSize(sizePerPage)
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
       {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: selectedCoupon && selectedCoupon.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  }

  //load All Coupons

  const loadTop20Customers = async payload => {
    let url =
      process.env.REACT_APP_BASEURL + "reports/packages_sold_and_total_price"
    const response = await postSubmitForm(url, payload)
    if (response && response.status === 1) {
      // console.log(response.data, 'top 20 customer')

      setTop20Customers(prev => (prev = response.data))
    } else {
      showNotification(response.message, "Error")
    }
  }

  const [editModal, setEditModal] = useState()


  // fetch data
  const handleValidSubmit = async (e, v, token) => {
   

    const object = {
      from_date: v.from_date,
      to_date: v.to_date,
      branch_name: branchesRef.current[0]?.name ||"Rendezvous"
    }
    loadTop20Customers(object)
  }

  //Update
  const handleValidUpdate = async (e, v) => {
    try {
      const object = {
        id: selectedCoupon._id,
        coupon_code: v.coupon_code,
        discount_percent: v.discount_percent,
        coupon_expiry_date: v.coupon_expiry_date,
      }
      let url = process.env.REACT_APP_BASEURL + "coupons/update"
      let response = await postSubmitForm(url, object)
      if (response.status === 1) {
        // loadtop20Customers()
        setEditModal(!editModal)
        showNotification(response.message, "Success")
      } else {
        showNotification(response.message, "Error")
      }
    } catch (error) {
      showNotification(error.message, "Error")
    }
  }

  const loadGameSalesReportOnFirst = async () => {
      
    let branchesResponse = await postSubmitForm(process.env.REACT_APP_BASEURL + "branches/getall", "");
    if (branchesResponse && branchesResponse.status === 1) {
      let branchList = branchesResponse.data
      branchList.filter((brn) => brn.name === "Rendezvous");
      setBranches(branchList)
      branchesRef.current = branchList
    } else {
      console.log("Error fetching branches");
    }
    setTodayDate(new Date().toISOString().split('T')[0])
    const payloadSales = {
      from_date: todayDate || v.date_from,
      to_date: todayDate || v.date_to,
      branch_name: branchesRef.current[0]?.name,
    };

    let salesUrl = process.env.REACT_APP_BASEURL + "reports/packages_sold_and_total_price";
    let salesResponse = await postSubmitForm(salesUrl, payloadSales);

    if (salesResponse && salesResponse.status === 1) {
      refContainer.current.reset();
      setTop20Customers(salesResponse.data);
    } else {
      showNotification(salesResponse.message, "Error");
    }

};

  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1)
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "0.5%" }
      },
    },
    {
      text: props.t("Branch Name"),
      dataField: "branch_name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },
    },
    // {
    //     text: props.t("Branch Adderess"),
    //     dataField: " ",
    //     sort: false,
    //     headerStyle: (colum, colIndex) => {
    //         return { width: "25%" }
    //     },
    // },

    {
      text: props.t("Total Packages Sold"),
      dataField: "totals.total_packages_sold",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },
    },
    {
      text: props.t("Total Revenue"),
      dataField: "totals.total_price",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" }
      },
    },
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  const loadAllBranches = async () => {
    let url = process.env.REACT_APP_BASEURL + "branches/getall"
    let response = await postSubmitForm(url, "")
    if (response && response.status === 1) {
      // console.log(response.data,"branch data from api")

      let branchList = response.data.map(branch => ({
        label: branch.name,
        value: branch.value,
        adderess: branch.adderess,
      }))
      // console.log(branchList, "branch list")
      setBranches(branchList)
      // console.log(response.data)
    } else {
      console.log("Error")
    }
  }

  function handleBranchSelect(e) {
    // console.log(e, "branch select event")
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Total Packages Sold And Revenue"
            breadcrumbItem1="Total Packages Sold and revenue"
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    color: "black",
                    background:
                      "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <AvForm
                    onValidSubmit={(e, v) =>{
                      // if(selectedBranch===null || selectedBranch===""){
                      //   showWarning("Please Select Branch","warning")
                      //   return;
                      // }else{
                        handleValidSubmit(e, v, employeeToken)}
                      // }
                    }
                    ref={refContainer}
                  >
                    <Row>
                      <Col lg={3}>
                        <label
                          style={{ color: "white" }}
                          className="form-label"
                        >
                          From Date
                        </label>

                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          options={{
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          onChange={v => {
                            // if (minDateOnChangeActive === true) {

                            const dateString = v

                            const date = new Date(dateString)

                            // Extract the date components
                            const day = date.getDate()
                            const month = date.getMonth() + 1 // Months are zero-based, so add 1
                            const year = date.getFullYear()

                            // Format the date as "dd-mm-yyyy"
                            const formattedDate = `${year}-${month
                              .toString()
                              .padStart(2, "0")}-${day
                              .toString()
                              .padStart(2, "0")}`

                            // console.log(formattedDate); // Output: 26-12-2023
                            setMinDateVal(formattedDate)
                            setDisabledStatus(false)
                            //   setMaxDateOnChangeActive(false)
                            // }
                          }}
                          name="from_date"
                          placeholder="Select From Date"
                          errorMessage="Select From Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>

                      <Col lg={3}>
                        <label
                          style={{ color: "white" }}
                          className="form-label"
                        >
                          To Date
                        </label>
                        <AvField
                          className="form-control"
                          type="select"
                          tag={Flatpickr}
                          onChange={v => {
                            // if (maxDateOnChangeActive === true) {

                            const dateString = v

                            const date = new Date(dateString)

                            // Extract the date components
                            const day = date.getDate()
                            const month = date.getMonth() + 1 // Months are zero-based, so add 1
                            const year = date.getFullYear()

                            // Format the date as "dd-mm-yyyy"
                            const formattedDate = `${year}-${month
                              .toString()
                              .padStart(2, "0")}-${day
                              .toString()
                              .padStart(2, "0")}`

                            // console.log(formattedDate); // Output: 26-12-2023
                            // setMaxDateVal(formattedDate)

                            //   setMinDateOnChangeActive(false)
                            // }
                          }}
                          options={{
                            minDate: minDateVal,
                            onDayCreate: (dObj, dStr, fp, dayElem) => {
                              dayElem.addEventListener("mousedown", e => {
                                e.preventDefault() // Prevent default click behavior
                              })
                            },
                          }}
                          name="to_date"
                          placeholder="Select To Date"
                          errorMessage="Select To Date."
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </Col>
                      {/* <Col lg={4}>
                        <Label style={{ color: "white" }}>Branch</Label>
                        <Select
                          placeholder="Select Branch"
                          onInputChange={handleBranchSelect}
                          onChange={selected => {
                            setSelectedBranch(selected)
                            selected === null
                              ? `${setSelectedCustomer(
                                  prev => (prev = null)
                                )} ${setSelected_game(
                                  prev => (prev = [])
                                )} ${setAllTheGames(
                                  prev => (prev = [])
                                )} ${setAvailableTables(
                                  prev => (prev = [])
                                )} ${setSelectedTable(
                                  prev => (prev = [])
                                )} ${setSelected_item(
                                  prev => (prev = [])
                                )} ${setGrandTotal(
                                  prev => (prev = 0)
                                )} ${setCardType(
                                  prev => (prev = null)
                                )} ${setSelected_package(
                                  prev => (prev = [])
                                )} ${setCardDetails(
                                  prev => (prev = {})
                                )} ${setPrevPkgValidation(
                                  prev => (prev = false)
                                )} ${setGameValidation(
                                  prev => (prev = false)
                                )} ${setItemValidation} `
                              : ""
                          }}
                          value={selectedBranch}
                          options={branches}
                          isClearable={true}
                          isSearchable
                          required
                        />
                      </Col> */}

                      <Col
                        lg={2}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <label
                          style={{ height: "0px" }}
                          className="form-label"
                        ></label>
                        <Button
                          style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "128px",
                            marginTop: "1rem",
                            // margin: "auto",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }}
                          type="submit"
                          // color="primary"
                          // className="btn btn-info btn-md"
                          onMouseEnter={e => {
                            e.target.style.transform = "scale(1.05)"
                          }}
                          onMouseLeave={e => {
                            e.target.style.transform = "scale(1)"
                          }}
                        >
                          Show
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              right: "0",
                              bottom: "0",
                              background:
                                "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                              pointerEvents: "none",
                            }}
                          ></div>
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                        {props.t("Total Packages Sold And Revenue")}
                      </Col>

                      <Col
                        lg={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                                                    style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={() => { printDiv("printDiv") }}
                                                    disabled={top20Customers.length<=0}
                                                >
                                                    <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("printDiv") }}
                                                    />&nbsp; {props.t("Print")}
                                                </Button>
                        <Button
                          style={{
                            width: "115px",
                            height: "35px",
                            background: "#F93A5C",
                            border: "none",
                          }}
                          type="submit"
                          className="btn btn-sm btn-secondary"
                          onClick={handleDownload}
                          disabled={top20Customers.length <= 0}
                        >
                          <i className="mdi mdi-file-export" /> &nbsp;
                          {props.t("Export CSV")}
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View All")}
                  </CardSubtitle>
                 <div id="printDiv">
                 <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={top20Customers && top20Customers}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row className="hide">
                            <Col
                              lg={4}
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          filter={filterFactory()}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          hover
                          condensed
                          // pagination={paginationFactory(options)}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                 </div>

                  <Modal
                    size="lg"
                    isOpen={editModal}
                    toggle={() => setEditModal(!editModal)}
                  >
                    <ModalHeader toggle={() => setEditModal(!editModal)}>
                      {props.t("Update Item - ")}
                      {selectedCoupon && selectedCoupon.coupon_code}
                    </ModalHeader>
                    <ModalBody>
                      {selectedCoupon && (
                        <>
                          <Row>
                            <Col xlg={12}>
                              <Card>
                                <CardBody>
                                  <AvForm onValidSubmit={handleValidUpdate}>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Coupon Code")}
                                          className="form-control"
                                          type="text"
                                          placeholder="Enter Coupon Code"
                                          name="coupon_code"
                                          value={selectedCoupon.coupon_code}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <AvField
                                          onWheel={preventValueChangeOnScroll}
                                          label={props.t("Discount in percent")}
                                          className="form-control"
                                          type="number"
                                          placeholder="Enter Discount"
                                          name="discount_percent"
                                          value={
                                            selectedCoupon.discount_percent
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <AvField
                                          label={props.t("Expiry Date")}
                                          className="form-control"
                                          type="date"
                                          placeholder="Enter Expiry Date"
                                          name="coupon_expiry_date"
                                          tag={Flatpickr}
                                          options={{
                                            onDayCreate: (
                                              dObj,
                                              dStr,
                                              fp,
                                              dayElem
                                            ) => {
                                              dayElem.addEventListener(
                                                "mousedown",
                                                e => {
                                                  e.preventDefault() // Prevent default click behavior
                                                }
                                              )
                                            },
                                          }}
                                          value={formatDate(
                                            selectedCoupon.coupon_expiry_date
                                          )}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <hr />
                                        <FormGroup className="mb-0 text-center">
                                          <div>
                                            <Button
                                              type="submit"
                                              color=""
                                              className="mr-1 btn btn-primary"
                                            >
                                              {props.t("Update")}
                                            </Button>{" "}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  connect(null, {})(withTranslation()(TotalPackageSold))
)
