export const SUBMIT_ADDEMPLOYEE = "SUBMIT_ADDEMPLOYEE";
export const SUBMIT_ADDEMPLOYEE_SUCCESS = "SUBMIT_ADDEMPLOYEE_SUCCESS";
export const LOAD_ADDEMPLOYEE = "LOAD_ADDEMPLOYEE";
export const LOAD_ADDEMPLOYEE_SUCCESS = "LOAD_ADDEMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const ACTIVATE_DEACTIVATE_EMPLOYEE = "ACTIVATE_DEACTIVATE_EMPLOYEE";
export const ACTIVATE_DEACTIVATE_EMPLOYEE_SUCCESS =
  "ACTIVATE_DEACTIVATE_EMPLOYEE_SUCCESS";
export const ADDEMPLOYEE_API_ERROR = "ADDEMPLOYEE_API_ERROR";
export const LOAD_GETBYROLE = "LOAD_GETBYROLE";
export const LOAD_GETBYROLE_SUCCESS = "LOAD_GETBYROLE_SUCCESS";
