import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  CardTitle,
  CardSubtitle,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import exportFromJSON from "export-from-json"
// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next
import { withTranslation } from "react-i18next"
import { postSubmitForm } from "../../helpers/backend_helper"
import showNotification from "../../helpers/show_notificaton"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BackgroundImage from "../../assets/images/Background.png"
import preloader from "../../helpers/Loader"
const Source = props => {
  const { SearchBar } = Search
  const [allSources, setAllSources] = useState([])

  useEffect(() => {
    loadAllSources()
  }, [])

  const refContainer = useRef(null)
  
  const handleDownload = async () => {
    const fileName = "All Sources";
    const exportType = "xls";

    let data_to_export = allSources;

    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          name:v.name,
          description:v.description
        });
      });

      exportFromJSON({ data, fileName, exportType });
    }
  };

  const loadAllSources = async () => {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL + "sources/getall"
    const response = await postSubmitForm(url, {})

    if (response && response.status === 1) {
      preloader(false)
      setAllSources(response.data)
    } else {
      preloader(false)
      showNotification(response.message, "Error")
    }
  }

 

  
  const handleValidSubmit = async (e, v) => {
    let url = process.env.REACT_APP_BASEURL + "sources/insert"

    const object = {
      name:v.name,
      description:v.description,
    }
    //console.log("Data", object)
    postSubmitForm(url, object).then(res => {
      if (res.status == 1 && res.data) {
        showNotification(res.message, "Success")
        loadAllSources()
        refContainer.current.reset()
      } else {
        showNotification(res.message, "Error")
      }
    })
  }
  const columns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: "Name",
      //formatter: employeeFormatter,
      dataField: "name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "description",
      text: props.t("Description"),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    
  ]

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Source")}
            breadcrumbItem1={props.t("Add Source")}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                style={{
                 
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <AvForm onValidSubmit={handleValidSubmit} ref={refContainer}>
                    <Row>
                      <Col lg={6}>
                        <div className="mx-2">
                          <label className="form-label">
                            {props.t("Name*")}
                          </label>
                          <AvField
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={props.t("Enter Source Name")}
                            validate={{
                              required: { value: true },
                            }}
                            errorMessage={props.t("Please Enter Valid Source Name")}
                            >
                           
                          </AvField>
                        </div>
                      </Col>
                     
                  
                      <Col lg={6}>
                        <div className="mx-2 ">
                          <label className="form-label">
                            {props.t("Description")}
                          </label>
                          <AvField
                            className="form-control"
                            type="textarea"
                            placeholder={props.t("Enter Description")}
                            name="description"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                     
                      <Col lg={12}>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                           <Button
                                      type="submit"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        color: "black",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        width: "100px",
                                        marginTop: "1.6rem",
                                        margin:"auto",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                    >
                                      {props.t("Submit")}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}
                                      ></div>
                                    </Button>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mt-4 mx-4"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}
                >
                  <CardTitle>
                    <Row>
                      <Col lg={6}>
                      {props.t("All Sources")}
                      </Col>

                      <Col lg={6} style={{display:"flex", justifyContent:"flex-end"}}>
                    <Button 
                    style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                    type="submit"
                    className="btn btn-sm btn-secondary"
                    onClick={handleDownload}
                     >
                      <i className="mdi mdi-file-export" /> &nbsp; 
                       {props.t("Export CSV")}
                                </Button> 
                    </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-3">
                    {props.t("View all your sources")}
                  </CardSubtitle>

                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={allSources && allSources}
                    search
                  >
                    {propst => (
                      <div>
                        <AvForm>
                          <Row>
                            <Col lg={4} style={{display:"flex", alignItems:"flex-start"}}>
                              <SearchBar
                                {...propst.searchProps}
                                style={{ width: "340px" }}
                              />
                            </Col>
                          </Row>
                        </AvForm>
                        <BootstrapTable
                          {...propst.baseProps}
                          noDataIndication={props.t("No data to display.")}
                          striped
                          filter={filterFactory()}
                          hover
                          condensed
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(Source)))
