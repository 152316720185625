import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { postSubmitForm } from "../../helpers/backend_helper";
import { shiftApiError, submitShiftSuccess } from "./actions";
import { SUBMIT_SHIFT } from "./actionTypes";
import Swal from "sweetalert2";

function showNotification(message, type) {
  if (type === "Success") Swal.fire(type, message, "success");
  else Swal.fire(type, message, "error");
}

//Insert a Shift
function* submitShift({ payload: { addShift, history } }) {
  try {
    let url = process.env.REACT_APP_BASEURL + "shifts/insert";

    const response = yield call(postSubmitForm, url, addShift);

    if (response.status === 1) {
      showNotification("Shift Added Successfully.", "Success");
      
      yield put(submitShiftSuccess(response));
    } else {
      console.log(response);
      showNotification(response.message, "Error");
      yield put(shiftApiError(response.message));
    }
  } catch (error) {
    showNotification("Something went wrong!", "Error");
    yield put(shiftApiError(error));
  }
}
export function* watchSubmitShift() {
  yield takeEvery(SUBMIT_SHIFT, submitShift);
}

function* ShiftSaga() {
  yield all([fork(watchSubmitShift)]);
}

export default ShiftSaga;
