import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { submitKitchenItemError, submitKitchenItemSuccess } from "./actions";
import { SUBMIT_KITCHEN_ITEM } from "./actionTypes";
import { postSubmitForm } from "../../../helpers/backend_helper";

//notifications
function showNotification(message, type){
    if (type === "Success") Swal.fire(type,message,"success");
    else Swal.fire(type,message,"error");
}
function* submitKitchenItem({ payload: {addItem, history} }) {
    try{
        let url = process.env.REACT_APP_BASEURL + "kitchen_items/insert";

        const response = yield call(postSubmitForm, url, addItem);
        if(response.status === 1){
            showNotification("Item added Successfully", "Success");
            yield put(submitKitchenItemSuccess(response));
        }else{
            console.log("Saga Responce",response);
            showNotification(response.message,"Error");
            yield put(submitKitchenItemError(response.message));
        }
    }catch(error){
        showNotification("Something went wrong!","Error");
        yield put(submitKitchenItemError(error));
    }

}

export function* watchSubmitKitchenItem(){
    yield takeEvery(SUBMIT_KITCHEN_ITEM, submitKitchenItem);
}
function* KitchenItemSaga(){
    yield all([fork
            (watchSubmitKitchenItem)
        ]);
}

export default KitchenItemSaga;