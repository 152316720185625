import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  Button,
  CardTitle,
  Table,
  Badge,
} from "reactstrap"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

import preloader from "../../helpers/Loader"
import Flatpickr from "react-flatpickr"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import cellEditFactory from "react-bootstrap-table2-editor"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import { ref } from "yup"
import exportFromJSON from "export-from-json"
import { formatDate } from "@fullcalendar/core"

const GenerateSalary = props => {
  const { SearchBar } = Search
  const refContainer = useRef(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [salaryData, setSalaryData] = useState([])
  const [salaryTotalStats, setSalaryTotalStats] = useState({ remark: "" })
  // const [totalBasicSalary, setTotalBasicSalary] = useState(0);
  // const [totalAllowance, setTotalAllowance] = useState(0);
  // const [totalDeduction, setTotalDeduction] = useState(0);
  // const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [generateBtnDisabled, setGenerateBtnDisabled] = useState(true)
  const [year, setYear] = useState("")
  const [excel_data, setExcel_data] = useState({})

  const handleDateChange = date => {
    // console.log(date, "date from faltpickr")
    setSelectedDate(date[0])
  }

  useEffect(() => {
    // loadAllEmployees()
    // setTotalBasicSalary(0);
    // setTotalAllowance(0);
    // setTotalDeduction(0);
    // setMonthlyTotal(0)
    // function calcTotal() {
    //     salaryData.forEach((emp) => {
    //         setTotalBasicSalary(prev => prev += emp.basic_salary);
    //         setTotalAllowance(prev => prev += (emp.allowance.food + emp.allowance.housing + emp.allowance.other + (emp.allowance.overtime || 0) + emp.allowance.arrear + emp.allowance.special + emp.allowance.loan_arrear));
    //         setTotalDeduction(prev => prev += (emp.deduction.loan + emp.deduction.undertime + (emp.punishment || 0)));
    //         setMonthlyTotal(prev => prev += (emp.basic_salary + (emp.allowance.food + emp.allowance.housing + emp.allowance.other + (emp.allowance.overtime || 0) + emp.allowance.arrear + emp.allowance.special + emp.allowance.loan_arrear) - (emp.deduction.loan + emp.deduction.undertime + (emp.punishment || 0))))
    //     })
    // }
    // calcTotal()
  }, [salaryData])

  //Export to csv
  const handleDownload = async () => {
    const fileName = "Salary Details"
    const exportType = "xls"

    let data_to_export = []
    data_to_export.push(excel_data)

    // console.log(data_to_export)

    if (data_to_export.length > 0) {
      const data = []
      data_to_export.forEach(v => {
        v.employee_details.forEach(employee => {
          const rowData = {
            // 'Employee Id': employee.employee_id,
            "Employee Code": employee.employee_code,
            "Employee Name": employee.employee_name,
            "Date of Birth": formatDate(employee.date_of_birth),
            "Date of Joining": formatDate(employee.date_of_joining),
            Designation: employee.designation,
            "Number of Days": employee.number_of_days,
            "Basic Salary": employee.basic_salary,
            "Allowance Food": employee.allowance.food,
            "Allowance Housing": employee.allowance.housing,
            "Allowance Other": employee.allowance.other,
            "Allowance Overtime": employee.allowance.overtime,
            "Allowance Arrear": employee.allowance.arrear,
            "Allowance Special": employee.allowance.special,
            "Allowance Loan Arrear": employee.allowance.loan_arrear,
            "Deduction Loan": employee.deduction.loan,
            "Deduction Undertime": employee.deduction.undertime,
            "Deduction Punishment": employee.deduction.punishment,
            "Net Salary": employee.net_salary,
            "Net Salary R.O.": employee.net_salary_round_off,
          }
          data.push(rowData)
        })
      })

      exportFromJSON({ data, fileName, exportType })
    }
  }
  //   console.log(salaryData)

  // function to get data from generate salaries API

  function getGenerateSalary(payload, token) {
    let url = process.env.REACT_APP_BASEURL

    axios
      .post(
        // url + `generatesalaries/generate_salary`,
        url + `generatesalaries/generate_salary`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        refContainer.current.reset()
        setGenerateBtnDisabled(true)
        showNotification(
          res.data.message,
          res.data.status === 0 ? "Error" : "Success"
        )
        localStorage.setItem(
          "salaryGeneratedDate",
          res.data.data.date.slice(0, 10)
        )
      })
      .catch(err => console.log(err))
  }

  // ------------------------------------------------------------------------------------------

  // const [allemployees, setAllEmployees] = useState([])
  // const loadAllEmployees = async () => {
  //     let url = process.env.REACT_APP_BASEURL + "employees/getall"
  //     const response = await postSubmitForm(url, {})
  //     if (response && response.status === 1) {
  //         setAllEmployees(response.data)
  //     } else {
  //         showNotification(response.message, "Error")
  //     }
  // }
  // --------------------------------------------------------------------------------

  // function to get the user Input for salary generation table of all employees
  const handleValidSubmit = async (e, v, token) => {
    // let url = process.env.REACT_APP_BASEURL + "adminusers/inser"
    // let formData = new FormData();
    // formData.set("month", v.month)
    // // formData.set("username", v.username);
    // // formData.set("pwd", v.pwd);
    // // formData.set("role", v.role);
    // console.log(formData,"form data")

    const object = {
      date: v.month + "-01",
    }

    getGenerateSalary(object, token)
    // console.log("Data", object)
    // var currentDate = new Date();
    // currentDate.setMonth(currentDate.getMonth() - 1);
    // var year = currentDate.getFullYear();
    // var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    // var day = currentDate.getDate().toString().padStart(2, '0');

    // object.date = object.date + "-01"

    // var formattedDate = year + '-' + month + '-' + "01";

    // console.log("formattedDAte:", formattedDate)
    // console.log("object date:", object)

    // if (object.date === formattedDate) {
    //     //    console.log("yess")
    //     getGenerateSalary(object, employeeToken)
    // } else {
    //     showNotification(_, "previous & future month's salary can't be generated !")
    // }

    // postSubmitForm(url, object).then(api_response => {
    //   if (api_response.status == 1 && api_response.data) {
    //     refContainer.current.reset()
    //     loadAllEmployees()
    //     loadAllDesignations()
    //     loadAllUsers()
    //     showNotification(api_response.message, "Success")
    //   } else {
    //     showNotification(api_response.message, "Error hello")
    //   }
    // })
  }

  // function to get month/date input from the user

  function handleShowSalaries(e, token) {
    preloader(true)
    let url = process.env.REACT_APP_BASEURL

    // getting previous months date
    var currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - 1)
    var year = currentDate.getFullYear()
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0")
    var day = currentDate.getDate().toString().padStart(2, "0")

    var formattedDate = year + "-" + month + "-" + "01"

    setYear(year)
    // console.log(formattedDate, "current date minus 1")

    // checking if user input matched the previous month date on the basis of which generate button will be enabled or will remain disabled
    if (e.target.value + "-01" === formattedDate) {
      // setgeneratebtndisabled is setting the generate button's enable or disabled state
      // console.log(e.target.value+"-01", localStorage.getItem("salaryGenerateDate"), "user input", "localstorage" )
      // e.target.value + "-01" !== localStorage.getItem("salaryGeneratedDate") ? setGenerateBtnDisabled(false) : setGenerateBtnDisabled(true)

      // creating payload for the api wich takes date selected by user and sourse property for only retrieving the calculated the salary data.
      const payload = {
        date: e.target.value + "-01",
        source: "get_salaries",
      }

      axios
        .post(
          url + `generatesalaries/generate_salary`,
          //`http://localhost:3002/generatesalaries/generate_salary`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(res => {
          preloader(false)
          // console.log(res.data.data);
          setSalaryData(res.data.data.employee_details)
          setSalaryTotalStats({
            ...salaryTotalStats,
            monthlyTotal: res.data.data.monthly_total,
            monthlyTotalRoundOff: res.data.data.monthly_total_round_off,
            totalAllowance: res.data.data.total_allowance,
            totalDeduction: res.data.data.total_deduction,
            totalBasicSalary: res.data.data.total_basic_salary,
            remark: res.data.data.remark,
          })
          setExcel_data(res.data.data)
          setGenerateBtnDisabled(
            res.data.data.is_salary_generated ? true : false
          )
        })
        .catch(err => {
          console.log(err)
          preloader(false)
        })
    } else if (Number(e.target.value.slice(5, 8)) < Number(month)) {
      // console.log("salary already generated ")

      setGenerateBtnDisabled(true)

      const payload = {
        date: e.target.value + "-01",
        source: "get_salaries",
      }

      axios
        .post(
          url + `generatesalaries/generate_salary`,
          //`http://localhost:3002/generatesalaries/generate_salary`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(res => {
          // console.log(res.data.data);
          preloader(false)
          setSalaryData(res.data.data.employee_details)
          setExcel_data(res.data.data)

          setSalaryTotalStats({
            ...salaryTotalStats,
            monthlyTotal: res.data.data.monthly_total,
            monthlyTotalRoundOff: res.data.data.monthly_total_round_off,
            totalAllowance: res.data.data.total_allowance,
            totalDeduction: res.data.data.total_deduction,
            totalBasicSalary: res.data.data.total_basic_salary,
            remark: res.data.data.remark,
          })
        })
        .catch(err => console.log(err))
    } else {
      preloader(false)

      // for future months this notification will be showed
      showNotification(_, "Salary data not present yet.")
    }
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  // ---------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t(" Salary")}
            breadcrumbItem1={props.t("Generate Salary")}
            alternateTitle={props.t("Generate Salary")}
          />
          {/* <Button style={{ marginTop: '-10px', marginBottom: "15px", backgroundColor: "#143758", cursor: "auto" }} >
                        Overtime Approval
                    </Button> */}

          <Row>
            <Col lg={7}>
              <Card style={{ height: "120px" }}>
                <CardBody
                // style={{
                //     boxShadow:
                //       "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                //   }}
                >
                  <Row
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <h6> Total Basic Salary</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.totalBasicSalary || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6>Total Allowance</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.totalAllowance || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6> Total Deduction</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.totalDeduction || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6>Total Monthly Salary</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.monthlyTotal || 0}
                      </Badge>
                    </Col>
                    <Col>
                      <h6>Total Monthly R.O.</h6>
                      <Badge className="fs-5  badge-soft-success m-2">
                        {salaryTotalStats.monthlyTotalRoundOff || 0}
                      </Badge>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={5}>
              <Card style={{ height: "" }}>
                <CardBody
                //  style={{
                //     color: "white",
                //     background:
                //       "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                //     boxShadow:
                //       "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                //   }}
                >
                  {/* <Row>
                                        <Col lg={12}> */}
                  {localStorage.getItem("role") === "Owner" && (
                    <AvForm
                      onValidSubmit={(e, v) =>
                        handleValidSubmit(e, v, employeeToken)
                      }
                      ref={refContainer}
                    >
                      {/* <Row style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }} > */}

                      <Row>
                        <Col lg={4} md={4} sm={4}>
                          <AvField
                            className="form-control"
                            label="Select Month"
                            type="month"
                            name="month"
                            placeholder="select month"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Month")}
                            onChange={e => handleShowSalaries(e, employeeToken)}
                          />
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          <AvField
                            label={props.t("Select Branch")}
                            className="form-control"
                            type="select"
                            name="branch"
                            errorMessage="Please Select Branch"
                            validate={{
                              required: { value: false },
                            }}
                          >
                            <option value="">{props.t("All")}</option>
                            <option value="Rendezvous">
                              {props.t("Rendezvous")}
                            </option>
                          </AvField>
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                          {/* <Button disabled={generateBtnDisabled} type="submit" style={{ width: "100px", marginTop:"1.65rem" }}  >
                                                {props.t("Generate")}
                                            </Button> */}
                          <Button
                            disabled={generateBtnDisabled}
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              width: "100px",
                              marginTop: "1.588rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Generate")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  )}

                  {localStorage.getItem("role") !== "Owner" && (
                    <AvForm
                      onValidSubmit={(e, v) =>
                        handleValidSubmit(e, v, employeeToken)
                      }
                      ref={refContainer}
                    >
                      {/* <Row style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }} > */}

                      <Row>
                        <Col lg={6}>
                          <AvField
                            className="form-control"
                            label="Select Month"
                            type="month"
                            name="month"
                            placeholder="select month"
                            validate={{ required: { value: true } }}
                            errorMessage={props.t("Select Month")}
                            onChange={e => handleShowSalaries(e, employeeToken)}
                          />
                        </Col>
                        {/* <Col lg={4} md={4} sm={4}>
                                                        <AvField
                                                            label={props.t("Select Branch")}
                                                            className="form-control"
                                                            type="select"
                                                            name="branch"
                                                            errorMessage="Please Select Branch"
                                                            validate={{
                                                                required: { value: false },
                                                            }}
                                                        >
                                                            <option value="">{props.t("All")}</option>
                                                            <option value="Rendezvous">{props.t("Rendezvous")}</option>
                                                        </AvField>

                                                    </Col> */}
                        <Col lg={6}>
                          <Button
                            disabled={generateBtnDisabled}
                            type="submit"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              backgroundColor: "#fdd005",
                              border: "none",
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "500",
                              // width: "200px",
                              marginTop: "1.588rem",
                              boxShadow:
                                "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                              transition: "transform 0.3s ease",
                            }}
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            {props.t("Generate")}
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  )}
                  {/* </Col>

                                    </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* //previous design */}
          {/* <Row>
                        <Col>

                            <Card  >

                                <CardBody style={{ display: "flex", justifyContent: "space-evenly" }} >
                                    <Row style={{ width: "100%", textAlign: "center" }} >


                                        <Col  >
                                            <h4>Total Basic Salary</h4>
                                            <Badge className="fs-5 badge-soft-success" >{salaryTotalStats.totalBasicSalary || 0}</Badge>
                                        </Col>
                                        <Col>
                                            <h4>Total Allowance</h4>
                                            <Badge className="fs-5 badge-soft-success">{salaryTotalStats.totalAllowance || 0}</Badge></Col>
                                        <Col>
                                            <h4>Total Deduction</h4>
                                            <Badge className="fs-5 badge-soft-success">{salaryTotalStats.totalDeduction || 0}</Badge></Col>
                                        <Col >
                                            <h4>Monthly Total</h4>
                                            <Badge className="fs-5 badge-soft-success">{salaryTotalStats.monthlyTotal || 0}</Badge></Col>
                                        <Col >
                                            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v, employeeToken)} ref={refContainer}>
                                                <Row style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }} >


                                                    <Col lg={6}  >

                                                  
                                                        <h4>Select Month</h4>
                                                        <AvField

                                                            className="form-control"
                                                            
                                                            type="month"
                                                            name="month"
                                                            placeholder="select month"
                                                            validate={{ required: { value: true } }}
                                                            errorMessage={props.t("Select Month")}
                                                            onChange={(e) => handleShowSalaries(e, employeeToken)}
                                                        />

                                                    </Col>
                                                    <Col  >

                                                        <Button disabled={generateBtnDisabled} type="submit" style={{ width: "100px" }} className="btn btn-info" >
                                                            {props.t("Generate")}
                                                        </Button>



                                                    </Col>
                                                </Row>


                                            </AvForm>
                                           

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}

          <Row>
            <Col lg={12}>
              {/* <label className="d-flex justify-content-center fs-2">Arear, Special and Punishment Record</label> */}
              <Card>
                <CardBody
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <CardTitle>
                    <Row>
                      <Col>
                        <h2
                          style={{ textAlign: "center" }}
                        >{`${salaryTotalStats.remark} ${year}`}</h2>
                      </Col>

                      {/* <Col lg={2} style={{ border: "1px solid black", display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    type="submit"
                                                    className="btn btn-sm btn-secondary"
                                                    onClick={handleDownload}
                                                >

                                                    <i className="mdi mdi-file-export" /> &nbsp;
                                                    {props.t("Export CSV")}
                                                </Button>

                                            </Col> */}
                    </Row>
                  </CardTitle>
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col
                      lg={2}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        style={{
                          width: "115px",
                          height: "35px",
                          marginTop: "-0.9rem",
                          background: "#F93A5C",
                          border: "none",
                        }}
                        type="submit"
                        className="btn btn-sm btn-secondary"
                        onClick={handleDownload}
                        disabled={salaryData.length === 0}
                      >
                        <i className="mdi mdi-file-export" /> &nbsp;
                        {props.t("Export CSV")}
                      </Button>
                      <hr />
                    </Col>
                  </Row>

                  <div style={{ overflowX: "auto", width: "auto" }}>
                    <Table border="1px">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>#</th>
                          <th>Employee Code</th>
                          <th>Employee Name</th>
                          <th>No. of Days</th>
                          <th style={{ textAlign: "center" }}>
                            Basic Salary{"(QAR)"}
                          </th>
                          <th colSpan="7" style={{ textAlign: "center" }}>
                            Allowance(QAR)
                          </th>
                          {/* <th>...</th>
                                                    <th>...</th>
                                                    <th>...</th>
                                                    <th>...</th>
                                                    <th>...</th> */}
                          <th colSpan="3" style={{ textAlign: "center" }}>
                            Deduction(QAR)
                          </th>

                          <th>Net Salary{"(QAR)"}</th>
                          <th>Net Salary R.O. {"(QAR)"}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr style={{ fontWeight: "bold", textAlign: "center" }}>
                          <td></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                          <td style={{ borderLeft: "1px solid lightGray" }}>
                            Food
                          </td>
                          <td>Housing</td>
                          <td>Other</td>
                          <td>Overtime</td>
                          <td>Arrear</td>
                          <td>Special</td>
                          <td style={{ borderRight: "1px solid lightGray" }}>
                            Loan Arrear
                          </td>
                          <td>Loan</td>
                          <td>Undertime</td>
                          <td>Punishment</td>
                          <td
                            style={{
                              borderLeft: "1px solid lightGray",
                              borderRight: "1px solid lightGray",
                            }}
                          ></td>
                        </tr>
                        {salaryData.map((emp, index) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {emp.employee_code}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {emp.employee_name}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {" "}
                              {emp.number_of_days}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                              }}
                            >
                              {emp.basic_salary}
                            </td>
                            <td>{emp.allowance.food}</td>
                            <td>{emp.allowance.housing}</td>
                            <td>{emp.allowance.other}</td>
                            <td>{emp.allowance.overtime || 0}</td>
                            <td>{emp.allowance.arrear}</td>
                            <td>{emp.allowance.special}</td>
                            <td style={{ borderRight: "1px solid lightGray" }}>
                              {emp.allowance.loan_arrear}
                            </td>
                            <td>{emp.deduction.loan}</td>
                            <td>{emp.deduction.undertime}</td>
                            <td>{emp.deduction.punishment || 0}</td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                fontWeight: "bold",
                              }}
                            >
                              {emp.net_salary}
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid lightGray",
                                fontWeight: "bold",
                              }}
                            >
                              {emp.net_salary_round_off}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(GenerateSalary)))
