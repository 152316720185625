import React, { useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Badge
} from "reactstrap"
import Loader from "../../helpers/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Redux
import { connect } from "react-redux"
import withRouter from "../../components/Common/withRouter"
//i18next

import { withTranslation } from "react-i18next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { AvField, AvForm } from "availity-reactstrap-validation"
import showNotification from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import Select from "react-select"
import exportFromJSON from "export-from-json"
import { formatDate } from "@fullcalendar/core"
import moment from "moment"


const Completed_Loan = (props) => {
  const { SearchBar } = Search
  const [allCompletedLoan, setAllCompletedLoan] = useState([])
useEffect(()=>{
    loadAllCompletedLoan()
},[])
  //Download Excel
  const handleDownload = async (e, v) => {
    const fileName = "All Loan";
    const exportType = "xls";

    let data_to_export = allCompletedLoan;

    if (data_to_export.length > 0) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v) {
        data.push({
          Name: v.employee_name,
          Code: v.employee_code,
          Loan_Status: v.is_loan_active ? "Active" : "Completed",
          Loan_Amount: v.loan_amount,
          EMI: v.emi_amount,
          Tenure: v.tenure,
          Apply_Date: formatDate(v.loan_apply_date),
          Approve_Date: formatDate(v.loan_approval_date),
          Remaining_Loan: v.remaining_loan,
          Remaining_Tenure: v.remaining_tenure
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
  }
 
  
  const loadAllCompletedLoan = async () => {
    Loader(true)
    let url = process.env.REACT_APP_BASEURL + "loans/completed"
    // let url = "http://localhost:3002/loans/completed"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      Loader(false)
      setAllCompletedLoan(response.data)
      // console.log(response.data, "loan data")
    } else {
      Loader(false)
      showNotification(response.message, "Error")
    }
  }
  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    printWindow.document.write(`
   
       <html>
       <title>${divName}</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
        
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
        </style>
       </head>
    <body>
  ${content.innerHTML}
    </body>
       </html>

    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.focus();
  };
  const completedLoansColumns = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = rowIndex + 1
        return rowNumber
      },
      text: props.t("S.N"),
      headerStyle: (colum, colIndex) => {
        return { width: "3%" }
      },
    },
    {
      text: props.t("Employee Code"),
      //formatter: employeeFormatter,
      dataField: "employee_code",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "employee_name",
      text: props.t("Name"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "loan_amount",
      text: props.t("Loan Amount"),
      formatter: (cell, row) => cell || '0',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "emi_amount",
      text: props.t("EMI"),
      formatter: (cell, row) => cell || '0',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" }
      },
    },
    {
      dataField: "tenure",
      text: props.t("Tenure"),
      sort: true,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "remaining_loan",
      text: props.t("Remaining Loan   "),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "remaining_tenure",
      text: props.t("Remaining Tenure   "),
      sort: false,
      formatter: (cell, row) => cell || '0',
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
    {
      dataField: "",
      text: props.t("Status"),
      formatter: (cell, row) => {
        return (
          <Badge className="fs-6 badge-soft-success" >Completed</Badge>
        )
      },

      headerStyle: (colum, colIndex) => {
        return { width: "4%" }
      },
    },

  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Salary")}
            breadcrumbItem1={props.t("Completed Loans")}
            alternateTitle="Completed Loans"
          />
          
          <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                        }}
                        >
                         
                          <div id="printDiv">
                          <ToolkitProvider 
                            keyField="_id"
                            columns={completedLoansColumns}
                            data={allCompletedLoan}
                            search
                            isAddUserList={true}
                            customPageSize={10}
                          >
                            {propst => (
                              <div>
                                <AvForm>
                                  <Row >
                                    
                                   <Col lg={4} style={{display:"flex", justifyContent:"flex-start"}}>
                                      <SearchBar
                                        {...propst.searchProps}
                                        placeholder="Search"
                                      />
                                    </Col> 
                                    <Col lg={8} style={{display:"flex", justifyContent:"flex-end", marginBottom:"10px"}}>
                                        
                                       <i
                                        className="fa fa-print fs-5 mt-2 me-2 " aria-hidden="true"
                                        title="Click to Print"
                                        style={{ cursor: "pointer", color: "black" }}
                                        onClick={() => {  printDiv("printDiv");                            
                                        }}
                                        ></i>
                                            <Button
                                            style={{ width: "115px", height:"35px", background:"#F93A5C", border:"none" }}
                                            type="submit"
                                            className="btn btn-sm btn-secondary"
                                            name="completed"
                                            onClick={handleDownload}
                                            >
                                            <i className="mdi mdi-file-export" /> &nbsp;
                                            {props.t("Export CSV")}
                                            </Button>
                                    </Col>
                                
                                  </Row>
                                </AvForm>
                                <BootstrapTable
                                  {...propst.baseProps}
                                  noDataIndication={props.t("No data to display.")}
                                  striped
                                  hover
                                  condensed
                                />

                              </div>
                            )}
                          </ToolkitProvider>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>         
         
                
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(connect(null, {})(withTranslation()(Completed_Loan)))
