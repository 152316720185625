import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Input,
  CardTitle,
  CardFooter,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap"

import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import withRouter from "../../components/Common/withRouter"
import showNotification, { showWarning } from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import "./allBooking.css"
import { orderBy } from "lodash"
import DsbdBookModal from "./DsbdBookModal"
import moment from "moment"
import TransferTable from "../../components/TransferTable"
import ReservedTable from "../../components/ReservedTable"

const TableStatus = props => {


  const [searchTerm, setSearchTerm] = useState();
  const [timers, setTimers] = useState({});
  const [spin, setSpin] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false)

  //All Games
  const [AllGame, setAllGame] = useState([])
  const [filteredGames, setFilteredGames] = useState(AllGame);
  const [selectedGame, setSelectedGame] = useState("");
  const loadGames = async () => {
    let url = process.env.REACT_APP_BASEURL + "games/getall"
    let response = await postSubmitForm(url, "")
    if (response.status === 1) {
      const res = response.data
      const sorted = res.sort((a, b) => a.name.localeCompare(b.name));
      setAllGame(sorted)
      const defaultTable = response.data.find(game => game.name === "Billiard");
      setSeletedTable(defaultTable?._id)
      setSelectedGame(defaultTable?.name)
    } else {
      console.log("Error")
    }
  }

  //Game Tables

  const [allGameTable, setAllGameTable] = useState([])
  const [filteredTables, setFilteredTables] = useState([])
  const [filterStatus, setFilterStatus] = useState("")
  const [seletedTable, setSeletedTable] = useState("")
  const [tableNumber, setTableNumber] = useState(false)
  const loadGameTables = async () => {
    let url = process.env.REACT_APP_BASEURL + "bookings/get_gametable_booking_details";
    let response = await postSubmitForm(url, {
      game_id: seletedTable
    });
    if (response.status === 1) {
      setSpin(false)
      const filter = response.data.filter(table => table.is_active === true);
      //  const sortedTables = orderBy(filter, [(table) => table.is_occupied, 'is_occupied'], ['asc', 'desc']);
      const sortedTables = orderBy(filter, [(table) => table.is_occupied, (table) => new Date(table.details[0]?.table_end_time)], ['asc', 'asc']);
      setAllGameTable(sortedTables)
      setFilteredTables(sortedTables)
      setFilterStatus("")
      setTableNumber(false)
      // setAllGameTable(filter)
    } else {
      setTableNumber(false)
      showNotification(response.message, "Error");
    }
  }

  const [bookingData, setBookingData] = useState([])
  const [tableDetails, setTableDetails] = useState()
  const loadBookingById = async (booking_id, tableNumber) => {
    let url = process.env.REACT_APP_BASEURL + "bookings/get_booking_by_id";
    let response = await postSubmitForm(url, {
      id: booking_id
    });
    if (response.status === 1) {
      let res = response.data?.game_details?.find((table) => table.table_number === tableNumber)
      setTableDetails(res)
      setDuration(res.game.duration)
      setBookingData([response.data])
    } else {
      showNotification(response.message, "Error");
    }
  }



  useEffect(() => {
    handleFilterChange()
  }, [filterStatus])

  function handleFilterChange() {
    const now = new Date();
    const filteredGameTables = allGameTable.filter(table => {
      if (filterStatus === "Available") {
        return !table.is_occupied;
      } else if (filterStatus === "In Process") {
        const startTime = new Date(table.details[0]?.table_start_time);
        const endTime = new Date(table.details[0]?.table_end_time);
        return startTime <= now && endTime >= now;
      } else if (filterStatus === "Reserved") {
        const startTime = new Date(table.details[0]?.table_start_time);
        let endTime;
        if (table.is_reserved_for_open && table.is_occupied) {
          endTime = new Date(startTime.getTime() + now.getTime());
        } else {
          endTime = new Date(table.details[0]?.table_end_time);
        }
        return table.is_occupied && table.is_reserved_for_open && startTime <= now && now < endTime;
      } else {
        return true;
      }
    });
    setFilteredTables(filteredGameTables);
  }

  const colors = useMemo(() => ({
    "completed": "#b0ff9e",
    "in process": "#fffd9e",
    "cancelled": "#ff9e9e",
    "null": "#ffff"
  }), []);
  // const colorNotation = useMemo(() => [
  //   {
  //     status: "In Process",
  //     color: "#fffd9e"
  //   },
  //   {
  //     status: "Available",
  //     color: "ffff"
  //   },
  //   {
  //     status: "Reserved",
  //     color: "#dabcff"
  //   }
  // ], []);


  const colorNotation = useMemo(() => {
    if (selectedGame === "Billiard" || selectedGame === "Card") {
      return [
        {
          status: "In Process",
          color: "#fffd9e"
        },
        {
          status: "Available",
          color: "ffff"
        },
        {
          status: "Reserved",
          color: "#dabcff"
        }
      ];
    } else {
      return [
        {
          status: "In Process",
          color: "#fffd9e"
        },
        {
          status: "Available",
          color: "#ffff"
        },
        {
          status: "Reserved",
          color: "#dabcff"
        }
      ];
    }
  }, [selectedGame]);

  // Rest of the component remains the same

  useEffect(() => {
    setFilteredGames(AllGame);
  }, [AllGame]);

  useEffect(() => {
    loadGames()
    loadGameTables()
  }, [])

  useEffect(() => {
    loadGameTables()
  }, [seletedTable])

  useEffect(() => {
    const filtered = AllGame.filter(item => {

      return AllGame !== "" ? item.name?.toLowerCase().includes(searchTerm?.toLowerCase()) : item
    }
    );
    setFilteredGames(filtered);
  }, [searchTerm]);


  const [openBookingModal, setOpenBookingModal] = useState(false)
  const [tableData, setTableData] = useState("")
  const OpneModal = useCallback(async (game) => {
    let currTime = new Date();
    let payload = {
      start_time: currTime,
      end_time: new Date(currTime.getTime() + 30 * 60000),
      game_id: game.game.game_id,
    };
    let url = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date";
    let res = await postSubmitForm(url, payload);
    if (res && res.status === 1 && res.data.some(item => item.table_number === game.table_number)) {
      setOpenBookingModal(true);
    } else {
      showWarning("Table will be busy from " + " " +
        moment(payload?.start_time).format("HH:mm") + " " +
        "to" + " " +
        moment(payload?.end_time).format("HH:mm"), "warning");
      setTableData("");
    }
  }, []);


  useEffect(() => {
    return () => {
      Object.values(timers).forEach(timer => clearInterval(timer));
    };
  }, [timers]);


  const [remainingTimes, setRemainingTimes] = useState(
    calculateRemainingTimes(filteredTables)
  );

  function calculateRemainingTimes(allGameTable) {
    return allGameTable?.map((table) => {
      if (table.details.length > 0) {
        const now = new Date();
        const startTime = new Date(table.details[0].table_start_time);
        if (table.is_occupied && table.is_reserved_for_open) {
          const timeDifference = now - startTime;
          const seconds = Math.floor((timeDifference / 1000) % 60);
          const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
          const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
          if (timeDifference > 0) {
            if (hours > 0) {
              return ` ${hours}h ${minutes}m ${seconds}s `;
            } else {
              return `${minutes}m ${seconds}s `;
            }
          } else {
            return "Booking ended";
          }
        }
        const endTime = new Date(table.details[0].table_end_time);
        if (now >= startTime && now <= endTime) {
          const timeDifference = endTime - now;
          const seconds = Math.floor((timeDifference / 1000) % 60);
          const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
          const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);

          if (timeDifference > 0) {
            if (hours > 0) {
              return ` ${hours}h ${minutes}m ${seconds}s remaining`;
            } else {
              return `${minutes}m ${seconds}s remaining`;
            }
          } else {
            return "Booking ended";
          }
          // } else if (now < startTime) {
          //   const timeDifference = startTime - now;
          //   const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
          //   const seconds = Math.floor((timeDifference / 1000) % 60);
          //   return `${minutes}m ${seconds}s until start`;
        } else {
          return "";
        }
      }
      return "";
    });
  }

  const handleValidUpdateResreved = async table => {
    let url = process.env.REACT_APP_BASEURL + "game_tables/reserve_game_table"
    let response = await postSubmitForm(url, {
      _id: table._id,
      is_reserved_for_open: table.is_reserved_for_open === true ? false : true
    })
    if (response.status === 1) {
      loadGameTables()
    } else {
      showNotification(response.message, "Error")
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTimes(calculateRemainingTimes(filteredTables));
    }, 1000);

    return () => clearInterval(interval);
  }, [filteredTables]);

  function handleBackgroundColor(data) {
    let color = "";
    switch (true) {
      case !data.is_reserved_for_open && data.is_occupied && new Date(data.details[0]?.table_start_time) <= new Date() && new Date(data.details[0]?.table_end_time) >= new Date():
        color = "#fffd9e";
        break;
      case data.details.length > 0 && data.is_reserved_for_open && data.is_occupied:
        color = "#fffd9e";
        break;
      case data.is_reserved_for_open:
        color = "#dabcff";
        break;
      default:
        color = "white";
        break;
    }
    return color;
  }
  const [extendDurationModal, setExtendDurationModal] = useState(false)
  const [reservedBookingModal, setReservedBookingModal] = useState(false)
  function handleModelOpen(table) {
    if (!table.is_occupied && !table.is_reserved_for_open) {
      OpneModal(table); setTableData(table)
    } else if (table.is_reserved_for_open && !table.is_occupied && table.details.length <= 0) {
      setReservedBookingModal(true); setTableData(table)
    }else if (table.is_reserved_for_open && !table.is_occupied && table.details.length > 0) {
      showWarning(`${table.table_number} is free but billing is not done yet!`)
    }
  }

  const handleCloseTable = async (table) => {
    const { details } = table
    setTableNumber(table?.table_number)
    let payload = {
      booking_id: details[0].booking_id,
      game_table_id: table._id,
      start_time: details[0].table_start_time
    }
    let url = process.env.REACT_APP_BASEURL + "bookings/end_reserved_booking"
    let response1 = await postSubmitForm(url, payload)
    if (response1.status === 1) {
      let res1 = response1.data
      let price = 0;
      let gameData = res1.gameData?.find((data)=>data._id===table._id)
      switch (res1.customer_details?.customer_type) {
        case "Friend":
          price = gameData.game.price_for_friend;
          break;
        case "Regular":
          price =  gameData.game.price_for_regular;
          break;
        case "VIP":
          price =  gameData.game.price_for_vip;
          break;
        default:
          break;
      }
      const subTotal = (price / 60) * res1.duration;
      const game_total = res1.gameData?.reduce((acc,curr)=>acc+curr.game.sub_total,0)
      const total_price = res1?.item_total_price ? (res1?.item_total_price  + (game_total+subTotal) ): (game_total+subTotal)
      let url = process.env.REACT_APP_BASEURL + "bookings/update_prices"
      let response2 = await postSubmitForm(url, {
        booking_id: details[0].booking_id,
        game_table_id: table._id,
        sub_total: Number(subTotal.toFixed(2)),
        game_total_price: Math.round((game_total+subTotal)),
        total_price: Math.round(total_price),
      })
      if (response2.status === 1) {
        showNotification(response2.message, "Success")
        setTimeout(() => {
          loadGameTables()
        }, 1000);
      } else {
        showNotification(response2.message, "Error")
      }
    } else {
      showNotification(response1.message, "Error")
    }
  }

  function handleExtendDuration(table) {
    // console.log(table);
    let tableNumber = table?.table_number
    let booking_id = table?.details[0].booking_id
    setExtendDurationModal(true)
    loadBookingById(booking_id, tableNumber)
  }

  const [duration, setDuration] = useState(0)
  const [ab, setAB] = useState({})
  const handleDurationChange = async () => {
    console.log(1);
    const value = duration;
    let price = 0;

    if (bookingData.length > 0 && bookingData[0].customer_details) {
      const customerType = bookingData[0].customer_details.customer_type;
      if (customerType === "Friend") {
        price = tableDetails.game.price_for_friend;
      } else if (customerType === "Regular") {
        price = tableDetails.game.price_for_regular;
      } else if (customerType === "VIP") {
        price = 0;
      }
    }

    if (+value === +tableDetails.game.duration) {
      return;
    } else if (+value >= tableDetails.game.duration) {
      console.log(2);
      const requestDura = value - tableDetails.game.duration;
      const endTime = new Date(tableDetails.game.table_end_time);
      const updatedET = new Date(endTime.getTime() + requestDura * 60000);

      const payload = {
        start_time: tableDetails.game.table_end_time,
        end_time: updatedET,
        game_id: tableDetails.game.game_id,
      };

      const PieUrl = process.env.REACT_APP_BASEURL + "bookings/table_bookings_by_date";
      const res = await postSubmitForm(PieUrl, payload);

      if (res && res.status === 1 && res.data.some(item => item.table_number === tableDetails.table_number)) {
        console.log(3);
        if (ab[tableDetails._id] === undefined) {
          setAB(prev => ({ ...prev, [tableDetails._id]: price }));
        }
        const a = [...bookingData[0].game_details];
        price = ab[tableDetails._id] ? ab[tableDetails._id] : price;
        if (+value >= 30) {
          console.log(4);
          const startTime = new Date(tableDetails.game.table_start_time);
          const updatedET = new Date(startTime.getTime() + value * 60000);
          const formattedEndTime = updatedET
          a.forEach(item => {
            if (item._id === tableDetails._id) {
              if (tableDetails.game.name.includes("VIP")) {
                if (+value > 60) {
                  const sixtyMin = 60;
                  const rem = +value - sixtyMin;
                  item.game.sub_total = ((150 / 60) * sixtyMin) + ((100 / 60) * rem);
                  item.game.sub_total = +(item.game.sub_total.toFixed(2));
                  item.game.duration = +value;
                  item.game.table_end_time = formattedEndTime;

                } else {
                  item.game.sub_total = (150 / 60) * +value;
                  item.game.sub_total = +(item.game.sub_total.toFixed(2));
                  item.game.duration = +value;
                  item.game.table_end_time = formattedEndTime;

                }
              } else {
                item.game.sub_total = (price / 60) * +value;
                item.game.sub_total = +(item.game.sub_total.toFixed(2));
                item.game.duration = +value;
                item.game.table_end_time = formattedEndTime;
              }
            }
          });
        }
        console.log(5);
        setBookingData(prev => {
          const newBookingData = [...prev];
          newBookingData[0].game_details = a;
          return newBookingData;
        });
        handleUpdateBooking()
      } else {
        showWarning("Table is busy", "warning");
      }
    } else if (+value <= tableDetails.game.duration) {
      showWarning("Duration cannot be reduced", "Warning");
    }
  };

  const handleUpdateBooking = async () => {
    let ForPayload = bookingData;
    if (duration === 0) {
      showWarning("Can't Update, No changes made", "Warning")
      return;
    }
    let prevPkgGamesDetails = ForPayload[0]?.package_details[0]?.game_details?.length === 0 ? [] : ForPayload[0]?.package_details[0]?.game_details?.filter(item => (item?.requested_duration > 0));
    let totalPrice = 0;
    let itemTotalPrice = 0;
    let gameTotalPrice = 0;
    let totalTime = 0;
    let bookedGameDetails = ForPayload[0].game_details
    if (bookedGameDetails?.length > 0) {
      totalPrice = Number((bookedGameDetails?.reduce((acc, obj) => acc + obj?.game?.sub_total, 0)).toFixed(2))
      gameTotalPrice = Number(totalPrice.toFixed(2));
    }
    if (ForPayload[0]?.item_details?.length > 0) {
      totalPrice += ForPayload[0].item_details?.reduce((acc, obj) => acc + obj?.sub_total, 0);
      itemTotalPrice = Number((totalPrice - gameTotalPrice).toFixed(2));
    }
    if (ForPayload[0]?.game_details?.length > 0) {
      totalTime = ForPayload[0]?.game_details?.reduce((acc, obj) => acc + obj?.game?.duration, 0);
    }
    if (prevPkgGamesDetails?.length > 0) {
      totalTime += prevPkgGamesDetails?.reduce((acc, obj) => acc + obj?.requested_duration, 0)
    }

    let payload = {
      item_details: ForPayload[0].item_details,
      customer_details: ForPayload[0].customer_details,
      booking_id: ForPayload[0]._id,
      game_details: bookedGameDetails,
      item_total_price: +itemTotalPrice,
      game_total_price: +gameTotalPrice,
      package_details: ForPayload[0].package_details,
      start_time: ForPayload[0].start_time,
      booking_status: ForPayload[0].booking_status,
      payment_status: "pending",
      total_price: +totalPrice,
      total_time: +totalTime
    }
    let maxTableEndTime = bookedGameDetails.reduce((maxEndTime, game) => {
      const tableEndTime = new Date(game.game.table_end_time).getTime();
      return tableEndTime > maxEndTime ? tableEndTime : maxEndTime;
    }, 0);
    payload.end_time = new Date(maxTableEndTime).toISOString();
    let url = process.env.REACT_APP_BASEURL + "bookings/update";
    let response = await postSubmitForm(url, payload)
    if (response && response.status === 1) {
      setExtendDurationModal(false)
      setDuration(0);
      setTableDetails([])
      loadGameTables()
      showNotification(response.message, "Success")
    } else {
      showWarning(response.message, "Warning")
    }
  }



  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <Row>
        <Col lg={3}>
          <Card style={{ height: "640px", overflowX: "auto" }} className="scroll-container-ts">
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Col>
              </Row>

              <Row>
                {AllGame?.length <= 0 ? (
                  <div className="d-flex justify-content-center" style={{ marginTop: "150px" }}>
                    <Spinner style={{ height: '3rem', width: '3rem' }} />
                  </div>
                ) : (
                  filteredGames.map((item, index) => (
                    <Col
                      lg={6}
                      sm={3}
                      md={3}
                      key={index + 1}
                      className="game-container"
                    >
                      <div
                        className={`${seletedTable && seletedTable === item?._id ? `selected-game` : `game-image-ds`}`}
                        onClick={() => { setSeletedTable(item._id); setSelectedGame(item.name) }}
                      >
                        <img
                          src={item.image}
                          className="game-image-content"
                          onClick={() => { setSeletedTable(item._id); setSelectedGame(item.name) }}
                        />
                      </div>
                      <CardTitle style={{ maxWidth: "100px" }} className={`${seletedTable && seletedTable === item?._id ? `selected-name text-truncate` : `game-name text-truncate`}`}>{item.name}</CardTitle>
                    </Col>
                  ))
                )
                }

              </Row>
            </CardBody>
          </Card>
        </Col>


        <Col lg={9} >
          <Card style={{ height: "640px", overflowX: "auto" }} className="scroll-container-ts">

            <CardBody >
              <Row>

                {/* Filters Start */}

                <Col lg={6} md={6}>
                  <Row>
                    {colorNotation.map((nota, ind) => {
                      return (
                        <>
                          <Col lg={4} md={4} style={{ display: "flex", cursor: "pointer" }} key={ind}
                          >
                            <div style={{ height: "18px", width: "18px", border: "1px solid black", backgroundColor: `${nota.color}` }} key={ind}
                            ></div> &nbsp; &nbsp;
                            <Button className="btn btn-sm"
                              style={{
                                width: "100%",
                                display: "block",
                                margin: "auto",
                                marginTop: "-0.2rem",
                                borderRadius: "16px",
                                fontSize: "11px",
                                color: 'black' ,
                                // backgroundColor: "#ff4539",
                                backgroundColor: `${nota.color}`,
                                // border: "1px solid black",
                                transition: "transform 0.3s ease",
                                // cursor: "pointer",
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = "scale(1.05)"
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = "scale(1)"
                              }}
                              onClick={() => setFilterStatus(nota.status)}>
                              {nota.status}
                            </Button>
                          </Col>
                        </>
                      )
                    })}
                  </Row>
                </Col>

                {/* Filters End  */}

                {/* Refresh Button Start*/}
                <Col lg={6} md={6} >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <i className={`${spin === true ? `fa fa-refresh fa-spin fs-5` : `fa fa-refresh fs-5`}`}
                      title="Refresh"
                      style={{ cursor: "pointer" }}
                      onClick={() => { setSpin(true); loadGameTables() }}
                    />
                  </div>
                </Col>
                {/* Refresh Button End */}

              </Row>


              <Row style={{ marginTop: "0.5rem" }}>
                {filteredTables.length > 0 ? filteredTables.map((table, index) =>
                  <Col lg={4} sm={6} key={index + 1} style={{ marginBottom: "30px", position: "relative" }} >
                    <CardFooter style={{
                      cursor: "pointer",
                      height: "200px", textAlign: "center", borderRadius: "10px",
                      // backgroundColor: `${new Date(table.details[0]?.table_start_time) <= new Date() && new Date(table.details[0]?.table_end_time) >= new Date() ? `#fffd9e` : `white`} ` || colors.null,
                      backgroundColor: `${handleBackgroundColor(table)}`,
                    }}
                      className="box"
                      onClick={() => handleModelOpen(table)}
                    >
                      {table.is_occupied ? (
                        <>
                          <img src={table?.table_image_url} style={{ width: "150px" }} />
                        </>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center justify-content-lg-center">
                            <img src={table?.table_image_url} style={{ width: "150px" }} />
                            {/* <div className="checkbox-wrapper-25 ms-2">
                              <input type="checkbox"
                                id={"customSwitchsize1" + table._id}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleValidUpdateResreved(table);
                                }}
                                checked={table.is_reserved_for_open} />
                            </div> */}
                          </div>
                        </>
                      )}
                      <CardTitle style={{ textAlign: "center", marginBottom: "0px" }}>
                        {table?.table_number}
                      </CardTitle>

                      {table.details.length > 0 && table.is_reserved_for_open && table.is_occupied ? (
                        <div style={{ textAlign: "center", fontSize: "12px", color: "red", fontWeight: "bold", marginTop: "10px" }}>

                          <span style={{ color: "black" }}> Total Time</span> &nbsp; : &nbsp;
                          {remainingTimes[index]}
                          <Button disabled= {tableNumber==table?.table_number} style={{ marginTop: "-8px" }} className="btn btn-sm ms-2  btn-rounded btn-danger"
                            onClick={() => handleCloseTable(table)}
                          >
                            {tableNumber==table?.table_number?"Wait..":"Close"}
                          </Button>
                        </div>
                      ) : (
                        <>
                          {new Date(table.details[0]?.table_start_time) <= new Date() && new Date(table.details[0]?.table_end_time) >= new Date() ?
                            (
                              <>
                                <Row>
                                  <Col lg={12} md={12} style={{ textAlign: "center", fontSize: "12px", color: "red", fontWeight: "bold" }}>
                                    <i className="fa fa-clock-o text-dark" /> &nbsp; : &nbsp;
                                    {remainingTimes[index]} <br />
                                  </Col>
                                </Row>
                                {/* <div style={{ textAlign: "center", fontSize: "12px", color: "red", fontWeight: "bold" }}>
                              <i className="fa fa-clock-o text-dark" /> &nbsp; : &nbsp;
                              {remainingTimes[index]} <br />
                            </div> */}
                                <Row>
                                  <Col lg={6} md={6} sm={6} xs={6}>
                                    <Button
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        height: "27px",
                                        backgroundColor: "#fdd005",
                                        border: "none",
                                        marginTop: "0.2rem",
                                        color: "black",
                                        fontSize: "11px",
                                        fontWeight: "600",
                                        marginBottom: "0.5rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                      onClick={() => {
                                        if (table.is_occupied && !table.is_reserved_for_open) {
                                          setOpenTransferModal(true); setTableData(table)
                                        }
                                      }}
                                    >
                                      Transfer
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}

                                      ></div>

                                    </Button>
                                  </Col>
                                  <Col lg={6} md={6} sm={6} xs={6}>
                                    <Button
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundColor: "#0D6EFD",
                                        border: "none",
                                        height: "27px",
                                        marginTop: "0.2rem",
                                        color: "white",
                                        fontSize: "11px",
                                        fontWeight: "500",
                                        marginBottom: "0.5rem",
                                        boxShadow:
                                          "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                                        transition: "transform 0.3s ease",
                                      }}
                                      onMouseEnter={e => {
                                        e.target.style.transform = "scale(1.05)"
                                      }}
                                      onMouseLeave={e => {
                                        e.target.style.transform = "scale(1)"
                                      }}
                                      onClick={() => {
                                        if (table.is_occupied && !table.is_reserved_for_open) {
                                          handleExtendDuration(table)
                                        }
                                      }}
                                    >
                                      Duration
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          left: "0",
                                          right: "0",
                                          bottom: "0",
                                          background:
                                            "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                          pointerEvents: "none",
                                        }}

                                      ></div>

                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )

                            :
                            null
                          }
                        </>
                      )}

                    </CardFooter>
                  </Col>
                ) :
                  <Col lg={12} style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
                    <h5 >
                      {props.t("No Tables found")}
                    </h5>
                  </Col>
                }
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>


      {/* Instant Booking Modal  */}
      <Modal
        size="xl"
        isOpen={openBookingModal}
        toggle={() => setOpenBookingModal(prev => (prev = !prev))}
      >
        <ModalHeader toggle={() => setOpenBookingModal(prev => (prev = !prev))}>
          {props.t("Instant Booking")}
        </ModalHeader>

        <ModalBody>
          <DsbdBookModal
            gameData={tableData}
            onClose={() => { setOpenBookingModal(prev => (prev = !prev)) }}
            reFresh={() => { loadGameTables() }}
          />
        </ModalBody>
      </Modal>

      {/* Transfer Table Modal*/}
      <Modal
        size="md"
        isOpen={openTransferModal}
        toggle={() => setOpenTransferModal(prev => (prev = !prev))}
      >
        <ModalHeader toggle={() => setOpenTransferModal(prev => (prev = !prev))}>
          {props.t("Transfer Table")}
        </ModalHeader>

        <ModalBody>
          <TransferTable
            gameData={tableData}
            onClose={() => { setOpenTransferModal(false) }}
            reFresh={() => { loadGameTables() }}
          />
        </ModalBody>
      </Modal>

      {/* Reserved Table Modal*/}
      <Modal
        size="md"
        isOpen={reservedBookingModal}
        toggle={() => setReservedBookingModal(prev => (prev = !prev))}
      >
        <ModalHeader toggle={() => setReservedBookingModal(prev => (prev = !prev))}>
          {props.t("Reserve Table")}
        </ModalHeader>

        <ModalBody>
          <ReservedTable
            gameData={tableData}
            onClose={() => { setReservedBookingModal(false) }}
            reFresh={() => { setTimeout(() => { loadGameTables() }, 1000) }}
          />
        </ModalBody>
      </Modal>

      {/* Extend  Duration Modal*/}
      <Modal
        size="md"
        isOpen={extendDurationModal}
        toggle={() => setExtendDurationModal(prev => (prev = !prev))}
      >
        <ModalHeader toggle={() => setExtendDurationModal(prev => (prev = !prev))}>
          {props.t("Extend  Duration")}
        </ModalHeader>

        <ModalBody>
          <Row className='d-flex justify-content-between ms-2'>
            <Col lg={6} md={6}>
              <Label>Customer :</Label>
            </Col>
            <Col lg={6} md={6}>
              <Label>{bookingData[0]?.customer_details?.name}</Label>
            </Col>
            <Col lg={6} md={6}>
              <Label>Table :</Label>
            </Col>
            <Col lg={6} md={6}>
              <Label>{tableDetails?.table_number}</Label>
            </Col>
            <Col lg={6} md={6}>
              <Label>Duration :</Label>
            </Col>
            <Col lg={6} md={6}>
              <Label>{tableDetails?.game?.duration} minutes</Label>
            </Col>
            <Col lg={8} md={8} className="d-flex justify-content-start align-items-center">
              <Label className="me-2"> Extend Duration :</Label>
              <Input
                type="number"
                onChange={(e) => setDuration(+e.target.value)}
                value={duration}
                style={{ width: "80px" }}
                className="me-2"
              />
              <span>min</span>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button color="secondary" onClick={() => { setExtendDurationModal(false); setDuration(0); setTableDetails([]) }} outline>Cancel</Button>
          <Button color="dark" onClick={() => { handleDurationChange() }}>Save</Button>
        </ModalFooter>
      </Modal>

      {/* </div> */}
    </React.Fragment>
  )
}

export default withRouter(connect(null, {})(withTranslation()(TableStatus)))
