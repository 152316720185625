import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardTitle,
  Badge
} from "reactstrap";
import classnames from "classnames";
import withRouter from "../../components/Common/withRouter"
//i18n
import exportFromJSON from "export-from-json";
//Flatpickr
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BackgroundImage from "../../assets/images/Background.png"
import infograph1 from "../../assets/images/A1.png";
import infograph2 from "../../assets/images/A2.png";
import infograph3 from "../../assets/images/A3.png";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Redux
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
//DataTable
import BootstrapTable from "react-bootstrap-table-next";
import "../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { postSubmitForm } from "../../helpers/backend_helper";
import moment from "moment";
import logo from "../../assets/images/Logo.png"
import Linechart from "../Chart/linechart";
import showNotification from "../../helpers/show_notificaton";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import preloader from "../../helpers/Loader";

const AccountsReport = (props) => {
  const { SearchBar } = Search;
  const [fromdate, setFromdate] = useState(new Date())
  const [todate, setTodate] = useState(new Date())
  const code = localStorage.getItem("code")

  useEffect(() => {
    loadAccountsReport();
  }, []);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} - {to} of {size} records.
    </span>
  )


  const [totalIncome, setTotalIncome] = useState();
  const [incomeTrend, setIncomeTrend] = useState([]);
  const [expenseTrend, setExpenseTrend] = useState([]);
  const [profitTrend, setProfitTrend] = useState([]);
  const [poTrend, setPoTrend] = useState([]);
  const [salariesTrend, setSalariesTrend] = useState([]);

  const [expensesTable, setExpensesTable] = useState([]);
  const [poTable, setPoTable] = useState([]);
  const [salariesTable, setSalariesTable] = useState([]);
  const [incomeTable, setIncomeTable] = useState([]);

  const [totalExpense, setTotalExpense] = useState();
  const [expense, setExpense] = useState();
  const [totalPurchaseOrder, setTotalPurchaseOrder] = useState();
  const [totalSalary, setTotalSalary] = useState();

  const [totalProfit, setTotalProfit] = useState();

  const loadAccountsReport = async () => {
    let url = process.env.REACT_APP_BASEURL + "reports/account_analysis";
    let dateFrom = moment(fromdate);
    let dateTo = moment(todate);
    let totalDays = dateTo.diff(dateFrom, 'days') + 1;

    if(totalDays>31){
      showNotification("Total number of days cannot be more than 31.", "warning");
      return ;
    }
    // Function to get data for a specific interval
    const getDataForInterval = async (from, to) => {
      let payload = {
        date_from: from.format("YYYY-MM-DD"),
        date_to: to.format("YYYY-MM-DD")
      };
      const response = await postSubmitForm(url, payload);
      return response;
    };

    let promises = [];

    if (totalDays > 10) {
      let interval = Math.ceil(totalDays / 4);

      for (let i = 0; i < 4; i++) {
        let intervalStart = dateFrom.clone().add(i * interval, 'days');
        let intervalEnd = (i === 3) ? dateTo : intervalStart.clone().add(interval - 1, 'days');
        if (intervalEnd.isAfter(dateTo)) {
          intervalEnd = dateTo;
        }
        promises.push(getDataForInterval(intervalStart, intervalEnd));
      }
    } else {
      promises.push(getDataForInterval(dateFrom, dateTo));
    }

    try {
      preloader(true);
      const results = await Promise.all(promises);

      // Aggregate results
      let totalIncome = 0;
      let totalProfit = 0;
      let totalExpense = 0;
      let incomeTrend = [];
      let expenseTrend = [];
      let profitTrend = [];
      let poTrend = [];
      let salariesTrend = [];
      let totalExpenses = 0;
      let totalPurchaseOrder = 0;
      let totalSalary = 0;
      let expensesTable = [];
      let poTable = [];
      let salariesTable = [];
      let incomeTable = [];

      results.forEach(response => {
        if (response && response.status === 1) {
          totalIncome += response.totalIncome;
          totalProfit += response.profitAmount;
          totalExpense += response.finalExpense;
          incomeTrend.push(...response.Incomecharts);
          expenseTrend.push(...response.Expensecharts);
          profitTrend.push(...response.profitCharts);
          poTrend.push(...response.PurchaseOrdercharts);
          salariesTrend.push(...response.Salaiescharts);
          totalExpenses += response.totalExpenses;
          totalPurchaseOrder += response.totalPurchaseorder;
          totalSalary += response.totalSalary;
          expensesTable.push(...response.expenses);
          poTable.push(...response.purchaseOrders);
          salariesTable.push(...response.salaries[0]?.employee_details || []);
          incomeTable.push(...response.income);
        } else {
          showNotification(response.message, "Error");
        }
      });

      setTotalIncome(totalIncome);
      setTotalProfit(totalProfit);
      setTotalExpense(totalExpense);
      setIncomeTrend(incomeTrend);
      setExpenseTrend(expenseTrend);
      setProfitTrend(profitTrend);
      setPoTrend(poTrend);
      setSalariesTrend(salariesTrend);
      setExpense(totalExpenses);
      setTotalPurchaseOrder(totalPurchaseOrder);
      setTotalSalary(totalSalary);
      setExpensesTable(expensesTable);
      setPoTable(poTable);
      setSalariesTable(salariesTable);
      setIncomeTable(incomeTable);

      preloader(false);
    } catch (error) {
      preloader(false);
      showNotification(error, "Error");
    }
  };
  
 
  const [activeTab, setactiveTab] = useState("0");
  const [activeTabIncome, setActiveTabIncome] = useState("1");
  const [graphTracker, setGraphTracker] = useState("0")

    //for pagination
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(100);
  const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    // hideSizePerPage: true,
    // hidePageListOnlyOnePage: true,
    onPageChange: (page, sizePerPage) => {
      setPageNumber(page)
      setPageSize(sizePerPage)
    },
    firstPageText: "First",
    prePageText: "Prev",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
      {
        text: "300",
        value: 300,
      },
      {
        text: "All",
        value: incomeTable && incomeTable.length,
      },
    ],
    hideSizePerPage: true,
    ignoreSinglePage: true,
  }


  const handleOpenPurchaseOrder = (po_id ) => {
    if (po_id) {
      return (<>
       <i
        style={{ color: "blue", cursor: "pointer", justifyContent: "center", display: 'flex' }}>
        <a href={"/Purchase-order?po_id=" + po_id } target="_blank" rel="noreferrer">
          {po_id}
        </a>
        </i>
        </>
      )
    }
    return <span style={{ color: "gray" }}>Not available</span>
}
const handleOpenInvoice = (cell,row ) => {
  if (row.booking_id) {
    return (<>
     <i
      style={{ color: "blue", cursor: "pointer" }}>
      <a href={"/invoice_details?booking_id=" + row.booking_id } target="_blank" rel="noreferrer">
        {row.invoice_no}
      </a>
      </i>
      </>
    )
  }
  return <span style={{ color: "gray" }}>Not available</span>
}

  const IncomeColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (+pageNumber - 1) * +pageSize + (+rowIndex + 1);
        return rowNumber;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "1%" }
      },
      footer:false
    },
    {
      dataField: "invoice_no",
      text: props.t("Invoice"),
      formatter: handleOpenInvoice ,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      footer:false
    },
    {
      dataField: "customer_details.name",
      text: props.t("Customer"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },      
      footer:false
    },
    {
      dataField: "customer_details.customer_type",
      text: props.t("Customer Type"),
      filter: selectFilter({
        style: { marginLeft: "5px" },
        options: [
          { value: "VIP", label: "VIP" },
          { value: "Regular", label: "Regular" },
          { value: "Friend", label: "Friend" },
        ],
        placeholder: "ALL",
        onFilter: (value) => {
          setType(value)
        }
      }),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" }
      },
      footer: false
    },
    {
      dataField: "customer_details.mobile",
      text: props.t("Number"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      footer:false
    },
    {
      text: props.t("Games"),
      dataField: "game_details",
      formatter: (gameDetails, row) => {
        if (gameDetails && gameDetails.length > 0) {
          return gameDetails.map((game, id) => (
            <Badge key={id} color="primary" className="me-1">
              {game.game.name}
            </Badge>
          ))
        }
        return (
          <Badge style={{ cursor: "not-allowed" }} color="secondary">
            No Games
          </Badge>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false
    },
    {
      text: props.t("Items"),
      dataField: "item_details",
      formatter: (itemDetails, row) => {
        if (itemDetails && itemDetails.length > 0) {
          return itemDetails.map((item, id) => (
            <Badge key={id} color="info" className="me-1">
              {item.name}
            </Badge>
          ))
        }

        return (
          <Badge style={{ cursor: "not-allowed" }} color="secondary">
            No Items
          </Badge>
        )
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false
    },
    {
      text: props.t("Packages"),
      dataField: "package_details",
      formatter: (packageDetails, row) => {
        if (packageDetails && packageDetails.length > 0) {
          return packageDetails.map((packages, id) => (
            <Badge
              className="glow-effect me-1"
              key={id}
              color="success"
              onClick={() => handlePackageBadgeClick(packages)}
            >
              {packages.package_name}
            </Badge>
          ));
        }
        return (
          <Badge className="cursor-not-allowed" color="secondary">
            No Packages
          </Badge>
        );
      },
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      footer:false
    },
    {
      dataField: "item_total_price",
      text: props.t("Items Total"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
        },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
    },
    {
      dataField: "game_total_price",
      text: props.t("Games Total"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
        },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
    },
    {
      dataField: "final_amount",
      text: props.t("Final Amount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
        },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
      //   style: { cursor: "pointer" },
    },
    {
      dataField: "total_amount",
      text: props.t("Total Amount"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
        },
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2)
    },

    {
      dataField: "createdAt",
      text: props.t("Transaction Date"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      formatter: (cell, row) => {
        return moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
      },
      footer:false
      //   style: { cursor: "pointer" },
    },

  ];
  const ExpenseColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "_id",
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
      text: props.t("#"),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      style: { cursor: "pointer" },
      footer:false
    },
    {
      dataField: "source.name",
      text: props.t("Source"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      footer:false
    },
    {
      dataField: "source.description",
      text: props.t("Description"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      style: { cursor: "pointer" },
      footer:false
    },

    {
      dataField: "amount",
      text: props.t("Amount"),
      sort: true,
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      //   style: { cursor: "pointer" },
    },

    {
      dataField: "date_of_transaction",
      text: props.t("Transaction Date"),
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row) => {
        return moment(row.date_of_transaction).format("YYYY-MM-DD")
      },
      footer:false
      //   style: { cursor: "pointer" },
    },

  ];
  const POColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      footer:false,
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("PO ID"),
      dataField: "po_id",
      formatter: handleOpenPurchaseOrder ,
      sort: true,
      footer:false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Supplier"),
      dataField: "supplier.name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false,
    },
    {
      text: props.t("Branch"),
      dataField: "branch_details.name",
      sort: false,
      //   filter: selectFilter({
      //     options: [{ value: "Rendezvous", label: "Rendezvous" }],
      //     placeholder: "ALL",
      //   }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false,
    },
    {
      text: props.t("E-mail"),
      dataField: "supplier.email",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false,
    },
    {
      text: props.t("Mobile"),
      dataField: "supplier.mobile",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false,
    },
    {
      text: props.t("Address"),
      dataField: "supplier.address",
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
      footer:false,
    },
    {
      text: props.t("Date"),
      dataField: "createdAt",
      sort: true,
      formatter: (cell, row) => {
        const timestamp = row.createdAt
        const formattedDate = moment(timestamp).format("YYYY-MM-DD")
        return formattedDate
      },
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
      footer:false,
    },
    {
      text: props.t("Status"),
      formatter: (cell, row) => {
        return (
          // <p>pending</p>
          <Badge
            className={`font-size-12 badge-soft-${row.po_status === "Pending"
              ? "danger"
              : row.po_status === "Delivered"
                ? "success"
                : row.po_status === "Partially Delivered"
                  ? "warning"
                  : null
              }`}
          >
            {row.po_status}
          </Badge>
        )
      },
      dataField: "po_status",
      sort: false,
      footer:false,
      //   filter: selectFilter({
      //     options: [
      //       { value: "Pending", label: "Pending" },
      //       { value: "Delivered", label: "Delivered" },
      //       { value: "Partially Delivered", label: "Partially Delivered" },
      //       { value: "Closed", label: "Closed" },
      //     ],
      //     defaultValue: "Pending",
      //     placeholder: "ALL",
      //   }),
      headerStyle: (colum, colIndex) => {
        return { width: "5%" }
      },
    },
    {
      text: props.t("Total Price"),
      dataField: "items_total_price",
      formatter: (cell, row) => {
        return <p>{row.items_total_price?.toFixed(2)}</p>
      },
      sort: true,
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { width: "8%" }
      },
    },
  ];
  const SalariesColumn = [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      text: props.t("#"),
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1
      },
      footer:false,
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Employee"),
      dataField: "employee_name",
      sort: true,
      footer:false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Code"),
      dataField: "employee_code",
      sort: true,
      footer:false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Designation"),
      dataField: "designation",
      sort: true,
      footer:false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Days"),
      dataField: "number_of_days",
      sort: true,
      footer:false,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Basic Salary"),
      dataField: "basic_salary",
      sort: true,
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },
    {
      text: props.t("Net Salary"),
      dataField: "net_salary",
      // sort: true,
      footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      },
    },

    {
      text: props.t("Loan"),
      dataField: "deduction.loan",
      // sort: true,
      footer:false,
      // footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      }
    },
    {
      text: props.t("Undertime"),
      dataField: "deduction.undertime",
      footer:false,
      // footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      }
    },
    {
      text: props.t("Loan Arrear"),
      dataField: "allowance.loan_arrear",
      // sort: true,
      footer:false,
      // footer: columnData => columnData.reduce((acc, item) => acc + item, 0).toFixed(2),
      headerStyle: (colum, colIndex) => {
        return { width: "2%" }
      }
    },
  ]

  // Print
  const printDiv = (divName) => {
    let content = document.getElementById(divName);
    var printWindow = window.open("",
      "",
      "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    const imageHeight = "105px";
    const imageWidth = "110px";
    const borderradius = "50%";
    const background = "#000000";
    printWindow.document.write(`
   
       <html>
        <title> Rendezvous - Game Parlour-${code}</title>
       <head> 
        <style>
        table {
          table-layout: fixed;
          font-family: "Poppins", sans-serif;
          border: 1px solid #eff2f7;
          font-size: 12px;
          border-collapse: collapse;
          max-width: 100%;
          color: #495057;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        
        for-print-heading {
          font-size: 16px;
          margin: 0 0 0 0;
          font-weight: bold;
        }

        for-print-sub_heading {
          font-size: 14px;
          margin: 0 0 0 0;
          font-weight: normal !important;
        }
        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }
        
        table tr:nth-child(even){background-color: #f2f2f2;}
        
        table tr:hover {background-color: #ddd;}
        
        table th {            
          text-align: left;
        }
         .head{
          display:flex;
          justify-content:space-between;
         }
         .hide{
            display:none;
         }
         .react-bootstrap-table-pagination {
            display: none; 
        }
        .heading{
            display: flex;
            align-items: center;
        }
        h2 {
            margin: 0; 
        }
        #logoImg {
            height: ${imageHeight};
            width: ${imageWidth};
            background-color: ${background};
            border-radius: ${borderradius};
            margin-right: 200px; 
        }
        </style>
       </head>
    <body>
   <div class="heading">
      <img src=${logo} id="logoImg" alt="Logo" >
      <h2> ${divName}</h2>
   </div>
  ${content.innerHTML}
    </body>
       </html>

    `);
    printWindow.document.close();
    const logoImg = printWindow.document.getElementById('logoImg');
    logoImg.onload = function () {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  }
  // Export CSV
  const handleDownloadIncomeDetails = async () => {
    const fileName = "income_details";
    const exportType = "xls";
    let data_to_export = incomeTable
    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          Invoice: v.invoice_no,
          Customer: v.customer_details?.name,
          Mobile: v.customer_details?.mobile,
          ItemTotal: v.item_total_price,
          GameTotal: v.game_total_price,
          TotalAmount: v.total_amount,
          DiscountAmount: v.discount_amount,
          FinalAmount: v.final_amount,
          Transactiondate: moment(v.createdAt).format("YYYY-MM-DD HH:mm:ss")
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
    exportFromJSON({ data, fileName, exportType });
  };
  const handleDownloadPODeatils = async () => {
    const fileName = "PO Details";
    const exportType = "xls";
    let data_to_export = poTable
    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          PO_ID: v.po_id,
          Supplier: v.supplier?.name,
          Mobile: v.supplier?.mobile,
          Address: v.supplier?.address,
          TotalAmount: v.items_total_price,
          PO_Status: v.po_status,
          Transactiondate: moment(v.createdAt).format("YYYY-MM-DD")
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
    exportFromJSON({ data, fileName, exportType });
  };
  const handleDownloadExpenses = async () => {
    const fileName = "Expenses";
    const exportType = "xls";
    let data_to_export = expensesTable
    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          Source: v.source?.name,
          Description: v.source?.description,
          Amount: v.amount,
          Transactiondate: moment(v.date_of_transaction).format("YYYY-MM-DD")
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
    exportFromJSON({ data, fileName, exportType });
  };
  const handleDownloadSalaryDetails = async () => {
    const fileName = "Salaries";
    const exportType = "xls";
    let data_to_export = salariesTable
    if (data_to_export) {
      var data = [];
      JSON.parse(JSON.stringify(data_to_export)).forEach(function (v, index) {
        data.push({
          Employee: v.employee_name,
          Code: v.employee_code,
          Designation: v.designation,
          Days: v.number_of_days,
          BasicSalary: v.basic_salary,
          NetSalary: v.net_salary,
          LoanDeduction: v.deduction?.loan,
          Undertime: v.deduction?.undertime,
          Overtime: v.allowance?.overtime,
        });
      });
      exportFromJSON({ data, fileName, exportType });
    }
    exportFromJSON({ data, fileName, exportType });
  };

  const expandRowPO = {
    onlyOneExpanding: true,
    renderer: row => (
      <>
        {/* {row.po_status === "Partially Delivered" && ( */}
        <Card >
          <div className="row "  >
            <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
              {row.items.length > 0 && (
                <CardBody>
                  <div className="table-responsive ">
                    <CardTitle>
                      <h5>Requested Items</h5>
                    </CardTitle>
                    <table
                      id="example"
                      className="table table-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead className="bg-light">
                        <tr>

                          <th>S.N</th>
                          <th>Name</th>
                          <th>Requested Quantity</th>
                          <th>Sub Total(QR)</th>

                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "white" }}>
                        {row?.items.map((item, index) =>

                          <tr key={index + 1} >
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item.quantity.in_unit} &nbsp; {item.unit}&nbsp; {(item.quantity.in_sub_unit ? item.quantity.in_sub_unit : null)} &nbsp; {item.quantity.in_sub_unit ? item.sub_unit : ""} </td>
                            <td>{item?.total_price.toFixed(2)}</td>
                          </tr>

                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              )}

              {row.delivery_details.length > 0 && (
                <CardBody>
                  <div className="table-responsive ">
                    <CardTitle>
                      <h5>Received Items</h5>
                    </CardTitle>
                    <table
                      id="example"
                      className="table table-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead className="bg-light">
                        <tr>
                          <th>S.N</th>
                          <th>Name</th>
                          <th>Received Quantity</th>
                          <th>Received date</th>
                          <th>Sub Total(QR)</th>
                        </tr>
                      </thead>
                      <tbody style={{ backgroundColor: "white" }}>
                        {row?.delivery_details.map((item, index) => (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td>
                              {item.receive.in_unit} &nbsp; {item.unit} &nbsp;{" "}
                              {item.receive.in_sub_unit
                                ? item.receive.in_sub_unit
                                : null}{" "}
                              &nbsp; {item.sub_unit}{" "}
                            </td>
                            <td>
                              {moment(item?.received_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{item?.total_price.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              )}
            </div>
          </div>
        </Card>
        {/* )} */}
      </>
    ),
  }
  const expandRowIncome = {
    onlyOneExpanding: true,
    renderer: row => (
      <Card>
        <div className="row "  >
          <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
            {/* Games */}
            {row.game_details.length > 0 && (
              <CardBody>
                <div className="table-responsive-sm ">
                  <CardTitle>
                    <h5>{props.t("Games")} </h5>
                  </CardTitle>
                  <table
                    id="example"
                    className="table table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead className="bg-light">
                      <tr>

                        <th>{props.t("S.N")}</th>
                        <th>{props.t("Name")}</th>
                        <th>{props.t("Duration(Minutes)")}</th>
                        <th>{props.t("Sub Total(QR)")}</th>

                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }} >
                      {row?.game_details.map((game, index) =>

                        <tr key={index + 1} >
                          <td>{index + 1}</td>
                          <td>{game?.game.name}</td>
                          <td>{game?.game.duration}</td>
                          <td>{game?.game.sub_total}</td>

                        </tr>

                      )}
                      <tr>
                        <td colSpan={3} style={{ textAlign: "end", fontWeight: "bold" }} >{props.t("Game Total")}</td>
                        <td style={{ fontWeight: "bold" }}>{row.game_total_price || 0}</td>
                      </tr>

                    </tbody>
                  </table>



                </div>
              </CardBody>
            )}

            {/* Items */}
            {row.item_details.length > 0 && (
              <CardBody>
                <div className="table-responsive ">
                  <CardTitle>
                    <h5>{props.t("Items")} </h5>
                  </CardTitle>
                  <table
                    id="example"
                    className="table table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead className="bg-light">
                      <tr>

                        <th>{props.t("S.N")}</th>
                        <th>{props.t("Name")}</th>
                        <th>{props.t("Quantity")}</th>
                        <th>{props.t("Sub Total(QR)")}</th>

                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }}>
                      {row?.item_details.map((item, index) =>

                        <tr key={index + 1} >
                          <td>{index + 1}</td>
                          <td>{item?.name}</td>
                          <td>{item.quantity?.in_unit} {item.unit} {item.sub_unit !== undefined && item.sub_unit !== "" ? item.quantity.in_sub_unit + " " + item.sub_unit : ""}</td>
                          <td>{item?.sub_total}</td>

                        </tr>

                      )}
                      <tr>
                        <td colSpan={3} style={{ textAlign: "end", fontWeight: "bold" }} >{props.t("Item Total")}</td>
                        <td style={{ fontWeight: "bold" }}>{row.item_total_price || 0}</td>
                      </tr>

                    </tbody>
                  </table>



                </div>
              </CardBody>
            )}

            {/* Packages */}
            {row.package_details.length > 0 && (
              <CardBody>
                <div className="table-responsive ">
                  <CardTitle> <h5>{props.t("Packages")} </h5></CardTitle>
                  <table
                    id="example"
                    className="table table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead className="bg-light">
                      <tr>

                        <th>{props.t("S.N")}</th>
                        <th>{props.t("Name")}</th>
                        <th>{props.t("Duration/Quantity")}</th>

                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white" }}>
                      {row.package_details && row.package_details[0]?.game_details?.map((item, index) =>

                        <tr key={index + 1} >
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.package_game_remaining || item.requested_duration}</td>
                        </tr>

                      )}

                      {row?.package_details && row?.package_details[0].item_details?.map((item, index) =>

                        <tr key={index + 1} >
                          <td>{row?.package_details[0].game_details.length + index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.quantity.in_unit} {item.unit} {item.quantity.in_sub_unit} {item.sub_unit}</td>


                        </tr>

                      )}

                    </tbody>
                  </table>



                </div>
              </CardBody>
            )}


          </div>
        </div>
      </Card>
    )
  };

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Accounts Reports")}
            breadcrumbItem={props.t("Accounts Report")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(0, 40, 80, 1) 5%, rgba(180, 210, 220, 1) 90%)",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                }}>
                  <Col lg={12}>
                    <AvForm >
                      <Row >
                        <Col md={3}>
                          <label style={{ color: "white" }} className="form-label">
                            {props.t("From Date")}
                          </label>

                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            options={{
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },
                            }}
                            onChange={(v) => {
                              const dateString = v;
                              setFromdate(new Date(dateString))

                            }}
                            value={fromdate}
                            name="date_from"
                            placeholder="Select From Date"
                            errorMessage="Select From Date."
                            validate={{
                              required: { value: true },
                            }}
                          />

                        </Col>

                        <Col md={3} >

                          <label style={{ color: "white" }} className="form-label">
                            {props.t("To Date")}
                          </label>

                          <AvField
                            className="form-control"
                            type="select"
                            tag={Flatpickr}
                            value={todate}
                            onChange={(v) => {
                              const dateString = v;
                              setTodate(new Date(dateString))

                            }}
                            options={{
                              // maxDate:'today',
                              onDayCreate: (dObj, dStr, fp, dayElem) => {
                                dayElem.addEventListener('mousedown', (e) => {
                                  e.preventDefault(); // Prevent default click behavior
                                });
                              },

                            }}
                            name="date_to"
                            placeholder="Select To Date"
                            errorMessage="Select To Date."
                            validate={{
                              required: { value: true },
                            }}
                          />

                        </Col>



                        <Col md={4} style={{ display: "flex", flexDirection: "column" }}  >
                          <label style={{ height: "0px" }} className="form-label">

                          </label>
                          <Button style={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundColor: "#fdd005",
                            border: "none",
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "500",
                            width: "128px",
                            marginTop: "1rem",
                            boxShadow:
                              "rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .4) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px",
                            transition: "transform 0.3s ease",
                          }} type="submit"
                            onClick={() => loadAccountsReport()}
                            // color="primary"
                            // className="btn btn-info btn-md"
                            onMouseEnter={e => {
                              e.target.style.transform = "scale(1.05)"
                            }}
                            onMouseLeave={e => {
                              e.target.style.transform = "scale(1)"
                            }}
                          >
                            Show
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                background:
                                  "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))",
                                pointerEvents: "none",
                              }}
                            ></div>
                          </Button>

                        </Col>

                      </Row>
                    </AvForm>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <div className="checkout-tabs">
            <Row>
              <Col lg="12">
                <Nav className="flex-column" pills>
                  <Row>
                    <Col lg={4}>
                      <NavItem>
                        <NavLink
                          style={{ backgroundImage: `url(${infograph1})`, backgroundSize: "cover", backgroundPosition: "center" }}
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            setactiveTab("1");
                          }}
                        >
                          <span style={{ color: activeTab === "1" ? "white" : "black" }}>
                            <i className="mdi mdi-cash-usd-outline d-block check-nav-icon mt-4 mb-2 ">

                            </i>
                            <p className="fw-bold mb-4">
                              {props.t("Income")} <br />
                              QR {totalIncome && totalIncome % 1!==0 ? totalIncome.toFixed(2) : totalIncome }
                            </p>
                          </span>

                        </NavLink>
                      </NavItem>
                    </Col>
                    <Col lg={4}>
                      <NavItem>
                        <NavLink
                          style={{ backgroundImage: `url(${infograph2})`, backgroundSize: "cover", backgroundPosition: "center" }}
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            setactiveTab("2");
                          }}
                        >
                          <span style={{ color: activeTab === "2" ? "white" : "black" }}>
                            <i className="mdi mdi-cash-minus d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="fw-bold mb-4">
                              {props.t("Expenses")} <br />
                              QR {totalExpense && totalExpense % 1 !== 0 ? totalExpense.toFixed(2) : totalExpense}
                            </p>
                          </span>

                        </NavLink>
                      </NavItem>
                    </Col>
                    <Col lg={4}>
                      <NavItem>
                        <NavLink
                          style={{ backgroundImage: `url(${infograph3})`, backgroundSize: "cover", backgroundPosition: "center" }}
                          className={classnames({ active: activeTab === "3" })}
                          onClick={() => {
                            setactiveTab("3");
                          }}
                        >
                          <span style={{ color: activeTab === "3" ? "white" : "black" }}>
                            <i className="mdi mdi-cash-plus d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="fw-bold mb-4">
                              {props.t("Profit")} <br />
                              QR {totalProfit && totalProfit % 1 !== 0 ? totalProfit.toFixed(2) : totalProfit}
                            </p>
                          </span>


                        </NavLink>
                      </NavItem>
                    </Col>
                  </Row>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Card className="shadow-none border mb-0">
                          <CardBody>
                            <div id="line-chart" className="e-chart mb-4">
                              <Linechart
                                line_data={incomeTrend && incomeTrend}
                                type="income_trend"
                                yAxisLabel="Income"
                              />
                            </div>
                            <Row>

                            </Row>

                            <div id="Income">
                              <ToolkitProvider
                                keyField="_id"
                                data={incomeTable && incomeTable}
                                columns={IncomeColumn}
                                noDataIndication={props.t(
                                  "No data to display."
                                )}
                                //   bootstrap4
                                search
                              >
                                {(propst) => (
                                  <div>
                                    <AvForm>
                                      <Row className="hide">
                                        <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                          <SearchBar {...propst.searchProps} />
                                        </Col>

                                        <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>

                                          <Button
                                            style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                            className="btn btn-sm btn-secondary"
                                            disabled={incomeTable?.length <= 0}
                                            onClick={() => { printDiv("Income") }}
                                          >
                                            <i
                                              className="fa fa-print " aria-hidden="true"
                                              onClick={() => { printDiv("Income") }}
                                            />&nbsp; {props.t("Print")}
                                          </Button>
                                          <Button
                                            style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                            type="submit"
                                            className="btn btn-sm btn-secondary"
                                            onClick={handleDownloadIncomeDetails}
                                            disabled={incomeTable.length <= 0}
                                          >
                                            <i className="mdi mdi-file-export" /> &nbsp;
                                            {props.t("Export CSV")}
                                          </Button>

                                        </Col>
                                      </Row>
                                    </AvForm>

                                    <BootstrapTable
                                      striped
                                      keyField="_id"
                                      filter={filterFactory()}
                                      pagination={paginationFactory(options)}
                                      // expandRow={expandRowIncome}
                                      hover
                                      condensed
                                      {...propst.baseProps}
                                    />
                                  </div>
                                )}
                              </ToolkitProvider>
                            </div>

                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="2"
                        id="v-pills-payment"
                        role="tabpanel"
                        aria-labelledby="v-pills-payment-tab"
                      >
                        <Card className="shadow-none border mb-0">
                          <CardBody>

                            {graphTracker === "0" && (
                              <div id="line-chart" className="e-chart mb-4">
                                <Linechart
                                  line_data={expenseTrend && expenseTrend}
                                  type="expense_trend"
                                  yAxisLabel="Expense"
                                />
                              </div>
                            )}

                            {graphTracker === "1" && (
                              <>
                                <div id="line-chart" className="e-chart mb-4">
                                  <Linechart
                                    line_data={expenseTrend && expenseTrend}
                                    type="expense_trend"
                                    yAxisLabel="Expense"
                                  />
                                </div>
                              </>
                            )}
                            {graphTracker === "2" && (
                              <>
                                <div id="line-chart" className="e-chart mb-4">
                                  <Linechart
                                    line_data={poTrend && poTrend}
                                    type="po_trend"
                                    yAxisLabel="PO"
                                  />
                                </div>
                              </>
                            )}

                            {graphTracker === "3" && (
                              <>
                                <div id="line-chart" className="e-chart mb-4">
                                  <Linechart
                                    line_data={salariesTrend && salariesTrend}
                                    type="salaries_trend"
                                    yAxisLabel="Salaries"
                                  />
                                </div>
                              </>
                            )}
                            <Row className="mb-2">

                            </Row>
                            <Row>
                              <Nav pills className="navtab-bg nav-justified">
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        activeTabIncome &&
                                        activeTabIncome === "1",
                                    })}
                                    onClick={() => {
                                      setActiveTabIncome("1");
                                      setGraphTracker("1")
                                    }}
                                  >
                                    {props.t("Expense")}:
                                    <b>
                                      {" QR "}
                                      {expense &&
                                        expense.toFixed(2)}
                                    </b>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        activeTabIncome &&
                                        activeTabIncome === "2",
                                    })}
                                    onClick={() => {
                                      setActiveTabIncome("2");
                                      setGraphTracker("2")
                                    }}
                                  >
                                    {props.t("Purchase Order")}:
                                    <b>
                                      {" QR "}
                                      {totalPurchaseOrder &&
                                        totalPurchaseOrder.toFixed(2)}
                                    </b>
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active:
                                        activeTabIncome &&
                                        activeTabIncome === "3",
                                    })}
                                    onClick={() => {
                                      setActiveTabIncome("3");
                                      setGraphTracker("3")
                                    }}
                                  >
                                    Salary:
                                    <b>
                                      {" QR "}
                                      {totalSalary &&
                                        totalSalary.toFixed(2)}
                                    </b>
                                  </NavLink>
                                </NavItem>
                              </Nav>

                              <TabContent
                                activeTab={activeTabIncome && activeTabIncome}
                                className="p-3 text-muted"
                              >
                                <TabPane tabId="1">
                                  <Row className="mb-2">

                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <div id="Total Expense Report ">
                                        <ToolkitProvider
                                          keyField="_id"
                                          data={expensesTable && expensesTable}
                                          columns={ExpenseColumn}
                                          noDataIndication={props.t("No data to display.")}
                                          search
                                        //   bootstrap4
                                        >
                                          {(propst) => (
                                            <div>
                                              <AvForm>
                                                <Row className="hide">
                                                  <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                    <SearchBar {...propst.searchProps} />
                                                  </Col>
                                                  <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                      style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                      className="btn btn-sm btn-secondary"
                                                      disabled={expensesTable?.length <= 0}
                                                      onClick={() => { printDiv("Total Expense Report ") }}
                                                    >
                                                      <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("Total Expense Report ") }}
                                                      />&nbsp; {props.t("Print")}
                                                    </Button>
                                                    <Button
                                                      style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                      type="submit"
                                                      className="btn btn-sm btn-secondary"
                                                      onClick={handleDownloadExpenses}
                                                      disabled={expensesTable.length <= 0}
                                                    >
                                                      <i className="mdi mdi-file-export" /> &nbsp;
                                                      {props.t("Export CSV")}
                                                    </Button>

                                                  </Col>
                                                </Row>
                                              </AvForm>

                                              <BootstrapTable
                                                striped
                                                keyField="_id"
                                                // expandRow={expandRowServices}
                                                hover
                                                condensed
                                                {...propst.baseProps}
                                              />
                                            </div>
                                          )}
                                        </ToolkitProvider>
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row className="mb-2">

                                  </Row>
                                  <Row>
                                    <Col sm="12">
                                      <div id="Purchase Order Expense">
                                        <ToolkitProvider
                                          keyField="_id"
                                          data={poTable && poTable}
                                          columns={POColumn}
                                          noDataIndication={props.t(
                                            "No data to display."
                                          )}
                                          //   bootstrap4
                                          search
                                        >
                                          {(propst) => (
                                            <div>
                                              <AvForm>
                                                <Row className="hide">
                                                  <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                    <SearchBar {...propst.searchProps} />
                                                  </Col>
                                                  <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                      style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                      className="btn btn-sm btn-secondary"
                                                      disabled={poTable?.length <= 0}
                                                      onClick={() => { printDiv("Purchase Order Expense") }}
                                                    >
                                                      <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("Purchase Order Expense") }}
                                                      />&nbsp; {props.t("Print")}
                                                    </Button>
                                                    <Button
                                                      style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                      type="submit"
                                                      className="btn btn-sm btn-secondary"
                                                      onClick={handleDownloadPODeatils}
                                                      disabled={poTable.length <= 0}
                                                    >
                                                      <i className="mdi mdi-file-export" /> &nbsp;
                                                      {props.t("Export CSV")}
                                                    </Button>

                                                  </Col>
                                                </Row>
                                              </AvForm>
                                              <BootstrapTable
                                                keyField="_id"
                                                striped
                                                hover
                                                expandRow={expandRowPO}
                                                condensed
                                                {...propst.baseProps}
                                              />{" "}
                                            </div>
                                          )}
                                        </ToolkitProvider>
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                  <Row className="mb-2">

                                  </Row>
                                  <Row>
                                    <Col sm="12">
                                      <div id="Salary Expense">
                                        <ToolkitProvider
                                          keyField="_id"
                                          data={salariesTable && salariesTable || []}
                                          columns={SalariesColumn}
                                          noDataIndication={props.t(
                                            "No data to display."
                                          )}
                                          //   bootstrap4
                                          search
                                        >
                                          {(propst) => (
                                            <div>
                                              <AvForm>
                                                <Row className="hide">
                                                  <Col lg={4} style={{ display: "flex", alignItems: "flex-start" }}>
                                                    <SearchBar {...propst.searchProps} />
                                                  </Col>
                                                  <Col lg={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <Button
                                                      style={{ width: "115px", height: "35px", background: "#1673F7", border: "none", fontSize: "12px", marginRight: "10px" }}
                                                      className="btn btn-sm btn-secondary"
                                                      disabled={salariesTable?.length <= 0}
                                                      onClick={() => { printDiv("Salary Expense") }}
                                                    >
                                                      <i
                                                        className="fa fa-print " aria-hidden="true"
                                                        onClick={() => { printDiv("Salary Expense") }}
                                                      />&nbsp; {props.t("Print")}
                                                    </Button>

                                                    <Button
                                                      style={{ width: "115px", height: "35px", background: "#F93A5C", border: "none" }}
                                                      type="submit"
                                                      className="btn btn-sm btn-secondary"
                                                      onClick={handleDownloadSalaryDetails}
                                                      disabled={salariesTable?.length <= 0}
                                                    >
                                                      <i className="mdi mdi-file-export" /> &nbsp;
                                                      {props.t("Export CSV")}
                                                    </Button>

                                                  </Col>
                                                </Row>
                                              </AvForm>
                                              <BootstrapTable
                                                keyField="_id"
                                                striped
                                                hover
                                                // expandRow={expandRowProducts}
                                                condensed
                                                {...propst.baseProps}
                                              />{" "}
                                            </div>
                                          )}
                                        </ToolkitProvider>
                                      </div>
                                    </Col>
                                  </Row>
                                </TabPane>
                              </TabContent>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>
                      <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
                        <Card className="shadow-none border mb-0">
                          <CardBody>
                            <Row>
                              <Col sm="12">
                                <CardTitle className="mb-4">
                                  {props.t("Profit ") +
                                    " = " +
                                    props.t("Income") +
                                    " - " +
                                    props.t("Expenses")}{" "}
                                  <br />
                                  {totalProfit && totalProfit} ={" "}
                                  {totalIncome && totalIncome} -{" "}
                                  {totalExpense && totalExpense}
                                </CardTitle>
                                <div id="line-chart" className="e-chart mb-4">
                                  <Linechart
                                    line_data={profitTrend && profitTrend}
                                    type="profit_trend"
                                    yAxisLabel="Profit"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </TabPane>

                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(connect(null, {})(withTranslation()(AccountsReport)))
