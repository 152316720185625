import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Swal from "sweetalert2";
import { submitItemError, submitItemSuccess } from "./actions";
import { SUBMIT_ITEM } from "./actionTypes";
import { postSubmitForm } from "../../../helpers/backend_helper";

//notifications
function showNotification(message, type){
    if (type === "Success") Swal.fire(type,message,"success");
    else Swal.fire(type,message,"error");
}
function* submitItem({ payload: {addItem, history} }) {
    try{
        let url = process.env.REACT_APP_BASEURL + "accessory_items/insert";

        const response = yield call(postSubmitForm, url, addItem);
        if(response.status === 1){
            showNotification("Item added Successfully", "Success");
            yield put(submitItemSuccess(response));
        }else{
            console.log("Saga Responce",response);
            showNotification(response.message,"Error");
            yield put(submitItemError(response.message));
        }
    }catch(error){
        showNotification("Something went wrong!","Error");
        yield put(submitItemError(error));
    }

}

export function* watchSubmitItem(){
    yield takeEvery(SUBMIT_ITEM, submitItem);
}
function* AccessoriesItemSaga(){
    yield all([fork
            (watchSubmitItem)
        ]);
}

export default AccessoriesItemSaga;