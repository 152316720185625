import { SUBMIT_ITEM, SUBMIT_ITEM_ERROR, SUBMIT_ITEM_SUCCESS } from "./actionTypes";


const initialState ={
    loading:false,
    error:null,
};

const accessoriesItem = ( state= initialState, action )=> {
    switch(action.type){
            case SUBMIT_ITEM:
                return {
                  ...state,
                    loading:true,
                    error:null,
                };
            case SUBMIT_ITEM_SUCCESS:
                return {
                  ...state,
                    loading:false,
                    error:null,
                    accessoriesItem:action.payload,
                };
            case SUBMIT_ITEM_ERROR:
                return {
                  ...state,
                    loading:false,
                    error:action.payload,
                };
            default:
                return state;
        }
}

export default accessoriesItem;