import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";
import { Label, Col, Row, Container, Card, CardBody } from 'reactstrap';

import showNotification from './show_notificaton';

const Search_Bar = (props) => {


    const [query, setQuery] = useState('');
    const [allItems, setAllItems] = useState([]);


    const debounceDelay = 500; // Adjust the debounce delay as per your requirement

    const { uniqueCode, setUniqueCode, addedItems, setAddedItems, cameWithRow, selectedPO, setPromotionalGoods, results, setResults, selectedFreeItems, setSelectedFreeItems, userToken } = props;

    // console.log(uniqueCode, "code")



    useEffect(() => {


        const fetchResults = async () => {
            let url = process.env.REACT_APP_BASEURL

            axios.post(
                url + `items/getall_item`,
                // `http://localhost:3002/purchase_orders/search_item`,
                {}, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }).then(res => { setAllItems(res.data.data); }).catch(err => console.log(err));
        };

        fetchResults()

    }, []);

    useEffect(() => {

        setAddedItems(prev => prev = { ...prev, [selectedPO._id]: { ...prev[selectedPO._id] } })
        setPromotionalGoods(prev => prev = { ...prev, [selectedPO._id]: prev[selectedPO._id] ? [...prev[selectedPO._id]] : [] })


        const fetchResults = async () => {
            let url = process.env.REACT_APP_BASEURL
            const payload = { name: query }
            axios.post(
                url + `purchase_orders/search_item`,
                // `http://localhost:3002/purchase_orders/search_item`,
                payload, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }).then(res => { setResults(res.data.data); }).catch(err => console.log(err));
        };


        const debounceTimer = setTimeout(() => {
            fetchResults();
        }, debounceDelay);

        return () => clearTimeout(debounceTimer);
    }, [query]);



    const handleInputChange = (newValue) => {
        // console.log(newValue)
        setQuery(newValue);
    };

    const handleOptionSelect = (e, selectedOption) => {


        let selectedItem = selectedOption.filter(item => e.label === item.name)

        setSelectedFreeItems(prev => prev = [...prev, e])

        setAddedItems(prev => prev = { ...prev, [selectedPO._id]: { ...prev[selectedPO._id], [e.label]: e.label } })

        setPromotionalGoods(prev => prev = { ...prev, [selectedPO._id]: prev[selectedPO._id] ? [...prev[selectedPO._id], { ...selectedItem[0], uniqueId: uniqueCode + 1, cameWith: cameWithRow, receive: { in_unit: 0, in_sub_unit: 0 }, expiry_date: "", total_price: 0 }] : [{ ...selectedOption[0], uniqueId: uniqueCode + 1, cameWith: cameWithRow, receive: { in_unit: 0, in_sub_unit: 0 }, expiry_date: "", total_price: 0 }] })

        setUniqueCode(prev => prev += 1)

    };

    return (
        <Container>

            <Card>

                <CardBody>

                    <Row>
                        <Col lg={4}>
                            <Label>Search Items to Add</Label>
                            <Select

                                inputValue={query}
                                onInputChange={handleInputChange}
                                options={(results.length > 0 && query !== "" ? results : allItems).filter(item => item.is_active !== false).map((result) => ({ label: result.name, value: result._id }))}
                                onChange={(e) => handleOptionSelect(e, (results.length > 0 && query !== "") ? results : allItems)}
                            />
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Container>
    );
};

export default Search_Bar;