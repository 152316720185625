import React, { useState, useEffect } from "react"
import axios from "axios"
import { InputShift } from "../InputShift/InputShift"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Input,
  Button,
  CardTitle,
  Table,
  Spinner,
} from "reactstrap"
// import {
//   Search,
// } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { loadRosterOwner } from "../../store/owner-roster/action"
import { use } from "i18next"
import { filter } from "lodash"
import showNotification from "../../helpers/show_notificaton"
import { postSubmitForm } from "../../helpers/backend_helper"
import tippy from "tippy.js"
import BackgroundImage from "../../assets/images/Background.png"

const Roster = () => {
  //meta title
  // document.title = "Form Elements | Skote - React Admin & Dashboard Template";

  // const { SearchBar } = Search
  const [employee_id, setEmployeeId] = useState(
    localStorage.getItem("employee_id")
  )
  const [employeeToken, setEmployeeToken] = useState(
    localStorage.getItem("userToken")
  )
  const [shiftTime, setShiftTime] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [visible, setVisible] = useState(true)
  const [visibleFilteredData, setVisibleFilteredData] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  const updateShiftTime = (index, value) => {
    const updatedShiftTimes = [...shiftTime]
    updateShiftTime[index].push(value)
    // updatedShiftTimes[index] = value;
    setShiftTime(updatedShiftTimes)
  }

  // calling useEffect hook to dispatch loadRosterOwner action
  useEffect(() => {
    // function getRoster(token) {

    //   console.log("token:",token)
    //   axios.post(`https://api-rendezvous.mhsinfotech.qa/rosters/get_by_employee`, {employee_id:employee_id}, {
    //     headers: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   }).then(res => {
    //     console.log("response from roster/get_by_employee route", res);
    //     setrosterData(res.data.data[0])

    //   }).catch(err => console.log(err));
    // }
    // getRoster(employeeToken);

    dispatch(loadRosterOwner())

    // console.log(" hello form owner roster dashboard")
    loadAllShift()
  }, [])
  // -----------------------------------------------------------------------

  // getting all employee roster from redux store
  const ownerRosterData = useSelector(store => store.ownerRoster)
  // console.log(ownerRosterData, "owner rosterData")
  // -------------------------------------------------------------------

  // getting search input function
  function handleSearch(value) {
    setSearchValue(value)
    handleFilterEmployee(value)
  }
  // -----------------------------------------------------------------------

  // function to filter employee for displaying on table when search is used
  function handleFilterEmployee(e) {
    const searchValue = e.target.value.trim()
    const filteredData = ownerRosterData.roster.filter(
      roster =>
        roster.code.includes(searchValue) ||
        roster.name.toLowerCase().includes(searchValue.toLowerCase())
    )

    if (searchValue === "") {
      setVisible(true)
      setVisibleFilteredData(false)
    } else {
      setVisible(false)
      setVisibleFilteredData(true)
    }
    setFilteredData(filteredData)
    // console.log(ownerRosterData, "data after search")
  }
  // -------------------------------------------------------------------------------------
  // function to search the shift id
  async function searchShiftId(e, rosterData, token, day) {
    let url = process.env.REACT_APP_BASEURL

    let shiftId = ""
    await axios
      .post(
        url + `shifts/getall`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        let temp = res.data.data.filter(shift => shift.name === e.target.value)
        // console.log("temp:", temp);
        shiftId = temp[0]._id
      })
      .catch(err => console.log(err))

    // console.log(shiftId," shift id")
    updateEmployeeRoster(rosterData, token, day, shiftId)
  }
  // --------------------------------------------------------------------------------

  // function to update the individual employee schedule by passing new shiftId
  function updateEmployeeRoster(rosterData, token, day, shiftId) {
    let url = process.env.REACT_APP_BASEURL

    let payload = {
      employee_id: rosterData._id,
      day: day,
      shift_id: shiftId,
    }

    axios
      .post(url + `rosters/update`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        // console.log(res);
        dispatch(loadRosterOwner())
        showNotification(res.message, "Success")
      })
      .catch(err => {
        console.log(err)
        showNotification(res.message, "Error")
      })
  }
  // ----------------------------------------------------------------------------------------------------
  // console.log("shift id:",shiftId)

  //All Shifts
  const [allShift, setAllShift] = useState([])
  const loadAllShift = async () => {
    let url = process.env.REACT_APP_BASEURL + "shifts/getall"
    const response = await postSubmitForm(url, {})
    if (response && response.status === 1) {
      const filteredData = response.data.filter(
        shift => shift.is_active === true
      )
      setAllShift(filteredData)
    } else {
      showNotification(response.message, "Error")
    }
  }

  const backgroundImageStyle = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }

  return (
    <React.Fragment>
      <div className="page-content" style={backgroundImageStyle}>
        <Container fluid={true}>
          <Breadcrumbs
            title="Attendance"
            breadcrumbItem1="Roster"
            alternateTitle="Roster"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody
                  style={{
                    overflowX: "scroll",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  }}
                >
                  <div className="mb-3">
                    <label className="form-label">
                      Search by Employee Code
                    </label>
                    <Input
                      style={{ width: "200px" }}
                      placeholder="Enter Employee Code"
                      type="text"
                      value={searchValue}
                      onKeyUp={handleFilterEmployee}
                      onChange={e => handleSearch(e.target.value)}
                    />
                  </div>

                  <div>
                    <Table className="table table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Employee Code</th>
                          <th>Name</th>
                          <th>Saturday</th>
                          <th>Sunday</th>
                          <th>Monday</th>
                          <th>Tuesday</th>
                          <th>Wednesday</th>
                          <th>Thursday</th>
                          <th>Friday</th>
                        </tr>
                      </thead>
                      <tbody>
                        {visible &&
                          ownerRosterData.roster.map((rosterData, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {(rosterData && rosterData.code) ||
                                  "unavailable"}
                              </td>
                              <td>
                                {(rosterData && rosterData.name) ||
                                  "unavailable"}
                              </td>

                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "saturday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.saturday &&
                                    rosterData.saturday.name !== "" && (
                                      <option value="">
                                        {rosterData.saturday.name}
                                      </option>
                                    )}
                                  <option value="Off">Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "sunday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.sunday &&
                                    rosterData.sunday.name !== "" && (
                                      <option value="">
                                        {rosterData.sunday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "monday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.monday &&
                                    rosterData.monday.name !== "" && (
                                      <option value="">
                                        {rosterData.monday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "tuesday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.tuesday &&
                                    rosterData.tuesday.name !== "" && (
                                      <option value="">
                                        {rosterData.tuesday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "wednesday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.wednesday &&
                                    rosterData.wednesday.name !== "" && (
                                      <option value="">
                                        {rosterData.wednesday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "thursday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.thursday &&
                                    rosterData.thursday.name !== "" && (
                                      <option value="">
                                        {rosterData.thursday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "friday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.friday &&
                                    rosterData.friday.name !== "" && (
                                      <option value="">
                                        {rosterData.friday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                            </tr>
                          ))}

                        {/* For Search Enter update */}

                        {visibleFilteredData &&
                          filteredData.map((rosterData, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {(rosterData && rosterData.code) ||
                                  "unavailable"}
                              </td>
                              <td>
                                {(rosterData && rosterData.name) ||
                                  "unavailable"}
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "saturday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.saturday &&
                                    rosterData.saturday.name !== "" && (
                                      <option value="">
                                        {rosterData.saturday.name}
                                      </option>
                                    )}
                                  <option value="Off">Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "sunday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.sunday &&
                                    rosterData.sunday.name !== "" && (
                                      <option value="">
                                        {rosterData.sunday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "monday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.monday &&
                                    rosterData.monday.name !== "" && (
                                      <option value="">
                                        {rosterData.monday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "tuesday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.tuesday &&
                                    rosterData.tuesday.name !== "" && (
                                      <option value="">
                                        {rosterData.tuesday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "wednesday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.wednesday &&
                                    rosterData.wednesday.name !== "" && (
                                      <option value="">
                                        {rosterData.wednesday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "thursday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.thursday &&
                                    rosterData.thursday.name !== "" && (
                                      <option value="">
                                        {rosterData.thursday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  className="form-control"
                                  type="select"
                                  placeholder="Enter shift name"
                                  name="shift_timing"
                                  onChange={e => {
                                    searchShiftId(
                                      e,
                                      rosterData,
                                      employeeToken,
                                      "friday"
                                    )
                                    updateShiftTime(index, e.target.value)
                                  }}
                                  value={shiftTime[index]}
                                >
                                  {rosterData &&
                                    rosterData.friday &&
                                    rosterData.friday.name !== "" && (
                                      <option value="">
                                        {rosterData.friday.name}
                                      </option>
                                    )}
                                  <option value="Off"> Off</option>
                                  {allShift &&
                                    allShift.map(shift => (
                                      <option
                                        key={shift._id}
                                        value={shift.name}
                                      >
                                        {shift.name}
                                      </option>
                                    ))}
                                </Input>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Roster
